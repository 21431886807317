import React from "react";
import { compose, graphql, Mutation, withApollo, Query } from "react-apollo";
import * as Yup from "yup"

import history from "../history"


import { getCurrentUser } from "../apollo/state/queries";
import { GET_FOLLOWERS_ALPHABETIZED, GET_MY_TRIPS } from "../apollo/db/queries"
import { CREATE_TRIP, CREATE_TRIP_GENERAL_LOCATIONS, CREATE_TRIP_USERS } from "../apollo/db/mutations"

import TripForm from "../components/TripForm";
import CreateTripUsers from "../components/CreateTripUsers"
import CreateTripLocations from "../components/CreateTripLocations"

class CreateTripContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			locations: [], 
			locationValue: '',
			showTooltip: false,
			showUsers: false,
			tooltipType: null,
			users: [],
			userIds: [],
			userValue: '', 
			voteNameViewable: true
		}

		this.handleAddUser = this.handleAddUser.bind(this)
		this.handleCreateTrip = this.handleCreateTrip.bind(this)
		this.handleHideUsers = this.handleHideUsers.bind(this)
		this.handleLocationChange = this.handleLocationChange.bind(this)
		this.handleShowUsers = this.handleShowUsers.bind(this)
		this.handleUserChange = this.handleUserChange.bind(this)
		this.handleAddLocation = this.handleAddLocation.bind(this)
		this.handleRemoveLocation = this.handleRemoveLocation.bind(this)
		this.handleRemoveUser = this.handleRemoveUser.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.handleToggleVoteNameViewable = this.handleToggleVoteNameViewable.bind(this)
		this.renderUsers = this.renderUsers.bind(this)

	}

	componentDidMount(){
		const { currentUser } = this.props
		this.setState({userIds: [currentUser.id]})
	}

	async handleCreateTrip(values, createTrip, createTripUsers, setSubmitting) {
		const { currentUser, client, handleClose } = this.props
		const { locations, userIds, voteNameViewable } = this.state

		const tripName = values.tripName
		const startDate = values.startDate ? values.startDate : null
		const endDate = values.endDate ? values.endDate : null

		try {
			const res = await createTrip({
				variables: {
					input: {
						trip: {
								name: tripName,
								startDate: startDate,
								endDate: endDate,
								creatorId: currentUser.id, 
								voteAnonymity: !voteNameViewable
							}
						}	
					}
				})
			const tripId = res.data.createTrip.trip.id

			if (locations.length){
				await client.mutate({
				   	 	mutation: CREATE_TRIP_GENERAL_LOCATIONS, 
				   	 	variables: {
				   	 		input: {
								parentTripId: tripId, 
								locations: locations
							}
						}
				})
			}

			await createTripUsers({
				variables: {
		   	 		input: {
						parentTripId: tripId, 
						userIds: userIds, 
						adderUserId: currentUser.id
					}
				}
			})
			
			

			handleClose()
			history.push(`/trip/${tripId}`)

	
			
		} catch {
			console.log('error')
			setSubmitting(false)
		}
	} 

	handleAddUser(follower){
		const { userIds, users } = this.state
		const userId = follower.followerId
		const user = {
			name: follower.firstName + ' ' + follower.lastName, 
			id: userId, 
			publicUrl: follower.publicUrl
		}
		this.setState({
			userIds: [...userIds, userId],
			users: [...users, user], 
			userValue: ''
		})

	}

	handleRemoveLocation(location){
		const { locations } = this.state
		const newLocations = locations.filter(loc => loc !== location)
		this.setState({locations: newLocations})
	}

	handleRemoveUser(userId){
		const { userIds, users } = this.state
		const newUserIds = userIds.filter(id => id !== userId)
		const newUsers = users.filter(user => user.id !== userId)
		this.setState({userIds: newUserIds, users: newUsers})
	}

	handleLocationChange(event){
		this.setState({locationValue: event.target.value})
	}

	handleUserChange(event){
		this.setState({userValue: event.target.value})
	}

	handleShowUsers(){
		this.setState({showUsers: true})
	}

	handleHideUsers(){
		this.setState({showUsers: false})
	}

	handleToggleVoteNameViewable(){
		const { voteNameViewable } = this.state
		this.setState({voteNameViewable: !voteNameViewable})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}


	handleAddLocation(selected){
		const { locations } = this.state
		
		const isAdded = locations.some(location => {
				return location.placeId === selected.place_id
			})
		
		if (isAdded){
			this.setState({error: "You've already added this city"})

		} else {
			const localityObj = selected.address_components.find(component => {
				return component.types.includes('locality')
			})
			const locality = localityObj ? localityObj.long_name : null
			const adminLevel1Obj = selected.address_components.find(component => {
				return component.types.includes('administrative_area_level_1')
			})
			const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
			const countryObj = selected.address_components.find(component => {
				return component.types.includes('country')
			})
			const country = countryObj.long_name 
			const newLocation = {
				fullName: selected.formatted_address, 
				locality: locality, 
				adminLevel1: adminLevel1, 
				country: country,
				placeId: selected.place_id, 
				lat: selected.geometry.location.lat(), 
				lng: selected.geometry.location.lng()
			}
			this.setState({locations: [...locations, newLocation], locationValue: ''})
		}
	}

	renderBasicTripInfo(){
		const { currentUser, handleClose } = this.props
		const { locations, locationValue, showTooltip, tooltipType, voteNameViewable } = this.state

		const initialValues = {
			tripName: '', 
			startDate: '', 
			endDate: ''
		}


  		const tripSchema = Yup.object().shape({
  		  tripName: Yup.string()
  		  .required('Trip Name is required!')
  		   .max(20, 'Trip name is too long. Maximum of 20 characters.'),
		  startDate: Yup.date(),
		  endDate: Yup.date().when('startDate', (st, schema) => {
		    	return Yup.date().min(st, "End Date must be after Start Date");
			})
		})
 
		return <Mutation mutation={CREATE_TRIP}
			>
	      	{(createTrip, { data, loading, error }) => {
	      		return <Mutation mutation={CREATE_TRIP_USERS}
					refetchQueries={[{query: GET_MY_TRIPS}]}
				>
			      	{(createTripUsers, { data, loading, error }) => {
				        return <TripForm
							createTripUsers={createTripUsers}
							currentUser={currentUser}
							error={error}
							handleAddLocation={this.handleAddLocation}
							handleClose={handleClose}
							handleLocationChange={this.handleLocationChange}
							handleRemoveLocation={this.handleRemoveLocations}
							handleSubmit={this.handleCreateTrip}
							handleToggle={this.handleToggleVoteNameViewable}
							handleToggleTooltip={this.handleToggleTooltip}
							initialValues={initialValues}
							locations={locations}
							locationValue={locationValue}
							mutationFunction={createTrip}
							renderLocations={this.renderLocations}
							renderUsers={this.renderUsers}
							showTooltip={showTooltip}
							tooltipType={tooltipType}
							validationSchema={tripSchema}
							voteNameViewable={voteNameViewable}
							view="createTrip"
						/>
					}}
				</Mutation>

			}}
		</Mutation>	
	}

	renderUsers(){
		const { currentUser } = this.props
		const {  showUsers, userIds, users, userValue, } = this.state
		return <Query query={GET_FOLLOWERS_ALPHABETIZED} variables={{profUserId: currentUser.id}}>
			{({ loading, error, data }) => {
				if (loading) {return <div> Loading... </div>}
				if (error) {return <div>Error</div>}
				if (data.followersAlphabetized.nodes.length === 0) {return <div>You currently have no one following you</div>}
				const followers = data.followersAlphabetized.nodes
			
				return <CreateTripUsers 
						followers={followers}
						handleFocus={this.handleShowUsers}
						handleBlur={this.handleHideUsers}
						handleChange={this.handleUserChange}
						handleRemoveUser={this.handleRemoveUser}
						handleSelect={this.handleAddUser}
						showUsers={showUsers}
						userIds={userIds}
						users={users}
						userValue={userValue}
						view="createTrip"
					/>
			}}
			</Query>	

	}

	render() {
		
		return (
			<div className="createTripContainer">
				{this.renderBasicTripInfo()}
	
				
			</div>
				
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(CreateTripContainer);
