import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment"

import { Button } from 'reactstrap'

import "../stylesheets/components/Comments.css"

import CommentForm from "./CommentForm"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"

const Comments = props => {
	const {
		comments, 
		createMutationFunction,
		currentUser,
		editId, 
		handleCancel, 
		handleDelete, 
		handleEdit,
		handleHideReviewComments, 
		handleShowReviewComments,
		handleSubmit,
		handleSubmitEdit,
		initialValues,
		showAllComments,
		showEdit,
		updateMutationFunction, 
		placeholder, 
		reviewId, 
		validationSchema,
		view
	} = props


	return (
		<div className="comments">
				{(view === 'trip' || view === 'tripLocation') && <div className="headerHolder fullHeaderHolder">
				<div className="subHeader">comments</div>
				<div className="headerUnderline subHeaderUnderline"></div>
			</div>}
				{!!currentUser.id ? <CommentForm
					mutationFunction={createMutationFunction}
					initialValues={initialValues}
					handleSubmit={handleSubmit}
					placeholder={placeholder}
					validationSchema={validationSchema}
				/> : <div className="fontSmall loginMessage">You must be logged in to comment</div>}
			<div className="commentsContent fontSmall">
		{/*removing elements from multidimensional arrays is not supported therefore cannot remove null in index 0 if no comments*/}
				{comments[0] !== null && comments.map((comment, index) => {
					let commentId; 
					if (view === 'trip'){
						commentId = comment.tripId
					} else if (view === 'tripLocation'){
						commentId = comment.tripLocId
					} else if (view === 'picture'){
						commentId = comment.picId
					} else if (view === 'overallAttraction'){
						commentId = comment.id
					} else {
						commentId = comment.attrId
					}

					console.log('comments', comments, commentId)
					if (view !== 'overallAttraction' || index === 0 || showAllComments) {
						return <div key={commentId}>
							{(showEdit && editId === commentId) ? <div>
								<div className="commentHeader">
									<div className="textLink fontLarge">{(comment.firstName + ' ' + comment.lastName)}</div>
									<div className="createdAt">{moment.utc(comment.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
									{comment.edited && <div className="edited">Edited</div>}
								</div>
								<CommentForm
									initialValues={{comment: comment.comment}}
									isEditing={true}
									handleCancel={handleCancel}
									handleSubmit={handleSubmitEdit}
									mutationFunction={updateMutationFunction}
									placeholder={placeholder}
									validationSchema={validationSchema}
								/> 
								
							</div> : 
								<div>
								{view === 'picture' ? <div>
									<div className="commentsHeaderTop">
										<div className="textLink fontLarge">{(comment.firstName + ' ' + comment.lastName)}</div>
										{!!currentUser.id && <div>
										{currentUser.id === comment.userId ? <div className="commentIcons">
													<i className={`material-icons activeIcon iconSmall`} onClick={() => handleEdit(commentId)}>edit</i>
													<i className={`material-icons activeIcon iconSmall`} onClick={() => handleDelete(commentId, 'comment')}>delete</i>
											</div> : <ReportObjectionableContainer
												reportItem={comment}
												reportType='LOCATION_PICTURE_COMMENT'
												reportTypeRender="comment"
											 />}
										 </div>
										}
									</div>
									<div className="commentsHeaderBottom">
										{comment.edited && <div className="edited">Edited</div>}
										<div className="createdAt">{moment.utc(comment.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
										
									</div>
								</div>


								: <div className="commentHeader">
									<div className="textLink">{(comment.firstName + ' ' + comment.lastName)}</div>
									<div className="createdAt">{moment.utc(comment.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
									{comment.edited && <div className="edited">Edited</div>}
									{currentUser.id && <div>
										{currentUser.id === comment.userId ? <div className="commentIcons">
												<i className={`material-icons activeIcon iconSmall`} onClick={() => handleEdit(commentId)}>edit</i>
												<i className={`material-icons activeIcon iconSmall`} onClick={() => handleDelete(commentId, 'comment')}>delete</i>
										</div> : <ReportObjectionableContainer
											reportItem={comment}
											reportType='ATTRACTION_COMMENT'
											reportTypeRender="comment"
										 />}
									 </div>
									}
									
								</div>}
								{comment.objectionable ? 
									<div>
										{comment.objectionable === 'CONFIRMED' ? 
					             		<div className="mainBackground">This comment has been flagged.</div> : 
					             		<div className="mainBackground">This comment has been reported and is under review.</div>}
								             	
									</div>
								: <div className="secondaryBackground">{comment.comment}</div>} 
							</div>}
							
						</div>
					}
				})}
				{view === 'overallAttraction' && comments.length > 1 && <div>{showAllComments ? <div className="textLink" onClick={() => handleHideReviewComments()}>Show Less</div> : <div className="textLink" onClick={() => handleShowReviewComments(reviewId)}>Show More</div>}</div>}
			</div>

		</div>
	);
};

export default Comments;
