import React from "react";


const SaveCallout = props => {
	const { 
		handleHideCallout
		} = props;
	return (
		<div className="saveCallout popup popupLarge">
			<div className="popupContent">
				<div className="secondaryHeader">Wanna save reviews for later?</div>
				<div className="marginBottom centerText">Click the bookmark icon. Your saves will be stored in your saved section.</div>
				<img 
					className="boxPic"
				 	src={require('../assets/SaveExample.png')} 
				 	alt="saveExample"/>

			

				<div className="marginBottom">Saves are anonymous</div>
				
				<button className="primaryButton singleFormButton" onClick={() => handleHideCallout()}>Got it</button>
		
			</div>
		</div>

		
	);
};

export default SaveCallout;
