import React from "react";
import ReadMoreAndLess from 'react-read-more-less';

import "../stylesheets/components/LocationHeader.css"

import FollowButtonContainer from "../containers/FollowButtonContainer"
import DatesContainer from "../containers/DatesContainer"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"

const LocationHeader = props => {
	const {
		blocked,
		currentUser,
		handleBack,
		handleShowAddAttraction,
		handleShowCreateMessage,
		handleShowDelete,
		handleShowEdit,
		handleShowUpload,
		handleViewProfile,
		lastLocation,
		location, 
		user
	} = props;

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}

	const getCharLimit = () => {
		const idx = location.overallReview.split('').findIndex(char => {
			return char === '\n'
		})
		if (idx > 1){
           return idx + 1
		} else if (idx !== -1) {
			return 2
		} else {
			return 120
		}
	}
	return (
		<div className="locationHeader">
			{/*lastLocation && <div className="backIcon" onClick={() => handleBack()}>Back</div>*/}
			<div className="locHeaderMain">
				<div className="locHeaderLeft">
				<img className="picLarge" src={location.publicUrl} alt="locationHeaderPicture"/>

				<div className="locHeaderContent">

					<div className="headerHolder fullHeaderHolder partialHeaderHolder">
						<div className="mainHeader">{getLocName(location)}</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
	
					<div className="secondaryHeader textLink" onClick={()=> handleViewProfile(location.userId)}> {(location.firstName + " " + location.lastName)}</div>		
					{location.overallReview && <div className="userInputText">
					{location.objectionable ?
				             	<div>{location.objectionable === 'CONFIRMED' ? 
				             		<div>This overall review has been flagged.</div> : 
				             		<div>This overall review has been reported and is under review.</div>}
				             	</div> 
				        : <ReadMoreAndLess
		               		 className="textLink"
			                charLimit={getCharLimit()}
			                readMoreText="Read more"
			                readLessText="Read less"
			            >
		                {location.overallReview}
			           		 </ReadMoreAndLess>}
			            {currentUser.id > 0 && currentUser.id !== location.userId && <div>
							<ReportObjectionableContainer 
								reportItem={location}
								reportType='LOCATION'
								reportTypeRender="location overall review"
							/>
						</div>}
					</div>}
		            
				            
				
					
					
					{currentUser.id === location.userId
						?
								<div className="icons">
								
			 						<i className={'material-icons activeIcon iconMedium locJoyride1'} onClick={() => handleShowEdit(location.overallReview)}>edit</i>
			 						<i className={'material-icons activeIcon iconMedium locJoyride2'} onClick={() => handleShowDelete(location)}>delete</i>
			 						
					
								</div>


					: 
						<div className="icons">
							<FollowButtonContainer 
								currentUser={currentUser}
								profUserId={location.userId}
								profUser={user}
								view="location"
							/>
							{currentUser.id > 0 && !blocked &&
								<i className={'material-icons activeIcon iconMedium'} onClick={()=> handleShowCreateMessage()}>message</i>}
							
						</div>
					}
					</div>
				</div>
				<div className="locHeaderRight">

					{/*<DatesContainer
						dates={location.dates}
						dateType="locationDate"
						locationUserId={location.userId}
						parentId={location.id}
						parentType="location"
						title="VISIT DATES"
					/>*/}
				</div>
					
			
			</div>
			

		</div>
	);
};

export default LocationHeader;
