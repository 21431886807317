import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { Button } from 'reactstrap'
import "../stylesheets/components/CreateMessageForm.css"

import MessageForm from "./MessageForm"

const CreateMessageForm = props => {
	const {
		createNewMessage,
		handleClose,
		handleSubmit,
		error,
		initialValues, 
		following,
		handleChange,
		handleFocus,
		handleRemoveUser,
		handleSelect,
		location,
		receiver,
		recipient,
		sent,
		showUsers,
		stateError, 
		userValue, 
		validationSchema, 
		view
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}



	return (
		<div className="createMessageForm popup popupLarge">
			<div className="closeIconHolder">
								<i className="material-icons activeIcon iconMedium" onClick={() => handleClose()}>cancel</i>
			</div> 
			{!sent ? <div className="popupContent">
				<div className="createMessageHeader">
					{recipient ? <div className="userInfo">
						<img className="picSmall" src={recipient.publicUrl} alt="profPic"/>
						<div className="userName">{(recipient.firstName + ' ' + recipient.lastName)}</div>
					</div> : 
					<div className="createMessageUsers">
						<div className="textTitle">Add Recipient</div>
						{view === 'allMessages' && <div className="fontXS">To send a message to someone who doesn't follow you, go to their profile or one of their location pages</div>}
						<div>
							{receiver && <div className="userInfo" key={receiver.followeeId}>
								<img className="picSmall" src={receiver.publicUrl} alt="profPic"/>
								<div className="userName">{(receiver.firstName + ' ' + receiver.lastName)}</div>
								<div onClick={() => handleRemoveUser(receiver.id)} className="removeIcon">-</div>
							</div>
							}</div>
						<div>
							<input 
								className="createMessageUserInput" 
								onFocus={() => handleFocus()} 
								onChange={e => handleChange(e)} 
								type="text" 
								name="userSearch" 
								value={userValue}
								onKeyDown={(e) => {
					                if (e.key === 'Enter') {
					                  e.preventDefault()
					                }
					              }}
								autoComplete="off"
							/>
							{showUsers && <div className="mainBackground selectDropdown">{following.length ? following.map(user => {
								if ((user.firstName.toLowerCase().startsWith(userValue.toLowerCase()) 
									|| user.lastName.toLowerCase().startsWith(userValue.toLowerCase()))
									&& (receiver && receiver.followeeId !== user.followeeId || !receiver)){
											return <div className="userInfo" onClick={() => handleSelect(user)} key={user.followeeId}>
												<img className="picSmall" src={user.publicUrl} alt="profPic"/>
												<div className="userName">{(user.firstName + ' ' + user.lastName)}</div>
											</div>			
								}
							}) : <div>You are not following anyone. To send a message to a user you're not following, create a message on their profile</div>}</div>}
						</div>
					</div>}
					{location && <div className="locationTag">{getLocName(location)}</div>}
				</div>

				<MessageForm 
					createNewMessage={createNewMessage}
					error={error}
					handleSubmit={handleSubmit}
					initialValues={initialValues}
					stateError={stateError}
					validationSchema={validationSchema}
				/>
			</div> :
			<div>Sent!</div>}

			
			

		</div>
	);
};

export default CreateMessageForm;
