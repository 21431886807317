import React from "react";
import { compose, graphql, Query, withApollo } from "react-apollo";
import { analytics } from '../index.js';

import history from "../history"


import { getCurrentUser, getPopup, getMessenger } from "../apollo/state/queries";
import { setMessengerId } from "../apollo/state/mutations"
import { GET_ALL_USER_MESSAGES, GET_UNSEEN_MESSAGES} from "../apollo/db/queries"
import { UPDATE_MESSAGES, UPDATE_MESSAGES_READ } from "../apollo/db/mutations"

import AllMessagesHeader from "../components/AllMessagesHeader"
import AllMessages from "../components/AllMessages";
import CreateMessageContainer from "./CreateMessageContainer"
import UserMessagesContainer from "./UserMessagesContainer"

class AllMessagesContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messengerId: null
		}

		this.handleGoToMessages = this.handleGoToMessages.bind(this)
		this.handleViewMessage = this.handleViewMessage.bind(this)
		this.handleHideCreateMessage = this.handleHideCreateMessage.bind(this)
		this.handleShowCreateMessage = this.handleShowCreateMessage.bind(this)

	}

	async componentDidMount(){
		const { client, messenger } = this.props
		const { messengerId } = this.state
		const res = await client.query({
			query: GET_UNSEEN_MESSAGES
		})
		const unseenMessages = res.data.unseenMessages
		if (unseenMessages){
			await client.mutate({
				mutation: UPDATE_MESSAGES,
				variables: {input: {}}, 
				refetchQueries: [{query: GET_UNSEEN_MESSAGES}]
			})
		}
		if (messenger.id !== messengerId){
			this.setState({messengerId: messenger.id})
		}
	}

	async componentDidUpdate(){
		const {  messenger } = this.props
		const { messengerId } = this.state
		if (messenger.id !== messengerId){
				console.log('mes', messenger)
			this.setState({messengerId: messenger.id})
		}
	}

	async handleViewMessage(message){
		const { handleClose } = this.state
		const { client, setMessengerId, view } = this.props
			try {
				if (!message.read){
					
					await client.mutate({
					   	 	mutation: UPDATE_MESSAGES_READ, 
					   	 	variables: {input: {messengerId: message.messengerId}}
				 	})
				 	await client.query({
					   	 	query: GET_ALL_USER_MESSAGES, 
					   	 	fetchPolicy: 'network-only'
				 	})
				}
				
			} catch {
				console.log('error')
			}

			await setMessengerId({
	                variables: { 
	                  id: message.messengerId
	                }
	            })
			if (view === 'navBar'){
	        	this.handleGoToMessages()
			}


	}

	async handleGoToMessages(){
		const { handleClose } = this.props
		if (handleClose){
			await handleClose()
		}
		history.push('/messages')
	}

	async handleShowCreateMessage(){
		await this.setState({showCreateMessage: true})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('show_create_message');
		}
	}

	handleHideCreateMessage(){
		this.setState({showCreateMessage: false})
	}

	renderHeader(){
		const { view } = this.props

		return <AllMessagesHeader
			handleGoToMessages={this.handleGoToMessages}
			handleShowCreateMessage={this.handleShowCreateMessage} 
			view={view}
		/>
	}

	renderCreateMessage(){
		return <CreateMessageContainer
			handleClose={this.handleHideCreateMessage}
			view="allMessages"
		 />
	}

	renderMessages(){
		const { currentUser } = this.props
		return <Query query={GET_ALL_USER_MESSAGES} fetchPolicy='network-only'>
			{({ loading, error, data }) => {

			if (loading) return <div>Loading...</div>;
			if (error) return <div>Error</div>;
			const allMessages = data.allUserMessages.nodes
				return <AllMessages 
					currentUser={currentUser}
					handleViewMessage={this.handleViewMessage} 
					messages={allMessages}
				/>
			}}
		</Query>

	}

	renderUserMessages(){
		const { messengerId } = this.state
		const { currentUser } = this.props
		console.log('messengerId', messengerId)
		return <UserMessagesContainer
			currentUser={currentUser} 
			messengerId={messengerId}
		/>
	}

	render() {
		const { currentUser, screenView, view } = this.props
		const { messengerId, showCreateMessage } = this.state
		 const popupClass = screenView.popup && "disabledBackground"
		
		return (
			<div className={`allMessagesContainer ${popupClass}`}>
				{currentUser.id ?
					<div>
						{this.renderHeader()}
						{showCreateMessage && this.renderCreateMessage()}
						<div className={messengerId && view !== 'navBar' ? "messagesContent allAndUserMessages" : "messagesContent"}>
							{this.renderMessages()}
						

							{(messengerId && view !== 'navBar') && 
								<div className="allMessagesRight">
									{this.renderUserMessages()}
								</div>}
						</div>
					</div> : 
						
					<div>You must login to see this page.</div>
				}
			</div>
		);
	}
}

export default compose(
	withApollo, 
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}),
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(getMessenger, {
		props: ({ loading, data: { messenger } }) => ({ 
			loading,
			messenger
		})
	}), 
	graphql(setMessengerId, {name: 'setMessengerId'}),
)(AllMessagesContainer);
