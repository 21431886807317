import React from "react";

import "../stylesheets/components/TripUsers.css"
import { Button } from 'reactstrap'

const TripUsers = props => {
	const {
		creatorId,
		currentUser,
		deleteTripUser,
		isEditingUsers, 
		handleGoToProfile,
		handleShowDelete,
		handleShowAddTripUsers,
		handleToggleIsEditingUsers,
		tripUsers
	} = props

	
	return (
		<div className="tripUsers">
			<div className="tripUsersHeader">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">users</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				<div className="tripUsersButtons">
					{isEditingUsers ? <Button className="cancelEdit" onClick={() => handleToggleIsEditingUsers()}>Cancel</Button> : <img 
						className="iconMedium activeIcon" 
						onClick={() => handleToggleIsEditingUsers()}
						src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
						alt="editIcon"
					/>}
					<img 
						className="iconMedium plusIcon" 
						onClick={() => handleShowAddTripUsers()}
						src="https://storage.googleapis.com/trvlpage-bucket/plus.svg"
						alt='plusIcon'
					/>
				</div>
			</div>
			<div className="mainBackground">
			{tripUsers.map(user => {
				return <div className="tripUserInfo" key={user.id}>
					<img className="picSmall" src={user.publicUrl} alt="profPic"/>
					<div className="textLink tripUserName" onClick={() => handleGoToProfile(user.id)}>{(user.firstName + " " + user.lastName)}</div>
					{creatorId === user.id && <div className="fontSmall">creator</div>}
					{(isEditingUsers && (creatorId !== user.id || creatorId === currentUser.id)) && <div className="removeIcon" onClick={() => handleShowDelete(user)}>-</div> }
				</div>
			})}
			</div>
			
		</div>
	);
};

export default TripUsers;
