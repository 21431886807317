import React from "react";

const PrivacyPolicy = props => {
		return (
			<div classname="privacyPolicy">
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontSize: '19px', fontFamily: '"Times New Roman",serif'}}>Privacy Policy</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', background: 'white'}}><strong><span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Information Security</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', background: 'white'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>We have implemented reasonable administrative, technical, and physical security procedures to help protect your information.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', background: 'white'}}><strong><span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Do Not Track Signals</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', background: 'white'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Our website and app are not designed to currently respond to “Do Not Track” (“DNT”) signal requests from browsers. Once the industry has settled on standards related to this issue, we may re-evaluate this approach.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Cookie Policy</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use cookies if you use Trvlpage (Product), including our website and app. Cookies enable Trvpage to offer the Product to you and to understand the information we receive about you, including information about your use the website and app, whether or not you are registered or logged in.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', background: 'white'}}>If you delete your cookies, your access to some functionality and areas of our Services might be degraded or restricted.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Authentication</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use cookies to verify your account and determine when you’re logged in so we can make it easier for you to access the Trvlpage Product and show you the appropriate experience and features. For example: We use cookies to keep you logged in as you navigate between Trvlpage pages. Cookies also help us remember your browser, so you do not have to keep logging into Trvlpage.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Security, site and product integrity</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use cookies to help us keep your account, data and the Trvlpage Product safe and secure. For example: Cookies help us ensure it is you adding a location to your profile or editing a review. We also use cookies to combat activity that violates our policies or otherwise degrades our ability to provide the Trvlpage Product.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We may place cookies on your computer or device, and receive information stored in cookies, when you use or visit the Trvlpage app or website.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Data Policy</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>To provide the Trvlpage Product, we must process information about you. The types of information we collect depend on how you use our Product. To request deletion of information, please email&nbsp;</span>contact@trvlpage.com</p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;<strong>Things you and others do and provide.</strong>&nbsp;</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Times'}}>Information and content you provide. We collect the content, communications and other information you provide when you use our Product, including when you sign up for an account, create content, and message or communicate with others. This can include information in or about the content you provide. Our systems automatically process content and communications you and others provide to analyze context and what's in them for the purposes described below.</span></li>
          <li><span style={{fontFamily: 'Times'}}>Networks and connections<strong>.&nbsp;</strong>We collect information about the people you are connected to and how you interact with them across our Product, such as people you communicate with the most.</span></li>
          <li><span style={{fontFamily: 'Times'}}>Your usage.<strong>&nbsp;</strong>We collect information about how you use our Product, such as the types of content you view or engage with; the features you use; the actions you take; the people or accounts you interact with; and the time, frequency and duration of your activities. For example, we log when you're using and have last used our Product and what content you view on our Product.</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Device Information.&nbsp;</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>As described below, we collect information from and about the computers, phones and other web-connected devices you use that integrate with our Product. Information we obtain from these devices includes:&nbsp;</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Times'}}>Device attributes: information such as the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Device operations: information about operations and behaviors performed on the device, such as whether a window is foregrounded or backgrounded, or mouse movements (which can help distinguish humans from bots).&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Device signals: Bluetooth signals, and information about nearby Wi-Fi access points, beacons, and cell towers. Data from device settings: information you allow us to receive through device settings you turn on, such as access to your GPS location, camera or photos.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Network and connections: information such as the name of your mobile operator or ISP, language, time zone, mobile phone number, IP address, connection speed and, in some cases, information about other devices that are nearby or on your network, so we can do things like help you stream a video from your phone to your TV.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Cookie data: data from cookies stored on your device, including cookie IDs and settings. Learn more about how we use cookies in the Facebook Cookies Policy and Instagram Cookies Policy.</span></li>
        </ul>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use the information we have as described below and to provide and support the Trvlpage Product. Here's how:</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Provide, personalize and improve our Products.</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use the information we have to deliver our Product, including to personalize features and content (including your Explore page and search results and make suggestions for people to follow). To create personalized Products that are unique and relevant to you, we use your connections and activities based on the data we collect and learn from you and others (including any data with special protections you choose to provide); how you use and interact with our Product; and the people, places, or things you're connected to and interested in on our Product.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Promote safety, integrity and security.</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use the information we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the integrity of our Product, and promote safety and security on the Trvlpage Product. For example, we use data we have to investigate suspicious activity or violations of our terms or policies, or to detect when someone needs help.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Communicate with you.</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We use the information we have to communicate with you about our Product, and let you know about our policies and terms. We also use your information to respond to you when you contact us.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>&nbsp;</p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>Date of Last Revision: July 1, 2020</span></p>
      </div>
		);
};

export default PrivacyPolicy;
