import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Collapse, CardBody, Card } from 'reactstrap';

import "../stylesheets/components/Dates.css"


const Dates = (props) => {
	const { 
		currentUser,
		dates,
		dateType,
		handleShowAdd, 
		handleShowDelete,
		handleShowEdit,
		handleToggleAllDates, 
		handleToggleIsEditing, 
		isEditing,
		isEditingTripLoc,
		locationUserId,
		showAllDates,
		title, 
		view
	} = props

	return (
		<div className="dates">
			<div className="headerAndIcons">
				{title && <div className="textTitle">{title}</div>}
				{((dateType !== 'locationDate' || currentUser.id === locationUserId) && view !== 'trip') &&
				 <div className="datesIconSection">
					{/*(!dates || !dates.length || dates[0] === null) && <div>Dates</div>*/}
					<div className="icons">
						 
						{view !== 'trip' && dates && dates[0] !== null && (isEditing ? <button className="cancelEdit" onClick={() => handleToggleIsEditing()}>Cancel</button> : 
							<div className="datesEditButtons">
							<i className={'material-icons activeIcon iconSmall'} onClick={() => handleToggleIsEditing()}>edit</i>

							
						</div>)}
						<i className={'material-icons activeIcon iconSmall'} onClick={() => handleShowAdd()}>add_circle</i>

					</div>
				</div>}
			</div> 
			<div className="datesContent">
				<div className="dates">
					{(dates && dates[0] !== null) ? dates.map((date, index) => {
						if (index < 2){
							return <div key={date.id} className="date">
								{(isEditing || isEditingTripLoc) && <div className="icons">	
									<i className={'material-icons activeIcon iconSmall'} onClick={() => handleShowEdit(date.id, date.startDate, date.endDate)}>edit</i>
									<i className={'material-icons activeIcon iconSmall'} onClick={() => handleShowDelete(date.id)}>delete</i>
 
								</div>}
						
							
								{date.startDate && <div className="fontSmall dateContent">{moment(date.startDate).format('MMMM Do YYYY, h:mm:ss a').slice(0, -13)}</div>}
								{(date.startDate || date.endDate) && <div className="dash">-</div>} 
								{date.endDate && <div className="fontSmall dateContent">{moment(date.endDate).format('MMMM Do YYYY, h:mm:ss a').slice(0, -13)}</div>}	
							</div>	
						}
						
					}) : 
					<div className="fontSmall">None Added</div>}
					{dates && dates.length > 2 && <Collapse isOpen={showAllDates}>
						{dates.map((date, index) => {
							if (index >= 2){
								return <div key={date.id} className="date">
									{(isEditing || isEditingTripLoc) && <div className="icons">		
										<i className={'material-icons activeIcon iconSmall'} onClick={() => handleShowEdit(date.id, date.startDate, date.endDate)}>edit</i>
										<i className={'material-icons activeIcon iconSmall'} onClick={() => handleShowDelete(date.id)}>delete</i>

									</div>}
							
								
									{date.startDate && <div className="fontSmall dateContent">{moment(date.startDate).format('MMMM Do YYYY, h:mm:ss a').slice(0, -13)}</div>}
									{(date.startDate || date.endDate) && <div className="dash">-</div>} 
									{date.endDate && <div className="fontSmall dateContent">{moment(date.endDate).format('MMMM Do YYYY, h:mm:ss a').slice(0, -13)}</div>}	
								</div>	
							}
						})}
					</Collapse>}
					{dates && dates.length > 2 && <div>
						{showAllDates ?
						 <div className="textLink fontXS" onClick={handleToggleAllDates}>Show Less</div>
						 : <div className="textLink fontXS" onClick={handleToggleAllDates}>Show All Dates</div>}
					</div>}
				</div>
				{view === 'trip' &&
				<i className={'material-icons activeIcon iconSmall'} onClick={() => handleShowAdd()}>add_circle</i>}
			</div>
		</div>
	);
};

export default Dates;
