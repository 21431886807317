import React from "react";

import "../stylesheets/components/CreateAttractionTags.css"

import { Button } from 'reactstrap'
import { availableTags } from "../utilities"

const CreateAttractionTags = props => {
	const { 
		handleChange,
		handleFocus,
		handleRemoveTag,
		handleSelectTag,
		handleToggleTooltip,
		showTags,
		showTooltip,
		selectedTags, 
		tagValue, 
		tooltipType
	} = props


	return (<div className="createAttractionTags">
		
		<div className="attractionTags">{selectedTags.map(selectedTag => {
			return <div className="attractionTagHolder" key={selectedTag}>
				<div className="attractionTagSmall">{selectedTag}</div>
				<div onClick={() => handleRemoveTag(selectedTag)} className="removeIcon">-</div>
			</div>
		})}</div>
		<div className="createAttractionTagsContent">
			<div>
				<div className="labelAndTooltip">
		          	<div className="textTitle textTitleForm">tags</div>
					{/*<div className="tooltipIcon" onClick={() => handleToggleTooltip('tags')}>?</div>*/}
				</div>
				{showTooltip && tooltipType === 'tags' && <div className='tooltip'>Key words that describe this place that users can use as filters while searching.</div>}
			</div>
			<div>
				<input 
					className="formField" 
					onFocus={() => handleFocus()} 
					onChange={e => handleChange(e)} 
					type="text" 
					name="tagSearch" 
					value={tagValue}
					onKeyDown={(e) => {
		                if (e.key === 'Enter') {
		                  e.preventDefault();
		                }
		              }}
				/>
				{showTags && <div className="mainBackground selectDropdown">{Object.keys(availableTags).map(tag => {
					if (tag.toLowerCase().startsWith(tagValue.toLowerCase()) && !selectedTags.includes(tag)){
						return <div className="textLink" onClick={() => handleSelectTag(tag)} key={tag}>
							<div>{availableTags[tag]}</div>
						</div>			
					}
				})}</div>}
			</div>
			
		</div>
	</div>
		
	);
};

export default CreateAttractionTags;