import React from "react";
import { Mutation, compose, graphql } from "react-apollo";
import { GoogleApiWrapper } from "google-maps-react";
import { analytics } from '../index.js';

import Autocomplete from "../components/Autocomplete"

import { getCurrentUser } from "../apollo/state/queries"
import { CREATE_ATTRACTION, UPDATE_ATTRACTION } from "../apollo/db/mutations"
import { GET_LOCATION_ATTRACTIONS } from "../apollo/db/queries"



class AddAttractionContainer extends React.Component {
	constructor() {
		super();
		this.state = {
		      loading: false, 
		      newAttractionType: 'ACTIVITY', 
		      stateError: null, 
		      value: ''
		    };
		       
    		this.handleCreateAttraction = this.handleCreateAttraction.bind(this)
    		this.handleSelectAttractionType = this.handleSelectAttractionType.bind(this)
  
	}


	handleSelectAttractionType(type){
		this.setState({newAttractionType: type})
	}



	async handleCreateAttraction(createAttraction, selected, setSubmitting) {
		const { attractions, currentUser, handleSetAddedAttraction, locationId, addAttractionType } = this.props
		const { newAttractionType } = this.state
		setSubmitting(true)
		const google = this.props.google;
		//event.preventDefault();

	
		if (!selected || !selected.place_id) {
			this.setState({stateError: 'Please select an attraction'})
			return null;
		}
		
		const placeIds = attractions.map(attraction => {
			return attraction.placeId
		})

		if (!placeIds.includes(selected.place_id)){
			this.setState({loading: true})

			const localityObj = selected.address_components.find(component => {
				return component.types.includes('locality')
			})
			//locality and country should always exist (this is just an extra protective layer in case it doesn't)
			const locality = localityObj ? localityObj.long_name : null
			const adminLevel1Obj = selected.address_components.find(component => {
				return component.types.includes('administrative_area_level_1')
			})
			const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
			const countryObj = selected.address_components.find(component => {
				return component.types.includes('country')
			})
			const country = countryObj ?countryObj.long_name : null

			let dollars, website;

			const request = {
				  placeId: selected.place_id,
				  fields: ['price_level', 'website']
				};


		   const service = new google.maps.places.PlacesService(document.getElementById('addAttractionMount'));

			await service.getDetails(request, handleDetails);
			
			async function handleDetails(place, status){
				 if (status === google.maps.places.PlacesServiceStatus.OK) {
				 	console.log('place', place)
				  	dollars = place.price_level
				  	website = place.website
				  	try {
				  		const res = await createAttraction({
						variables: {
								input: {
									
										locId: locationId, 
									    attrAttractionType: addAttractionType ? addAttractionType : newAttractionType, 
									    attrName: selected.name,
									    attrPlaceId: selected.place_id,
									    attrLocality: locality, 
									    attrAdminLevel1: adminLevel1, 
									    attrCountry: country,
									    attrLat: selected.geometry.location.lat(),
									    attrLng: selected.geometry.location.lng(), 
									    attrDollars: dollars, 
									    attrWebsite: website
									  
									}	
								}
							})
			
						const attrId = res.data.createAttractionCustom.integer
						console.log('attrId', attrId)
						handleSetAddedAttraction({id: attrId, attractionType: addAttractionType ? addAttractionType : newAttractionType })
						setSubmitting(false)

						if (process.env.NODE_ENV !== 'development'){
							await analytics.logEvent('add_attraction', {
							  place: selected
							});
							await analytics.logEvent('add_attr_process', {
							  page: 'add', 
							  action: 'complete'
							});	

						}
						
					} catch(error) {
						setSubmitting(false)
				  		console.log('error', error)
				  	}

				} else {
					try {
						const res = await createAttraction({
							variables: {
								input: {
									
										locId: locationId, 
									    attrAttractionType: addAttractionType ? addAttractionType : newAttractionType, 
									    attrName: selected.name,
									    attrPlaceId: selected.place_id,
									    attrLocality: locality, 
									    attrAdminLevel1: adminLevel1, 
									    attrCountry: country,
									    attrLat: selected.geometry.location.lat(),
									    attrLng: selected.geometry.location.lng(), 
									    attrDollars: dollars, 
									    attrWebsite: website
									  
									}	
								}
							})
				
							const attrId = res.data.createAttractionCustom.integer
							console.log('attrId', attrId)

							handleSetAddedAttraction({id: attrId, attractionType: addAttractionType ? addAttractionType : newAttractionType })
							setSubmitting(false)
							if (process.env.NODE_ENV !== 'development'){
								await analytics.logEvent('add_attraction', {
								  place: selected
								});

								await analytics.logEvent('add_attr_process', {
								  page: 'add', 
								  action: 'complete'
								});	

							}

					} catch(error) {
						setSubmitting(false)
						console.log('error', error)
					}			
				} 
				  	
			}

		} else {
			setSubmitting(false)
			this.setState({stateError: "You have already added this attraction"})
		}

	}

	renderAddAttraction(){
		const { currentUser, handleClose, locationId, locationLat, locationLng, view } = this.props
		const { stateError } = this.state

		const currentUserId = currentUser.id ? currentUser.id : 0

		return (<Mutation
		      mutation={CREATE_ATTRACTION}
		      refetchQueries={[{query: GET_LOCATION_ATTRACTIONS, variables:{locId: locationId, currentUserId: currentUserId}}]}
		    >
	      {(createAttraction, { data, loading, error }) => {
	      return 	(
	      	<div className="addAttraction popup popupLarge">
				<Autocomplete
				cancelText="Cancel"
				error={error}
				id="add-attraction" 
				handleSelectAttractionType={this.handleSelectAttractionType}
				handleSubmit={this.handleCreateAttraction}
				handleClose={handleClose}
				locationLat={locationLat}
				locationLng={locationLng}
				mutationFunction={createAttraction}
				name="Add Activity, Food & Drinks, or Lodging"
				popup={true}
				stateError={stateError}
				type="attraction"
				value={this.state.value} 
				view={view}
	     		 />
			
     		 </div>
		    )}}
		</Mutation>)
	}

	
	render() {
		const { addAttraction } = this.state
		
		return (
			<div className="addAttractionContainer">
			

			{this.renderAddAttraction()}
			<div id="addAttractionMount"></div>

				
			</div>
		);
	}
}

export default compose(
graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	GoogleApiWrapper({
			apiKey: process.env.REACT_APP_GOOGLE_API_KEY
	})
	)(AddAttractionContainer);

