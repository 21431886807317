import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import "../stylesheets/components/TripHeader.css"

const TripHeader = props => {
	const { 
		handleShowEditTrip,
		trip
	} = props
	return (
		<div className="tripHeader">	
			<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">{trip.name}</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
			<div className="tripDates">
				{trip.startDate && <div>{moment(trip.startDate).format('MMMM Do YYYY, h:mm:ss a').slice(0, -13)} </div>}
				{(trip.startDate || trip.endDate) && <div>-</div>} 
				{trip.endDate && <div>{moment(trip.endDate).format('MMMM Do YYYY, h:mm:ss a').slice(0, -13)}</div>}
			</div>
			<div className="tripHeaderButtons">
				<Link className="primaryButton" to={`/tripItinerary/${trip.id}`}>Trip Itinerary</Link>
			
				<img 
					className="iconMedium activeIcon" 
					onClick={() => handleShowEditTrip()}
					src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
					alt="editIcon"
				/>
			</div>
		</div>
	);
};

export default TripHeader;
