import React from "react";

import "../stylesheets/components/SearchFilters.css"
import { availableTags } from "../utilities"

const SearchFilters = props => {
	const { 
		attrAndUp,
		attrContent,
		attrStars,
		destinationType,
		handleHover,
		handleSelectAttrContent,
		handleSelectStar,
		handleSelectTag,
		handleSetDestinationType,
		handleSetDestSearchValue,
		handleToggleAndUp,
		handleToggleFilters,
		hoverStars,  
		showMoreFilters,
		tags
	} = props;

	const getHeight = () => {
		return 
	}

	return (
		<div className={showMoreFilters ? "searchFilters" : "searchFilters searchFiltersCollapsed"}>
			<div className="filtersContent">
				<div className="filterSection">
					<div className="textTitle">Stars</div>
					<div className="filterStars">
						<div
						onMouseEnter={(e) => handleHover(e, 1)}
						onMouseLeave={(e) => handleHover(e, 0)}
						onClick={(e) => handleSelectStar(e, 1)}
						>
						{(hoverStars > 0 || attrStars > 0) ?
						<i className={'material-icons activeIcon iconSmall'}>star</i> : 
						<i className={'material-icons inactiveIcon iconSmall'}>star</i>
						} 
						</div>
						<div
						onMouseEnter={(e) => handleHover(e, 2)}
						onMouseLeave={(e) => handleHover(e, 0)}
						onClick={(e) => handleSelectStar(e, 2)}
						>
						{(hoverStars > 1 || attrStars > 1) ?
						<i className={'material-icons activeIcon iconSmall'}>star</i> : 
						<i className={'material-icons inactiveIcon iconSmall'}>star</i>
						} 
						</div>
						<div
						onMouseEnter={(e) => handleHover(e, 3)}
						onMouseLeave={(e) => handleHover(e, 0)}
						onClick={(e) => handleSelectStar(e, 3)}
						>
						{(hoverStars > 2 || attrStars > 2) ?
						<i className={'material-icons activeIcon iconSmall'}>star</i> : 
						<i className={'material-icons inactiveIcon iconSmall'}>star</i>
						}
						</div>
						<div
						onMouseEnter={(e) => handleHover(e, 4)}
						onMouseLeave={(e) => handleHover(e, 0)}
						onClick={(e) => handleSelectStar(e, 4)}
						> 
						{(hoverStars > 3 || attrStars > 3) ?
						<i className={'material-icons activeIcon iconSmall'}>star</i> : 
						<i className={'material-icons inactiveIcon iconSmall'}>star</i>
						} 
						</div>
						<div
						onMouseEnter={(e) => handleHover(e, 5)}
						onMouseLeave={(e) => handleHover(e, 0)}
						onClick={(e) => handleSelectStar(e, 5)}
						>
						{(hoverStars > 4 || attrStars > 4) ?
						<i className={'material-icons activeIcon iconSmall'}>star</i> : 
						<i className={'material-icons inactiveIcon iconSmall'}>star</i>
						}
						</div>
					</div>
					<div className="checkboxSection andUpSection">
					 	<input 
					 		type="checkbox" 
					 		value="attrAndUp" 
					 		className="checkboxMedium"
					 		checked={attrAndUp}
					 		onChange={(e) => handleToggleAndUp(e)}
					 	/>
					 	<label for="attrAndUp">And Up</label>
					</div>
					<div className="textLink fontSmall" onClick={(e) => handleSelectStar(e, 0)}>clear</div>
				</div>
				 <div>
					 <div className="textTitle tagTitle">Tags</div>
					 {showMoreFilters ? <div className="tagSection" style={{height: `${Object.keys(availableTags).length/3 * 1.6}em`}}>
						{Object.keys(availableTags).map((tag, index) => {
							if (index < 12 || showMoreFilters){
								return <div className="checkboxSection" key={tag}>
								<label className="checkboxLabel" htmlFor={tag}>{availableTags[tag]}</label>
								{tags.includes(tag) ? 
									<input type="checkbox" id={tag} name={tag} onClick={(e) => handleSelectTag(e, tag)} checked/> :
									<input type="checkbox" id={tag} name={tag} onClick={(e) => handleSelectTag(e, tag)}/>} 
								</div>
							}
							
						})} 
					</div> : <div className="tagSection">
						{Object.keys(availableTags).map((tag, index) => {
							if (index < 12 || showMoreFilters){
								return <div className="checkboxSection" key={tag}>
								<label className="checkboxLabel" htmlFor={tag}>{availableTags[tag]}</label>
								{tags.includes(tag) ? 
									<input type="checkbox" id={tag} name={tag} onClick={(e) => handleSelectTag(e, tag)} checked/> :
									<input type="checkbox" id={tag} name={tag} onClick={(e) => handleSelectTag(e, tag)}/>} 
								</div>
							}
							
						})} 

					</div>}
				</div>
				<div className="filterSection">
					<div className="textTitle">Category</div>
					<div className="checkboxSection">
					 	<label for="reviews" className="checkboxLabel">Reviews</label>
					 	<input 
					 		type="checkbox" 
					 		value="reviews" 
					 		className="checkbox"
					 		checked={attrContent.includes('reviews')}
					 		onChange={(e) => handleSelectAttrContent(e, "reviews")}
					 	/>	
					</div>
					<div className="checkboxSection">
					 	<label for="tips" className="checkboxLabel">Tips</label>
					 	<input 
					 		type="checkbox" 
					 		value="tips" 
					 		className="checkbox"
					 		checked={attrContent.includes('tips')}
					 		onChange={(e) => handleSelectAttrContent(e, "tips")}
					 	/>	
					</div>
				</div>


			</div>
			<div className="toggleFilterHolder">
				{showMoreFilters ? <div className="textLink" onClick={() => handleToggleFilters()}>Show Less</div> :
				<div className="textLink" onClick={() => handleToggleFilters()}>Show More</div>}
			</div>
			
		</div>
	);
};

export default SearchFilters;
