import React from "react";
import { compose, graphql, Query, withApollo } from "react-apollo";
import { analytics } from '../index.js';

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { GET_LOCATION_AND_ATTRACTIONS, GET_USER_AND_LOCATIONS } from "../apollo/db/queries"
 import { setCurrentUser } from "../apollo/state/mutations";
 import { UPDATE_USER } from "../apollo/db/mutations";

import AddAttractionContainer from "./AddAttractionContainer"
import AddLocationContainer from "./AddLocationContainer"
import AddSelection from "../components/AddSelection"
import AddTripContainer from "../containers/AddTripContainer"
import AttractionDetailsContainer from "./AttractionDetailsContainer"
import LocationSelection from "../components/LocationSelection"
import UploadPicturesContainer from "../containers/UploadPicturesContainer"



class AddContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			addedAttraction: null,
			addAttractionStep: 'add',
			addView: 'initial', 
			addType: null, 
			locationId: null, 
			stateError: null
		};

		this.handleClose = this.handleClose.bind(this)
		this.handleSelectAdd = this.handleSelectAdd.bind(this)
		this.handleSelectLocation = this.handleSelectLocation.bind(this)
		this.handleSetAddedAttraction = this.handleSetAddedAttraction.bind(this)
	}



	handleSetAddedAttraction(attraction){
		attraction.tags = [];
		this.setState({addedAttraction: attraction, addAttractionStep: 'details'})
	}

	handleSelectLocation(values){
		console.log('selecting', values)
		if (!values.locationId || values.locationId === 'choose'){
			this.setState({stateError: "Please select a location"})
		} else {
			this.setState({locationId: Number(values.locationId), addView: 'add', stateError: null})
		}
	}

	async handleSelectAdd(type){
		if (type === 'attraction' || type === 'pictures'){
			if (type === 'attraction'){
				if (process.env.NODE_ENV !== 'development') {
					await analytics.logEvent('show_add_attraction', {
						view: 'add_container'
					});
				}
			} else {
				if (process.env.NODE_ENV !== 'development') {
					await analytics.logEvent('show_add_location_pictures', {
						view: 'add_container'
					});
				}
			}
			this.setState({addView: 'locationSelection', addType: type})
			
		} else if (type === 'location'){
			if (process.env.NODE_ENV !== 'development') {
				await analytics.logEvent('show_add_location', {
					view: 'add_container'
				});
			}
			this.setState({addView: 'add', addType: type})
		} else {
			if (process.env.NODE_ENV !== 'development') {
				await analytics.logEvent('show_add_trip', {
					view: 'add_container'
				});
			}
			this.setState({addView: 'add', addType: type})
		}
	}

	handleClose(newId){
		console.log('newId', newId)
		const { addType } = this.state
		this.setState({
			addedAttraction: null,
			addAttractionStep: 'add',
			addView: 'initial', 
			addType: null, 
			locationId: null
		})
		if (newId && addType === 'attraction'){
			history.push(`/attraction/${newId}`)
		} else if (newId) {
			history.push(`/location/${newId}`)
		}


	}



	renderLocationAdd(){
		const { currentUser } = this.props
		return <Query query={GET_USER_AND_LOCATIONS} variables={{id: currentUser.id}} >
			{({ loading, error, data }) => {
				if (loading) return <div>Loading...</div>;
				if (error) return <div>Error!!</div>;

				const locations = data.userById.locationsByUserId.nodes

				return <AddLocationContainer 
					currentUser={currentUser}
					handleClose={this.handleClose}
					locations={locations}
					profUserId={currentUser.id}
				/>

		     			
			}}
		</Query>	

		
	}

	renderPictureAdd(){
		const { locationId } = this.state
		return <UploadPicturesContainer 
			locationId={locationId}

			handleClose={this.handleClose}
			view='mobileAdd'
		/>

	}

	renderUpcomingTripAdd(){
		return <AddTripContainer
			handleClose={this.handleClose}
		 />
	}
			
	renderAttractionAdd(){
		const { currentUser } = this.props
		const { locationId } = this.state
		return <Query query={GET_LOCATION_AND_ATTRACTIONS} variables={{id: locationId}}>
			{({ loading, error, data }) => {
				if (loading) return <div>Loading...</div>;
				if (error) return <div>Error!</div>;
				const location = data.locationById
				const attractions = location.attractionsByLocationId.nodes
				return <AddAttractionContainer
					currentUser={currentUser}
					attractions={attractions}
					closeText={'Later'}
					handleClose={this.handleClose}
					handleSetAddedAttraction={this.handleSetAddedAttraction}
					locationId={locationId}
					locationLat={location.lat}
					locationLng={location.lng}
					view='addContainer'
				/>

		     			
			}}
		</Query>	

	}	 

	renderAttractionDetails(){
		const { addedAttraction, locationId } = this.state
		return <AttractionDetailsContainer 
			attraction={addedAttraction}
			closeText="Later"
			locationId={locationId}
			handleFinish={this.handleClose}
			submitText="Submit"

		/>
	}

	renderInitialAddView(){
		const { currentUser } = this.props
		return <Query query={GET_USER_AND_LOCATIONS} variables={{id: currentUser.id}} >
			{({ loading, error, data }) => {
				if (loading) return <div>Loading...</div>;
				if (error) return <div>Error</div>;


				const locations = data.userById.locationsByUserId.nodes

				return <AddSelection 
				handleClose={this.handleClose}
				handleSelectAdd={this.handleSelectAdd}
				locations={locations}
			/>

		     			
			}}
		</Query>
		
	}    

	renderLocationSelection(){
		const { currentUser } = this.props
		const { stateError } = this.state
		return <Query query={GET_USER_AND_LOCATIONS} variables={{id: currentUser.id}} >
			{({ loading, error, data }) => {
				if (loading) return <div>Loading...</div>;
				if (error) return <div>Error</div>;


				const locations = data.userById.locationsByUserId.nodes

				return <LocationSelection
					handleClose={this.handleClose}
					handleSubmit={this.handleSelectLocation}
					locations={locations}
					stateError={stateError}

				/>

		     			
			}}
		</Query>	
	}    




	render() {
		const { currentUser } = this.props;
		const { addAttractionStep, addType, addView } = this.state;
		console.log('rending add', addType)
		return (<div className="addContainer">
			{currentUser.id ? <div>
				{addView === 'initial' && this.renderInitialAddView()}
				{addView === 'locationSelection' && this.renderLocationSelection()}
				{addView === 'add' && addType === 'location' && this.renderLocationAdd()}
				{addView === 'add' && addType === 'attraction' && <div>{addAttractionStep === 'add' ? this.renderAttractionAdd() : this.renderAttractionDetails()}</div>}
				{addView === 'add' && addType === 'pictures' && this.renderPictureAdd()}
				{addView === 'add' && addType === 'upcomingTrip' && this.renderUpcomingTripAdd()}

       			
     		 </div> : <div>Please login or create an account to view this page</div>}
     	</div>
		);
	}
}


export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}),
	graphql(setCurrentUser, {name: 'setCurrentUser'})

)(AddContainer);

