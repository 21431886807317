import React from "react";
import { compose, graphql, Mutation, withApollo } from "react-apollo";

import history from "../history"


import TripVoteButtons from "../components/TripVoteButtons"

import { getCurrentUser } from "../apollo/state/queries";
import { GET_TRIP_BY_ID, GET_TRIP_GENERAL_ATTRACTIONS } from "../apollo/db/queries"
import { CREATE_NEW_TRIP_ATTRACTION_VOTE, DELETE_TRIP_ATTRACTION_VOTE } from "../apollo/db/mutations"


class TripAttractionVoteContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			voteNameViewable: false
		}

		this.handleCreateTripAttractionVote = this.handleCreateTripAttractionVote.bind(this)
		this.handleDeleteTripAttractionVote = this.handleDeleteTripAttractionVote.bind(this)

	}

	async componentDidMount(){
		const { client, tripId } = this.props
		
		try {
			const res = await client.query({
			query: GET_TRIP_BY_ID,
			variables: {id: tripId}
		})

		const voteAnonymity = res.data.tripById.voteAnonymity

		await this.setState({voteNameViewable: !voteAnonymity})

		} catch {
			console.log('error')
		}


	}
	
	async handleCreateTripAttractionVote(direction, createNewTripAttractionVote){
		const { tripGeneralAttrId } = this.props

		
		try {
			await createNewTripAttractionVote({
				variables: {
					input: {
							tripGeneralAttrId: tripGeneralAttrId,
							direction: direction
						}	
					}
				})
		} catch {
			console.log('error')
		}
	}

	async handleDeleteTripAttractionVote(voteId, deleteTripAttractionVote){
		try {
				await deleteTripAttractionVote({
					variables: {
						input: {
							id: voteId
							}
						}
					})

			} catch {
				console.log('error')
			}
	}


	render() {
		const { handleShowVoters, tripLocId, userVote, userVoteId, votes } = this.props
		const { voteNameViewable } = this.state

		const upVotes = votes[0] ? votes.filter(vote => vote.voteDirection === "UP") : []
		const downVotes = votes[0] ? votes.filter(vote => vote.voteDirection === "DOWN") : []

		return (<div className="TripAttractionVoteContainer">
			{<Mutation
	     		 mutation={CREATE_NEW_TRIP_ATTRACTION_VOTE}
	     		 refetchQueries={[{query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLocId}}]}
		    >
		      	{(createNewTripAttractionVote, { loading, error }) => {
		      		return <Mutation
			     		 mutation={DELETE_TRIP_ATTRACTION_VOTE}
			     		 refetchQueries={[{query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLocId}}]}
				    >
				      	{(deleteTripAttractionVote, { loading, error }) => {
				      		return <TripVoteButtons 
				      			createNewTripAttractionVote={createNewTripAttractionVote}
								deleteTripAttractionVote={deleteTripAttractionVote}
								downVotes={downVotes}
								error={error}
								handleCreateTripAttractionVote={this.handleCreateTripAttractionVote} 
								handleDeleteTripAttractionVote={this.handleDeleteTripAttractionVote}
								handleShowVoters={handleShowVoters}
								upVotes={upVotes}
								userVote={userVote} 
								userVoteId={userVoteId}
								voteNameViewable={voteNameViewable}
				      		/>
						}}
					</Mutation>
		      	}}
			</Mutation>}
				
		</div>);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(TripAttractionVoteContainer);