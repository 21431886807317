import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/GeneralLocTips.css"

import AddToTripContainer from "../containers/AddToTripContainer"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"

const GeneralLocTips = props => {
	const { 
		currentUser, 
		handleShowDelete, 
		handleToggleIsEditing,
		handleToggleTooltip,
		handleToggleTips,
		isEditing,
		showAllTips,
		showTooltip,
		tips, 
		tripLocName,
		view
	} = props

	return (
		<div className="generalLocTips">
			<div className="generalLocTipsHeader">
				{view === 'tripLocation' ? <div>
					<div className="labelAndTooltip">
			          	<div className="headerHolder fullHeaderHolder marginBottom">
							<div className="subHeader">location tips</div>
							<div className="headerUnderline subHeaderUnderline"></div>
						</div>
						<div className="tooltipIcon" onClick={() => handleToggleTooltip()}>?</div>
					</div>
					{showTooltip && <div className='tooltip'>{`Add tips from other users' ${tripLocName} pages`}</div>}
				</div> : <div className="headerHolder fullHeaderHolder">
					<div className="subHeader">location tips</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>}
				{view === 'tripLocation' && <div>{isEditing ? <button className="cancelEdit" onClick={() => handleToggleIsEditing()}>Cancel</button> : 
				<i className={`material-icons activeIcon iconMedium`} onClick={() => handleToggleIsEditing()}>edit</i>}
				</div>}
			</div>
			{!!tips.length ? <div className="mainBackground">
				{tips.map((tip, index )=> {
					if (showAllTips || index < 5){
						return <div className="tip" key={tip.id}>
							<div className="categoryTitle tipTitle">{tip.category[0] + tip.category.slice(1).toLowerCase()}</div>
							<div>
								<Link className="textLink" to={`/location/${tip.locationId}`}>{(tip.firstName + ' ' + tip.lastName)}</Link>
								{tip.objectionable ? 
										<div>
											{tip.objectionable === 'CONFIRMED' ? 
						             		<div>This tip has been flagged.</div> : 
						             		<div>This tip has been reported and is under review.</div>}
									             	
										</div>
								: <div className="fontSmall">{tip.tip}</div>}
							</div>
							{view === 'tripLocation' && isEditing && <div className="removeIcon" onClick={() => handleShowDelete(tip.id)}>-</div>}
							{view !== 'tripLocation' && currentUser.id > 0 && <div>
								<ReportObjectionableContainer
									reportItem={tip}
									reportType={'LOCATION_TIP'}
									reportTypeRender="location tip"
								 />
								{/*<AddToTripContainer
									locationId={tip.locationId} 
									tip={tip}
									type="tip"
								/>*/}
							</div>}

						</div>
					}	
				})}
				<div>
				{tips.length > 5 && <div className="flexCenter">
					{showAllTips ? <div className="textLink fontLarge" onClick={() => handleToggleTips()}>Show Less</div> : <div className="textLink fontLarge" onClick={() => handleToggleTips()}>Show More</div>}
				</div>}
				</div>
			</div> : <div>{view === 'tripLocation' ? 
				<div className="mainBackground">No tips have been added to this location yet.</div> 
				: <div className="mainBackground">There are no tips for this location yet.</div>}</div>}
		</div>
	);
}

export default GeneralLocTips;