import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import "../stylesheets/components/AllMessages.css"

const AllMessages = props => {
	const { 
		currentUser,
		handleViewMessage,
		messages
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}

	return (
		<div className="allMessages">
			{messages.length ? <div>{messages.map(message => {
				return <div className="message secondaryBackground" key={message.id} onClick={() => handleViewMessage(message)} key={message.id}>
					<img className="picMedium" src={message.publicUrl} alt="profPic" />
					<div className="messageContent">
						<div className="messageHeader">
							<div className="messengerName">{(message.firstName + ' ' + message.lastName)}</div>
							<div className="fontXS messageCreatedAt">{moment.utc(message.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
						</div>
						{message.locationId && <div className="locationTagHolder">{message.locationId && <Link className="locationTag textLink fontXS" to={`/location/${message.locationId}`}>{getLocName(message)}</Link>}</div>}
					
						<div className="messageHeader">
							<div className={message.receiverId === currentUser.id ? "fontSmall messageReceived" : "fontSmall"}>{message.messageContent}</div>
							{!message.read && message.receiverId === currentUser.id && <div className="unread">*</div>}
						</div>
						
					
					</div>
				</div>
			})}</div> : <div>You have no messages</div>}
		</div>
	);
};

export default AllMessages;
