import React from "react";
//import { bookmark } from 'react-icons/md';


import "../stylesheets/components/SaveIcon.css"

const SaveIcon = props => {
	const { 
		attraction,
		currentUser,
		deleteSavedAttraction,
		handleCreateSaved,
		handleDeleteSavedAttraction,
		saveAttraction,
		savedIds,
		unsavedIds,
		view
	} = props


	
	return (
		<div  className="saveIcon">
			{currentUser.id ? <div>{((attraction.savedId && view !== 'search') || (view === 'search' && attraction.savedId && !unsavedIds.includes(attraction.id)) || (view === 'search' && savedIds.includes(attraction.id))) ?  
				 <div className="saveButtonHolder">
				<i className="material-icons activeIcon iconSmall" onClick={() => handleDeleteSavedAttraction(attraction, deleteSavedAttraction)}>bookmark</i>
				{view !== 'saved' && view !== 'savedLocation' && <div className="saveButtonLabel">
					{(view === 'search' && savedIds.includes(attraction.id)) ? <div>{attraction.saveCount ? <div className="saveCount">{Number(attraction.saveCount) + 1}</div> : <div className="saveCount">1</div>}
						{Number(attraction.saveCount) + 1 === "1" ? 
							<div>save</div> :
							<div>saves</div>
						}</div>
						: <div>{attraction.saveCount ? <div className="saveCount">{attraction.saveCount}</div> : <div className="saveCount">0</div>}
						{attraction.saveCount && attraction.saveCount === "1" ? 
							<div>save</div> :
							<div>saves</div>
						}</div>}
					</div>}
				</div>
			:  
			 	<div className="saveButtonHolder">

					<i className="material-icons inactiveIcon iconSmall" onClick={() => handleCreateSaved(attraction, saveAttraction)}>bookmark</i>
				
					{view !== 'saved' && view !== 'savedLocation' && <div className="saveButtonLabel">
					{(view === 'search' && unsavedIds.includes(attraction.id)) ? 
							<div>{Number(attraction.saveCount) - 1 ? 
								<div className="saveCount">{Number(attraction.saveCount) - 1}</div> 
								: <div className="saveCount">0</div>}
							{Number(attraction.saveCount - 1) === 1 ? 
								<div>save</div> :
								<div>saves</div>
							}
						</div>
						: <div>{attraction.saveCount ? <div className="saveCount">{attraction.saveCount}</div> : <div className="saveCount">0</div>}
						{attraction.saveCount && attraction.saveCount === "1" ? 
							<div>save</div> :
							<div>saves</div>
						}</div>}
					</div>}
				</div>}
			</div> : <div className="saveButtonHolder">{attraction.saveCount ? 
					<div className="saveCount">{attraction.saveCount}</div> 
					: <div className="saveCount">0</div>}
					{attraction.saveCount && attraction.saveCount === "1" ? 
						<div>save</div> :
						<div>saves</div>}
					
			</div>}
		</div>
	);
};

export default SaveIcon;
