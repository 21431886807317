import React from "react";
import { compose, graphql, withApollo } from "react-apollo";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Yup from "yup"
import { analytics } from '../index.js';


import history from "../history"
import { GET_EXPLORE_ATTRACTIONS, GET_EXPLORE_LOCATIONS, GET_EXPLORE_LOCATIONS_V2, GET_EXPLORE_LOCATIONS_FOLLOWING, GET_ALL_OVERALL_LOCATIONS, GET_FOLLOWING_OVERALL_LOCATIONS, GET_UPCOMING_TRIPS } from "../apollo/db/queries"
import { CREATE_TRIP_USERS , SAVE_ATTRACTION, CONVERT_ITINERARY, SAVE_ATTRACTION_INITIAL} from '../apollo/db/mutations'

import { getCurrentUser } from "../apollo/state/queries"
import { setCurrentUser } from "../apollo/state/mutations"

import Login from "../components/Login";
import PasswordReset from "../components/PasswordReset"
import PasswordResetEmail from "../components/PasswordResetEmail"

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://trvlpage.uc.r.appspot.com'
			///'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}

class LoginContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			loginMessage: null,
			stateError: null, 
			step: "login"
		};

		this.handleGoToReset = this.handleGoToReset.bind(this)
		this.handlePasswordReset = this.handlePasswordReset.bind(this)
		this.handlePasswordResetEmail = this.handlePasswordResetEmail.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount(){
		const { client, currentUser } = this.props
		const token = window.location.href.split('=')[1]
		if (token) {
			this.setState({step: 'passwordReset'})
		}
		// const res = await client.query({
  //         query: GET_EXPLORE_ATTRACTIONS,
  //         variables: { explorerId: 1, pageNumber: 1 },
  //         fetchPolicy: 'network-only'
  //       })



   //   	console.log('currentUser', currentUser)

        const res = await client.mutate({
        	mutation: SAVE_ATTRACTION_INITIAL,
        	variables: {
	   	 		input: {
					attrId: 7
				}
			}
        	
        })


        console.log('res', res)


        //const exploreAttractions = res.data.exploreAttractions.nodes
        //console.log('exploreLocations', exploreAttractions)
        //this.setState({locations: exploreLocations})
		
	}

	async handleSubmit(values, setSubmitting) {
		const { setCurrentUser } = this.props

		setSubmitting(true)

		const email = values.email.toLowerCase();
		const password = values.password


		try {
			const res = await axios(`${getUrl()}/me`, {
			  method: "put",
			  data: {"email": email, "password": password},
			  withCredentials: true, 
			})
		
			//user has not verified email?
			if (res.data.message){
				if (res.data.resendOption){
					history.push('/signup?notVerified')
				} else {
					this.setState({stateError: res.data.message})
				}
				setSubmitting(false)
				return null;
			}

			


			const graphqlRes = await axios({
			  url: `${getUrl()}/graphql`,
			  method: 'post',
			  withCredentials: true,
			  data: {
			    query: `
			      {
					  currentPerson {
					    id
					    firstName
					    lastName
					    currentCity
					    about
					    birthday
					    publicity
					    seenExplanation
					    seenSaveExplanation
					    seenLocExplanation
					    seenProfExplanation
					    seenInstaConnection
					    locationViews
					    pageViews
					    pushToken
					    publicUrl
					    locUploadNumber
    					attrUploadNumber
					  }
					}
			      `
			  }
			})

			console.log('res in login', res)
			
			//const user = undefined
			const user = graphqlRes.data.data.currentPerson
			//user did not finish signup 
			if (!user && res.data.id){
				await setCurrentUser({
	                variables: {
	                  id: res.data.id
	                }
	              })
				history.push('/signup?finishSignup')
				return null;
			}

			await setCurrentUser({
                variables: {
                  id: user.id, 
			      firstName: user.firstName,
			      lastName: user.lastName,
			      about: user.about,
			      hometown: user.hometown,
			      currentCity: user.currentCity,
			      birthday: user.birthday,
			      publicity: user.publicity,
			      seenExplanation: user.seenExplanation,
			      seenSaveExplanation: user.seenSaveExplanation,
			      seenLocExplanation: user.seenLocExplanation, 
			      seenProfExplanation: user.seenProfExplanation,
			      seenInstaConnection: user.seenInstaConnection,
			      locationViews: user.locationViews,
			      pageViews: user.pageViews, 
			      pushToken: user.pushToken,
			      publicUrl: user.publicUrl, 
			      locUploadNumber: user.locUploadNumber,
    			  attrUploadNumber: user.attrUploadNumber
                }
              })
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.setUserId(user.id);

				await analytics.setUserProperties({
				  birthday: user.birthday,
				  currentCity: user.currentCity, 
				  device: 'desktop', 
				  createdAt: user.createdAt, 
				  version: '1.4.6'
				});

				await analytics.logEvent('login', {
				  method: 'trvlpage'
				});
			}


			history.push('/explore');

		} catch {
			setSubmitting(false)
			this.setState({stateError: "There was an error with the login."})
		}	
		
	}


	async handlePasswordResetEmail(values, setSubmitting) {
		const email = values.email.toLowerCase()

		setSubmitting(true)
	
			try {
				const res = await axios(`${getUrl()}/me/passwordResetEmail`, {
				  method: "put",
				  data: {"email": email},
				  withCredentials: true
				})

				if (res.data.message){
					this.setState({stateError: res.data.message})
					setSubmitting(false)
				} else {
					this.setState({loginMessage: "Sent! Please check your email.", step: 'passwordReset', stateError: null})
					setSubmitting(false)
				}
			} catch {
				this.setState({stateError: "There was an error sending the password reset email"})
				setSubmitting(false)
			}
		
		
	}

	async handlePasswordReset(values, setSubmitting){
		setSubmitting(true)
		const token = values.verificationKey
		const password = values.password
		try {
			const res = await axios(`${getUrl()}/me/passwordReset`, {
			  method: "put",
			  data: {"password": password, "token": token},
			  withCredentials: true
			})

			if (res.data.message){
				this.setState({stateError: res.data.message})
				setSubmitting(false)
			} else {
				this.setState({loginMessage: "Password reset complete. Please login.", step: 'login', stateError: null})
				setSubmitting(false)
			}
		} catch {
			setSubmitting(false)
			this.setState({stateError: 'There was an error with the password reset.'})
		}

	}

	handleGoToReset(){
		this.setState({step: 'passwordResetEmail', stateError: null})
	}

	renderPasswordReset(){
		const { loginMessage, stateError } = this.state
		const resetSchema = Yup.object().shape({
		  password: Yup.string()
		  	.strict(true)
		    .min(8, 'Password has to be longer than 8 characters!') 
		    .required('Password is required!') 
		    .trim("No leading or trailing spaces")
		})
		return <PasswordReset 
					handlePasswordReset={this.handlePasswordReset}
					stateError={stateError}
					loginMessage={loginMessage}
					resetSchema={resetSchema}
				/>
	}


	render() {
		const { verification } = this.props
		const {  loginMessage, resendOption, stateError, step } = this.state
		const initialValues = {
			email: '', 
			password: '', 
		}
		return (
			<div className="loginContainer">
				{step === 'passwordResetEmail' &&  
					<PasswordResetEmail 
						handlePasswordResetEmail={this.handlePasswordResetEmail}
						stateError={stateError}
						loginMessage={loginMessage}
					/>}
				{step === 'login' && <Login 
					handleGoToReset={this.handleGoToReset}
					handleSubmit={this.handleSubmit} 
					stateError={stateError} 
					initialValues={initialValues}
					verification={verification} 
				/>}
				{step === 'passwordReset' && this.renderPasswordReset()}

				
			</div>
		);
	}
}




export default compose(
	withApollo,
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
	graphql(getCurrentUser, {
		props: ({loading, data: { currentUser }}) => ({
			loading,
			currentUser
		})
	})
)(LoginContainer);
