import React from "react";
import { compose, graphql, withApollo, Query, Mutation } from "react-apollo";
import history from "../history";

import { analytics } from "../index.js"

import { getCurrentUser } from "../apollo/state/queries";
import { 
	setCurrentUser, 
	setDestinationSearchPlace,
  	setDestinationSearchValue,
  	setDestinationSearchType,
  	setUserSearchValue, 
  	setSearchView
} from "../apollo/state/mutations";

import { 
	getDestinationSearch
 } from "../apollo/state/queries"

import SearchAutocomplete from "../components/SearchAutocomplete"
import SearchBar from "../components/SearchBar"



class SearchBarContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			stateError: null,
			view: 'destination'
		};

		this.handleChange = this.handleChange.bind(this)
		this.handleEnter = this.handleEnter.bind(this)
		this.handleSetSearching = this.handleSetSearching.bind(this)
		this.handleSetDestSearchPlace = this.handleSetDestSearchPlace.bind(this)
		this.handleSetSearchView = this.handleSetSearchView.bind(this)
		this.handleSetUserSearchValue = this.handleSetUserSearchValue.bind(this)
		//this.handleSetView = this.handleSetView.bind(this)
	
	}

	async handleChange(event){
		const { setDestinationSearchPlace, destinationSearch } = this.props
		const placeValue = event.target.value
		await this.setState({placeValue: placeValue, stateError: null})
		if (destinationSearch.place && placeValue === ''){
			await setDestinationSearchPlace({
				variables: {
					place: null
				}
			})
		}

	}


	handleSetSearching(event){
		if (event){

			event.preventDefault()
		}
		 history.push('/search')
	}

	async handleSetSearchView(view){
		const { setSearchView  } = this.props
		await this.setState({view: view, stateError: null})
		await setSearchView({
			variables: {
				view: view
			}
		})

		this.handleSetSearching()
	}



	async handleSetUserSearchValue(event){
		const { setUserSearchValue } = this.props
		const value = event.target.value
		await setUserSearchValue({
			variables: {
				value: value
			}
		})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('set_user_search_value', {
			  value: value
			});
		}
	}

	handleEnter(event){
		const { destinationSearch } = this.props
		//if enter is hit before selecting a location
		event.preventDefault()
		if (!destinationSearch.place){
			this.setState({stateError: "Please select a location"})
		}
	}

	async handleSetDestSearchPlace(selected){
		const { setDestinationSearchPlace } = this.props
		if (selected.place_id){
			const localityObj = selected.address_components.find(component => {
				return component.types.includes('locality')
			})
			//locality and country should always exist (this is just an extra protective layer in case it doesn't)
			const locality = localityObj ? localityObj.long_name : null
			const adminLevel1Obj = selected.address_components.find(component => {
				return component.types.includes('administrative_area_level_1')
			})
			const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
			const countryObj = selected.address_components.find(component => {
				return component.types.includes('country')
			})
			const country = countryObj ? countryObj.long_name : null

			const place = {
				locality: locality, 
				adminLevel1: adminLevel1, 
				country: country, 
				lat: selected.geometry.location.lat(), 
				lng: selected.geometry.location.lng()
			} 

			let selectedPlace;
			if (locality && country === 'United States'){
				let locName = `${locality}, ${adminLevel1}, ${country}`
				selectedPlace = locName
			} else if (locality){
				let locName = `${locality}, ${country}`
				selectedPlace = locName
			} else if (adminLevel1){
				let locName = `${adminLevel1}, ${country}`
				selectedPlace = locName
			} else {
				selectedPlace = country
			}

			await this.setState({placeValue: selectedPlace, stateError: null})
			await setDestinationSearchPlace({
				variables: {
					place: place
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('set_destination_search_value', {
				  place: place
				});	
			}
			this.handleSetSearching()
		}
	}
	
	render() {
		const { currentUser } = this.props;
		const { error, placeValue, stateError, view} = this.state
		return (
			<div className='searchBarContainer'>
			{view === 'destination' ? 
				<SearchAutocomplete 
					handleChange={this.handleChange}
					handleEnter={this.handleEnter}
					handleSelect={this.handleSetDestSearchPlace}
					handleSetSearching={this.handleSetSearching}
					handleSetView={this.handleSetSearchView}
					placeholder="Search for a city, state, or country"
					stateError={stateError}
					type="search"
					value={placeValue}
				/> : 
				<SearchBar 
					handleSetSearching={this.handleSetSearching}
					handleChange={this.handleSetUserSearchValue}
					handleSetView={this.handleSetSearchView}
					view={view}
				/>

			}
				
			</div>
		);
	}
}


export default compose(
	withApollo,
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
	graphql(setDestinationSearchPlace, {name: 'setDestinationSearchPlace'}),
	graphql(setDestinationSearchType, {name: 'setDestinationSearchType'}),
	graphql(setUserSearchValue, {name: 'setUserSearchValue'}),
	graphql(setSearchView, {name: 'setSearchView'}),
	graphql(getDestinationSearch, {
		props: ({loading, data: { destinationSearch }}) => ({
			loading,
			destinationSearch
		})
	}), 
	graphql(getCurrentUser, {
		props: ({loading, data: { currentUser }}) => ({
			loading,
			currentUser
		})
	})
)(SearchBarContainer);
