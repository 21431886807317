import React from "react";


const SearchBar = props => {
	const {  
		handleChange,
		handleSetSearching,
		handleSetView,
		view
	} = props;
	return (
		<div className="searchBar">
		
			<form className="searchForm">
				<div>
					<input 
						 //onFocus={handleSetSearching}
						 className="formField searchField" 
						 onChange={e => handleChange(e)} 
						 name="search" 
						 placeholder="search users"
						 autoComplete="off"
						 onKeyDown={(e) => {
			                if (e.key === 'Enter') {
			                console.log('clicking enter')
			                  handleSetSearching(e)
			                }
			            }} 
					/>
					<div className="searchToggleView">	
						<div className="switchTitle">Switch to: </div>
						<div className="textLink" onClick={() => handleSetView('destination')}>
							Destination Search
						</div>
					</div>
				</div>
				<button className="searchButton primaryButton fontSmall" onClick={(e) => handleSetSearching(e)}>Search Users</button>
			</form>
		</div>
	);

};

export default SearchBar;
