import React from "react";

import "../stylesheets/components/Deletion.css"

const Deletion = props => {
	const { 
		error, 
		handleDelete, 
		handleHideDelete, 
		name,
		mutationFunction, 
		type,
		} = props;
	return (
		<div className="deletion popup popupSmall">
			<div className="popupContent">
				{name ? <div className="tertiaryHeader">{`Are you sure you want to delete the ${type} ${name}?`}</div> : <div className="secondaryHeader">{`Are you sure you want to delete this ${type}?`}</div>}
				{error && <div className="error">There was an error.</div>}
				<div className="formButtons">
					<button className="secondaryButton formButton" onClick={() => handleHideDelete()}> Nevermind </button>
					<button className="primaryButton formButton" onClick={(e) => handleDelete(mutationFunction, e)}>Yes</button>
				</div>
			</div>
		</div>
	);
};

export default Deletion;
