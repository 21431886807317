import React from "react";

import "../stylesheets/components/TripLocations.css"

import { 
	GET_TRIP_LOCATION_DATES, 
} from "../apollo/db/queries"
import {  
	CREATE_TRIP_LOCATION_DATE, 
	DELETE_TRIP_LOCATION_DATE, 
	UPDATE_TRIP_LOCATION_DATE 
} from "../apollo/db/mutations"

import DatesContainer from "../containers/DatesContainer"

const TripLocations = props => {
	const {  
		handleGoToLocation,
		handleShowAddLocation,
		handleShowDelete,
		handleShowGroupLocations,
		handleToggleIsEditing,
		isEditing,
		locations,
		tripId
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}


	return (
		<div className="tripLocations">
			
			<div className="tripLocationsHeader">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">destinations</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				<div>
					<img className="iconMedium" 
  						onClick={() => handleShowAddLocation()}
   						src="https://storage.googleapis.com/trvlpage-bucket/plus_pin.svg" 
   						alt="addPinIcon"
   					/>
				{isEditing ? 
					<button className="cancelEdit" onClick={handleToggleIsEditing}>Close Edit</button> : 
					<img 
						className="iconMedium activeIcon" 
						onClick={handleToggleIsEditing}
						src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
						alt="editIcon"
					/>}
					<img 
						className="iconMedium activeIcon" 
						onClick={() => handleShowGroupLocations()} 
						src="https://storage.googleapis.com/trvlpage-bucket/group_pins.svg"
					/>
				</div>
			</div>
			<div className="mainBackground">
				<div className="textTitle tripLocationsTitle">Dates</div>
				{locations.length ? locations.map(location => {
					return <div className="tripLocation" key={location.id}>
						<div className="locNameAndRemove">
						{isEditing && 
							<div className="removeIcon activeIcon" onClick={() => handleShowDelete(location)}>-</div>}
							<div className="textLink secondarySubHeader" onClick={()=> handleGoToLocation(location.id)}>{(locations.indexOf(location) + 1) + '. ' + getLocName(location)}</div>
						</div>
						<div className="tripLocDates">
							<DatesContainer
								dateType="tripLocationDate"
								dates={location.dates}
								parentId={location.id}
								isEditingTripLoc={isEditing}
								parentType="tripLocation"
								tripId={tripId}
								view="trip"

							/>
						</div>
					</div>
				}) : <div>No locations have been added to this trip.</div>}

			</div>
	

		</div>
	);
};

export default TripLocations;
