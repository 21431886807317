import React from "react";
import { compose, graphql, withApollo } from "react-apollo";
import { Route, withRouter } from "react-router-dom"
import { withLastLocation } from 'react-router-last-location';
import CookieConsent from "react-cookie-consent";
import axios from "axios";
import { analytics } from '../index.js';


import history from "../history"

import "../stylesheets/Icons.css"
import "../stylesheets/general.css"

import { getCurrentUser } from "../apollo/state/queries";
import { setCurrentUser } from "../apollo/state/mutations";
import { UPDATE_USER } from "../apollo/db/mutations"


import CreateAccountCallout from "../components/CreateAccountCallout"
import NavBarContainer from "../containers/NavBarContainer"
import UploadCallout from "../components/UploadCallout"
import WelcomeContainer from "../containers/WelcomeContainer"

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
			
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}


class Main extends React.Component {
	constructor() {
		super();
		this.state = {
			showAccountCallout: false, 
			showUploadCallout: false
			
		};

		this.componentDidMount = this.componentDidMount.bind(this)
		this.handleGoToAdd = this.handleGoToAdd.bind(this)
		this.handleGoToSignup = this.handleGoToSignup.bind(this)
		this.handleHideAccountCallout = this.handleHideAccountCallout.bind(this)
		this.handleHideUploadingCallout = this.handleHideUploadingCallout.bind(this)
		this.handleUpdatePageViews = this.handleUpdatePageViews.bind(this)

	}
 
	async componentDidMount(){
		const { currentUser, setCurrentUser, lastLocation } = this.props
		const { pathname } = this.props.location;
		let user; 
		getUrl()
	
		this.unlisten = history.listen( location =>  {
                this.handleUpdatePageViews()
				  if (process.env.NODE_ENV !== 'development'){
				  	try {
					  	const currentScreen = location.pathname.split('/')[1]
						analytics.setCurrentScreen(currentScreen);
				  	} catch {
				  		console.log('error')
				  	}
				}
                
           });

		if (!currentUser.id && currentUser.id !== 0){
			try {
			
			const res = await axios.get(`${getUrl()}/me`, {withCredentials: true, credentials: 'same-origin'})
			if (res.data){
			
				
				const graphqlRes = await axios({
				  url: `${getUrl()}/graphql`,
				  method: 'post',
				  withCredentials: true,
				  data: {
					    query: `
					      {
							  currentPerson {
							    id
							    firstName
							    lastName
							    currentCity
							    about
							    birthday
							    publicity
							    seenExplanation
							    seenSaveExplanation
							    seenLocExplanation
							    seenProfExplanation
							    seenInstaConnection
							    locationViews
							    pageViews
							    pushToken
							    publicUrl
							    locUploadNumber
    							attrUploadNumber
							  }
							}
					      `
					  }
				})

		
			 user = graphqlRes.data.data.currentPerson
			} 

			} catch {
				console.log('error')
			}
			
			if (user || pathname !== '/'){
				await setCurrentUser({
                variables: {
	                  id: user ? user.id : 0, 
				      firstName: user ? user.firstName : null,
				      lastName: user ? user.lastName : null,
				      about: user ? user.about : null,
				      currentCity: user ? user.currentCity: null,
				      birthday: user ? user.birthday : null,
				      publicity: user ? user.publicity : null,
				      seenExplanation: user ? user.seenExplanation : null,
				      seenSaveExplanation: user ? user.seenSaveExplanation : null,
				      seenLocExplanation: user ? user.seenLocExplanation : null,
				      seenProfExplanation: user ? user.seenProfExplanation : null,
				      seenInstaConnection: user ? user.seenInstaConnection : null,
				      locationViews: user ? user.locationViews : 0, 
				      pageViews: user ? user.pageViews : 0, 
				      pushToken: user ? user.pushToken : null,
				      publicUrl: user ? user.publicUrl : null, 
				      locUploadNumber: user ? user.locUploadNumber : null, 
   					  attrUploadNumber: user ? user.attrUploadNumber : null
	                }
              })
				if (process.env.NODE_ENV !== 'development'){
					await analytics.setUserId(user.id);

					await analytics.setUserProperties({
					  birthday: user.birthday,
					  currentCity: user.currentCity, 
					  device: 'desktop', 
					  createdAt: user.createdAt, 
					  version: '1.4.6'
					});
				}
			}

			if (user && pathname === '/'){
				history.push('/explore')
			}
		
		}	
	}


	async handleUpdatePageViews(){
		const { currentUser, setCurrentUser, client } = this.props
		const userPatch = { pageViews: currentUser.pageViews + 1 } 

		const newCurrentUser = Object.assign({}, currentUser, userPatch)


			try {
				if (currentUser.id > 0){
					await client.mutate({
						mutation: UPDATE_USER,
						variables: {
							input: {
								id: currentUser.id,
								userPatch: userPatch
								}	
							}
					})
				}
				


				await setCurrentUser({
              	  variables: newCurrentUser
	            })


			} catch {
				console.log('error')
			}
			if (currentUser.id === 0 && currentUser.pageViews === 6){
				try {
					if (process.env.NODE_ENV !== 'development'){
					 	await analytics.logEvent('account_callout', {
					 		action: 'show_callout'
					 	});
					}
				} catch {
					console.log('error with analytics')
				}
				
				this.setState({showAccountCallout: true})
			} else if (currentUser.id && currentUser.pageViews === 6 && (!currentUser.locUploadNumber || !currentUser.attrUploadNumber || !currentUser.seenInstaConnection)){
				try {
					if (process.env.NODE_ENV !== 'development'){
					 	await analytics.logEvent('uploading_callout', {
					 		action: 'show_callout'
					 	});
					}
				} catch {
					console.log('error with analytics')
				}
				this.setState({showUploadCallout: true})
			}
	}

	componentWillUnmount() {
        this.unlisten();
     
    }

	async handleGoToSignup(){
		try {
			if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('account_callout', {
			 		action: 'go_to_signup'
			 	});
			}
		} catch {
			console.log('error with analytics')
		}
		
		history.push('/signup')
	}

	async handleHideAccountCallout(){
		try {
			if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('account_callout', {
			 		action: 'later'
			 	});
			}
		} catch {
			console.log('error with analytics')
		}

		this.setState({showAccountCallout: false})
	}

	async handleHideUploadingCallout(){
		try {
			if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('uploading_callout', {
			 		action: 'later'
			 	});
			}
		} catch {
			console.log('error with analytics')
		}
		
		this.setState({showUploadCallout: false})

	}

	async handleGoToAdd(){
		const { currentUser } = this.props
		try {
			if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('uploading_callout', {
			 		action: 'go_to_add'
			 	});
			}	 			
		} catch {
			console.log('error with analytics')
		}
	
		this.setState({showUploadCallout: false}, () => {
			history.push(`/add`)
			
		})
		
	}

	async handleConnectInsta(){
		try {
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('uploading_callout', {
			 		action: 'go_to_insta'
			 	});
			 	await analytics.logEvent('connect_insta');
			}
		} catch {
			console.log('error with analytics')
		}

		const res = window.location.href = 'https://api.instagram.com/oauth/authorize?client_id=368242907540964&redirect_uri=https://trvlpage.uc.r.appspot.com/instagram&scope=user_profile,user_media&response_type=code'
		
	}
	

	render() {
		const { currentUser, lastLocation } = this.props;
		const { pathname } = this.props.location;
		const { showAccountCallout, showUploadCallout } = this.state
		console.log('currentUser', currentUser)
		return (
			<div className="Main">
				{(currentUser.id || currentUser.id === 0) ? <div>
				 <NavBarContainer/> 
				 {showAccountCallout && <CreateAccountCallout 
				 		handleGoToSignup={this.handleGoToSignup}
						handleHideCallout={this.handleHideAccountCallout}
				 	/>}
				 {showUploadCallout && <UploadCallout
				 		currentUser={currentUser}
				 		handleGoToAdd={this.handleGoToAdd}
				 		handleHideCallout={this.handleHideUploadingCallout}
				 		handleConnectInsta={this.handleConnectInsta}
				 	/>}
				 </div>: 
				 <Route exact path="/" component={WelcomeContainer}/>}
				 {!currentUser.id && !lastLocation && <CookieConsent
				    location="bottom"
				    buttonText="Ok"
				    cookieName="trvlpageCookie"
				    style={{ background: "#2B373B" }}
				    buttonStyle={{ color: "#4e503b", fontSize: "11px" }}
				    expires={574}
				>
				    This site uses cookies to provide you the best user experience. By using trvlpage, you Connect our use of cookies. For more info, check out our 
							<a 
								className="textTitle textLink"
								href={'https://www.trvlpage.com/privacy-policy'}  
								target="_blank"
								rel="noopener noreferrer">Privacy Policy</a>
				</CookieConsent>
				}
			
			</div>
		);
	}
}


export default compose(
	withApollo,
	withRouter,
	withLastLocation,
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(Main);
