import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";


import initialPic from '../assets/globe.jpg'


const EntryCode = props => {
	const {
		ask,
		error, 
		handleClose, 
		handleSubmit, 
		mutationFunction
	} = props

	

	return (
		<div className="entryCode initialScreen">
			<div className="initialScreenLeft">
					<div className="headerHolder initialHeaderHolder">
						<div className="mainHeader initialHeader">Can we access your contacts?</div>
						<div className="headerUnderline initialHeaderUnderline"></div>
					</div>
					{ask === 1 ? <div className="marginBottom formAdditionalText">Trvlpage is all about connecting with your friends</div> :
					<div className="marginBottom formAdditionalText">It will be lonely without them</div>}
									
					<Formik
			
				     
							onKeyDown={(e) => {
				                if (e.key === 'Enter') {
				                  e.preventDefault()
				                }
				            }} 
				    >
				      {({ isSubmitting, setSubmitting, values }) => (
				        <Form 
				        	className="form"
				        >
				        	<div>this will be an image that looks like the contact request and has the same text</div>
								
					       	<div className="formButtons">
										<button className="secondaryButton formButton" onClick={() => handleClose(setSubmitting)}>Later</button> 
										<button className="primaryButton formButton" onClick={() => handleSubmit(values, setSubmitting)} disabled={isSubmitting}>Connect</button>
									</div>
				           <div className="progressDots">
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot progressDotFilled"></div>
						         	<div className="progressDot"></div>
								  		
					      		</div>
				       	 
				        </Form>
				      )}
				    </Formik>
						
				</div>
			<div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="formPicture"/>
			</div>

		</div>

	);
};

export default EntryCode;