import React from "react";
import { Mutation, compose, graphql } from "react-apollo";
import { Route, withRouter } from "react-router-dom"
import { withLastLocation } from 'react-router-last-location';
import CookieConsent from "react-cookie-consent";
import axios from "axios";
import { analytics } from '../index.js';

import history from "../history"


import "../stylesheets/Icons.css"
import "../stylesheets/general.css"

import { getCurrentUser } from "../apollo/state/queries"
import { GET_ATTRACTION_PICTURES, GET_LOCATION_ATTRACTIONS, GET_LOCATION_PICTURES, GET_USER_AND_LOCATIONS } from "../apollo/db/queries"
import { CREATE_LOCATION_AND_ATTRACTION_INSTA } from "../apollo/db/mutations"

import InstagramAddPics from "../components/InstagramAddPics"


const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}


class InstaConnectionContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			attrValues: [],
			attractions: [],
			imageUrls: [], 
			locValues: [], 
			locations: [],
			next: null


		};

		this.handleCreatePictures = this.handleCreatePictures.bind(this)
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleSelect = this.handleSelect.bind(this)
	}

 	async componentDidMount(){
 		let res;
 		const args = window.location.search.split('&')
 		const accessToken = args[0].split('=')[1]
 		let userId = args[1].split('=')[1]
 		const imageUrls = []
 			axios.get(`https://graph.instagram.com/${userId}/media?fields=media_url,permalink,id&access_token=${accessToken}`)
 			.then(res => {
 				res.data.data.forEach(pic => {
	 			if (pic.children){
	 				pic.children.data.forEach(childPic => {
	 					console.log('childPic media_url', childPic.media_url.slice(0,12))
	 					if (childPic.media_url.slice(0,13) !== 'https://video'){
	 						imageUrls.push(childPic)	
	 					}
	 				})
	 			} else {
	 				imageUrls.push(pic)

	 			}
 			
		 		})
		 				 				console.log('imageUrls', imageUrls)

		 		const next = res.data.paging.next 
		 		this.setState({imageUrls: imageUrls, next: next})
 			})
 			.catch(err => {
 			//for some reason sometimes a userId is off by one. This is coming from Instagram's side
	 				let lastNumber = userId.slice(-2)
		 			let newLastNumber = Number(lastNumber) + 1
		 			let newUserId = userId.slice(0,-2).concat(newLastNumber)
		 			console.log('newUser', newUserId, accessToken)
		 			//there is something super janky happening where it wont allow 1 to be added to the complete user id
		 			axios.get(`https://graph.instagram.com/${newUserId}/media?fields=media_url,permalink,id&access_token=${accessToken}`)
		 			.then(res => {
		 				res.data.data.forEach(pic => {
				 			if (pic.children){
				 				pic.children.data.forEach(childPic => {
				 					console.log('childPic media_url', childPic.media_url.slice(0,12))
				 					if (childPic.media_url.slice(0,13) !== 'https://video'){
				 						imageUrls.push(childPic)	
				 					}
				 				})
				 			} else {
				 				imageUrls.push(pic)

				 			}
			 			})
			 			console.log('imageUrls', imageUrls)

			 			const next = res.data.paging.next 
 						this.setState({imageUrls: imageUrls, next: next})
			 		})

		 			//res = await axios.get(`https://graph.instagram.com/${newUserId}/media?fields=media_url,permalink,children{media_url,permalink}&access_token=${accessToken}`)
	 			})
 				.catch(err => {

	 				console.log('trying this one')
	 				let lastNumber = userId.slice(-2)
		 			let newLastNumber = Number(lastNumber) - 1
		 			let newUserId = userId.slice(0,-2).concat(newLastNumber)
		 			//there is something super janky happening where it wont allow 1 to be added to the complete user id
		 			axios.get(`https://graph.instagram.com/${newUserId}/media?fields=media_url,permalink,id&access_token=${accessToken}`)
		 			.then(res => {
		 				res.data.data.forEach(pic => {
				 			if (pic.children){
				 				pic.children.data.forEach(childPic => {
				 					console.log('childPic media_url', childPic.media_url.slice(0,12))
				 					if (childPic.media_url.slice(0,13) !== 'https://video'){
				 						imageUrls.push(childPic)	
				 					}
				 				})
				 			} else {
				 				imageUrls.push(pic)

				 			}
		 				})
		 				console.log('imageUrls', imageUrls)
		 				const next = res.data.paging.next 
 						this.setState({imageUrls: imageUrls, next: next})
		 			})
 				})
 			
 	}

	handleSelect(selected, index, type){
		const { attractions, locations } = this.state
		if (type === 'instagramLocation'){
			const newLocations = locations.slice()
			newLocations[index] = selected
			this.setState({locations: newLocations, stateError: null})
		} else {
			const newAttractions = attractions.slice()
			newAttractions[index] = selected
			this.setState({attractions: newAttractions, stateError: null})
		}
	

	}

	async handleLoadMore(){
		console.log('this is running')
		const { imageUrls, next } = this.state
		const res = await axios.get(next)
		const newImageUrls = res.data.data
 		const newNext = res.data.paging.next 
 		const combinedUrls = imageUrls.concat(newImageUrls)
 		this.setState({imageUrls: combinedUrls, next: newNext})

	}

	

	async handleCreatePictures(createLocationAndAttractionInsta, setSubmitting){
		const { attractions, imageUrls, locations } = this.state
		const { handleClose } = this.props

		setSubmitting(true)

		const imagePromises = []
		const instaImageUrls = []
		const instaImageIds = []

		const finalLocs = locations.filter(loc => loc)
		const finalAttrs = attractions.filter(attr => attr)

		locations.forEach((location, index) => {
			if (location){
				console.log('location', location, index)
				const imageUrl = imageUrls[index].permalink
				const imageId = imageUrls[index].id
				//const res = axios.get(`https://graph.facebook.com/v9.0/instagram_oembed?url=${imageUrl}&maxwidth=320&fields=thumbnail_url%2Cauthor_name%2Cprovider_name%2Cprovider_url&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}.`)
				const res = axios(`${getUrl()}/instagram/embed?url=${imageUrl}`)
				imagePromises.push(res)
				instaImageUrls.push(imageUrl)
				instaImageIds.push(imageId)
			}

			
		})


		const imagesInfo = []

		Promise.all(imagePromises)
		.then(imagesResponses => {

			finalLocs.forEach((location, index) => {

					const localityObj = location.address_components.find(component => {
						return component.types.includes('locality')
					})
					//locality and country should always exist (this is just an extra protective layer in case it doesn't)
					const locality = localityObj ? localityObj.long_name : null
					const adminLevel1Obj = location.address_components.find(component => {
						return component.types.includes('administrative_area_level_1')
					})
					const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
					const countryObj = location.address_components.find(component => {
						return component.types.includes('country')
					})
					const country = countryObj ?countryObj.long_name : null

					let attrType, attrName, attrPlaceId, attrLocality, attrAdminLevel1, attrCountry, attrLat, attrLng
					
					if (finalAttrs[index]){
						const attraction = finalAttrs[index]
						console.log('attraction', attraction)
						const localityObj = attraction.address_components.find(component => {
						return component.types.includes('locality')
						})
						//locality and country should always exist (this is just an extra protective layer in case it doesn't)
						attrLocality = localityObj ? localityObj.long_name : null
						const adminLevel1Obj = attraction.address_components.find(component => {
							return component.types.includes('administrative_area_level_1')
						})
						attrAdminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
						const countryObj = attraction.address_components.find(component => {
							return component.types.includes('country')
						})
						attrCountry = countryObj ? countryObj.long_name : null

						let type;
						if (attraction.types.includes('restaurant') || attraction.types.includes('food') || attraction.types.includes('bar') || attraction.types.includes('bar')){
							 attrType = "RESTAURANT"
						} else if (attraction.types.includes('lodging')){
							attrType = "LODGING"
						} else {
							attrType = "ACTIVITY"
						}

						attrName = attraction.name
						attrPlaceId = attraction.place_id
						attrLat = attraction.geometry.location.lat()
						attrLng = attraction.geometry.location.lng() 
					}

					if (imagesResponses.data !== 'this user is on private'){
						const instaImageInfo = imagesResponses[index].data

						const imageInfo = Object.assign({}, {
							locPlaceId: location.place_id,
							locLocality: locality, 
			 			 	locAdminLevel1: adminLevel1,
			 			 	locCountry: country,
			 			 	locFullName: location.formatted_address, 
							locLat: location.geometry.location.lat(), 
							locLng: location.geometry.location.lng(), 
							attrType: attrType,
							attrName: attrName, 
							attrPlaceId: attrPlaceId,
							attrLocality: attrLocality, 
							attrAdminLevel1: attrAdminLevel1,
							attrCountry: attrCountry, 
							attrLat: attrLat, 
							attrLng: attrLng, 
							publicUrl: instaImageInfo.thumbnail_url, 
							instaAuthorName: instaImageInfo.author_name, 
							instaUrl: instaImageUrls[index],
							instaId: instaImageIds[index]
			 			})
						imagesInfo.push(imageInfo)
					
					} else {

							const imageInfo = Object.assign({}, {
								locPlaceId: location.place_id,
								locLocality: locality, 
				 			 	locAdminLevel1: adminLevel1,
				 			 	locCountry: country,
				 			 	locFullName: location.formatted_address, 
								locLat: location.geometry.location.lat(), 
								locLng: location.geometry.location.lng(), 
								attrType: attrType,
								attrName: attrName, 
								attrPlaceId: attrPlaceId,
								attrLocality: attrLocality, 
								attrAdminLevel1: attrAdminLevel1,
								attrCountry: attrCountry, 
								attrLat: attrLat, 
								attrLng: attrLng, 
								publicUrl: null, 
								instaAuthorName: null, 
								instaUrl: instaImageUrls[index], 
								instaId: instaImageIds[index]
			 			})
						imagesInfo.push(imageInfo)
					}
				
				})
						

			try {
				const res = createLocationAndAttractionInsta({
					variables: {
						input: {
							instagramAdds: imagesInfo
						}
					}
				})
				setSubmitting(false)
				if (process.env.NODE_ENV !== 'development'){
				 	 analytics.logEvent('add_insta_pics', {
				 		pictures: imagesInfo.length, 
				 		attractions: imagesInfo.filter(info => info.attrPlaceId).length
				 	});
				}

				handleClose()
			} catch(err) {
				handleClose()
				setSubmitting(false)
				console.log('error', err)

			}
			
		
		})
		
		
						
	}
	

	render() {
		const { attrValues, imageUrls, locations, locValues } = this.state 
		const { currentUser, handleClose } = this.props
		return (<div className="instaConnectionContainer">
			<Mutation
		      mutation={CREATE_LOCATION_AND_ATTRACTION_INSTA}
		      refetchQueries={(result) => {
			      	const queries = [{query: GET_USER_AND_LOCATIONS, variables: {id: currentUser.id}}]
			      	const attrIds = []
			      	const locIds = []
			      	const locsAndAttrs = result.data.createLocationAndAttractionInsta.instagramAddResultData
			      	locsAndAttrs.forEach(locAndAttr => {
			      		if (locAndAttr.attrId && !attrIds.includes(locAndAttr.attrId)){
			      			attrIds.push(locAndAttr.attrId)
			      			queries.push({query: GET_ATTRACTION_PICTURES, variables: {attrId: locAndAttr.attrId}}) 
			      		}
			      		if (!locIds.includes(locAndAttr.locId)){
			      			locIds.push(locAndAttr.locId)
			      			queries.push({query: GET_LOCATION_ATTRACTIONS, variables:{locId: locAndAttr.locId, currentUserId: currentUser.id}})
			      			queries.push({query: GET_LOCATION_PICTURES, variables: {locId: locAndAttr.locId}})
			      		}
			      	})
			      	
			      	return queries
			    }}
		    >
	      {(createLocationAndAttractionInsta, { data, loading, error }) => {
	      return <InstagramAddPics 
	      		attrValues={attrValues}
	      		createLocationAndAttractionInsta={createLocationAndAttractionInsta}
 				handleClose={handleClose}
				handleCreatePictures={this.handleCreatePictures}
				handleSelect={this.handleSelect}
				loadMore={this.handleLoadMore}
				locations={locations}
				imageUrls={imageUrls}
				locValues={locValues}
			/>
		    }}
		</Mutation>
			
		</div>);
	}
}


export default compose(
	withRouter,
	withLastLocation,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(InstaConnectionContainer);
