import React from "react";
import { Query, compose, graphql, withApollo } from "react-apollo";
import { withLastLocation } from 'react-router-last-location';
import { Mutation } from "react-apollo";
import axios from "axios"
import * as Yup from "yup"
import { analytics } from '../index.js';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';


// import "../stylesheets/containers/LocationContainer.css"

import history from "../history"

import AddAttractionContainer from "../containers/AddAttractionContainer"
import AllPicturesView from "../components/AllPicturesView"
import AttractionsContainer from "../containers/AttractionsContainer";
import AttractionDetailsContainer from "../containers/AttractionDetailsContainer"
import CreateMessageContainer from "./CreateMessageContainer"
import Deletion from "../components/Deletion"
import LocationDetails from "../components/LocationDetails"
import LocationHeader from "../components/LocationHeader";
import Pictures from "../components/Pictures";
import MapView from "../components/MapView";
import LocationTipsContainer from "./LocationTipsContainer"
import PictureViewContainer from "../containers/PictureViewContainer";
import SaveCallout from "../components/SaveCallout";
import UploadPicturesContainer from "./UploadPicturesContainer"

import { getCurrentUser, getPopup } from "../apollo/state/queries";
import { setCurrentUser, setPopup } from "../apollo/state/mutations";
import { 
	CREATE_LOCATION_PICTURES, 
	DELETE_LOCATION, 
	UPDATE_LOCATION, 
	UPDATE_USER,
	VIEW_LOCATION  
} from "../apollo/db/mutations"
import {
	GET_FOLLOWING_INFO,
	GET_LOCATION_ATTRACTIONS,
	GET_LOCATION_PICTURES, 
	GET_LOCATION_VIEW_AND_DATES, 
	GET_USER_AND_LOCATIONS, 
	GET_USER_VIEW_BY_ID 
} from "../apollo/db/queries"

class LocationContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addAttraction: 'add',
			addAttractionType: null,
			addedAttraction: null,
			currentUserId: null,
			deletionId: null, 
			deletionName: null,
			initialPicIndex: 0,
			loading: false,
			locationId: null,  
			locationInitialValues: {},
			run: false,
			showAddAttraction: false,
			showAllPictures: false,
			showCreateMessage: false,
			showDelete: false,
			showEdit: false, 
			showPicture: false,
			showSaveCallout: false,
			showUpload: false, 
			steps: [{
               target: '.locJoyride1',
               content: `Edit your overall review here. \n\n To edit a review of a specific place, click the pencil icon on the review.`, 
               disableBeacon: true
             },
             {
               target: '.locJoyride2',
               content: `To delete this location, click here. You will need to confirm your deletion.`, 
               disableBeacon: true
             },
             {
               target: '.locJoyride3',
               content: `Upload pictures for this location here.`, 
               disableBeacon: true
             }, 
             {
               target: '.locJoyride4',
               content: `Add or edit overall location tips here.`, 
               disableBeacon: true
             }, 
             {
               target: '.locJoyride5',
               content: `Add stars, reviews, and tips for Activities, Food & Drinks, and Lodging with these pins.`, 
               disableBeacon: true
             }],
		};

		this.handleDeleteLocation = this.handleDeleteLocation.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleFinishAddAttraction = this.handleFinishAddAttraction.bind(this)
		this.handleBack = this.handleBack.bind(this)
		this.handleHideAddAttraction = this.handleHideAddAttraction.bind(this)
		this.handleHideAllPictures = this.handleHideAllPictures.bind(this)
		this.handleHideCallout = this.handleHideCallout.bind(this)
		this.handleHideCreateMessage = this.handleHideCreateMessage.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleHidePicture = this.handleHidePicture.bind(this)
		this.handleHideUpload = this.handleHideUpload.bind(this)
		this.handleJoyride = this.handleJoyride.bind(this)
		this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this)
		this.handleSelectPicture=this.handleSelectPicture.bind(this)
		this.handleSelectPin = this.handleSelectPin.bind(this)
		this.handleSelectAttraction = this.handleSelectAttraction.bind(this)
		this.handleSetAddedAttraction = this.handleSetAddedAttraction.bind(this)
		this.handleShowAddAttraction = this.handleShowAddAttraction.bind(this)
		this.handleShowAllPictures = this.handleShowAllPictures.bind(this)
		this.handleShowCreateMessage = this.handleShowCreateMessage.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleShowPicture = this.handleShowPicture.bind(this)
		this.handleShowUpload = this.handleShowUpload.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.handleUpdateLocation = this.handleUpdateLocation.bind(this)
		this.handleUpdateLocationViews = this.handleUpdateLocationViews.bind(this)
		this.handleUpdateUser = this.handleUpdateUser.bind(this)
		this.handleViewProfile = this.handleViewProfile.bind(this)
	}



	async componentDidMount(){
		const { locationId } = this.state
		const { client, currentUser, setPopup } = this.props
		const newLocationId = parseInt(window.location.href.split('/').slice(-1)[0])
		
		try {
			await client.mutate({
			   	 	mutation: VIEW_LOCATION, 
			   	 	variables: {
			   	 		input: {
			   	 			locId: newLocationId, 
			   	 			viewerId: currentUser.id
			   	 		}
			   	 	}
		 	})
		 	if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('view_location', {
					locId: newLocationId

				});
			}

		} catch {
			console.log("Error")
		}

		
		await setPopup({
			variables: {
				popup: false
			}
		})
	 	
		this.setState({
			currentUserId: currentUser ? currentUser.id : null,
			locationId: newLocationId, 
			showEdit: false, 
			showDelete: false,
			showUpload: false
		}, () => {
			this.handleUpdateLocationViews()
			this.handleJoyride(newLocationId)
		})


		if(currentUser.locationViews === 2 && currentUser.pageViews !== 6){
			 setTimeout(() => {
			 	this.setState({showSaveCallout: true})
			 }, 2000)	
		}

	}

	async componentDidUpdate(){
		const { currentUserId, locationId } = this.state
		const { client, currentUser, screenView, setPopup } = this.props
		const newLocationId = parseInt(window.location.href.split('/').slice(-1)[0])


		if (locationId !== newLocationId && !(isNaN(locationId) && isNaN(newLocationId))){
			try {
				await client.mutate({
				   	 	mutation: VIEW_LOCATION, 
				   	 	variables: {
				   	 		input: {
				   	 			locId: newLocationId,
				   	 			viewerId: currentUser.id
				   	 		}
				   	 	}
			 	})
			 	if (process.env.NODE_ENV !== 'development'){
				 	await analytics.logEvent('view_location', {
						locId: newLocationId

					});
				}


			} catch {
				console.log("Error")
			}
		
			
			if (screenView.popup){
				await setPopup({
					variables: {
						popup: false
					}
				})

			}
					

			this.setState({
				currentUserId: currentUser.id,
				locationId: newLocationId, 
				showEdit: false, 
				showDelete: false, 
				showUpload: false
			}, () =>{
				this.handleUpdateLocationViews()
				this.handleJoyride(newLocationId)
			})


			if(currentUser.locationViews === 2 && currentUser.pageViews !== 7){
				console.log('this is running with ', currentUser)
				 setTimeout(() => {
				 	this.setState({showSaveCallout: true})
				 }, 2000)	
			}
		}

	}

	async handleJoyride(newLocationId){
		const { client, currentUser } = this.props
		const res = await client.query({
	   	 	query: GET_LOCATION_VIEW_AND_DATES, 
	   	 	variables: { locId: newLocationId}
 		})
 		const location = res.data.locationViewAndDates

 		if (currentUser.id && location.userId === currentUser.id && !currentUser.seenLocExplanation){
			setTimeout(() => {
				try {
					this.setState({run: true}, () => {
						 this.handleUpdateUser()
					})
					 
				} catch {
					console.log('error with copilot')
				}
				
			}, 1000)
 		}
	}

	handleJoyrideCallback(data){
	    const { action, index, status, type } = data;

	    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
	      // Update state to advance the tour
	      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
	    }
	    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
	      // Need to set our running state to false, so we can restart if we click start again.
	      this.setState({ run: false });
	    }

 	};

	async handleUpdateUser(){
		const { currentUser, setCurrentUser, client } = this.props
		const userPatch = { seenLocExplanation: true } 

		const newCurrentUser = Object.assign({}, currentUser, userPatch)

			try {
				
				await client.mutate({
					mutation: UPDATE_USER,
					variables: {
						input: {
							id: currentUser.id,
							userPatch: userPatch
							}	
						}
				})


				await setCurrentUser({
              	  variables: newCurrentUser
	            })
				
			} catch {
				console.log('error')
			}
	}


	
	async handleDeleteLocation(deleteLocation){
		const { currentUser, setPopup } = this.props
		const { locationId } = this.state
		try {
				await deleteLocation({
					variables: {
						input: {
							id: locationId
							}
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ loading: false, error: null, showDelete: false })

				history.push(`/profile/${currentUser.id}`)
			} catch {
				console.log('error')
			}
	}

	async handleDelete(mutationFunction){
		const { currentUser, setPopup } = this.props
		const { deletionId } = this.state
		try {
			await mutationFunction({
			variables: {
				input: {
					id: deletionId
					}
				}
			})

			await setPopup({
				variables: {
					popup: false
				}
			})

			this.setState({ loading: false, showDelete: false, deletionId: null, deletionName: null })

		} catch {
			
			console.log('error')
		}
	}

	async handleUpdateLocationViews(){
		const { currentUser, setCurrentUser, client } = this.props
		const userPatch = { locationViews: currentUser.locationViews + 1 } 

		const newCurrentUser = Object.assign({}, currentUser, userPatch)


			try {

				await setCurrentUser({
              	  variables: newCurrentUser
	            })
				
			} catch {
				console.log('error')
			}
	}



	handleSelectAttraction(attractionId){
		history.push(`/attraction/${attractionId}`)
	}

	async handleUpdateLocation(values, updateLocation, createLocationDates, setSubmitting){
		const { locationId } = this.state
		const { setPopup } = this.props

		setSubmitting(true)

		const overallReview = values.overallReview
		try {
				await updateLocation({
					variables: {
						input: {
							locId: locationId,
							newOverallReview: overallReview
							}	
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ loading: false, showEdit: false })
				setSubmitting(false)
				if (process.env.NODE_ENV !== 'development'){
					await analytics.logEvent('add_loc_details');

				}
			} catch {
					console.log('error')
					setSubmitting(false)
			}
	}



	async handleSelectPin(pin){
		console.log('this will do something')

	}

	handleBack(){
		history.goBack()
	}

	async handleShowAddAttraction(type){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		await this.setState({showAddAttraction: true, addAttractionType: type})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('show_add_attraction', {
				view: 'location'
			});
		}
	}

	async handleHideAddAttraction(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAddAttraction: false, addAttraction: 'add', })
	}

	async handleFinishAddAttraction(){
		const { showAddAttraction } = this.state
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})

		this.setState({addAttraction: 'add', showAddAttraction: false})
		
	}

	async handleShowCreateMessage(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showCreateMessage: true})
	}

	async handleShowDelete(location){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: location.id, deletionName: this.getLocName(location)})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null, deletionName: null})
	}

	async handleHideCreateMessage(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showCreateMessage: false})
	}

	handleSetAddedAttraction(attraction){
		attraction.tags = [];
		this.setState({addedAttraction: attraction, addAttraction: 'details'})
	}

	handleHideCallout(){
		this.setState({showSaveCallout: false})
	}

	
	async handleShowEdit(overallReview){
		const { setPopup } = this.props

		await setPopup({
			variables: {
				popup: true
			}
		})

		this.setState({showEdit: true, locationInitialValues: {overallReview: overallReview}})
	}

	async handleHideEdit(){
		const { setPopup } = this.props

		await setPopup({
			variables: {
				popup: false
			}
		})

		this.setState({showEdit: false})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}

	async handleShowPicture(initialPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: initialPicIndex})
	}

	async handleHidePicture(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showPicture: false})
	}

	async handleShowUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		await this.setState({showUpload: true})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('show_add_location_pictures', {
				view: 'location'
			});
		}
	}

	async handleHideUpload(){
		const { setPopup } = this.props
		await this.setState({showUpload: false})
		await setPopup({
			variables: {
				popup: false
			}
		})
	}

	async handleShowAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAllPictures: true})
	}

	async handleHideAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAllPictures: false})
	}

	async handleSelectPicture(id, currentPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: currentPicIndex, showAllPictures: false})
	}

	handleViewProfile(locationUserId){
		history.push(`/profile/${locationUserId}`)
	}

	getVisibleAttractions(attractions){
		const { currentUser } = this.props
		return attractions.filter(attraction => {
			return attraction.visibility === 'PUBLIC' || currentUser.id === attraction.userId
		})
	}

	getLocName(location) {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName.toUpperCase()
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName.toUpperCase()
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName.toUpperCase()
		} else {
			return location.country.toUpperCase()
		}
	}


	renderDelete(){
		const { currentUser } = this.props
		const { deletionName, locationId } = this.state

		return (<Mutation
		      mutation={DELETE_LOCATION}
		      refetchQueries={[{query: GET_USER_AND_LOCATIONS, variables: {id: currentUser.id}}]}
		    >
		      {(deleteLocation, { loading, error }) => {

		      	return (<Deletion 
		      		error={error}
			    	id={locationId} 
			    	handleHideDelete={this.handleHideDelete}
			    	handleDelete={this.handleDeleteLocation}
			    	mutationFunction={deleteLocation}
			    	name={deletionName}
			    	type="location"
			    />)
		      	}}
		</Mutation>)
	}

	renderUpload(){
		const { locationId } = this.state

		return <UploadPicturesContainer 
			handleClose={this.handleHideUpload}
			locationId={locationId}

		/>
	}


	renderSaveCallout(){
		return <SaveCallout 
			handleHideCallout={this.handleHideCallout}
		/>
	}

	renderCreateMessage(location, user){
		// const user = {
		// 	id: location.userId, 
		// 	firstName: location.firstName, 
		// 	lastName: location.lastName, 
		// 	publicUrl: location.publicUrl
		// }

		return <CreateMessageContainer 
			handleClose={this.handleHideCreateMessage}
			location={location}
			recipient={user}
		/>
	}

	renderEdit(){
		const { locationId, locationInitialValues, showDelete, showTooltip, tooltipType } = this.state

 		const locDetailsSchema = Yup.object().shape({
		  	overallReview: Yup.string()
		  	.max(2000, 'Review section is too long. Maximum of 2000 characters.')

		})
			return <Mutation 
				mutation={UPDATE_LOCATION}
				refetchQueries={[{query: GET_LOCATION_VIEW_AND_DATES, variables: {locId: locationId}}]}
				awaitRefetchQueries={true}
			>
				{( updateLocation, { loading, error }) => {
					
					return  <div>
						<LocationDetails 
					    	closeText="Cancel"
					    	error={error}
					    	handleClose={this.handleHideEdit}
					    	handleSubmit={this.handleUpdateLocation}
					    	handleToggleTooltip={this.handleToggleTooltip}
							initialValues={locationInitialValues}
					    	locationId={locationId} 
					    	locDetailsSchema={locDetailsSchema}
							showTooltip={showTooltip}
							submitText="Submit"
							tooltipType={tooltipType}
					    	updateLocation={updateLocation}
		
					    	/>
					</div>
				}}
			</Mutation>
						

										
	}

	renderLocation(){
		const { currentUser, lastLocation } = this.props
		const { isEditing, locationId, showCreateMessage, showDelete } = this.state
		if ( locationId ){
			return <Query query={GET_LOCATION_VIEW_AND_DATES} variables={{locId: locationId}}>
				{({ loading, error, data }) => {
					if (loading) return <div>Loading...</div>;
					if (error) return <div>Error</div>;
					if(!data.locationViewAndDates) return <div>This location doesnt exist </div>
					const location = data.locationViewAndDates
				
					if (location){
						const followingUserId = currentUser.id > 0 ? currentUser.id : 0
						return <Query 
							query={GET_FOLLOWING_INFO}
				    		variables= {{ profileId: location.userId, currentUserId: followingUserId }}
						>
							{({data, loading, error}) => {
								if(loading) {return <div>Loading..</div>}
								if(error) {return <div>Error</div>}
								const followingInfo = data.followingInfo

								return <Query 
									query={GET_USER_VIEW_BY_ID}
							    	variables= {{ profUserId: location.userId }}
									>
										{({data, loading, error}) => {
											if(loading) {return <div>Loading..</div>}
											if(error) {return <div>Error</div>}

											const publicity = data.userViewById.publicity
											const user = data.userViewById

										
											return <div>
												{showDelete && this.renderDelete()} 
												{showCreateMessage && this.renderCreateMessage(location, user)}
												<LocationHeader
													blocked={followingInfo && followingInfo.status === "BLOCKED"}
													currentUser={currentUser}
													handleBack={this.handleBack}
													handleShowCreateMessage={this.handleShowCreateMessage}
													handleShowDelete={this.handleShowDelete}
													handleShowEdit={this.handleShowEdit}
													handleShowUpload={this.handleShowUpload}
													handleViewProfile={this.handleViewProfile}
													lastLocation={lastLocation}
													location={location}
													user={user}
												/>



								
								

											

											<div className="locationContent">
												{((followingInfo && followingInfo.status === 'BLOCKED') || 
												(publicity === "PRIVATE" && (!followingInfo || followingInfo.status !== "APPROVED"))) 
												&& currentUser.id !== location.userId  ?
												 <div>

													 {this.renderLocationPictures(false, location.userId)}
													 {this.renderLocationTips(location)}
													 {this.renderAttractions(location.userId)}
												</div> : 
												 <div>
											 		{this.renderLocationPictures(true, location.userId)}
											 		{this.renderLocationTips(location)}
													{this.renderAttractions(location.userId)}

												
										 		</div>}
										 	</div>
										
										</div>
									}}
								</Query>

							}}
						</Query>	
					} 
		      
				}}
			</Query>
		} else {
			return <div>Loading... </div>
		}
		
	}

	renderLocationPictures(picturesViewable, locationUserId){
		const { locationId, showAllPictures, showPicture, initialPicIndex } = this.state
		const { currentUser } = this.props
		return(<Query query={GET_LOCATION_PICTURES} variables={{locId: locationId}}>
						{({ loading, error, data }) => {
						if (loading) return <div>Loading...</div>;
						if (error) return <div>Error</div>;


						const pictures = data.locationPictures.nodes

						const displayPics = (pictures.length && pictures[0] !== null) ? pictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []

						return (<div>
							<div>
								<Pictures 
									currentUser={currentUser}
									pictures={displayPics}
									maxIndex={10}
									handleShowPicture={this.handleShowPicture}
									handleShowAll={this.handleShowAllPictures}
									handleShowUpload={this.handleShowUpload}
									locationUserId={locationUserId}
									picturesViewable={picturesViewable}
									type="location"
									view="location"
								/>
							</div>
							{showPicture && <PictureViewContainer 
								pictures={displayPics} 
								handleClose={this.handleHidePicture}
								initialPicIndex={initialPicIndex}
								locationId={locationId}
								currentUser={currentUser}
								view="location"
							/>}
							{showAllPictures && <AllPicturesView
								pictures={displayPics}
								handleClose={this.handleHideAllPictures}
								handleSelectPicture={this.handleSelectPicture}
								/>}
							</div>)
				      
					}}
					</Query>)
	}

	renderLocationTips(location){
		return <LocationTipsContainer
			location={location}
		 />
	}

	renderAttractions(locationUserId){
		const { 
			currentUser, 
			setPopup
		} = this.props
		const { addAttraction, addedAttraction, addAttractionType, locationId, showAddAttraction } = this.state
		const currentUserId = currentUser.id > 0 ? currentUser.id : 0;
		return (<Query query={GET_LOCATION_ATTRACTIONS} variables={{locId: locationId, currentUserId: currentUserId}}>
			{({ loading, error, data }) => {
				if (loading) return <div>Loading...</div>;
				if (error) return <div>Error</div>;
				const attractions = data.locationAttractions.nodes

				return (<Query query={GET_LOCATION_VIEW_AND_DATES} variables={{locId: locationId}}>
				{({ loading, error, data }) => {
					if (loading) return <div>Loading...</div>;
					if (error) return <div>Error</div>;
					const location = data.locationViewAndDates;
					return (<div>
						{showAddAttraction && (addAttraction === 'add' ? <AddAttractionContainer 
						currentUser={currentUser} 
						locationLat={location.lat} 
						locationLng={location.lng} 
						addAttractionType={addAttractionType}
						attractions={attractions}
						locationId={locationId}
						handleClose={this.handleHideAddAttraction}
						handleSetAddedAttraction={this.handleSetAddedAttraction}
						/> : 
						<AttractionDetailsContainer 
							addAttraction={true}
							attraction={addedAttraction}
							handleFinish={this.handleFinishAddAttraction}
							locationId={locationId}
						/>
						)}	
						<div className="attractionsSection">
								{attractions ? 
									<AttractionsContainer 
									currentUser={currentUser}
									attractions={this.getVisibleAttractions(attractions)} 
									handleSelectAttraction={this.handleSelectAttraction}
									handleShowAddAttraction={this.handleShowAddAttraction}
									locationId={location.id} 
									locationUserId={location.userId} 
									locationLat={location.lat}
									locationLng={location.lng}
									setPopup={setPopup}
									/>
									: <div> This user has not added any attractions for this location</div>}
						</div>

					
					</div>)

				}}
				</Query>)
				
			}}
		</Query>)
	}


	render() {
		const { run, stepIndex, steps } = this.state
		const { 
			error,
			loading,
			locationId, 
			showDelete, 
			showEdit,
			showSaveCallout, 
			showUpload 
		} = this.state
		const { screenView } = this.props 

		const popupClass = screenView.popup && "disabledBackground"
	
		return (
			<div className={`locationContainer ${popupClass}`}>
				<Joyride 
					callback={this.handleJoyrideCallback}
					continuous={true}
					showProgress={true}
          			showSkipButton={true}
					steps={steps} 
					run={run}

				/>
		
				{showSaveCallout && this.renderSaveCallout()}


			    {showUpload && this.renderUpload()}

			    {showEdit &&  this.renderEdit()}

			    {locationId !== null ? <div>
					{!isNaN(locationId) ? this.renderLocation() : <div>This location page does not exist</div>}
				</div> : <div>Loading...</div>}
			    



			</div>
		);
	}
}

export default compose(
	withApollo,
	withLastLocation,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}), 
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'}), 
	graphql(setCurrentUser, {name: 'setCurrentUser'})
)(LocationContainer);
