import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/BasicInfo.css"

import Autocomplete from "./Autocomplete"

import initialPic from '../assets/globe.jpg'


const BasicInfo = props => {
	const {
		basicSchema,
		currentCityValue,
		fromLogin,
		handleClose,
		handleChange,
		handleSelect,
		handleSubmit,
		initialValues,
		mutationFunction,
		optionalMutationFunction,
		thirdMutationFunction,
		stateError,
		title,
		view
	} = props;
	console.log('fromLogin', fromLogin)
	return (
		<div className={view === 'signup' ? 'basicInfo initialScreen' : "basicInfo popup popupXL" }>
			<div className={view === 'signup' ? "initialScreenLeft" : "popupContent" }>
				{view === 'signup' ? <div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">about yourself</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div> : <div className="secondaryHeader">{title}</div>}
				{fromLogin && <div>If you've already filled this out, please check your cookie settings or try a different browser.</div>}
				

				<Formik
					validationSchema={basicSchema}
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(
							values,
							setSubmitting,
							mutationFunction,
							optionalMutationFunction,
							thirdMutationFunction
							
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="form">
							
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="firstName">first name<div className="required">*</div></label>
								<Field 
									className="formField" 
									type="text" 
									name="firstName" 
									autoComplete="off"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						              }} 
									required 
								/>
							</div>
								<ErrorMessage className="formError" name="firstName" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="lastName">last name <div className="required">*</div></label>
								<Field 
									className="formField" 
									type="text" 
									name="lastName" 
									autoComplete="off"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						              }} 
									required 
								/>
							</div>
								<ErrorMessage className="formError" name="lastName" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="birthday">birthday</label>
								<Field 
									className="formField" 
									type="date" 
									name="birthday"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						              }}
								/>
							</div>
								<ErrorMessage className="formError" name="birthday" component="div" />
							{view === 'profile' && 
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="about">about</label>
									<Field className="formField formFieldLarge" rows={4} component="textarea" name="about" autoComplete="off"/>
								</div>}
									<ErrorMessage className="formError" name="about" component="div" />
								
							
								<div className="currentCityInfo">
									{initialValues.currentCity && <div className="formItem">
										<div className="textTitle textTitleForm">current city</div>
										<div>{initialValues.currentCity}</div>
									</div>}
									{view === 'profile' ? 
										<div className="textTitle textTitleForm">new current city</div> :
										<div className="textTitle textTitleForm">current city</div>}
								
									<Autocomplete  
										handleChange={handleChange}
								        handleSelect={handleSelect}
								        type="currentCity"
								        value={currentCityValue}
									/>
								
								</div>
							{stateError && <div className="error">{stateError}</div>}
							<div className="formButtons">
								{view === 'profile' && <button className="secondaryButton formButton" onClick={handleClose}>Cancel</button>} 
								<button className={view === 'profile' ? "primaryButton formButton" : "primaryButton singleFormButton"} type="submit" disabled={isSubmitting}>Submit</button>
							</div>
							{view === 'signup' && <div className="progressDots">
					         	<div className="progressDot"></div>
					         	<div className="progressDot"></div>
					         	<div className="progressDot progressDotFilled"></div>
					         	<div className="progressDot"></div>
					         	<div className="progressDot"></div>
					         	<div className="progressDot"></div>
	   				  		
			      		 	 </div>}
						

						</Form>}
				</Formik>
				
			</div>
		
			 {view === 'signup' && <div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="formPicture"/>
			</div>}
		</div>
	);
};

export default BasicInfo;
