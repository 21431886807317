import React from "react";
import { Mutation, compose, graphql, withApollo } from "react-apollo";
import { GoogleApiWrapper } from "google-maps-react";
import { analytics } from '../index.js';
import * as Yup from "yup"


import TripForm from "../components/TripForm"

import { getCurrentUser } from "../apollo/state/queries"
import { GET_UPCOMING_TRIPS, GET_FOLLOWERS_ALPHABETIZED } from "../apollo/db/queries"
import { CREATE_TRIP_GENERAL_LOCATIONS, CREATE_TRIP, CREATE_TRIP_USERS } from "../apollo/db/mutations"



class AddTripContainer extends React.Component {
	constructor() {
		super();
		this.state = {
		      loading: false,
		      sendNotifications: true, 
		      selected: null,
		      showTooltip: false, 
		      tooltipType: null, 
		      tripPublic: true, 
		      stateError: null

		    };
		      
    		this.handleCreateTrip = this.handleCreateTrip.bind(this)
    		this.handleToggleNotifications = this.handleToggleNotifications.bind(this)
    		this.handleTogglePublicity = this.handleTogglePublicity.bind(this)
    		this.handleSelect = this.handleSelect.bind(this)
    		this.sendPushNotification = this.sendPushNotification.bind(this)
		    this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
  
	}

	handleSelect(selected){
		this.setState({selected: selected})
	}

	handleToggleNotifications(){
		const { sendNotifications } = this.state
		this.setState({sendNotifications: !sendNotifications})
	}

	handleTogglePublicity(){
		const { tripPublic } = this.state
		this.setState({tripPublic: !tripPublic})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}


	async handleCreateTrip(values, setSubmitting, createTrip, createTripGeneralLocations, createTripUsers) {
		const { currentUser, handleClose, client } = this.props
		const { selected, sendNotifications, tripPublic } = this.state

		setSubmitting(true)

	
		if (!selected || !selected.place_id) {
			this.setState({stateError: 'Please select a location'})
			return null;
		}
		
		this.setState({loading: true})

		const localityObj = selected.address_components.find(component => {
			return component.types.includes('locality')
		})
		//locality and country should always exist (this is just an extra protective layer in case it doesn't)
		const locality = localityObj ? localityObj.long_name : null
		const adminLevel1Obj = selected.address_components.find(component => {
			return component.types.includes('administrative_area_level_1')
		})
		const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
		const countryObj = selected.address_components.find(component => {
			return component.types.includes('country')
		})
		const country = countryObj ?countryObj.long_name : null

		const locations = [{
		  fullName: selected.formatted_address, 
		  placeId: selected.place_id, 
		  locality: locality, 
		  adminLevel1: adminLevel1, 
		  country: country,
		  lat: selected.geometry.location.lat(), 
		  lng: selected.geometry.location.lng()
		}]


		try {
			const res = await createTrip({
				variables: {
					input: {
						trip: {
								startDate: values.startDate,
								endDate: values.endDate,
								stops: 'SINGLE',
								creatorId: currentUser.id,
								comments: values.comments
							}
						}	
					}
				})
			const tripId = res.data.createTrip.trip.id

			
			
			await createTripUsers({
				variables: {
		   	 		input: {
						parentTripId: tripId, 
						userIds: [currentUser.id], 
						adderUserId: currentUser.id
					}
				}
			})
			
			await createTripGeneralLocations({
			   	 	variables: {
			   	 		input: {
							parentTripId: tripId, 
							locations: locations, 
							notify: sendNotifications, 
							publicity: tripPublic
						}
					}
			})
			
			
			if (sendNotifications){
				const res =  await client.query({
 	  		   	 	query: GET_FOLLOWERS_ALPHABETIZED,
  	  	    		variables: { profUserId: currentUser.id }
  	  	    	})
			 	
			 	const followers = res.data.followersAlphabetized.nodes


			 	followers.forEach(follower => {
			 		if (follower.pushToken){
			 			console.log('sending push notification', follower)
			 			this.sendPushNotification(follower.pushToken, locality, adminLevel1, country, values.comments)
			 		}
			 	})
			}



			setSubmitting(false)
			handleClose()
			 if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_trip', {
				  page: 'add', 
				  stops: 'single', 
				  notify: sendNotifications, 
				  hasComments: !!values.comment
				});
			 }

		} catch(err) {
			console.log('error', err)
			setSubmitting(false)
		}
	
			
	}

	async sendPushNotification(token, locality, adminLevel1, country, comments){
		const { currentUser } = this.props
		let location; 
		console.log('locality', locality && country === 'United States', adminLevel1)
		if (locality && country === 'United States'){
			location = `${locality}, ${adminLevel1}`
		} else if (locality){
			location = `${locality}, ${country}`
		} else if (adminLevel1){
			location = `${adminLevel1}, ${country}`
		} else {
			location = `${country}`
		}
		console.log('location!!', location)

	    const message = {
	      to: token,
	      sound: 'default',
	      body: comments ? `${currentUser.firstName} ${currentUser.lastName} is headed to ${location}! ${currentUser.firstName} says "${comments}" Help them make their trip great and upload any recs you might have.` : `${currentUser.firstName} ${currentUser.lastName} is headed to ${location}! Help them make their trip great and upload any recs you might have.`,
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	     'mode': 'no-cors',
        'method': 'POST',
        'headers': {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
	      body: JSON.stringify(message),
	    });
  	};

	renderAddUpcomingTrip(){
		const { currentUser, handleClose } = this.props
		const { sendNotifications, showTooltip, stateError, tripPublic, tooltipType } = this.state


		const tripSchema = Yup.object().shape({
		  startDate: Yup.date(),
		  endDate: Yup.date().when('startDate', (st, schema) => {
		    	return Yup.date().min(st, "End Date must be after Start Date");
			})

		})

		return (<Mutation
		      mutation={CREATE_TRIP}
		    >
	      {(createTrip, { data, loading, error }) => {
	      	return 	(
			    <Mutation
				      mutation={CREATE_TRIP_GENERAL_LOCATIONS}
				      refetchQueries={[{query: GET_UPCOMING_TRIPS,  variables: {profUserId: currentUser.id}}]}
			    >
		        {(createTripGeneralLocations, { data, loading, error }) => {
		        	return 	(
			      		<Mutation
					      mutation={CREATE_TRIP_USERS}
					    >
					    {(createTripUsers, { data, loading, error }) => {
					    	return 	(
								<TripForm
									error={error}
									createTrip={createTrip}
									createTripGeneralLocations={createTripGeneralLocations}
									createTripUsers={createTripUsers}
									handleSelect={this.handleSelect}
									handleToggleNotifications={this.handleToggleNotifications}
									handleTogglePublicity={this.handleTogglePublicity}
									handleToggleTooltip={this.handleToggleTooltip}
									handleSubmit={this.handleCreateTrip}
									handleClose={handleClose}
									sendNotifications={sendNotifications}
									showTooltip={showTooltip}
									stateError={stateError}
									tooltipType={tooltipType}
									tripPublic={tripPublic}
									tripSchema={tripSchema}
					     		 />
					        )}}
						</Mutation>
		     		)}}
				</Mutation>
			
		    )}}
		</Mutation>)
	}

	
	render() {
		
		return (
			<div className="addTripContainer">
			

			{this.renderAddUpcomingTrip()}

				
			</div>
		);
	}
}

export default compose(
	withApollo, 
graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	GoogleApiWrapper({
			apiKey: process.env.REACT_APP_GOOGLE_API_KEY
	})
	)(AddTripContainer);

