import React from "react";
import { Link } from "react-router-dom";

//import PinchZoomPan from "react-responsive-pinch-zoom-pan";

import FollowButtonContainer from "../containers/FollowButtonContainer";

import "../stylesheets/components/ProfileHeader.css"

import {
	Button
} from 'reactstrap';

const ProfileHeader = props => {
	const {
		blocked,
		currentUser,
		createNewHeaderPicture,
		handleSetHeader,
		handleShowAddLocation,
		handleShowCreateMessage,
		handleShowEdit,
		handleShowFollow,
		handleShowHeaderUpload,
		handleShowInstaPrompt,
		handleShowListView,
		handleShowUpload,
		headerPic,
		headerSrc,
		locationsNumber,
		profUserId,
		savesNumber,
		user, 
	} = props;
	console.log('saves', savesNumber)
	return (
		<div className="profileHeader">
				<div className="profHeaderLeft">
					<img
						src={user.publicUrl}
						className="profPic"
						alt="profilePicture"
					/>

					<div className="profHeaderContent">
				
						<div className="secondaryHeader secondTextColor">{(user.firstName + ' ' + user.lastName)}</div>
							
				

						{user.currentCity && <div className="titleAndText"><div className="textTitle">Current City:</div><div>{user.currentCity}</div></div>}
						<div className="userInputText fontSmall">{user.about}</div>
					
						{currentUser.id === profUserId && <div className="icons">
								
								<i className="material-icons activeIcon iconMedium profJoyride1" onClick={() => handleShowUpload()}>add_a_photo</i>
								<i className="material-icons activeIcon iconMedium profJoyride2" onClick={() => handleShowEdit(user)}>edit</i>
								<i className="material-icons activeIcon iconMedium profJoyride3" onClick={() => handleShowAddLocation()}>add_location</i>
								<img className="activeIcon iconMedium instaIcon profJoyride4" src={require('../assets/instagram.png')} onClick={() => handleShowInstaPrompt()}/>

							</div>}
						{(currentUser.id !== profUserId && currentUser.id > 0 && !blocked) && <div className="icons">
							<i className="material-icons activeIcon iconMedium" onClick={() => handleShowCreateMessage()}>message</i>
			
							<FollowButtonContainer profUserId={profUserId} view="profile" profUser={user}/>
							</div>
						}
						{currentUser.id !== profUserId && currentUser.id > 0 && <FollowButtonContainer profUserId={profUserId} view="profile" type="block" profUser={user}/>}
					</div>
					
				</div>
					

				<div className="profHeaderRight">
					<div className="profHeaderNumbers marginBottom">
						<div className="numberItem" onClick={() => handleShowFollow('followers')}>
							<div className="number textLink">{user.followersNumber}</div>
							<div className="numberType">FOLLOWERS</div>
						</div>
						<div className="numberItem" onClick={() => handleShowFollow('following')}>
							<div className="number textLink">{user.followingNumber}</div>
							<div className="numberType">FOLLOWING</div>
						</div>
						<div className="numberItem" onClick={() => handleShowListView()}>
							<div className="number textLink">{locationsNumber}</div>
							<div className="numberType">LOCATIONS</div>
						</div>
						{currentUser.id === profUserId && <div className="numberItem">
							<Link to="/saved" className="number textLink">{savesNumber}</Link>
							{savesNumber === 1 ?  <div className="numberType">SAVE</div> : <div className="numberType">SAVES</div>}
						</div>}
					</div>
	
				</div>

				

 
		
		</div>
	);
};

export default ProfileHeader;
