import React from "react";
import { compose, graphql, withApollo, Query, Mutation } from "react-apollo";
import * as Yup from "yup"


import history from "../history"

import CommentsContainer from "../containers/CommentsContainer"
import Deletion from "../components/Deletion"
import ItineraryContainer from "../containers/ItineraryContainer"
import TripLocAttractionsContainer from "./TripLocAttractionsContainer"
import TripLocationHeader from "../components/TripLocationHeader"
import TripLocTipsContainer from "../containers/TripLocTipsContainer"
import UngroupLocations from "../components/UngroupLocations"

import { getCurrentUser, getPopup } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import { 
	GET_GROUPED_TRIP_LOCATIONS,
	GET_TRIP_GENERAL_ATTRACTIONS,
	GET_TRIP_GENERAL_LOCATION_BY_ID, 
	GET_TRIP_GENERAL_LOCATIONS, 
	GET_TRIP_ITINERARY_ITEMS, 
	GET_TRIP_LOCATION_AND_DATES, 
	GET_TRIP_LOCATION_COMMENTS,
	GET_TRIP_LOCATION_TIPS, 
	GET_TRIP_USERS
	 } from "../apollo/db/queries"

import { DELETE_TRIP_GENERAL_LOCATION, UNGROUP_TRIP_LOCATIONS } from "../apollo/db/mutations"


class TripLocationContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionName: null,
			isViewable: null,
			showDelete: false,
			showUngroup: false,
			tripLocationId: null,
			tripId: null, 
			view: 'overview'
		}

		this.handleDelete = this.handleDelete.bind(this)
		this.handleGoToTrip = this.handleGoToTrip.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleToggleView = this.handleToggleView.bind(this)
		this.handleHideUngroup = this.handleHideUngroup.bind(this)
		this.handleShowUngroup = this.handleShowUngroup.bind(this)
		this.handleUngroupLocations = this.handleUngroupLocations.bind(this)
	}

	async componentDidMount(){
		const { client, currentUser } = this.props
		const { isViewable } = this.state
		const tripLocationId = Number(window.location.href.split('/').slice(-1))

		const res = await client.query({
			query: GET_TRIP_GENERAL_LOCATION_BY_ID, 
			variables: { id: tripLocationId }, 
			fetchPolicy: 'network-only'
		})

		const tripLocation = res.data.tripGeneralLocationById

		if (currentUser.id){
			const tripRes =  await client.query({
	  	  		   	 	query: GET_TRIP_USERS,
	  	  	    		variables: { viewedTripId: tripLocation.tripId }
	  	  	 })

			const tripUsers = tripRes.data.tripUsers.nodes

			const viewable = tripUsers.some(user => user.userId === currentUser.id)
			if (viewable){
				this.setState({
					isViewable: true, 
					tripId: tripLocation.tripId, 
					tripLocationId: tripLocation.id
				})
			}
		} else if (tripLocation) {
			this.setState({
				tripId: tripLocation.tripId, 
				tripLocationId: tripLocation.id
			})
		}	

	}

	async componentDidUpdate(){
		//this is needed if currentUser has yet to be set on state from Main.js request
		const { client, currentUser } = this.props
		const { isViewable, tripId } = this.state

		if (currentUser.id && !isViewable){
			const res =  await client.query({
	  	  		   	 	query: GET_TRIP_USERS,
	  	  	    		variables: { viewedTripId: tripId }
	  	  	 })

			const tripUsers = res.data.tripUsers.nodes
					
			const viewable = tripUsers.some(user => user.userId === currentUser.id)
			if (viewable){
				this.setState({isViewable: true})
			}
		}
	}

	async handleDelete(mutationFunction){
		const { tripId, tripLocationId } = this.state
		const { setPopup } = this.props
		try {
				await mutationFunction({
					variables: {
						input: {
							id: tripLocationId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showDelete: false, deletionId: null, deletionName: null })
			} catch {
				console.log('error')
			}

			history.push(`/trip/${tripId}`)
	}

	async handleUngroupLocations(ungroupTripLocations){
		const { tripLocationId } = this.state
		const { setPopup } = this.props

		try {
			await ungroupTripLocations({
				variables: {
					input: { 
						mainTripLocId: tripLocationId
						}
					}
				})
			await setPopup({
					variables: {
						popup: false
					}
				})
			this.setState({ showUngroup: false })
		} catch {
			console.log('error')
		}

	}

	async handleShowDelete(location){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionName: location.fullName})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionName: null})
	}


	handleGoToTrip(tripId){
		history.push(`/trip/${tripId}`)
	}

	async handleShowUngroup(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showUngroup: true})
	}

	async handleHideUngroup(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showUngroup: false})
	}


	handleToggleView(view){
		this.setState({view: view})
	}



	renderDelete(){
		const { deletionName, tripId } = this.state
		return <Mutation
     		 mutation={DELETE_TRIP_GENERAL_LOCATION}
     		 refetchQueries={[{query: GET_TRIP_GENERAL_LOCATIONS, variables:{viewedTripId: tripId}}]}
	    >
	      	{(deleteTripGeneralLocation, { loading, error }) => {
	      		return <div className="deletionLocation">
	  				<Deletion 
	  				    error={error}
						handleHideDelete={this.handleHideDelete}
						handleDelete={this.handleDelete}
						mutationFunction={deleteTripGeneralLocation}
						name={deletionName}
						type="trip location"
					/>
				</div>
	      	}}
	    </Mutation>
	}

	renderUngroupLocations(){
		const { tripLocationId, tripId } = this.state
	


		return <Query query={GET_GROUPED_TRIP_LOCATIONS} variables={{mainTripLocId: tripLocationId}}>
  			{({ loading, error, data }) => {
	  			if (loading) {return <div> Loading... </div>}
	  			if (error) {return <div>Error</div>}
	  			
				const groupedTripLocations = data.groupedTripLocations.nodes
			
				return <Mutation mutation={UNGROUP_TRIP_LOCATIONS}
					refetchQueries={(result) => {
						let queries = []
					 	groupedTripLocations.forEach(tripLoc => {
					 		queries = queries.concat([{query: GET_TRIP_GENERAL_LOCATIONS, variables:{viewedTripId: tripId}},
				     		 {query: GET_TRIP_LOCATION_COMMENTS, variables:{tripLocId: tripLoc.id}}, 
				     		 {query: GET_TRIP_ITINERARY_ITEMS, variables:{tripLocId: tripLoc.id}}, 
				     		 {query: GET_TRIP_LOCATION_TIPS, variables:{tripLocId: tripLoc.id}}, 
				     		 {query: GET_TRIP_GENERAL_ATTRACTIONS, variables:{tripLocId: tripLoc.id}}, 
				     		 {query : GET_TRIP_LOCATION_AND_DATES, variables: {tripLocId: tripLoc.id}}
		     		 		])
					 	})
					 	return queries
		     		 }}
				>
				 	{(ungroupTripLocations, { loading, error }) => {
				 		return <UngroupLocations
				 		    error={error}
				 			groupedTripLocations={groupedTripLocations}
				 			handleHideUngroup={this.handleHideUngroup}
				 			handleUngroupLocations={this.handleUngroupLocations}
				 			ungroupTripLocations={ungroupTripLocations}
				 		 />
				 	}}
				</Mutation>
			}}
		</Query>
	}

	renderHeader(){
		const { showDelete, tripId, tripLocationId, view } = this.state
		 return <Query query={GET_TRIP_LOCATION_AND_DATES} variables={{tripLocId: tripLocationId}}>
  			{({ loading, error, data }) => {
  			if (loading) {return <div> Loading... </div>}
  			if (error) {return <div>Error</div>}
  			
			const tripLocation = data.tripLocationAndDates
		
					return <div>
						
						<TripLocationHeader 
						    error={error}
							handleGoToTrip={this.handleGoToTrip}
							handleShowAddAttraction={this.handleShowAddAttraction}
							handleShowDelete={this.handleShowDelete}
							handleShowUngroup={this.handleShowUngroup}
							handleToggleView={this.handleToggleView}
							tripId={tripId}
							tripLocation={tripLocation}
							view={view}
						/>

		
						{view === 'tripLocItinerary' ? <div className="itineraryView"> 
							{this.renderItinerary(tripLocation.dates, tripLocation)}
						</div> : <div>
							{this.renderAttractions(tripLocation)}
							<div className="tripLocationLeft">
								{this.renderTips(tripLocation.fullName)}
								{this.renderComments()}
							</div>
						</div>}
					</div>
			}}
		</Query>
	}

	renderAttractions(tripLocation){
		const {   
			tripId, 
			tripLocationId, 
			view
		 } = this.state
		return <TripLocAttractionsContainer
			tripId={tripId}
			tripLocId={tripLocationId}
			tripLocation={tripLocation}
			view={view}
		/>
	}	

	renderComments(){
		const { tripLocationId } = this.state
		return <CommentsContainer
			commentType="tripLocationComment"
			parentId={tripLocationId}
			parentType="tripLocation"
		 />

	}

	renderTips(tripLocName){
		const { tripLocationId } = this.state
		return <TripLocTipsContainer 
			tripLocId={tripLocationId}
			tripLocName={tripLocName}
		/>
	}

	renderItinerary(dates, tripLocation){
		const {  
			isEditing,
			tripId, 
			tripLocationId,  
			view
		 } = this.state
		return <ItineraryContainer 
				dates={dates}
				isEditing={isEditing}
				tripId={tripId}
				tripLocId={tripLocationId}
				tripLocation={tripLocation}
				view={view}
			/>
	
	}

	
	render() {
		const {  
			isViewable, 
			showDelete,
			showUngroup, 	 
			view
		 } = this.state
		 const { currentUser, screenView } = this.props
		 const popupClass = screenView.popup && "disabledBackground"

		return 	<div className={`tripLocationContainer ${popupClass}`}>
		{isViewable ? 
					<div>
						
						
						{showUngroup && this.renderUngroupLocations()}
						{showDelete && this.renderDelete()}
						{this.renderHeader()}

						

					</div> : 
				<div>You cannot view trips you are not a part of.</div>}
		</div>
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}),
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'})
	)(TripLocationContainer);
