import React from "react";
import { compose, graphql, Query, Mutation } from "react-apollo";
import * as Yup from 'yup'
import { analytics } from '../index.js';

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import { GET_LOCATION_TIPS } from "../apollo/db/queries"
import { CREATE_LOCATION_TIPS, DELETE_LOCATION_TIPS, UPDATE_LOCATION_TIPS } from "../apollo/db/mutations"

import LocationTips from "../components/LocationTips";
import LocationTipsForm from "../components/LocationTipsForm"

class LocationTipsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showEdit: false
		}

		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleEditTips = this.handleEditTips.bind(this)
	}

	async handleEditTips(values, createLocationTips, setSubmitting, updateLocationTips, deleteLocationTips, locationTips){
		const { location, setPopup } = this.props
		const { error } = this.state

		setSubmitting(true)

		const editTips = []
		const addTips = []
		const deleteTips = []
		Object.keys(values).map(category => {
			if (locationTips.some(tip => tip.category === category.toUpperCase())){
				if (values[category] && values[category].length){
				   editTips.push({tipId: locationTips.find(tip => tip.category === category.toUpperCase()).id, newTip: values[category] })
				} else {
				   deleteTips.push(locationTips.find(tip => tip.category === category.toUpperCase()).id)
				}

			} else if (values[category] && values[category].length) {
				addTips.push({tip: values[category], category: category.toUpperCase()})
			} 
		})

		
		try {
			if (editTips.length){
				await updateLocationTips({
					variables: {
						input: {
							locId: location.id, 
							locationTips: editTips
						}
					}
				})
			} 
			if (deleteTips.length){
				await deleteLocationTips({
					variables: {
						input: {
							locationTipIds: deleteTips
						}
					}
				})
			 
		    }
		    if (addTips.length){
				await createLocationTips({
					variables: {
						input: {
							locId: location.id, 
							locationTips: addTips
						}
					}
				})
				
			}
			await setPopup({
				variables: {
					popup: false
				}
			})
			this.setState({showEdit: false})
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development' && addTips.length) {
				await analytics.logEvent('add_location_tips', {
				  amount: addTips.length
				});
				
			}
		} catch {
			setSubmitting(false)
			console.log('error')
		}		
	}

	async handleShowEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showEdit: true})
	}

	async handleHideEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showEdit: false})
	}

	renderEditLocationTips(locationTips){
		const { location } = this.props

		const getTip = (category) => {
			const categoryTip = locationTips.find(tip => tip.category === category)
			if (categoryTip){
					return categoryTip
			} else {
					return ''
			}
		}


		const initialValues = {
			cultural: getTip('CULTURAL').tip, 
			transportation: getTip('TRANSPORTATION').tip,
			packing: getTip('PACKING').tip,
			safety: getTip('SAFETY').tip,
			other: getTip('OTHER').tip
		}

		const locTipsSchema = Yup.object().shape({
		   cultural: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   transportation: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   packing: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   safety: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   other: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),

		})

		return <Mutation 
			mutation={UPDATE_LOCATION_TIPS}
			refetchQueries={[{query: GET_LOCATION_TIPS, variables: {locId: location.id}}]}
		>
			{( updateLocationTips, { loading, error }) => {
				return <Mutation 
					mutation={CREATE_LOCATION_TIPS}
					refetchQueries={[{query: GET_LOCATION_TIPS, variables: {locId: location.id}}]}
				>
					{( createLocationTips, { loading, error }) => {
						return <Mutation 
							mutation={DELETE_LOCATION_TIPS}
							refetchQueries={[{query: GET_LOCATION_TIPS, variables: {locId: location.id}}]}
						>
							{( deleteLocationTips, { loading, error }) => {
								return <div>
									<LocationTipsForm 
								    	closeText="Cancel"
								    	createLocationTips={createLocationTips}
								    	deleteLocationTips={deleteLocationTips}
								    	error={error}
								    	handleClose={this.handleHideEdit}
								    	handleSubmit={this.handleEditTips}
										initialValues={initialValues}
								    	locationId={location.id} 
								    	locationTips={locationTips}
										submitText="Submit"
								    	updateLocationTips={updateLocationTips}
								    	validationSchema={locTipsSchema}
								    />
								</div>
							}}
						</Mutation>
					}}
				</Mutation>
			}}
		</Mutation>
	}

	renderLocationTips(){
		const { currentUser, location } = this.props
		const { showEdit } = this.state

		return <Query query={GET_LOCATION_TIPS} variables={{locId: location.id}}>
  			{({ loading, error, data }) => {
	  			if (loading) {return <div> Loading... </div>}
	  			if (error) {return <div>Error</div>}

				const locationTips = data.locationTips.nodes

				const getTip = (category) => {
					const categoryTip = locationTips.find(tip => tip.category === category)
					if (categoryTip){
							return categoryTip
					} else {
							return ''
					}
				}

				const tips = {
					CULTURAL: getTip("CULTURAL"), 
					TRANSPORTATION: getTip("TRANSPORTATION"), 
					PACKING: getTip('PACKING'),
					SAFETY: getTip('SAFETY'),
					OTHER: getTip('OTHER')
				}

				return <div>
					{showEdit && this.renderEditLocationTips(locationTips)}

					<LocationTips
						currentUser={currentUser}
						handleShowEdit={this.handleShowEdit}
						locationUserId={location.userId}
						locationId={location.id}
						tips={tips} 
					/>
				</div>
			}}
		</Query>
	}

	render() {
		return (
			<div className="locationTipsContainer">
				{this.renderLocationTips()}
			</div>
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
)(LocationTipsContainer);

