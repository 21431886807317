import React from "react";
import { Link } from "react-router-dom";
//import { Star, star_half, local_activity, hotel, restaurant, local_bar } from 'react-icons/md';

import "../stylesheets/components/Visitors.css"

const Visitors = props => {
	const { 
		handleClose,
		handleGoToAttraction,
		handleGoToLocation,
		handleShowOtherVisitors,
		otherVisitors, 
		popup,
		view
	} = props

	const getStars =  (count, className) => {
			const stars = []
			let filledStars, halfStar;
			if (count % 1 >= 0.75){
				filledStars = Math.round(count);
				halfStar = false;
			} else if (count % 1 < 0.75 && count % 1 >= 0.25){
				filledStars = (Math.floor(count));
				halfStar = true;
			} else {
				filledStars = (Math.floor(count));
				halfStar = false;
			}
			while(stars.length < filledStars){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}  
			if (halfStar){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star_half</i>
					)
			}
			while (stars.length < 5){
					stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}
			 
			return stars
	}
	const getIcon = attractionType => {
			if (attractionType === "ACTIVITY"){
				return <i className={`material-icons nonClickableIcon iconSmall`}>local_activity</i>
			
			} else if (attractionType === 'RESTAURANT'){
				return <div className="restaurantIcons">
					<i className={`material-icons nonClickableIcon iconSmall`}>local_bar</i>
					<i className={`material-icons nonClickableIcon iconSmall`}>restaurant</i>
				</div>
			} else if (attractionType === "LODGING") {
				return <i className={`material-icons nonClickableIcon iconSmall`}>hotel</i>
			}
	}

	let starClassName = view !== 'attractions' ? 'star' : 'starSmall'
	return (
		<div className={popup ? "popup popupMedium visitors" : "visitors"}>
			<div className={popup && "popupContent"}>
				{view !== 'attractions' && !popup && <div className="headerHolder fullHeaderHolder marginBottom">
					<div className="subHeader">visitors</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>}
				{(otherVisitors && otherVisitors.length) ? 
				<div className={view === "attractions" ? "visitorsContent" : "visitorContent mainBackground"}>
					{otherVisitors.map((visitor, index) => {
						if ((view !== 'attractions' || index < 3) || popup){
							return <div className={popup ? "visitor mainBackground" : "visitor"} key={visitor.id}>
							{(view === "overallLocation" || view === "tripLocation") && <img 
										className="picSmall" 
										src={visitor.publicUrl}
										alt="visitorPic"/>}
							{ (view === "overallAttraction" || view === 'attraction') && 
								<div className="userInfo">
									{view === 'overallAttraction' && <div>{getIcon(visitor.attractionType)}</div>}
									<Link className="textLink fontSmall userName" to={`/attraction/${visitor.id}`}>{(visitor.firstName + " " + visitor.lastName)}</Link>
								</div>}
							{view === "overallLocation" && 
								<Link className="textLink" to={`/location/${visitor.id}`}>{(visitor.firstName + " " + visitor.lastName)}</Link>}
							{view === 'attractions' && <div className='attractionVisitor'>
								<Link className="textLink fontSmall visitorName" to={`/location/${visitor.locationId}`}>{(visitor.firstName + " " + visitor.lastName)}</Link>
								</div>}
							{(view === "attraction" || view === 'attractions') && visitor.stars && <div className="visitorStars stars">
								{getStars(visitor.stars, starClassName)}
				
							</div>}
						</div>
						}

					})}

					{view === 'attractions' && !popup && otherVisitors.length > 3 && <div className="fontSmall textLink" onClick={() => handleShowOtherVisitors(otherVisitors)}>{`and ${otherVisitors.length - 3} other friends`}</div>}
				</div>
			 
				: <div>{view !== 'attractions' && <div className='mainBackground'>No one else that you follow has been here</div>}</div>}
			</div>
			{popup && 				<i className="material-icons activeIcon iconMedium" onClick={() => handleClose()}>cancel</i>}
		</div>
	);
};

export default Visitors;
