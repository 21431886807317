import gql from "graphql-tag";
//import { getCurrentUser } from "./queries";

export default {
  setCurrentUser(
    _,
    {
      id,
      firstName,
      lastName,
      about,
      currentCity,
      birthday,
      publicity, 
      seenExplanation,
      seenSaveExplanation,
      seenLocExplanation, 
      seenProfExplanation,
      seenInstaConnection,
      locationViews,
      pageViews, 
      pushToken,
      publicUrl,
      locUploadNumber,
      attrUploadNumber
    },
    { cache }
  ) {
    console.log('does this go?')
    const query = gql`
          query getCurrentUser {
            currentUser @client {
              __typename
              id
              firstName
              lastName
              about
              currentCity
              birthday
              publicity
              seenExplanation
              seenSaveExplanation
              seenLocExplanation 
              seenProfExplanation
              seenInstaConnection
              locationViews
              pageViews 
              pushToken
              publicUrl
              locUploadNumber
              attrUploadNumber
            }
          }
          `;
    const previousState = cache.readQuery({ query });

   
    const data = {
      ...previousState,
      currentUser: {
        ...previousState.currentUser,
        id: id,
        firstName: firstName,
        lastName: lastName,
        about: about,
        currentCity: currentCity,
        birthday: birthday,
        publicity: publicity,
        seenExplanation: seenExplanation,
        seenSaveExplanation: seenSaveExplanation,
        seenLocExplanation: seenLocExplanation,
        seenProfExplanation: seenProfExplanation,
        seenInstaConnection: seenInstaConnection,
        locationViews: locationViews,
        pageViews: pageViews,
        pushToken: pushToken,
        publicUrl: publicUrl, 
        locUploadNumber: locUploadNumber,
        attrUploadNumber: attrUploadNumber
      }
    };
    console.log('data', data)
    cache.writeQuery({ query, data });
    return null;
  },  
  setPopup(
    _,
    {
      popup
    },
    { cache }
  ) {
    const query = gql`
          query getPopup {
           screenView @client {
              __typename
              popup
            }
          }
          `;
    const previousState = cache.readQuery({ query });

   
    const data = {
      ...previousState,
      screenView: {
        ...previousState.screenView,
        popup: popup
      }
    };

    cache.writeQuery({ query, data });
    return null;
  }, 
   setNotificationsType(
    _,
    {
      type
    },
    { cache }
  ) {
    const query = gql`
          query getNotificationsType {
            notificationsType @client {
              __typename
              type
            }
          }
          `;
    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     notificationsType: {
        ...previousState.notificationsType,
        type: type
      }
    };
    cache.writeQuery({ query, data });
    return null;
  },  
  setExploreLocations(
    _,
    { 
      locations
    },
    { cache }
  ) {
    const data = {
      explore: {
        locations: locations, 
        __typename: "explore"
      }
    }
    cache.writeData({ data });
    return null;
  }, 
  setDestinationSearchValue(
    _,
    {
      value
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
            destinationSearch @client {
              __typename
             value, 
              type, 
              place, 
              attractionTypes, 
              attractionContent,
              attractionTags,
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        value: value
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchType(
    _,
    {
      type
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
            destinationSearch @client {
              __typename
              value, 
              type, 
              place, 
              attractionTypes, 
              attractionContent, 
              attractionTags,
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        type: type
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchAttrTypes(
    _,
    {
      attractionTypes
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
            destinationSearch @client {
              __typename
              value, 
              type, 
              place, 
              attractionTypes, 
              attractionContent, 
              attractionTags,
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionTypes: attractionTypes
      }
    };
    cache.writeQuery({ query, data });
    return null;
  },
  setDestinationSearchAttrContent(
    _,
    {
      attractionContent
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
            destinationSearch @client {
              __typename
              value, 
              type, 
              place, 
              attractionTypes, 
              attractionContent, 
              attractionTags,
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;
    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionContent: attractionContent
      }
    };
    cache.writeQuery({ query, data });
    return null;
  },
  setDestinationSearchAttrTags(
    _,
    {
      attractionTags
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
            destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionTags: attractionTags
      }
    };
    cache.writeQuery({ query, data });
    return null;
  },
  setDestinationSearchAttrStars(
    _,
    {
      attractionStars
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
            destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionStars: attractionStars
      }
    };
    cache.writeQuery({ query, data });
    return null;
  },
  setDestinationSearchAttrAndUp(
    _,
    {
      attractionAndUp
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;
    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionAndUp: attractionAndUp
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchPlace(
    _,
    {
      place
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;
    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        __typename: 'destinationSearch',
        value: null, 
        type: 'locations', 
        place: place, 
        attractionAndUp: false,
        attractionTypes: [], 
        attractionContent: [], 
        attractionStars: null,
        attractionTags: [], 
        attractionSort: 'recommended',
        attractionSortDir: 'desc', 
        locationSort: 'recommended', 
        locationSortDir: 'desc'
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchAttrSort(
    _,
    {
      attractionSort
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionSort: attractionSort
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchAttrSortDir(
    _,
    {
      attractionSortDir
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        attractionSortDir: attractionSortDir
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchLocSort(
    _,
    {
      locationSort
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        locationSort: locationSort
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchLocSortDir(
    _,
    {
      locationSortDir
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        locationSortDir: locationSortDir
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setDestinationSearchNeedsRefresh(
    _,
    {
      needsRefresh
    },
    { cache }
  ) {
    const query = gql`
          query getDestinationSearch {
           destinationSearch @client {
              value, 
              type, 
              place, 
              attractionAndUp, 
              attractionStars,
              attractionTypes, 
              attractionContent, 
              attractionTags, 
              attractionSortDir, 
              attractionSort, 
              locationSortDir, 
              locationSort, 
              needsRefresh
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     destinationSearch: {
        ...previousState.destinationSearch,
        needsRefresh: needsRefresh
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
  setUserSearchValue(
    _,
    {
      value
    },
    { cache }
  ) {
    const query = gql`
          query getUserSearch {
            userSearch @client {
              __typename
              value
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     userSearch: {
        ...previousState.userSearch,
        value: value
      }
    };
    cache.writeQuery({ query, data });
    return null;
  },
  setSearchView(
    _,
    {
      view
    },
    { cache }
  ) {
    const query = gql`
          query getSearchView {
            searchView @client {
              __typename
              view
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     searchView: {
        ...previousState.searchView,
        view: view
      }
    };
    cache.writeQuery({ query, data });
    return null;
  }, 
   setMessengerId(
    _,
    {
      id
    },
    { cache }
  ) {
    const query = gql`
          query getMessenger {
            messenger @client {
              __typename
              id
            }
          }
          `;

    const previousState = cache.readQuery({ query });
    const data = { 
      ...previousState,
     messenger: {
        ...previousState.messenger,
        id: id
      }
    };
    console.log('about to write the data')
    cache.writeQuery({ query, data });
    return null;
  }

};
