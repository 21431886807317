import React from "react";

import "../stylesheets/components/NotificationsHeader.css"

const NotificationsHeader = props => {
	const { 
		handleSetType,
		handleViewAll,
		type, 
		unseenFollowers,
		view
	} = props
	const selectedToggle = buttonType => buttonType === type && 'toggleSelected' 
		return(<div className="notificationsHeader">	
			{view !== 'navBar' ? <div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">notifications</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				 : <div className="textLink" onClick={() => handleViewAll()}>View All</div>}
			<div className="toggleButtons">
			    <div className={`toggleButton toggleLeft ${selectedToggle('followers')}`} onClick={(e)=>handleSetType('followers', e)}>Followers<div>{!!Number(unseenFollowers) && <div className="notificationDot followerDot"></div>}</div></div>
				<div className={`toggleButton toggleRight ${selectedToggle('other')}`} onClick={(e)=>handleSetType('other', e)}>Notifications</div>
			</div>

		</div>
	);
};

export default NotificationsHeader;
