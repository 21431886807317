import React from "react";

import { Button } from 'reactstrap'
import "../stylesheets/components/TripLocationHeader.css"

import DatesContainer from "../containers/DatesContainer"

const TripLocationHeader = props => {
	const { 
		handleAddDates,
		handleEditDates, 
		handleGoToTrip,
		handleShowAddAttraction,
		handleShowDelete,
		handleShowUngroup,
		handleToggleView,
		tripId,
		tripLocation, 
		view
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}


	const selectedView = viewType => view === viewType && 'toggleSelected';

	
	return (
			<div className="tripLocationHeader"> 
				
				<div className='headerAndIcons'>
					<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">{getLocName(tripLocation)}</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
					<div className="icons">
						{tripLocation.groupedTripLocId && <div className="secondaryButton buttonSmall" onClick={() => handleShowUngroup()}>Ungroup</div>}

						<img 
							className="iconMedium" 
							onClick={() => handleShowDelete(tripLocation)}
							src="https://storage.googleapis.com/trvlpage-bucket/trash.svg" 
							alt="deleteIcon"
						/>
					
					</div>
				</div>
				<div className="secondaryHeader textLink" onClick={() => handleGoToTrip(tripId)}>{tripLocation.tripName}</div>
			
				<DatesContainer 
					parentId={tripLocation.id}
					tripId={tripId}
					dates={tripLocation.dates}
					dateType="tripLocationDate"
					parentType="tripLocation"
					title='DATES'
					view="tripLocation"
				/>
				
				
				<div className="toggleButtons">
					<div className={`toggleButton toggleLeft ${selectedView('overview')}`} onClick={() => handleToggleView('overview')}>Overview</div>
					<div className={`toggleButton toggleRight ${selectedView('tripLocItinerary')}`} onClick={() => handleToggleView('tripLocItinerary')}>Itinerary</div>
				</div>	
					
				

		
				
			</div>
		
	);
};

export default TripLocationHeader;
