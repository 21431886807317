import React from 'react';
import { DropTarget } from 'react-dnd';

import "../stylesheets/components/ItineraryDropZone.css"

class ItineraryDropZone extends React.Component {

	render() {
		const { last, position, connectDropTarget } = this.props;
		const lastZoneClass = last && 'lastZone'
		return connectDropTarget(
			<div className={`itineraryDropZone ${lastZoneClass}`}>
				
			</div>
		);
	}
}


const cardTarget = {
	drop(props, monitor, component) {
	    const item = monitor.getItem()

	   	props.handleDrop(item, props.position, props.moveItineraryItemForward, props.moveItineraryItemBackward, props.insertItineraryItem)
	    
	  }
};

export default DropTarget("CARD", cardTarget, connect => ({
		connectDropTarget: connect.dropTarget()
	}))
(ItineraryDropZone);