import React from "react";
import { Query, compose, graphql, withApollo } from "react-apollo";
import { analytics } from '../index.js';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import { GET_ALL_OVERALL_LOCATIONS, GET_EXPLORE_LOCATIONS, GET_FOLLOWING_OVERALL_LOCATIONS } from "../apollo/db/queries"
import { getCurrentUser, getExplore } from "../apollo/state/queries"
import { setExploreLocations } from "../apollo/state/mutations"

import history from "../history"

import Explore from "../components/Explore";
import ExploreHeader from "../components/ExploreHeader"
import MapView from "../components/MapView"



class ExploreContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			explorerId: null, 
			exploreView: 'list', 
			currentUserSteps: [{
               target: '.exploreJoyride',
               placement: 'center',
               content: `Welcome to trvlpage! This is your explore page. \n\n\n  Here you can check out other people's location pages.`, 
               disableBeacon: true
             },
             {
               target: '.exploreJoyride',
               placement: 'center',
               content: `Once you start following and interacting with people, they will be customized to your network. \n\n\n For now, you'll see some of top locations on trvlpage`, 
               disableBeacon: true
             }],
            guestSteps: [{
               target: '.exploreJoyride',
               placement: 'center',
               content: `Thanks for checking out trvlpage! This is an explore page. \n\n\n  Here you can check out other people's location pages.`, 
               disableBeacon: true
             },
             {
               target: '.exploreJoyride',
               placement: 'center',
               content: `If you create an account, it will be customized to your network. \n\n\n For now, you'll see some of top locations on trvlpage.`, 
               disableBeacon: true
             }], 
       		followingOnly: false,
            run: false
		}

		
		this.handleGoToLocation = this.handleGoToLocation.bind(this)
		this.handleGoToOverallLoc = this.handleGoToOverallLoc.bind(this)
		this.handleJoyride = this.handleJoyride.bind(this)
		this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this)
		this.handleRefreshLocations = this.handleRefreshLocations.bind(this)
		this.handleShowMore = this.handleShowMore.bind(this)
		this.handleToggleExploreView = this.handleToggleExploreView.bind(this)
		this.handleToggleMapView = this.handleToggleMapView.bind(this)

	}

	async componentDidMount(){
		const { client, currentUser, explore, setExploreLocations } = this.props
		const locations = explore.locations

		if (!locations.length && (currentUser.id === 0 || currentUser.id)){
			try {
				const res =  await client.query({
	  		   	 	query: GET_EXPLORE_LOCATIONS,
	  	    		variables: { explorerId: currentUser.id },
	  	    		fetchPolicy: 'network-only'
	  	    	})
		    		const exploreLocations = res.data.exploreLocations.nodes
		   	
		   			await setExploreLocations({
		    			variables: {
		    				locations: exploreLocations
	    				}
	    			})

	    			this.setState({explorerId: currentUser.id}, () => {
	    				setTimeout(() => {
	    					this.handleJoyride()	
	    				}, 1000)
	    			})

		    	
	   		} catch {
	   			console.log('error')
	   		}
		}
	}

	async componentDidUpdate(){
		const { explorerId } = this.state
		const { client, currentUser, explore, setExploreLocations } = this.props
		const locations = explore.locations
		if (!locations.length && currentUser.id !== explorerId){
			try {
				const res =  await client.query({
	  		   	 	query: GET_EXPLORE_LOCATIONS,
	  	    		variables: { explorerId: currentUser.id },
	  	    		fetchPolicy: 'network-only'
	  	    	})
		    		const exploreLocations = res.data.exploreLocations.nodes
		   	
		   			await setExploreLocations({
		    			variables: {
		    				locations: exploreLocations
		    			}
		    		})
	    			
	    			this.setState({explorerId: currentUser.id})
		    	
	   		} catch {
	   			console.log('error')
	   		}
		}
	}

	handleJoyride(){
		const { run } = this.state
		const { explore } = this.props
		const explanation = window.location.search;


		if (explanation){
				this.setState({run: true})
		}
	}

	handleJoyrideCallback(data){
	    const { action, index, status, type } = data;

	    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
	      // Update state to advance the tour
	      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
	    }
	    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
	      // Need to set our running state to false, so we can restart if we click start again.
	      this.setState({ run: false });
	    }

 	};

	handleGoToLocation (locationId) {
		history.push(`/location/${locationId}`)
	}

	handleGoToOverallLoc(pin){
		history.push(`/locationView/${pin.placeId}`)
	}

	async handleToggleExploreView(view){
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('change_explore_view', {
			  view: view
			});
		}
		this.setState({exploreView: view})

	}

	async handleToggleMapView(){
		const { followingOnly } = this.state
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('change_explore_map_view', {
			  followingOnly: !followingOnly
			});
		}
		this.setState({followingOnly: !followingOnly})
	
	}

	async handleShowMore(){
		const { client, currentUser, explore, setExploreLocations } = this.props
		const locations = explore.locations
		const res =  await client.query({
	   	 	query: GET_EXPLORE_LOCATIONS,
    		variables: { explorerId: currentUser.id },
    		fetchPolicy: 'network-only'
    	})
    	const exploreLocations = res.data.exploreLocations.nodes

    	
   		await setExploreLocations({
			variables: {
				locations:  [...locations, ...exploreLocations]
			}
		})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('show_more_explore');
		}
		
	}

	async handleRefreshLocations(){
		const { client, currentUser, setExploreLocations } = this.props

		const res =  await client.query({
	   	 	query: GET_EXPLORE_LOCATIONS,
    		variables: { explorerId: currentUser.id },
    		fetchPolicy: 'network-only'
    	})
    	const exploreLocations = res.data.exploreLocations.nodes

    	
   		await setExploreLocations({
			variables: {
				locations:  exploreLocations
			}
		})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('refresh_explore');
		}

		
	}

	renderLocations(){
		const { currentUser, explore } = this.props
		const locations = explore.locations
			return <Explore 
			 	handleGoToLocation={this.handleGoToLocation}
				handleRefreshLocations={this.handleRefreshLocations}
				handleShowMore={this.handleShowMore}
				locations={locations}
			/>
	
	}

	renderHeader(){
		const { exploreView, followingOnly } = this.state
		return <ExploreHeader
			exploreView={exploreView}
			handleToggleExploreView={this.handleToggleExploreView}
			handleToggleMapView={this.handleToggleMapView}
			followingOnly={followingOnly}
		/>
	}

	renderLocationsMap(){
		return <Query 
			query={GET_ALL_OVERALL_LOCATIONS}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const locations = data.allOverallLocations.nodes

				return <MapView 
					handleSelectPin={this.handleGoToOverallLoc}
					pins={locations}
					view="explore"
					zoom={2}
				/>
			}}
		</Query>
	}

	renderFollowingLocationsMap(){
		return <Query 
			query={GET_FOLLOWING_OVERALL_LOCATIONS}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const locations = data.followingOverallLocations.nodes

				return <MapView 
					handleSelectPin={this.handleGoToOverallLoc}
					pins={locations}
					view="explore"
					zoom={2}
				/>
			}}
		</Query>
	}




	render() {
		const { currentUserSteps, exploreView, followingOnly, guestSteps, run } = this.state
		const { currentUser, explore } = this.props
		const explanation = window.location.search;
		const locations = explore.locations
		console.log('CURRENT USER', currentUser)
		return (
			<div className="exploreContainer">
			<Joyride 
					callback={this.handleJoyrideCallback}
					continuous={true}
					showProgress={true}
          			showSkipButton={true}
					steps={currentUser.id ? currentUserSteps : guestSteps} 
					run={run}

				/>
				{this.renderHeader()}
				

				<div className="exploreJoyride">
				
				{exploreView === 'list' ? this.renderLocations() : <div>
					{followingOnly ? this.renderFollowingLocationsMap() : this.renderLocationsMap()}
				</div>}

				</div>
					

			
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	graphql(getExplore, {
		props: ({ data: {explore} }) => ({
			explore
		})
	}),
	graphql(setExploreLocations, {name: 'setExploreLocations'})
)(ExploreContainer);
