import React from "react";
import { compose, graphql, Query, Mutation, withApollo } from "react-apollo";
import { animateScroll, Element } from 'react-scroll';
import * as Yup from "yup"
import axios from "axios"
import { analytics } from '../index.js';

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { GET_ALL_USER_MESSAGES, GET_USER_MESSAGES, GET_USER_VIEW_BY_ID } from "../apollo/db/queries";
import { CREATE_NEW_MESSAGE, UPDATE_MESSAGES_READ } from "../apollo/db/mutations"


import UserMessages from "../components/UserMessages";
import MessageForm from "../components/MessageForm";



class UserMessagesContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			messengerId: null,
		}
		this.handleCreateMessage = this.handleCreateMessage.bind(this)
	}


	async componentDidMount(){
		const { currentUser, messengerId} = this.props
		const newMessengerId = this.props.messengerId
		 	
			this.setState({messengerId: newMessengerId})
		

	}

	async componentDidUpdate(){
		const { messengerId } = this.state
		const { currentUser } = this.props
		const newMessengerId = this.props.messengerId
		if (messengerId !== newMessengerId){
		
			await this.setState({messengerId: newMessengerId})
		}

	}

		

	async handleCreateMessage(values, createNewMessage, resetForm, setSubmitting){
		const { client, currentUser, messengerId } = this.props
		//const { messengerId } = this.state
		setSubmitting(true)
		const messageContent = values.message
		
			try {
				await createNewMessage({   	 	
			   	 	variables: {
			   	 		input: {
		   	 				receiveId: messengerId,
		   	 				message: messageContent
			   	 		
						}
					}
				})

			resetForm()

			const res =  await client.query({
		  		query: GET_USER_VIEW_BY_ID,
  	    		variables: { profUserId: messengerId }
  	    	})
  	    	
  	    	const receiverUser = res.data.userViewById
  	    	setSubmitting(false)
			if (receiverUser.pushToken && receiverUser.pushToken !== currentUser.pushToken){
				await this.sendPushNotification(receiverUser.pushToken)
				
			}
			await analytics.logEvent('create_message', {
				  receiverId: messengerId
				});
				
			} catch {
				setSubmitting(false)
				console.log('error')
			}

	}

	async sendPushNotification(token){
		const { currentUser, profUser } = this.props


	    const message = {
	      to: token,
	      sound: 'default',
	      body: `${currentUser.firstName} ${currentUser.lastName} sent you a message`,
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	      'mode': 'no-cors',
	        'method': 'POST',
	        'headers': {
	            'Accept': 'application/json',
	            'Content-Type': 'application/json',
	        },
	      body: JSON.stringify(message),
	    });
  	};


	renderMessages(){
		const { currentUser, messengerId } = this.props
		return <Query query={GET_USER_MESSAGES} variables={{messengerId: messengerId}} fetchPolicy='network-only'>
			{({ loading, error, data }) => {

			if (loading) return <div>Loading...</div>;
			if (error) return <div>Error</div>;
			const messages = data.userMessages.nodes
				return <UserMessages 
					currentUser={currentUser}
					messages={messages}
				/>
			
			}}
		</Query>

	}
 
	renderMessageForm(){
		const { messengerId } = this.props
		const initialValues = {message: ''}
		const messageSchema = Yup.object().shape({
		  	comment: Yup.string()
		  	.max(2000, 'Message is too long. Maximum of 2000 characters.')

		})
		return <Mutation
     		 mutation={CREATE_NEW_MESSAGE}
     		 refetchQueries={[{query: GET_USER_MESSAGES, variables:{messengerId: messengerId}}, 
     		 {query: GET_ALL_USER_MESSAGES}]}	
	    >
	      	{(createNewMessage, { loading, error }) => {
				return <MessageForm 
				    error={error}
					createNewMessage={createNewMessage}
					handleSubmit={this.handleCreateMessage}
					initialValues={initialValues}
					validationSchema={messageSchema}
				/>
			}}
		</Mutation>
	}

	render() {
		const { currentUser, messengerId } = this.props
		return (
			<div>{messengerId ? <div>
				{currentUser.id ?
					<div>
						<div>
							{this.renderMessages()}
							<div ref={(el) => { this.messagesEnd = el; }}></div>
						</div>
						{this.renderMessageForm()}

					</div> : 
					<div>You must login to see this page.</div>
				}
			</div> : <div>Loading...</div>}
			</div>
		);
	}
}

export default compose(
	 withApollo, 
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(UserMessagesContainer);
