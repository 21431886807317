import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { Element, Link } from 'react-scroll'

import "../stylesheets/components/LandingPage.css"

import Autocomplete from "./Autocomplete"
import mainImage from "../assets/MainLP.png"

const LandingPage = props => {
	const {
		createReferral,
		country,
		error, 
		handleClose, 
		handleChangePhoneNumber,
		handleChangeReferNumber,
		handleGoToPrivacyPolicy,
		handleGoToTerms,
		handleSelect,
		handleSignup, 
		handleSendReferrals,
		inviteFailure,
		mutationFunction, 
		phoneNumber, 
		onMobile,
		referNumbers,
		stateError, 
		step, 
		updateWaitingList,
		waitListSignup
	} = props

	console.log('onMobile', onMobile)

	const mainSubtext = () => {
		if (step === 'initial'){
			return "Join the waitlist and we'll let you know when it's your turn!"
		} else if (step === 'refer'){
			return "Get your friends on board and jump in line!"
		} else {
			return "Thanks for joining! We'll shoot you a text when it's time to take off."
		}
	}
	return (
	<div className={onMobile ? "landingPage landingPageMobile" : "landingPage landingPageDesktop"}>
		{/*This is for better search results*/}
		<h1 style={{display: 'none' }}>Trvlpage</h1>
		<div className="landingPageSection">
			<div className="landingPageSectionLeft">
				<div style={{display: 'flex', marginLeft: 20, alignItems: 'center'}}>
				<img src={require('../assets/horizontalLogo.svg')} className="logoIcon"/>
					<div onClick={() => handleGoToPrivacyPolicy()} style={{color: '#105B57', marginLeft: 20, textAlign: 'center'}}>Privacy Policy</div>
					<div onClick={() => handleGoToTerms()}  style={{color: '#105B57', marginLeft: 10, textAlign: 'center'}}>Terms of Service</div>
				</div>

				{onMobile && <div className="landingPageNav">
						<Link to="reflect" smooth={true} offset={50} duration={500} style={{color: '#105B57'}} className="landingPageNavFont">Reflect</Link>
						<Link to="connect" smooth={true} offset={50} duration={500} style={{color: '#105B57'}} className="landingPageNavFont">Connect</Link>
						<Link to="explore" smooth={true} offset={50} duration={500} style={{color: '#105B57'}} className="landingPageNavFont">Explore</Link>
						{/*<button className="landingPageButton" onClick={() => console.log('this will log in')}>Log In</button>*/}

				</div>}
				<Element name="main" className="landingPageTextSection">
					<div className="landingPageTitle">
						Your go-to spot for trusted travel recs from your network.
					</div>
					<div className="landingPageSubtext">
						{mainSubtext()}
					</div>

					{step === 'initial' && <Formik
						onSubmit={( values, { setSubmitting }) => {
							handleSignup(values, setSubmitting, waitListSignup)
						}}
				 
				    >
				      {({ isSubmitting }) => (
				        <Form className="landingPageForm">
				        	
				        	<div className="landingPageItem">
					          <PhoneInput
						        placeholder="Phone Number"
						        name="phoneNumber"
						        value={phoneNumber}
						        onChange={(value) => handleChangePhoneNumber(value)}
						        defaultCountry="US"
						      />
					          	<ErrorMessage className="waitListFormError" name="email" component="div" />

					        </div>
				        	
				        	<Autocomplete  
						        handleSelect={handleSelect}
						        type="waitingList"
						        placeholder="Where do you live?"
							/>

							<div className="landingPageNames">
								<div className="landingPageName landingPageNameLeft">
						          <Field 
						          	className="landingPageField" 
						          	type="text"
						          	placeholder="Your First Name"
		 							required
						          	name="firstName" 
						          	onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						              }}
						          	/>
						          	<ErrorMessage className="landingPageFormError" name="firstName" component="div" />

						        </div>
						        <div className="landingPageName landingPageNameRight">
						          <Field 
						          	className="landingPageField" 
						          	type="text" 
						          	placeholder="Your Last Name"
						          	required
						          	name="lastName" 
						          	onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						              }}
						          	/>
						          	<ErrorMessage className="landingPageFormError" name="lastName" component="div" />

						        </div>
						     </div>
						     <div className="landingPageButtonHolder">
								<button className="landingPageButton" type="submit" disabled={isSubmitting}>Join the Waiting List</button>
							</div>
				        </Form>
				      )}
				    </Formik>}
				    {step === 'refer' && <Formik
						onSubmit={(values, {setSubmitting}) => {
					        handleSendReferrals(values, setSubmitting, createReferral, updateWaitingList)
					      }}
				 
				    >
				      {({ isSubmitting }) => (
				        <Form className="landingPageForm">
				        	
				        	<div className="landingPageItem">
					          <Field 
					          	className="landingPageField" 
					          	type="text" 
					          	name="email1" 
					           	placeholder="email"

					          	onKeyDown={(e) => {
					                if (e.key === 'Enter') {
					                  e.preventDefault()
					                }
					              }}
					          	/>
					          	<ErrorMessage className="landingPageError" name="email1" component="div" />

					        </div>
				        	<div className="landingPageItem">
					          <Field 
					          	className="landingPageField" 
					          	type="text" 
					          	name="email2" 
					          	placeholder="email"

					          	onKeyDown={(e) => {
					                if (e.key === 'Enter') {
					                  e.preventDefault()
					                }
					              }}
					          	/>
					          	<ErrorMessage className="landingPageError" name="email2" component="div" />

					        </div>
					        <div className="landingPageItem">
					          <Field 
					          	className="landingPageField" 
					          	type="text" 
					          	name="email3" 
					          	placeholder="email"
					          	onKeyDown={(e) => {
					                if (e.key === 'Enter') {
					                  e.preventDefault()
					                }
					              }}
					          	/>
				          		<ErrorMessage className="landingPageError" name="email3" component="div" />

				       		</div>
							
						     <div className="landingPageButtonHolder">
								<button className="landingPageButton" type="submit" disabled={isSubmitting}>Send those invites!</button>
							</div>
				        </Form>
				      )}
				    </Formik>}
				</Element>
			
			</div>
			<div className="landingPageSectionRight">
					{!onMobile && <div className="landingPageMainPic" style={{ backgroundImage: `url(${mainImage})` }}>
							<div className="landingPageNav">
								<Link to="reflect" smooth={true} offset={50} duration={500} style={{color: '#105B57'}} className="landingPageNavFont">Reflect</Link>
								<Link to="connect" smooth={true} offset={50} duration={500} style={{color: '#105B57'}} className="landingPageNavFont">Connect</Link>
								<Link to="explore" smooth={true} offset={50} duration={500} style={{color: '#105B57'}} className="landingPageNavFont">Explore</Link>
								{/*<button className="landingPageButton" onClick={() => console.log('this will log in')}>Log In</button>*/}
							
							</div>
						
				    </div>}


			</div>
		</div>
		<Element className="landingPageSection" name="reflect">
			<div className="landingPageSectionLeft">
				<img src={require('../assets/ReflectLP.png')} className="landingPagePicture"/>

			</div>
			<div className="landingPageSectionRight">
				<div className="landingPageTextSection">

					<img src={require('../assets/PictureLP.png')} className="landingPageIcon"/>

					<div className="landingPageTitle">
							Reflect.
					</div>
					<div className="landingPageSubtext">
						Add your past travel experiences to share with your friends and remember for the future.
					</div>
				</div>
			</div>
		</Element>
		{onMobile ?<Element className="landingPageSection" name="connect">
			<div className="landingPageSectionRight">
				<img src={require('../assets/ConnectLP.png')} className="landingPagePicture"/>

			</div>
			<div className="landingPageSectionLeft">
				<div className="landingPageTextSection">

					<img src={require('../assets/PeopleLP.png')} className="landingPageIcon"/>

					<div className="landingPageTitle">
							Connect.
					</div>
					<div className="landingPageSubtext">
						Connect to your network.
					</div>
				</div>

			</div>
			
		</Element> : 


		 <Element className="landingPageSection" name="connect">
			<div className="landingPageSectionLeft">
				<div className="landingPageTextSection">

					<img src={require('../assets/PeopleLP.png')} className="landingPageIcon"/>

					<div className="landingPageTitle">
							Connect.
					</div>
					<div className="landingPageSubtext">
						Connect to your network.
					</div>
				</div>

			</div>
			<div className="landingPageSectionRight">
				<img src={require('../assets/ConnectLP.png')} className="landingPagePicture"/>

			</div>
		</Element>}
		<Element className="landingPageSection" name='explore'>
			<div className="landingPageSectionLeft">
				<img src={require('../assets/ExploreLP.png')} className="landingPagePicture"/>

			</div>
			<div className="landingPageSectionRight">
				<div className="landingPageTextSection">

					<img src={require('../assets/EarthLP.png')} className="landingPageIcon"/>

					<div className="landingPageTitle">
							Explore.
					</div>
					<div className="landingPageSubtext">
						Easily plan a trip by comparing your network's recommendations. 
					</div>
				</div>
			</div>
		</Element>

		<div className="landingPageSection landingPageSectionBottom">
			<div className="landingPageTextSection">
				<div className="landingPageTitle">
					Your go-to spot for trusted travel recs from your network.
				</div>
				<div className="landingPageSubtext">
					Only available on iOS.
				</div>
				 <div className="landingPageButtonHolder">
					<Link to="main" smooth={true} offset={50} duration={500} style={{color: 'white'}} className="landingPageButton landingPageButtonLarge" >Join the Waiting List</Link>
				</div>
				<a 
				className="inlineLink textLink"
				href={'https://www.instagram.com/trvl.page'}  
				target="_blank"
				rel="noopener noreferrer"><img src={require('../assets/InstagramLP.png')} className="socialIcon"/></a>

			</div>
		</div>
		

	</div>);
}

export default LandingPage;
