import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/Welcome.css"

import initialPic from '../assets/globe.jpg'


const Welcome = (props) => {
	const {handleGuest} = props
	return (
		<div className="welcome initialScreen">

			<div className="initialScreenLeft">
				<div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">trvlpage</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
				
				<div className="initialContent">
					<div className="initialItems">
						<Link className="primaryButton formButton initialItem" to="/signup">Entry Code</Link>

						<Link className="primaryButton formButton initialItem" to="/signup">Have an invite?</Link>

						<Link className="primaryButton formButton initialItem" to="/waitlist">Join Waitlist</Link>
						
						<Link className="textLink initialItem" to="/login">Login</Link>
						{/*<div className="fontSmall">This site uses cookies to provide you the best user experience. By using trvlpage, you accept our use of cookies. For more info, check out our 
							<a 
								className="textTitle textLink"
								href={'https://www.trvlpage.com/privacy-policy'}  
								target="_blank"
								rel="noopener noreferrer">Privacy Policy</a>
						</div> */}
					</div>
				</div>
			</div>
			<div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')} 
			 	alt="welcomePicture"/>
			</div>

		</div>
	);
};

export default Welcome;
