import React from "react";
import { compose, graphql, Query, withApollo, Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";


import history from "../history"

import { getCurrentUser, getPopup } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import {
	GET_TRIP_BY_ID, 
	GET_TRIP_USERS
} from "../apollo/db/queries"
import {   
	UPDATE_TRIP
} from "../apollo/db/mutations"

import Deletion from "../components/Deletion"
import CommentsContainer from "./CommentsContainer"
import LocationTips from "../components/LocationTips"
import TripLocationsContainer from "./TripLocationsContainer"
import TripForm from "../components/TripForm"
import TripHeader from "../components/TripHeader"
import TripTransportationsContainer from "../containers/TripTransportationsContainer"
import TripUsersContainer from "../containers/TripUsersContainer"

class TripContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionId: null,
			isViewable: false,
			showEditTrip: false, 
			showTooltip: false,
			tooltipType: null,
			tripId: null, 
			voteNameViewable: false
		}

		this.handleHideEditTrip = this.handleHideEditTrip.bind(this)
		this.handleShowEditTrip = this.handleShowEditTrip.bind(this)
		this.handleUpdateTrip = this.handleUpdateTrip.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.handleToggleVoteNameViewable = this.handleToggleVoteNameViewable.bind(this)
	}

	async componentDidMount(){
		const { client, currentUser } = this.props
		const { isViewable } = this.state
		const tripId = Number(window.location.href.split('/').slice(-1))

		const tripRes = await client.query({
			query: GET_TRIP_BY_ID, 
			variables: { id: tripId }
		})

		const trip = tripRes.data.tripById

		if (trip){

			await this.setState({voteNameViewable: !trip.voterAnonymity})
		}


		if (currentUser.id){
			const res =  await client.query({
	  	  		   	 	query: GET_TRIP_USERS,
	  	  	    		variables: { viewedTripId: tripId }
	  	  	 })

			const tripUsers = res.data.tripUsers.nodes

			const viewable = tripUsers.some(user => user.userId === currentUser.id)
			if (viewable){
				this.setState({isViewable: true, tripId: tripId})
			}
		} 
	}

	async componentDidUpdate(){
		//this is needed if currentUser has yet to be set on state from Main.js request
		const { client, currentUser } = this.props
		const { isViewable } = this.state
		const tripId = Number(window.location.href.split('/').slice(-1))

		if (currentUser.id && !isViewable){
			const res =  await client.query({
	  	  		   	 	query: GET_TRIP_USERS,
	  	  	    		variables: { viewedTripId: tripId }
	  	  	 })

			const tripUsers = res.data.tripUsers.nodes
			
			const viewable = tripUsers.some(user => user.userId === currentUser.id)
			if (viewable){
				this.setState({isViewable: true, tripId: tripId})
			}
		}
	}

	
	async handleShowDelete(deletionId, deletionType){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: deletionId, deletionType: deletionType})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null, deletionType: null})
	}

	async handleShowEditTrip(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showEditTrip: true})
	}

	async handleHideEditTrip(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showEditTrip: false})
	}

	handleToggleVoteNameViewable(){
		const { voteNameViewable } = this.state
		this.setState({voteNameViewable: !voteNameViewable})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}


	async handleUpdateTrip(values, updateTrip){
		const { setPopup } = this.props
		const { tripId, voteNameViewable } = this.state

		const startDate = values.startDate ? values.startDate : null
		const endDate = values.endDate ? values.endDate : null
		const tripName = values.tripName

		try {
				await updateTrip({
					variables: {
			 			input: {
							id: tripId,
							tripPatch: {
								name: tripName,
								startDate: startDate, 
								endDate: endDate, 
								voteAnonymity: !voteNameViewable
							}	
						}
					}
				})
				await setPopup({
					variables: {
						popup: false
					}
				})
		
				this.setState({ showEditTrip: false })

			} catch {
				console.log('error')
			}
	}
	
	renderHeader(){
		const { showEditTrip, showTooltip, tooltipType, tripId, voteNameViewable } = this.state
		return <Query 
			query={GET_TRIP_BY_ID}
	    	variables= {{ id: tripId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const trip = data.tripById 

				return <Mutation
		     		 mutation={UPDATE_TRIP}
		     		 refetchQueries={[{query: GET_TRIP_BY_ID, variables:{id: tripId}}]}
			    >
			      	{(updateTrip, { loading, error }) => {
			      		const initialValues = {
			      			tripName: trip.name,
			      			startDate: trip.startDate ? trip.startDate : '', 
			      			endDate: trip.endDate ? trip.endDate : '', 
			
			      		}

			      		const tripSchema = Yup.object().shape({
			      		  tripName: Yup.string()
			      		  .required('Trip Name is required!')
			      		  .max(20, 'Trip name is too long. Maximum of 20 characters.'),
						  startDate: Yup.date(),
						  endDate: Yup.date().when('startDate', (st, schema) => {
						    	return Yup.date().min(st, "End Date must be after Start Date");
							})

						})

						return <div>
							{showEditTrip && <div className="editTripForm">
									<TripForm 
									    error={error}
										handleClose={this.handleHideEditTrip}
										handleEdit={this.showEditTrip}
										handleSubmit={this.handleUpdateTrip}
										handleToggle={this.handleToggleVoteNameViewable}
										handleToggleTooltip={this.handleToggleTooltip}
										initialValues={initialValues}
										mutationFunction={updateTrip}
										showTooltip={showTooltip}
										tooltipType={tooltipType}
										validationSchema={tripSchema}
										voteNameViewable={voteNameViewable}

									/>
								</div>}
							<TripHeader 
								handleShowEditTrip={this.handleShowEditTrip}
								trip={trip}
							/>
						</div>
					}}
				</Mutation>

			}}
		</Query>

	}

	render() {
		const { currentUser, screenView } = this.props
		const { deletionId, isViewable, mutationFunction, showDelete, tripId } = this.state
		const popupClass = screenView.popup && "disabledBackground"
		return (<div className={`tripContainer ${popupClass}`}>
				{isViewable ? 
					<div>
			
						
						{this.renderHeader()}
						<TripLocationsContainer 
							tripId={tripId}
						/>
						<div className="tripContent">
							<div className="tripLeft">
								<TripTransportationsContainer 
									tripId={tripId}
								/>
							
								<CommentsContainer 
									commentType='tripComment'
									parentId={tripId}
									parentType='trip'
								/>
							</div>
							<div className="tripRight">
								<TripUsersContainer 
									tripId={tripId}
								/>
							</div>
						</div>
				

						
					</div>
				: <div>Only trips that you're a part of can be viewed</div>}
				
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'})
)(TripContainer);
