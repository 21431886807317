import React from "react";
import { compose, graphql, withApollo, Query, Mutation } from "react-apollo";
import * as Yup from 'yup'
import { analytics } from '../index.js'; 

import "../stylesheets/containers/CommentsContainer.css"


import Deletion from "../components/Deletion"
import Comments from "../components/Comments"


import { getCurrentUser } from "../apollo/state/queries";
import { 
	GET_ATTRACTION_COMMENTS, 
	GET_OVERALL_ATTRACTION_REVIEWS,
	GET_LOCATION_PICTURE_COMMENTS,
	GET_TRIP_LOCATION_COMMENTS, 
	GET_TRIP_COMMENTS } from "../apollo/db/queries"
import { 
	CREATE_ATTRACTION_COMMENT,
	CREATE_NEW_PICTURE_COMMENT,
	CREATE_TRIP_LOCATION_COMMENT, 
	CREATE_TRIP_COMMENT, 
	DELETE_ATTRACTION_COMMENT,
	DELETE_LOCATION_PICTURE_COMMENT,
	DELETE_TRIP_LOCATION_COMMENT, 
	DELETE_TRIP_COMMENT,
	UPDATE_ATTRACTION_COMMENT,
	UPDATE_LOCATION_PICTURE_COMMENT,
	UPDATE_TRIP_LOCATION_COMMENT,
	UPDATE_TRIP_COMMENT 
} from "../apollo/db/mutations"

class CommentsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editId: null,
			deletionId: null, 
			deletionType: null,
			showAllComments: false,
			showDelete: false, 
			showEdit: false, 
			stateError: null, 
		}

		this.handleCreateAttractionComment = this.handleCreateAttractionComment.bind(this)
		this.handleCreateComment = this.handleCreateComment.bind(this)
		this.handleCreatePictureComment = this.handleCreatePictureComment.bind(this)
		this.handleCreateTripComment = this.handleCreateTripComment.bind(this)
		this.handleCreateTripLocComment = this.handleCreateTripLocComment.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleDeleteAttractionComment = this.handleDeleteAttractionComment.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleHideReviewComments = this.handleHideReviewComments.bind(this)
		this.sendPushNotification = this.sendPushNotification.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleShowReviewComments = this.handleShowReviewComments.bind(this)
		this.handleUpdateComment = this.handleUpdateComment.bind(this)
		this.handleUpdateAttractionComment = this.handleUpdateAttractionComment.bind(this)
		
	}

	async handleCreateComment(values, createComment, resetForm, setSubmitting){
		const { currentUser, commentType, parentId, parentType } = this.props

		setSubmitting(true)

		const comment = values.comment
		try {
			await createComment({   	 	
		   	 	variables: {
		   	 		input: {
		   	 			[commentType]: {
		   	 				[parentType + "Id"]: parentId, 
		   	 				userId: currentUser.id, 
		   	 				comment: comment
		   	 			}	
					}
				}
			})

			resetForm()
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_comment', {
				  type: commentType
				});
			}
			
		} catch {
			setSubmitting(false)
			console.log('error')
		}
	
	}

	async handleCreatePictureComment(values, createNewPictureComment, resetForm, setSubmitting){
		const { currentUser, commentType, parentId, parentType, picture, pushToken } = this.props

		setSubmitting(true)

		const locPicId = picture.id
		const locId = picture.locationId
		const picOwnerId = picture.ownerUserId
		const comment= values.comment

		try {
			await createNewPictureComment({   	 	
		   	 	variables: {
		   	 		input: {
	   	 				locPicId: locPicId, 
	   	 				locId: locId, 
	   	 				picOwnerId: picOwnerId,
	   	 				commentContent: comment
		   	 			
					}
				}
			})

			if (pushToken && pushToken !== currentUser.pushToken){
				await this.sendPushNotification(pushToken, 'picture')
				
			}


			resetForm()
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_comment', {
				  type: commentType
				});
			}
			
		} catch {
			setSubmitting(false)
			console.log('error')
		}

	}



	//these needs their onw mutation functions to account for refetching overall attraction reviews
	async handleCreateAttractionComment(values, createAttractionComment, resetForm, setSubmitting){
		const { currentUser, parentId, pushToken } = this.props

		setSubmitting(true)

		const comment = values.comment
		try {
			await createAttractionComment({   	 	
		   	 	variables: {
		   	 		input: {
	   	 				attrId: parentId,  
	   	 				newComment: comment	
					}
				}
			})

			if (pushToken && pushToken !== currentUser.pushToken){
				await this.sendPushNotification(pushToken, 'attraction')
			}


			resetForm()
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_comment', {
				  type: 'attractionComment'
				});
			}
				
			
		} catch {
			setSubmitting(false)
			console.log('error')
		}
	
	}


	//they need their own mutation functions to notifications for all trip users to see them
	async handleCreateTripLocComment(values, createTripLocationComment, resetForm, setSubmitting){
		const { currentUser, parentId } = this.props

		setSubmitting(true)

		const comment = values.comment

		try {
			await createTripLocationComment({   	 	
		   	 	variables: {
		   	 		input: {
	   	 				tripLocId: parentId,  
	   	 				newComment: comment	
					}
				}
			})

			resetForm()
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_comment', {
				  type: 'tripLocComment'
				});
			}
			
		} catch {
			setSubmitting(false)
			console.log('error')
		}
	
	}

	async handleCreateTripComment(values, createTripComment, resetForm, setSubmitting){
		const { currentUser, parentId } = this.props

		setSubmitting(true)

		const comment = values.comment
		try {
			await createTripComment({   	 	
		   	 	variables: {
		   	 		input: {
	   	 				viewedTripId: parentId,  
	   	 				newComment: comment	
					}
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_comment', {
				  type: 'tripComment'
				});
			}

		resetForm()
		setSubmitting(false)
			
		} catch {
			setSubmitting(false)
			console.log('error')
		}

	}

	async sendPushNotification(token, type){
	    const message = {
	      to: token,
	      sound: 'default',
	      body: `Someone commented on one of your ${type}s`,
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	       'mode': 'no-cors',
	        'method': 'POST',
	        'headers': {
	            'Accept': 'application/json',
	            'Content-Type': 'application/json',
	        },
	      	body: JSON.stringify(message),
	    });
  	};




	async handleUpdateComment(values, updateComment, setSubmitting){
		const { editId } = this.state
		const { commentType } = this.props

		setSubmitting(true)

		const comment = values.comment
		try {
			await updateComment({
				variables: {
					input: {
						id: editId,
						[commentType + 'Patch']: {
							comment: comment, 
							edited: true
						}	
					}
				}
			})

			this.setState({ showEdit: false, editId: null })
			setSubmitting(false)

		} catch {
			setSubmitting(false)
			console.log('error')
		}
	
		
	}


	async handleUpdateAttractionComment(values, updateAttractionComment, setSubmitting){
		const { editId } = this.state
		const { commentType } = this.props

		setSubmitting(true)

		const comment = values.comment

	
		try {
			await updateAttractionComment({
				variables: {
					input: {
						commentId: editId,
						newComment: comment 
						
					}
				}
			})

			this.setState({ showEdit: false, editId: null })
			setSubmitting(false)

		} catch {
			setSubmitting(false)
			console.log('error')
		}
	
		
	}

	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		try {
				await mutationFunction({
					variables: {
						input: {
							id: deletionId
							}
						}
					})
				this.setState({ showDelete: false, deletionId: null, deletionType: null})
			} catch {
				console.log('error')
			}
	}

	async handleDeleteAttractionComment(deleteAttractionComment){
		const { deletionId } = this.state
		try {
				await deleteAttractionComment({
					variables: {
						input: {
							commentId: deletionId
							}
						}
					})
				this.setState({ showDelete: false, deletionId: null, deletionType: null})
			} catch {
				console.log('error')
			}
	}

	handleShowDelete(deletionId, deletionType){
		this.setState({showDelete: true, deletionId: deletionId, deletionType: deletionType})
	}

	handleHideDelete(){
		this.setState({showDelete: false, deletionId: null, })
	}


	handleShowReviewComments(){
		this.setState({showAllComments: true})
	}

	handleHideReviewComments(){
		this.setState({showAllComments: false})
	}

	handleShowEdit(editId){
		this.setState({showEdit: true, editId: editId})
	}

	handleHideEdit(){
		this.setState({showEdit: false, editId: null})
	}


	renderDeleteTripLocComment(){
		const { parentId } = this.props
		return <Mutation
 			 mutation={DELETE_TRIP_LOCATION_COMMENT}
 			 refetchQueries={[
 	      	{query: GET_TRIP_LOCATION_COMMENTS, variables:{tripLocId: parentId}}]}
	    >
	      	{(deleteTripLocationComment, { loading, error }) => {
	      		return <div className="deletionComment">
	      			<Deletion 
	      			    error={error}
	      				mutationFunction={deleteTripLocationComment}
	      				handleDelete={this.handleDelete}
	      				handleHideDelete={this.handleHideDelete}
	      				type="trip location comment"

	      			/>
      			</div>
	     	}}
	    </Mutation>
	}

	renderDeleteTripComment(){
		const { parentId } = this.props
		return <Mutation
 			 mutation={DELETE_TRIP_COMMENT}
 			 refetchQueries={[
 	      	{query: GET_TRIP_COMMENTS, variables:{ viewedTripId: parentId }}]}
	    >
	      	{(deleteTripComment, { loading, error }) => {
	      		return <div className="deletionComment">
	      			<Deletion 
	      			    error={error}
	      				mutationFunction={deleteTripComment}
	      				handleDelete={this.handleDelete}
	      				handleHideDelete={this.handleHideDelete}
	      				type="trip comment"

	      			/>
      			</div>
	     	}}
	    </Mutation>
	}

	renderDeleteLocPicComment(){
		const { parentId } = this.props
		return <Mutation
 			 mutation={DELETE_LOCATION_PICTURE_COMMENT}
 			 refetchQueries={[
 	      	{query: GET_LOCATION_PICTURE_COMMENTS, variables:{ locPicId: parentId }}]}
	    >
	      	{(deleteLocationPictureComment, { loading, error }) => {
	      		return <div className="deletionComment">
	      			<Deletion 
	      			    error={error}
	      				mutationFunction={deleteLocationPictureComment}
	      				handleDelete={this.handleDelete}
	      				handleHideDelete={this.handleHideDelete}
	      				type="location picture comment"
	      			/>
      			</div>
	     	}}
	    </Mutation>
	}

	renderDeleteAttractionComment(){
		const { parentId } = this.props
		return <Mutation
 			 mutation={DELETE_ATTRACTION_COMMENT}
 			 refetchQueries={(result) => {
		      	const placeId = result.data.deleteAttractionCommentCustom.string
		      	return [{query: GET_ATTRACTION_COMMENTS, variables:{attrId: parentId}}, 
		      	{query: GET_OVERALL_ATTRACTION_REVIEWS, variables:{ attrPlaceId: placeId }}]
			}}
	    >
	      	{(deleteAttractionComment, { loading, error }) => {
	      		return <div className="deletionComment">
	      			<Deletion 
	      			    error={error}
	      				mutationFunction={deleteAttractionComment}
	      				handleDelete={this.handleDeleteAttractionComment}
	      				handleHideDelete={this.handleHideDelete}
	      				type="attraction comment"

	      			/>
      			</div>
	     	}}
	    </Mutation>
	}

	renderTripComments(){
		const {  editId, deletionType, showDelete, showEdit } = this.state
		const { currentUser, parentId } = this.props

		const initialValues = {
			comment: ''
		}

		const commentSchema = Yup.object().shape({
		  	comment: Yup.string()
		  	.max(1000, 'Comment is too long. Maximum of 1000 characters.')

		})

		return <Query 
			query={GET_TRIP_COMMENTS}
	    	variables= {{ viewedTripId: parentId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const tripComments = data.tripComments.nodes

				console.log('tripComments', tripComments)
				return <Mutation
		     		 mutation={CREATE_TRIP_COMMENT}
		     		 refetchQueries={[{query: GET_TRIP_COMMENTS, variables:{viewedTripId: parentId}}]}
		     		
			    >
			      	{(createTripComment, { loading, error }) => {
			      		return <Mutation
			     		 	mutation={UPDATE_TRIP_COMMENT}
			     		 	refetchQueries={[
			     	      	{query: GET_TRIP_COMMENTS, variables:{viewedTripId: parentId}}]}
					    >
					      	{(updateTripComment, { loading, error }) => {

					      		return <div className="tripCommentsHolder">
						      		{showDelete && this.renderDeleteTripComment()}
						      		<Comments
						      			comments={tripComments}
						      			createMutationFunction={createTripComment}
						      			currentUser={currentUser}
						      			editId={editId}
						      		    error={error}
						      			handleCancel={this.handleHideEdit}
						      			handleDelete={this.handleShowDelete}
						      			handleEdit={this.handleShowEdit}
						      			handleSubmitEdit={this.handleUpdateComment}
						      			handleSubmit={this.handleCreateTripComment}
						      			initialValues={initialValues}
						      			placeholder="Ask questions or share your thoughts about this trip"
						      			showEdit={showEdit}
						      			updateMutationFunction={updateTripComment}
						      			validationSchema={commentSchema}
						      			view="trip"
						      		/>
			      				</div>
					      	}}

					    </Mutation>
					
			     	}}
			    </Mutation>
			}}
		</Query>
	}

	renderLocPicComments(){
		const { editId, deletionType, showDelete, showEdit } = this.state
		const { currentUser, parentId, view } = this.props
		console.log('parentId!!!', parentId)

		const initialValues = {
			comment: ''
		}
		
		const commentSchema = Yup.object().shape({
		  	comment: Yup.string()
		  	.max(1000, 'Comment is too long. Maximum of 1000 characters.')

		})
		
		return <Query 
			query={GET_LOCATION_PICTURE_COMMENTS}
	    	variables= {{ locPicId: parentId }}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const locPicComments = data.locationPictureComments.nodes
			
				return <Mutation
		     		 mutation={CREATE_NEW_PICTURE_COMMENT}
		     		 refetchQueries={[{query: GET_LOCATION_PICTURE_COMMENTS, variables:{locPicId: parentId}}]}
			    >
			      	{(createNewPictureComment, { loading, error }) => {

			      		return <Mutation
			     		 	mutation={UPDATE_LOCATION_PICTURE_COMMENT}
			     		 	refetchQueries={[
			     	      	{query: GET_LOCATION_PICTURE_COMMENTS, variables:{locPicId: parentId}}]}
					    >
					      	{(updateLocationPictureComment, { loading, error }) => {

					      		return <div className="locPicCommentsHolder">
						      		{showDelete && 
						      			this.renderDeleteLocPicComment()}
						      			
						      		<Comments
						      			comments={locPicComments}
						      			createMutationFunction={createNewPictureComment}
						      			currentUser={currentUser}
						      			editId={editId}
						      		    error={error}
						      			handleCancel={this.handleHideEdit}
						      			handleDelete={this.handleShowDelete}
						      			handleEdit={this.handleShowEdit}
						      			handleSubmitEdit={this.handleUpdateComment}
						      			handleSubmit={this.handleCreatePictureComment}
						      			initialValues={initialValues}
						      			placeholder="Leave a Comment."
						      			showEdit={showEdit}
						      			updateMutationFunction={updateLocationPictureComment}
						      			validationSchema={commentSchema}
						      			view={view}
						      		/>
			      				</div>
					      	}}

					    </Mutation>
					   	
			     	}}
			    </Mutation>
			}}
		</Query>
	}



	renderTripLocComments(){
		const { editId, deletionType, showDelete, showEdit } = this.state
		const { currentUser, parentId } = this.props

		const initialValues = {
			comment: ''
		}

		const commentSchema = Yup.object().shape({
		  	comment: Yup.string()
		  	.max(1000, 'Comment is too long. Maximum of 1000 characters.')

		})
	
		return <Query 
			query={GET_TRIP_LOCATION_COMMENTS}
	    	variables= {{ tripLocId: parentId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const tripLocComments = data.tripLocationComments.nodes
			 	console.log('tripLocComments', tripLocComments)
				return <Mutation
		     		 mutation={CREATE_TRIP_LOCATION_COMMENT}
		     		 refetchQueries={[{query: GET_TRIP_LOCATION_COMMENTS, variables:{tripLocId: parentId}}]}
			    >
			      	{(createTripLocationComment, { loading, error }) => {

			      		return <Mutation
			     		 	mutation={UPDATE_TRIP_LOCATION_COMMENT}
			     		 	refetchQueries={[
			     	      	{query: GET_TRIP_LOCATION_COMMENTS, variables:{tripLocId: parentId}}]}
					    >
					      	{(updateTripLocationComment, { loading, error }) => {

					      		return <div className="tripCommentsHolder">
						      		{showDelete && 
						      			this.renderDeleteTripLocComment()}
						      			
						      		<Comments
						      			comments={tripLocComments}
						      			createMutationFunction={createTripLocationComment}
						      			currentUser={currentUser}
						      			editId={editId}
						      			error={error}
						      			handleCancel={this.handleHideEdit}
						      			handleDelete={this.handleShowDelete}
						      			handleEdit={this.handleShowEdit}
						      			handleSubmitEdit={this.handleUpdateComment}
						      			handleSubmit={this.handleCreateTripLocComment}
						      			initialValues={initialValues}
						      			placeholder="Ask questions or share your thoughts"
						      			showEdit={showEdit}
						      			updateMutationFunction={updateTripLocationComment}
						      			validationSchema={commentSchema}
						      			view="tripLocation"
						      		/>
			      				</div>
					      	}}

					    </Mutation>
					   	
			     	}}
			    </Mutation>
			}}
		</Query>
	}

	renderAttractionComments(){
		const { editId, deletionType, showDelete, showEdit } = this.state
		const { currentUser, parentId } = this.props

		const initialValues = {
			comment: ''
		}

		const commentSchema = Yup.object().shape({
		  	comment: Yup.string()
		  	.max(1000, 'Comment is too long. Maximum of 1000 characters.')

		})
		

		return <Query 
			query={GET_ATTRACTION_COMMENTS}
	    	variables= {{ attrId: parentId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const attractionComments = data.attractionComments.nodes
			
				return <Mutation
		     		 mutation={CREATE_ATTRACTION_COMMENT}
		     		 refetchQueries={(result) => {
				      	const placeId = result.data.createAttractionCommentCustom.string
				      	return [{query: GET_ATTRACTION_COMMENTS, variables:{attrId: parentId}}, 
				      	{query: GET_OVERALL_ATTRACTION_REVIEWS, variables:{ attrPlaceId: placeId }}]
					}}
			    >
			      	{(createAttractionComment, { loading, error }) => {

			      		return <Mutation
			     		 	mutation={UPDATE_ATTRACTION_COMMENT}
			     		 	refetchQueries={(result) => {
						      	const placeId = result.data.updateAttractionCommentCustom.string
						      	return [{query: GET_ATTRACTION_COMMENTS, variables:{attrId: parentId}}, 
						      	{query: GET_OVERALL_ATTRACTION_REVIEWS, variables:{ attrPlaceId: placeId }}]
							}}
					    >
					      	{(updateAttractionComment, { loading, error }) => {

					      		return <div className="attractionCommentsHolder">
						      		{showDelete && 
						      			this.renderDeleteAttractionComment()}
						      			
						      		<Comments
						      			comments={attractionComments}
						      			createMutationFunction={createAttractionComment}
						      			currentUser={currentUser}
						      			editId={editId}
						      			error={error}
						      			handleCancel={this.handleHideEdit}
						      			handleDelete={this.handleShowDelete}
						      			handleEdit={this.handleShowEdit}
						      			handleSubmitEdit={this.handleUpdateAttractionComment}
						      			handleSubmit={this.handleCreateAttractionComment}
						      			initialValues={initialValues}
						      			placeholder="Ask a question or leave a comment"
						      			showEdit={showEdit}
						      			updateMutationFunction={updateAttractionComment}
						      			validationSchema={commentSchema}
						      		/>
			      				</div>
					      	}}

					    </Mutation>
					   	
			     	}}
			    </Mutation>
			}}
		</Query>
	}

	renderOverallAttractionComments(){
		const { editId, deletionType, showAllComments, showDelete, showEdit  } = this.state
		const { currentUser, comments, parentId, placeholder, reviewId } = this.props

		const initialValues = {
			comment: ''
		}

		const commentSchema = Yup.object().shape({
		  	comment: Yup.string()
		  	.max(1000, 'Comment is too long. Maximum of 1000 characters.')

		})
		

		return <Mutation
		     		 mutation={CREATE_ATTRACTION_COMMENT}
		     		 refetchQueries={(result) => {
				      	const placeId = result.data.createAttractionCommentCustom.string
				      	return [{query: GET_ATTRACTION_COMMENTS, variables:{attrId: parentId}}, 
				      	{query: GET_OVERALL_ATTRACTION_REVIEWS, variables:{ attrPlaceId: placeId }}]
					}}
			    >
			      	{(createAttractionComment, { loading, error }) => {

			      		return <Mutation
			     		 	mutation={UPDATE_ATTRACTION_COMMENT}
			     		 	refetchQueries={(result) => {
						      	const placeId = result.data.updateAttractionCommentCustom.string
						      	return [{query: GET_ATTRACTION_COMMENTS, variables:{attrId: parentId}}, 
						      	{query: GET_OVERALL_ATTRACTION_REVIEWS, variables:{ attrPlaceId: placeId }}]
							}}
					    >
					      	{(updateAttractionComment, { loading, error }) => {

					      		return <div className="attractionCommentsHolder">
						      		{showDelete && 
						      			this.renderDeleteAttractionComment()}
						      			
						      		<Comments
						      			editId={editId}
						      			comments={comments}
						      			createMutationFunction={createAttractionComment}
						      			currentUser={currentUser}
						      			error={error}
						      			handleCancel={this.handleHideEdit}
						      			handleDelete={this.handleShowDelete}
						      			handleEdit={this.handleShowEdit}
						      			handleHideReviewComments={this.handleHideReviewComments}
						      			handleShowReviewComments={this.handleShowReviewComments}
						      			handleSubmitEdit={this.handleUpdateAttractionComment}
						      			handleSubmit={this.handleCreateAttractionComment}
						      			initialValues={initialValues}
						      			placeholder="Ask a question or leave a comment"
						      			reviewId={reviewId}
						      			showAllComments={showAllComments}
						      			showEdit={showEdit}
						      			updateMutationFunction={updateAttractionComment}
						      			view="overallAttraction"

						      		/>
			      				</div>
					      	}}

					    </Mutation>
					   	
			     	}}
			    </Mutation>
	}


	render() {
		const { commentType, view } = this.props
		return (
			<div className="commentsContainer">
				{commentType === 'tripLocationComment' && this.renderTripLocComments()}
				{commentType === 'tripComment' && this.renderTripComments()}
				{commentType === 'locationPictureComment' && this.renderLocPicComments()}
				{commentType === 'attractionComment' && (view === 'overallAttraction' ? this.renderOverallAttractionComments() : this.renderAttractionComments())}
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
	)(CommentsContainer);
