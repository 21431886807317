import React from "react";
import { compose, Query, graphql, withApollo } from "react-apollo";

import "../stylesheets/containers/SavedContainer.css"

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries"
import { GET_SAVED_ATTRACTIONS, GET_SAVED_LOCATIONS, GET_MY_TRIPS } from "../apollo/db/queries"

import Attraction from "../components/Attraction"
import Autocomplete from "../components/Autocomplete"
import MapView from "../components/MapView"
import SavedHeader from "../components/SavedHeader"
import SavedListView from "../components/SavedListView"


class SavedContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			attractionPreview: null,
			attractionPlaceId: null,
			centerLat: null, 
			centerLng: null,
			filterTerm: '',
			locationName: null,
			stateError: null,
			view: 'list', 
			zoom: 2,
			zoomId: null, 
			zoomTerm: ''
		}

		this.handleChangeFilter = this.handleChangeFilter.bind(this)
		this.handleChangeZoom = this.handleChangeZoom.bind(this)
		this.handleCloseLocationView = this.handleCloseLocationView.bind(this)
		this.handleEnter = this.handleEnter.bind(this)
		this.handleViewChange = this.handleViewChange.bind(this)
		this.handleGoToAttraction = this.handleGoToAttraction.bind(this)
		this.handleSelectAttraction = this.handleSelectAttraction.bind(this)
		this.handleSelectAttractionPreview = this.handleSelectAttractionPreview.bind(this)
		this.handleSelectLocation = this.handleSelectLocation.bind(this)
		this.handleSelectPin = this.handleSelectPin.bind(this)
		this.handleSelectZoom = this.handleSelectZoom.bind(this)
		this.handleToggleAttraction = this.handleToggleAttraction.bind(this)
	}


	handleChangeZoom(event){
		this.setState({zoomTerm: event.target.value})
	}

	handleChangeFilter(event){
		this.setState({filterTerm: event.target.value})
	}

	handleViewChange(view){
		this.setState({view: view, attractionPreview: null})
	}

	handleSelectZoom(selected){
		if (selected.place_id){
		
			const zoom = selected.types.includes('country') ? 4.5 : 11
			this.setState({
				centerLat: selected.geometry.location.lat(),
				centerLng: selected.geometry.location.lng(),
				stateError: null, 
				zoomId: selected.place_id,
				zoom: zoom})
		}
	}


	handleToggleAttraction(placeId){
		const {attractionPlaceId} = this.state
		if (attractionPlaceId){
			this.setState({attractionPlaceId: null})
		} else {
			this.setState({attractionPlaceId: placeId})
		}
	}

	async handleSelectPin(pin){
		console.log('user feedback to determine what this does')	
	}

	handleSelectAttraction(attractionId){
		history.push(`/attraction/${attractionId}`)
	}

	handleSelectAttractionPreview(attraction){
		this.setState({attractionPreview: attraction})
	}

	handleSelectLocation(locationPlaceId){
		history.push(`/savedLocation/${locationPlaceId}`)
	}

	handleCloseLocationView(){
		this.setState({locationName: null, locationId: null})
	}

	handleEnter(event){
		//if enter is hit before selecting a location- handleSetZoom provided
		event.preventDefault()
		this.setState({stateError: "Please select location to zoom in on"})
	}

	async handleGoToAttraction(attraction){
		history.push(`/attractionPlaceId/${attraction.id}`)
	}

	getLocName(location){
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName.toUpperCase()
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName.toUpperCase()
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName.toUpperCase()
		} else {
			return location.country.toUpperCase()
		}
	}

	renderHeader(){
		const { error, filterTerm, view, zoomTerm } = this.state
		return <SavedHeader
			error={error} 
			filterTerm={filterTerm}
			handleChangeFilter={this.handleChangeFilter}
			handleChangeZoom={this.handleChangeZoom}
			handleEnter={this.handleEnter}
			handleSelectZoom={this.handleSelectZoom}
			handleViewChange={this.handleViewChange}
			view={view}
			zoomTerm={zoomTerm}
		/>
	}

	renderSavedAttractions(){
		const { currentUser } = this.props
		const { 
			attractionPreview, 
			centerLat, 
			centerLng, 
			filterTerm, 
			locationName, 
			locationId, 
			zoomId, 
			view, 
			zoom } = this.state
		return (<Query query={GET_SAVED_LOCATIONS}>
			{({data, loading, error}) => {
				if (loading) return (<div>Loading...</div>)
				if (error) return (<div>Error</div>)
					console.log('data', data)
				
				const savedAttractions = data.savedAttractionViews.nodes

				const savedByLocation = savedAttractions.reduce((accum, attraction)=> {
					const locName = this.getLocName(attraction)
					if (accum[locName]){
							accum[locName][attraction.attractionType].push(attraction)
						} else {
							accum[locName] = {placeId: attraction.locationPlaceId, 'ACTIVITY': [], 'LODGING': [], 'RESTAURANT': []}
							accum[locName][attraction.attractionType].push(attraction)
					}	
					return accum
				}, {})

				return <div>

					{view === "list" ?
						<div className="savedListView">
							<SavedListView 
								currentUser={currentUser}
								filterTerm={filterTerm}
								handleShowAddToTrip={this.handleShowAddToTrip}
								handleSelectAttraction={this.handleSelectAttraction}
								handleSelectLocation={this.handleSelectLocation}
								locationId={locationId}
								savedByLocation={savedByLocation}
								view="saved"
							/>
						</div>
						 : <div className="savedMapView">
							{attractionPreview && 
								<div className="attractionPreview">
									<Attraction
										key={attractionPreview.id}
										attraction={attractionPreview}
										currentUser={currentUser}
										handleShowAddToTrip={this.handleShowAddToTrip}
										handleSelectAttraction={this.handleSelectAttraction}
										id={attractionPreview.id}
										locationUserId={attractionPreview.locationUserId}
										locationId={attractionPreview.locationId} 
										view="saved"
									/>
								</div>}
							<MapView 
								centerLat={centerLat}
								centerLng={centerLng}
								handleSelectPin={this.handleSelectAttractionPreview}
								pins={savedAttractions}
								zoomId={zoomId}
								view="saved"
								zoom={zoom}
							/>
						</div>
					} </div>
			}}
		</Query>)
	}

	render() {
		const { currentUser } = this.props
		const {  view } = this.state
		return (
			<div className="savedContainer">
			{currentUser.id > 0 ? <div>
				<div>	
					{this.renderHeader()}
					
					{this.renderSavedAttractions()}
				</div>	
				</div> : <div>Please login or create an account to view this section</div>}		
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	})
)(SavedContainer);
