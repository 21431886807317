import React from "react";


const NoTripLocations = props => {
	const { 
		handleClose
		} = props;
	return (
		<div className="noTripLocations popup popupSmall">
			<div className="popupContent">
				<div className="tertiaryHeader marginBottom">There are currently no reviews on Trvlpage for this location.</div>
				<div className="formButtons">
					<button className="primaryButton singleFormButton" onClick={() => handleClose()}>Got it</button>
				</div>
			</div>
		</div>

		
	);
};

export default NoTripLocations;