import React from "react";
import ReactPaginate from 'react-paginate';

import "../stylesheets/components/SearchResults.css"

import MapView from "./MapView"
import SearchResult from "./SearchResult"


const SearchResults = props => {
	const { 
		attrContent,
		currentUser, 
		destinationPlace,
		destinationType,
		handleGoToResult,
		handleToggleSavedAttraction,
		handleToggleUnsavedAttraction,
		handlePageChange,
		handleSearch,
		handleSelectPin,
		page,
		pinIndexRef,
		results,
		savedIds, 
		sortedResults, 
		tags, 
		type,
		unsavedIds,
		view
	} = props;
	let secondary, header, link;

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}



	return (
		<div className="searchResults searchJoyride1 searchJoyride2">
			<div className={view === 'destination' ? "searchLeft" : 'searchFull'}>
				{view === "user" && 
				 	results.map((result, index) => {
				 		if (index >= 10 * page && index < 10 * (page + 1)){
				 			header = result.firstName + ' ' + result.lastName
							secondary = result.currentCity ? "Current City: " + result.currentCity : 'Current city not listed'
							return (<SearchResult 
								resultType='user'
								currentUser={currentUser}
								key={result.id} 
								handleGoToResult={handleGoToResult}
								header={header} 
								secondary={secondary} 
								link={`/profile/${result.id}`}
								result={result} 
							/>)
				 		}	
					})
				} 
				{view === 'destination' && destinationType === 'locations' &&  
					sortedResults.map((result, index) => {
						if (index >= 10 * page && index < 10 * (page + 1)){
							secondary = result.firstName ? result.firstName + ' ' + result.lastName : 'overall view'
							link = result.locationId ? `/location/${result.locationId}` : `/locationView/${result.placeId}`
							
							return (<SearchResult 
								resultType='location'
								currentUser={currentUser}
								key={index}
								handleGoToResult={handleGoToResult}
								header={getLocName(result)} 
								secondary={secondary} 
								link={link} 
								pinIndexRef={pinIndexRef}
								result={result}
							/>)
						}
					})
				}
				
				{view === 'destination' && destinationType === 'attractions' && 
					sortedResults.map((result, index) => {
						if (index >= 10 * page && index < 10 * (page + 1)){
							secondary = result.locationName ? result.firstName + ' ' + result.lastName : 'overall view'
							link = result.attractionId ? `/attraction/${result.attractionId}` : `/attractionView/${result.placeId}`
							header = result.name
							return (<SearchResult 
								attrContent={attrContent}
								currentUser={currentUser}
								resultType='attraction'
								handleGoToResult={handleGoToResult}
								handleToggleSavedAttraction={handleToggleSavedAttraction}
								handleToggleUnsavedAttraction={handleToggleUnsavedAttraction}
								handleSearch={handleSearch}
								key={index} 
								header={header} 
								savedIds={savedIds}
								unsavedIds={unsavedIds}
								secondary={secondary} 
								tertiary={getLocName(result)}
								link={link} 
								pinIndexRef={pinIndexRef}
								result={result}
								searchTags={tags}
							/>)
						}
					})
				}
				{view === 'destination' && sortedResults.length > 10 &&

		    	
		           <div className="paginationButtons"><ReactPaginate
			          previousLabel={"← Previous"}
			          nextLabel={"Next →"}
			          breakLabel={<span className="gap">...</span>}
			          pageCount={sortedResults.length/10}
			          onPageChange={handlePageChange}
			          forcePage={page}
			          containerClassName={"pagination"}
			          previousLinkClassName={"textLink"}
			          nextLinkClassName={"textLink"}
			          disabledClassName={"disabledPagination"}
			          activeClassName={"paginationSelected"}
			          pageClassName={"paginationButton"}
			          pageLinkClassName={"paginationButtonLink"}
			        /></div>}

				
				{view === 'user' && results.length > 10 && <ReactPaginate
			          previousLabel={"← Previous"}
			          nextLabel={"Next →"}
			          breakLabel={<span className="gap">...</span>}
			          pageCount={results.length/10}
			          onPageChange={handlePageChange}
			          forcePage={page}
			          containerClassName={"pagination"}
			          previousLinkClassName={"textLink"}
			          nextLinkClassName={"textLink"}
			          disabledClassName={"disabledPagination"}
			          activeClassName={"paginationSelected"}
			          pageClassName={"paginationButton"}
			          pageLinkClassName={"paginationButtonLink"}
			        />}
			</div>

			{view !== 'user' && <div className="searchRight">
				<MapView 
					centerLat={destinationPlace.lat}
					centerLng={destinationPlace.lng}
					handleSelectPin={handleSelectPin}
					pins={pinIndexRef}
					zoom={5}
				/>
			</div>}
		</div>
	);
};

export default SearchResults;
