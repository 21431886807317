import React from "react";
import { compose, graphql, Query, withApollo, Mutation } from "react-apollo";
import * as Yup from "yup"

import history from "../history"

import "../stylesheets/containers/TripTransportationsContainer.css"

import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import { 
	GET_TRIP_TRANSPORTATIONS, 
	GET_TRIP_USERS
} from "../apollo/db/queries"
import {  
	CREATE_TRIP_TRANSPORTATION, 
	DELETE_TRIP_TRANSPORTATION, 
	UPDATE_TRIP_TRANSPORTATION
} from "../apollo/db/mutations"


import TripTransportationForm from "../components/TripTransportationForm"
import Deletion from "../components/Deletion"
import TripTransportations from "../components/TripTransportations"

class TripTransportationsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionId: null,
			editTransportion: null,
			isEditing: false,
			isViewable: false,
			showAdd: false,
			showDelete: false,
			showEdit: false,
			showTooltip: false, 
			showUsers: false,
			stateError: null,
			tooltipType: null,
			transportationType: "FLIGHT",
			user: null,
			value: ''
		}

	
		this.handleChange = this.handleChange.bind(this)
		this.handleChangeType = this.handleChangeType.bind(this)
		this.handleCreateTransportation = this.handleCreateTransportation.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleGoToProfile = this.handleGoToProfile.bind(this)
		this.handleHideAdd = this.handleHideAdd.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleRemoveUser = this.handleRemoveUser.bind(this)
		this.handleSelectUser = this.handleSelectUser.bind(this)
		this.handleShowAdd = this.handleShowAdd.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleShowUsers = this.handleShowUsers.bind(this)
		this.handleToggleIsEditing = this.handleToggleIsEditing.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.handleUpdateTransportation = this.handleUpdateTransportation.bind(this)
	}
	
	async handleShowDelete(deletionId){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: deletionId})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null})
	}

	handleToggleIsEditing(){
		const { isEditing } = this.state
		this.setState({isEditing: !isEditing})
	}


	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}

	handleShowUsers(){
		this.setState({showUsers: true})
	}

	handleSelectUser(user){
		this.setState({user: user, showUsers: false, stateError: null})
	}

	handleRemoveUser(){
		this.setState({user: null})
	}

	async handleShowAdd(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAdd: true})
	}

	async handleHideAdd(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAdd: false})
	}

	handleChangeType(type){
		this.setState({transportationType: type})
	}

	async handleShowEdit(transportation){
		const { setPopup } = this.props
		const user = {
			id: transportation.userId, 
			firstName: transportation.firstName, 
			lastName: transportation.lastName, 
			publicUrl: transportation.publicUrl
		}
		await setPopup({
			variables: {
				popup: false
			}
		})
		
		this.setState({
			editTransportation: transportation, 
			showEdit: true, 
			transportationType: transportation.transportationType, 
			user: user
		})
	}

	async handleHideEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showEdit: false})
	}

	handleGoToProfile(profId){
		history.push(`/profile/${profId}`)
	}

	handleChange(event){
		this.setState({value: event.target.value})
	}

	async handleCreateTransportation(values, createTripTransportation){
		const { currentUser, client, setPopup, tripId } = this.props
		const { transportationType, user } = this.state

		if (!user){
			this.setState({stateError: 'Please select a user for this transportation'})
		} else {
			const userId = user.userId
			const departureTime = values.departureTime ? values.departureTime : null
			const arrivalTime = values.arrivalTime ? values.arrivalTime : null
			const departureDate = values.departureDate ? values.departureDate : null
			const arrivalDate = values.arrivalDate ? values.arrivalDate : null
			const transportationCompany = values.transportationCompany ? values.transportationCompany : null
			const transportationNumber = values.transportationNumber ? values.transportationNumber : null
			const departureCity = values.departureCity ? values.departureCity : null
			const arrivalCity = values.arrivalCity ? values.arrivalCity : null
			const type = values.type


			try {
				await createTripTransportation({   	 	
			   	 	variables: {
			   	 		input: {
			   	 			tripTransportation: {
			   	 			  tripId: tripId,
						      userId: userId,
						      departureTime: departureTime,
						      departureDate: departureDate,
						      arrivalTime: arrivalTime,
						      arrivalDate: arrivalDate,
						      transportationType: type,
						      transportationCompany: transportationCompany,
						      transportationNumber: transportationNumber,
						      departureCity: departureCity,
						      arrivalCity: arrivalCity
			   	 			}
						     
						}
					}
				})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({showAdd: false, user: null})

				
			} catch {
				console.log('error')
			}
		}

		
	}


	async handleUpdateTransportation(values, updateTripTransportation){
		const { currentUser, client, setPopup, tripId } = this.props
		const { editTransportation, transportationType, user } = this.state

		if (!user){
			this.setState({stateError: 'Please select a user for this transportation'})
		} else {
			const userId = user.id
			const departureTime = values.departureTime ? values.departureTime : null
			const arrivalTime = values.arrivalTime ? values.arrivalTime : null
			const departureDate = values.departureDate ? values.departureDate : null
			const arrivalDate = values.arrivalDate ? values.arrivalDate : null
			const transportationCompany = values.transportationCompany ? values.transportationCompany : null
			const transportationNumber = values.transportationNumber ? values.transportationNumber : null
			const departureCity = values.departureCity ? values.departureCity : null
			const arrivalCity = values.arrivalCity ? values.arrivalCity : null
			const type = values.type

			try {
				await updateTripTransportation({   	 	
			   	 	variables: {
			   	 		input: {
			   	 			id: editTransportation.id,
			   	 			tripTransportationPatch: {
			   	 			  tripId: tripId,
						      userId: userId,
						      departureTime: departureTime,
						      departureDate: departureDate,
						      arrivalTime: arrivalTime,
						      arrivalDate: arrivalDate,
						      transportationType: type,
						      transportationCompany: transportationCompany,
						      transportationNumber: transportationNumber,
						      departureCity: departureCity,
						      arrivalCity: arrivalCity
			   	 			}
						     
						}
					}
				})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({showEdit: false, user: null, editTransportation: null})

				
			} catch {
				console.log('error')
			}

		}
		
		
	}
	
	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		const { setPopup } = this.props
		try {
				await mutationFunction({
					variables: {
						input: {
							id: deletionId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showDelete: false, deletionId: null })
			} catch {
				console.log('error')
			}
	}


	renderTransportations (){
		const { isEditing, showAdd, showDelete } = this.state
		const { currentUser, tripId } = this.props
		
		
		return <Query 
			query={GET_TRIP_TRANSPORTATIONS}
	    	variables= {{ viewedTripId: tripId}}
	    	//fetchPolicy='network-only'
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const tripTransportations = data.tripTransportations.nodes
			
				
		      	return <div className="tripTransportationsHolder">
		  
			     
					<TripTransportations 
						currentUser={currentUser}
						handleGoToProfile={this.handleGoToProfile}
						handleShowDelete={this.handleShowDelete}
						handleShowEdit={this.handleShowEdit}
						handleShowAdd={this.handleShowAdd}
						handleToggleIsEditing={this.handleToggleIsEditing}
						isEditing={isEditing}
						tripTransportations={tripTransportations}
					/>
				
				</div>	 
			
			}}
		</Query>
		
	}

	renderDelete(){
		const { tripId } = this.props

		return <Mutation
     		mutation={DELETE_TRIP_TRANSPORTATION}
     		refetchQueries={[{query: GET_TRIP_TRANSPORTATIONS, variables:{viewedTripId: tripId}}]}
	    >
	      {(deleteTripTransportation, { loading, error }) => {
	      	return  <div className="deletionUser">
	      		<Deletion 
	      		    error={error}
	      	 		mutationFunction={deleteTripTransportation}
	      	 		handleDelete={this.handleDelete}
	      	 		handleHideDelete={this.handleHideDelete}
	      	 		type="transportation"
	    		/>
	    	</div>

	      }}
		</Mutation>

	}

	renderAdd(transportation){
		const { currentUser, tripId } = this.props
		const { 
			stateError, 
			showTooltip,
			showUsers, 
			tooltipType,
			transportationType, 
			user, 
			value 
		} = this.state

		const initialValues = {
			transportationCompany: '',
			transportationNumber: '', 
			departureDate: '', 
			departureTime: '', 
			arrivalDate: '', 
			arrivalTime: '', 
			type: 'FLIGHT'
		}

		return <Query query={GET_TRIP_USERS} variables={{viewedTripId: tripId}}>
			{({ loading, error, data }) => {
				if (loading) {return <div> Loading... </div>}
				if (error) {return <div>Error</div>}
				const tripUsers = data.tripUsers.nodes

				return <Mutation
		     		 mutation={CREATE_TRIP_TRANSPORTATION}
		     		 refetchQueries={[{query: GET_TRIP_TRANSPORTATIONS, variables:{viewedTripId: tripId}}]}
			    >
			      	{(createTripTransportation, { loading, error }) => (

			
						 <TripTransportationForm
                            error={error}
							handleChange={this.handleChange}
							handleChangeType={this.handleChangeType}
							handleClose={this.handleHideAdd}
							handleFocus={this.handleShowUsers}
							handleRemoveUser={this.handleRemoveUser}
							handleSelectUser={this.handleSelectUser}
							handleSubmit={this.handleCreateTransportation}
							handleToggleTooltip={this.handleToggleTooltip}
					 		mutationFunction={createTripTransportation}
					 		showTooltip={showTooltip}
							showUsers={showUsers}
						 	stateError={stateError}
							tooltipType={tooltipType}
							tripUsers={tripUsers}
							user={user}
							value={value}
						/>
					)}
			    </Mutation>
			}}
		</Query>	
	}

	renderEdit(){
		const { currentUser, tripId } = this.props
		const {  editTransportation, stateError, showUsers, transportationType, user, value } = this.state

		const initialValues = {
			transportationCompany: editTransportation.transportationCompany,
			transportationNumber: editTransportation.transportationNumber, 
			departureDate: editTransportation.departureDate, 
			departureTime: editTransportation.departureTime, 
			arrivalDate: editTransportation.arrivalDate, 
			arrivalTime: editTransportation.arrivalTime, 
			type: editTransportation.transportationType
		}
		return <Query query={GET_TRIP_USERS} variables={{viewedTripId: tripId}}>
			{({ loading, error, data }) => {
				if (loading) {return <div> Loading... </div>}
				if (error) {return <div>Error</div>}
				const tripUsers = data.tripUsers.nodes

		
				return <Mutation
		     		 mutation={UPDATE_TRIP_TRANSPORTATION}
		     		 refetchQueries={[{query: GET_TRIP_TRANSPORTATIONS, variables:{viewedTripId: tripId}}]}
			    >
			      	{(updateTripTransportation, { loading }) => (
			
						 <TripTransportationForm 
						    error={error}
							handleChange={this.handleChange}
							handleChangeType={this.handleChangeType}
							handleClose={this.handleHideAdd}
							handleFocus={this.handleShowUsers}
							handleRemoveUser={this.handleRemoveUser}
							handleSelectUser={this.handleSelectUser}
							handleSubmit={this.handleUpdateTransportation}
							initialValues={initialValues}
					 		mutationFunction={updateTripTransportation}
							showUsers={showUsers}
						 	stateError={stateError}
							tripUsers={tripUsers}
							user={user}
							value={value}
						/>
					)}
			    </Mutation>
			}}
		</Query>	
	}
	
	render() {
		const { deletionId, isEditing, isViewable, showAdd, showEdit, showDelete } = this.state
		return (<div className="tripTransportationsContainer">
				
				{this.renderTransportations()}
				{showDelete && this.renderDelete()}
				{showEdit && this.renderEdit()}
				{showAdd && this.renderAdd()}
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
)(TripTransportationsContainer);
