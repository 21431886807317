import React from "react";

import "../stylesheets/components/GroupTripLocations.css"

import { Button } from 'reactstrap'

const GroupTripLocations = props => {
	const {
		handleChangeMainLocation,
		handleClose,
		handleGroupLocations,
		handleToggleGroupLocation,
		handleToggleTooltip,
		groupTripLocations,
		locations, 
		mainLocationId,
		selectedLocations, 
		showTooltip,
		stateError, 
		tooltipType
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}

	return (
		<div className="groupTripLocations popup popupMedium">
			<div className="popupContent">
				<div>
       				<div className="labelAndTooltip">
			          	 <div className="secondaryHeader">Group Locations</div>
						<div className="tooltipIcon" onClick={() => handleToggleTooltip('groupTripLocations')}>?</div>
					</div>
					{showTooltip && tooltipType === 'groupTripLocations' && <div className='tooltip'>Group multiple locations to have them display under one name.</div>}
				</div>
				<form 
					className="form" 
					onSubmit={(e) => handleGroupLocations(e, groupTripLocations)}
					onKeyDown={(e) => {
		                if (e.key === 'Enter') {
		                  e.preventDefault()
		                }
		            }} 
		        >
					<div className="groupTripLocsContent">{locations.map(location => {
						return <div className="mainBackground" key={location.id}>
						  <input type="checkbox" id={location.id} name={location.fullName} onChange={() => handleToggleGroupLocation(location.id)} checked={selectedLocations.includes(location.id)}/>
						  <label htmlFor={location.fullName}>{getLocName(location)}</label>
						  {selectedLocations.includes(location.id) && (mainLocationId === location.id ? <div>STAR</div> : <div onClick={() => handleChangeMainLocation(location.id)}>Make Main</div>)}
						</div>
					})}
					</div>
					{stateError && <div>{stateError}</div>}
					<div className="formButtons">
						<Button className="secondaryButton" onClick={() => handleClose()}>Close</Button>
						<Button className="primaryButton" type="submit">Submit</Button>
					</div>

				</form>
			</div>

		</div>
	);
};

export default GroupTripLocations;
