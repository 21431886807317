import React from "react";

import "../stylesheets/components/AttractionBasicInfo.css"

import { Collapse, CardBody, Card } from 'reactstrap';

const AttractionBasicInfo = props => {
	const { 
		attraction,
		collapse, 
		handleToggleHours, 
		handleShowGooglePictures
	} = props

	const getDollarSigns = (dollars) => { 
		if (dollars === 0) {
			return ['free']
		} else {
			const signs = []
			while (signs.length < dollars){
				signs.push(<div>$</div>)
			}
			while (signs.length < 4){
				signs.push(<div className="opaqueSign">$</div>)
			}
			return signs
		}
	}

		
	return (<div className="attractionBasicInfo">
			<div className="headerHolder fullHeaderHolder marginBottom">
				<div className="subHeader">basic information</div>
				<div className="headerUnderline subHeaderUnderline"></div>
			</div>
		{(attraction.dollars || attraction.website) ?
				<div className="mainBackground">
					<div>
					{attraction.website && 
						<div className="basicInfoItem">
							<div className="textTitle fontSmall">Website:</div>
							<a 
								className="fontSmall textLink"
								href={attraction.website}  
								target="_blank"
								rel="noopener noreferrer">{attraction.website}</a>
						</div>}
					</div>
					<div>
					{(attraction && attraction.dollars) && 
							<div className="basicInfoItem">
								<div className="textTitle fontSmall">Price Level: </div>
								<div className="dollarSigns">
									{getDollarSigns(attraction.dollars).map(sign => {
										return sign})} 
								</div>
							</div>}
					</div>
						
				</div> : <div className="attractionBasicInfo mainBackground">Basic Info is not available at the moment</div>}

		</div>
	);
};

export default AttractionBasicInfo;
