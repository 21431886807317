 import gql from "graphql-tag";

const ADD_LOCATION_PICTURE_ATTRACTION = gql `
mutation addLocationPictureAttraction($input: AddLocationPictureAttractionInput!){
  addLocationPictureAttraction(input: $input){
    attrPictureDatum {
      pictureId
      attractionId
      attractionPlaceId
    }
  }
}`

const ADD_ITINERARY_ITEM = gql `
mutation addItineraryItem($input: AddItineraryItemInput!){
  addItineraryItem(input: $input){
    clientMutationId
  }
}`


const ADD_TRIP_ATTRACTION = gql `
mutation addTripAttraction($input: AddTripAttractionInput!){
  addTripAttraction(input: $input){
    integer
  }
}`


const ADD_TRIP_LOCATION_TIP = gql `
mutation addTripLocationTip($input: AddTripLocationTipInput!){
  addTripLocationTip(input: $input){
    integer
  }
}`

const BLOCK_USER = gql `
mutation blockUser($input: BlockUserInput!){
  blockUser(input: $input){
    follower{
      id
      followerId
      followeeId
    }
  }
}`

const CHANGE_ITINERARY_ITEM_DATE = gql`
mutation changeItineraryItemDate($input: ChangeItineraryItemDateInput!){
  changeItineraryItemDate(input: $input){
   clientMutationId
  }
}
`

const CHECK_ENTRY_CODE = gql`
mutation checkEntryCode($input: CheckEntryCodeInput!){
  checkEntryCode(input: $input){
    boolean
  }
}`

const CONNECT = gql`
mutation connect($input: ConnectInput!){
  connect(input: $input){
     clientMutationId
  }
}
`
const CONVERT_ITINERARY = gql`
mutation convertItineraryToReviews($input: ConvertItineraryToReviewsInput!){
  convertItineraryToReviews(input: $input){
     attractions {
      id 
      name
     }
  }
}
`


const CREATE_ATTRACTION = gql`
mutation createAttractionCustom($input: CreateAttractionCustomInput!) {
    createAttractionCustom(input: $input) {
     integer
    }
  }
`


const CREATE_ATTRACTION_COMMENT = gql`
mutation createAttractionCommentCustom($input: CreateAttractionCommentCustomInput!){
  createAttractionCommentCustom(input: $input){
     string
  }
}
`

const CREATE_ATTRACTION_TAGS = gql`
mutation createAttractionTags($input: CreateAttractionTagsInput!){
  createAttractionTags(input: $input){
     clientMutationId
  }
}
`

const CREATE_FOLLOWER = gql`
mutation createFollower($input: CreateFollowerInput!){
  createFollower(input: $input){
    follower{
      id
      followerId
      followeeId
    }
  }
}
`


const CREATE_LOCATION = gql`
mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      location {
        id
        placeId
        locality
        adminLevel1
        country
        fullName
        lat
        lng
      }
    }
  }
`

const CREATE_LOCATION_AND_ATTRACTION_CUSTOM = gql`
mutation createLocationAndAttractionCustom($input: CreateLocationAndAttractionCustomInput!) {
    createLocationAndAttractionCustom(input: $input) {
      clientMutationId
    }
  }
`

const CREATE_LOCATION_AND_ATTRACTION_INSTA = gql`
mutation createLocationAndAttractionInsta($input: CreateLocationAndAttractionInstaInput!) {
    createLocationAndAttractionInsta(input: $input) {
      instagramAddResultData{
        locId 
        locPlaceId
        attrId
        attrPlaceId
      }

    }
  }
`

const CREATE_LOCATIONS = gql`
mutation createLocations($input: CreateLocationsInput!) {
    createLocations(input: $input) {
      clientMutationId

    }
  }
`

const CREATE_LOCATION_DATE = gql`
mutation createLocationDate($input: CreateLocationDateInput!) {
    createLocationDate(input: $input) {
      locationDate {
        id
        locationId
        startDate
        endDate
      }
    }
  }
`
const CREATE_NEW_PICTURE_COMMENT = gql`
mutation createNewPictureComment($input: CreateNewPictureCommentInput!){
  createNewPictureComment(input: $input){
     clientMutationId
  }
}
`

const CREATE_LOCATION_PICTURES = gql `
mutation createLocationPictures($input: CreateLocationPicturesInput!){
  createLocationPictures(input: $input){
    attrPictureData {
      pictureId 
      attractionId
      attractionPlaceId
    }
  }
}

`

const CREATE_NEW_PROFILE_PICTURE = gql `
mutation createNewProfilePicture($input: CreateNewProfilePictureInput!){
  createNewProfilePicture(input: $input){
    profilePicture{
      id
      publicUrl
    } 
  }
}

`

const CREATE_NEW_HEADER_PICTURE = gql `
mutation createNewHeaderPicture($input: CreateNewHeaderPictureInput!){
  createNewHeaderPicture(input: $input){
    clientMutationId
  }
}

`

const CREATE_NEW_TRIP_ATTRACTION_VOTE = gql`
mutation createNewTripAttractionVote($input: CreateNewTripAttractionVoteInput!){
  createNewTripAttractionVote(input: $input){
   clientMutationId
  }
}
`

const CREATE_LOCATION_TIPS = gql`
mutation createLocationTips($input: CreateLocationTipsInput!){
  createLocationTips(input: $input){
   clientMutationId
  }
}
`

const CREATE_NEW_MESSAGE = gql`
mutation createNewMessage($input: CreateNewMessageInput!){
  createNewMessage(input: $input){
   clientMutationId
  }
}
`



const CREATE_PICTURE_TAG = gql `  
mutation createPictureTag($input: CreatePictureTagInput!) {
    createPictureTag(input: $input) {
      pictureTag {
        id
        userId
        pictureId
      }
    }
  }
`


const CREATE_PROFILE_PICTURE = gql `  
mutation createProfilePicture($input: CreateProfilePictureInput!) {
    createProfilePicture(input: $input) {
      profilePicture {
        id
        publicUrl
        userId
        caption
      }
    }
  }
`

const CREATE_REFERRAL = gql`
mutation createReferral($input: CreateReferralInput!){
  createReferral(input: $input){
    clientMutationId
  }
}
`

const CREATE_SAVED_ATTRACTION = gql`
mutation createSavedAttraction($input: CreateSavedAttractionInput!){
  createSavedAttraction(input: $input){
    savedAttraction{
      id
      attractionId
      locationId
      saverUserId
      ownerUserId
    }
  }
}
`

const CREATE_TRIP = gql`
mutation createTrip($input: CreateTripInput!){
  createTrip(input: $input){
    trip{
      id
      startDate
      endDate
      creatorId
      createdAt
    }
  }
}
`

const CREATE_TRIP_ATTRACTION = gql`
mutation createTripAttraction($input: CreateTripAttractionInput!){
  createTripAttraction(input: $input){
    tripAttraction{
      id
      attractionId
      tripGeneralAttractionId 
    }
  }
}
`

const CREATE_TRIP_COMMENT = gql`
mutation createTripCommentCustom($input: CreateTripCommentCustomInput!){
  createTripCommentCustom(input: $input){
    clientMutationId
  }
}
`

const CREATE_TRIP_TRANSPORTATION = gql`
mutation createTripTransportation($input: CreateTripTransportationInput!){
  createTripTransportation(input: $input){
    tripTransportation{
      id
      tripId
      userId
      departureTime
      departureDate
      arrivalTime
      arrivalDate
      transportationType
      transportationCompany
      transportationNumber
      departureCity
      arrivalCity
    }
  }
}
`


const CREATE_NEW_TRIP_GENERAL_ATTRACTION = gql`
mutation createNewTripGeneralAttraction($input: CreateNewTripGeneralAttractionInput!) {
    createNewTripGeneralAttraction(input: $input) {
      clientMutationId
    }
  }
`


const CREATE_TRIP_GENERAL_LOCATION = gql`
mutation createTripGeneralLocation($input: CreateTripGeneralLocationInput!) {
    createTripGeneralLocation(input: $input) {
      tripGeneralLocation {
        id
        fullName
        placeId
        lat
        lng
        tripId 
        createdAt
      }
    }
  }
`
const CREATE_TRIP_GENERAL_LOCATIONS = gql`
mutation createTripGeneralLocations($input: CreateTripGeneralLocationsInput!){
  createTripGeneralLocations(input: $input){
    clientMutationId
  }
}
`


const CREATE_TRIP_LOCATION_COMMENT = gql`
mutation createTripLocCommentCustom($input: CreateTripLocCommentCustomInput!){
  createTripLocCommentCustom(input: $input){
    clientMutationId
  }
}
`



const CREATE_TRIP_LOCATION_DATE = gql`
mutation createTripLocationDate($input: CreateTripLocationDateInput!) {
    createTripLocationDate(input: $input) {
      tripLocationDate {
        id
        tripLocationId
        startDate
        endDate
      }
    }
  }
`

const CREATE_TRIP_USERS = gql`
mutation createTripUsers($input: CreateTripUsersInput!){
  createTripUsers(input: $input){
    integer
  }
}
`

const CREATE_TRIP_USER = gql`
mutation createTripUser($input: CreateTripUsersInput!){
  createTripUser(input: $input){
    tripUser{
      id
      firstName
      lastName
    }
  }
}
`



const CREATE_NEW_USER = gql`
  mutation createNewUser($input: CreateNewUserInput!) {
    createNewUser(input: $input) {
      user {
        id
        authId        
        firstName
        lastName
        currentCity
        birthday
      }
    }
  }
`;


const DELETE_ATTRACTION = gql`
mutation deleteAttractionById($input: DeleteAttractionByIdInput!){
  deleteAttractionById(input: $input){
    deletedAttractionId
  }
}`

const DELETE_ATTRACTION_COMMENT = gql`
mutation deleteAttractionCommentCustom($input: DeleteAttractionCommentCustomInput!){
  deleteAttractionCommentCustom(input: $input){
    string
  }
}`

const DELETE_ATTRACTION_TAGS = gql`
mutation deleteAttractionTags($input: DeleteAttractionTagsInput!){
  deleteAttractionTags(input: $input){
     clientMutationId
  }
}
`

const DELETE_FOLLOWER = gql`
mutation deleteFollowerById($input: DeleteFollowerByIdInput!){
  deleteFollowerById(input: $input){
    deletedFollowerId
  }
}`



const DELETE_LOCATION = gql`
mutation deleteLocationById($input: DeleteLocationByIdInput!){
  deleteLocationById(input: $input){
    deletedLocationId
  }
}`

const DELETE_LOCATION_DATE = gql`
mutation deleteLocationDateById($input: DeleteLocationDateByIdInput!){
  deleteLocationDateById(input: $input){
    deletedLocationDateId
  }
}`

const DELETE_LOCATION_PICTURE = gql `
mutation deleteLocationPictureCustom($input: DeleteLocationPictureCustomInput!){
  deleteLocationPictureCustom(input: $input){
    attrPictureDatum {
      pictureId
      attractionId
      attractionPlaceId
    }
  }
}
`

const DELETE_LOCATION_PICTURE_COMMENT = gql`
mutation deleteLocationPictureCommentById($input: DeleteLocationPictureCommentByIdInput!){
  deleteLocationPictureCommentById(input: $input){
    clientMutationId
  }
}`

const DELETE_LOCATION_TIPS = gql `
mutation deleteLocationTips($input: DeleteLocationTipsInput!){
  deleteLocationTips(input: $input){
    clientMutationId
  }
}
`

const DELETE_MESSAGE = gql`
mutation deleteMessageById($input: DeleteMessageByIdInput!){
  deleteMessageById(input: $input){
    deletedMessageId
  }
}`


const DELETE_PICTURE_TAG = gql`
mutation deletePictureTagById($input: DeletePictureTagByIdInput!){
  deletePictureTagById(input: $input){
    deletedPictureTagId
  }
}`

const DELETE_PROFILE_PICTURE = gql `
mutation deleteProfilePic($input: DeleteProfilePicInput!){
  deleteProfilePic(input: $input){
    clientMutationId
  }
}`



const DELETE_SAVED_ATTRACTION = gql`
mutation deleteSavedAttractionCustom($input: DeleteSavedAttractionCustomInput!){
  deleteSavedAttractionCustom(input: $input){
    tripGeneralLocations {
      id
    }
  }
}`


const DELETE_TRIP_ATTRACTION = gql`
mutation deleteTripAttractionCustom($input: DeleteTripAttractionCustomInput!){
  deleteTripAttractionCustom(input: $input){
    clientMutationId
  }
}`

const DELETE_TRIP_ATTRACTION_VOTE = gql`
mutation deleteTripAttractionVote($input: DeleteTripAttractionVoteByIdInput!){
  deleteTripAttractionVoteById(input: $input){
    deletedTripAttractionVoteId
  }
}`


const DELETE_TRIP_COMMENT = gql`
mutation deleteTripCommentById($input: DeleteTripCommentByIdInput!){
  deleteTripCommentById(input: $input){
    deletedTripCommentId
  }
}`


const DELETE_TRIP_GENERAL_ATTRACTION = gql`
mutation deleteTripGeneralAttractionById($input: DeleteTripGeneralAttractionByIdInput!){
  deleteTripGeneralAttractionById(input: $input){
    deletedTripGeneralAttractionId
  }
}`

const DELETE_TRIP_GENERAL_LOCATION = gql `
mutation deleteTripGeneralLocationById($input: DeleteTripGeneralLocationByIdInput!){
  deleteTripGeneralLocationById(input: $input) {
    deletedTripGeneralLocationId
  }
}`

const DELETE_TRIP_LOCATION_COMMENT = gql`
mutation deleteTripLocationCommentById($input: DeleteTripLocationCommentByIdInput!){
  deleteTripLocationCommentById(input: $input){
    deletedTripLocationCommentId
  }
}`

const DELETE_TRIP_LOCATION_DATE = gql`
mutation deleteTripLocationDateById($input: DeleteTripLocationDateByIdInput!){
  deleteTripLocationDateById(input: $input){
    deletedTripLocationDateId
  }
}`

const DELETE_TRIP_LOCATION_TIP = gql `
mutation deleteTripLocationTipById($input: DeleteTripLocationTipByIdInput!){
  deleteTripLocationTipById(input: $input){
    deletedTripLocationTipId
  }
}
`

const DELETE_TRIP_TRANSPORTATION = gql `
mutation deleteTripTransportationById($input: DeleteTripTransportationByIdInput!){
  deleteTripTransportationById(input: $input) {
    deletedTripTransportationId
  }
}`


const DELETE_TRIP_USER = gql `
mutation deleteTripUserById($input: DeleteTripUserByIdInput!){
  deleteTripUserById(input: $input) {
    deletedTripUserId
  }
}`


const FOLLOW = gql `
 mutation follow($input: FollowInput!){
  follow(input: $input){
    follower{
      followeeId
      id
    }
  }
}
`


const GROUP_TRIP_LOCATIONS = gql `
 mutation groupTripLocations($input: GroupTripLocationsInput!){
  groupTripLocations(input: $input){
    clientMutationId
  }
}
`

const INSERT_ITINERARY_ITEM = gql `
mutation insertItineraryItem($input: InsertItineraryItemInput!){
  insertItineraryItem(input: $input){
    clientMutationId
  }
}
`

const MAKE_MAIN_PICTURE = gql `
mutation makeMainPicture($input: MakeMainPictureInput!){
  makeMainPicture(input: $input){
    clientMutationId
  }
}
`

const MOVE_ITINERARY_ITEM_FORWARD = gql `
mutation moveItineraryItemForward($input: MoveItineraryItemForwardInput!){
  moveItineraryItemForward(input: $input){
    clientMutationId
  }
}
`

const MOVE_ITINERARY_ITEM_BACKWARD = gql `
mutation moveItineraryItemBackward($input: MoveItineraryItemBackwardInput!){
  moveItineraryItemBackward(input: $input){
    clientMutationId
  }
}
`

const REMOVE_ITINERARY_ITEM = gql`
mutation removeItineraryItem($input: RemoveItineraryItemInput!){
  removeItineraryItem(input: $input){
    clientMutationId
  }
}`

const REMOVE_LOCATION_PICTURE_ATTRACTION = gql`
mutation removeLocationPictureAttraction($input: RemoveLocationPictureAttractionInput!){
  removeLocationPictureAttraction(input: $input){
    attrPictureDatum {
      pictureId
      attractionId
      attractionPlaceId
    }
  }
}`

const REMOVE_SAVED = gql `
mutation removeSaved($input: RemoveSavedInput!){
  removeSaved(input: $input){
    clientMutationId
  }
}
`

const REPORT_OBJECTIONABLE = gql `
mutation reportObjectionable($input: ReportObjectionableInput!){
  reportObjectionable(input: $input){
    clientMutationId
  }
}
`
const SAVE_ATTRACTION = gql `
mutation saveAttraction($input: SaveAttractionInput!){
  saveAttraction(input: $input){
    tripGeneralLocations {
      id
    }
  }
}
`


const SAVE_ATTRACTION_INITIAL = gql`
mutation saveAttractionInitial($input: SaveAttractionInitialInput!) {
    saveAttractionInitial(input: $input) {
      integer
    }
  }
`

const RECORD_SEARCH = gql `
  mutation recordSearch($input: RecordSearchInput!){
    recordSearch(input: $input){
      clientMutationId
    }
  }
`

const REPLACE_ATTRACTION_PLACE_IDS = gql `
  mutation replaceAttractionPlaceIds($input: ReplaceAttractionPlaceIdsInput!){
    replaceAttractionPlaceIds(input: $input){
      clientMutationId
    }
  }
`

const SEARCH_ATTRACTION_CLICK = gql `
  mutation searchAttractionClick($input: SearchAttractionClickInput!){
    searchAttractionClick(input: $input){
      clientMutationId
    }
  }
`

const SEARCH_LOCATION_CLICK = gql `
  mutation searchLocationClick($input: SearchLocationClickInput!){
    searchLocationClick(input: $input){
      clientMutationId
    }
  }
`

const SEARCH_PROFILE_CLICK = gql `
  mutation searchProfileClick($input: SearchProfileClickInput!){
    searchProfileClick(input: $input){
      clientMutationId
    }
  }
`

const UNGROUP_TRIP_LOCATIONS = gql `
  mutation ungroupTripLocations($input: UngroupTripLocationsInput!){
    ungroupTripLocations(input: $input){
      clientMutationId
    }
  }
`

const UNTAG_SELF_PICTURE = gql `
  mutation untagSelfPicture($input: UntagSelfPictureInput!){
    untagSelfPicture(input: $input){
      clientMutationId
    }
  }
`



const UPDATE_FOLLOWER_SCORE = gql`
  mutation updateFollowerScore($input: UpdateFollowerScoreInput!){
    updateFollowerScore(input: $input){
      clientMutationId
    }
  }
`



const UPDATE_ATTRACTION = gql `
mutation updateAttractionCustom($input: UpdateAttractionCustomInput!){
  updateAttractionCustom(input: $input){
    clientMutationId
  }
}`

const UPDATE_ATTRACTION_COMMENT = gql`
  mutation updateAttractionCommentCustom($input: UpdateAttractionCommentCustomInput!){
  updateAttractionCommentCustom(input: $input){
   string
  }
}
`;

const UPDATE_FOLLOW = gql `
  mutation updateFollowerById($input: UpdateFollowerByIdInput!){
  updateFollowerById(input: $input){
    follower{
      id
      followerId
      followeeId
      status
    }
  }
}
`

const UPDATE_FOLLOWER_ALERTS = gql`
  mutation updateFollowerAlerts($input: UpdateFollowerAlertsInput!){
    updateFollowerAlerts(input: $input){
      clientMutationId
    }
  }
`

const UPDATE_ITINERARY_ITEM = gql`
  mutation updateItineraryItemById($input: UpdateItineraryItemByIdInput!){
  updateItineraryItemById(input: $input){
    itineraryItem {
      id
      tripLocationId
      title
      note
    }
  }
}
`;

const UPDATE_LOCATION = gql`
mutation updateLocationCustom($input: UpdateLocationCustomInput!){
  updateLocationCustom(input: $input){
    clientMutationId
  }
}`

const UPDATE_LOCATION_DATE = gql`
  mutation updateLocationDateById($input: UpdateLocationDateByIdInput!){
  updateLocationDateById(input: $input){
    locationDate {
      id
      locationId
      startDate
      endDate
    }
  }
}
`;

const UPDATE_LOCATION_PICTURE_COMMENT = gql`
  mutation updateLocationPictureCommentById($input: UpdateLocationPictureCommentByIdInput!){
  updateLocationPictureCommentById(input: $input){
   clientMutationId
  }
}
`;


const UPDATE_LOCATION_TIPS = gql`
mutation updateLocationTips($input: UpdateLocationTipsInput!){
  updateLocationTips(input: $input){
   clientMutationId
  }
}
`

const UPDATE_MESSAGES = gql`
mutation updateMessages($input: UpdateMessagesInput!){
  updateMessages(input: $input){
    clientMutationId
  }
}
`

const UPDATE_MESSAGES_READ = gql`
mutation updateMessagesRead($input: UpdateMessagesReadInput!){
  updateMessagesRead(input: $input){
    clientMutationId
  }
}
`

const UPDATE_NOTIFICATIONS = gql`
mutation updateNotifications($input: UpdateNotificationsInput!){
  updateNotifications(input: $input){
    clientMutationId
  }
}
`


const UPDATE_TRIP = gql`
  mutation updateTripById($input: UpdateTripByIdInput!){
  updateTripById(input: $input){
    trip {
      id
      name 
      startDate
      endDate
    }
  }
}
`;

const UPDATE_TRIP_COMMENT = gql`
  mutation updateTripCommentById($input: UpdateTripCommentByIdInput!){
  updateTripCommentById(input: $input){
    tripComment {
      id
      tripId 
      comment 
      edited
      createdAt
    }
  }
}
`;

const UPDATE_TRIP_LOCATION_COMMENT = gql`
  mutation updateTripLocationCommentById($input: UpdateTripLocationCommentByIdInput!){
  updateTripLocationCommentById(input: $input){
    tripLocationComment {
      id
      tripLocationId 
      comment 
      edited
      createdAt
    }
  }
}
`;

const UPDATE_TRIP_LOCATION_DATE = gql`
  mutation updateTripLocationDateById($input: UpdateTripLocationDateByIdInput!){
  updateTripLocationDateById(input: $input){
    tripLocationDate {
      id
      tripLocationId
      startDate
      endDate
    }
  }
}
`;

const UPDATE_TRIP_TRANSPORTATION = gql`
mutation updateTripTransportationById($input: UpdateTripTransportationByIdInput!){
  updateTripTransportationById(input: $input){
    tripTransportation{
      id
      tripId
      userId
      departureTime
      departureDate
      arrivalTime
      arrivalDate
      transportationType
      transportationCompany
      transportationNumber
      departureCity
      arrivalCity
    }
  }
}
`

const UPDATE_USER = gql`
  mutation updateUserById($input: UpdateUserByIdInput!){
  updateUserById(input: $input){
    user {
      id
      firstName
      lastName
      about
      currentCity
      birthday
      publicity     
    }
  }
}
`;

const UPDATE_WAITING_LIST = gql `
  mutation updateWaitingListById($input: UpdateWaitingListByIdInput!){
  updateWaitingListById(input: $input){
   clientMutationId
  }
}
`

const VIEW_ATTRACTION= gql`
  mutation viewAttraction($input: ViewAttractionInput!){
    viewAttraction(input: $input){
      clientMutationId
    }
  }
`

const VIEW_LOCATION= gql`
  mutation viewLocation($input: ViewLocationInput!){
    viewLocation(input: $input){
      clientMutationId
    }
  }
`

const VIEW_PROFILE= gql`
  mutation viewProfile($input: ViewProfileInput!){
    viewProfile(input: $input){
      clientMutationId
    }
  }
`

const WAIT_LIST_SIGNUP= gql`
  mutation waitListSignup($input: WaitListSignupInput!){
    waitListSignup(input: $input){
      integer
    }
  }
`



export { 
  ADD_ITINERARY_ITEM,
  ADD_LOCATION_PICTURE_ATTRACTION,
  ADD_TRIP_ATTRACTION,
  ADD_TRIP_LOCATION_TIP,
  BLOCK_USER,
  CHANGE_ITINERARY_ITEM_DATE,
  CHECK_ENTRY_CODE,
  CONNECT,
  CONVERT_ITINERARY,
  CREATE_ATTRACTION,
  CREATE_ATTRACTION_COMMENT,
  CREATE_ATTRACTION_TAGS,
  CREATE_FOLLOWER,
  CREATE_LOCATION,
  CREATE_LOCATIONS,
  CREATE_LOCATION_AND_ATTRACTION_CUSTOM,
  CREATE_LOCATION_AND_ATTRACTION_INSTA,
  CREATE_LOCATION_DATE,
  CREATE_NEW_PICTURE_COMMENT,
  CREATE_LOCATION_PICTURES,
  CREATE_LOCATION_TIPS,
  CREATE_NEW_MESSAGE,
  CREATE_NEW_HEADER_PICTURE,
  CREATE_NEW_PROFILE_PICTURE,
  CREATE_NEW_TRIP_ATTRACTION_VOTE,
  CREATE_NEW_TRIP_GENERAL_ATTRACTION, 
  CREATE_NEW_USER,
  CREATE_PICTURE_TAG,
  CREATE_PROFILE_PICTURE,
  CREATE_REFERRAL, 
  CREATE_SAVED_ATTRACTION,
  CREATE_TRIP, 
  CREATE_TRIP_ATTRACTION,
  CREATE_TRIP_COMMENT,
  CREATE_TRIP_GENERAL_LOCATION,
  CREATE_TRIP_GENERAL_LOCATIONS,
  CREATE_TRIP_LOCATION_COMMENT,
  CREATE_TRIP_LOCATION_DATE,
  CREATE_TRIP_TRANSPORTATION,
  CREATE_TRIP_USER, 
  CREATE_TRIP_USERS, 
  DELETE_ATTRACTION,
  DELETE_ATTRACTION_COMMENT,
  DELETE_ATTRACTION_TAGS,
  DELETE_FOLLOWER,
  DELETE_LOCATION,
  DELETE_LOCATION_DATE,
  DELETE_LOCATION_PICTURE,
  DELETE_LOCATION_PICTURE_COMMENT,
  DELETE_LOCATION_TIPS,
  DELETE_MESSAGE,
  DELETE_PICTURE_TAG,
  DELETE_PROFILE_PICTURE, 
  DELETE_SAVED_ATTRACTION,
  DELETE_TRIP_ATTRACTION,
  DELETE_TRIP_ATTRACTION_VOTE,
  DELETE_TRIP_COMMENT,
  DELETE_TRIP_GENERAL_ATTRACTION,
  DELETE_TRIP_GENERAL_LOCATION,
  DELETE_TRIP_LOCATION_COMMENT,
  DELETE_TRIP_LOCATION_DATE,
  DELETE_TRIP_LOCATION_TIP,
  DELETE_TRIP_TRANSPORTATION,
  DELETE_TRIP_USER,
  FOLLOW,
  GROUP_TRIP_LOCATIONS,
  INSERT_ITINERARY_ITEM,
  MAKE_MAIN_PICTURE,
  MOVE_ITINERARY_ITEM_BACKWARD,
  MOVE_ITINERARY_ITEM_FORWARD,
  RECORD_SEARCH,
  REMOVE_ITINERARY_ITEM,
  REMOVE_LOCATION_PICTURE_ATTRACTION,
  REMOVE_SAVED,
  REPORT_OBJECTIONABLE,
  REPLACE_ATTRACTION_PLACE_IDS,
  SAVE_ATTRACTION,
  SAVE_ATTRACTION_INITIAL,
  SEARCH_ATTRACTION_CLICK,
  SEARCH_LOCATION_CLICK, 
  SEARCH_PROFILE_CLICK,
  UNGROUP_TRIP_LOCATIONS,
  UNTAG_SELF_PICTURE,
  UPDATE_ATTRACTION, 
  UPDATE_ATTRACTION_COMMENT,
  UPDATE_FOLLOW,
  UPDATE_FOLLOWER_SCORE,
  UPDATE_FOLLOWER_ALERTS,
  UPDATE_ITINERARY_ITEM,
  UPDATE_LOCATION, 
  UPDATE_LOCATION_DATE,
  UPDATE_LOCATION_PICTURE_COMMENT,
  UPDATE_LOCATION_TIPS,
  UPDATE_MESSAGES, 
  UPDATE_MESSAGES_READ,
  UPDATE_NOTIFICATIONS,
  UPDATE_TRIP,
  UPDATE_TRIP_COMMENT,
  UPDATE_TRIP_LOCATION_COMMENT,
  UPDATE_TRIP_LOCATION_DATE,
  UPDATE_TRIP_TRANSPORTATION,
  UPDATE_USER,
  UPDATE_WAITING_LIST,
  VIEW_ATTRACTION,
  VIEW_LOCATION, 
  VIEW_PROFILE, 
  WAIT_LIST_SIGNUP
   };
