import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import "../stylesheets/components/PictureAdd.css"
import "../stylesheets/components/Form.css"
//import "../stylesheets/components/PictureAdd.css"

import { Button } from 'reactstrap';

 
const PictureAdd = props => {
	const {
		createLocationPictures,
		error,
		handleChange,
		handleClose,
		handleSubmit,
		loading, 
		renderSelectedPicture, 
		selectedPictures 
	} = props;
	return (
		<div className="pictureAdd popup popupXL">
			<div className="popupContent">
				<div className="secondaryHeader">Upload Pictures</div>
				<Formik
			      onSubmit={(values, {setSubmitting}) => {
			        handleSubmit(createLocationPictures, setSubmitting)}
			      }
			    >
			      {({ isSubmitting }) => (
			        <Form className="form">
						{loading
							? <div> loading... this may take a bit </div>
							: <form className="form">
									<label className="primaryButton chooseFiles">Choose Files
									<input className="pictureInput"
										type="file"
										name="file"
										accept="image/*"
										onChange={e =>
											handleChange(e)}
										multiple
									/>
									</label>
									<div className="selectedPictures">{Object.keys(selectedPictures).map(fileIndex => {
											return renderSelectedPicture(selectedPictures, fileIndex)
										})}	
									</div>
									<div className="formButtons">
										<button className={Object.keys(selectedPictures).length ? "secondaryButton formButton" : "secondaryButton singleFormButton"} onClick={handleClose}>Cancel</button>
										{!!Object.keys(selectedPictures).length && <button className={"primaryButton formButton"} type="submit" disabled={isSubmitting}>Submit</button>}
									</div>
								</form>}
						
						{error && <div>There was an error</div>}
					</Form>)}
			    </Formik>
						


			</div>
		</div>
	);
};

export default PictureAdd;
