import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { Button } from 'reactstrap'

const LocationTipsForm = props => {
	const {
		closeText,
		createLocationTips,
		deleteLocationTips,
		error,
		handleClose,
		handleSubmit,
		isEditing,
		initialValues,
		locationId, 
		locationTips,
		updateLocationTips, 
		validationSchema,
		type
	} = props

	return (
		<div className="locationTipsForm popup popupLarge popupScroll">
			<div className="popupContent">
				<div className="secondaryHeader formHeader">General Tips</div>

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						handleSubmit(
							values,
							createLocationTips,
							setSubmitting,
							updateLocationTips, 
							deleteLocationTips,
							locationTips
								
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="form">
							
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="cultural">cultural</label>
								<Field className="formField formFieldLarge" component="textarea" name="cultural" autoComplete="off" placeholder='Ex: It is customary to take off your shoes when you enter homes. All the hostels we stayed at had a place for your shoes outside, so be sure to look before walking in with your shoes on.'/>
							</div>
								<ErrorMessage className="formError" name="cultural" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="transportation">transportation</label>
								<Field className="formField formFieldLarge" component="textarea" name="transportation" autoComplete="off" placeholder="Ex: The train system is very good!" />
							</div>
								<ErrorMessage className="formError" name="transportation" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="packing">packing</label>
								<Field className="formField formFieldLarge" component="textarea" name="packing" autoComplete="off" placeholder="Ex: Be sure to bring good walking shoes. It is quite hilly." />
							</div>
								<ErrorMessage className="formError" name="packing" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="safety">safety</label>
								<Field className="formField formFieldLarge" component="textarea" name="safety" autoComplete="off" placeholder="Ex: Pickpocketing is big. Make sure you keep a close eye on your belongings."/>
							</div>
								<ErrorMessage className="formError" name="packing" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="other">other</label>
								<Field className="formField formFieldLarge" component="textarea" name="other" autoComplete="off" placeholder="Ex: Bring cash, most places don't take credit cards." />
							</div>
								<ErrorMessage className="formError" name="other" component="div" />
						
							{error && <div className="error">There was an error.</div>}
							<div className="formButtons">
								{type === 'createLocation' ? <Button onClick={() => handleClose(locationId)}>{closeText}</Button>
								: <button className="secondaryButton formButton" onClick={() => handleClose(locationId)}>{closeText}</button>}
								<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>
									Submit
								</button>
							</div> 
							

						</Form>}
				</Formik>
			</div>
		</div>
	);
};

export default LocationTipsForm;
