import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/NavBar.css"

import SearchBarContainer from "../containers/SearchBarContainer";

import {
	Navbar,
	Nav,  
  	DropdownToggle,
  	DropdownMenu,
 	DropdownItem, 
	UncontrolledButtonDropdown
} from 'reactstrap';

const NavBar = props => {
	const {
		currentUser,
		handleBlur,
		handleGoToLogin, 
		handleGoToSignup,
		handleToggleMessages,
		handleToggleNotifications,
		logout,
		unseenFollowers,
		unseenMessages,
		unseenNotifications
	} = props;

	return (
			<div className="navBarHolder">
			{currentUser.id > 0
					
				? <div className="navBar">
					<div className="leftColumnSpace">
					</div>
					<div className="navMain">
						<div className="searchBar">
							<SearchBarContainer />

			
						</div>
						{/*<div className="navMiddle">
							<img src="./icon.png" className="picSmall"/>
						</div>*/}
						<div className="navButtons">
							<div className="navLinks">
						
								<div className="navProf">
									<img 
										className="picSmall" 
										src={currentUser.publicUrl} 
										alt="profPic"/>
									<Link className="navLink" to={`/profile/${currentUser.id}`}>My Trvlpage</Link>
								</div>
								<Link className="navLink" to="/explore">Explore</Link>
								{/*<Link className="navLink" to="/trips">Trips</Link>*/}
								<Link className="navLink" to="/saved">Saves</Link>
								<Link className="navLink" to="/add">Add</Link>
			
							</div>
						


							
							<div className="navIcon">
								<i className={'material-icons activeIcon iconMedium'} onClick={(e => handleToggleMessages(e))}>email</i>
								
								{!!Number(unseenMessages) && <div className="notificationDot"></div>}
							</div>


							
							<div className="navIcon">
									<i className={'material-icons activeIcon iconMedium'} onClick={(e) => handleToggleNotifications(e)}>notifications</i>

									{(!!Number(unseenNotifications) || !!Number(unseenFollowers)) && <div className="notificationDot"></div>}
							</div>
							
						</div>

					</div>
					<div className="dropdown">
						<div>
								<UncontrolledButtonDropdown>
									<DropdownToggle className="dropdownToggle">
										...
									</DropdownToggle>
									<DropdownMenu className="dropdownMenu">
										<DropdownItem>
											<div onClick={e => logout(e)}>
												Logout
											</div>
										</DropdownItem>
										<DropdownItem>
											<Link className="settingsLink" to="/settings">
												Settings
											</Link>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledButtonDropdown>
							</div>
					</div>
					</div>
				: 
				<div className="navBar">
					<div className="leftColumnSpace">
					</div>
					<div className="navMain">
						<div className="searchBar">
							<SearchBarContainer />
						</div>
						<div className="navButtons">
							<Link className="navLink" to="/explore">Explore</Link>
							<div className="navLink" onClick={() => handleGoToSignup()}>Sign Up</div>
							<div className="navLink" onClick={() => handleGoToLogin()}>Login</div>
						</div>
					</div>
					<div className="navGuest">
					</div>

				</div>}
				</div>
		

		
	);
};

export default NavBar;
