import React from "react";

//import "../stylesheets/components/Explore.css"

const OverallMonthsVisited = props => {
	const { 
		unknownMonthVisitors,
		visitorsInMonths
	} = props
	return (
		<div className="overallMonthsVisited">
		 	{Object.keys(visitorsInMonths).map(month => {
		 		return <div key={month}>
		 			<div>{month}</div>
		 			<div>{visitorsInMonths[month].length}</div>
		 		{/* this will be a dropdown with visitors name*/}
		 		</div>
		 	})}

		</div>
	);
};

export default OverallMonthsVisited;
