import React from "react";


const InstaConnectionRequest = props => {
	const { 
		 handleClose, 
		 handleConnect
		} = props;
	return <div className="deletion popup popupSmall">
			<div className="popupContent">
					<div className="tertiaryHeader">Connect your Trvlpage to your Instagram</div>
					<div>This way you can easily add locations to your Trvlpage based on your Instagram pictures</div>
					<div className="formButtons">
						<button className="secondaryButton formButton" onClick={() => handleClose()}>
							Later
						</button>
						<button className="secondaryButton formButton" onClick={() => handleConnect()}>
							Later
						</button>
					</div>
				</div>
		</div>
};

export default InstaConnectionRequest;