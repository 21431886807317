import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from "react-router-dom";

import initialPic from '../assets/globe.jpg'


const Login = props => {
	const {
		error, 
		handleGoToReset,
		handleResendVerification,
		handleSubmit, 
		initialValues,
		stateError, 
		resendOption,
		verification 
	} = props
	return (
	<div className="initialScreen">
		<div className="initialScreenLeft">
			<div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">login</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
			{verification && <div>This email has already been verified. Please Log In</div>}
		<Formik
		      initialValues={initialValues}
	
		      onSubmit={(values, { setSubmitting }) => {
		        handleSubmit(values, setSubmitting)
		      }}
		    >
		      {({ isSubmitting }) => (
		        <Form 
		        	className="form"
		        	onKeyDown={(e) => {
		                if (e.key === 'Enter') {
		                  e.preventDefault()
		                }
		            }} 
		        >
		        	<div className="formItem">
			          	<label className="textTitle textTitleForm" htmlFor="email">email</label>
			          	<Field className="formField" type="text" name="email" autoComplete="on" required/>
		          	</div>
			          	<ErrorMessage name="email" component="div" required/>
		          	<div className="formItem">
			          <label className="textTitle textTitleForm" htmlFor="password">password</label>
			          <Field className="formField" type="password" name="password" autoComplete="on" required />
			        </div>
			          <ErrorMessage name="password" component="div" />
			          {stateError && <div className="error">{stateError}</div>}
			          	{error && <div className="error">There was an error.</div>}
			        {resendOption && <button className="primaryButton" onClick={() => handleResendVerification()}>Resend Verification Email</button>}
			       	
			       	<button className="primaryButton singleFormButton" type="submit">
			            Log In
			          </button>
			       	 
		        </Form>
		      )}
		    </Formik>
		     <button className="secondaryButton topButton" onClick={() => handleGoToReset()}> 
			       	  	Forgot Password
			       	  </button>
			          
			       	<Link className="textLink" to="/signup">Sign Up</Link>
		 </div>
		 <div className="initialScreenRight">
			<img 
			className="initialPicture"
		 	src={require('../assets/globe.jpg')}
		 	alt="loginPicture"/>
		</div>

	</div>);
}

export default Login;
