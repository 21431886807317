import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
//import { persistCache } from 'apollo-cache-persist';
import { HttpLink } from "apollo-link-http";
//import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { ApolloLink } from "apollo-link";
import gql from "graphql-tag";

import 'bootstrap/dist/css/bootstrap.min.css';

import { defaultState } from "./apollo/state/state";

import Routes from "./Routes";
import resolvers from "./apollo/state/resolvers";

import * as firebase from 'firebase';

const cache = new InMemoryCache();

const getUrl = () => {
  if (process.env.REACT_APP_SERVER_ENV === 'qa'){
      return ''
  } else if (process.env.NODE_ENV === 'development'){
    if (process.env.REACT_APP_SERVER_ENV === 'local'){
        return 'http://localhost:5000'
        //return  'https://trvlpage.uc.r.appspot.com'
    } else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
      return  'https://trvlpage.uc.r.appspot.com'
    } else {
      //return 'https://20210526t174430-dot-trvlpage.appspot.com'
      return  'https://20220112t173811-dot-trvlpage.appspot.com'
    } 
  } else {
    return  'https://trvlpage.uc.r.appspot.com'
  }
}


//const logLink = onError(error => console.error("Apollo Error", error)); // as any

var firebaseConfig = {
          apiKey: "AIzaSyBLZlN0kaloKuCxOqFebTvfbfYTJoy_VBw",
          authDomain: "trvlpage.firebaseapp.com",
          databaseURL: "https://trvlpage.firebaseio.com",
          projectId: "trvlpage",
          storageBucket: "trvlpage.appspot.com",
          messagingSenderId: "66604851728",
          appId: "1:66604851728:web:72edcc934a69fe6bbd2095",
          measurementId: "G-FCSE2TFTTJ"
        };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();


const typeDefs = gql`
  extend type Location {
    adminLevel1: String,
    country: String,
    firstName: String,
    fullName: String,
    id: Int,
    lastName: String,
    locality: String,
    publicUrl: String,
    score: String,
    userId: Int
  }, 
  extend type Place {
    locality: String, 
    adminLevel1: String, 
    country: String, 
    lat: Decimal, 
    lng: Decimal
  }
`;

// const setUpCache = async () => {
//   await persistCache({
//     cache,
//     storage: window.localStorage,
//   });
// }

//setUpCache();



const stateLink = withClientState({
  cache,
  defaults: defaultState,
  resolvers: {
    Mutation: resolvers
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    stateLink,
    new HttpLink({
      uri: `${getUrl()}/graphql`,
      credentials: "include"
    })
  ]),
  cache,
  //resolvers: {}, 
  typeDefs
});

client.onResetStore(stateLink.writeDefaults);

const ApolloApp = RoutesComponent =>
  <div>
    <ApolloProvider client={client}>
      <RoutesComponent />
    </ApolloProvider>
  </div>;

ReactDOM.render(ApolloApp(Routes), document.getElementById("root"));

