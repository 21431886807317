import React from "react";
import { Link } from "react-router-dom";


import "../stylesheets/components/AttractionHeader.css"

import AddToTripContainer from "../containers/AddToTripContainer"
import AttractionTags from "../components/AttractionTags"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"
import SavedButtonContainer from "../containers/SavedButtonContainer"

const AttractionHeader = props => {
	const { 
		attraction,
		currentUser, 
		handleShowDelete,
		handleShowEdit, 
	} = props


		const getIcon = attractionType => {
			if (attractionType === "ACTIVITY"){
				return <i className="material-icons nonClickableIcon iconLarge">local_activity</i>
 
			} else if (attractionType === 'RESTAURANT'){
				return <div className="restaurantIcons">
					<i className="material-icons nonClickableIcon iconLarge">local_bar</i>
					<i className="material-icons nonClickableIcon iconLarge">restaurant</i>
				</div>
			} else if (attractionType === "LODGING") {
				return <i className="material-icons nonClickableIcon iconLarge">hotel</i>
			}
	}

		const getStars =  (count, className) => {
			const stars = []
			let filledStars, halfStar;
			if (count % 1 >= 0.75){
				filledStars = Math.round(count);
				halfStar = false;
			} else if (count % 1 < 0.75 && count % 1 >= 0.25){
				filledStars = (Math.floor(count));
				halfStar = true;
			} else {
				filledStars = (Math.floor(count));
				halfStar = false;
			}
			while(stars.length < filledStars){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}  
			if (halfStar){
				stars.push(
					<i className={`material-icons nonClickableIcon ${className}`}>star_half</i>
					)
			}
			while (stars.length < 5){
				stars.push(
						<i className={`material-icons nonClickableInactiveIcon ${className}`}>star</i>
				)
			}
			 
			return stars
		}




	return (
		<div className="attractionHeader">
			<div className="attractionHeaderContent">
					<div className="attractionHeaderTop">
						
						<div className="headerHolder fullHeaderHolder">
							<div className="mainHeader">{attraction.name}</div>
							<div className="headerUnderline mainHeaderUnderline"></div>
						</div>
						<div>
						{currentUser.id === attraction.userId ?  
							<div className="icons">
								{attraction.visibility === 'PRIVATE' && <i className="material-icons nonClickableIcon iconSmall">visibility_off</i>}
								<i className="material-icons activeIcon iconMedium" onClick={() => handleShowEdit(attraction)}>edit</i>
								<i className="material-icons activeIcon iconMedium" onClick={() => handleShowDelete(attraction)}>delete</i>
	

							</div> :
								<div className="addAndSaveButtons"> 
									{/*<AddToTripContainer 
										attraction={attraction}
										locationId={attraction.locationId}
										type="attraction"
									/>*/}
									{!!currentUser.id && <SavedButtonContainer 
										attraction={attraction}
										view='attraction'
									/>}
								</div>
							}
						</div>
					</div>
					<Link className="secondaryHeader textLink" to={`/location/${attraction.locationId}`}>{attraction.locationName}</Link>
					<div className="nameAndStars">
						<Link className ="tertiaryHeader textLink" to={`/profile/${attraction.userId}`}>{(attraction.firstName + " " + attraction.lastName)}</Link>
						{attraction.stars && <div className="stars headerStars">
							{getStars(attraction.stars, 'star headerStar')}
							</div>}
						<div className="booking"></div>
					</div>
					{attraction.tags && <AttractionTags 
						tags={attraction.tags}
						view='attraction'
					/>}
					{currentUser.id > 0 && currentUser.id !== attraction.userId && <div>
						<ReportObjectionableContainer
							reportItem={attraction}
							reportType="ATTRACTION"
							reportTypeRender="attraction"
						 />
						</div>}
				</div>
				
	
		</div>
	);
};

export default AttractionHeader;
