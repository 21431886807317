import React from 'react';
import { DragSource } from 'react-dnd';
import moment from 'moment'

import "../stylesheets/components/ItineraryItem.css"


class ItineraryItem extends React.Component {

	render() {
		const { itineraryItem, isDragging, connectDragSource } = this.props;
		const opacity = isDragging ? 0.5 : 1;

		return connectDragSource(
			<div className="itineraryItem secondaryBackgroundColor" style={{ opacity }}>
				{itineraryItem.title ? <div className="itemTitle">{itineraryItem.title}</div> : <div className="itemTitle">{itineraryItem.name}</div>}
				<div className="timeframe">{itineraryItem.startTime && <div>{(moment(itineraryItem.startTime, 'HH:mm').format('hh:mm a'))}</div>}
				{(itineraryItem.startTime && itineraryItem.endTime) && <div>-</div>}
				{itineraryItem.endTime && <div>{(moment(itineraryItem.endTime, 'HH:mm').format('hh:mm a'))}</div>}
				</div>
				{itineraryItem.note && <div>{itineraryItem.note}</div>}
			</div>);
	}
}

const cardSource = {

	beginDrag(props) {		
		return {			
			id: props.itineraryItem.id,
			type: props.type,
			orderNumber: props.itineraryItem.orderNumber
		};
	},

	endDrag(props, monitor) {
		const item = monitor.getItem();
		const dropResult = monitor.getDropResult();

		if (!dropResult && item.type === 'existing'){
			props.handleShowDelete(item.id, item.orderNumber)
		}
	}
};


export default DragSource("CARD", cardSource, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
}))(ItineraryItem)

