import React from "react";


const UploadCallout = props => {
	const { 
		currentUser, 
		handleConnectInsta,
		handleGoToAdd, 
		handleHideCallout
		} = props;

	if (!currentUser.locUploadNumber && !currentUser.attrUploadNumber){
		return (
			<div className="uploadCallout popup popupSmall">
				<div className="popupContent">
					<div className="secondaryHeader">Don't Forget!</div>
					<div className="marginBottom centerText">Start uploading your own travels using the Add button or by connecting to your Instagram</div>
					
					<button className="primaryButton marginBottom" onClick={() => handleConnectInsta()}>Connect</button>
						<button className="primaryButton marginBottom" onClick={() => handleGoToAdd()}>Add</button>
						<button className="secondaryButton marginBottom" onClick={() => handleHideCallout()}>Later</button>

			
				</div>
			</div>

		
		);
	} else if (currentUser.locUploadNumber && currentUser.attrUploadNumber && !currentUser.seenInstaConnection){
		return (
			<div className="uploadCallout popup popupSmall">
				<div className="popupContent">
					<div className="secondaryHeader">Connect to Instagram</div>
					<div className="marginBottom centerText">Don’t forget. You can easily add locations and attractions to your Trvlpage based on your Instagram pictures! Connect below or from My trvlpage.</div>
					
					<button className="primaryButton marginBottom" onClick={() => handleConnectInsta()}>Connect</button>
						<button className="secondaryButton marginBottom" onClick={() => handleHideCallout()}>Later</button>

			
				</div>
			</div>

		
		);
	} else {
		return (
			<div className="uploadCallout popup popupSmall">
				<div className="popupContent">
					<div className="secondaryHeader">Add Details</div>
					<div className="marginBottom centerText">Make sure to add details to your trips so you don’t forget what you did and your friends know what to do!</div>
					
					{!currentUser.seenInstaConnection && <button className="primaryButton marginBottom" onClick={() => handleConnectInsta()}>Connect</button>}
						<button className="primaryButton marginBottom" onClick={() => handleGoToAdd()}>Add</button>
						<button className="secondaryButton marginBottom" onClick={() => handleHideCallout()}>Later</button>

			
				</div>
			</div>

		
		);
	}
	
};

export default UploadCallout;
