import React from "react";

// /import "../stylesheets/components/ItineraryItemDetails.css"
import { Button } from 'reactstrap'

const ItineraryItemDetails = props => {
	const { 
		handleClose,
		handleShowDelete,
		handleShowEdit, 
		item
	} = props
	return (
		<div className="itineraryItemDetails popup popupMedium">
			<div className="popupContent">
				<div className="itineraryItemDetailsHeader">
					{item.title ? <div className="secondaryHeader">{item.title}</div> : <div className="secondaryHeader">{item.name}</div>}
					<div className="itineraryEditButtons">
						<img 
								className="iconSmall" 
								onClick={() => handleShowEdit(item)}
								src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
								alt="editIcon"
						/>
						<img 
								className="iconSmall" 
								onClick={() => handleShowDelete(item)}
								src="https://storage.googleapis.com/trvlpage-bucket/trash.svg" 
								alt="deleteIcon"
						/>
					</div>
				</div>
				<div className='form'>
					<div className="formItem">
						<div className="textTitle textTitleForm">date</div>
						<div>{item.tripDate}</div>
					</div>
					<div className="formItem">
						<div className="textTitle textTitleForm">start time</div>
						<div>{item.startTime}</div>
					</div>
					<div className="formItem">
						<div className="textTitle textTitleForm">end time</div>
						<div>{item.endTime}</div>
					</div>
					<div className="formItem">
						<div className="textTitle textTitleForm">notes</div>
						<div>{item.notes}</div>
					</div>
				</div>
			</div>

			<button className="tertiaryButton iconMedium" onClick={() => handleClose()}>x</button>



		</div>
	);
};

export default ItineraryItemDetails;
