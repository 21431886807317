import React from "react";

import { Button } from 'reactstrap'

const CreatePictureTag = props => {
	const { 
		createPictureTag,
		currentPicIndex,
		following,
		handleChange,
		handleSelect,
		pictures,
		taggedUsers,
		userValue
	} = props


	const isTagged = (followeeId) => {
		const tags = pictures[currentPicIndex].tags
		if (tags && tags.find(tag => tag.userId === followeeId)){
			return true
		}

	}
	
	return (<div className="createPictureTag">
			<input 
				className="createPictureTagInput" 
				style={{width: '150px'}} 
				onChange={e => handleChange(e)} 
				type="text" 
				name="userSearch" 
				value={userValue}
				autoComplete="off"
			/>
			<div className="mainBackground selectDropdown">{following.map(followee => {
				if ((followee.firstName.toLowerCase().startsWith(userValue.toLowerCase()) 
					|| followee.lastName.toLowerCase().startsWith(userValue.toLowerCase())) 
					&& !isTagged(followee.followeeId)){
						return <div onClick={() => handleSelect(followee.followeeId, createPictureTag, followee.pushToken)} key={followee.followeeId}>
							<div className='userInfo'>{followee.firstName + ' ' + followee.lastName}</div>
						</div>			
				}
			})}</div>

	</div>
		
	);
};

export default CreatePictureTag;
