import React from "react";

import Attraction from "../components/Attraction";
import MapView from "../components/MapView"


const Attractions = props => {
	const { 
		attractions,
		currentUser,
		handleSelectAttraction,
		handleShowAddAttraction,
		handleSelectPin,
		handleShowDelete,
		handleShowEdit,
		handleShowOtherVisitors,
		locationUserId,
		locationLat,
		locationLng, 
		sortedAttractions

	} = props

	const getIcon = attractionType => {
			if (attractionType === "ACTIVITY"){
				return <i className={'material-icons nonClickableIcon iconMedium'}>local_activity</i> 
			
			} else if (attractionType === 'RESTAURANT'){
				return <div className="restaurantIcons">
					<i className={'material-icons nonClickableIcon iconMedium'}>local_bar</i>
					<i className={'material-icons nonClickableIcon iconMedium'}>local_restaurant</i> 
				</div>
			} else if (attractionType === "LODGING") {
				return <i className={'material-icons nonClickableIcon iconMedium'}>hotel</i>
			}
	}

		const displayWording = {
			'ACTIVITY': "activities", 
			'LODGING': "lodging", 
			'RESTAURANT': 'food & drinks'
		}
		return <div className="attractionsContent">
			<div className="attractionsLeft">
				{Object.keys(sortedAttractions).map(type => {
				return (<div key={type}>

					<div className="headerAndIcons">
						<div>
							<div className="headerHolder fullHeaderHolder marginBottom">
								<div className="categoryHeader">{displayWording[type]}</div>
								<div className="headerUnderline categoryHeaderUnderline"></div>
							</div>
				
							{/*<div className="icons">{getIcon(type)}</div>*/}
						</div>
						{locationUserId === currentUser.id && <i className={type === "ACTIVITY" ? 'material-icons activeIcon iconMedium locJoyride5' : 'material-icons activeIcon iconMedium'} onClick={() => handleShowAddAttraction(type)}>add_location</i>}
					</div>
					
					<div >
						{sortedAttractions[type].length ? sortedAttractions[type].map(attraction => {
							const number = (attractions.indexOf(attraction) + 1).toString()
			

						return (<div key={attraction.id}>
	 							<div name={`attraction${attraction.id}`}>
		 							<Attraction
										attraction={attraction}
										currentUser={currentUser}
										handleSelectAttraction={handleSelectAttraction}
										handleShowDelete={handleShowDelete}
										handleShowEdit={handleShowEdit}
										handleShowOtherVisitors={handleShowOtherVisitors}
										id={attraction.id}
										locationUserId={attraction.userId}
										locationId={attraction.locationId}
										number={number}
										otherVisitors={currentUser.id ? attraction.visitors : []}
										view="location"
							 		/>	
						 		</div>
			
						</div>)

							
							
					}) : <div className="mainBackground">This user has not uploaded any {displayWording[type].toLowerCase()} for this location.</div>}
					</div>
							
				</div>)})}
			</div>
			<div className="attractionsRight">
				<MapView 
					centerLat={Number(locationLat)} 
					centerLng={Number(locationLng)} 
					handleSelectPin={handleSelectPin}
					pins={attractions}
					view="location"
					zoom={11} 
				/>
			</div>
		</div>
};

export default Attractions;
