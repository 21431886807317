import React from "react";
import { Mutation } from "react-apollo";
import { GoogleApiWrapper } from "google-maps-react";
import * as Yup from 'yup'
import { analytics } from '../index.js';

import Autocomplete from "../components/Autocomplete"
import LocationDetails from "../components/LocationDetails"
import LocationTipsForm from "../components/LocationTipsForm"

import { CREATE_LOCATION, CREATE_LOCATION_DATE, CREATE_LOCATION_TIPS, UPDATE_LOCATION } from "../apollo/db/mutations"
import { GET_LOCATION_VIEW_AND_DATES, GET_USER_AND_LOCATIONS } from "../apollo/db/queries";

class AddLocationContainer extends React.Component {
	constructor() {
		super();
		this.state = {
		      addLocation: 'add', 
		      attractionId: null,
		      loading: false, 
		      locationId: null,
		      showTooltip: false,
		      tooltipType: null,
		      stateError: null, 
		      value: ''
	
		    };

    		this.handleChange = this.handleChange.bind(this)
    		this.handleCreateLocation = this.handleCreateLocation.bind(this)
    		this.handleCreateLocationTips = this.handleCreateLocationTips.bind(this)
    		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
    		this.handleReviewLater = this.handleReviewLater.bind(this)
    		this.handleUpdateLocation = this.handleUpdateLocation.bind(this)
    		this.handleTipsLater = this.handleTipsLater.bind(this)
    	
    		
	}
		

	handleChange(event){
		this.setState({value: event.target.value, stateError: null})
	}

	async handleReviewLater(){
		this.setState({addLocation: 'tips', showTooltip: false, stateError: null})
		if (process.env.NODE_ENV !== 'development') {
			await analytics.logEvent('add_loc_process', {
			  page: 'details', 
			  action: 'later'
			});	
		}
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}

	async handleTipsLater(){
		const { handleClose } = this.props
		handleClose()
		if (process.env.NODE_ENV !== 'development') {
			await analytics.logEvent('add_loc_process', {
			  page: 'tips', 
			  action: 'later'
			});	
		}
	}


	async handleCreateLocation(createLocation, selected, setSubmitting) {
		const { currentUser, locations } = this.props
		console.log('this is running to add', currentUser)
		setSubmitting(true)


		if (!selected || !selected.place_id) {
			this.setState({stateError: 'Please select a location'})
			return null;
		}
		this.setState({loading: true})

		const placeIds = locations.map(location => {
			return location.placeId
		})


		if (!placeIds.includes(selected.place_id)){


			const localityObj = selected.address_components.find(component => {
				return component.types.includes('locality')
			})
			const locality = localityObj ? localityObj.long_name : null
			const adminLevel1Obj = selected.address_components.find(component => {
				return component.types.includes('administrative_area_level_1')
			})
			const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
			const countryObj = selected.address_components.find(component => {
				return component.types.includes('country')
			})
			const country = countryObj.long_name 


			try {
				const res = await createLocation({
					variables: {
						input: {
							location: {
								userId: currentUser.id,
							    placeId: selected.place_id,
								locality: locality, 
								adminLevel1: adminLevel1, 
								country: country, 
							    fullName: selected.formatted_address, 
							    lat: selected.geometry.location.lat(), 
							    lng: selected.geometry.location.lng()
								}
							}	
						}
					})

				const locationId = res.data.createLocation.location.id

				await this.setState({ 
					loading: false, 
					stateError: null, 
					locationId: locationId,
					value: "", 
					addLocation: "details", 
					showTooltip: false })

				setSubmitting(false)

				if (process.env.NODE_ENV !== 'development'){

					await analytics.logEvent('add_loc_process', {
					  page: 'add', 
					  action: 'complete'
					});	

					await analytics.logEvent('add_location', {
					  place: selected
					});
				}
			} catch(error) {
				setSubmitting(false)
				console.log('error', error)
			}
		} else {
			setSubmitting(false)
			this.setState({stateError: "You have already added this location"})
		}

	} 

	async handleCreateLocationTips(values, createLocationTips, setSubmitting) {
		const { locationId } = this.state	
		const { handleClose } = this.props

		setSubmitting(true)

		const locationTips = []

		Object.keys(values).forEach(category => {
			if (values[category]){
				locationTips.push({tip: values[category], category: category.toUpperCase()})
			}
		})
			
		try {
			const res = await createLocationTips({
				variables: {
					input: {
						locId: locationId, 
						locationTips: locationTips
					}

					}
				})
			setSubmitting(false)
			handleClose(locationId)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_location_tips', {
				  amount: locationTips.length
				});

				await analytics.logEvent('add_loc_process', {
				  page: 'tips', 
				  action: 'complete'
				});
				
			}

		} catch (error) {
			setSubmitting(false)
			console.log('error with tips', error)
		}
	

	}
		
     async handleUpdateLocation(values, updateLocation, createLocationDate, setSubmitting){
    	const { handleClose } = this.props
    	const { locationId } = this.state

    	setSubmitting(true)

    	this.setState({loading: true})
		const overallReview = values.overallReview.length ? values.overallReview : null
		const startDate = values.startDate ? values.startDate : null
		const endDate = values.endDate ? values.endDate : null

	
		try {
			await updateLocation({
    		variables: {
						input: {
							locId: locationId,
							newOverallReview: overallReview
							}	
						}
					})


			await createLocationDate({
					variables: {
						input: {
							locationDate: {
								locationId: locationId, 
								startDate: startDate, 
								endDate: endDate

							}
						}
					}
				})


    	    this.setState({addLocation: 'tips', loading: false, stateError: null, showTooltip: false})
    	    setSubmitting(false)

    	    if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_loc_details');

				await analytics.logEvent('add_loc_process', {
				  page: 'details', 
				  action: 'complete'
				});	

				
			}

    	
		} catch(error) {
			console.log('error with update', error)
			setSubmitting(false)

		}

    }


    renderAddLocation(){
    	const { handleClose, profUserId  }= this.props
		const { value, stateError, showTooltip } = this.state
    		return (<Mutation
		      mutation={CREATE_LOCATION}
		      refetchQueries={[{query: GET_USER_AND_LOCATIONS, variables: {id: profUserId}}]}
		    >
		      {(createLocation, { data, loading, error }) => {
		      return 	(
		      	<div className="addLocation popup popupLarge">
					<Autocomplete
						error={error} 
						id="add-location"
						value={value} 
						handleClose={handleClose}
						handleChange={this.handleChange}
						handleSubmit={this.handleCreateLocation}
						handleToggleTooltip={this.handleToggleTooltip}
						mutationFunction={createLocation}
						placeholder="Add a city"
						popup={true}
						stateError={stateError}
						showTooltip={showTooltip}
						name="New Location"
						type="location"
						view="addLocation"
						cancelText="Cancel"
	         		 />
         		</div>
		       
		      )}}
		    </Mutation>)
    }
 
    renderLocationDetails(){
    	const { profUserId } = this.props
    	const { locationId, showTooltip, tooltipType } = this.state
		const locationInitialValues = {overallReview: ''}
		const locDetailsSchema = Yup.object().shape({
		  	overallReview: Yup.string()
		  	.max(2000, 'Overall Review section is too long. Maximum of 2000 characters.')

		})
    	return (<Mutation
		      mutation={UPDATE_LOCATION}
		      refetchQueries={[{query: GET_USER_AND_LOCATIONS, variables: {id: profUserId}}]}
		    >
		      {(updateLocation, { data, loading, error }) => {
		      	return <Mutation
				    mutation={CREATE_LOCATION_DATE}
				    refetchQueries={[{query: GET_LOCATION_VIEW_AND_DATES, variables: {locId: locationId}}]}
		  		> 
		  			{(createLocationDate, { data, loading, error }) => {
				      return <div>
							<LocationDetails
							closeText="Later"
							createLocationDate={createLocationDate}
							error={error}
							handleClose={this.handleReviewLater}
							handleToggleTooltip={this.handleToggleTooltip}
							handleSubmit={this.handleUpdateLocation}
							initialValues={locationInitialValues}
							locationId={locationId}
							locDetailsSchema={locDetailsSchema}
							showTooltip={showTooltip}
							submitText="Add"
							tooltipType={tooltipType}
							updateLocation={updateLocation}
							view="add"
			         		 />
		         		 </div>
		         	}}
		        </Mutation>
						
		  
		      }}
		    </Mutation>)
    }

    renderLocationTips(){
    	const { handleClose, profUserId } = this.props
    	const { locationId } = this.state
		
		const initialValues = { 
			cultural: '', 
			transportation: '', 
			packing: '', 
			safety: '',
			other: ''
		}

		const locTipsSchema = Yup.object().shape({
		   cultural: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   transportation: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   packing: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   safety: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),
		   other: Yup.string()
		  	.max(200, 'Tip is too long. Maximum of 200 characters.'),

		})

    	return (<Mutation
		      mutation={CREATE_LOCATION_TIPS}
		    >
		      {(createLocationTips, { data, loading, error }) => {
		      	return (<div>
					<LocationTipsForm
						closeText="Later"
						createLocationTips={createLocationTips}
						error={error}
						handleClose={this.handleTipsLater}
						handleSubmit={this.handleCreateLocationTips}
						initialValues={initialValues}
						locationId={locationId}
						submitText="Add"
						type="addLocation"
						validationSchema={locTipsSchema}
	         		 />
         		</div>
			
		    )}}
		</Mutation>)
    }
    

	render() {
		const { addLocation } = this.state
		return (
			<div className="addLocationContainer">

			{addLocation === "add" && this.renderAddLocation()}
			{addLocation === "details" && this.renderLocationDetails()}
			{addLocation === "tips" && this.renderLocationTips()}
		  
			</div>
		);
	}
}
export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(AddLocationContainer);

