import React from "react";
import Switch from 'react-toggle-switch'

import "../stylesheets/components/Settings.css"

const Settings = props => {
	const {
		error,
		currentUser, 
		updateUser, 
		handleUpdateUser, 
		profPublic
	} = props
	return (
		<div className="settings">
			<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">settings</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
			<div className="settingsContent">
				<div className="settingsItem">
					<div className="settingsTitle">
						<div className="textTitle">Picture Publicity:</div>
						<div className="fontSmall">If you choose to be on private, only people that follow you will be able to see your pictures. You will get follow requests instead of a user being able to automatically follow you. Tips and reviews will always be public.</div>
					</div>
					<div className="settingsValue">
					
						<div className="formToggle">
							<div className="fontSmall">Private (Followers Only)</div>
							<Switch className="formToggleSwitch" onClick={e => handleUpdateUser(e, updateUser, !profPublic)} on={profPublic}/>
							<div className="fontSmall">Public (Anyone)</div>
						</div>
			
					</div>
				    {error && <div className="error">There was an error.</div>}
				</div>
			</div>

		</div>
	);
};

export default Settings;
