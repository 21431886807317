import React from "react";


//import "../stylesheets/components/ExploreHeader.css"

const ExploreHeader = props => {
	const { 
		handleToggleExploreView, 
		handleToggleMapView,
		followingOnly,
		exploreView
	} = props
	const selectedToggle = buttonType => buttonType === exploreView && 'toggleSelected' 
		return(<div className="exploreHeader">	
			<div className="toggleButtons">
			    <div className={`toggleButton toggleLeft ${selectedToggle('list')}`} onClick={()=>handleToggleExploreView('list')}>List</div>
				<div className={`toggleButton toggleRight ${selectedToggle('map')}`} onClick={()=>handleToggleExploreView('map')}>Map</div>
			</div>
			{exploreView === 'map' && <div className="checkboxTouching">
			 	<input 
			 		type="checkbox" 
			 		value="followingOnly" 
			 		className="checkboxMedium"
			 		checked={followingOnly}
			 		onChange={(e) => handleToggleMapView(e)}
			 	/>
			 	<label for="followingOnly">Following Only</label>
			</div>}

		</div>
	);
};

export default ExploreHeader;
