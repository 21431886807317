import React from "react";

const TermsOfService = props => {
		return (
			<div classname="TermsOfService">
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontSize: '32px', fontFamily: 'Times', color: 'black'}}>Terms of Service</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>Trvlpage builds technologies and services that enable people to connect with each other and share travel advice. These Terms govern your use of Trvlpage (Product) except where we expressly state that separate terms (and not these) apply. This Product is provided to you by Trvlpage LLC. We don’t charge you to use Trvlpage. We don’t sell your personal data or share information that directly identifies you (such as your name, email address or other contact information) with advertisers.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Our services</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>Our mission is to give people an easy place to log their trips and plan better trips with advice from their network. To help advance this mission, we provide the Product and services described below to you:&nbsp;</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Times'}}>Provide a personalized experience for you: Your experience on Trvlpage is unlike anyone else's: from search results to explore. We use the data we have - for example, about the connections you make and the choices and settings you select to personalize your experience.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Connect you with people you trust: We help you find and connect with people in Trvlpage. We use the data we have to make suggestions for you and others - for example, which users to follow.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Share your travel journey: There are many ways to express yourself on Trvlpage and to communicate with friends, family, and others about what matters to you - for example, adding locations to your profile and uploading reviews, tips, and pictures.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Combat harmful conduct and protect and support our community: People will only build community on Trvlpage if they feel safe. If we learn of content or conduct that is harmful, we will take appropriate action - for example, offering help, removing content, removing or restricting access to certain features, disabling an account, or contacting law enforcement.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Enable global access to our services: To operate our global service, we need to store and distribute content and data in our data centers and systems around the world, including outside your country of residence. This infrastructure may be operated or controlled by Trvlpage LLC.</span></li>
        </ul>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Your commitments to Trvlpage and our community</span></strong></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We provide these services to you and others to help advance our mission. In exchange, we need you to make the following commitments:&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'decimal'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Who can use Trvlpage.</span></strong></li>
          </ol>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;When people stand behind their opinions and actions, our community is safer and more accountable. For this reason, you must:</span></p>
        <ol style={{listStyleType: 'lower-alpha'}}>
          <li><span style={{fontFamily: 'Times'}}>&nbsp;Use the same name that you use in everyday life.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>&nbsp;Provide accurate information about yourself.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>Create only one account (your own) and use your timeline for personal purposes.</span></li>
          <li><span style={{fontFamily: 'Times'}}>Not share your password, give access to your Trvlpage account to others, or transfer your account to anyone else (without our permission).&nbsp;</span></li>
        </ol>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textIndent: '.25in'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;We try to make Trvlpage broadly available to everyone, but you cannot use Trvlpage if:&nbsp;</span></p>
        <ol style={{listStyleType: 'lower-alpha'}}>
          <li><span style={{fontFamily: 'Times'}}>You are under 13 years old.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>You are a convicted sex offender.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Times'}}>We've previously disabled your account for violations of our Terms or Policies.</span></li>
          <li><span style={{fontFamily: 'Times'}}>You are prohibited from receiving our product, services, or software under applicable laws.&nbsp;</span></li>
        </ol>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'undefined'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>What you can share and do on Trvlpage.</span></strong></li>
          </ol>
        </div>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginLeft: '.25in'}}><span style={{fontFamily: 'Times', color: 'black'}}>We want people to use Trvlpage to share their travel journey, but not at the expense of the safety and well-being of others or the integrity of our community. You therefore agree not to engage in the conduct described below (or to facilitate or support others in doing so):&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginLeft: '.25in'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <ol style={{listStyleType: 'decimal', marginLeft: '0.25in'}}>
          <li><span style={{fontFamily: 'Times'}}>You may not use our Product to do or share anything:</span></li>
        </ol>
        <ul style={{listStyleType: 'disc', marginLeft: '82.85px'}}>
          <li><span style={{fontFamily: 'Times'}}>That is unlawful, misleading, discriminatory or fraudulent,</span></li>
          <li><span style={{fontFamily: 'Times'}}>That infringes or violates someone else's rights, including their intellectual property rights.&nbsp;</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>2. You may not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our Product.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>3. You may not access or collect data from our Product using automated means (without our prior permission) or attempt to access data you do not have permission to access.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We can remove or restrict access to content that is in violation of these provisions. If we remove content that you have shared, we’ll let you know and explain any options you have to request another review, unless you seriously or repeatedly violate these Terms or if doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Product; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'undefined'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>The permissions you give us&nbsp;</span></strong></li>
          </ol>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We need certain permissions from you to provide our services:&nbsp;</span></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'decimal', marginLeft: '0.25in'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: 'Times'}}>Permission to use content you create and share:</span></u><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;Some content that you share or upload, such as photos or videos, may be protected by intellectual property laws.&nbsp;</span></li>
          </ol>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.75in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>You own the intellectual property rights (things like copyright or trademarks) in any such content that you create and share on Trvlpage. Nothing in these Terms takes away the rights you have to your own content. You are free to share your content with anyone else, wherever you want.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.75in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>However, to provide our services we need you to give us some legal permissions (known as a ‘license’) to use this content. This is solely for the purposes of providing and improving our Product in Section 1 above. Specifically, when you share, post, or upload content that is covered by intellectual property rights on or in connection with our Product, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). This means, for example, that if you share a photo on Trvlpage, you give us permission to store, copy, and share it with others (consistent with your settings).&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.75in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>You can delete content individually or all at once by deleting your account. To delete your account, email contact@trvlpage.com. When you delete content, it’s no longer visible to other users, however it may continue to exist elsewhere on our systems.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.75in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: 'Times', color: 'black'}}>Permission to update software you use or download</span></u><strong><span style={{fontFamily: 'Times', color: 'black'}}>:</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginLeft: '.5in'}}><span style={{fontFamily: 'Times', color: 'black'}}>If you download or use our software, you give us permission to download and install updates to the software where available.&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginLeft: '.5in'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'undefined'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times'}}>Limits on using our intellectual property</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></li>
          </ol>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>If you use content covered by intellectual property rights that we have and make available in our Product (for example, images, designs, videos, or sounds we provide that you add to content you create or share on Trvlpage), we retain all rights to that content (but not yours). You must obtain our written permission (or permission under an open source license) to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>Additional Provisions</span></strong></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'decimal', marginLeft: '0.25in'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times'}}>Updating our Terms</span></strong><span style={{fontFamily: 'Times'}}>&nbsp;</span></li>
          </ol>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.75in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We work constantly to improve our services and develop new features to make our Product better for you and our community. As a result, we may need to update these Terms from time to time to accurately reflect our services and practices. Unless otherwise required by law, we will notify you before we make changes to these Terms and give you an opportunity to review them before they go into effect. Once any updated Terms are in effect, you will be bound by them if you continue to use our Product. We hope that you will continue using our Product, but if you do not agree to our updated Terms and no longer want to be a part of the Trvlpage community and you should email contact@trvlpage.com to delete your account.&nbsp;</span></p>
        <div style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0in', listStyleType: 'undefined', marginLeft: '0.25in'}}>
            <li style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times'}}>Account suspension or termination</span></strong><span style={{fontFamily: 'Times'}}>&nbsp;</span></li>
          </ol>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.75in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We want Trvlpage to be a place where people feel welcome and safe to express themselves and share their thoughts and ideas. If we determine that you have clearly, seriously or repeatedly breached our Terms or Policies, we may suspend or permanently disable access to your account. We may also suspend or disable your account if you repeatedly infringe other people’s intellectual property rights or where we are required to do so for legal reasons. Where we take such action we’ll let you know and explain any options you have to request a review, unless doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Product; or where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons. If you delete or we disable your account, these Terms shall terminate as an agreement between you and us, but the following provisions will remain in place: 3, 5.2-5.5.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>3. Limits on liability</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;We work hard to provide the best Product we can and to specify clear guidelines for everyone who uses them. Our Product, however, are provided "as is," and we make no guarantees that they always will be safe, secure, or error-free, or that they will function without disruptions, delays, or imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. We do not control or direct what people and others do or say, and we are not responsible for their actions or conduct (whether online or offline) or any content they share (including offensive, inappropriate, obscene, unlawful, and other objectionable content). We cannot predict when issues might arise with our Product. Accordingly, our liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the Trvlpage Product, even if we have been advised of the possibility of such damages. Our aggregate liability arising out of or relating to these Terms or the Trvlpage Product will not exceed the greater of $1.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>4. Disputes</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>We try to provide clear rules so that we can limit or hopefully avoid disputes between you and us. If a dispute does arise, however, it's useful to know up front where it can be resolved and what laws will apply. For any claim, cause of action, or dispute you have against us that arises out of or relates to these Terms or the Trvlpage Product("claim"), you agree that it will be resolved exclusively in a state court located in Cook County. You also agree to submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim, and that the laws of the State of Illinois will govern these Terms and any claim, without regard to conflict of law provisions.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.5in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: 'Times', color: 'black'}}>5. Other</span></strong><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>1. These Terms make up the entire agreement between you and Trvlpage LLC regarding your use of our Product. They supersede any prior agreements.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;2. If any portion of these Terms is found to be unenforceable, the remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it will not be considered a waiver. Any amendment to or waiver of these Terms must be made in writing and signed by us.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>3. You will not transfer any of your rights or obligations under these Terms to anyone else without our consent.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>4. You may designate a person (called a legacy contact) to manage your account if it is memorialized. Only your legacy contact or a person who you have identified in a valid will or similar document expressing clear consent to disclose your content upon death or incapacity will be able to seek disclosure from your account after it is memorialized.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>5. These Terms do not confer any third-party beneficiary rights. All of our rights and obligations under these Terms are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>6. You should know that we may need to change the username for your account in certain circumstances (for example, if someone else claims the username and it appears unrelated to the name you use in everyday life).&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>7. We always appreciate your feedback and other suggestions about our product and services. But you should know that we may use them without any restriction or obligation to compensate you, and we are under no obligation to keep them confidential.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '1.0in', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>8. We reserve all rights not expressly granted to you.</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>&nbsp;</span></p>
        <p style={{margin: '0in', marginBottom: '.0001pt', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Times', color: 'black'}}>Date of Last Revision: July 1, 2020</span></p>
      </div>
		);
};

export default TermsOfService;
