import React from "react";
import { compose, Mutation, Query, graphql } from "react-apollo";

import { getCurrentUser } from "../apollo/state/queries";
import {
	GET_ATTRACTION_PICTURES, 
	GET_FOLLOWING_ALPHABETIZED,
	GET_LOCATION_ATTRACTIONS, 
	GET_LOCATION_PICTURES, 
	GET_NOTIFICATIONS_BY_DATE, 
	GET_OVERALL_ATTRACTION_PICTURES } from "../apollo/db/queries"
import { 
	ADD_LOCATION_PICTURE_ATTRACTION, 
	CREATE_PICTURE_TAG,
	DELETE_LOCATION_PICTURE, 
	DELETE_PICTURE_TAG,
	MAKE_MAIN_PICTURE,
	UNTAG_SELF_PICTURE,
	REMOVE_LOCATION_PICTURE_ATTRACTION 
} from "../apollo/db/mutations"

import CreatePictureTag from "../components/CreatePictureTag"
import Deletion from "../components/Deletion"
import PictureAttractionSelection from "../components/PictureAttractionSelection"
import PictureView from "../components/PictureView";

class PictureViewContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allowTags: false,
			currentPicIndex: 0,
			deletionId: null,
			deletionIndex: null,
			deleteTagId: null,
			showDelete: false, 
			showSelectAttraction: false, 
			showTags: false,
			showTooltip: false,
			tagXOffset: null, 
			tagXPercent: null, 
			tagYPercent: null, 
			userValue: ''

		}
 
		this.handleAddLocPicAttraction= this.handleAddLocPicAttraction.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleChangePic = this.handleChangePic.bind(this)
		this.handleCreatePictureTag = this.handleCreatePictureTag.bind(this)
		this.handleDeletePic = this.handleDeletePic.bind(this)
		this.handleDeleteTag = this.handleDeleteTag.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideDeleteTag = this.handleHideDeleteTag.bind(this)
		this.handleMakeMainPicture = this.handleMakeMainPicture.bind(this)
		this.handleSelectTagLocation = this.handleSelectTagLocation.bind(this)
		this.handleToggleAllowTags = this.handleToggleAllowTags.bind(this)
		this.handleToggleShowTags = this.handleToggleShowTags.bind(this)
		this.handleUntagSelf = this.handleUntagSelf.bind(this)
		this.handleRemoveLocPictureAttraction = this.handleRemoveLocPictureAttraction.bind(this)
		this.handleSelectDeleteTag = this.handleSelectDeleteTag.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowSelectAttraction = this.handleShowSelectAttraction.bind(this)
		this.renderSelectAttraction = this.renderSelectAttraction.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.renderTagPicture = this.renderTagPicture.bind(this)

	}

	componentDidMount(){
		const { pictures,  initialPicIndex } = this.props
		console.log('initialPicIndex', initialPicIndex)
		this.setState({pictures: pictures, currentPicIndex: initialPicIndex})

	}

	handleChangePic(direction){
		const { currentPicIndex } = this.state
		if (direction === "next"){
			this.setState({currentPicIndex: currentPicIndex + 1, showSelectAttraction: false})
		} else {
			this.setState({currentPicIndex: currentPicIndex - 1, showSelectAttraction: false})
		}
	}

	handleToggleTooltip(){
		const { showTooltip } = this.state
		this.setState({showTooltip: !showTooltip})
	}

	handleChange(event){
		this.setState({userValue: event.target.value})
	}

	handleToggleAllowTags(){
		const { allowTags, showTags } = this.state
		this.setState({allowTags: !allowTags, showTags: !showTags, tagXPercent: null, tagYPercent: null, tagXOffset: null})
	}

	handleToggleShowTags(){
		const { showTags } = this.state
		this.setState({showTags: !showTags})
	}

	handleSelectDeleteTag(tagId){
		this.setState({deleteTagId: tagId})
	}

	handleHideDeleteTag(){
		this.setState({deleteTagId: null})
	}



	handleSelectTagLocation(event){
		  let currentTargetRect = event.currentTarget.getBoundingClientRect();
		  console.log('event', event, currentTargetRect)
		  const offsetX = event.clientX - currentTargetRect.left 
		  const offsetY = event.clientY - currentTargetRect.top;

		  const xPercent = (offsetX/currentTargetRect.width) * 100
		  const yPercent = (offsetY/currentTargetRect.height) * 100

		  //pictureTagwidth is 150px to calculate percentage moved to center.
		 let tagXOffset = (150/currentTargetRect.width)/2 * 100

 
		  this.setState({tagXPercent: xPercent, tagYPercent: yPercent, tagXOffset: tagXOffset})
	  
	}

	async handleCreatePictureTag(userId, createPictureTag, userPushToken){
		const { currentPicIndex, tagXOffset, tagXPercent, tagYPercent } = this.state
		const { pictures } = this.props

		const pictureId = pictures[currentPicIndex].id

		try {
			await createPictureTag({
				variables: {
					input: {
						pictureTag: {
							userId: userId,
							pictureId: pictureId,
							leftOffset: tagXOffset,
							leftPercent: tagXPercent,
							topPercent: tagYPercent
						}
						
					}
				}
			})

			if (userPushToken){
		 		await this.sendPushNotification(userPushToken)
		 	}

			this.setState({ loading: false, tagXOffset: null, tagXPercent: null, tagYPercent: null })
		} catch {
			console.log('error')
		}
	}

	async sendPushNotification(token){
	    const message = {
	      to: token,
	      sound: 'default',
	      body: 'Someone tagged you in a picture',
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	       'mode': 'no-cors',
	        'method': 'POST',
	        'headers': {
	            'Accept': 'application/json',
	            'Content-Type': 'application/json',
	        },
	      body: JSON.stringify(message),
	    });
  	};

	async handleDeletePic(deleteLocationPictureCustom){
		const { deletionId, deletionIndex } = this.state
		const { handleClose, pictures } = this.props
		try {
				await deleteLocationPictureCustom({
					variables: {
						input: {
							locPictureId: deletionId
							}
						}
					})

				await this.setState({ 
					loading: false, 
					showDelete: false, 
					deletionType: null 
				})

				if (deletionIndex === pictures.length - 1){
					handleClose()
				}
			} catch {
				console.log('error')
			}
	}

	async handleDeleteTag(deletePictureTag){
		const { deletionId } = this.state
		const { handleClose, view } = this.props
		try {
				await deletePictureTag({
					variables: {
						input: {
							id: deletionId
							}
						}
					})

				this.setState({ loading: false, showDelete: false, deletionType: null })
			} catch {
				console.log('error')
			}
	}

	async handleUntagSelf(untagSelfPicture, e){
		const { deletionId } = this.state
		const { handleClose, view } = this.props
		console.log('deletionId', deletionId)
		try {
				await untagSelfPicture({
					variables: {
						input: {
							picTagId: deletionId
							}
						}
					})

				this.setState({ loading: false, showDelete: false, deletionType: null })
				if (view === 'attraction' || view === 'location'){
					handleClose()
				} else {
					handleClose(e)
				}
			} catch {
				console.log('error')
			}
	}

	async handleMakeMainPicture(locPicId, locId, makeMainPicture){
		try {
			await makeMainPicture({
				variables: {
					input: {
						locPicId: locPicId, 
						locId: locId
						}
					}
				})

		} catch {
			console.log('error')
		}
	}


	async handleRemoveLocPictureAttraction(removeLocationPictureAttraction){
		const { deletionId } = this.state
		const { handleClose, view } = this.props
		try {
				await removeLocationPictureAttraction({
					variables: {
						input: {
							locPictureId: deletionId
							}
						}
					})

				await this.setState({ loading: false, showDelete: false, deletionType: null})
				
				if (view === 'attraction'){
					handleClose()
				}

			} catch {
				console.log('error')
			}
	}

	async handleAddLocPicAttraction(attraction, addLocationPictureAttraction){
		const { currentPicIndex } = this.state
		const { pictures } = this.props
		try {
				await addLocationPictureAttraction({
					variables: {
						input: {
							locPictureId: pictures[currentPicIndex].id, 
							attrId: attraction.id
							}
						}
					})

				this.setState({ loading: false, showSelectAttraction: false})
			} catch {
				console.log('error')
			}
	}


	handleHideDelete(){
		this.setState({showDelete: false})
	}

	handleShowDelete(id, type, picIndex){
		this.setState({showDelete: true, deletionId: id, deletionType: type, deletionIndex: picIndex})

	}

	handleShowSelectAttraction(){
		this.setState({showSelectAttraction: true})
	}

 
	renderSelectAttraction(){
		const { currentUser, locationId } = this.props 
		return <Query query={GET_LOCATION_ATTRACTIONS} variables={{locId: locationId, currentUserId: currentUser.id}}>
  			{({ loading, error, data }) => {
	  			if (loading) {return <div> Loading... </div>}
	  			if (error) {return <div>Error</div>}

	  			const locAttractions = data.locationAttractions.nodes
			  	return <Mutation
					mutation={ADD_LOCATION_PICTURE_ATTRACTION}
					 refetchQueries={(result) => {  	
				      	const pic = result.data.addLocationPictureAttraction.attrPictureDatum
				      	return [{query: GET_LOCATION_PICTURES, variables: {locId: locationId}}, 
				      	{query: GET_ATTRACTION_PICTURES, variables: {attrId: pic.attractionId}}, 
				      	{query: GET_OVERALL_ATTRACTION_PICTURES, variables: {attrPlaceId: pic.attractionPlaceId}}]
				      }}
				>
					{( addLocationPictureAttraction, {loading, error}) => {

			  			return <PictureAttractionSelection 
			  				handleSelectAttraction={this.handleAddLocPicAttraction}
			  				locAttractions={locAttractions}
			  				mutationFunction={addLocationPictureAttraction}
			  			/>
			  		}}
			  	</Mutation>


	  		}}
	  	</Query>
	}

	renderTagPicture(){
		const { currentUser, pictures } = this.props
		const { currentPicIndex, userValue } = this.state 

		const picture = pictures[currentPicIndex]

		return <Mutation
			mutation={CREATE_PICTURE_TAG}
			//there could be more refetch queries here for: all the location_picutres/attraction_pictures of anyone tagged in the pic
			//and overall_location/overall_attraction pictures. However, at this time I decided the benefit is not worth the effort atm
			//because the only person who can create tags is the uploading user therefore, the benefit would be for them to see their pic in other places
			refetchQueries={() => {
				if (picture.attractionId){
					return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}, 
		     		 {query: GET_ATTRACTION_PICTURES, variables: {attrId: picture.attractionId}}]
				} else {
					return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}]
				}
			}}
		>
			{( createPictureTag, {loading, error}) => {
				return <Query query={GET_FOLLOWING_ALPHABETIZED} variables={{profUserId: currentUser.id}}>
		  			{({ loading, error, data }) => {
			  			if (loading) {return <div> Loading... </div>}
			  			if (error) {return <div>Error</div>}

			  			const following = data.followingAlphabetized.nodes
						return <div>
							<CreatePictureTag 
								createPictureTag={createPictureTag}
								currentPicIndex={currentPicIndex}
								following={following}
								handleChange={this.handleChange}
								handleSelect={this.handleCreatePictureTag}
								pictures={pictures}
								userValue={userValue}
							/>	
						</div>
					}}
				</Query>
			}}
		</Mutation>
	}

	renderCurrentPicture(){
		const { currentUser, handleClose, pictures } = this.props
		const { 
			allowTags, 
			currentPicIndex, 
			deleteTagId, 
			showSelectAttraction, 
			showTags, 
			showTooltip,
			tagXOffset,
			tagXPercent, 
			tagYPercent 
		} = this.state
		
		const picture = pictures[currentPicIndex]
		return <Mutation
			mutation={MAKE_MAIN_PICTURE}
			//there could be more refetch queries here for: all the location_picutres/attraction_pictures of anyone tagged in the pic
			//and overall_location/overall_attraction pictures. However, at this time I decided the benefit is not worth the effort atm
			//because the only person who can create tags is the uploading user therefore, the benefit would be for them to see their pic in other places
			refetchQueries={() => {
				if (picture.attractionId){
					return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}, 
		     		 {query: GET_ATTRACTION_PICTURES, variables: {attrId: picture.attractionId}}]
				} else {
					return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}]
				}
			}}
		>
			{( makeMainPicture, {loading, error}) => {
				return <div>
					{!!pictures.length && <PictureView 
						allowTags={allowTags}
						currentUser={currentUser}
						currentPicIndex={currentPicIndex}
						deleteTagId={deleteTagId}
						error={error}
						handleChangePic={this.handleChangePic}
						handleClose={handleClose} 
						handleHideDeleteTag={this.handleHideDeleteTag}
						handleMakeMainPicture={this.handleMakeMainPicture}
						handleShowDelete={this.handleShowDelete}
						handleShowSelectAttraction={this.handleShowSelectAttraction}
						handleSelectDeleteTag={this.handleSelectDeleteTag}
						handleSelectTagLocation={this.handleSelectTagLocation}
						handleToggleAllowTags={this.handleToggleAllowTags}
						handleToggleShowTags={this.handleToggleShowTags}
						handleToggleTooltip={this.handleToggleTooltip}
						makeMainPicture={makeMainPicture}
						picture={pictures[currentPicIndex]}
						picturesLength = {pictures.length} 
						renderSelectAttraction={this.renderSelectAttraction}
						renderTagPicture={this.renderTagPicture}
						showSelectAttraction={showSelectAttraction}
						showTags={showTags}
						showTooltip={showTooltip}
						tags={pictures[currentPicIndex].tags}
						tagXOffset={tagXOffset}
						tagXPercent={tagXPercent}
						tagYPercent={tagYPercent}
					/>}
				</div>
			}}
		</Mutation>

		
	}

	renderDeleteTag(){
		const { currentPicIndex, deletionId } = this.state
		const { pictures } = this.props

		const picture = pictures[currentPicIndex]
		console.log('picture', picture)

		return (<Mutation
		mutation={DELETE_PICTURE_TAG}
		//there could be more refetch queries here for: all the location_picutres/attraction_pictures of anyone tagged in the pic
		//and overall_location/overall_attraction pictures. However, at this time I decided the benefit is not worth the effort atm
		//because the only person who can create tags is the uploading user therefore, the benefit would be for them to see their pic in other places
		refetchQueries={() => {
			if (picture.attractionId){
				return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}, 
	     		 {query: GET_ATTRACTION_PICTURES, variables: {attrId: picture.attractionId}}]
			} else {
				return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}]
			}
		}}
			
		
		>
		{( deletePictureTag, {loading, error}) => {
			return <Deletion  
		    	id={deletionId} 
		    	error={error}
		    	handleHideDelete={this.handleHideDelete}
		    	handleDelete={this.handleDeleteTag}
		    	mutationFunction={deletePictureTag}
		    	type='tag'
		    />
		}}
		</Mutation>)
	}

	renderUntag(){
		const { currentPicIndex, deletionId } = this.state
		const { pictures } = this.props

		const picture = pictures[currentPicIndex]

		return (<Mutation
		mutation={UNTAG_SELF_PICTURE}
		//there could be more refetch queries here for: all the location_picutres/attraction_pictures of anyone tagged in the pic
		//and overall_location/overall_attraction pictures. However, at this time I decided the benefit is not worth the effort atm
		//because the only person who can create tags is the uploading user therefore, the benefit would be for them to see their pic in other places
		refetchQueries={() => {
			if (picture.attractionId){
				return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}, 
	     		 {query: GET_ATTRACTION_PICTURES, variables: {attrId: picture.attractionId}}, 
	     		 {query: GET_NOTIFICATIONS_BY_DATE}]
			} else {
				return [{query: GET_LOCATION_PICTURES, variables: {locId: picture.locationId}}, 
	     		{query: GET_NOTIFICATIONS_BY_DATE}]
			}
		}}
			
		
		>
		{( untagSelfPicture, {loading, error}) => {
			return <Deletion  
			    error={error}
		    	id={deletionId} 
		    	handleHideDelete={this.handleHideDelete}
		    	handleDelete={this.handleUntagSelf}
		    	mutationFunction={untagSelfPicture}
		    	type="tag"
		    	/>
		}}
		</Mutation>)
	}

	renderRemoveAttraction(){
		const { deletionId } = this.state
		const { locationId } = this.props

		return (<Mutation
		mutation={REMOVE_LOCATION_PICTURE_ATTRACTION}
		 refetchQueries={(result) => {  	
	      	const pic = result.data.removeLocationPictureAttraction.attrPictureDatum
	      	return [{query: GET_LOCATION_PICTURES, variables: {locId: locationId}}, 
	      	{query: GET_ATTRACTION_PICTURES, variables: {attrId: pic.attractionId}}, 
	      	{query: GET_OVERALL_ATTRACTION_PICTURES, variables: {attrPlaceId: pic.attractionPlaceId}}]
	      }}
		>
		{( removeLocationPictureAttraction, {loading, error}) => {
			return <Deletion 
			    error={error} 
		    	id={deletionId} 
		    	handleHideDelete={this.handleHideDelete}
		    	handleDelete={this.handleRemoveLocPictureAttraction}
		    	mutationFunction={removeLocationPictureAttraction}
		    	type="attraction tag"
		    />
		}}
		</Mutation>)
	}

	renderDeletePicture(){
		const { deletionId } = this.state
		const { locationId } = this.props
		return (<Mutation
		mutation={DELETE_LOCATION_PICTURE}
		 refetchQueries={(result) => {  	
	      	const pic = result.data.deleteLocationPictureCustom.attrPictureDatum
	      	return [{query: GET_LOCATION_PICTURES, variables: {locId: locationId}}, 
	      	{query: GET_ATTRACTION_PICTURES, variables: {attrId: pic.attractionId}}, 
	      	{query: GET_OVERALL_ATTRACTION_PICTURES, variables: {attrPlaceId: pic.attractionPlaceId}}]
	      }}
		>
		{( deleteLocationPictureCustom, {loading, error}) => {
			return <Deletion 
			    error={error} 
		    	id={deletionId} 
		    	handleHideDelete={this.handleHideDelete}
		    	handleDelete={this.handleDeletePic}
		    	mutationFunction={deleteLocationPictureCustom}
		    	type="picture"
		    	/>
		}}
		</Mutation>)

	}

	render() {
		const { deletionType, showDelete } = this.state
		return (
			<div className="pictureViewContainer">
				{this.renderCurrentPicture()}
				{(showDelete && deletionType === "picture") && this.renderDeletePicture()}
				{(showDelete && deletionType === "attraction") && this.renderRemoveAttraction()}
				{(showDelete && deletionType === "tag") && this.renderDeleteTag()}
				{(showDelete && deletionType === "untag") && this.renderUntag()}
			</div>
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(PictureViewContainer);
