import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/Form.css"
import { Button } from 'reactstrap';


const ItineraryItemLocation = props => {
	const {
		handleClose,
		handleSubmit,
		initialValues, 
		stateError,
		tripLocations
	} = props;

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}



	return (
		<div className="itineraryItemLocation popup popupMedium">
			<div className="popupContent">
				<div className="secondaryHeader">Choose a Location</div>

				<Formik
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(
							values				
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="form">
				          	<div className="formItem">
					          <label className="textTitle" htmlFor="tripLocation">Item Location</label>
					            <Field 
					            	className="dropdownSelect" 
					            	component="select" 
					            	name="tripLocation"
					            	onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        >
					            	<option value='choose'>Choose One</option>
					            	{tripLocations.map(tripLoc => {
					            		return  <option key={tripLoc.id} value={tripLoc.id}>{(getLocName(tripLoc))}</option>
					            	})}
						          </Field>
					        </div>
			          		<ErrorMessage name="notes" component="div" />
				      
					
					
							{stateError && <div className="error">{stateError}</div>}
							<div className="formButtons">
								{<Button className="secondaryButton" onClick={handleClose}>Cancel</Button>}
								<Button className="primaryButton" type="submit">
									Submit
								</Button>
							</div>
				
						</Form>}

				</Formik>
				
				
			</div>
	
		</div>
	);
};

export default ItineraryItemLocation;