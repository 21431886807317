import React from "react";
import ReadMoreAndLess from 'react-read-more-less';

import "../stylesheets/components/SearchResult.css"

import AddToTripContainer from "../containers/AddToTripContainer"
import AttractionTags from "./AttractionTags"
import SavedButtonContainer from "../containers/SavedButtonContainer"

const SearchResult = props => {
	const { 
		attrContent,
		currentUser, 
		handleGoToAttraction,
		handleGoToResult,
		handleToggleSavedAttraction,
		handleToggleUnsavedAttraction,
		handleSearch,
		header, 
		link, 
		result,
		resultType,
		savedIds, 
		searchTags, 
		secondary, 
		tertiary, 
		unsavedIds
	} = props;


    const getCharLimit = (text) => {
		const idx = text.split('').findIndex(char => {
			return char === '\n'
		})
		if (idx > 1){
           return idx + 1
		} else if (idx !== -1) {
			return 2
		} else {
			return 60
		}
	}

	const resultClassNames = () => {
		if (resultType === 'location'){
			if (secondary === 'overall view'){
				return 'locationResult searchResult mainBackground secondaryBackgroundColor'
			} else {
				return 'locationResult searchResult mainBackground'
			}
		} else if (resultType === 'attraction'){
			if (secondary === 'overall view'){
				return 'attractionResult searchResult mainBackground secondaryBackgroundColor'
			} else {
				return 'attractionResult searchResult mainBackground'
			}
			
		} else {
			return 'userResult searchResult mainBackground'
		}
	}

	const getStars =  (count, className) => {
			const stars = []
			let filledStars, halfStar;
			if (count % 1 >= 0.75){
				filledStars = Math.round(count);
				halfStar = false;
			} else if (count % 1 < 0.75 && count % 1 >= 0.25){
				filledStars = (Math.floor(count));
				halfStar = true;
			} else {
				filledStars = (Math.floor(count));
				halfStar = false;
			}
			while(stars.length < filledStars){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}  
			if (halfStar){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star_half</i>)
			}
			while (stars.length < 5){
					stars.push(<i className={`material-icons nonClickableInactiveIcon ${className}`}>star</i>)
			}
			 
		
			return stars
	}

	const getIcon = attractionType => {
			if (attractionType === "ACTIVITY"){
				return <i className={'material-icons nonClickableIcon iconMedium'}>local_activity</i>
			} else if (attractionType === 'RESTAURANT'){
				return <div className="restaurantIcons">
					<i className={'material-icons nonClickableIcon iconMedium'}>local_bar</i>
					<i className={'material-icons nonClickableIcon iconMedium'}>restaurant</i>
				</div>
			} else if (attractionType === "LODGING") {
				return <i className={'material-icons nonClickableIcon iconMedium'}>hotel</i>
			}
	}

	const findPinNumber = () => {
		const { pinIndexRef, result } = props
		const pinNumber = pinIndexRef.findIndex(searchResult => {
			return searchResult.placeId === result.placeId
		})
		return pinNumber + 1
	}

	return (
		<div className={resultClassNames()}>
			<div className="searchResultContent">
				<div className='searchResultLeft'>
					<div className="searchResultLeftTop">
						{(result.locationId || resultType === 'user') && 
							<div>{result.mainPictureUrl ? 
								<img className="picMedium" src={result.mainPictureUrl} alt="locationPicture"/>
								: <img className="picMedium" src={result.publicUrl} alt="profilePicture"/>
								}
							</div>}
				
						<div>
							<div className={secondary === 'overall view' && 'searchOverallLeft'}>
								<div className="headerAndIcons">
									{(resultType === 'attraction' || resultType === 'location') ? 
									<div className="textLink secondarySubHeader" onClick={()=> handleGoToResult(link, resultType, result)}> {header}</div>
									: <div className="textLink secondarySubHeader" onClick={()=> handleGoToResult(link, resultType, result)}> {header}</div>}
									{result.attractionId && 
										<div className='icons'>{getIcon(result.attractionType)}</div>}
								</div>
								<div className="searchResultHeaderMiddle">
									
									<div className="tertiarySubHeader secondTextColor">{secondary}</div>
									{result.attractionId && result.stars && <div className="stars">
										{getStars(result.stars, 'star')}
									</div>}
									
								</div>
								{tertiary && <div className="fontSmall">{tertiary}</div>}
							</div>
						</div>
					</div>

						{result.attractionId  && <div className="attractionReviewAndTips">
							{(attrContent.includes('reviews') || attrContent.length === 0) &&
								<div className="attractionInfo fontSmall">
									<div className="textTitle">Review: </div>
									{result.review ? <div className="userInputText"><ReadMoreAndLess
						                className="textLink"
						                charLimit={getCharLimit(result.review)}
						                readMoreText="Read more"
						                readLessText="Read less"
						            >
						                {result.review}
						            </ReadMoreAndLess></div> : <div>N/A</div>}
					           	</div>}
							{(attrContent.includes('tips') || attrContent.length === 0) &&
								<div className="attractionInfo fontSmall">
					           		<div className="textTitle">Tips: </div>
					           		{result.tips ? <div className="userInputText"><ReadMoreAndLess
						                className="textLink"
						                charLimit={getCharLimit(result.tips)}
						                readMoreText="Read more"
						                readLessText="Read less"
						            >
						                {result.tips}
						            </ReadMoreAndLess></div> : <div>N/A</div>}
					        	</div>}
						</div>}
				
				</div>

				{resultType === 'attraction' && !result.attractionId && <div className="searchResultRight">
					<div className="starsHolder">
						<div>Average Rating</div>
						{result.stars && <div className="stars">
							{getStars(result.stars, 'star')}
						</div>}
					</div>
				</div>}

				{resultType === 'location' &&
				 <div className="searchResultRight">
					 <div className="starsHolder">
						 	<div>Average Attraction Rating</div>
						 {result.averageStars ? <div className="stars">
							{getStars(result.averageStars, 'star')}
						
						</div> : <div>
							<div>N/A</div>
						</div>}
				 	</div>
				 </div>}

		</div>
			
				{resultType === 'attraction' && secondary !== 'overall view' &&
				 <div className="searchResultBottom">
				 		 <AttractionTags 
						 	searchTags={searchTags}
						 	tags ={result.tags}
						 	view="search"
						 />
				 		<div className="icons">
				 			{/*can't use id or else apollo client will render false results but addtotrip and savedbutton require id on attraction*/}
							{/*<AddToTripContainer 
							attraction={Object.assign({}, result, {id: result.attractionId})}
							locationId={result.locationId}
							type="attraction"
							/>*/}
							 <div className="pinAndNumber"><i className={'material-icons iconSmall'}>place</i>
								<div>{findPinNumber()}</div>
							</div>
							{!!currentUser.id && <SavedButtonContainer 
								attraction={Object.assign({}, result, {id: result.attractionId, saveCount: result.saveCount, savedId: result.savedId})}
								locationId={result.locationId}
								attractionId={result.attractionId}
								handleToggleSavedAttraction={handleToggleSavedAttraction}
								handleToggleUnsavedAttraction={handleToggleUnsavedAttraction}
								handleSearch={handleSearch}
								locationUserId={result.userId}
								savedIds={savedIds}
								unsavedIds={unsavedIds}
								view='search'
							/>} 
						</div>
				 
					

				 </div>}

				 
		</div>
	);
};

export default SearchResult;
