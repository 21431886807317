import React from "react";
import PhoneInput from 'react-phone-number-input';
import { Link } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";


import initialPic from '../assets/globe.jpg'


const PhoneNumber = props => {
	const {
		country,
		error, 
		handleClose, 
		handleChangePhoneNumber,
		handleSubmit, 
		mutationFunction, 
		phoneNumber
	} = props

	// const onValueChange = (phoneNumber, setFieldValue, onChange ) => {
 //        setFieldValue('phoneNumber', phoneNumber);

 //        if (onChange !== null) {
 //            onChange(phoneNumber);
 //        }
 //    };

	return (
		<div className="phoneNumber initialScreen">
			<div className="initialScreenLeft">
					<div className="headerHolder initialHeaderHolder">
						<div className="mainHeader initialHeader">phone number</div>
						<div className="headerUnderline initialHeaderUnderline"></div>
					</div>
					<div className="marginBottom formAdditionalText">If you have been invited by a current user or it's your turn from the waitlist, enter your number.</div>
									
					<Formik
			
				      onSubmit={( values, { setSubmitting }) => {
								handleSubmit(setSubmitting)
							}}
							onKeyDown={(e) => {
				                if (e.key === 'Enter') {
				                  e.preventDefault()
				                }
				            }} 
				    >
				      {({ isSubmitting, setFieldValue, values, onChange }) => (
				        <Form 
				        	className="form"
				        >

								<PhoneInput
							        placeholder="Enter phone number"
							        name="phoneNumber"
							        value={phoneNumber}
							        onChange={(value) => handleChangePhoneNumber(value)}
							       defaultCountry="US"
							      />
					       	<div className="formButtons">
										<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>Send</button>
									</div>
							<Link className="textLink initialItem" to={`/`}>Back To Welcome Screen</Link>

							<div className="checkboxSection checkboxTouching">
							 	{/*<input 
							 		type="checkbox" 
							 		value="reviews" 
							 		className="checkbox"
							 		checked={values.agreeTerms}
							 		onClick={() => setFieldValue('agreeTerms', !values.agreeTerms)}
							 	/>	*/}        
						 			<div>By clicking Sign Up you agree to the 
							 			<a 
											className="inlineLink textLink"
											href={'https://www.trvlpage.com/terms-of-service'}  
											target="_blank"
											rel="noopener noreferrer">Terms of Service</a>
										and 
										<a 
											className="inlineLink textLink"
											href={'https://www.trvlpage.com/privacy-policy'}  
											target="_blank"
											rel="noopener noreferrer">Privacy Policy</a>
									</div> 
							</div>
				           <div className="progressDots">
						         	<div className="progressDot progressDotFilled"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
								  		
					      		</div>
				       	 
				        </Form>
				      )}
				    </Formik>
						
				</div>
			<div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="formPicture"/>
			</div>

		</div>

	);
};

export default PhoneNumber;