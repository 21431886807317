import React from "react";
//import { bookmark } from 'react-icons/md';


const ReportObjectionableButton = props => {
	const { 
		handleShowReport
	} = props

	
	return (
		<div  className="ReportObjectionableButton">
			<div className="textLink fontSmall" onClick={() => handleShowReport()}>Report Content</div>
		</div>
	);
};

export default ReportObjectionableButton;
