import React from "react";
import { compose, graphql, Mutation } from "react-apollo";
import { analytics } from '../index.js';

import Settings from "../components/Settings";

import { getCurrentUser } from "../apollo/state/queries"
import { setCurrentUser } from "../apollo/state/mutations"
import { UPDATE_USER } from "../apollo/db/mutations"

class SettingsContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = { 
			loading: false
		};
		this.handleUpdateUser = this.handleUpdateUser.bind(this)
	}

	async handleUpdateUser(event, updateUser, newProfPublic) {
		const { currentUser, setCurrentUser } = this.props

		console.log('here?', newProfPublic)

		const userPatch = newProfPublic ? {publicity: 'PUBLIC'} : {publicity: 'PRIVATE'}

		const newCurrentUser = Object.assign({}, currentUser, userPatch)

		this.setState({loading: true})
			try {
				await updateUser({
					variables: {
						input: {
							id: currentUser.id,
							userPatch: userPatch
							}	
						}
					})

				await setCurrentUser({
              	  variables: newCurrentUser
	            })
				

				await this.setState({ loading: false, error: null})
				if (process.env.NODE_ENV !== 'development'){
					await analytics.logEvent('change_setting', {
						type: 'publicity', 
						value: newProfPublic ? 'public' : 'private'
					});
				}
			} catch {
				console.log('error')
			}
	} 


	render() {
		const { currentUser } = this.props
		return (
			<div className="settingsContainer">
			{currentUser.id > 0 ?
				<div>
					<Mutation
				      mutation={UPDATE_USER}
				    	>
				      {(updateUser, { loading, error }) => (
					    <div className="settingsHolder">
					        <Settings 
					           error={error}
						       handleUpdateUser={this.handleUpdateUser}
						       updateUser={updateUser}
						       currentUser={currentUser}
						       profPublic={currentUser.publicity === 'PUBLIC' ? true : false}
					        />
			      	 	</div>
			      	)}

			    </Mutation>
			</div> : <div>Please log in to see this page </div>
		}
		</div>);
	}
}

export default compose(
	graphql(setCurrentUser, {name: 'setCurrentUser'}), 
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
	)(SettingsContainer);
