import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/SuggestedFollows.css"

import FollowButtonContainer from "../containers/FollowButtonContainer";

const SuggestedFollow = props => {
	const { suggestedUsers } = props;
	return (
		<div className="suggestedFollows">
			<div className="headerHolder fullHeaderHolder">
				<div className="subHeader">suggested to follow</div>
				<div className="headerUnderline subHeaderUnderline"></div>
			</div>
			<div className="suggestedFollowContent">
				{suggestedUsers.map(user => {
					return <div key={user.id} className='mainBackground suggestedFollowHolder'>
						<div className='suggestedFollow'>
							<img className="picMedium" src={user.publicUrl} alt="profPic" />
							
							<Link className="textLink" to={`/profile/${user.id}`}>
								{(user.firstName + ' ' + user.lastName)}
							</Link>
							<FollowButtonContainer profUserId={user.id} profUser={user}/>
						</div>
					</div>
				})}
			</div>
		</div>
	);
};

export default SuggestedFollow;
