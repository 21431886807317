import React from "react";
import { Query, Mutation, graphql, compose } from "react-apollo";
import { analytics } from '../index.js';

import FollowButton from "../components/FollowButton"

import { getCurrentUser } from "../apollo/state/queries";
import { BLOCK_USER, FOLLOW, DELETE_FOLLOWER } from "../apollo/db/mutations"
import { GET_FOLLOWER_INFO, GET_FOLLOWERS_ALPHABETIZED, GET_FOLLOWERS_BY_DATE, GET_FOLLOWING_ALPHABETIZED, GET_FOLLOWING_INFO, GET_USER_VIEW_BY_ID } from "../apollo/db/queries";

class FollowButtonContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

		this.blockUser = this.handleBlockUser.bind(this)
		this.handleDeleteFollow = this.handleDeleteFollow.bind(this)
		this.handleFollow = this.handleFollow.bind(this)
	}


	async handleFollow(profUserId, follow){
		const { currentUser, profUser } = this.props
		try {
			await follow({
					variables: {
						input: {
							newFolloweeId: profUserId 	
						}
					}
					})

			if (profUser.pushToken && profUser.pushToken !== currentUser.pushToken){
				await this.sendPushNotification(profUser.pushToken)
				
			}
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('follow', {
			 		followeeId: profUserId
				});
			}


		} catch {
			console.log('error')
		}
	}

	async handleDeleteFollow(id, deleteFollower){
		try {
			await deleteFollower({
					variables: {
						input: {
							id: id
							}
						}
					})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('unfollow', {
			 		followeeId: id
				});
			}
		} catch {
			console.log('error')
		}

	}

	async handleBlockUser(profUserId, blockUser){
		try {
			await blockUser({
				variables: {
					input: {
						profUserId: profUserId 	
					}
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('block', {
			 		followeeId: profUserId
				});
			}

		} catch {
			console.log('error')
		}
	}

	async sendPushNotification(token){
		const { currentUser, profUser } = this.props

		const body = profUser.publicity === 'PUBLIC' ? `${currentUser.firstName} ${currentUser.lastName} started to follow you` :  `${currentUser.firstName} ${currentUser.lastName} requested to follow you`

	    const message = {
	      to: token,
	      sound: 'default',
	      body: body,
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	     'mode': 'no-cors',
	        'method': 'POST',
	        'headers': {
	            'Accept': 'application/json',
	            'Content-Type': 'application/json',
	        },
	      body: JSON.stringify(message),
	    });
  	};



	render() {
		const { currentUser, profUserId, type, view } = this.props;
		console.log('profUserId', profUserId)
		return (<div className="followButtonContainer">
			{(!!currentUser.id && currentUser.id !== profUserId) &&	
			<Query query={GET_FOLLOWER_INFO} variables={{profileId: profUserId}} >
				{({ loading, error, data }) => {
					if (loading) return <div>Loading...</div>;
					if (error) return <div>Error</div>;
					const followerInfo = data.followerInfo
					const followingUserId = currentUser.id > 0 ? currentUser.id : 0
					return (<Query query={GET_FOLLOWING_INFO} variables={{profileId: profUserId, currentUserId: followingUserId}} >
						{({ loading, error, data }) => {
							if (loading) return <div>Loading...</div>;
							if (error) return <div>Error</div>;
							const followingInfo = data.followingInfo
							return <Mutation
								mutation={BLOCK_USER}
								refetchQueries={[{query: GET_FOLLOWER_INFO, variables: {profileId: profUserId}},
							      {query: GET_FOLLOWING_INFO, variables: {profileId: profUserId, currentUserId: followingUserId}},
							      {query: GET_FOLLOWERS_BY_DATE}, 
							      {query: GET_FOLLOWERS_ALPHABETIZED, variables: {profUserId: currentUser.id}},
							      {query: GET_FOLLOWING_ALPHABETIZED, variables: {profUserId: currentUser.id}},
							       {query: GET_USER_VIEW_BY_ID, variables: {profUserId: currentUser.id}}, 
							      {query: GET_USER_VIEW_BY_ID, variables: {profUserId: profUserId}}      
							      ]}
								>
								{(blockUser, { loading, error, data }) => {
									return <Mutation
						      		  mutation={DELETE_FOLLOWER}
								      refetchQueries={[{query: GET_FOLLOWER_INFO, variables: {profileId: profUserId}},
									      {query: GET_FOLLOWING_INFO, variables: {profileId: profUserId, currentUserId: followingUserId}},
									      {query: GET_FOLLOWERS_BY_DATE}, 
									      {query: GET_FOLLOWERS_ALPHABETIZED, variables: {profUserId: currentUser.id}},
									      {query: GET_FOLLOWING_ALPHABETIZED, variables: {profUserId: currentUser.id}},
									       {query: GET_USER_VIEW_BY_ID, variables: {profUserId: currentUser.id}}, 
							     			 {query: GET_USER_VIEW_BY_ID, variables: {profUserId: profUserId}}      
									      ]} 
								    >
								    	{(deleteFollower, { loading, error }) => {
									    	return <Mutation
										      mutation={FOLLOW}
										      refetchQueries={[{query: GET_FOLLOWER_INFO, variables: {profileId: profUserId}},
										      {query: GET_FOLLOWING_INFO, variables: {profileId: profUserId, currentUserId: followingUserId}},
										      {query: GET_FOLLOWERS_BY_DATE}, 
										      {query: GET_FOLLOWERS_ALPHABETIZED, variables: {profUserId: currentUser.id}},
										      {query: GET_FOLLOWING_ALPHABETIZED, variables: {profUserId: currentUser.id}},
										       {query: GET_USER_VIEW_BY_ID, variables: {profUserId: currentUser.id}}, 
							    				{query: GET_USER_VIEW_BY_ID, variables: {profUserId: profUserId}}      
										      ]}
										    >
										      	{(follow, { loading, error }) => {


													return <FollowButton 
													    blockUser={blockUser}
														deleteFollower={deleteFollower}
														error={error}
														follow={follow}
														followerInfo={followerInfo}
														followingInfo={followingInfo}
														handleBlockUser={this.handleBlockUser}
														handleDeleteFollow={this.handleDeleteFollow} 
														handleFollow={this.handleFollow}
														followingInfo={followingInfo}
														profUserId={profUserId}
														type={type}
														view={view}
													/>
												}}
											</Mutation>

										}}
									</Mutation>			
									
								}}

							</Mutation>

						}}
					</Query>)
					
					
				}}

			</Query>}
				

				
		</div>
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	})
)(FollowButtonContainer);
