import React from "react";
import { compose } from "react-apollo";

import LocationListView from "../components/LocationListView"


class LocationListViewContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: '',
		}

		this.handleChange = this.handleChange.bind(this)
	}


	handleChange(event){
		this.setState({value: event.target.value})
	}


	renderLocationListView(){
		const { locations, handleHideListView } = this.props
		const { value } = this.state
			return <LocationListView
				handleChange={this.handleChange} 
				handleClose={handleHideListView}
				locations={locations}
				value={value}
			/>
	}
	render() {
		const { view } = this.state
		
		return (
			<div className="locationListViewContainer">
				{this.renderLocationListView()}
			</div>
		);
	}
}

export default compose()(LocationListViewContainer);
