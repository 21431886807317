import React from "react";


const OverallAttractionHeader = (props) => {
	const {
		attraction,
		currentUser,
		handleBack,
		handleToggleOverallView,
		lastLocation, 
		overallView
	} = props

	const selected = view => view === overallView && 'toggleSelected';
	
		// const localityObj = attraction.address_components.find(component => {
		// 	return component.types.includes('locality')
		// })
		// //locality and country should always exist (this is just an extra protective layer in case it doesn't)
		// const locality = localityObj ? localityObj.long_name : null
		// const adminLevel1Obj = attraction.address_components.find(component => {
		// 	return component.types.includes('administrative_area_level_1')
		// })
		// const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
		// const countryObj = attraction.address_components.find(component => {
		// 	return component.types.includes('country')
		// })
		// const country = countryObj ?countryObj.long_name : null

		const locName = attraction.adminLevel1 ? 
		`${attraction.locality}, ${attraction.adminLevel1}, ${attraction.country}` :
		`${attraction.locality},  ${attraction.country}`
	


	return (
		<div className="overallAttractionHeader">
			{/*lastLocation && <div className="backIcon" onClick={() => handleBack()}>Back</div>*/}
			<div className="overallAttractionHeaderContent">
				<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">{attraction.name}</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
				<div className="secondaryHeader">{locName }</div>
				{currentUser.id  ? <div className="toggleButtons">
					<div className={`toggleButton toggleLeft ${selected('following')}`} onClick={() => handleToggleOverallView('following')}>Following View</div>
					<div className={`toggleButton toggleRight ${selected('public')}`} onClick={() => handleToggleOverallView('public')}>Public View</div>
				</div> : <div className="toggleButtons">
					<div className="toggleButton toggleLeft toggleSelected">Public View</div>
					<div>Create an account or login for Follow View</div>
				</div>}
			</div>
		</div>
	);
};

export default OverallAttractionHeader;
