import React from "react";
import { compose, Query, graphql, Mutation, withApollo } from "react-apollo";


import AddToTrip from "../components/AddToTrip"
import AddToTripIcon from "../components/AddToTripIcon"

import { getCurrentUser } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import { 
	GET_LOCATION_VIEW_AND_DATES, 
	GET_MY_TRIPS, 
	GET_TRIP_ATTRACTION_EXISTS, 
	GET_TRIP_GENERAL_ATTRACTIONS, 
	GET_TRIP_GENERAL_LOCATIONS, 
	GET_TRIP_LOCATION_TIP_EXISTS,
	GET_TRIP_LOCATION_TIPS, 
} from "../apollo/db/queries"
import { 
	ADD_TRIP_ATTRACTION, 
	ADD_TRIP_LOCATION_TIP
	 } from "../apollo/db/mutations"


class AddToTripContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			added: false,
			error: null,
			showAddToTrip: false,
			showSelectTripLocation: false, 
			showTooltip: false,
			stateError: null,
			tip: null,
			tooltipType: null,
			tripAttraction: {},
			tripId: null
		}

		this.handleAddTripAttraction = this.handleAddTripAttraction.bind(this)
		this.handleAddTripLocationTip = this.handleAddTripLocationTip.bind(this)
		this.handleHideAddToTrip = this.handleHideAddToTrip.bind(this)
		this.handleSelectTrip = this.handleSelectTrip.bind(this)
		this.handleShowAddToTrip = this.handleShowAddToTrip.bind(this)
		this.handleShowAddTipToTrip = this.handleShowAddTipToTrip.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.handleTripChange = this.handleTripChange.bind(this)
	}
	


	async handleShowAddToTrip(attraction){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAddToTrip: true, tripAttraction: attraction})
	}

	async handleShowAddTipToTrip(tip){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAddToTrip: true, tip: tip})
	}

	async handleHideAddToTrip(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showAddToTrip: false, 
			tripId: null, 
			tripAttraction: null, 
			tip: null, 
			stateError: null
		})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}


	handleTripChange(tripId){
		this.setState({tripId: Number(tripId), stateError: null})
	}

	async handleAddTripAttraction(addTripAttraction, existingTripLoc, parentLocation){
		const { client, setPopup } = this.props
		const { tripAttraction, tripId } = this.state
		let exists;



		try { 
			if ( existingTripLoc ){
				const res =  await client.query({
 	  		   	 	query: GET_TRIP_ATTRACTION_EXISTS,
  	  	    		variables: { attrId: tripAttraction.id, tripLocId: existingTripLoc.id, attrType: tripAttraction.attractionType }, 
  	  	    		fetchPolicy: 'network-only'
  	  	    	})
			 	
			 	exists = res.data.tripAttractionExists
			}

			const addedTripLocId = existingTripLoc ? existingTripLoc.id : null
			

			if (!exists){
				await addTripAttraction({
						variables: {
						input: {	
								addedAttrId: tripAttraction.id,
								addedAttrPlaceId: tripAttraction.placeId,
								addedAttrLat: tripAttraction.lat,
								addedAttrLng: tripAttraction.lng,
								addedAttrName: tripAttraction.name,
								addedAttrType: tripAttraction.attractionType,
								addedTripId: tripId,
								addedTripLocId: addedTripLocId,
								addedLocPlaceId: parentLocation.placeId,
								addedLocLat: parentLocation.lat,
								addedLocLng: parentLocation.lng,
								addedLocFullName: parentLocation.fullName

							}	
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})	
				await this.setState({added: true})
				setTimeout(() => {
					this.setState({
						showAddToTrip: false, 
						added: false, 
						tripId: null, 
						tripAttraction: null, 
						tip: null, 
						stateError: null
					})
				}, 1000)
				
			} else {
				this.setState({stateError: 'This attraction review has already been added to this trip'})
			}
			
	
		} catch {
			console.log("ERROR!!")

		}		
	}

	async handleAddTripLocationTip(addTripLocationTip, existingTripLoc, parentLocation){
		const { client, setPopup } = this.props
		const { tip, tripId } = this.state
		let exists;

		try {

			if ( existingTripLoc ) {
				const res =  await client.query({
 	  		   	 	query: GET_TRIP_LOCATION_TIP_EXISTS,
  	  	    		variables: { tripLocId: existingTripLoc.id, tipId: tip.id }, 
  	  	    		fetchPolicy: "network-only"
  	  	    	})

				exists = res.data.tripLocationTipExists

			}

	

			const addedTripLocId = existingTripLoc ? existingTripLoc.id : null
		
			if (!exists){
				await addTripLocationTip({
						variables: {
						input: {	
								locationTipId: tip.id,
								addedTripId: tripId,
								addedTripLocId: addedTripLocId,
								addedLocPlaceId: parentLocation.placeId,
								addedLocLat: parentLocation.lat,
								addedLocLng: parentLocation.lng,
								addedLocFullName: parentLocation.fullName

							}	
						}
					})	

				await setPopup({
					variables: {
						popup: false
					}
				})
				await this.setState({added: true})
				setTimeout(() => {
					this.setState({
						showAddToTrip: false, 
						added: false, 
						tripId: null, 
						tripAttraction: null, 
						tip: null, 
						stateError: null
					})
				}, 1000)
			} else {
				this.setState({stateError: 'This tip has already been added to this trip'})
			}
			
	
		} catch {
			this.setState({stateError: 'There was an error.'})

		}		
	}


	async handleSelectTrip(event, mutationFunction){
		const { client, location, locationId, type } = this.props
		const { tripId } = this.state
		event.preventDefault()

		const locationsRes =  await client.query({
 	  		   	 	query: GET_TRIP_GENERAL_LOCATIONS,
  	  	    		variables: { viewedTripId: tripId }
  	  	})

  	  	const tripLocations = locationsRes.data.tripGeneralLocations.nodes
		
		console.log('locationId', locationId)
		const locRes =  await client.query({
 	  		   	 	query: GET_LOCATION_VIEW_AND_DATES,
  	  	    		variables: { locId: locationId }
  	  	})

  	  	const parentLocation = locRes.data.locationViewAndDates

  	  	const existingTripLoc = tripLocations.find(tripLoc => {
			return tripLoc.placeId === parentLocation.placeId
		})

		if (!tripId) {
			this.setState({stateError: "Please select a trip to add this to"})
		} else {
			if (type === 'attraction'){
				this.handleAddTripAttraction(mutationFunction, existingTripLoc, parentLocation)
			} else {
				this.handleAddTripLocationTip(mutationFunction, existingTripLoc, parentLocation)
			}
		}

	}

	renderAddToTripIcon(){
		const { attraction, tip, type } = this.props
		return <AddToTripIcon
			attraction={attraction} 
			handleShowAddToTrip={this.handleShowAddToTrip}
			handleShowAddTipToTrip={this.handleShowAddTipToTrip}
			tip={tip}
			type={type}
		/>
	}

	renderAddToTrip(){
		const { added, showTooltip, stateError, tooltipType, tripId } = this.state
		const { type } = this.props
		return <Query query={GET_MY_TRIPS}>
			{({data, loading, error}) => {
				if (loading) return (<div>Loading...</div>)
				if (error) return (<div>Error</div>)
				const myTrips = data.myTrips.nodes
				if (type === 'attraction'){
					return <Mutation
				      mutation={ADD_TRIP_ATTRACTION}
				      refetchQueries={(result) => {
				     	const tripLocId = result.data.addTripAttraction.integer
				      return [
				      	{query: GET_TRIP_GENERAL_LOCATIONS, variables: {viewedTripId: tripId}}, 
				     	{query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLocId}}]
				      }}>
				      
			      		{(addTripAttraction, { loading, error }) => {
				      		return <AddToTrip 
				      			added={added}
				      			error={error}
				      			handleClose={this.handleHideAddToTrip}
				      			handleSelectTrip={this.handleSelectTrip}
				      			handleToggleTooltip={this.handleToggleTooltip}
				      			handleTripChange={this.handleTripChange}
				      			mutationFunction={addTripAttraction}
				      			myTrips={myTrips}
				      			showTooltip={showTooltip}
				      			stateError={stateError}
				      			tooltipType={tooltipType}
				      			tripId={tripId}
				      			type="attraction review"
				      		/>
						}}
					</Mutation>
				} else {
					return <Mutation
				      mutation={ADD_TRIP_LOCATION_TIP}
				      refetchQueries={(result) => {
				     	const tripLocId = result.data.addTripLocationTip.integer
				      return [
				      	{query: GET_TRIP_LOCATION_TIPS, variables: {tripLocId: tripLocId}}, 
				     	{query: GET_TRIP_GENERAL_LOCATIONS, variables: {viewedTripId: tripId}}]
				      }}>
				     
				      	{(addTripLocationTip, { loading, error }) => {
				      		return  <AddToTrip 
				      		    added={added}
				      		    error={error}
				      			handleClose={this.handleHideAddToTrip}
				      			handleSelectTrip={this.handleSelectTrip}
				      			handleToggleTooltip={this.handleToggleTooltip}
				      			handleTripChange={this.handleTripChange}
				      			mutationFunction={addTripLocationTip}
				      			myTrips={myTrips}
				      			showTooltip={showTooltip}
				      			stateError={stateError}
				      			tooltipType={tooltipType}
				      			tripId={tripId}
				      			type="location tip"
				      		/>

				      	}}
				    </Mutation>
				}
			}}
		</Query>
	}

	

	render() {
		const { currentUser, attraction, tip, type} = this.props
		const { showAddToTrip, showSelectTripLocation } = this.state
		return (
			<div className="addToTripContainer">
				{showAddToTrip && this.renderAddToTrip()}
				{this.renderAddToTripIcon()}
				
				
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	graphql(setPopup, {name: 'setPopup'}) 
)(AddToTripContainer);
