import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";

import "../stylesheets/components/MapView.css"


let map, marker;
let markers = []; 
let currentCityMarker;
export class MapView extends Component {
		constructor(props) {
		super(props);
		this.state = {
			currentCityLat: null, 
			currentCityLng: null,
			pins: null,
			zoomId: null
		};
	}


	async componentDidMount(){
		const { centerLat, centerLng, currentCityLat, currentCityLng, currentUser, followingInfo, handleSelectPin, handleSelectTripPin, page, pins, profUserId, tripPins, view, zoom } = this.props
			
			const center = centerLat ? {lat: Number(centerLat), lng: Number(centerLng)} : {lat: 45, lng: -45}
			   map = new this.props.google.maps.Map(document.getElementById('map'), {
			    zoom: zoom,
			    center: center
			  })


		
			console.log('rendering map', pins)
		if (currentCityLat && currentCityLng){
			 marker = new this.props.google.maps.Marker({
			 	map: map, 
			 	position: {lat: Number(currentCityLat), lng: Number(currentCityLng)},
			 	icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"

			 })

			 currentCityMarker = marker;

			
		 }

		if (view !== 'profile' && view !== 'saved' && view !== 'explore'){
			markers.forEach(marker => {
				marker.setMap(null);
			})
			markers = []
			pins.map(pin => {
			const label = (pins.indexOf(pin) + 1).toString()

			marker = new this.props.google.maps.Marker({
			    map: map,
			    position: {lat: Number(pin.lat), lng: Number(pin.lng)}, 
			    label: label, 
			    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"

			  });

			markers.push(marker)
		

			return marker.addListener('click', () => handleSelectPin(pin));

		})



			//if only 1 pin, map will be very zoomed in (padding does not work on 1 pin)
			if (markers.length > 1){
				let bounds = new this.props.google.maps.LatLngBounds();
			 	 markers.forEach(marker => {
			  		bounds.extend(marker.getPosition())
			  	})


				map.fitBounds(bounds);
			}
								
		} else {
			pins.map(pin => {
				marker = new this.props.google.maps.Marker({
				    map: map,
				    position: {lat: Number(pin.lat), lng: Number(pin.lng)},
	  			    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"

			 	});

	
				return marker.addListener('click', () => handleSelectPin(pin)); 
			})

			{((currentUser && currentUser.id === profUserId) || (followingInfo && followingInfo.status === 'APPROVED')) && tripPins && tripPins.map(tripPin => {
				if (tripPin.visible || (currentUser && currentUser.id === profUserId)){
						marker = new this.props.google.maps.Marker({
					    map: map,
					    position: {lat: Number(tripPin.lat), lng: Number(tripPin.lng)}, 
					 	icon: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
					  });

					markers.push(marker)
				

					return marker.addListener('click', () => handleSelectTripPin(tripPin.placeId));
				 	
				}

				
			})}

			console.log('after this?')
			
			
		}
		this.setState({
			currentCityLat: currentCityLat, 
			currentCityLng: currentCityLng, 
			pins: pins, 
			tripPins: tripPins
		})

	}


		componentDidUpdate(){
			const { centerLat, centerLng, currentCityLat, currentCityLng, currentUser, followingInfo, pins, tripPins, handleSelectPin, handleSelectTripPin, profUserId, view, zoomId, zoom } = this.props

			if (zoomId && zoomId !== this.state.zoomId){
				const center = {lat: Number(centerLat), lng: Number(centerLng)} 
				map.setZoom(zoom)
				map.setCenter(center)
				this.setState({zoomId: zoomId})
			}

			if (view === 'profile' && (this.state.currentCityLat !== currentCityLat || this.state.currentCityLng !== currentCityLng)){
					
					if (currentCityMarker){
						currentCityMarker.setMap(null);
						
					}
				
					 marker = new this.props.google.maps.Marker({
					 	map: map, 
					 	position: {lat: Number(currentCityLat), lng: Number(currentCityLng)}					
					  })

					 this.setState({
					 	currentCityLat: currentCityLat, 
						currentCityLng: currentCityLng
					 })
			}

	 		
			if (pins !== this.state.pins || tripPins !== this.state.tripPins){
				if (view !== 'profile'){
	
				
					markers.forEach(marker => {
						marker.setMap(null);
					})
					markers = []
					pins.map(pin => {
						const label = (pins.indexOf(pin) + 1).toString()

						marker = new this.props.google.maps.Marker({
						    map: map,
						    position: {lat: Number(pin.lat), lng: Number(pin.lng)}, 
						    label: label,
						    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"

						  });

						markers.push(marker)

						return marker.addListener('click', () => handleSelectPin(pin));
						 
					 })

					

					//if only 1 pin, map will be very zoomed in (padding does not work on 1 pin)
					if (markers.length > 1){

						let bounds = new this.props.google.maps.LatLngBounds();
					 	 markers.forEach(marker => {
					  		bounds.extend(marker.getPosition())
					  	})
						map.fitBounds(bounds);
					}
						
							
				} else {
					pins.map(pin => {
					marker = new this.props.google.maps.Marker({
					    map: map,
					    position: {lat: Number(pin.lat), lng: Number(pin.lng)},
					    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"

					  });

					
					
					return marker.addListener('click', () => handleSelectPin(pin));
					 
					})
						{((currentUser && currentUser.id === profUserId) || (followingInfo && followingInfo.status === 'APPROVED')) && tripPins && tripPins.map(tripPin => {		
							if (tripPin.visible || (currentUser && currentUser.id === profUserId)){
								marker = new this.props.google.maps.Marker({
							    map: map,
							    position: {lat: Number(tripPin.lat), lng: Number(tripPin.lng)}, 
					 			icon: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
							  });


								markers.push(marker)
								return marker.addListener('click', () => handleSelectTripPin(tripPin.placeId));
							}
						})}
				}
				this.setState({
	 				pins: pins, 
	 				tripPins: tripPins
				})

			}

 
 			

		}
			

	render() {
		return (
			<div className="mapView">
			<div id="map"></div>

	
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(MapView);

