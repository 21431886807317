import React from "react";
import { compose, graphql, Query, withApollo } from "react-apollo";
import { GoogleApiWrapper } from "google-maps-react";
import { withLastLocation } from 'react-router-last-location';
import { analytics } from '../index.js';

import history from "../history"

import AllPicturesView from "../components/AllPicturesView"
import AttractionBasicInfo from "../components/AttractionBasicInfo"
import MapView from "../components/MapView"
import OverallAttractionHeader from "../components/OverallAttractionHeader"
import OverallAttractionReviews from "../components/OverallAttractionReviews"
import Pictures from "../components/Pictures"
import PictureViewContainer from "./PictureViewContainer"
import Visitors from "../components/Visitors"

import { getCurrentUser, getPopup } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";

import { 
	GET_OVERALL_ATTRACTION_PICTURES, 
	GET_OVERALL_ATTRACTION_PICTURES_ALL,
	GET_OVERALL_ATTRACTION_REVIEWS,
	GET_OVERALL_ATTRACTION_REVIEWS_ALL,
	GET_OVERALL_ATTRACTION_VISITORS, 
	GET_OVERALL_ATTRACTION_BY_PLACE_ID
} from "../apollo/db/queries"

class OverallAttractionContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attractionPlaceId: null,
			collapse: false,
			currentUserId: null,
			initialPicIndex: 0,
			overallView: 'following',
			showAllPictures: false,
			showPicture: false
		}

		this.handleBack = this.handleBack.bind(this)
		this.handleHideAllPictures = this.handleHideAllPictures.bind(this)
		this.handleHideGooglePictures = this.handleHideGooglePictures.bind(this)
		this.handleHidePicture = this.handleHidePicture.bind(this)
		this.handleSelectPicture = this.handleSelectPicture.bind(this)
		this.handleShowAllPictures = this.handleShowAllPictures.bind(this)
		this.handleShowGooglePictures = this.handleShowGooglePictures.bind(this)
		this.handleShowPicture = this.handleShowPicture.bind(this)
		this.handleToggleHours = this.handleToggleHours.bind(this)
		this.handleToggleOverallView = this.handleToggleOverallView.bind(this)
	}

	async componentDidMount(){
		const { client, currentUser } = this.props
		const { attractionPlaceId } = this.state
		const newAttractionPlaceId = window.location.href.split('/').slice(-1)[0]
		if (attractionPlaceId !== newAttractionPlaceId){

			//there's no way to get to a new overall attraction view while it's mounted so this can be here instead of update
			// const google = this.props.google;
			// const self = this;
			// let basicInfo;
			// const request = {
			//   placeId: newAttractionPlaceId,
			//   fields: ['opening_hours', 'website', 'formatted_phone_number', 'international_phone_number', 'name', 'photos', 'price_level', 'address_component', 'geometry']
			// };

	
			// const service = new google.maps.places.PlacesService(document.getElementById('overallAttrMount'));


			// await service.getDetails(request, handleDetails);

			// function handleDetails(place, status){
			// 		if (status === 'NOT_FOUND'){
						
			// 			console.log('could not find attraction')
						
			// 		} else if (status === google.maps.places.PlacesServiceStatus.OK) {
			// 			//setting this on the other set state wasnt working- dig in more later
			// 		  self.setState({attractionBasicInfo: place})
			// 		}
			// 	}

			if (currentUser.id){
				try {
					const res = await client.query({
						query: GET_OVERALL_ATTRACTION_VISITORS,
						variables: { attrPlaceId: newAttractionPlaceId}
					})

					const visitors = res.data.overallAttractionVisitors.nodes
					if (visitors.length){
						this.setState({
							currentUserId: currentUser.id,
							attractionPlaceId: newAttractionPlaceId
						})

					} else {
						this.setState({
							attractionPlaceId: newAttractionPlaceId, 
							currentUserId: currentUser.id,
							overallView: 'public'
						})
					}
					
				} catch {
					console.log('error')
				}


			} else {
				this.setState({
					attractionPlaceId: newAttractionPlaceId, 
					overallView: 'public'
				})
			} 

		}
	}


	async componentDidUpdate(){
		const { client, currentUser } = this.props
		const { attractionPlaceId, currentUserId, overallView } = this.state
		//if page is refreshed current user is not set on mount so need this to be able to display following view.
		if (overallView !== 'following' && currentUser.id && !currentUserId){
			try {
				const res = await client.query({
					query: GET_OVERALL_ATTRACTION_VISITORS,
					variables: { attrPlaceId: attractionPlaceId}
				})

				const visitors = res.data.overallAttractionVisitors.nodes
				if (visitors.length){
					this.setState({
						currentUserId: currentUser.id,
						overallView: 'following'
					})
				}
				
			} catch {
				console.log('error')
			}

		} 	
	}

	async handleShowAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAllPictures: true})
	}

	async handleHideAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAllPictures: false})
	}

	async handleSelectPicture(id, currentPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: currentPicIndex, showAllPictures: false})
	}


	handleBack(){
		history.goBack()
	}


	async handleShowPicture(initialPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: initialPicIndex})
	}

	async handleHidePicture(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showPicture: false, intialPicIndex: 0})
	}

	async handleShowGooglePictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showGooglePictures: true})
	}

	async handleHideGooglePictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showGooglePictures: false})
	}


	handleToggleHours(){
		const { collapse } = this.state
		this.setState({collapse: !collapse})
	}


	async handleToggleOverallView(view){
		this.setState({overallView: view})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('change_overall_attraction_view', {
	   		 	view: view
	   		});
	   	}
	}

	renderHeader(){
		const { currentUser, lastLocation } = this.props
		const { attractionBasicInfo, attractionPlaceId, overallView } = this.state
		return <Query 
			query={GET_OVERALL_ATTRACTION_BY_PLACE_ID}
	    	variables= {{ attrPlaceId: attractionPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const attraction = data.overallAttractionByPlaceId

				return <OverallAttractionHeader 
					attraction={attraction}
					currentUser={currentUser}
					handleBack={this.handleBack}
					handleToggleOverallView={this.handleToggleOverallView}
					lastLocation={lastLocation}
					overallView={overallView}
				/>
			}}
		</Query>
	
	}

	renderMap(){
		const { attractionPlaceId } = this.state 
		return <Query 
			query={GET_OVERALL_ATTRACTION_BY_PLACE_ID}
	    	variables= {{ attrPlaceId: attractionPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const attraction = data.overallAttractionByPlaceId

				return <MapView 
					centerLat={attraction.lat}
					centerLng={attraction.lng}
					pins={[attraction]}
					view="overallAttraction"
					zoom={15}
				/>
			}}
		</Query>
	}

	renderPictures(){
		const { attractionPlaceId, initialPicIndex, showAllPictures, showPicture } = this.state

		return <Query 
			query={GET_OVERALL_ATTRACTION_PICTURES}
	    	variables= {{ attrPlaceId: attractionPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const pictures = data.overallAttractionPictures.nodes
				 const displayPics = (pictures.length && pictures[0] !== null) ? pictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []

				return <div>
					{showPicture && <PictureViewContainer 
						 	handleClose={this.handleHidePicture}
						 	initialPicIndex={initialPicIndex}
							pictures={displayPics}
						/>}
					<Pictures 
						handleShowAll={this.handleShowAllPictures}
						handleShowPicture={this.handleShowPicture}
						pictures={displayPics}
			            picturesViewable={true}
			            type="overall attraction"
						view="overallAttraction"
					/>
					{showAllPictures && <AllPicturesView
						pictures={displayPics}
						handleClose={this.handleHideAllPictures}
						handleSelectPicture={this.handleSelectPicture}
					/>}
				</div>
			}}
		</Query>
	}


	renderGooglePictures(){
		const { attractionBasicInfo } = this.state
		const pictures = attractionBasicInfo.photos && attractionBasicInfo.photos.map(photo => {
			return photo.getUrl()
		})
		return <AllPicturesView 
			handleClose={this.handleHideGooglePictures}
			handleSelectPicture={()=> console.log('this will show pic')}
			pictures={pictures}
			type="googlePictures"
		/>
	} 

	renderAllPictures(){
		const { attractionPlaceId, initialPicIndex, showAllPictures, showPicture } = this.state
		const { currentUser } = this.props
		const currentUserId = currentUser.id ? currentUser.id : 0;
		return <Query 
			query={GET_OVERALL_ATTRACTION_PICTURES_ALL}
	    	variables= {{ attrPlaceId: attractionPlaceId, currentUserId: currentUserId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const pictures = data.overallAttractionPicturesAll.nodes

				 const displayPics = (pictures.length && pictures[0] !== null) ? pictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []


				return <div>
					{showPicture && <PictureViewContainer 
						 	handleClose={this.handleHidePicture}
						 	initialPicIndex={initialPicIndex}
							pictures={displayPics}
						/>}
					<Pictures 
						handleShowAll={this.handleShowAllPictures}
						handleShowPicture={this.handleShowPicture}
						pictures={displayPics}
						picturesViewable={true}
						type="overall attraction"
						view={'overallAttraction'}
					/>

					{showAllPictures && <AllPicturesView
						pictures={displayPics}
						handleClose={this.handleHideAllPictures}
						handleSelectPicture={this.handleSelectPicture}
					/>}
				</div>
			}}
		</Query>
	}

	renderReviews(){
		const { attractionBasicInfo, attractionPlaceId, overallView} = this.state
		const { currentUser } = this.props
		return <Query 
			query={GET_OVERALL_ATTRACTION_REVIEWS}
	    	variables= {{ attrPlaceId: attractionPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const reviews = data.overallAttractionReviews.nodes
				return <OverallAttractionReviews 
					attractionBasicInfo={attractionBasicInfo}
					currentUser={ currentUser }
					overallView={overallView}
					reviews={reviews}
				/>
			}}
		</Query>

	}

	renderAllReviews(){
		const { attractionBasicInfo, attractionPlaceId, overallView } = this.state
		const { currentUser } = this.props
		const currentUserId = currentUser.id ? currentUser.id : 0;
		return <Query 
			query={GET_OVERALL_ATTRACTION_REVIEWS_ALL}
	    	variables= {{ attrPlaceId: attractionPlaceId, currentUserId: currentUserId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const reviews = data.overallAttractionReviewsAll.nodes
				return <OverallAttractionReviews 
					attractionBasicInfo={attractionBasicInfo}
					currentUser={ currentUser }
					overallView={overallView}
					reviews={reviews}
				/>
			}}
		</Query>

	}

	renderVisitors(){
		const { attractionPlaceId } = this.state

		return <Query 
			query={GET_OVERALL_ATTRACTION_VISITORS}
	    	variables= {{ attrPlaceId: attractionPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const visitors = data.overallAttractionVisitors.nodes

				return <div>
					
					<Visitors
						otherVisitors={visitors}
						view="overallAttraction"
					/>
				</div>
			}}
		</Query>

	}

	renderBasicInfo(){
		const { attractionPlaceId, attractionBasicInfo, collapse } = this.state
		return <Query 
			query={GET_OVERALL_ATTRACTION_BY_PLACE_ID}
	    	variables= {{ attrPlaceId: attractionPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const attraction = data.overallAttractionByPlaceId

				return <AttractionBasicInfo 
					attraction={attraction}
					handleShowGooglePictures={this.handleShowGooglePictures}
					handleToggleHours={this.handleToggleHours}
					collapse={collapse}

				/>
			}}
		</Query>


	}


	render() {
		const { currentUser, screenView } = this.props
		const { attractionBasicInfo, attractionPlaceId, overallView, showGooglePictures } = this.state
		const popupClass = screenView.popup && "disabledBackground"
		return (
			<div className={`overallAttractionContainer ${popupClass}`}>
			<div id="overallAttrMount"></div>
			{ attractionPlaceId ?
			<div>
				{this.renderHeader()}
				{showGooglePictures && this.renderGooglePictures()}
				{overallView === 'following' ? this.renderPictures() : this.renderAllPictures()}
				<div className="overallAttractionContent">
					<div className="overallAttractionLeft">
						{overallView === 'following' ? this.renderReviews() : this.renderAllReviews()}
					</div>
					<div className="overallAttractionRight">
						{this.renderMap()}
						{currentUser.id && overallView === 'following' && this.renderVisitors()}
						{this.renderBasicInfo()}
					</div>
				</div>

				
			</div> : 
			<div>Loading...</div>
				}
			</div>
			
		);
	}
}

export default compose(
	withApollo,
	withLastLocation,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}),
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'}), 
	GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
	})
)(OverallAttractionContainer);
