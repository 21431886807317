import React from "react";

const OverallLocationHeader = props => {
	const {
		currentUser,
		handleBack,
		handleToggleOverallView, 
		lastLocation,
		name, 
		overallView
	} = props;
	console.log('overallView', overallView)
	const selected = view => view === overallView && 'toggleSelected';
	return (
		<div className="overallLocationHeader">
			{/*lastLocation && <div className="backIcon" onClick={() => handleBack()}>Back</div>*/}
			<div className="overallLocationHeaderContent">
				<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">{name}</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
				{currentUser.id  ? <div className="toggleButtons">
					<div className={`toggleButton toggleLeft ${selected('following')}`} onClick={() => handleToggleOverallView('following')}>Following View</div>
					<div className={`toggleButton toggleRight ${selected('public')}`} onClick={() => handleToggleOverallView('public')}>Public View</div>
				</div> : <div className="toggleButtons">
					<div className="toggleButton toggleLeft toggleSelected">Public View</div>
					<div>Create an account or login for Follow View</div>
				</div>}
			</div>
		</div>
			

		
	);
};

export default OverallLocationHeader;
