import React from "react";
import { compose, graphql } from "react-apollo";

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { setCurrentUser } from "../apollo/state/mutations"
import { analytics } from '../index.js';

import Welcome from "../components/Welcome";


//const analytics = analytics();

class WelcomeContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stateError: null
		}

		this.handleGuest = this.handleGuest.bind(this)
	}

	async handleGuest(){
		const {setCurrentUser} = this.props
		try {
			await setCurrentUser({
                variables: {
                  id: 0
                }
              })

			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('continue_as_guest');
			}


		} catch {
			console.log('error?')
			this.setState({stateError: "There was an error."})
		}
		 history.push('/explore?explanation');
		
	}
	render() {
		const { stateError } = this.state
		return (
			<div className="welcomeContainer">
				<Welcome handleGuest={this.handleGuest} stateError={stateError}/>
			</div>
		);
	}
}

export default compose(
	graphql(setCurrentUser, {name: 'setCurrentUser'}), 
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
	)(WelcomeContainer);
