import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/Picture.css"

const Picture = props => {
	const { 
		handleShowPicture,
		picIndex,
		picture, 
		type,
		view
	 } = props;
	return (
		<div className="pictureHolder">
			{view === 'overallLocation' && <Link className="textLink fontSmall picName" to={`/location/${picture.locationId}`}>{(picture.firstName + ' ' + picture.lastName)}</Link>}
			{view === 'overallAttraction' && <Link className="textLink fontSmall picName" to={`/attraction/${picture.attractionId}`}>{(picture.firstName + ' ' + picture.lastName)}</Link>}
			
		    {type === 'allPictures' ? 
		    <img
				className="picture" 
				src={picture.publicUrl} 
				alt="picture" /> 
		    : <img 
				onClick={() => handleShowPicture(picIndex)} 
				className="picture" 
				src={picture.publicUrl} 
				alt="picture" /> 
			}
		</div>
	);
};

export default Picture;
