import React from "react";

import "../stylesheets/components/AddToTripIcon.css"

const AddToTripIcon = props => {
	const { 
		attraction,
		handleShowAddToTrip, 
		handleShowAddTipToTrip,
		tip,
		type
	} = props
	return (
		<div className="addToTripIcon">

			{type === 'attraction' ?
				<img 
					className="iconSmall" 
					onClick={() => handleShowAddToTrip(attraction)}
					src="https://storage.googleapis.com/trvlpage-bucket/plus.svg"
					/> :
				<img 
					className="iconSmall" 
					onClick={() => handleShowAddTipToTrip(tip)}
					src="https://storage.googleapis.com/trvlpage-bucket/plus.svg"
					/>

			}
			<div className="addToTripLabel fontXS">Add To Trip</div>
		

		</div>
	);
};

export default AddToTripIcon;
