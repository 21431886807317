import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import DatesForm from "./DatesForm"


import { Button } from 'reactstrap';

const LocationDetails = (props) => {
	const {
		closeText,
		createLocationDate,
		error, 
		handleClose,
		handleToggleTooltip, 
    	handleSubmit,
		initialValues,
		locationId, 
		locDetailsSchema,
		showTooltip,
		tooltipType,
		submitText, 
		updateLocation, 
		view
		} = props 
	return (
		<div className="locationDetails popup popupLarge">
			<div className="popupContent">
				<div className="secondaryHeader formHeader">Location Details</div>

				<Formik
			      initialValues={initialValues}
		          validationSchema={locDetailsSchema}
			      onSubmit={(values, { setSubmitting }) => {
			        handleSubmit(values, updateLocation, createLocationDate, setSubmitting)
			      }}
			    >
			      {({ isSubmitting }) => (
			        <Form className="form">
			       		<div className="formItem">
			       			<div>
			       				<div className="labelAndTooltip">
						          	<label className="textTitle textTitleForm" htmlFor="overallReview">overall review</label>
									<div className="tooltipIcon" onClick={() => handleToggleTooltip('overallReview')}>?</div>
								</div>
								{showTooltip && tooltipType === 'overallReview' && <div className='tooltip'>Your overall thoughts on the location as a whole. You can add speficic attractions and reviews within the location page.</div>}
							</div>
				          	<Field className="formField" 
				          		component="textarea" 
				          		rows={6}
				          		name="overallReview" 
				          		autoComplete="off"
				          	/>
				          	{view === 'add' && <div>
				          		<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="startDate">start date</label>
									<Field 
										className="formField" 
										type="date" 
										name="startDate"
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        />
								</div>
									<ErrorMessage className="formError" name="startDate" component="div" />
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="endDate">end date</label>
									<Field 
										className="formField" 
										type="date" 
										name="endDate"
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        />
								</div>
									<ErrorMessage className="formError" name="endDate" component="div" />
							</div>}
				        </div>
			          <ErrorMessage name="overallReview" component="div" />
			          {error && <div className="error">There was an error.</div>}
			          <div className="formButtons">
			    		<button className="secondaryButton formButton" onClick={() => handleClose(locationId)}>{closeText}</button>
			        	<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>
			            {submitText}
			          </button>
			          </div>
			           <div className="formItem">
			          	<div>To edit a specific activity, restaurant, or lodging, click the pencil on that review.</div>
			          </div>
			        </Form>
			      )}
			    </Formik>
			   

			</div>	
		</div>
	);
};

export default LocationDetails;
