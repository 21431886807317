import React from "react";

import "../stylesheets/components/TripItineraryHeader.css"

const TripItineraryHeader = props => {
	const { 
		handleBack, 
		handleExportItinerary,
		handleGoToTrip, 
		lastLocation
	} = props
	return (
		<div className="tripItineraryHeader">
			{/*lastLocation && <div className="backIcon" onClick={() => handleBack()}>Back</div>*/}
			<div className='tripItineraryHeaderContent  '>
				<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">trip itinerary</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
				<div className="secondaryButton" onClick={() => handleExportItinerary()}>Export </div>
			</div>
			{/*<button className="secondaryButton"onClick={() => handleGoToTrip()}>Trip Info</button>*/}
		</div>
	);
};

export default TripItineraryHeader;
