import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/Form.css"
import { Button } from 'reactstrap';

const DatesForm = props => {
	const {
		error, 
		initialValues, 
		handleClose,
		handleSubmit, 
		mutationFunction, 
		stateError,
		validationSchema, 
		view
	} = props;

	return (
		<div className="datesForm popup popupMedium">
			<div className="popupContent">
				{<h2 className="secondaryHeader">Dates</h2>}

				<Formik
					validationSchema={validationSchema}
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(
							values,
							mutationFunction,
							setSubmitting				
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="form">
				
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="startDate">start date</label>
									<Field 
										className="formField" 
										type="date" 
										name="startDate"
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        />
								</div>
									<ErrorMessage className="formError" name="startDate" component="div" />
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="endDate">end date</label>
									<Field 
										className="formField" 
										type="date" 
										name="endDate"
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        />
								</div>
									<ErrorMessage className="formError" name="endDate" component="div" />
						    {stateError && <div className="error">{stateError}</div>}
						    {error && <div className="error">There was an error.</div>}
							<div className="formButtons">
								<Button className="secondaryButton" onClick={() => handleClose()}>Cancel</Button>
								<Button className="primaryButton" type="submit">
									Submit
								</Button>
							</div>
							

						</Form>}
				</Formik>
				
			</div>
		</div>
	);
};

export default DatesForm;
