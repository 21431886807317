import React from "react";

import "../stylesheets/components/AllPicturesView.css"

import Picture from "../components/Picture"

const AllPicturesView = props => {
	const { 
		handleClose,
		handleSelectPicture, 
		pictures, 
		type
	} = props
	return (
		<div className={type === 'googlePictures' ? "googlePictures allPicturesView popup popupLarge" : "allPicturesView popup popupLarge"}>
			<div className="closeIconHolder">
								<i className="material-icons activeIcon iconMedium" onClick={() => handleClose()}>cancel</i>
			</div>
			<div className="popupContent">
			{type !== 'googlePictures' ? pictures.map((pic, index) => {
				return <div className="singlePicture" key={pic.id} onClick={() => handleSelectPicture(pic.id, index)}>
						<Picture 
							picture={pic}
							type="allPictures" 
						/>
				</div>
			}) : pictures.map((pic, index) => {
				return <div className="singlePicture" key={pic.id}>
						<img 
							className="googlePic"
							src={pic} 
						/>
				</div>
			})}
			</div>
		</div>
	);
};

export default AllPicturesView;
