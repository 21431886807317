import React from "react";
import axios from "axios";
import { compose, withApollo, graphql, Query } from "react-apollo";
import ReactPaginate from 'react-paginate';
import { analytics } from '../index.js';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import "../stylesheets/containers/SearchContainer.css"

import history from "../history"

import Autocomplete from "../components/Autocomplete"
import MapView from "../components/MapView"
import Search from "../components/Search";
import SearchFilters from "../components/SearchFilters"
import SearchHeader from "../components/SearchHeader"
import SearchResults from "../components/SearchResults"
import SuggestedFollows from "../components/SuggestedFollows"
 
 
import { 
	GET_SUGGESTED_FOLLOW_USERS,
	SEARCH_ATTRACTIONS, 
	SEARCH_LOCATIONS, 
	SEARCH_USERS 
} from "../apollo/db/queries"
import { 
	RECORD_SEARCH, 
	SAVE_ATTRACTION, 
	SEARCH_ATTRACTION_CLICK, 
	SEARCH_LOCATION_CLICK, 
	SEARCH_PROFILE_CLICK, 
	UPDATE_USER
	 } from "../apollo/db/mutations"
import { 
	getCurrentUser, 
	getDestinationSearch, 
	getUserSearch, 
	getSearchView
 } from "../apollo/state/queries"
 import {
 	setCurrentUser,
 	setDestinationSearchAttrContent,
 	setDestinationSearchAttrStars,
 	setDestinationSearchAttrTags,
 	setDestinationSearchAttrTypes, 
 	setDestinationSearchType,
 	setDestinationSearchAttrAndUp,
 	setDestinationSearchAttrSort, 
	setDestinationSearchAttrSortDir, 
	setDestinationSearchLocSort, 
	setDestinationSearchLocSortDir,
	setDestinationSearchValue, 
	setDestinationSearchNeedsRefresh,
 } from "../apollo/state/mutations"


class SearchContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			attrAndUp: false,
			attrSort: 'recommended',
			attrSortDirection: 'desc',
			attrStars: null,
			attrTypes: [],
			attrContent: [],
			destinationPlace: null,
			destinationType: 'locations',
			destinationValue: null,
			hasSearched: false,
			hoverStars: 0,
			locSort: 'recommended', 
			locSortDirection: 'desc',
			page: 0,
			pinIndexRef: [],
			placeValue: '', 
			results: [],
			run: false, 
			savedIds: [],
			showMoreFilters: false, 
			sortedResults: [],
			stateError: null,
			steps: [{
               target: '.searchJoyride1',
               placement: 'center',
               content: `Search results are customized to you. \n\n\nThey are based on your interactions with others and the quality of the content.`, 
               disableBeacon: true
             },
             {
               target: '.searchJoyride2',
               placement: 'center',
               content: `The grey results are a specific person's experience. \n\n\nThe green results give you an overall view where you can toggle between just your network and everyone on trvlpage who has been there.`, 
               disableBeacon: true
             }],
			view: 'destination',
			tags: [],
			unsavedIds: [],
			userValue: null
			
		    };
		   
		   this.handleChangeSort = this.handleChangeSort.bind(this)
		   this.handleChangeSortDirection = this.handleChangeSortDirection.bind(this)
		   this.handleGoToResult = this.handleGoToResult.bind(this)
		   this.handleHover = this.handleHover.bind(this)
		   this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this)
		   this.handlePageChange = this.handlePageChange.bind(this)
		   this.handleSelectAttrType = this.handleSelectAttrType.bind(this)
		   this.handleSelectAttrContent = this.handleSelectAttrContent.bind(this)
		   this.handleSetDestSearchValue = this.handleSetDestSearchValue.bind(this)
		   this.handleSetDestinationType = this.handleSetDestinationType.bind(this)
		   this.handleSelectPin = this.handleSelectPin.bind(this)
		   this.handleSelectStar = this.handleSelectStar.bind(this)
		   this.handleSelectTag = this.handleSelectTag.bind(this)
		   this.handleSearch = this.handleSearch.bind(this)
		   this.handleToggleAndUp = this.handleToggleAndUp.bind(this)
		   this.handleToggleFilters = this.handleToggleFilters.bind(this)
		   this.handleToggleSavedAttraction = this.handleToggleSavedAttraction.bind(this)
		   this.handleToggleUnsavedAttraction = this.handleToggleUnsavedAttraction.bind(this)
		   this.handleUpdateUser = this.handleUpdateUser.bind(this)
		  
	}

	async componentDidMount(){
		console.log('is this mounting?')
		const { destinationSearch, userSearch, searchView } = this.props
		const { destinationPlace, destinationType, destinationValue, userValue, view } = this.state
		if (destinationValue !== destinationSearch.value 
			|| destinationType !== destinationSearch.type || 
			destinationPlace !== destinationSearch.place ||
			userValue !== userSearch.value ||
			view !== searchView.view){
			await this.setState({
				attrAndUp: destinationSearch.attractionAndUp,
				attrSort: destinationSearch.attractionSort, 
				attrSortDirection: destinationSearch.attractionSortDir,
				attrStars: destinationSearch.attractionStars,
				attrTypes: destinationSearch.attractionTypes,
				attrContent: destinationSearch.attractionContent,
				destinationPlace: destinationSearch.place,
				destinationType: destinationSearch.type,
				destinationValue: destinationSearch.value,
				locSort: destinationSearch.locationSort,
				locSortDirection: destinationSearch.locationSortDir,
				tags: destinationSearch.attractionTags, 
				userValue: userSearch.value,
				view: searchView.view
			})
			if ((searchView.view === 'user' && userSearch.value) ||
					(searchView.view === 'destination' && 
						destinationSearch.place)){
				console.log('does this run?')
				await this.handleSearch()
			}
		}

	}

	async componentDidUpdate(){
		console.log('is this updating?')
		const { destinationSearch, userSearch, searchView, setDestinationSearchNeedsRefresh, } = this.props
		const { destinationPlace, destinationType, destinationValue, userValue, view } = this.state
		console.log('destinationPlace', destinationPlace)
		if (destinationValue !== destinationSearch.value 
			|| destinationType !== destinationSearch.type || 
			destinationPlace !== destinationSearch.place ||
			userValue !== userSearch.value ||
			view !== searchView.view || destinationSearch.needsRefresh){
			if ((searchView.view === 'user' && userSearch.value) ||
					(searchView.view === 'destination' && 
						destinationSearch.place)){
						const newSaved = destinationSearch.needsRefresh
				//results needs to be set to an empty array or else itll rerender before handleSearch is done and fail
						await setDestinationSearchNeedsRefresh({
							variables: {
								needsRefresh: false
							}
						})
					await this.setState({
						attrAndUp: destinationSearch.attractionAndUp,
						attrSort: destinationSearch.attractionSort, 
						attrSortDirection: destinationSearch.attractionSortDir,
						attrStars: destinationSearch.attractionStars,
						attrTypes: destinationSearch.attractionTypes,
						attrContent: destinationSearch.attractionContent,
						destinationPlace: destinationSearch.place,
						destinationType: destinationSearch.type,
						destinationValue: destinationSearch.value,
						locSort: destinationSearch.locationSort,
						locSortDirection: destinationSearch.locationSortDir,
						results: [],
						tags: destinationSearch.attractionTags, 
						userValue: userSearch.value,
						view: searchView.view
					})
					console.log('ABOUT TO SEARCH')
				this.handleSearch(newSaved)
			} else {
				await this.setState({
						attrAndUp: destinationSearch.attractionAndUp,
						attrSort: destinationSearch.attractionSort, 
						attrSortDirection: destinationSearch.attractionSortDir,
						attrStars: destinationSearch.attractionStars,
						attrTypes: destinationSearch.attractionTypes,
						attrContent: destinationSearch.attractionContent,
						destinationPlace: destinationSearch.place,
						destinationType: destinationSearch.type,
						destinationValue: destinationSearch.value,
						locSort: destinationSearch.locationSort,
						locSortDirection: destinationSearch.locationSortDir,
						results: [],
						tags: destinationSearch.attractionTags, 
						userValue: userSearch.value,
						view: searchView.view
					})
			} 
			
		}
	}

	async componentWillUnmount(){
		const { setDestinationSearchNeedsRefresh } = this.props
		const { savedIds, unsavedIds } = this.state
		console.log('savedId', savedIds, unsavedIds)
		if (savedIds.length || unsavedIds.length){
			await setDestinationSearchNeedsRefresh({
					variables: {
						needsRefresh: true
					}
				})
		}
	}




	async handleToggleFilters(){
		const { client } = this.props
		const { showMoreFilters } = this.state 

		await this.setState({showMoreFilters: !showMoreFilters})

		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('toggle_search_filters', {
				  action: showMoreFilters ? 'close' : 'open'
			});
		}
 
	}


	async handleToggleAndUp(e){
		const { attrAndUp } = this.state
		const { setDestinationSearchAttrAndUp } = this.props
		e.stopPropagation()
		await this.setState({
			attrAndUp: !attrAndUp, 
			page: 0
		})
		await setDestinationSearchAttrAndUp({
			variables: {
				attractionAndUp: !attrAndUp
			}
		})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('change_search_filter', {
			  type: 'andUp', 
			  value: attrAndUp ? 'not-checked': 'checked'
			});
		}
		this.sortResults()
	}

	handleHover(e, stars){
		e.stopPropagation()
		this.setState({
			hoverStars: stars
		})
	}

	
	handleToggleUnsavedAttraction(attrId){
		console.log('is this saving? ', attrId)
		const { savedIds } = this.state
		
		if (savedIds.includes(attrId)){
			const newSavedIds = savedIds.filter(id => attrId !== attrId)
			this.setState({savedIds: newSavedIds})
			
		} else {
			this.setState({savedIds: [...savedIds, attrId]})	
			
		}
		
	}

	handleToggleSavedAttraction(attrId){
		const { unsavedIds } = this.state
		
		if (unsavedIds.includes(attrId)){
			const newUnsavedIds = unsavedIds.filter(id => attrId !== attrId)
			this.setState({unsavedIds: newUnsavedIds})
			
		} else {
			this.setState({unsavedIds: [...unsavedIds, attrId]})	
			
		}

	}

	async handleSelectStar(e, stars){
		const { setDestinationSearchAttrAndUp, setDestinationSearchAttrStars } = this.props
		e.stopPropagation()
		if (stars === 0){
			await this.setState({attrStars: stars, attrAndUp: false, page: 0})
			await setDestinationSearchAttrStars({
				variables: {
					attractionStars: stars
				}
			})
			await setDestinationSearchAttrAndUp({
				variables: {
					attractionAndUp: false
				}
			})
		}
		await this.setState({attrStars: stars, page: 0})
		await setDestinationSearchAttrStars({
			variables: {
				attractionStars: stars
			}
		})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('change_search_filter', {
			  type: 'stars', 
			  value: stars.toString()
			});
		}
		this.sortResults()
	}

	async handlePageChange(pageInfo){
		await this.setState({page: pageInfo.selected})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('show_more_search_results');
		}
		this.sortResults()
		window.scrollTo(0, 0)
		
	}


	async handleSelectTag(e, newTag){
		const { tags, destinationType } = this.state
		const { setDestinationSearchType, setDestinationSearchAttrTags } = this.props
		e.stopPropagation()
		if (tags.includes(newTag)){
			const newTags = tags.filter(tag => tag !== newTag)
			await this.setState({tags: newTags, page: 0})
			await setDestinationSearchAttrTags({
				variables: {
					attractionTags: newTags
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
				  type: 'remove_tag', 
				  value: newTag
				});
			}
			this.sortResults()
		} else {
			await this.setState({tags: [...tags, newTag], page: 0})
			await setDestinationSearchAttrTags({
				variables: {
					attractionTags: [...tags, newTag]
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
				  type: 'add_tag', 
				  value: newTag
				});
			}
			this.sortResults()
		}
		
	}

	async handleSetDestinationType(type){
		const { destinationType } = this.state
		const { setDestinationSearchType } = this.props
		if (destinationType !== type){
			await this.setState({results: [], sortedResults: [], pinIndexRef: [], showMoreFilters: false, page: 0})
			await setDestinationSearchType({
				variables: {
					type: type
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_destination_type', {
					type: type
				});
			}
			//this.sortResults()
		}

		
		
	}

	async handleSetDestSearchValue(event){
		const { setDestinationSearchValue, setDestinationSearchType, destinationSearch } = this.props
		const placeValue = event.target.value
		await this.setState({placeValue: placeValue, page: 0})
		await setDestinationSearchValue({
			variables: {
				value: placeValue
			}
		})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('set_destination_search_value', {
				value: placeValue
			});
		}
	}


	async handleSelectAttrType(newType){
		const { attrTypes, destinationType } = this.state
		const { setDestinationSearchAttrTypes } = this.props
	    if (attrTypes.includes(newType)){
			const newAttrTypes = attrTypes.filter(attrType => attrType !== newType)
			await this.setState({attrTypes: newAttrTypes, page: 0})
			await setDestinationSearchAttrTypes({
				variables: {
					attractionTypes: newAttrTypes
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'remove_attr_type',
					value: newType
				});
			}
			this.sortResults()
		} else {
			await this.setState({attrTypes: [...attrTypes, newType], page: 0})
			await setDestinationSearchAttrTypes({
				variables: {
					attractionTypes: [...attrTypes, newType]
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'add_attr_type',
					value: newType
				});
			}
			this.sortResults()
		}
	}



	async handleSelectAttrContent(e, newContent){
		const { setDestinationSearchAttrContent } = this.props
		const { attrContent } = this.state
		e.stopPropagation()
		if (attrContent.includes(newContent)){
			const newAttrContent = attrContent.filter(content => content !== newContent)
			await this.setState({attrContent: newAttrContent, page: 0})
			await setDestinationSearchAttrContent({
				variables: {
					attractionContent: newAttrContent
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'remove_attr_content',
					value: newContent
				});
			}
			this.sortResults()
		} else {
			await this.setState({attrContent: [...attrContent, newContent], page: 0})
			await setDestinationSearchAttrContent({
				variables: {
					attractionContent: [...attrContent, newContent]
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'add_attr_content',
					value: newContent
				});
			}
			this.sortResults()
		}

	}

	async handleChangeSortDirection(type, direction){
		const { setDestinationSearchAttrSortDir, setDestinationSearchLocSortDir } = this.props
		await this.setState({[type]: direction})
		if (type === 'locSortDirection'){
			await setDestinationSearchLocSortDir({
				variables: {
					locationSortDir: direction
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'location_sort_direction',
					value: direction
				});
			}
		} else {
			await setDestinationSearchAttrSortDir({
				variables: {
					attractionSortDir: direction
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'attraction_sort_direction',
					value: direction
				});
			}
		}
		this.sortResults()
	}

	async handleChangeSort(type, event){
		const { setDestinationSearchAttrSort , setDestinationSearchLocSort } = this.props
		const sortBy = event.target.value
		await this.setState({[type]: sortBy})
		if (type === 'locSort'){
			await setDestinationSearchLocSort({
				variables: {
					locationSort: sortBy
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'location_sort_by',
					value: sortBy
				});
			}

		} else {
			await setDestinationSearchAttrSort({
				variables: {
					attractionSort: sortBy
				}
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('change_search_filter', {
					type: 'attraction_sort_by',
					value: sortBy
				});
			}
		}
		this.sortResults()
	}

	filterResults(results){ 
		const { attrAndUp, attrContent, attrStars, attrTypes, tags } = this.state 	
		let attrResults = results
		if (attrContent === 'reviews'){
			attrResults = attrResults.filter(result => result.review && result.review.length)
		} else if (attrContent === 'tips'){
			attrResults = attrResults.filter(result => {
				return result.tips && result.tips.length
			})
		}
		if ( attrStars ){
			if (attrAndUp){
				attrResults = attrResults.filter(result => Number(result.stars) >= attrStars)
			} else {
				attrResults = attrResults.filter(result => Number(result.stars) === attrStars)
			}
		}
		if (attrTypes.length){
			attrResults = attrResults.filter(result => { 
				return attrTypes.includes(result.attractionType) || 
					(result.attractionTypes && result.attractionTypes.some(type => {
						return attrTypes.includes(type)
					}))
			})
		}
		if (tags.length){
			attrResults = attrResults.filter(result => { 
				return result.tags && result.tags.length && result.tags[0] && 
				result.tags.some(tag => {
					return tags.includes(tag.tag)
				})
			})
		}
		return attrResults;
	}


	sortResults(){
		const {attrContent, attrSort, attrSortDirection, destinationType, locSort, locSortDirection, page, results} = this.state
		const { currentUser } = this.props
		let sortedResults;
		if (destinationType === 'attractions'){
			const attrResults = this.filterResults(results)
			if (attrSort === 'recommended'){
				if (attrSortDirection === 'desc'){
					sortedResults = attrResults
				} else {
					sortedResults = attrResults.slice().reverse()
				}
			} else if (attrSort === 'rating'){
				const attrWithStars = attrResults.filter(result => result.stars)
				const attrWithoutStars = attrResults.filter(result => !result.stars)

				if (attrSortDirection === 'desc'){
					sortedResults = attrWithStars.slice().sort((resultA, resultB) => {
						return resultB.stars - resultA.stars	
					}).concat(attrWithoutStars)
				} else {
					sortedResults = attrWithStars.slice().sort((resultA, resultB) => {
						return resultA.stars - resultB.stars	
					}).concat(attrWithoutStars)
				}
			}		
		} 
		else if (destinationType === 'locations'){
			if (locSort === 'recommended'){
				if (locSortDirection === 'desc'){
					sortedResults = results
				} else {
					sortedResults = results.slice().reverse()
				}
			} else if (locSort === 'rating'){
				const locWithStars = results.filter(result => result.averageStars)
				const locWithoutStars = results.filter(result => !result.averageStars)
				if (locSortDirection === 'desc'){
					sortedResults = locWithStars.sort((resultA, resultB) => {
						return resultB.averageStars - resultA.averageStars	
					}).concat(locWithoutStars)
				} else {
					sortedResults = locWithStars.slice().sort((resultA, resultB) => {
						return resultA.averageStars - resultB.averageStars	
					}).concat(locWithoutStars)
				}	
			} else if (locSort === 'date'){
				const locWithDates = results.filter(result => result.dates && result.dates[0] !== null)
				const locWithoutDates = results.filter(result => !result.dates || result.dates[0] === null)

				if (locSortDirection === 'desc'){
					sortedResults = locWithDates.slice().sort((resultA, resultB) => {
						if (resultA.dates[0].endDate && resultB.dates[0].endDate){
							return new Date(resultB.dates[0].endDate) - new Date(resultA.dates[0].endDate)
						} else if (!resultA.dates[0].endDate && !resultB.dates[0].endDate) {
							return new Date(resultB.dates[0].startDate) - new Date(resultA.dates[0].startDate)
						} else if (!resultB.dates[0].endDate) {
							return new Date(resultB.dates[0].startDate) - new Date(resultA.dates[0].endDate)
						} else {
							return new Date(resultB.dates[0].endDate) - new Date(resultA.dates[0].startDate)
						}
					}).concat(locWithoutDates)
			
				} else {
					sortedResults = locWithDates.slice().sort((resultA, resultB) => {
						if (resultA.dates[0].endDate && resultB.dates[0].endDate){
							return new Date(resultA.dates[0].endDate) - new Date(resultB.dates[0].endDate)
						} else if (!resultA.dates[0].endDate && !resultB.dates[0].endDate) {
							return new Date(resultA.dates[0].startDate) - new Date(resultB.dates[0].startDate)
						} else if (!resultA.dates[0].endDate) {
							return new Date(resultA.dates[0].startDate) - new Date(resultB.dates[0].endDate)
						} else {
							return new Date(resultA.dates[0].endDate) - new Date(resultB.dates[0].startDate)
						}
					}).concat(locWithoutDates)
				}	
			}
		}

		let pinIndexRef = [];
				
		sortedResults.forEach((item, index) => {
			if (index >= 10 * page && index < 10 * (page + 1) && !pinIndexRef.find(pin => pin.placeId === item.placeId)){
				pinIndexRef.push(item)
			}
		})
		
		this.setState({
			    pinIndexRef: pinIndexRef,
			  	sortedResults: sortedResults,
			  
		})


		if (sortedResults.length && currentUser.id && !currentUser.seenExplanation){
			console.log('in here about to run')
				this.setState({run: true}, () => {
					this.handleUpdateUser()
				})

		}


	}

	handleJoyrideCallback(data){
	    const { action, index, status, type } = data;

	    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
	      // Update state to advance the tour
	      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
	    }
	    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
	      // Need to set our running state to false, so we can restart if we click start again.
	      this.setState({ run: false });
	    }

 	};

	async handleUpdateUser(){
		const { currentUser, setCurrentUser, client } = this.props
		const userPatch = { seenExplanation: true } 

		const newCurrentUser = Object.assign({}, currentUser, userPatch)

			try {
				
				await client.mutate({
					mutation: UPDATE_USER,
					variables: {
						input: {
							id: currentUser.id,
							userPatch: userPatch
							}	
						}
				})


				await setCurrentUser({
              	  variables: newCurrentUser
	            })
				
			} catch {
				console.log('error')
			}
	}

	async handleGoToResult(link, resultType, result){
		const { client, currentUser, destinationPlace } = this.props
		try {
			const viewerId = currentUser.id > 0 ? currentUser.id : 0
			if (resultType === 'user'){
				await client.mutate({
					mutation: SEARCH_PROFILE_CLICK,
					variables: {
						input: {
							profUserId: result.id, 
							viewerId: viewerId
						}
					
					}
				})
				if (process.env.NODE_ENV !== 'development'){
					await analytics.logEvent('search_click', {
						type: 'user',
						userId: result.id
					});
				}

			} else if (resultType === 'location'){
				if (result.locationId){
					await client.mutate({
					mutation: SEARCH_LOCATION_CLICK,
						variables: {
							input: {
								locId: result.locationId,
								locationUserId: result.userId, 
								viewerId: viewerId
							}
						
						}
					})
				}
				
				if (viewerId){
					await client.mutate({
						mutation: RECORD_SEARCH,
						variables: {
							input: {
								searchType: 'LOCATIONS', 
								searchName: result.fullName, 
								searchLat: result.lat, 
								searchLng: result.lng, 
								searchLocality: result.locality, 
								searchAdminLevel1: result.adminLevel1, 
								searchCountry: result.country, 
								searchPlaceId: result.placeId
							}
						
						}
					})
				}
				if (process.env.NODE_ENV !== 'development'){
					await analytics.logEvent('search_click', {
						type: 'location',
						view: result.locationId ? 'individual' : 'overall',
						locationId: result.locationId, 
						locUserId: result.userId,
						locName: result.fullName, 
						locLat: result.lat, 
						locLng: result.lng, 
						locLocality: result.locality, 
						locAdminLevel1: result.adminLevel1, 
						locCountry: result.country, 
						locPlaceId: result.placeId, 
						searchPlaceId: destinationPlace.placeId, 
						searchLocality: destinationPlace.locality,
						searchAdminLevel1: destinationPlace.adminLevel1, 
						searchCountry: destinationPlace.country
					});
				}
				
			} else if (resultType === 'attraction'){
				if (result.id){
					await client.mutate({
						mutation: SEARCH_ATTRACTION_CLICK,
						variables: {
							input: {
								attrId: result.id, 
								locId: result.locationId,
								locationUserId: result.userId, 
								viewerId: viewerId
							}
						
						}
					})
				}
				if (viewerId){
					await client.mutate({
						mutation: RECORD_SEARCH,
						variables: {
							input: {
								searchType: 'ATTRACTIONS', 
								searchName: result.name, 
								searchLat: result.lat, 
								searchLng: result.lng, 
								searchLocality: result.locality, 
								searchAdminLevel1: result.adminLevel1, 
								searchCountry: result.country, 
								searchPlaceId: result.placeId
							}
						}
					})
				}
				if (process.env.NODE_ENV !== 'development'){
					await analytics.logEvent('search_click', {
						type: 'location',
						view: result.id ? 'individual' : 'overall',
						attractionId: result.id, 
						attrUserId: result.userId,
						attrName: result.fullName, 
						attrLat: result.lat, 
						attrLng: result.lng, 
						attrLocality: result.locality, 
						attrAdminLevel1: result.adminLevel1, 
						attrCountry: result.country, 
						attrPlaceId: result.placeId, 
						searchPlaceId: destinationPlace.placeId, 
						searchLocality: destinationPlace.locality,
						searchAdminLevel1: destinationPlace.adminLevel1, 
						searchCountry: destinationPlace.country
					});
				}
				
			}

		} catch {
			console.log('error')
		}
		history.push(link)
	}

	async handleSearch(newSaved){
		console.log('searching!!')

		const { client, currentUser } = this.props
		const { attrTypes, tags, destinationType, destinationPlace, destinationValue, userValue, view } = this.state
	 

		let res;

		if (view === 'user' && userValue){
			res = await client.query({
		    	query: SEARCH_USERS,
		    	variables: { 
		    		searchValue: userValue, 
		    		currentUserId: currentUser.id ? currentUser.id : 0 
		    	}
		  	})

		 	
		  this.setState({
		  	results: res.data.searchUsers.nodes,
		  	hasSearched: true, 
		  	page: 0
		  	//resultsType: 'users' 
		  })
		} else if (view === 'destination' && (destinationPlace || destinationType === 'attractions' && destinationValue)){
			if (destinationType === 'attractions'){
				res = await client.query({
			    	query: SEARCH_ATTRACTIONS,
			    	variables: { 
			    		searchValue: destinationValue,
			    		searchLocality: destinationPlace.locality,
			    		searchAdminLevel1: destinationPlace.adminLevel1,
			    		searchCountry: destinationPlace.country,
			    		currentUserId: currentUser.id ? currentUser.id : 0
			    	 },
			    	 fetchPolicy: newSaved ? 'network-only' : 'cache-first'
		  		})
				
				await this.setState({
					  	results: res.data.searchAttractions.nodes, 
					  	hasSearched: true, 
					  	page: 0
				})
				this.sortResults()
			} else {
				res = await client.query({
			   	 	query: SEARCH_LOCATIONS,
			    	variables: { 
			    		searchLocality: destinationPlace.locality,
			    		searchAdminLevel1: destinationPlace.adminLevel1,
			    		searchCountry: destinationPlace.country,
			    		currentUserId: currentUser.id ? currentUser.id : 0
			    	}
		 		 })

				
				await this.setState({
					  	results:  res.data.searchLocations.nodes, 
					  	hasSearched: true,
					  	page: 0
				})
		  		this.sortResults()

			}

		} else {
			console.log({stateError: "There was an error with the search."})
		}


	}

	handleSelectPin(pin){
		const { destinationType } = this.state
		if (destinationType === 'attractions'){
			history.push(`/attractionView/${pin.placeId}`)
		} else {
			history.push(`/locationView/${pin.placeId}`)
		}
	}

	renderHeader(){
		const { 
			attrAndUp,
			attrContent,
			attrSort, 
			attrStars,
			attrTypes, 
			destinationType, 
			hoverStars,
			locSort,
			placeValue, 
			showMoreFilters, 
			tags
		} = this.state
		return <SearchHeader 
			attrAndUp={attrAndUp}
			attrContent={attrContent}
			attrSort={attrSort}
			attrStars={attrStars}
			attrTypes={attrTypes}
			destinationType={destinationType}
			handleChangeSort={this.handleChangeSort}
			handleHover={this.handleHover}
			handleSelectAttrContent={this.handleSelectAttrContent}
			handleSelectAttrType={this.handleSelectAttrType}
			handleSelectStar={this.handleSelectStar}
			handleSelectTag={this.handleSelectTag}
			handleSetDestinationType={this.handleSetDestinationType}
			handleSetDestSearchValue={this.handleSetDestSearchValue}
			handleToggleAndUp={this.handleToggleAndUp}
			handleToggleFilters={this.handleToggleFilters}
			hoverStars={hoverStars}
			locSort={locSort}
			placeValue={placeValue}
			showMoreFilters={showMoreFilters}
			tags={tags}
		/>
	}

	
	renderSuggestedFollow(){
		return <Query 
			query={GET_SUGGESTED_FOLLOW_USERS}
		>
			{({data, loading, error}) => { 
				if (loading) { return <div>Loading...</div> }
				if (error) { return <div>Error</div> }

				const suggestedUsers = data.suggestedFollowUsers.nodes

				return <SuggestedFollows suggestedUsers={suggestedUsers} />	 
			
					
			}}
		</Query>
	
	}

	renderSearchResults(){
		const { 
			attrContent, 
			attrAndUp,
			attrStars,
			destinationType, 
			destinationPlace, 
			hoverStars,
			results, 
			page,
			pinIndexRef,
			savedIds,
			showMoreFilters,
			sortedResults,
			tags,
			unsavedIds,
			type, 
			view 
		} = this.state
		const { currentUser } = this.props

		return <div>
			{destinationType === 'attractions' && <SearchFilters 
				attrAndUp={attrAndUp}
				attrContent={attrContent}
				attrStars={attrStars}
				destinationType={destinationType}
				handleHover={this.handleHover}
				handleSelectAttrContent={this.handleSelectAttrContent}
				handleSelectStar={this.handleSelectStar}
				handleSelectTag={this.handleSelectTag}
				handleToggleAndUp={this.handleToggleAndUp}
				handleToggleFilters={this.handleToggleFilters}
				hoverStars={hoverStars}
				showMoreFilters={showMoreFilters}
				tags={tags}
			/>}
			<SearchResults 
				attrContent={attrContent}
				currentUser={currentUser}
				destinationPlace={destinationPlace}
				destinationType={destinationType}
				handleGoToResult={this.handleGoToResult}
				handlePageChange={this.handlePageChange}
				handleToggleSavedAttraction={this.handleToggleSavedAttraction}
				handleToggleUnsavedAttraction={this.handleToggleUnsavedAttraction}
				handleSearch={this.handleSearch}
				handleSelectPin={this.handleSelectPin}
				page={page}
				pinIndexRef={pinIndexRef}
				results={results}
				savedIds={savedIds}
				unsavedIds={unsavedIds}
				sortedResults={sortedResults}
				tags={tags}
				type={type}
				view={view}
			/>
		</div>
	}


		
	render() {
		const { attrTypes, hasSearched, results, displayButtonType, locValue, run, stateError, steps, view } = this.state
		const { currentUser, destinationSearch } = this.props


		return (
			<div className="searchContainer">
				<Joyride 
					callback={this.handleJoyrideCallback}
					continuous={true}
					showProgress={true}
          			showSkipButton={true}
					steps={steps} 
					run={run}

				/>
			   {stateError && <div>{stateError}</div>}
				{view !== 'user'  && hasSearched && this.renderHeader()}
				{results.length ? this.renderSearchResults() : 
					<div>{hasSearched ? <div className="noSearchResults">There are no results for this search term.</div> : <Search />}</div>
				}

				
				{view === 'user' && !!currentUser.id && this.renderSuggestedFollow()}		
						
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	graphql(getDestinationSearch, {
		props: ({loading, data: { destinationSearch }}) => ({
			loading,
			destinationSearch
		})
	}), 
	graphql(getUserSearch, {
		props: ({loading, data: { userSearch }}) => ({
			loading,
			userSearch
		})
	}), 
	graphql(getSearchView, {
		props: ({loading, data: { searchView }}) => ({
			loading,
			searchView
		})
	}),
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
	graphql(setDestinationSearchType, {name: 'setDestinationSearchType'}),
	graphql(setDestinationSearchNeedsRefresh, {name: 'setDestinationSearchNeedsRefresh'}),
	graphql(setDestinationSearchAttrContent, {name: 'setDestinationSearchAttrContent'}),
	graphql(setDestinationSearchAttrStars, {name: 'setDestinationSearchAttrStars'}),
	graphql(setDestinationSearchAttrTypes, {name: 'setDestinationSearchAttrTypes'}),
	graphql(setDestinationSearchAttrTags, {name: 'setDestinationSearchAttrTags'}),
	graphql(setDestinationSearchAttrAndUp, {name: 'setDestinationSearchAttrAndUp'}),
	graphql(setDestinationSearchAttrSort, {name: 'setDestinationSearchAttrSort'}),
	graphql(setDestinationSearchAttrSortDir, {name: 'setDestinationSearchAttrSortDir'}),
	graphql(setDestinationSearchLocSort, {name: 'setDestinationSearchLocSort'}),
	graphql(setDestinationSearchLocSortDir, {name: 'setDestinationSearchLocSortDir'}),
	graphql(setDestinationSearchValue, {name: 'setDestinationSearchValue'}),
)(SearchContainer);
