import React from "react";

import "../stylesheets/components/SinglePicAdd.css"

import initialPic from '../assets/globe.jpg'


const SinglePicAdd = props => {
	const {
		error,
		closeText,
		handleChange,
		handleClose,
		loading, 
		title, 
		view
	} = props;
	return (
		<div className={view === "signup" ? "singlePicAdd initialScreen" : "popup popupXL singlePicAdd"}>
			<div className={view === "signup" ? "initialScreenLeft" : "popupContent"}>
				<div className="initialItems">
					{view === 'signup' ? <div className="headerHolder initialHeaderHolder">
						<div className="mainHeader initialHeader">add profile picture</div>
						<div className="headerUnderline initialHeaderUnderline"></div>
					</div> :
					<div className="secondaryHeader">{title}</div>}
					{loading
						? <div> loading... this may take a bit </div>
						: <form className="form">
								<label className="primaryButton singleFormButton">Choose File
									<input className="pictureInput" type="file" name="file" accept="image/*" onChange={e => handleChange(e)}/>
								</label>
							</form>}

					
					{error && <div>There was an error</div>}
					<div className="formButtons singleFormButton">
			         	<button className="secondaryButton singleFormButton" disabled={loading} onClick={handleClose}>{closeText ? closeText : 'Cancel'}</button>
		          	</div>
		          	{view === 'signup' && <div className="progressDots">
			         	<div className="progressDot"></div>
			         	<div className="progressDot"></div>
			         	<div className="progressDot"></div>
			         	<div className="progressDot progressDotFilled"></div>
			         	<div className="progressDot"></div>
			         	<div className="progressDot"></div>
					  		
		      		  </div>}
		         </div>
			</div>
			{view === 'signup' && <div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="profPicPicture"/>
			</div>}
		</div>
	);
};

export default SinglePicAdd;
