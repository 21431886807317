import gql from "graphql-tag";

const CHECK_ENTRY_CODE = gql`
query checkEntryCode($enteredCode: String!){
  checkEntryCode(enteredCode: $enteredCode)
}`


 

const GET_ALL_OVERALL_LOCATIONS = gql `
query allOverallLocations{
  allOverallLocations{
    nodes {
      fullName
      locality
      adminLevel1
      country
      placeId
      lat 
      lng
    }
  }
}`


const GET_ALL_USER_MESSAGES = gql`
query allUserMessages{
  allUserMessages{
   nodes{
    id 
    messengerId 
    senderId
    receiverId
    firstName
    lastName
    publicUrl
    locationId 
    fullName
    locality
    adminLevel1
    country
    messageContent 
    seen
    read
    objectionable
    createdAt
   }
  }
}`

const GET_ATTRACTION_COMMENTS = gql`
query attractionComments($attrId: Int!){
  attractionComments(attrId: $attrId){
   nodes{
    attrId
    userId 
    firstName
    lastName
    publicUrl
    comment 
    edited
    objectionable
    createdAt
   }
  }
}`

const GET_ATTRACTION_PICTURES = gql`
query attractionPictures($attrId: Int!){
  attractionPictures(attrId: $attrId){
   nodes{
      id
      locationId
      ownerUserId
      publicUrl
      attractionId
      attractionName
      pushToken
      instaAuthorName
      instaUrl
      mainPicture
      tags {
          id 
          userId
          firstName
          lastName
          leftOffset
          leftPercent
          topPercent
      }
    }
  }
}`

const GET_ATTRACTION_BY_ID = gql `
query attractionById ($id: Int!){
  attractionById(id: $id){
      id
      locationId 
      name
      review
      stars
      attractionType
      lat 
      lng
      placeId
  }
}
`


const GET_ATTRACTION_VIEW_BY_ID = gql `
query attractionViewById ($attrId: Int!, $currentUserId: Int!){
  attractionViewById(attrId: $attrId, currentUserId: $currentUserId){
      id
      userId
      locationId 
      firstName
      lastName
      pushToken
      name
      review
      tips
      visibility
      stars
      attractionType
      dollars
      website
      lat 
      lng
      country
      locality
      adminLevel1
      locationName
      placeId
      locationPlaceId
      savedId
      saveCount
      objectionable
      visitors {
        id 
        locationId
        userId
        firstName
        lastName
        publicUrl
        stars
      }
      tags {
        id 
        tag 
        attractionId
      }
  }
}
`

//this has to be person or else it grabs it from the state
const GET_CURRENT_PERSON = gql`
{
  currentPerson {
    id
    firstName
    lastName
    currentCity
    currentCityLat
    currentCityLng
    about
    birthday
    publicity
    seenExplanation
    seenSaveExplanation
    seenLocExplanation
    seenProfExplanation
    seenInstaConnection
    locationViews
    pageViews
    publicUrl
    locUploadNumber
    attrUploadNumber
  }
}
`

const GET_EXPLORE_LOCATIONS = gql `
  query exploreLocations ($explorerId: Int!){
  exploreLocations(explorerId: $explorerId){
    nodes{
      id
      fullName
      locality
      adminLevel1
      country
      userId
      firstName
      lastName
      publicUrl
      score
    }
      
  }
}
`
const GET_EXPLORE_ATTRACTIONS = gql `
  query exploreAttractions ($explorerId: Int!, $pageNumber: Int){
  exploreAttractions(explorerId: $explorerId, pageNumber: $pageNumber){
    nodes{
      id
      placeId
      name
      locality
      adminLevel1
      country
      userId
      firstName
      lastName
      publicUrl
      pushToken
      locId 
      locPlaceId 
      locLocality
      locAdminLevel1
      locCountry
      review 
      tips 
      stars 
    }
      
  }
}
`

const GET_EXPLORE_LOCATIONS_V2 = gql `
  query exploreLocationsV2 ($explorerId: Int!, $pageNumber: Int){
  exploreLocationsV2(explorerId: $explorerId, pageNumber: $pageNumber){
    nodes{
      id
      fullName
      locality
      adminLevel1
      country
      userId
      firstName
      lastName
      publicUrl
      pushToken
      attrId 
      attrName
      attrReview 
      attrTips 
      attrStars
      tipId
      tipCategory
      tipContent
      recentDate
      exploreType
    }
      
  }
}
`

const GET_FOLLOWER_INFO = gql`
query followerInfo($profileId: Int){
  followerInfo(profileId: $profileId){
    id
    followerId
    followeeId
    status
  }
}
`

const GET_FOLLOWING_INFO = gql`
query followingInfo($profileId: Int, $currentUserId: Int){
  followingInfo(profileId: $profileId, currentUserId: $currentUserId){
    id
    followerId
    followeeId
    status
  }
}
`

const GET_FOLLOWERS_BY_DATE = gql `
 query followersByDate{
    followersByDate{
      nodes{
      id
      firstName
      lastName
      publicUrl
      status
      seen
      followerId
    }
    }
  }
`

const GET_FOLLOWERS_ALPHABETIZED = gql `
 query followersAlphabetized($profUserId: Int!){
    followersAlphabetized(profUserId: $profUserId){
      nodes{
      id
      followerId
      firstName
      lastName
      pushToken
      publicUrl
      status
      seen
    }
    }
  }
`

const GET_FOLLOWING_ALPHABETIZED = gql `
 query followingAlphabetized($profUserId: Int!){
    followingAlphabetized(profUserId: $profUserId){
      nodes{
      id
      followeeId
      firstName
      lastName
      pushToken
      publicUrl
      status
    }
    }
  }
`


const GET_FOLLOWING_OVERALL_LOCATIONS = gql `
query followingOverallLocations{
  followingOverallLocations{
    nodes {
      fullName
      locality
      adminLevel1
      country
      placeId
      lat 
      lng
    }
  }
}`

const GET_GROUPED_TRIP_LOCATIONS = gql `
 query groupedTripLocations($mainTripLocId: Int!){
    groupedTripLocations(mainTripLocId: $mainTripLocId){
      nodes{
        id
        fullName
        locality
        adminLevel1
        country

      }
    }
  }
`

const GET_ITINERARY_ITEMS = gql `
query itineraryItems ($tripLocId: Int, $selectedTripDate: Date){
  itineraryItems(tripLocId: $tripLocId, selectedTripDate: $selectedTripDate){
    nodes {
        id
        tripLocationId
        tripGeneralAttractionId 
        name 
        placeId
        lat 
        lng
        title 
        note 
        tripDate 
        startTime
        endTime
        orderNumber

    }
  }
}
`

const GET_LOCATION_ATTRACTIONS = gql `
query locationAttractions($locId: Int, $currentUserId: Int){
  locationAttractions(locId: $locId, currentUserId: $currentUserId){
    nodes {
      id
      userId
      locationId 
      firstName
      lastName
      pushToken
      name
      review
      tips
      stars
      savedId
      saveCount
      attractionType
      lat 
      lng
      country
      locality
      adminLevel1
      locationName
      placeId
      locationPlaceId
      visibility
      objectionable
      visitors {
        id 
        locationId
        userId
        firstName
        lastName
        publicUrl
        stars
      }
      tags {
        id 
        tag
        attractionId
      }
   }
  }
}
`


const GET_LOCATION_SAVED_ATTRACTIONS = gql `
query locationSavedAttractions($locPlaceId: String!){
  locationSavedAttractions(locPlaceId: $locPlaceId){
    nodes {
      overallStars
      name
      attractionTypes
      amount
      placeId
      lat 
      lng
      reviews {
        id
        locationName
        attractionType
        firstName
        lastName
        name 
        stars
        review 
        tips
        savedId
        placeId
        lat 
        lng
        country 
        locality
        adminLevel1
        userId
        locationId
        locationPlaceId
        objectionable
      } 
     
   }
  }
}
`


const GET_LOCATION_AND_ATTRACTIONS = gql `
query locationById($id: Int!){
  locationById(id: $id) {
      id
      userId
      locality
      adminLevel1
      country
      fullName
      locality
      adminLevel1
      country
      overallReview
      lat 
      lng
      objectionable
    attractionsByLocationId {
      nodes {
      id
      locationId
      attractionType
      placeId
      name 
      stars
      review
      lat
      lng
      objectionable
      }
    }
  }
}
`



const GET_LOCATION_PICTURE_COMMENTS = gql`
query locationPictureComments($locPicId: Int!){
  locationPictureComments(locPicId: $locPicId){
   nodes{
    picId
    userId 
    firstName
    lastName
    publicUrl
    comment 
    edited
    objectionable
    createdAt
   }
  }
}`

const GET_LOCATION_PICTURES = gql `
query locationPictures($locId: Int!){
    locationPictures(locId: $locId){
      nodes{
        id
        locationId
        ownerUserId
        pushToken
        publicUrl
        attractionId
        attractionName
        mainPicture
        instaAuthorName
        instaUrl
        tags {
          id 
          userId
          firstName
          lastName
          leftOffset
          leftPercent
          topPercent
        }
      }
    }
  }
`

const GET_LOCATION_TIPS = gql `
query locationTips ($locId: Int!){
  locationTips(locId: $locId){
    nodes{
      id
      locationId
      tip
      category
      createdAt 
      objectionable
    }
  }
}
`


const GET_LOCATION_VIEW_AND_DATES = gql`
query locationViewAndDates ($locId: Int!){
  locationViewAndDates(locId: $locId){
    id 
    userId
    publicUrl
    firstName
    lastName
    overallReview
    locality
    adminLevel1
    country
    fullName
    locality
    adminLevel1
    country
    lat
    lng
    placeId
    objectionable
    dates {
      id
      startDate
      endDate
    }
  }
}`

const GET_MY_TRIPS = gql`
query myTrips{
    myTrips{
      nodes{
        id
        name
        startDate 
        endDate
        creatorId
      }  
  }
}`



const GET_NOTIFICATIONS_BY_DATE = gql`
query notificationsByDate{
    notificationsByDate{
      nodes{
        type
        seen
        userId
        publicUrl
        firstName
        lastName
        tripId
        tripName
        tripLocId
        tripLocName
        saveCount
        attractionName
        attractionId
        locationName
        locationId
        pictureId,
        pictureLocationName, 
        pictureLocationId, 
        pictureAttractionName, 
        pictureAttractionId,
        pictureUrl 
        taggedUserId
        tags {
          id
          firstName
          lastName
          userId
          leftOffset
          leftPercent
          topPercent
          __typename
        }
        communicationsContent
        tripUploadType
        tripAddComment
        __typename
        createdAt

      }
  }
}`


const GET_OVERALL_ATTRACTION_PICTURES = gql `
query overallAttractionPictures ($attrPlaceId: String){
  overallAttractionPictures(attrPlaceId: $attrPlaceId){
    nodes{
      id
      attractionId
      locationId
      userId
      firstName
      lastName
      pushToken
      instaAuthorName
      instaUrl
      publicUrl 
    }
  }
}
`

const GET_OVERALL_ATTRACTION_PICTURES_ALL = gql `
query overallAttractionPicturesAll ($attrPlaceId: String, $currentUserId: Int){
  overallAttractionPicturesAll(attrPlaceId: $attrPlaceId, currentUserId: $currentUserId){
    nodes{
      id
      attractionId
      locationId
      userId
      firstName
      lastName
      pushToken
      instaAuthorName
      instaUrl
      publicUrl 
    }
  }
}
`


const GET_OVERALL_ATTRACTION_REVIEWS = gql `
query overallAttractionReviews ($attrPlaceId: String){
  overallAttractionReviews(attrPlaceId: $attrPlaceId){
    nodes{
      id
      locationId
      locationPlaceId
      review 
      tips
      stars
      placeId
      lat
      lng
      country
      locality
      adminLevel1
      name
      saveCount
      userId
      firstName
      lastName
      pushToken
      publicUrl
      attractionType
      objectionable
      tags {
          id 
          tag
        }
      comments {
        id 
        attractionId
        userId
        firstName
        lastName
        publicUrl
        comment 
        edited
        objectionable
        createdAt
      }
      savedId

    }
  }
}
`

const GET_OVERALL_ATTRACTION_REVIEWS_ALL = gql `
query overallAttractionReviewsAll ($attrPlaceId: String, $currentUserId: Int){
  overallAttractionReviewsAll(attrPlaceId: $attrPlaceId, currentUserId: $currentUserId){
    nodes{
      id
      review
      tips 
      stars
      userId
      firstName
      lastName
      pushToken
      publicUrl
      attractionType
      saveCount
      objectionable
      tags {
          id 
          tag
        }
      comments {
        id 
        attractionId
        userId
        firstName
        lastName
        publicUrl
        comment 
        edited
        objectionable
        createdAt
      }

    }
  }
}
`

const GET_OVERALL_ATTRACTION_VISITORS = gql `
query overallAttractionVisitors ($attrPlaceId: String){
  overallAttractionVisitors(attrPlaceId: $attrPlaceId){
    nodes{
      id
      locationId
      firstName
      lastName
      publicUrl
      attractionType
    }
  }
}
`

const GET_OVERALL_ATTRACTION_BY_PLACE_ID = gql `
query overallAttractionByPlaceId ($attrPlaceId: String){
 overallAttractionByPlaceId(attrPlaceId: $attrPlaceId){
      name 
      locality
      adminLevel1
      country
      lat 
      lng
      dollars
      website
  }
}
`

const GET_OVERALL_LOCATION = gql`
query overallLocation ($locationPlaceId: String!){
    overallLocation(locationPlaceId: $locationPlaceId){
      fullName
      locality
      adminLevel1
      country
      lat
      lng
  }
}`



const GET_OVERALL_LOCATION_ATTRACTIONS = gql `
query overallLocationAttractions ($locPlaceId: String){
  overallLocationAttractions(locPlaceId: $locPlaceId){
    nodes{
      overallStars
      name
      attractionTypes
      amount
      placeId
      lat 
      lng
      reviews {
        id
        locationName
        attractionType
        firstName
        lastName
        pushToken
        name 
        stars
        review 
        tips
        placeId
        lat 
        lng
        country
        locality
        adminLevel1
        userId
        saveCount
        locationId
        locationPlaceId
        objectionable
      } 
      savedAttractions {
        id 
        savedId 
      }
    }
  }
}
`

const GET_OVERALL_LOCATION_ATTRACTIONS_ALL = gql `
query overallLocationAttractionsAll ($locPlaceId: String, $currentUserId: Int){
  overallLocationAttractionsAll(locPlaceId: $locPlaceId, currentUserId: $currentUserId){
    nodes{
      overallStars
      name
      attractionTypes
      amount
      placeId
      lat 
      lng
      reviews {
        id
        locationName
        attractionType
        firstName
        lastName
        pushToken
        name 
        stars
        review 
        tips
        placeId
        lat 
        lng
        country
        locality
        adminLevel1
        saveCount
        userId
        locationId
        locationPlaceId
        objectionable
      } 
      savedAttractions {
        id 
        savedId 
      } 
     
    }
  }
}
`
const GET_OVERALL_LOCATION_BY_PLACE_ID = gql `
query overallLocationByPlaceId ($locPlaceId: String){
 overallLocationByPlaceId(locPlaceId: $locPlaceId){

      id 
      locality
      adminLevel1
      country
      fullName
      lat
      lng
      placeId
    
 }
}
`

const GET_OVERALL_LOCATION_PICTURES = gql `
query overallLocationPictures ($locPlaceId: String){
  overallLocationPictures(locPlaceId: $locPlaceId){
    nodes{
      id
      attractionId
      attractionName
      locationId
      userId
      firstName
      lastName
      pushToken
      instaAuthorName
      instaUrl
      publicUrl 
    }
  }
}
`


const GET_OVERALL_LOCATION_PICTURES_ALL = gql `
query overallLocationPicturesAll ($locPlaceId: String, $currentUserId: Int){
  overallLocationPicturesAll(locPlaceId: $locPlaceId, currentUserId: $currentUserId){
    nodes{
      id
      attractionId
      attractionName
      locationId
      userId
      firstName
      lastName
      pushToken
      instaAuthorName
      instaUrl
      publicUrl
    }
  }
}
`



const GET_OVERALL_LOCATION_TIPS = gql `
query overallLocationTips ($locationPlaceId: String){
  overallLocationTips(locationPlaceId: $locationPlaceId){
    nodes{
      id
      locationId
      firstName
      lastName
      publicUrl
      tip
      category
      firstName
      lastName
      publicUrl
      userId
      objectionable
      placeId
    }
  }
}
`


const GET_OVERALL_LOCATION_TIPS_ALL = gql `
query overallLocationTipsAll ($locationPlaceId: String, $currentUserId: Int){
  overallLocationTipsAll(locationPlaceId: $locationPlaceId, currentUserId: $currentUserId){
    nodes{
      id
      locationId
      firstName
      lastName
      publicUrl
      tip
      category
      firstName
      lastName
      publicUrl
      placeId
      userId
      objectionable
    }
  }
}
`

const GET_OVERALL_LOCATION_VISITORS = gql `
query overallLocationVisitors ($locationPlaceId: String){
  overallLocationVisitors(locationPlaceId: $locationPlaceId){
    nodes{
      id
      userId
      firstName
      lastName
      publicUrl
      dates {
        startDate
        endDate
        id
      }
    }
  }
}
`




const GET_SAVED_ATTRACTIONS = gql `
 query savedAttractionViews{
    savedAttractionViews{
      nodes{
        id
        locationName
        locality
        adminLevel1
        country
        attractionType
        firstName
        lastName
        name 
        stars
        review 
        tips
        placeId
        lat
        lng
        locationUserId
        savedId
        locationId
        userId
        locationPlaceId
        objectionable
      }
    }
  }
`

const GET_SAVED_LOCATIONS = gql `
 query savedLocations{
    savedLocations{
      nodes{
        cityName
        lat
        lng
        reviewCount
        placeId
      }
    }
  }
`


const GET_SUGGESTED_FOLLOW_USERS = gql`
query suggestedFollowUsers{
  suggestedFollowUsers{
   nodes{
    id 
    firstName
    lastName
    publicUrl
    pushToken
   }
  }
}`

const GET_SUGGESTED_FROM_CONTACTS = gql`
query suggestedFromContacts($contacts: [String]){
  suggestedFromContacts(contacts: $contacts){
   nodes{
    id 
    firstName
    lastName
    publicUrl
    pushToken
   }
  }
}`


const GET_TRIP_BY_ID = gql `
query tripById ($id: Int!){
  tripById(id: $id){
      id
      name 
      startDate
      endDate
      creatorId
      voteAnonymity
  }
}
`

const GET_TRIP_COMMENTS = gql`
query tripComments($viewedTripId: Int!){
  tripComments(viewedTripId: $viewedTripId){
   nodes{
    tripId 
    userId 
    firstName
    lastName
    publicUrl
    comment 
    edited
    createdAt
   }
  }
}`

const GET_TRIP_GENERAL_ATTRACTIONS = gql`
query tripGeneralAttractions($tripLocId: Int!){
  tripGeneralAttractions(tripLocId: $tripLocId){
   nodes{
    id
    placeId
    name
    lat
    lng
    attractionTypes
    votes {
      voteDirection
      userId
      firstName
      lastName
      publicUrl
    }
     reviews {
        id
        locationName
        attractionType
        firstName
        lastName
        name 
        stars
        review
        tips 
        placeId
        userId
        locationId
        locationPlaceId
      } 
    userVote
    userVoteId
    savedAttractions {
        id 
        savedId 
      } 
   }
  }
}`


const GET_TRIP_GENERAL_LOCATIONS = gql`
query tripGeneralLocations($viewedTripId: Int!){
  tripGeneralLocations(viewedTripId: $viewedTripId){
   nodes{
    id
    placeId
    fullName
    locality
    adminLevel1
    country
    lat
    lng
    dates {
      id
      startDate
      endDate
    }
   }
  }
}`


const GET_TRIP_ITINERARY_ITEMS = gql`
query tripItineraryItems($viewedTripId: Int!){
  tripItineraryItems(viewedTripId: $viewedTripId){
   nodes{
     id
     tripLocationId
     tripGeneralAttractionId 
     name 
     placeId
     lat 
     lng
     title 
     note 
     tripDate 
     startTime
     endTime
     orderNumber
   }
  }
}`


const GET_TRIP_GENERAL_LOCATION_BY_ID = gql `
query tripGeneralLocationById ($id: Int!){
  tripGeneralLocationById(id: $id){
      id
      tripId 
      fullName 
      locality
      adminLevel1
     country
      lat 
      lng
  }
}
`


const GET_TRIP_LOCATION_AND_DATES = gql `
query tripLocationAndDates ($tripLocId: Int!){
  tripLocationAndDates(tripLocId: $tripLocId){
      id
      placeId
      tripName 
      fullName 
      locality
      adminLevel1
      country
      lat 
      lng
      groupedTripLocId
      dates {
        id
        startDate
        endDate
      }
  }
}
`

const GET_TRIP_LOCATION_COMMENTS = gql`
query tripLocationsComments($tripLocId: Int!){
  tripLocationComments(tripLocId: $tripLocId){
   nodes{
    tripLocId
    userId 
    firstName
    lastName
    publicUrl
    comment 
    edited
    createdAt
   }
  }
}`

const GET_TRIP_LOCATION_DATES = gql `
query tripLocationDates ($tripLocId: Int!){
  tripLocationDates(tripLocId: $tripLocId){
    nodes {
        id
        tripLocationId
        startDate
        endDate
    }
  }
}
`


const GET_TRIP_ATTRACTION_EXISTS = gql`
query tripAttractionExists($attrId: Int!, $tripLocId: Int!, $attrType: AttractionType){
  tripAttractionExists(attrId: $attrId, tripLocId: $tripLocId, attrType: $attrType)
}`

const GET_TRIP_LOCATION_TIP_EXISTS = gql`
query tripLocationTipExists($tripLocId: Int!, $tipId: Int!){
  tripLocationTipExists(tripLocId: $tripLocId, tipId: $tipId)
}`



const GET_TRIP_LOCATION_TIPS = gql`
query tripLocationTips ($tripLocId: Int!){
    tripLocationTips(tripLocId: $tripLocId){
      nodes {
        id 
        locationId
        tip 
        category
        userId
        firstName
        lastName
        publicUrl
      }
  }
}`

const GET_TRIP_TRANSPORTATIONS = gql`
query tripTransportations($viewedTripId: Int!){
  tripTransportations(viewedTripId: $viewedTripId){
   nodes{
    id
    tripId
    userId
    departureTime
    departureDate
    arrivalTime
    arrivalDate
    transportationType
    transportationCompany
    transportationNumber
    departureCity
    arrivalCity
    firstName
    lastName
    publicUrl
   }
  }
}`

const GET_TRIP_USERS = gql`
query tripUsers($viewedTripId: Int!){
  tripUsers(viewedTripId: $viewedTripId){
   nodes{
    id
    userId
    firstName
    lastName
    publicUrl
   }
  }
}`

const GET_UNSEEN_FOLLOWERS = gql`
query unseenFollowers{
   unseenFollowers
}`


const GET_UNSEEN_MESSAGES = gql`
query unseenMessages{
  unseenMessages
}`

const GET_UNSEEN_NOTIFICATIONS = gql`
query unseenNotifications{
  unseenNotifications
}`

const GET_UPCOMING_TRIPS = gql`
query upcomingTrips($profUserId: Int!){
  upcomingTrips(profUserId: $profUserId){
   nodes{
      id 
       startDate 
       endDate 
       name 
       genearlLocId 
       locality 
       adminLevel1 
       country
       tripLocId
   }
  }
}`




const GET_USER_AND_LOCATIONS = gql`
query userById($id: Int!){
  userById(id: $id) {
    id
    firstName
    lastName
    currentCity
    currentCityLat
    currentCityLng
    about
    birthday
    publicity
    locationsByUserId {
      nodes {
      id
      placeId
      locality
      adminLevel1
      country
      fullName
      lat
      lng
      }
    }
    profilePicturesByUserId {
      nodes {
      id
      publicUrl
      caption
      updatedAt
      mainPicture
      }
    }
  }
}`

const GET_USER_BY_ID = gql`
query userById($id: Int!){
  userById(id: $id) {
    id
    firstName
    lastName
    currentCity
    currentCityLat
    currentCityLng
    about
    birthday
    publicity
    pushToken
  }
}`


const GET_USER_MESSAGES = gql`
query userMessages($messengerId: Int!){
  userMessages(messengerId: $messengerId) {
    nodes {
      id
      createdAt
      receiverFirstName
      receiverLastName
      receiverPublicUrl
      senderFirstName
      senderLastName
      senderPublicUrl
      locationId 
      fullName
      locality
      adminLevel1
      country
      seen 
      read
      messageContent
      messengerId
      senderId
      receiverId
      objectionable
    } 

  }
}`


const GET_USER_VIEW_BY_ID = gql `
query userViewById($profUserId: Int!){
  userViewById(profUserId: $profUserId){
    id
    firstName
    lastName
    currentCity
    currentCityLat
    currentCityLng
    about
    birthday
    publicity
    publicUrl
    headerPicUrl
    followersNumber
    followingNumber
    pushToken
  }
}

`

const GET_WEEKLY_EMAIL = gql `
query weeklyEmail{
  weeklyEmail{
    nodes{
      userId
      authId
      firstName
      lastName
      numberLocationsAdded
      numberAttractionsAdded
      numberSavesMade
      numberSavesReceived
      addedTripLocNames
      tripUploads {
        creatorId
        tripLocName
        numberTotalReviews
        numberFollowerReviews
      }
      pastTripLocNames
      numberLocations
      locAdderFirstName
      locAdderLastName 
      locName 
      numberAttractions 
      attrAdderFirstName 
      attrAdderLastName 
      attrName 
      attrLocName 
      followingTrips {
        creatorId
        firstName
        lastName
        fullName
      } 
    }
    
  }
}
`

const PICTURES_VIEWABLE = gql `
query picturesViewable ($ownerUserId: Int!, $currentUserId: Int){
  picturesViewable(ownerUserId: $ownerUserId, currentUserId: $currentUserId)
}
`

const SEARCH_ATTRACTIONS = gql`
query searchAttractions ($searchValue: String, $searchLocality: String, $searchAdminLevel1: String, $searchCountry: String, $currentUserId: Int){
  searchAttractions(searchValue: $searchValue, searchLocality: $searchLocality, searchAdminLevel1: $searchAdminLevel1, searchCountry: $searchCountry, currentUserId: $currentUserId){
    nodes {
        attractionId
        stars
        name
        review 
        tips
        attractionType
        attractionTypes
        locality
        adminLevel1
        country
        placeId
        firstName
        lastName
        publicUrl
        lat 
        lng
        locationId
        locationPlaceId
        locationName
        userId
        savedId
        saveCount
        tags {
          id 
          tag
        }       
    }
  }
}`

const SEARCH_LOCATIONS = gql `
query searchLocations ($searchLocality: String, $searchAdminLevel1: String, $searchCountry: String, $currentUserId: Int){
  searchLocations(searchLocality: $searchLocality, searchAdminLevel1: $searchAdminLevel1, searchCountry: $searchCountry, currentUserId: $currentUserId){
    nodes {
      locationId
      fullName
      locality
      adminLevel1
      country
      overallReview
      firstName
      lastName
      publicUrl
      lat 
      lng
      userId
      mainPictureUrl
      averageStars
      placeId
      dates {
        startDate
        endDate
        id
      }
    }
  }
}`


const SEARCH_USERS = gql`
query searchUsers ($searchValue: String, $currentUserId: Int){
  searchUsers(searchValue: $searchValue, currentUserId: $currentUserId){
    nodes {
      id
      firstName
      lastName
      currentCity
      publicUrl
     
    }
  }
}`




export { 
  CHECK_ENTRY_CODE,
  GET_WEEKLY_EMAIL,
  GET_ALL_OVERALL_LOCATIONS,
  GET_ALL_USER_MESSAGES,
  GET_ATTRACTION_COMMENTS,
  GET_ATTRACTION_PICTURES,
  GET_ATTRACTION_BY_ID,
  GET_ATTRACTION_VIEW_BY_ID,
  GET_CURRENT_PERSON,
  GET_EXPLORE_ATTRACTIONS,
  GET_EXPLORE_LOCATIONS,
  GET_EXPLORE_LOCATIONS_V2,
  GET_FOLLOWER_INFO,
  GET_FOLLOWING_INFO, 
  GET_FOLLOWERS_BY_DATE,
  GET_FOLLOWERS_ALPHABETIZED,
  GET_FOLLOWING_ALPHABETIZED,
  GET_FOLLOWING_OVERALL_LOCATIONS,
  GET_GROUPED_TRIP_LOCATIONS,
  GET_ITINERARY_ITEMS,
  GET_LOCATION_ATTRACTIONS,
  GET_LOCATION_AND_ATTRACTIONS,
  GET_LOCATION_PICTURE_COMMENTS,
  GET_LOCATION_PICTURES,
  GET_LOCATION_SAVED_ATTRACTIONS,
  GET_LOCATION_TIPS,
  GET_LOCATION_VIEW_AND_DATES,
  GET_MY_TRIPS,
  GET_NOTIFICATIONS_BY_DATE,
  GET_OVERALL_ATTRACTION_PICTURES,
  GET_OVERALL_ATTRACTION_PICTURES_ALL,
  GET_OVERALL_ATTRACTION_REVIEWS,
  GET_OVERALL_ATTRACTION_REVIEWS_ALL,
  GET_OVERALL_ATTRACTION_VISITORS,
  GET_OVERALL_ATTRACTION_BY_PLACE_ID,
  GET_OVERALL_LOCATION,
  GET_OVERALL_LOCATION_ATTRACTIONS,
  GET_OVERALL_LOCATION_ATTRACTIONS_ALL,
  GET_OVERALL_LOCATION_BY_PLACE_ID,
  GET_OVERALL_LOCATION_PICTURES,
  GET_OVERALL_LOCATION_PICTURES_ALL,
  GET_OVERALL_LOCATION_TIPS,
  GET_OVERALL_LOCATION_TIPS_ALL,
  GET_OVERALL_LOCATION_VISITORS,
  GET_SAVED_ATTRACTIONS,
  GET_SAVED_LOCATIONS,
  GET_SUGGESTED_FOLLOW_USERS,
  GET_SUGGESTED_FROM_CONTACTS,
  GET_TRIP_ATTRACTION_EXISTS,
  GET_TRIP_BY_ID,
  GET_TRIP_COMMENTS,
  GET_TRIP_GENERAL_ATTRACTIONS,
  GET_TRIP_GENERAL_LOCATIONS,
  GET_TRIP_GENERAL_LOCATION_BY_ID,
  GET_TRIP_ITINERARY_ITEMS,
  GET_TRIP_LOCATION_AND_DATES,
  GET_TRIP_LOCATION_COMMENTS,
  GET_TRIP_LOCATION_DATES,
  GET_TRIP_LOCATION_TIP_EXISTS,
  GET_TRIP_LOCATION_TIPS,
  GET_TRIP_TRANSPORTATIONS,
  GET_TRIP_USERS,
  GET_UNSEEN_FOLLOWERS,
  GET_UNSEEN_MESSAGES,
  GET_UNSEEN_NOTIFICATIONS,
  GET_UPCOMING_TRIPS,
  GET_USER_AND_LOCATIONS,
  GET_USER_BY_ID, 
  GET_USER_MESSAGES,
  GET_USER_VIEW_BY_ID,
  PICTURES_VIEWABLE,
  SEARCH_ATTRACTIONS,
  SEARCH_LOCATIONS, 
  SEARCH_USERS };
