import React from "react";

import "../stylesheets/components/CreateTripUsers.css"
import { Button } from 'reactstrap'

const CreateTripUsers = props => {
	const { 
		createTripUsers,
		followers,
		error,
		handleChange,
		handleClose,
		handleFocus,
		handleRemoveUser,
		handleSelect,
		handleSubmit,
		showUsers,
		tripUsers, 
		userIds,
		users,
		userValue, 
		view
	} = props

	const isTripUser = (follower) => {
		return tripUsers.some(user => {
			return user.userId === follower.followerId
		})
	}

	return (<div className={view === 'createTrip' ? "createTripUsers" : "createTripUsers popup popupMedium"}>
		<div className={view === 'trip' && 'popupContent'}>
			{view === 'trip' && <div className="secondaryHeader">Add Trip Members</div>}
			<div className="tripAddedUsers">{users.map(user => {
				return <div className="userTagHolder" key={user.id}>
					<div className="userTag">
						
						<div className="fontSmall">{user.name}</div>
					</div>
					<div onClick={() => handleRemoveUser(user.id)} className="removeIcon">-</div>
				</div>
			})}</div>
			<div className="addTripUsers">
				{view === 'createTrip' && <div className="textTitle textTitleForm">trip members</div>}
				<div className={view === 'trip' && 'form'}>
					<input 
						className="formField" 
						onFocus={() => handleFocus()} 
						onChange={e => handleChange(e)} 
						type="text" 
						name="userSearch" 
						value={userValue}
						autoComplete="off"
						onKeyDown={(e) => {
			                if (e.key === 'Enter') {
			                  e.preventDefault()
			                }
			              }}
					/>
					{showUsers && <div className="mainBackground selectDropdown">{followers.map(follower => {
						if ((follower.firstName.toLowerCase().startsWith(userValue.toLowerCase()) 
							|| follower.lastName.toLowerCase().startsWith(userValue.toLowerCase()))
							&& !userIds.includes(follower.followerId)
							&& (tripUsers && !isTripUser(follower) || !tripUsers)){
									return <div className="userInfo" onClick={() => handleSelect(follower)} key={follower.followerId}>
										<img className="picXS" src={follower.publicUrl} alt="profPic"/>
										<div className="textLink fontSmall">{(follower.firstName + ' ' + follower.lastName)}</div>
									</div>			
						}
					})}</div>}
					{error && <div className="error">There was an error.</div>}
					{view === 'trip' && <div className="formButtons">
							<button className="secondaryButton" onClick={() => handleClose()}>Cancel</button>
							<button className="primaryButton" onClick={() => handleSubmit(createTripUsers)}>Submit</button>
						</div>}
				</div>
			</div>
			
		</div>
	</div>
		
	);
};

export default CreateTripUsers;
