import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Switch from 'react-toggle-switch'


import Autocomplete from "./Autocomplete"


const TripForm = props => {
	const {
		createTrip,  
		createTripGeneralLocations,
		createTripUsers, 
		handleClose,
		handleSelect,
		handleSubmit,
		handleToggleTooltip, 
		handleToggleNotifications,
		handleTogglePublicity, 
		sendNotifications,
		showTooltip,
		stateError,
		tooltipType,
		tripPublic, 
		upcomingTripSchema,

	} = props;
	return (
		<div className="tripForm popup popupXL">
			<div className="popupContent">
				<div className="labelAndTooltip">
			          	<div className="secondaryHeader">Upcoming Trip</div>
						<div className="tooltipIcon" onClick={() => handleToggleTooltip('upcomingTrip')}>?</div>
					</div>
				{showTooltip && tooltipType === 'upcomingTrip' && <div className='tooltip'>Add an upcoming trip and the location will be added to your Trvlpage to help you organize your plans! Upcoming trip pins will only be visible to you.</div>}

				

				<Formik
					validationSchema={upcomingTripSchema}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(
							values,
							setSubmitting,	
							createTrip, 
							createTripGeneralLocations,
							createTripUsers

						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="form">
							<div className="fullWidth">
								
								<Autocomplete  
							        handleSelect={handleSelect}
							        type="upcomingTripLocation"
								/>
							
							</div>
							
							
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="startDate">start date</label>
									<Field 
										className="formField" 
										type="date" 
										name="startDate"
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        />
								</div>
									<ErrorMessage className="formError" name="startDate" component="div" />
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="endDate">end date</label>
									<Field 
										className="formField" 
										type="date" 
										name="endDate"
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault()
							                }
							            }} 
							        />
								</div>
							
								<div className="formItem">
									<label className="textTitle textTitleForm" htmlFor="comments">comments & questions</label>

				
									<Field className="formField formFieldLarge" placeholder={"Ex. Especially looking for good restaurant recs! And what’s the best neighborhood to stay in?"} rows={4} component="textarea" name="comments" autoComplete="off"/>
								</div>
									<ErrorMessage className="formError" name="comments" component="div" />
								<div className="formItem">
									<div className="labelAndTooltip">
										<div className="textTitle textTitleForm">notify followers</div>
										<div className="tooltipIcon" onClick={() => handleToggleTooltip('notifications')}>?</div>
									</div>
									{showTooltip && tooltipType === 'notifications' && <div className='tooltip'>Choose to notify your followers and let them know where you're planning to go. They will receive a notification, along with your comments and questions, asking them to upload their recommendations for the area to Trvlpage so that you can plan the best trip possible using their trusted tips!</div>}

				
									<div className="formToggle">
										<div className="fontSmall">Do Not Notify Followers</div>
										<Switch className="formToggleSwitch" onClick={() => handleToggleNotifications()} on={sendNotifications}/>
										<div className="fontSmall">Notify Followers</div>
									</div>
								</div>

								<div className="formItem">
									<div className="labelAndTooltip">
										<div className="textTitle textTitleForm">trip pin publicity</div>
										<div className="tooltipIcon" onClick={() => handleToggleTooltip('publicity')}>?</div>
									</div>
									{showTooltip && tooltipType === 'publicity' && <div className='tooltip'>Your trip pins will show up on your profile map. Choose whether your followers can see them or just you.</div>}

				
									<div className="formToggle">
										<div className="fontSmall">Just Me</div>
										<Switch className="formToggleSwitch" onClick={() => handleTogglePublicity()} on={tripPublic}/>
										<div className="fontSmall">My Followers</div>
									</div>
								</div>
							
								
							{stateError && <div className="error">{stateError}</div>}
							<div className="formButtons">
								<button className="secondaryButton formButton" onClick={() => handleClose()}>Cancel</button> 
								<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>Submit</button>
							</div>
							
						

						</Form>}
				</Formik>
				
			</div>
		
			
		</div>
	);
};

export default TripForm; 
