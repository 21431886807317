import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/MessageForm.css"

const MessageForm = props => {
	const {
		createNewMessage,
		error,
		handleSubmit,
		initialValues,
		stateError, 
		validationSchema
	} = props

	return (
		<div className="messageForm">
			<div>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						handleSubmit(
							values,
							createNewMessage, 
							resetForm, 
							setSubmitting	
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="messageForm">
							<div className="formItem">
								<Field className="formField" component="textarea" name="message" placeholder="Send a message" autoComplete="off" required />
							</div>
								<ErrorMessage className="formError" name="message" component="div" />
							{stateError && <div className="error">{stateError}</div>}
							{error && <div className="error">There was an error.</div>}
						
							<button className="primaryButton singleFormButton" type="submit" disabled={isSubmitting}>Send</button>
							

						</Form>}
				</Formik>
			</div>


		</div>
	);
};

export default MessageForm;
