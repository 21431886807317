import React from "react";


import Notification from "../components/Notification";

const Notifications = props => {
	const { 
		createLocationAndAttractionCustom,
		error,
		followersByDate, 
		handleCreateLocationAndAttraction, 
		handleShowPicture,
		handleUpdateFollow,
		notifications,
		notificationView,
		page,
		renderPagination,
		updateFollow, 
		view 
	} = props
	return (
		<div className="notifications">

			{notificationView === 'follow' ?
				<div>
					{followersByDate.map(follower => {				
						 return <Notification
						    error={error} 
							handleUpdateFollow={handleUpdateFollow}	
						 	key={follower.id}
							notification={follower} 
							notificationType="follower"
							updateFollow={updateFollow}
							/>	
					})}
				</div> :
				<div>
				 {(notifications.length) ?
					<div>
						{notifications.map((notification, index) => {	
								if (index >= 10 * page && index < 10 * (page + 1)){
									return <Notification
									error={error} 
									createLocationAndAttractionCustom={createLocationAndAttractionCustom}
									handleCreateLocationAndAttraction={handleCreateLocationAndAttraction}
									handleShowPicture={handleShowPicture}
								 	key={index}
									notification={notification} 
									/>
								}
								
						})}
						{(notifications.length > 10 && view !== 'navBar') && 
							renderPagination(notifications)
						}
						</div>
					: <div>You have no notifications</div>}
				</div>}
		</div>
		
	);
};

export default Notifications;
