import React from "react";
import ReactExport from "react-data-export";
import moment from "moment";

import "../stylesheets/components/ExportItinerary.css"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExportItinerary extends React.Component {

	organizeIntoDataSet(tripDates, itineraryItemsByDate){
		const dataSet = tripDates.map((date, index) => {
			const dateSection = {}
			if (index !== 0){
				dateSection.ySteps = 1
			}
			dateSection.columns = [moment.utc(date).format('YYYY-MM-DD'), "Title/Name", "Start Time", "End Time", "Notes"]
			dateSection.data = itineraryItemsByDate[date] ? itineraryItemsByDate[date].map(item => {
				const nameOrTitle = item.title ? item.title : item.name
				return ["", nameOrTitle, item.startTime, item.endTime, item.note]
			}) : []
			return dateSection
		})
		return dataSet
		//return 'HI'
	}

	render() {
		const { handleClose, itineraryItemsByDate, tripDates } = this.props
		return (<div className="exportItinerary popup popupSmall">
	           <div className="popupContent">
		           <ExcelFile>
		                <ExcelSheet  dataSet={this.organizeIntoDataSet(tripDates, itineraryItemsByDate)} name="Trip Itinerary"/>
		            </ExcelFile>
	          	</div>
	            <button className="tertiaryButton iconMedium" onClick={() => handleClose()}>x</button>
            </div>

        );
	}
}

 

export default ExportItinerary;