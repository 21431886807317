import React from "react";
import { compose, Mutation, graphql } from "react-apollo";
import { analytics } from '../index.js';

import { getCurrentUser, getDestinationSearch } from "../apollo/state/queries"
import { setDestinationSearchNeedsRefresh } from "../apollo/state/mutations"
import { DELETE_SAVED_ATTRACTION, SAVE_ATTRACTION } from "../apollo/db/mutations"
import { 
	GET_ATTRACTION_VIEW_BY_ID, 
	GET_SAVED_ATTRACTIONS, 
	GET_LOCATION_ATTRACTIONS, 
	GET_LOCATION_SAVED_ATTRACTIONS, 
	GET_OVERALL_ATTRACTION_REVIEWS,
	GET_OVERALL_ATTRACTION_REVIEWS_ALL, 
	GET_OVERALL_LOCATION_ATTRACTIONS, 
	GET_TRIP_GENERAL_ATTRACTIONS, 
	SEARCH_ATTRACTIONS
} from "../apollo/db/queries"


import SaveIcon from "../components/SaveIcon"

class SavedButtonContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
		}
		this.handleCreateSaved = this.handleCreateSaved.bind(this)
		this.handleDeleteSavedAttraction = this.handleDeleteSavedAttraction.bind(this)
		this.sendPushNotification = this.sendPushNotification.bind(this)
	}

	async handleCreateSaved(attraction, saveAttraction){
		const { unsavedIds } = this.state
		const { destinationSearch, handleToggleUnsavedAttraction, handleToggleSavedAttraction, handleSearch, setDestinationSearchNeedsRefresh, view } = this.props
		 try {
		 
		 		await saveAttraction({
				variables: {
					input: {
					
							locationUserId: attraction.userId,
							attrId: attraction.id, 
							locId: attraction.locationId
							
						}	
					}
				})

				if (attraction.pushToken){
		 			await this.sendPushNotification(attraction.pushToken)
		 		}
				
		 	
		 	if (view === 'search'){
		 		if (attraction.savedId){
					handleToggleSavedAttraction(attraction.id)
				} else {
					handleToggleUnsavedAttraction(attraction.id)
				}
		 	} else if ((attraction.country === 'United States' && attraction.adminLevel1 === destinationSearch.place.adminLevel1) || (attraction.country !== 'United States' && attraction.country === destinationSearch.place.country)){
		 		console.log('is it here?')
		 		//refresh attraction saved button on search results. researches for country or state in case attractions are added outside of locality on a location page. (wont refresh if crossing state or country lines)
		 		//await handleSearch(true)
		 		console.log('setDestinationSearchNeedsRefresh', setDestinationSearchNeedsRefresh)
		 		await setDestinationSearchNeedsRefresh({
					variables: {
						needsRefresh: true
					}
				})

		 	}

		 	if (process.env.NODE_ENV !== 'development'){
		 		await analytics.logEvent('save', {
					attrId: attraction.id, 
				});
			}

		 	
		 	
		} catch {
			console.log('error!!')
		}

	}

	async sendPushNotification(token){
	    const message = {
	      to: token,
	      sound: 'default',
	      body: 'Someone saved one of your reviews',
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	     'mode': 'no-cors',
        'method': 'POST',
        'headers': {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
	      body: JSON.stringify(message),
	    });
  	};

	async handleDeleteSavedAttraction(attraction, deleteSavedAttraction, setSubmitting){
		const { destinationSearch, handleToggleUnsavedAttraction, handleToggleSavedAttraction, handleSearch, setDestinationSearchNeedsRefresh, view } = this.props
		const { saving, unsavedIds } = this.state

		console.log('deleting attraction', attraction)
	
		try {
				await deleteSavedAttraction({
					variables: {
						input: {
							attrId: attraction.id 
							}
						}
					})
			console.log('destinationSearch', destinationSearch)

			if (view === 'search'){
				if (attraction.savedId){
					handleToggleSavedAttraction(attraction.id)
				} else {
					handleToggleUnsavedAttraction(attraction.id)
				}
		 	  
			} else if ((attraction.country === 'United States' && attraction.adminLevel1 === destinationSearch.place.adminLevel1) || (attraction.country !== 'United States' && attraction.country === destinationSearch.place.country)){
		 		//refresh attraction saved button on search results. researches for country or state in case attractions are added outside of locality on a location page. (wont refresh if crossing state or country lines)
		 		//await handleSearch(true)
		 		await setDestinationSearchNeedsRefresh({
					variables: {
						needsRefresh: true
					}
				})

		 	}
		 	if (process.env.NODE_ENV !== 'development'){
		 		await analytics.logEvent('remove_save', {
				  	attrId: attraction.id 
				});
			}
				
	

			
		} catch {
            console.log('error')
		}
	}

	renderButton(){
		const {  
			attraction, 
			currentUser, 
			destinationSearch,
			savedIds,
			unsavedIds,
			view
		} = this.props

		  return (<Mutation
		      mutation={SAVE_ATTRACTION}
		      refetchQueries={(result => {
				const tripLocs = result.data.saveAttraction.tripGeneralLocations
				const tripLocQueries = tripLocs.map(tripLoc => {
					return {query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLoc.id}}
				})
				const queries = tripLocQueries.concat([{query: GET_LOCATION_ATTRACTIONS, variables: {locId: attraction.locationId, currentUserId: currentUser.id}}, 
			      {query: GET_ATTRACTION_VIEW_BY_ID, variables: { attrId: attraction.id, currentUserId: currentUser.id}}, 
			      {query: GET_LOCATION_SAVED_ATTRACTIONS, variables: { locPlaceId: attraction.locationPlaceId }}, 
			      {query: GET_SAVED_ATTRACTIONS}, 
			      {query: GET_OVERALL_ATTRACTION_REVIEWS, variables: {attrPlaceId: attraction.placeId}}, 
			      {query: GET_OVERALL_ATTRACTION_REVIEWS_ALL, variables: {attrPlaceId: attraction.placeId}}, 
			      {query: GET_OVERALL_LOCATION_ATTRACTIONS, variables: {locationPlaceId: attraction.locationPlaceId}}, 
			      ])
				return queries 
				})}
				     
		    >
		    {(saveAttraction, { data, loading, error }) => {
		    	return (<Mutation
		      mutation={DELETE_SAVED_ATTRACTION} 

		      refetchQueries={(result => {
				const tripLocs = result.data.deleteSavedAttractionCustom.tripGeneralLocations
				const tripLocQueries = tripLocs.map(tripLoc => {
					return {query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLoc.id}}
				})
				const queries = tripLocQueries.concat([{query: GET_LOCATION_ATTRACTIONS, variables: {locId: attraction.locationId, currentUserId: currentUser.id}}, 
			      {query: GET_ATTRACTION_VIEW_BY_ID, variables: { attrId: attraction.id, currentUserId: currentUser.id}}, 
			      {query: GET_LOCATION_SAVED_ATTRACTIONS, variables: { locPlaceId: attraction.locationPlaceId }}, 
			      {query: GET_SAVED_ATTRACTIONS}, 
			      {query: GET_OVERALL_ATTRACTION_REVIEWS, variables: {attrPlaceId: attraction.placeId}}, 
			      {query: GET_OVERALL_ATTRACTION_REVIEWS_ALL, variables: {attrPlaceId: attraction.placeId}}, 
			      {query: GET_OVERALL_LOCATION_ATTRACTIONS, variables: {locationPlaceId: attraction.locationPlaceId}}
			      ])
				return queries 
				})}
		    >
			    {(deleteSavedAttraction, { data, loading, error }) => {
			    	return <SaveIcon 
			    		attraction={attraction}
			    		currentUser={currentUser}
			    		deleteSavedAttraction={deleteSavedAttraction}
			    		error={error}
			    		handleCreateSaved={this.handleCreateSaved}
			    		handleDeleteSavedAttraction={this.handleDeleteSavedAttraction}
			    		saveAttraction={saveAttraction}
			    		savedIds={savedIds}
			    		unsavedIds={unsavedIds}
			    		view={view}
			    	/>
			    }} 
			    </Mutation>)
		    	
		   	}}</Mutation>
		  )
		
	}
	render() {
		return (
			<div className="savedButtonContainer">
				{this.renderButton()}
			</div>
		);
	}
} 

export default compose(
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}), 
	graphql(getDestinationSearch, {
		props: ({loading, data: { destinationSearch }}) => ({
			loading,
			destinationSearch
		})
	}), 
    graphql(setDestinationSearchNeedsRefresh, {name: 'setDestinationSearchNeedsRefresh'}),


	)(SavedButtonContainer);