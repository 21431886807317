import React from "react";

import "../stylesheets/components/FollowButton.css"
import {
  	DropdownToggle,
  	DropdownMenu,
 	DropdownItem, 
	UncontrolledButtonDropdown
} from 'reactstrap';

const FollowButton = props => {
	const { 
		blockUser,
		deleteFollower, 
		error,
		follow,
		followerInfo,
		followingInfo,
		handleBlockUser,
		handleDeleteFollow, 
		handleFollow,
		profUserId,
		type,
		view
	} = props
	return (
		<div className="followButton">
			{type === 'block' ? <div>
				{(followerInfo && followerInfo.status === "BLOCKED") ? 

				<div className="textLink" onClick={() => handleDeleteFollow(followerInfo.id, deleteFollower)}>unblock</div> : 
				<div className="textLink" onClick={() => handleBlockUser(profUserId, blockUser)}>block</div>}

				
				
			</div> : <div>
				{followingInfo ? <div>
					{(followingInfo.status === "APPROVED" || followingInfo.status === "PENDING") && <div>
			    		{followingInfo.status === "PENDING" ? 
			    			<i className={`material-icons activeIcon iconMedium`} onClick={() => handleDeleteFollow(followingInfo.id, deleteFollower)}>people_outline</i>
							: <i className={`material-icons activeIcon iconMedium`} onClick={() => handleDeleteFollow(followingInfo.id, deleteFollower)}>people</i>}
			    	</div>} 
				</div> : <div>
					<i className={`material-icons activeIcon iconMedium`} onClick={() => handleFollow(profUserId, follow)}>person_add</i> 
		      	</div>}
		        {error && <div>There was an error.</div>}
	        </div>}
		
		</div>
		
	);
};

export default FollowButton;
