import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/PictureView.css"
import {
	Button
} from 'reactstrap';

import CommentsContainer from '../containers/CommentsContainer'


const PictureView = props => {
	const { 
		allowTags,
		currentUser,
		currentPicIndex,
		deleteTagId,
		handleChangePic,
		handleClose,
		handleHideDeleteTag,
		handleMakeMainPicture,
		handleShowDelete,
		handleShowSelectAttraction,
		handleSelectDeleteTag,
		handleSelectTagLocation,
		handleToggleAllowTags,
		handleToggleShowTags,
		handleToggleTooltip,
		makeMainPicture,
		navigate,
		picture, 
		picturesLength,
		renderSelectAttraction,
		renderTagPicture,
		showSelectAttraction, 
		showTags,
		showTooltip,
		tags,
		tagXOffset,
		tagXPercent, 
		tagYPercent
		 } = props


		 const currentUserTag = tags && tags.find(tag => tag.userId === currentUser.id)
		 
		 const firstPicture = index => index === 0 ? 'first' : null;
		 const firstArrowDisabled = index => index === 0 ? true : false;
		 const lastPicture = index => index + 1 >= picturesLength ? 'last' : null;
		 const lastArrowDisabled = index => index + 1 >= picturesLength ? true : false;


	return (
		<div className="pictureView popup popupXL">
			<div className="closeIconHolder">
				<i className="material-icons activeIcon iconMedium" onClick={(e) => handleClose(e)}>cancel</i>
			</div>
			<div className="popupContent">
				<div className="pictureViewLeft">
					<div className="pictureViewContent">
						<div className={`arrowHolder ${firstPicture(currentPicIndex)}`} disabled={firstArrowDisabled(currentPicIndex)} onClick={() => handleChangePic('last')}>
							<i className={`material-icons activeIcon iconLarge ${firstPicture(currentPicIndex)}`}>arrow_left</i>		
						</div>
						<div className="pictureAndTags">
							{tagXPercent && <div style={{'left': `${tagXPercent - tagXOffset}%`, 'top': `${tagYPercent}%`, position: 'absolute', zIndex: 5}}>{renderTagPicture()}</div>}
							{showTags && tags && tags.map(tag => {
								return <div key={tag.id}>{deleteTagId === tag.id ? 
								
										<div className="pictureTag" onClick={() => handleHideDeleteTag()} style={{'left': `${tag.leftPercent - tag.leftOffset}%`, 'top': `${tag.topPercent}%`, position: 'absolute'}}>{tag.firstName + ' ' + tag.lastName}<div className="removeIcon" onClick={() => handleShowDelete(tag.id, 'tag')}>-</div></div>
									 : <div>
									{currentUser.id === picture.ownerUserId ? <div className="pictureTag" onClick={() => handleSelectDeleteTag(tag.id)} style={{'left': `${tag.leftPercent - tag.leftOffset}%`, 'top': `${tag.topPercent}%`, position: 'absolute'}}>{tag.firstName + ' ' + tag.lastName}</div>
									: <div className="pictureTag" style={{'left': `${tag.leftPercent - tag.leftOffset}%`, 'top': `${tag.topPercent}%`, 'position': 'absolute'}}>{tag.firstName + ' ' + tag.lastName}</div> }
									</div>
								}</div>
								})
							}
							{allowTags ? <img className="pictureViewPic" onClick={event => handleSelectTagLocation(event)} src={picture.publicUrl} alt="locationPicture" /> : 
							<img className="pictureViewPic" src={picture.publicUrl} alt="locationPicture" />}
							{picture.instaAuthorName && <div className="instagramAttribution">
								<div>{picture.instaAuthorName}</div>
								<a href={`https://${picture.instaUrl}`}>{picture.instaUrl}</a>
								<div>Instagram</div>
							</div>}
						</div>
						<div className={`arrowHolder ${lastPicture(currentPicIndex)}`} disabled={lastArrowDisabled(currentPicIndex)} onClick={() => handleChangePic('next')}>
							<i className={`material-icons activeIcon iconLarge ${lastPicture(currentPicIndex)}`} disabled={lastArrowDisabled(currentPicIndex)} >arrow_right</i>		
						</div>

					</div>
						
			
				</div>

				<div className="pictureViewRight">
					
					{currentUser.id === picture.ownerUserId && <div className="picViewTopButtons">

						{picture.mainPicture ? 
							<div className="fontSmall">
									Main Picture
								</div>
							: <div>
								<div className="labelAndTooltip">
					
									<button className="primaryButton fontSmall" onClick={() => handleMakeMainPicture(picture.id, picture.locationId, makeMainPicture)}>Make Main Picture</button>
				   					<div className="tooltipIcon" onClick={() => handleToggleTooltip()}>?</div>

										
								</div>
							{showTooltip && <div className='tooltip'>Main pictures are the pictures displayed on explore.</div>}
						</div>}

						
						<i className="material-icons activeIcon iconSmall" onClick={() => handleShowDelete(picture.id, 'picture', currentPicIndex)}>delete</i>

					</div>}


				
					{picture.attractionName ? 
						<div  className="attractionTagHolder marginBottom">
							<Link className="attractionTagSmall" to={`/attraction/${picture.attractionId}`}>{picture.attractionName}</Link>
							{currentUser.id === picture.ownerUserId &&
							 <div className="removeIcon" onClick={() => handleShowDelete(picture.id, 'attraction')}>-</div>}
						</div> :<div> 
						{currentUser.id === picture.ownerUserId && !showSelectAttraction && <button className="primaryButton fontSmall" onClick={() => handleShowSelectAttraction()}>Connect to Specific Place</button>}
					</div>}
						
		
					

					{showSelectAttraction && renderSelectAttraction()}

					<div>
					{picture.ownerUserId === currentUser.id ? 
						
							(allowTags ? <div className="textLink fontSmall" onClick={() => handleToggleAllowTags()}>Done Tagging</div> : <div className="nameAndIcon">
								<i className={'material-icons activeIcon iconMedium'} onClick={() => handleToggleAllowTags()}>person</i>
								<div className="textLink fontSmall" onClick={() => handleToggleAllowTags()}>Tag People</div>
								</div>) :
							(showTags ? <div className="textLink fontSmall" onClick={() => handleToggleShowTags()}>Cancel Tag View</div> : <div className="textLink fontSmall" onClick={() => handleToggleShowTags()}>View Tags</div>)

					}
					{currentUserTag && <button className="secondaryButton" onClick={() => handleShowDelete(currentUserTag.id, 'untag')}>Remove Tag</button>}
					</div>

					<CommentsContainer 
						parentId={picture.id}
						picture={picture}
						view="picture"
						commentType="locationPictureComment"
						parentType="locationPicture"
						placeholder="Add a comment"
						pushToken={picture.pushToken}
					/>
				</div>
			</div>
			

		</div>
	);
};

export default PictureView;
