import React from "react";

import "../stylesheets/components/LocationTips.css"

import AddToTripContainer from "../containers/AddToTripContainer"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"


const LocationTips = props => {
	const { 
		currentUser,
		handleShowEdit,
		locationId,
		locationUserId,
		tips
	} = props


	return (
		<div className="locationTips">
			<div className="headerAndIcons">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">tips</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				{currentUser.id === locationUserId && <div>
					<i className={'material-icons iconMedium activeIcon locJoyride4'} onClick={() => handleShowEdit()}>edit</i>
					
				</div>}
			</div>
			<div className="mainBackground">
					{tips['CULTURAL'] && <div className="tipSection">
						<div className="categoryTitle tipTitle">Cultural</div>
	
						<div>
							{tips["CULTURAL"].objectionable ? 
									<div>
										{tips["CULTURAL"].objectionable === 'CONFIRMED' ? 
					             		<div className="fontSmall">This tip has been flagged.</div> : 
					             		<div className="fontSmall">This tip has been reported and is under review.</div>}
								             	
									</div>
							: <div className="fontSmall">{tips["CULTURAL"].tip}</div>}
							{(currentUser.id > 0 && locationUserId !== currentUser.id) && <div>
								{/*<AddToTripContainer
								locationId={locationId} 
								tip={tips['CULTURAL']}
								type="tip"
								/>*/}
								<ReportObjectionableContainer
									reportItem={tips["CULTURAL"]}
									reportType={'LOCATION_TIP'}
									reportTypeRender="location tip"
								 />
							</div>}
							</div> 
					
					</div>}
						
					{tips['TRANSPORTATION'] && <div className="tipSection">
						<div className="categoryTitle tipTitle">Transportation</div>
						
						 <div>
							{tips["TRANSPORTATION"].objectionable ? 
									<div>
										{tips["TRANSPORTATION"].objectionable === 'CONFIRMED' ? 
					             		<div className="fontSmall">This tip has been flagged.</div> : 
					             		<div className="fontSmall">This tip has been reported and is under review.</div>}
								             	
									</div>
								: <div className="fontSmall">{tips['TRANSPORTATION'].tip}</div>}
							{(currentUser.id > 0 && locationUserId !== currentUser.id) && <div>
								{/*<AddToTripContainer
								locationId={locationId} 
								tip={tips['TRANSPORTATION']}
								type="tip"
								/>*/}
							<ReportObjectionableContainer
									reportItem={tips['TRANSPORTATION']}
									reportType={'LOCATION_TIP'}
									reportTypeRender="location tip"
								 />
							</div>}
						</div>
	
					</div>}
					{tips['PACKING'] && <div className="tipSection">
						<div className="categoryTitle tipTitle">Packing</div>
						
						<div>
							{tips["PACKING"].objectionable ? 
									<div>
										{tips["PACKING"].objectionable === 'CONFIRMED' ? 
					             		<div className="fontSmall">This tip has been flagged.</div> : 
					             		<div className="fontSmall">This tip has been reported and is under review.</div>}
								             	
									</div>
								: <div className="fontSmall">{tips['PACKING'].tip}</div>}
							{(currentUser.id > 0 && locationUserId !== currentUser.id) && <div>
								{/*<AddToTripContainer
								locationId={locationId} 
								tip={tips['PACKING']}
								type="tip"
								/>*/}
							<ReportObjectionableContainer
									reportItem={tips['PACKING']}
									reportType={'LOCATION_TIP'}
									reportTypeRender="location tip"
								 />
							</div>}
						</div> 
	
					</div>}
					{tips['SAFETY'] && <div className="tipSection">
						<div className="categoryTitle tipTitle">Safety</div>
						
						<div>
							{tips["CULTURAL"].objectionable ? 
									<div>
										{tips["CULTURAL"].objectionable === 'CONFIRMED' ? 
					             		<div className="fontSmall">This tip has been flagged.</div> : 
					             		<div className="fontSmall">This tip has been reported and is under review.</div>}
								             	
									</div>
								: <div className="fontSmall">{tips['SAFETY'].tip}</div>}
							{(currentUser.id > 0 && locationUserId !== currentUser.id) && <div>
								{/*<AddToTripContainer
								locationId={locationId} 
								tip={tips['SAFETY']}
								type="tip"
							/>*/}
							<ReportObjectionableContainer
									reportItem={tips['SAFETY']}
									reportType={'LOCATION_TIP'}
									reportTypeRender="location tip"
								 />
							</div>}
						</div> 
	
					</div>}
					{tips['OTHER'] && <div className="tipSection">
						<div className="categoryTitle tipTitle">Other</div>
						
						<div>
							{tips["OTHER"].objectionable ? 
									<div>
										{tips["OTHER"].objectionable === 'CONFIRMED' ? 
					             		<div className="fontSmall">This tip has been flagged.</div> : 
					             		<div className="fontSmall">This tip has been reported and is under review.</div>}
								             	
									</div>
								: <div className="fontSmall">{tips['OTHER'].tip}</div>}
							{(currentUser.id > 0 && locationUserId !== currentUser.id) && <div>
								{/*<AddToTripContainer
									locationId={locationId} 
									tip={tips['OTHER']}
									type="tip"
								/>*/}
							<ReportObjectionableContainer
									reportItem={tips['OTHER']}
									reportType={'LOCATION_TIP'}
									reportTypeRender="location tip"
								 />
							</div>}
						</div> 
	
					</div>}
			
			</div>
			

		</div>
	);
};

export default LocationTips;
