import React from "react";
import { Link } from "react-router-dom";

import "../stylesheets/components/FollowView.css"
import FollowButtonContainer from "../containers/FollowButtonContainer"

const FollowView = props => {
	const { 
		handleChange,
		handleClose,
		handleViewFollowers, 
		handleViewFollowing, 
		users,
		value, 
		view
	} = props

	const selectedToggle = buttonType => buttonType === view && 'toggleSelected'
	return (
		<div className="followView popup popupSmall">
			<div className="closeIconHolder">
			  <i className="material-icons activeIcon iconMedium" onClick={() => handleClose()}>cancel</i>
			</div>
			<div className="popupContent">	
				<div className="toggleButtons">
					<div className={`toggleButton toggleLeft ${selectedToggle("followers")}`} onClick={() => handleViewFollowers()}>Followers</div>
					<div className={`toggleButton toggleRight ${selectedToggle("following")}`} onClick={() => handleViewFollowing()}>Following</div>
				</div>
				{!!users.length && <input className="filterInput" placeholder={'Filter by name'} value={value} onChange={(e) => handleChange(e)}/>}

				<div className="fullWidth popupScroll">
					{users.length ? users.map(user => {
						if ((user.firstName + user.lastName).toLowerCase().indexOf(value.toLowerCase()) !== -1){
							return <div className="follow mainBackground" key={user.id}>
								<div className="followUserInfo">
									<img className="picSmall" src={user.publicUrl} alt="profPic" />
									
									<Link className="textLink" to={`/profile/${view === 'following' ? user.followeeId : user.followerId}`}>
										{(user.firstName + ' ' + user.lastName)}
									</Link>
								</div>
								<FollowButtonContainer profUserId={view === 'following' ? user.followeeId : user.followerId} profUser={user}/>

							</div>
						}
						
					}) : <div>{view === 'following' ? 
						<div>This user is not following anyone.</div>
						:<div>This user has no followers.</div>
					}</div>}
				</div>

			</div>


		</div>
	);
};

export default FollowView;
