import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';


import "../stylesheets/components/InstagramAddPics.css"

import Autocomplete from "./Autocomplete"

const InstagramAddPics = props => {
	const { 
		attrValues,
		createLocationAndAttractionInsta,
		handleChange,
		handleCreatePictures,
		handleClose,
		handleSelect,
		imageUrls,
		loadMore, 
		locations, 
		locValues
	 } = props;
	return (
		<div className="instagramAddPics popup">
			<div className="popupContent">
			<div className="secondaryHeader formHeader">Where were these pictures taken?</div>
			<div className="fontSmall">If your Instagram is on private the pictures will not be displayed in Trvlpage, but it will still create pages for the locations, activities, restaurants and lodgings that you tag.</div>
			<Formik
		      onSubmit={(values, {setSubmitting}) => {
		       handleCreatePictures(createLocationAndAttractionInsta, setSubmitting)
		      }}
		    >
		      {({ isSubmitting }) => (
		        <Form className="form">
					{imageUrls.map((url, index) => {
						return <div className="instagramAddSection"> 
							<img
							className="picture" 
							src={url.media_url} 
							alt="picture" />
							<div className="formItem marginLeft"> 
								<Autocomplete 
									index={index}
									placeholder="City or town that this was taken"
									value={locValues[index]}
									handleSelect={handleSelect}
									type="instagramLocation"

								/>
								<div>
								{locations[index] && <Autocomplete 
									index={index}
									placeholder="Specific place this was taken (restaurant, activity, site, etc.)"
									value={attrValues[index]}
									handleSelect={handleSelect}
									locationLat={locations[index].geometry.location.lat()}
									locationLng={locations[index].geometry.location.lng()}
									type="instagramAttraction"

								/>}
								</div>
							</div>
						</div> 
					})}
					<div className="textLink" onClick={() => loadMore()}>Load More</div>
					<div className="formButtons">
		   				<button className="secondaryButton" onClick={() => handleClose()}>Cancel</button>
		 				<button className="primaryButton" type="submit" disabled={isSubmitting} >Submit</button>
		   			</div>
		   		</Form>)}
		   	</Formik>
	   		</div>
		</div>
	);
};

export default InstagramAddPics;
