import React from "react";


const CreateAccountCallout = props => {
	const { 
		handleGoToSignup,
		handleHideCallout
		} = props;
	return (
		<div className="createAccountCallout popup popupSmall">
			<div className="popupContent">
				<div className="secondaryHeader">Create an account</div>
				<div className="marginBottom centerText">Like what you see? Create an account and start remembering your travel experiences, connecting with friends, and exploring the world with useful trusted recommendations!</div>
				
				<div className="formButtons">
					<button className="secondaryButton" onClick={() => handleHideCallout()}>Maybe Later</button>
					<button className="primaryButton" onClick={() => handleGoToSignup()}>Create an account</button>
				</div>
		
			</div>
		</div>

		
	);
};

export default CreateAccountCallout;
