import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from "react-router-dom";


import "../stylesheets/components/Form.css"
import { Button } from 'reactstrap';

import initialPic from '../assets/globe.jpg'



const PasswordResetEmail = props => {
	const {
		error,
		handlePasswordResetEmail, 
		initialValues,
		loginError, 
		loginMessage, 
		stateError
	} = props

	return (
	<div className="initialScreen passwordResetEmail">
		<div className="initialScreenLeft">
			<div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">send password reset email</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
			{loginError && loginError}
			{loginMessage && loginMessage}


		<Formik
		      initialValues={initialValues}
		      onSubmit={(values, {setSubmitting}) => {
		        handlePasswordResetEmail(values, setSubmitting)
		      }}
		    >
		      {({ isSubmitting }) => (
		        <Form 
		        	className="form"
		        	onKeyDown={(e) => {
		                if (e.key === 'Enter') {
		                  e.preventDefault()
		                }
		            }} 
		        >
		        	<div className="formItem">
			          	<label className="textTitle textTitleForm" htmlFor="email">email</label>
			          	<Field 
			          		className="formField" 
			          		type="text" 
			          		name="email" 
			          		autoComplete="off" 
			          		required/>
		          	</div>
			          	<ErrorMessage name="email" component="div" required/>
			          	 {stateError && <div className="error">{stateError}</div>}
			          	{error && <div className="error">There was an error.</div>}
		
			     
			          <button className="primaryButton singleFormButton" type="submit" disabled={isSubmitting}>
			            Send Email
			          </button>
	
		          <Link className="textLink" to="/signup">Sign Up</Link>
		        </Form>
		      )}
		    </Formik>
		 </div>
		 <div className="initialScreenRight">
			<img 
			className="initialPicture"
		 	src={require('../assets/globe.jpg')} 
		 	alt="loginPicture"/>
		</div>

	</div>);
}

export default PasswordResetEmail;
