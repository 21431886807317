import React from "react";

import "../stylesheets/components/TripTransportations.css"


const TripTransporations = props => {
	const {
		currentUser,
		deleteTripTransportation,
		isEditing, 
		handleGoToProfile,
		handleShowDelete,
		handleShowEdit,
		handleShowAdd,
		handleToggleIsEditing,
		tripTransportations
	} = props

	
	return (
		<div className="tripTransportations">
			<div className="tripTransportationsHeader">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">transportations</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				<div className="tripTransportationsButtons">
					{isEditing ? <button className="cancelEdit" onClick={() => handleToggleIsEditing()}>Cancel</button> : <img 
						className="iconMedium activeIcon" 
						onClick={() => handleToggleIsEditing()}
						src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
						alt="editIcon"
					/>}
					<img 
						className="iconMedium activeIcon" 
						onClick={() => handleShowAdd()}
						src="https://storage.googleapis.com/trvlpage-bucket/plus.svg"
					/>

				</div>
			</div>
			<div className="transportationCategoryTitles">
				<div className="transportationCategoryTitle textTitle fontSmall">Name</div>
				<div className="transportationCategoryTitle textTitle fontSmall">Company/Type/Number</div>
				<div className="transportationCategoryTitle textTitle fontSmall">Departure</div>
				<div className="transportationCategoryTitle textTitle fontSmall">Arrival</div>
			</div>
			<div>
			{tripTransportations.length ? tripTransportations.map(transportation => {
				return <div className="transportation mainBackground" key={transportation.id}>
					<div className="transportationUserInfo transportationItem">
						<img className="picSmall" src={transportation.publicUrl} alt="profPic"/>
						<div className="textLink userName" onClick={() => handleGoToProfile(transportation.userId)}>{(transportation.firstName + " " + transportation.lastName)}</div>
					</div>
					<div className="transportationItem">
						<div>{transportation.transportationCompany && transportation.transportationCompany}</div>
						<div>{transportation.transportationNumber && transportation.transportationNumber}</div>
						<div>{transportation.transportationType && transportation.transportationType}</div>
					</div>
					<div className="transportationItem">
						<div>{transportation.departureCity && transportation.departureCity}</div>
						<div>{transportation.departureDate && transportation.departureDate}</div>
						<div>{transportation.departureTime && transportation.departureTime}</div>
					</div>
					<div className="transportationItem">
						<div>{transportation.arrivalCity && transportation.arrivalCity}</div>
						<div>{transportation.arrivalDate && transportation.arrivalDate}</div>
						<div>{transportation.arrivalTime && transportation.arrivalTime}</div>
					</div>
						
					
					{isEditing && <div className="transportationButtons">
						<div className="removeIcon iconSmall" onClick={() => handleShowDelete(transportation.id)}>-</div>
						<img 
							className="iconSmall" 
							onClick={() => handleShowEdit(transportation)}
							src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
							alt="editIcon"
						/>
					</div> }
				</div>
			}) : <div className="mainBackground">No transportations have been added to this trip.</div>}
			</div>
			
		</div>
	);
};

export default TripTransporations;
