import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/Form.css"
import "../stylesheets/components/ItineraryItemForm.css"
import { Button } from 'reactstrap';

import DatesContainer from "../containers/DatesContainer"

const ItineraryItemForm = props => {
	const {
		action,
		addType,
		attractionName,
		changeDatesOption,
		changeItineraryItemDate,
		dates,
		error,
		handleChangeChosenLoc,
		handleClose,
		handleShowLocDates,
		handleSubmit,
		handleToggleAddType,
		initialValues, 
		mutationFunction,
		showLocDates, 
		stateError,
		tripAttractions,
		tripLocations,
		tripLocId,
		tripId,
		validationSchema, 
		view
	} = props;


	return (
		<div className="itineraryItemForm popup popupXL">
			<div className="popupContent">
				<h2 className="secondaryHeader">Add Itinerary Item</h2>
				<Formik
					validationSchema={validationSchema}
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(
							values,
							mutationFunction,
							changeItineraryItemDate,
							setSubmitting
							
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form className="form">
							{action === 'edit' && <div>
								{attractionName ? <div className="textTitle">{attractionName}</div>
								: <div>
									<div className="formItem">
										<label className="textTitle" htmlFor="title">Title</label>
										<Field 
											className="formField" 
											type="text" 
											name="title" 
											autoComplete="off" 
											onKeyDown={(e) => {
								                if (e.key === 'Enter') {
								                  e.preventDefault()
								                }
								            }} 
											required 
										/>
									</div>
									<ErrorMessage name="title" component="div" />
								</div>}
							</div>}
							{action === 'add' && 
				          		<div>
				          		{addType === 'attraction' ? 
						          	<div>
							          	{tripAttractions.length ? <div className="formItem switchSection">
							          		<div>
								        	  <label className="textTitle" htmlFor="tripAttraction">Pick a place</label>
								        	   <div className="fontSmall textLink" onClick={() => handleToggleAddType('title')}>Free form title</div>
								        	</div>
								            <Field 
								            	className="dropdownSelect" 
								            	component="select" 
								            	name="tripAttraction"
								            	onKeyDown={(e) => {
									                if (e.key === 'Enter') {
									                  e.preventDefault()
									                }
									            }} 
									        >
								            	<option value='choose'>Choose One</option>
								            	{tripAttractions.map(tripAttr => {
								            		return  <option value={tripAttr.id} key={tripAttr.id}>{tripAttr.name}</option>
								            	})}
									          </Field>

								        </div> : <div className="formItem">
								        	<div>
								        	  <label className="textTitle" htmlFor="tripAttraction">Pick a place</label>
								        	   <div className="fontSmall textLink" onClick={() => handleToggleAddType('title')}>Free form title</div>
								        	</div>
								         	<div className="fontSmall">Add specific places to your list on this locations page</div>
								        </div>}
					          		<ErrorMessage name="notes" component="div" />
					          		
					          		</div> : 
					          		<div>
						          		<div className="formItem switchSection">
						          		<div>
											<label className="textTitle" htmlFor="title">Title</label>
											<div className="textLink fontSmall switchButton" onClick={() => handleToggleAddType('attraction')}>Select an added place</div>
										</div>
										<Field 
											className="formField" 
											type="text" 
											name="title" 
											autoComplete="off" 
											onKeyDown={(e) => {
								                if (e.key === 'Enter') {
								                  e.preventDefault()
								                }
								            }} 
											required />	
									</div>
									
									<ErrorMessage className="formError" name="title" component="div" /></div>
								}
					        </div>}
							<div className="formItem">
								<label className="textTitle" htmlFor="startTime">Start Time</label>
								<Field 
									className="formField" 
									type="time" 
									name="startTime"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						            }}  
						        />
							</div>
								<ErrorMessage className="formError" name="startTime" component="div" />
							<div className="formItem">
								<label className="textTitle" htmlFor="endTime">End Time</label>
								<Field 
									className="formField" 
									type="time" 
									name="endTime"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						            }} 
								/>
							</div>
								<ErrorMessage className="formError" name="endTime" component="div" />

							<div className="formItem">
								<label className="textTitle" htmlFor="tripDate">Trip Date</label>
								<Field 
									className="formField" 
									type="date" 
									name="tripDate"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						            }}  
						        />
							</div>
								<ErrorMessage className="formError" name="tripDate" component="div" required />

							<div className="formItem">
					          <label className="textTitle" htmlFor="note">Notes</label>
					          <Field className="formField formFieldLarge" component="textarea" rows={4} name="note" autoComplete="off"/>
					        </div>
				          		<ErrorMessage name="note" component="div" />

				          	
							
						
							
							{changeDatesOption && <button className="secondaryButton" onClick={() => handleShowLocDates()}>Adjust Location Dates</button>}
							{showLocDates &&  <DatesContainer 
								dates={dates ? dates : tripLocations.find(loc => tripLocId === loc.id).dates}
								dateType="tripLocationDate"
								isEditing={true}
								parentId={tripLocId}
								parentType="tripLocation"
								tripId={tripId}
							/>}
							{stateError && <div className="error">{stateError}</div>}
							{error && <div className="error">There was an error.</div>}
							<div className="formButtons">
								{<Button className="secondaryButton" onClick={handleClose}>Cancel</Button>}
								<Button className="primaryButton" type="submit">
									Submit
								</Button>
							</div>
				
						</Form>}

				</Formik>
				
				
			</div>
	
		</div>
	);
};

export default ItineraryItemForm;
