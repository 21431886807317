import React from "react";
import { Formik } from "formik";



const LocationSelection = props => {
	const {
		handleClose,
		handleSubmit,
		initialValues, 
		stateError,
		locations, 
		view
	} = props;

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName
		} else {
			return location.country
		}
	}



	return (<div className="deletion popup popupSmall">
			<div className="popupContent">
				<label className="textTitle textTitleForm" htmlFor="overallReview">Choose a Location</label>

				 <Formik
					onSubmit={(values, { setSubmitting }) => {
						console.log('values', values)
						handleSubmit(values)
					}}
				>
					{({ isSubmitting, handleChange, values, setFieldValue, errors, setFieldTouched, touched }) =>
						<div className="form">
							<div className="formItem">
									<select 
										className="dropdownSelect" 
										name="locationId"
										onChange={(e) =>
										    setFieldValue('locationId', e.target.value)
										  }
										onKeyDown={(e) => {
							                if (e.key === 'Enter') {
							                  e.preventDefault();
							                }
							              }}>
							               {locations.map(loc => {
							               	return <option value={loc.id} selected={loc.id}>{getLocName(loc)}</option>
											})}
							              <option value="choose" selected="choose">Choose One</option>
									</select>
								</div>
				          	
				      
					
					
							{stateError && <div className="error">{stateError}</div>}
							<div className="formButtons">
								<button className="secondaryButton formButton" onClick={() => handleClose()}>Cancel</button>
								<button className="primaryButton formButton" type="submit" onClick={() => {handleSubmit(values)}}>Submit</button>
							</div>
						
				
						</div>}

				</Formik> 
		
			</div>
		</div>);
};

export default LocationSelection;