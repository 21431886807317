//eventually this should get it directly from db and maintain display capitlization
export const availableTags = { 
	FAMILY_FRIENDLY: 'Family-friendly', 
	OUTDOORSY: 'Outdoorsy',
	CULTURAL: 'Cultural',
	ARTSY: 'Artsy', 
	SCENIC: 'Scenic', 
	TOURISTY: 'Touristy', 
	ROMANTIC: 'Romantic',
	VEGETARIAN_FRIENDLY: 'Vegetarian-friendly', 
	VEGAN_FRIEDNLY: 'Vegan-friendly',
	LATE_NIGHT_PARTY: 'Late-night-party',
	HAPPY_HOUR_DEALS: 'Happy hour deals',
	OUTDOOR_SEATING: 'Outdoor seating',
	LGBTQ_FRIENDLY: 'LGBTQ-friendly', 
	INSTA_WORTHY: 'Insta-worthy',
	HEALTHY_OPTIONS: 'Healthy options',
	PARTY_SPOT: 'Party spot',
	DIVE_BAR: 'Dive bar',
	ROOFTOP: 'Rooftop',
	GOOD_FOR_GROUPS: 'Good for Groups',
	LATE_NIGHT_KITCHEN: 'Late Night Kitchen', 
	OVERPRICED: 'Overpriced'
}

