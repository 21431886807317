import React from "react";
import { GoogleApiWrapper } from "google-maps-react";

import "../stylesheets/components/SearchAutocomplete.css"


let autocomplete, selected;

class SearchAutocomplete extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	async componentDidMount() {
		const { locationLat, locationLng, type, handleSelect } = this.props;
		const google = this.props.google;

	


		autocomplete = new google.maps.places.Autocomplete(
			document.getElementById("searchAutocomplete"),
			{ types: ["(regions)"] }
		);
		
		autocomplete.addListener("place_changed", function() {
			selected = autocomplete.getPlace();	
				handleSelect(selected)
		});
	
	}


	render() {
		const {
			cancelText,
			handleChange,
			handleClose,
			handleEnter,
			handleSetSearching,
			handleSetView,
			mutationFunction,
			placeholder,
			name,
			stateError,
			value, 
			type
		} = this.props;
		return (
			<div className="searchAutocomplete">
				<form className="searchForm">
					<div>
						 <input
						 	//onFocus={handleSetSearching}
							className="formField searchField"
							id="searchAutocomplete"
							value={value}
							onChange={e => handleChange(e)}
							placeholder={placeholder}
							onKeyDown={(e) => {
				                if (e.key === 'Enter') {
				                  handleEnter(e)
				                }
				            }} 
						/>
						<div className="searchToggleView">	
							{stateError && <div className="fontXS error">{stateError}</div>}
							<div className="switchTitle">Switch to: </div>
							<div className="textLink" onClick={() => handleSetView('user')}>
								User Search
							</div>
						</div>
					</div>
					<button className="searchButton primaryButton fontSmall" onClick={(e) => handleSetSearching(e)}>Search Destinations</button>



				</form>
				
	
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(SearchAutocomplete);
