import React from "react";
import { Link } from "react-router-dom"

const SavedLocationHeader = props => {
	const { 
		location
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}

	return (
		<div className="savedLocationHeader">
			<div className="backAndHeader">
				<Link className="backIcon" to={'/saved'}>Back</Link>
				<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">{getLocName(location)}</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
			</div>
			
		</div>
	);
};

export default SavedLocationHeader;
