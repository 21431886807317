import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { Formik, Form, Field, ErrorMessage } from "formik";


import "../stylesheets/components/Autocomplete.css"
import { Button } from 'reactstrap';


let autocomplete, selected;

class Autocomplete extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	async componentDidMount() {
		const { locationLat, locationLng, type, handleSelect, index } = this.props;
		const google = this.props.google;
		let options = {};

		if (type === "attraction" || type === "tripAttraction" || type === 'instagramAttraction') {
			const firstLat = Number(locationLat) - 0.2;
			const firstLng = Number(locationLng) - 0.2;
			const secondLat = Number(locationLat) + 0.2;
			const secondLng = Number(locationLng) + 0.2;

			const defaultBounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(firstLat, firstLng),
				new google.maps.LatLng(secondLat, secondLng)
			);

			options = {
				bounds: defaultBounds,
				types: ["establishment"]
			};
		} else if (type === "location" || type === 'waitingList' || type === 'createTrip' || type === 'tripLocation' || type === "currentCity" || type ==='currentCitySignUp' || type === 'instagramLocation' || type === "locationsSignup" || type === 'upcomingTripLocation') {
			options = { types: ["(cities)"] };
		} else if (type === "saved" || type === "search" ) {
			options = { types: ["(regions)"] };
		}

		if (type === "instagramLocation" || type === "locationsSignup"){
			window['autocomplete'+index] = new google.maps.places.Autocomplete(
				document.getElementById(`autocomplete${index}`),
				options
			);

			window['autocomplete'+index].addListener("place_changed", function() {
				selected = window['autocomplete'+index].getPlace();
					handleSelect(selected, index, type)
			});
		} else if (type === "instagramAttraction"){
			console.log('this is for attr')
			window['autocompleteAttr'+index] = new google.maps.places.Autocomplete(
				document.getElementById(`autocompleteAttr${index}`),
				options
			);

			window['autocompleteAttr'+index].addListener("place_changed", function() {
				selected = window['autocompleteAttr'+index].getPlace();
					handleSelect(selected, index, type)
			});
		} else {
			autocomplete = new google.maps.places.Autocomplete(
				document.getElementById("autocomplete"),
				options
			);

			autocomplete.addListener("place_changed", function() {
				selected = autocomplete.getPlace();
				if (type === "saved" || type === 'waitingList' ||type === 'createTrip' || type === "search" || type === 'currentCity' || type === 'tripAttraction' || type === 'upcomingTripLocation'){
					handleSelect(selected)
				} 
			});
		}

		
	
	}



//<form className="form" onSubmit={e => handleSubmit(e, mutationFunction, selected, items)}>
	render() {
		const {
			cancelText,
			error,
			handleClose,
			handleRemoveLocation,
			handleSubmit,
			handleSelectAttractionType,
			handleToggleTooltip,
			index,
			items,
			locationAmount, 
			mutationFunction,
			newAttraction,
			newAttractionType,
			placeholder,
			popup,
			name,
			renderAttractionTypes,
			showTooltip,
			stateError,
			value, 
			view,
			type
		} = this.props;

		return (
			<div className={popup ? "autocomplete popupContent" : "autocomplete"}>
				{type !== 'search' && type !== 'saved' && type !== 'currentCitySignUp' && view !== 'locationsSignup' && type !=='instagramLocation' && view !== 'addLocation' && type !== 'upcomingTripLocation' && <div className="secondaryHeader"> {name} </div>}
				{view === 'addLocation' && <div className="labelAndTooltip">
						          	<label className="textTitle textTitleForm" htmlFor="overallReview">Add Location</label>
									<div className="tooltipIcon" onClick={() => handleToggleTooltip('add')}>?</div>
								</div>}
				<Formik
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(mutationFunction, selected, setSubmitting, items);
					}}
				>
					{({ values, setFieldValue, isSubmitting }) =>
						<Form className="form">
							<div className={type === "instagramLocation" ? "formItem shortForm" : "formItem"}>
							 <input
								className="autocompleteField"
								id={(type === 'instagramLocation' || type === 'locationsSignup') ? `autocomplete${index}` : (type === 'instagramAttraction' ? `autocompleteAttr${index}` : "autocomplete")}
								placeholder={placeholder}
								onKeyDown={(e) => {
									console.log('e', e.key)
					                if (e.key === 'Enter' && type !== 'location' && type !== 'attraction' && type !== 'tripLocation' && type !== 'tripAttraction') {
					                  e.preventDefault()
					                } else if (e.key === 'Backspace' && type === 'locationsSignup'){
					                	handleRemoveLocation(index)
					                }
					              }}
							/>
							</div>

						{error && (type !== 'locationsSignup' || index === locationAmount) && <div className="error">There is an error.</div>}
						{stateError && <div className="error">{stateError}</div>}
						{showTooltip && <div className='tooltip'>Add a city to your profile map. Once you add a city, you'll be able to add specific places within that city. Location pages are limited to cities so search for the city that corresponds to the destination you're trying to add.</div>}
						{(type === 'tripAttraction' && newAttractionType) || view === 'addContainer' && 
		  					
	  					<div className="dropdownSection">
							<div className="textTitle">Category</div>
							<select className="dropdownSelect" onChange={(e) => handleSelectAttractionType(e.target.value)}>
								<option value="ACTIVITY" selected={newAttractionType === 'ACTIVITY'}>Activity</option>
								<option value="RESTAURANT" selected={newAttractionType === 'RESTAURANT'}>Food & Drinks</option>
								<option value="LODGING" selected={newAttractionType === 'LODGING'}>Lodging</option>
							</select>
						
	  					</div>}


						{(type === "location" || type === "attraction" || type === 'tripAttraction' || type === 'tripLocation' || (type === "locationsSignup" && index === locationAmount - 1)) && <div className="formButtons">
							<Button className="secondaryButton formButton" onClick={() => handleClose()}>{cancelText}</Button>
							<Button className="primaryButton formButton" type="submit" disabled={isSubmitting}>Add</Button>
						</div>}
					</Form>
				}
			</Formik>
		</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(Autocomplete);
