import React from "react";

const AddToTrip = props => {
	const {
		added,
		error,
		handleClose,
		handleSelectTrip, 
		handleToggleTooltip,
		handleTripChange,
		mutationFunction, 
		myTrips, 
		showTooltip,
		stateError,
		tooltipType,
		tripId,
		type 
	} = props
	return (
		<div className="addToTrip popup popupMedium">
			{!added ? <div className="popupContent">
				<div>
	   				<div className="labelAndTooltip">
			          	<div className="secondaryHeader">Select A Trip:</div>
						<div className="tooltipIcon" onClick={() => handleToggleTooltip('addToTrip')}>?</div>
					</div>
					{showTooltip && tooltipType === 'addToTrip' && <div className='tooltip'>{`Add this ${type} to a trip.`}</div>}
				</div>
				{myTrips.length ?
					<form 
						className="form" 
						onSubmit={(e) => handleSelectTrip(e, mutationFunction)}
						onKeyDown={(e) => {
			                if (e.key === 'Enter') {
			                  e.preventDefault()
			                }
			              }}
					>
						<select className="dropdownSelect" onChange={(e) =>handleTripChange(e.target.value)}>
						<option>Choose one</option>
						{myTrips.map(trip => {
							return <option value={trip.id} key={trip.id} selected={tripId === trip.id}>{trip.name}</option>
						})}
						</select>
						{stateError && <div className="error">{stateError}</div>}
						{error && <div className="error">There was an error.</div>}
						<div className="formButtons">
							<button className="secondaryButton" onClick={() => handleClose()}>Cancel</button>
							<button className="primaryButton" disabled={!tripId} type="submit">Submit</button>
						</div>
					</form> : <div className="form">
						<div>You currently have no trips</div>
						<div className="formButtons singleFormButton">
							<button className="secondaryButton" onClick={() => handleClose()}>Cancel</button>
						</div>
				</div>}
			</div> : <div className="popupContent fontXL">Added!</div>}


		</div>
	);
};

export default AddToTrip;
