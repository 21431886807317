import React from "react";
import moment from "moment";

import "../stylesheets/components/TripItinerary.css"

const colors = ['#e6194B', '#ffe119', '#f58231', '#bfef45', '#3cb44b', '#4363d8', '#42d4f4', '#911eb4', '#f032e6', '#000075']

const TripItinerary = props => {
	const { 
		handleLeftTripDateArrow,
		handleRightTripDateArrow,
		handleShowAdd,
		handleShowDelete,
		handleShowEdit,
		handleShowItemDetails,
		handleToggleIsEditingItinerary,
		isEditingItinerary,
		itineraryItemsByDate, 
		tripDates, 
		tripDatesStartIndex, 
		tripLocIds
	} = props
	return (
		<div className="tripItinerary">
			<div className="tripItineraryButtons">
				<div className="tripItinButtons tripItinButtonsRight">
				<div>{tripDatesStartIndex !== 0 && <div onClick={() => handleLeftTripDateArrow()}>\</div>}</div>
					{isEditingItinerary ? <div onClick={() => handleToggleIsEditingItinerary()}>Cancel</div> 
						: <img 
							className="iconMedium activeIcon" 
							onClick={() => handleToggleIsEditingItinerary()}
							src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
							alt="editIcon"
						/>}
					<img 
						className="iconMedium activeIcon" 
						onClick={() => handleShowAdd()}
						src="https://storage.googleapis.com/trvlpage-bucket/plus.svg"
					/>
					{tripDates.length > (tripDatesStartIndex + 14) && <div onClick={() => handleRightTripDateArrow()}>\</div>}
				</div>
			</div>
			
			<div className="tripItineraryDays">
				{tripDates.map((date, index) => {
					if (index >= tripDatesStartIndex && index < tripDatesStartIndex + 14)
					return <div className="tripItineraryDay mainBackground" key={index} onClick={(e) => handleShowAdd(e, date)}>
						<div className="fontSmall textTitle tripItinDate">{moment.utc(date).format('MMMM Do YYYY, h:mm:ss a').slice(0, -17)}</div>
						{itineraryItemsByDate[date] && itineraryItemsByDate[date].map(item => {
							console.log('item', item)
							//use the index to determine which color will show - possibility of 2 of the same colors next to each other if over 10 locations
							const color = colors[tripLocIds.indexOf(item.tripLocationId).toString()[0]]
							return <div className="tripItineraryItem" key={item.id} >
								<div className="timeframe fontSmall" style={{color: color}}>
									{item.startTime && <div className="fontSmall">{moment(item.startTime, 'HH:mm').format('h:mm a')}</div>}
									{(item.startTime || item.endTime) && <div className="fontSmall">-</div>} 
									{item.endTime && <div className="fontSmall">{moment(item.endTime, 'HH:mm').format('h:mm a')}</div>}
								</div>
								{item.title ? <div className="fontSmall textTitle" style={{color: color}} onClick={() => handleShowItemDetails(item)}>{item.title}</div> : <div className="fontSmall textTitle" style={{color: color}} onClick={() => handleShowItemDetails(item)} >{item.name}</div>}
							
								{isEditingItinerary && <div className="tripItinItemButtons">
									<img 
											className="iconSmall editIcon" 
											onClick={() => handleShowEdit(item)}
											src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
											alt="editIcon"
									/>
									<div className="iconSmall removeIcon" onClick={() => handleShowDelete(item)}>-</div>
								</div>}
							
							</div>
							
						})}
					</div>
				})}
			</div>
		</div>
	);
};

export default TripItinerary;
