import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/TripTransportationForm.css"
import { Button } from 'reactstrap'

const TripTransportationForm = props => {
	const { 
		error,
		handleChange,
		handleChangeType,
		handleClose,
		handleFocus,
		handleRemoveUser,
		handleSelectUser,
		handleSubmit,
		handleToggleTooltip,
		initialValues,
		mutationFunction,
		showTooltip,
		showUsers,
		stateError,
		tooltipType,
		tripUsers, 
		user,
		value
	} = props

	console.log('initial values', initialValues)

	return (<div className="tripTransportationForm popup popupXL">
		<div className="popupContent">
			<div className="secondaryHeader">Add New Transportation</div>
			<Formik
				//validationSchema={basicSchema}
				initialValues={initialValues}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(
						values,
						mutationFunction
						
					);
				}}
			>
				{({ isSubmitting }) =>
					<Form 
						className="form"
						onKeyDown={(e) => {
			                if (e.key === 'Enter') {
			                  e.preventDefault()
			                }
			            }} 
			        >
						<div className="formItems">
							<div className="formItem">
								<div>
				       				<div className="labelAndTooltip">
							          		<label className="textTitle textTitleForm" htmlFor="transportationCompany">company</label>
										<div className="tooltipIcon" onClick={() => handleToggleTooltip('company')}>?</div>
									</div>
									{showTooltip && tooltipType === 'company' && <div className='tooltip'>The company that runs your transportation. Ex. Delta, American Airlines, Greyhound</div>}
								</div>
								<Field className="formField" type="text" name="transportationCompany" autoComplete="off" />
							</div>
								<ErrorMessage className="formError" name="transportationCompany" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="transportationNumber">transportation number</label>
								<Field className="formField" type="text" name="transportationNumber" autoComplete="off" />
							</div>
								<ErrorMessage className="formError" name="transportationNumber" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="departureCity">departure city</label>
								<Field className="formField" type="text" name="departureCity" autoComplete="off" />
							</div>
								<ErrorMessage className="formError" name="departureCity" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="arrivalCity">arrival city</label>
								<Field className="formField" type="text" name="arrivalCity" autoComplete="off" />
							</div>
								<ErrorMessage className="formError" name="arrivalCity" component="div" />
				
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="departureDate">departure date</label>
								<Field className="formField" type="date" name="departureDate" />
							</div>
								<ErrorMessage className="formError" name="departureDate" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="departureTime">departure time</label>
								<Field className="formField" type="time" name="departureTime" />
							</div>
								<ErrorMessage className="formError" name="departureTime" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="arrivalDate">arrival date</label>
								<Field className="formField" type="date" name="arrivalDate" />
							</div>
								<ErrorMessage className="formError" name="arrivalDate" component="div" />
							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="arrivalTime">arrival time</label>
								<Field className="formField" type="time" name="arrivalTime" />
							</div>
								<ErrorMessage className="formError" name="arrivalTime" component="div" />
							</div>

							{user && <div className="userTagHolder" key={user.id}>
								<div className="userTag">
									<div>{(user.firstName + " " + user.lastName)}</div>
								</div>
								<div className="removeIcon" onClick={() => handleRemoveUser(user.id)} >-</div>
							</div>}

							<div className="addTripUser">
								<div className="textTitle textTitleForm">user</div>
								<div>
									<input 
										className="formField" 
										onFocus={() => handleFocus()} 
										onChange={e => handleChange(e)} 
										type="text" 
										name="userSearch" 
										value={value}
										autoComplete="off"
									/>
									{showUsers && <div className="mainBackground selectDropdown">{tripUsers.map(tripUser => {
										if ((tripUser.firstName.toLowerCase().startsWith(value.toLowerCase()) 
											|| tripUser.lastName.toLowerCase().startsWith(value.toLowerCase()))
											&& (!user || user && user.id !== tripUser.userId)){
													return <div className="addTripUser" onClick={() => handleSelectUser(tripUser)} key={tripUser.userId}>
														<img className="picXS" src={tripUser.publicUrl} alt="profPic"/>
														<div className="textLink">{(tripUser.firstName + ' ' + tripUser.lastName)}</div>
													</div>			
										}
									})}</div>}
								</div>
							</div>
							<div className="formItem">
								<div className="textTitle textTitleForm">transportation type</div>
								<Field as="select" className="dropdownSelect" name='type'>
									<option value="FLIGHT">Flight</option>
									<option value="BUS">Bus</option>
									<option value="TRAIN">Train</option>
									<option value="OTHER">Other</option>
								</Field>
							</div>
						{stateError && <div className="error"> {stateError}</div>}
						{error && <div className="error">There was an error.</div>}
						<div className="formButtons">
							<Button className="secondaryButton" onClick={handleClose}>Cancel</Button>
							<Button className="primaryButton" type="submit" disabled={isSubmitting}>
								Submit
							</Button>
						</div>

					</Form>}
			</Formik>
		
		</div>
		
	</div>
		
	);
};

export default TripTransportationForm;
