import React from "react";
import { Link } from "react-router-dom";
import moment from "moment"

import "../stylesheets/components/Notification.css"
import { Button } from 'reactstrap';

const Notification = props => {
	const { 
		createLocationAndAttractionCustom,
		error,
		handleCreateLocationAndAttraction,
		handleShowPicture,
		handleUpdateFollow, 
		notification,
		notificationType,
		updateFollow 
	} = props;

	return (
		<div className="mainBackground notification" key={notification.id}>

				
				{notificationType === "follower" && 
				<div >
					{notification.status === "PENDING"
					? <Link className="notificationContent" to={`/profile/${notification.followerId}`}>
							<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
							<div className="notificationMiddle">
								<div className="notificationText">{`${(notification.firstName + ' ' + notification.lastName)} has requested to follow you.`}</div>
							</div>
							{error && <div className="error">There was an error.</div>}
							<div className="followButtons">
								<button className="primaryButton" onClick={(e) =>handleUpdateFollow(e, notification.id, "APPROVED", updateFollow)}>Accept</button>
								<button className="secondaryButton" onClick={e => handleUpdateFollow(e, notification.id, 'DENIED', updateFollow)}>Deny</button>
							</div>
						</Link>
					: <Link className="notificationContent" to={`/profile/${notification.followerId}`}>
						<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
						<div className="notificationMiddle">
							<div className="notificationText">{`${(notification.firstName + ' ' + notification.lastName)} has recently started following you.`}</div>
						</div>
					</Link>}

				</div>}

				{notification.type === "SAVE" && 
				<Link className="notificationContent" to={`/attraction/${notification.attractionId}`}>
					{notification.saveCount === "1" ? 
						<div className="notificationText fontSmall">{`${notification.saveCount} person has saved ${notification.attractionName} from your ${notification.locationName} location page.`}</div> : 
						<div className="notificationText fontSmall">{`${notification.saveCount} people have saved ${notification.attractionName} from your ${notification.locationName} location page.`}</div>}
				</Link> }
				{notification.type === "TRIP" && 
				<Link className="notificationContent" to={`/trip/${notification.tripId}`}>
					
					<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
					
					<div className="notificationMiddle">
						<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
					
						<div className="notificationText fontSmall">{`${(notification.firstName + ' ' + notification.lastName)} added you to the trip ${notification.tripName}`}</div>
						
					</div>
				</Link>}
				{notification.type === "TRIP_UPLOAD" && 
				<Link className="notificationContent" to={`/location/${notification.locationId}`}>
					
					<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
					
					<div className="notificationMiddle">
						<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
						{notification.tripUploadType === 'REVIEW' ? 
						<div className="notificationText fontSmall">{`${(notification.firstName + ' ' + notification.lastName)} added a review to their ${notification.tripLocName} page`}</div> :
						<div className="notificationText fontSmall">{`${(notification.firstName + ' ' + notification.lastName)} added ${notification.tripUploadType.toLowerCase()} to their ${notification.tripLocName} page`}</div>

						}
					</div>
				</Link>}
				{notification.type === "PICTURE_TAG" && 
				<div className="notificationContent" onClick={(e) => handleShowPicture(e, notification)}>
					
					<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
				
					<div className="notificationMiddle">
						<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
							
						<div className="notificationText fontSmall">{`${(notification.firstName + ' '  + notification.lastName + ' ')} has tagged you in a picture.`}</div>
						{notification.locationId && notification.attractionId && 
								<div className="notificationText fontSmall">{`It has been added to your ${notification.locationName} location page and your ${notification.attractionName} attraction page.`}</div>
						}
						{notification.locationId && !notification.attractionId && notification.pictureAttractionId && 
							<div>
								<div className="notificationMain">
									<div className="notificationText fontSmall">{`It has been added to your ${notification.locationName} location page`}</div>
								</div>
								<div className="notificationMain">
									{error && <div className="error">There was an error.</div>}
									<div className="notificationText fontSmall">{`Click to create a ${notification.pictureAttractionName} attraction on that page:`}</div>
									<button className="primaryButton" onClick={(e) => handleCreateLocationAndAttraction(e, notification.pictureLocationId, notification.pictureAttractionId, notification.locationId, createLocationAndAttractionCustom)}>Create</button>
								</div>
							</div>
						}

						{notification.locationId && !notification.attractionId && !notification.pictureAttractionId && 
							<div className="notificationText fontSmall">{`It has been added to your ${notification.locationName} location page.`}</div>
						}

						{!notification.locationId && !notification.attractionId && notification.pictureAttractionId && 
							<div className="notificationMain">
							    {error && <div className="error">There was an error.</div>}
								<div className="notificationText fontSmall">{`Click to create a ${notification.pictureLocationName} page with a ${notification.pictureAttractionName} attraction on that page:`}</div>
								<button className="primaryButton" onClick={(e) => handleCreateLocationAndAttraction(e, notification.pictureLocationId, notification.pictureAttractionId, null, createLocationAndAttractionCustom)}>Create</button>
							</div>
						}

						{!notification.locationId && !notification.attractionId && !notification.pictureAttractionId && 
							<div className="notificationMain">
							    {error && <div className="error">There was an error.</div>}
								<div className="notificationText fontSmall">{`Click to create a ${notification.pictureLocationName} location page:`}</div>
								<button className="primaryButton" onClick={(e) => handleCreateLocationAndAttraction(e, notification.pictureLocationId, null, null, createLocationAndAttractionCustom)}>Create</button>
							</div>
						}

					</div>
					
					<img className="picMedium squarePic" src={notification.pictureUrl} />
				</div>}
				{notification.type === 'TRIP_COMMENT' &&
				 <Link className="notificationContent" to={`/trip/${notification.tripId}`}>
					<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
						<div className="notificationMiddle">
							<div className="notificationText fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
							<div className="notificationText fontSmall">{`${(notification.firstName + ' ' +  notification.lastName)} has commented on the trip ${notification.tripName}`}</div>
						</div>	
				</Link>}
				{notification.type === 'TRIP_LOC_COMMENT' && 
				<Link className="notificationContent" to={`/tripLocation/${notification.tripLocId}`}>
		
					<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
					<div className="notificationMiddle">
						<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
						<div className="notificationText fontSmall">{`${(notification.firstName + ' ' + notification.lastName)} has commented on the trip location ${notification.tripLocName} within the ${notification.tripName} trip.`}</div>
					</div>
				</Link>}
				{notification.type === 'ATTRACTION_COMMENT' && 
				<Link className="notificationContent" to={`/attraction/${notification.attractionId}`}>
					
					<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
					<div className="notificationMiddle">
						<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
						<div className="notificationText fontSmall">{`${(notification.firstName + ' ' + notification.lastName)} has commented on your ${notification.attractionName} attraction.`}</div>
					</div>
				</Link>}
				{notification.type === "PICTURE_COMMENT" && 
					<div className="notificationContent" onClick={(e) => handleShowPicture(e, notification)}>
						<img className="picMedium" src={notification.publicUrl} alt="profPicture" />
			
						<div className="notificationMiddle">
							<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
							<div className="notificationText fontSmall">{`${(notification.firstName + ' ' + notification.lastName)} has commented on your picture.`}</div>
						</div>
						<img  className="picMedium squarePic" onClick={(e) => handleShowPicture(e, notification)} src={notification.pictureUrl} />
					</div>}
				{notification.type === "COMMUNICATIONS" && 
					<div className="notificationContent">
						<div className="notificationMiddle">

							<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
							<div className="notificationText fontSmall">{`${notification.communicationsContent}`}</div>
						</div>
					</div>}
				{notification.type === "TRIP_ADD" && 
					<div className="notificationContent">
						<img className="picMedium" src={notification.publicUrl} alt="profPicture" />

						<div className="notificationMiddle">

							<div className="fontSmall">{moment.utc(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
							<div className="notificationText fontSmall">{notification.tripAddComment ? `${notification.firstName} ${notification.lastName} is headed to ${notification.tripLocName}! ${notification.firstName} says "${notification.tripAddComment}" Help them make their trip great and upload any recs you might have.` : `${notification.firstName} ${notification.lastName} is headed to ${notification.tripLocName}! Help them make their trip great and upload any recs you might have.`}</div>
						</div>
					</div>}

	
			
		</div>
	);
};

export default Notification;
