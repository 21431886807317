import React from "react";
import { Mutation, compose, graphql } from "react-apollo";
import * as Yup from 'yup'
import { analytics } from '../index.js';

import AttractionDetails from '../components/AttractionDetails'

import { getCurrentUser } from "../apollo/state/queries"
import { CREATE_ATTRACTION_TAGS, DELETE_ATTRACTION_TAGS, UPDATE_ATTRACTION } from "../apollo/db/mutations"
import { GET_ATTRACTION_VIEW_BY_ID, GET_LOCATION_ATTRACTIONS } from "../apollo/db/queries"



class AttractionDetailsContainer extends React.Component {
	constructor() {
		super();
		this.state = {
		      attractionPublic: true,
		      attractionType: null,
		      hoverStars: 0,
		      loading: false, 
		      selectedStars: null,
		      selectedTags: [],
		      showTags: false, 
		      showTooltip: false, 
		      tagValue: '',
		      tooltipType: null
		    };
		       
    		this.handleChange = this.handleChange.bind(this)
    		this.handleChangeAttrType = this.handleChangeAttrType.bind(this)
    		this.handleDetailsLater = this.handleDetailsLater.bind(this)
    		this.handleHideTags = this.handleHideTags.bind(this)
    		this.handleHover = this.handleHover.bind(this)
    		this.handleRemoveTag = this.handleRemoveTag.bind(this)
    		this.handleSelectStar = this.handleSelectStar.bind(this)
    		this.handleSelectTag = this.handleSelectTag.bind(this)
    		this.handleShowTags = this.handleShowTags.bind(this)
    		this.handleToggleAttractionPublic = this.handleToggleAttractionPublic.bind(this)
    		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
    		this.handleUpdateAttraction = this.handleUpdateAttraction.bind(this)
   
	}

	componentDidMount(){
		const { attraction, attrType } = this.props
		const originalTags = attraction.tags ? attraction.tags.map(tag => tag.tag) : []
		const attractionPublic = attraction.visibility === 'PRIVATE' ? false : true;
		const attractionType = attraction.attractionType
		this.setState({selectedTags: originalTags, selectedStars: attraction.stars, attractionPublic: attractionPublic, attractionType: attractionType})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}

	async handleDetailsLater(){
		const { handleFinish } = this.props
		handleFinish()
		if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_attr_process', {
				  page: 'details', 
				  action: 'later'
				});	
			
		}
	}


	handleChange(event){
		this.setState({tagValue: event.target.value})
	}

	handleHover(stars){
		this.setState({
			hoverStars: stars
		})
	}

	handleShowTags(){
		this.setState({showTags: true})
	}

	handleHideTags(){
		this.setState({showTags: false})
	}

	handleSelectTag(tag){
		const { selectedTags } = this.state
		this.setState({selectedTags: [...selectedTags, tag]})
	}

	handleRemoveTag(tag){
		const { selectedTags } = this.state
		const newSelectedTags = selectedTags.filter(selectedTag => selectedTag !== tag)
		this.setState({selectedTags: newSelectedTags})
	}


	handleSelectStar(stars){
		this.setState({selectedStars: stars})
	}

	handleToggleAttractionPublic(){
		const { attractionPublic } = this.state
		this.setState({attractionPublic: !attractionPublic})
	}

	handleChangeAttrType(type){
		this.setState({attractionType: type})
	}


	async handleUpdateAttraction(values, updateAttraction, createAttractionTags, deleteAttractionTags, setSubmitting) {
		const { attraction, handleFinish, locationId, addAttraction } = this.props
		const { attractionPublic, attractionType, selectedStars, selectedTags } = this.state

		setSubmitting(true)

		const review = values.review.length ? values.review : null
		const tips = values.tips.length ? values.tips : null
		const visibility = attractionPublic ? 'PUBLIC' : 'PRIVATE'

		console.log('locationId', locationId)

		const originalTagInfo = attraction.tags ? attraction.tags : []
		const deletedIds = []
		originalTagInfo.forEach(tagInfo => {
			if (!selectedTags.includes(tagInfo.tag)){
				deletedIds.push(tagInfo.id)
			}
		})
		const originalTags = originalTagInfo.map(tagInfo => tagInfo.tag)
		const newTags = []
		selectedTags.forEach(tag => {
			if (!originalTags.includes(tag)){
				newTags.push(tag)
			}
		})

		try {
			if(newTags.length){
				await createAttractionTags({
					variables: {
						input: {
							attrId: attraction.id,
							newTags: newTags
						}
					}
				})
			}
			
			if(deletedIds.length){
				await deleteAttractionTags({
					variables: {
						input: {
							tagIds: deletedIds
						}
					}
				})
			}

			//this always fires and needs to be last so that it refreshes the queries
			await updateAttraction({
				variables: {
					input: {
						attrId: attraction.id,
						attrType: attractionType,
						locId: locationId,
						newStars: selectedStars,
						newReview: review, 
						newTips: tips,
						newVisibility: visibility
						}
					}
				})
			


			this.setState({ loading: false})
			setSubmitting(false)

			handleFinish(attraction.id)

			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_attr_details');
				if (addAttraction){
					await analytics.logEvent('add_attr_process', {
					  page: 'details', 
					  action: 'complete'
					});	
				}
				

			}
		} catch {
			console.log('error')
			setSubmitting(false)
		}
	} 

	renderAttractionDetails(){
		const { addAttraction, attraction, currentUser, closeText, handleFinish, submitText } = this.props
		const { 
			attractionPublic, 
			attractionType, 
			hoverStars, 
			showTags, 
			showTooltip,
			selectedStars, 
			selectedTags, 
			tagValue, 
			tooltipType 
		} = this.state

		const currentUserId = currentUser.id ? currentUser.id : 0

		const attractionInitialValues = {
			review: attraction.review ? attraction.review : '', 
			stars: attraction.stars ? attraction.stars : 0, 
			tips: attraction.tips ? attraction.tips : ''
		} 

		const attrDetailsSchema = Yup.object().shape({
		  	review: Yup.string()
		  	.max(2000, 'Review section is too long. Maximum of 2000 characters.'), 
		  	tips: Yup.string()
		  	.max(2000, 'Tips section is too long. Maximum of 2000 characters.'), 


		})

 
		return (<Mutation
	      mutation={UPDATE_ATTRACTION}
	      refetchQueries={[{query: GET_LOCATION_ATTRACTIONS, variables:{locId: attraction.locationId, currentUserId: currentUserId}}, 
	      {query: GET_ATTRACTION_VIEW_BY_ID, variables:{attrId: attraction.id, currentUserId: currentUserId}}]}
	    >
	        {(updateAttraction, { data, loading, error }) => {

		      	return (<Mutation
			      mutation={CREATE_ATTRACTION_TAGS}
			    >
			        {(createAttractionTags, { data, loading, error }) => {
		        		return <Mutation
					      mutation={DELETE_ATTRACTION_TAGS}
					    >
					        {(deleteAttractionTags, { data, loading, error }) => {
						      	return <AttractionDetails
									id="add-attraction-details"
									attraction={attraction}
									attractionPublic={attractionPublic} 
									attractionType={attractionType}
									attrDetailsSchema={attrDetailsSchema}
									closeText={closeText ? closeText : "Later"}
									createAttractionTags={createAttractionTags}
									deleteAttractionTags={deleteAttractionTags}
									error={error}
									handleChange={this.handleChange}
									handleChangeAttrType={this.handleChangeAttrType}
									handleFinish={addAttraction ? this.handleDetailsLater : handleFinish}
									handleHover={this.handleHover}
									handleRemoveTag={this.handleRemoveTag}
									handleSelectStar={this.handleSelectStar}
									handleSelectTag={this.handleSelectTag}
									handleShowTags={this.handleShowTags}
									handleSubmit={this.handleUpdateAttraction}
									hoverStars={hoverStars}
									handleToggle={this.handleToggleAttractionPublic}
									handleToggleTooltip={this.handleToggleTooltip}
									initialValues={attractionInitialValues}
									showTags={showTags}
									showTooltip={showTooltip}
									selectedStars={selectedStars}
									selectedTags={selectedTags}
									submitText={submitText ? submitText: "Add"}
									tagValue={tagValue}
									tooltipType={tooltipType}
									updateAttraction={updateAttraction}
						     		 />
					     		
					     	}}
					    </Mutation>
				    }}
				</Mutation>  
	      	)}}
	    </Mutation>)
	}
	
	render() {
		const { addAttraction } = this.state
		
		return (
			<div className="attractionDetailsContainer">
			
				{this.renderAttractionDetails()}
				
			</div>
				
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
}))(AttractionDetailsContainer);

