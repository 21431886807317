import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from "react-router-dom";

import "../stylesheets/components/Form.css"
import { Button } from 'reactstrap';

import initialPic from '../assets/globe.jpg'


const CheckKey = props => {
	const {
		handleCheckKey, 
		handleResendVerification, 
		verificationSent,
		initialValues,
	} = props

	return (
	<div className="checkKey initialScreen">
		<div className="initialScreenLeft">
			<div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">verify email</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
			<div className="marginBottom formAdditionalText">Check your email for your key (it might be in your spam or promotions folder)</div>
			{verificationSent && <div>Code Resent!</div>}

		<Formik
		      initialValues={initialValues}
		      onSubmit={(values, {setSubmitting}) => {
		        handleCheckKey(values, setSubmitting)
		      }}
		    >
		      {({ isSubmitting }) => (
		        <Form className="form">
		        	
		        	<div className="formItem">
			          	<label className="textTitle textTitleForm">key</label>
			          	<Field className="formField" type="verificationKey" name="verificationKey" autoComplete="off" required/>
		          	</div>
			          	<ErrorMessage name="verificationKey" component="div" required/>
				        
			
			          <button className="primaryButton singleFormButton" type="submit" disabled={isSubmitting}>
			           	Check Key
			          </button>

			         
			          <div className="progressDots">
				         	<div className="progressDot"></div>
				         	<div className="progressDot progressDotFilled"></div>
				         	<div className="progressDot"></div>
				         	<div className="progressDot"></div>
				         	<div className="progressDot"></div>
				         	<div className="progressDot"></div>
	   				  		
	   				  		
			      		  </div>
		        
		        </Form>
		      )}
		    </Formik>
		     <button onClick={() => handleResendVerification()} className="secondaryButton">
			           	Resend Verification Email
			          </button>
		 </div>
		 <div className="initialScreenRight">
			<img 
			className="initialPicture"
		 	src={require('../assets/globe.jpg')}
		 	alt="loginPicture"/>
		</div>

	</div>);
}

export default CheckKey;
