import React from "react";


const PictureAttractionSelection = props => {
	const { 
		handleSelectAttraction, 
		locAttractions, 
		mutationFunction
	} = props
	return (
		<div className="pictureAttractionSelection mainBackground selectDropdown">
			{locAttractions.length ? locAttractions.map(attraction => {
				return <div className="fontXS textLink" key={attraction.id} onClick={() => handleSelectAttraction(attraction, mutationFunction)}>{attraction.name}</div>
			}) : 
			<div>You currently have no places for this location</div>}
		</div>

		
	
		
	);
};

export default PictureAttractionSelection;
