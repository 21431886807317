import React from "react";
import { compose, graphql, Query, Mutation, withApollo} from "react-apollo";
import moment from "moment";
import * as Yup from "yup";

import history from "../history"
 
import Deletion from "../components/Deletion"
import ExportItinerary from "../components/ExportItinerary"
import LocItinerary from "../components/LocItinerary";
import ItineraryDropZone from "../components/ItineraryDropZone";
import ItineraryItem from "../components/ItineraryItem";
import ItineraryItemDetails from "../components/ItineraryItemDetails"
import ItineraryItemForm from "../components/ItineraryItemForm"
import ItineraryItemLocation from "../components/ItineraryItemLocation"
import MapView from "../components/MapView"
import TripItinerary from "../components/TripItinerary";
import TripLocAttractionsContainer from "./TripLocAttractionsContainer";

import { 
	GET_ITINERARY_ITEMS, 
	GET_TRIP_BY_ID, 
	GET_TRIP_GENERAL_ATTRACTIONS,
	GET_TRIP_ITINERARY_ITEMS } from "../apollo/db/queries"
import { getCurrentUser } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import { 
	ADD_ITINERARY_ITEM,
	CHANGE_ITINERARY_ITEM_DATE,
	REMOVE_ITINERARY_ITEM, 
	INSERT_ITINERARY_ITEM, 
	MOVE_ITINERARY_ITEM_BACKWARD, 
	MOVE_ITINERARY_ITEM_FORWARD, 
	UPDATE_ITINERARY_ITEM
	 } from "../apollo/db/mutations"

class ItineraryContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addToDate: null,
			addType: 'title',
			changeDatesOption: false,
			chosenTripLocId: null,
			datesList: [],
			datesStartIndex: 0,
			deletionOrderNumber: null,
			deletionId: null,
			deletionName: null,
			deletionTripDate: null, 
			deletionTripLocId: null,
			editItem: null,
			isEditingItinerary: false, 
			mapView: 'all',
			originalDate: null,
			selectedDate: null, 
			showAddItem: false, 
			showDelete: false,
			showEdit: false, 
			showItemDetails: false,
			showLocDates: false,
			showSelectLocation: false,
			stateError: null,
			toggledAttractionId: null, 
			tripDatesStartIndex: 0, 
			viewedItineraryItem: null,
		}

		this.handleChangeDate = this.handleChangeDate.bind(this)
		this.handleChangeChosenLoc = this.handleChangeChosenLoc.bind(this)
		this.handleAddItineraryItem = this.handleAddItineraryItem.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleDrop = this.handleDrop.bind(this)
		this.handleHideAddItem = this.handleHideAddItem.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleHideItemDetails = this.handleHideItemDetails.bind(this)
		this.handleHideSelectLocation = this.handleHideSelectLocation.bind(this)
		this.handleLeftDateArrow = this.handleLeftDateArrow.bind(this)
		this.handleLeftTripDateArrow = this.handleLeftTripDateArrow.bind(this)
		this.handleRightDateArrow = this.handleRightDateArrow.bind(this)
		this.handleRightTripDateArrow = this.handleRightTripDateArrow.bind(this)
		this.handleSelectLocation = this.handleSelectLocation.bind(this)
		this.handleShowAddItem = this.handleShowAddItem.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleShowItemDetails = this.handleShowItemDetails.bind(this)
		this.handleShowLocDates = this.handleShowLocDates.bind(this)
		this.handleShowSelectLocation = this.handleShowSelectLocation.bind(this)
		this.handleToggleAttraction = this.handleToggleAttraction.bind(this)
		this.handleToggleIsEditingItinerary = this.handleToggleIsEditingItinerary.bind(this)
		this.handleToggleMapView = this.handleToggleMapView.bind(this)
		this.handleToggleAddType = this.handleToggleAddType.bind(this)
		this.handleUpdateItineraryItem = this.handleUpdateItineraryItem.bind(this)
		this.renderItineraryDropZone = this.renderItineraryDropZone.bind(this)
	}

	componentDidMount(){
		const { dates } = this.props
		if ((dates && dates[0] !== null)){
			const datesList = this.getDisplayDates(dates)
			//dates must be local time to be compared/minipulated but diplsayed as UTC b/c dates should be consistent across tiemezones
			this.setState({selectedDate: new Date(dates[0].startDate), datesList: datesList})
		}
	}


	getItineraryPins(items){
		return items.filter(item => {
			return item.lat
		})
	}

	 getDisplayDates(dates){
	 	console.log('dates', dates)
		let displayDates = []
		dates && dates.forEach(dateSet => {
			let date = new Date(dateSet.startDate)
			while(date <= new Date(dateSet.endDate)){
				displayDates.push(date)
				let nextDate = new Date(date);
				nextDate.setDate(nextDate.getDate() + 1);
				date = nextDate;
			}
		})
		return displayDates
	}

	getTripDisplayDates(startDate, endDate){
		let displayDates = []
		let date = new Date(startDate)
			while(date <= new Date(endDate)){
				displayDates.push(date)
				let nextDate = new Date(date);
				nextDate.setDate(nextDate.getDate() + 1);
				date = nextDate;
			}
		return displayDates
	}

	handleChangeDate(date){
		this.setState({selectedDate: date})
	} 

	async handleShowAddItem(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAddItem: true})
	}

	async handleHideAddItem(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showAddItem: false, 
			stateError: null, 
			changeDatesOption: false, 
			showLocDates: false
		})
	}

	handleToggleAddType(type){
		this.setState({addType: type})
	}

	async handleShowDelete(item){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({
			showDelete: true, 
			deletionId: item.id, 
			deletionOrderNumber: item.orderNumber, 
			deletionName: item.title ? item.title : item.name,
			deletionTripDate: item.tripDate, 
			deletionTripLocId: item.tripLocationId
		})
	}

	async handleShowItemDetails(item){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showItemDetails: true, viewedItineraryItem: item})
	}

	async handleHideItemDetails(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showItemDetails: false, viewedItineraryItem: null})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showDelete: false, 
			deletionId: null,
			deletionOrderNumber: null, 
			deletionName: null,
			deletionTripDate: null, 
			deletionTripLocId: null
		})
	}

	handleShowLocDates(){
		this.setState({showLocDates: true})
	}

	handleHideLocDates(){
		this.setState({showLocDates: false})
	}

	async handleShowEdit(itineraryItem){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({
			showEdit: true, 
			editItem: itineraryItem, 
			originalDate: itineraryItem.tripDate, 
			showItemDetails: false, 
			viewedItineraryItem: null
		})
	}

	async handleHideEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showEdit: false, 
			editItem: null,
			stateError: null, 
			changeDatesOption: false, 
			showLocDates: false
		})
	}

	handleShowSelectLocation(event, date){
		if (event){
			if (event.target === event.currentTarget){
				this.setState({showSelectLocation: true, addToDate: date})
			} 	
		} else {
			this.setState({showSelectLocation: true})
		}
	}

	handleHideSelectLocation(){
		this.setState({
			showSelectLocation: false
		})
	}

	handleToggleMapView(view){
		this.setState({mapView: view})
	}

	handleChangeChosenLoc(tripLocId){
		this.setState({
			chosenTripLocId: tripLocId
		})
	}

	handleRightDateArrow(){
		const { datesStartIndex, selectedDate } = this.state
		const newDate = new Date(selectedDate)
        newDate.setDate(newDate.getDate() + 1)
		this.setState({ datesStartIndex: datesStartIndex + 1, selectedDate: newDate  }) 
	}

	handleRightTripDateArrow(){
		const { tripDatesStartIndex } = this.state
		this.setState({ tripDatesStartIndex: tripDatesStartIndex + 14 }) 
	}

	
	handleLeftDateArrow(){
		const { datesStartIndex, selectedDate } = this.state
		const newDate = new Date(selectedDate)
        newDate.setDate(newDate.getDate() - 1)
		this.setState({ datesStartIndex: datesStartIndex - 1, selectedDate: newDate })
	}

	handleLeftTripDateArrow(){
		const { tripDatesStartIndex } = this.state
		this.setState({ tripDatesStartIndex: tripDatesStartIndex - 14 }) 
	}

	handleToggleIsEditingItinerary(){
		const { isEditingItinerary } = this.state
		this.setState({isEditingItinerary: !isEditingItinerary})
	}

	handleToggleAttraction(attractionId){
		const {toggledAttractionId} = this.state
		if (toggledAttractionId){
			this.setState({toggledAttractionId: null})
		} else {
			this.setState({toggledAttractionId: attractionId})
		}
	}

	handleSelectLocation(values){
		const tripLocId = values.tripLocation
		console.log('tripLocId', tripLocId)
		if (!tripLocId || tripLocId === 'choose'){
			this.setState({stateError: "Please select a location"})
		} else {

			this.setState({chosenTripLocId: Number(tripLocId), showSelectLocation: false, showAddItem: true})
		}
	}

	getItemsByDate(tripItineraryItems){
		const itineraryItemsByDate = tripItineraryItems.reduce((accum, item) => {
				if (accum[new Date(item.tripDate)]){
					accum[new Date(item.tripDate)].push(item)
					return accum
				} else {
					let tripDate = item.tripDate
					accum[new Date(tripDate)] = [item]
					return accum
				}
			}, {})

		Object.keys(itineraryItemsByDate).forEach(date => {
			itineraryItemsByDate[date].sort((itemA, itemB) => {
				if (itemA.tripLocationId !== itemB.tripLocationId){
					let dayBefore = new Date(itemA.tripDate);
					dayBefore.setDate(dayBefore.getDate() - 1);

					let dayAfter = new Date(itemA.tripDate);
					dayAfter.setDate(dayAfter.getDate() + 1);
				
					if (itineraryItemsByDate[dayBefore]){
						let itemsDayBeforeA = itineraryItemsByDate[dayBefore].filter(item => {
						return item.tripLocationId === itemA.tripLocationId
						})

						let itemsDayBeforeB = itineraryItemsByDate[dayBefore].filter(item => {
							return item.tripLocationId === itemB.tripLocationId
						})
						return itemsDayBeforeB.length - itemsDayBeforeA.length;
					} else if (itineraryItemsByDate[dayAfter]){
				
							let itemsDayAfterA = itineraryItemsByDate[dayAfter].filter(item => {
							return item.tripLocationId === itemA.tripLocationId
							})

							let itemsDayAfterB = itineraryItemsByDate[dayAfter].filter(item => {
								return item.tripLocationId === itemB.tripLocationId
							})
							return itemsDayAfterA.length - itemsDayAfterB.length
						
					} else {
						return 0;
					}
					
				
				} else {
					return 0;
				}
			})
		})
		return itineraryItemsByDate
	}

	async handleDrop(item, position, moveItineraryItemForward, moveItineraryItemBackward, insertItineraryItem){
		const { tripLocId } = this.props
		const { selectedDate } = this.state
		console.log('position', position)
		if (position !== item.orderNumber && position !== item.orderNumber - 1){
			if (item.type === 'existing' && position > item.orderNumber){
				try {
					await moveItineraryItemForward({
						variables: {
							input: {
							 	itineraryItemId: item.id,
							 	newPosition: position, 
							 	oldPosition: item.orderNumber, 
							 	itemTripDate: selectedDate, 
							 	tripLocId: tripLocId	
							}
						}
					})
				} catch {
					console.log('error')
				}
			} else if (item.type === 'existing' && position < item.orderNumber) {
				try {
					await moveItineraryItemBackward({
						variables: {
							input: {
							 	itineraryItemId: item.id,
							 	newPosition: position + 1, 
							 	oldPosition: item.orderNumber, 
							 	itemTripDate: selectedDate, 
							 	tripLocId: tripLocId		
							}
						}
					})
				} catch {
					console.log('error')
				}
			} else if (item.type === 'new'){
				try {
					await insertItineraryItem({
						variables: {
							input: {
							 	generalAttrId: item.id,
							 	tripLocId: tripLocId, 
							 	selectedPosition: position + 1, 
							 	selectedTripDate: selectedDate	
							}
						}
					})
				} catch {
					console.log('error')
				}
			}

		}
	

	}

	async handleUpdateItineraryItem(values, updateItineraryItem, changeItineraryItemDate){
		const {  datesList, editItem, originalDate, selectedDate } = this.state
		const { client, dates, setPopup, trip, tripLocations, tripLocId } = this.props
	
		const title = values.title.length ? values.title : null
		const attractionId = editItem ? editItem.tripGeneralAttractionId : values.tripAttraction
		const startTime = values.startTime ? values.startTime : null
		const endTime = values.endTime ? values.endTime : null
		const note = values.note ? values.note : null
		const tripDate = values.tripDate
		const tripLocationId = editItem.tripLocationId
		

		const acceptableDates = !trip ? this.getDisplayDates(dates) : this.getDisplayDates(tripLocations.find(loc => tripLocationId === loc.id).dates)

		if (!title && (attractionId === 'choose' || attractionId === undefined)) {
			this.setState({stateError: "Please create a title or choose an attraction"})
		} else if (!values.tripDate){
			this.setState({stateError: "Please select a date"})
		} else if ((!acceptableDates.find(date => date.getTime() === new Date(values.tripDate).getTime()))){
			console.log('is this setting?')
			this.setState({stateError: "The date you selected is not a date for this location", changeDatesOption: true})
		} else {

			try {
				await updateItineraryItem({
					variables: {
						input: {
							id: editItem.id, 
							itineraryItemPatch: {
								tripGeneralAttractionId: attractionId,
								tripDate: tripDate, 
								tripLocationId: tripLocationId, 
								orderNumber: editItem.orderNumber,
								title: title,
								startTime: startTime,
								endTime: endTime, 
								note: note, 
							}
						}	
					}
				})


				if (new Date(tripDate).getTime() !== new Date(originalDate).getTime()){
					try {
						await changeItineraryItemDate({
							variables: {
								input: {
									tripLocId: tripLocationId,
									itineraryItemId: editItem.id, 
									itemOrderNumber: editItem.orderNumber, 
									oldTripDate: editItem.tripDate,
									newTripDate: tripDate
								}
							}
						})


						client.query({
							query: GET_ITINERARY_ITEMS, 
							variables: {tripLocId: tripLocId, selectedTripDate: new Date(tripDate)},
							fetchPolicy: 'network-only'
						})

					} catch {
						console.log('error')
					}
				}
				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ showEdit: false, editItem: null, stateError: null, changeDatesOption: false, showLocDates: false})
			} catch {
				console.log('error')
			}
		}
		
	}
	

	async handleDelete(mutationFunction){
		const { deletionId, deletionOrderNumber, deletionTripDate, deletionTripLocId } = this.state
		const { setPopup } = this.props
		try {
				await mutationFunction({
					variables: {
						input: {
							itineraryItemId: deletionId, 
							itemOrderNumber: deletionOrderNumber, 
							tripLocId: deletionTripLocId,
							itemTripDate: deletionTripDate
							}
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({showDelete: false, deletionId: null, showItemDetails: false, viewedItineraryItem: null})
			} catch {
				console.log('error')
			}
	}


	async handleAddItineraryItem(values, addItineraryItem) {
		const { dates, setPopup, trip, tripLocations, tripLocId } = this.props
		const { chosenTripLocId, datesList, selectedDate, viewedItineraryItem } = this.state

		const title = values.title.length ? values.title : null
		const attractionId = values.tripAttraction ? values.tripAttraction : null
		const startTime = values.startTime ? values.startTime : null
		const endTime = values.endTime ? values.endTime : null
		const note = values.note ? values.note : null
		const tripDate = values.tripDate
		const tripLocationId = tripLocId ? tripLocId : chosenTripLocId
	
		const acceptableDates = !trip ? this.getDisplayDates(dates) : this.getDisplayDates(tripLocations.find(loc => chosenTripLocId === loc.id).dates)

		if (!title && (attractionId === 'choose' || attractionId === undefined)) {
			this.setState({stateError: "Please create a title or choose an attraction"})
		} else if (!values.tripDate){
			this.setState({stateError: "Please select a date"})
		} else if ((!acceptableDates.find(date => date.getTime() === new Date(values.tripDate).getTime()))){
			console.log('is this setting?')
			this.setState({stateError: "The date you selected is not a date for this location", changeDatesOption: true})
		} else {

			try {
				await addItineraryItem({
					variables: {
						input: {
							tripLocId: tripLocationId,
							attrId: attractionId && attractionId !== 'choose' ? Number(attractionId) : null,
							addedTitle: title ? title: null, 
							addedNote: note, 
							addedTripDate: tripDate,
							addedStartTime: startTime, 
							addedEndTime: endTime
							  
							}	
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ 
					showAddItem: false, 
					stateError: null, 
					changeDatesOption: false, 
					showLocDates: false
				})
			} catch {
				console.log('error')
			}
		}
		

	}

	renderDelete(){
		const { tripId, tripLocId } = this.props
		const { deletionName, selectedDate } = this.state
		console.log('deletionName', deletionName)
		return <Mutation
	      	mutation={REMOVE_ITINERARY_ITEM}
	      	refetchQueries={[{query: GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}}, 
	      	{query:GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
	      	>
	      {(removeItineraryItem, { data, loading, error }) => {
	      		return <Deletion
	      		    error={error}
	      			handleDelete={this.handleDelete}
	      			handleHideDelete={this.handleHideDelete}
	      			mutationFunction={removeItineraryItem}
	      			name={deletionName}
	      			type="itineray item"
	      		 />

	      
	      }}
	    </Mutation>

	}

	renderItemDetails(){
		const { viewedItineraryItem } = this.state
		return <ItineraryItemDetails 
			handleClose={this.handleHideItemDetails}
			handleShowDelete={this.handleShowDelete}
			handleShowEdit={this.handleShowEdit}
			item={viewedItineraryItem}

			 />
	}

	renderExport(itineraryItemsByDate, tripDates){
		const { handleHideExportItinerary } = this.props
		return <ExportItinerary 
				handleClose={handleHideExportItinerary}
				itineraryItemsByDate={itineraryItemsByDate}
				tripDates={tripDates}
			/>
	
	}

	renderItineraryDropZone(position, last){
		const { tripId, tripLocId } = this.props
		const { selectedDate } = this.state

		//const selectedTripDate = new Date(selectedDate)

		return <Mutation
	      	mutation={INSERT_ITINERARY_ITEM}
	      	refetchQueries={[{query: GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}}, 
	      	{query:GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
	     >
	      	{(insertItineraryItem, { data, loading, error }) => {
	      		return <Mutation
			      	mutation={MOVE_ITINERARY_ITEM_BACKWARD}
			      	refetchQueries={[{query: GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}}, 
			      	{query:GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
			    >
			    	{(moveItineraryItemBackward, { data, loading, error }) => {
			    		return <Mutation
					      	mutation={MOVE_ITINERARY_ITEM_FORWARD}
					      	refetchQueries={[{query: GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}}, 
					      	{query:GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
					    >
					    	{(moveItineraryItemForward, { data, loading, error }) => {
					      		return <div>
					      			<ItineraryDropZone
					      			    error={error} 
										handleDrop={this.handleDrop}
										insertItineraryItem={insertItineraryItem}
										last={last}
										moveItineraryItemBackward={moveItineraryItemBackward}
										moveItineraryItemForward={moveItineraryItemForward}
										position={position}

									/>

					      		</div>
					      	}}
					    </Mutation>
			      	}}

			    </Mutation>

	      
	     	}}
	    </Mutation>
	}


	renderTripItinerary(){
		const { chosenTripLocId, isEditingItinerary, tripDatesStartIndex } = this.state
		const { exportItinerary, trip, tripId } = this.props
			return <Query query={GET_TRIP_ITINERARY_ITEMS} variables={{viewedTripId: tripId}}>
	  			{({ loading, error, data }) => {
		  			if (loading) {return <div> Loading... </div>}
		  			if (error) {return <div>Error</div>}

		  	
					const tripItineraryItems = data.tripItineraryItems.nodes

					const itineraryItemsByDate = this.getItemsByDate(tripItineraryItems)

					const tripLocIds = tripItineraryItems.reduce((accum, item) => {
						if (!accum.includes(item.tripLocationId)){
							accum.push(item.tripLocationId)
						}
						return accum
					}, []) 

					const tripDates = this.getTripDisplayDates(trip.startDate, trip.endDate)


					return <div>
						
						{exportItinerary && this.renderExport(itineraryItemsByDate, tripDates)}
						<TripItinerary
							handleLeftTripDateArrow={this.handleLeftTripDateArrow} 
							handleRightTripDateArrow={this.handleRightTripDateArrow}
							handleShowAdd={this.handleShowSelectLocation}
							handleShowDelete={this.handleShowDelete}
							handleShowEdit={this.handleShowEdit}
							handleShowItemDetails={this.handleShowItemDetails}
							handleToggleIsEditingItinerary={this.handleToggleIsEditingItinerary}
							isEditingItinerary={isEditingItinerary}
							itineraryItemsByDate={itineraryItemsByDate}
							tripDates={tripDates}
							tripDatesStartIndex={tripDatesStartIndex}
							tripLocIds={tripLocIds}
						/>
					</div>
						
				}}
			</Query>

	}


	renderItinerary(){
		const { dates, isEditing, tripId, tripLocId, tripLocation, view } = this.props
		const { datesList, datesStartIndex, error, isEditingItinerary, mapView, selectedDate, showAddItem, toggledAttractionId } = this.state

		return  <Query query={GET_ITINERARY_ITEMS} variables={{tripLocId: tripLocId, selectedTripDate: selectedDate}}>
  			{({ loading, error, data }) => {
	  			if (loading) {return <div> Loading... </div>}
	  			if (error) {return <div>Error</div>}

				const itineraryItems = data.itineraryItems.nodes

				const itineraryItemsCount = itineraryItems.length
				console.log('dates in itinerary container', dates)
				
				return <div className='tripLocItinerary'>
					<div className="tripLocItineraryLeft">
						<TripLocAttractionsContainer 
							isEditing={isEditing}
							handleToggleMapView={this.handleToggleMapView}
							itineraryPins={this.getItineraryPins(itineraryItems)}
							mapView={mapView}
							tripId={tripId}
							tripLocId={tripLocId}
							tripLocation={tripLocation}
							view={view}
						/>
					</div>

					<div className="tripLocItineraryRight">
					 {(dates && dates[0] !== null ? <LocItinerary 
							datesList={this.getDisplayDates(dates)}
							datesStartIndex={datesStartIndex}
							error={error}
							handleChangeDate={this.handleChangeDate}
							handleDrop={this.handleDrop}
							handleLeftDateArrow={this.handleLeftDateArrow}
							handleRightDateArrow={this.handleRightDateArrow}
							handleShowAddItem={this.handleShowAddItem}
							handleShowDelete={this.handleShowDelete}
							handleShowEdit={this.handleShowEdit}
							itineraryItems={itineraryItems}
							itineraryPins={this.getItineraryPins(itineraryItems)}
							renderItineraryItem={this.renderItineraryItem}
							renderItineraryDropZone={this.renderItineraryDropZone}
							selectedDate={selectedDate}
							tripId={tripId}
						/> : 
						<div>Add dates for this location to create an itinerary.</div>)}
						
					</div>
				</div>

				
			}}
		</Query>
	}


	renderSelectLocation(){
		const { stateError } = this.state
		const { tripLocations } = this.props

		const initialValues = {
			tripLocation: ''
		}

		return <ItineraryItemLocation 
			handleClose={this.handleHideSelectLocation}
			handleSubmit={this.handleSelectLocation}
			initialValues={initialValues}
			stateError={stateError}
			tripLocations={tripLocations}
		/>
	}

	renderAddItem(){
		const { addToDate,changeDatesOption, chosenTripLocId, selectedDate, showLocDates, stateError, addType } = this.state
		const { dates, tripId, tripLocations, tripLocId, view } = this.props

		const initialValues = {
			title: '', 
			startTime: '', 
			endTime: '',
			note: '',
			tripAttraction: '', 
			tripDate: selectedDate ? moment.utc(selectedDate).format('YYYY-MM-DD') :  (addToDate ? moment.utc(addToDate).format('YYYY-MM-DD') : '')
		}

		const itinItemSchema = Yup.object().shape({
		  title: Yup.string()
		  	.max(40, 'Title is too long. Maximum of 40 characters.'),
		  note: Yup.string()
		  	.max(200, 'Note section is too long. Maximum of 200 characters.')

		})


		return <Mutation 
			mutation={ADD_ITINERARY_ITEM}
		    refetchQueries={[{query:GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}}, 
		    {query: GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
		>
		 	{(addItineraryItem, { data, loading, error }) => {

		 		const tripLocationId = chosenTripLocId ? chosenTripLocId : tripLocId
				return <Query query={GET_TRIP_GENERAL_ATTRACTIONS} variables={{tripLocId: tripLocationId}}>
		  			{({ loading, error, data }) => {
			  			if (loading) {return <div> Loading... </div>}
			  			if (error) {return <div>Error</div>}

			  			const tripAttractions = data.tripGeneralAttractions.nodes

						return <ItineraryItemForm 
								action="add"
								addType={addType}
								changeDatesOption={changeDatesOption}
								chosenTripLocId={chosenTripLocId}
								dates={dates}
								error={error}
								handleChangeChosenLoc={this.handleChangeChosenLoc}
								handleClose={this.handleHideAddItem}
								handleShowLocDates={this.handleShowLocDates}
								handleSubmit={this.handleAddItineraryItem}
								handleToggleAddType={this.handleToggleAddType}
								initialValues={initialValues}
								mutationFunction={addItineraryItem}
								showLocDates={showLocDates}
								stateError={stateError}
								tripAttractions={tripAttractions}
								tripId={tripId}
								tripLocations={tripLocations}
								tripLocId={tripLocId ? tripLocId : chosenTripLocId}
								type="nonAttractionItem"
								validationSchema={itinItemSchema}
								view={view}
						/>
					}}
				</Query>
			}}
		</Mutation>
	}

	renderEditItem(){
		const { changeDatesOption, editItem, error, selectedDate, showLocDates, stateError } = this.state
		const { dates, tripId, tripLocations, tripLocId } = this.props

		const initialValues = {
			title: editItem.title ? editItem.title : '', 
			startTime: editItem.startTime ? editItem.startTime : '', 
			endTime: editItem.endTime ? editItem.endTime : '',
			note: editItem.note ? editItem.note : '', 
			tripDate: editItem.tripDate
		}

		const itinItemSchema = Yup.object().shape({
		  title: Yup.string()
		  	.max(40, 'Title is too long. Maximum of 40 characters.'),
		  note: Yup.string()
		  	.max(200, 'Note section is too long. Maximum of 200 characters.')

		})

			return <Mutation 
			mutation={UPDATE_ITINERARY_ITEM}
		    refetchQueries={[{query:GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}},
		    {query:GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
		>
		 	{(updateItineraryItem, { data, loading, error }) => {

		 		return <Mutation 
					mutation={CHANGE_ITINERARY_ITEM_DATE}
				    refetchQueries={[{query:GET_ITINERARY_ITEMS, variables: {tripLocId: tripLocId, selectedTripDate: selectedDate}}, 
				    {query:GET_TRIP_ITINERARY_ITEMS, variables: {viewedTripId: tripId}}]}
				>
				 	{(changeItineraryItemDate, { data, loading, error }) => {

						return <ItineraryItemForm 
								action="edit"
								attractionName={editItem.name}
								changeDatesOption={changeDatesOption}
								changeItineraryItemDate={changeItineraryItemDate}
								dates={dates}
								error={error}
								handleClose={this.handleHideEdit}
								handleShowLocDates={this.handleShowLocDates}
								handleSubmit={this.handleUpdateItineraryItem}
								initialValues={initialValues}
								mutationFunction={updateItineraryItem}
								showLocDates={showLocDates}
								stateError={stateError}
								tripLocId={editItem.tripLocationId}
								validationSchema={itinItemSchema}
							/>
					}}
				</Mutation>
			}}
		</Mutation>

	}


	render() {
		const { dates, trip, tripLocations, view } = this.props
		const { selectedDate, showAddItem, showDelete, showEdit, showItemDetails, showSelectLocation } = this.state
		return (
			<div className="itineraryContainer">
				{showDelete && this.renderDelete()}
				{showEdit && this.renderEditItem()}
				{showAddItem && this.renderAddItem()}
				{showItemDetails && this.renderItemDetails()}
				{showSelectLocation && this.renderSelectLocation()}
			
				<div>
				{view === 'tripLocItinerary' && this.renderItinerary()}
				{view === 'tripItinerary' && ((trip.startDate && trip.endDate && tripLocations.length) ? <div>{this.renderTripItinerary()}</div> : 
				<div>Make sure your trip has a start date, an end date, and atleast one trip location to create an itinerary.</div>)}
				</div>

			</div>
		);
	}
}

export default compose(withApollo, 
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
	)(ItineraryContainer);
