import React from "react";
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'

import "../stylesheets/components/GroupedAttractionsList.css"

import Attraction from "./Attraction"
import ItineraryItem from "./ItineraryItem"
import TripAttractionVoteContainer from "../containers/TripAttractionVoteContainer"

const GroupedAttractionsList = props => {
	const { 
		attractions,
		attractionId,
		attractionReviews,
		chosenType,
		collapseId,
		collapsePlaceId,
		currentUser,
		deleteTripAttraction,
		isEditing,
		handleSelectAttraction,
		handleShowAddAttraction,
		handleShowDelete,
		handleShowDeleteTripAttr,
		handleShowVoters,
		handleToggleIsEditing,
		handleToggleType,
		handleToggleView,
		handleToggleAttraction,
		sortBy,
		tripId,
		tripLocId,
		tripLocView,
		view
	 } = props

		const getStars =  (count, className) => {
			const stars = []
			let filledStars, halfStar;
			if (count % 1 >= 0.75){
				filledStars = Math.round(count);
				halfStar = false;
			} else if (count % 1 < 0.75 && count % 1 >= 0.25){
				filledStars = (Math.floor(count));
				halfStar = true;
			} else {
				filledStars = (Math.floor(count));
				halfStar = false;
			}
			while(stars.length < filledStars){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}  
			if (halfStar){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>half_star</i>)
			}
			while (stars.length < 5){
					stars.push(<i className={`material-icons nonClickableInactiveIcon ${className}`}>star</i>)
			}
			 
		
			return stars
	}



	const getIcon = attractionType => {
			if (attractionType === "ACTIVITY"){
				return <i className={'material-icons nonClickableIcon iconMedium'}>local_activity</i>
				
			} else if (attractionType === 'RESTAURANT'){
				return <div className="restaurantIcons">
					<i className={'material-icons nonClickableIcon iconMedium'}>local_bar</i>
					<i className={'material-icons nonClickableIcon iconMedium'}>restaurant</i>
				</div>
			} else if (attractionType === "LODGING") {
				return <i className={'material-icons nonClickableIcon iconMedium'}>hotel</i>
			}
	}

	const selectedDropdown = dropdownValue => dropdownValue === sortBy ? true : false
	const selectedType = type => type === chosenType && 'tabSelected'
	
	return (

		<div className="groupedAttractionsList">
			<div className="groupedAttractionsHeader">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">activities, lodging, food & drinks</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				{view === 'overallLocation' && <div className="dropdownSection">
					<div className="textTitle">Sort By:</div>
						<select className="dropdownSelect" onChange={(e) => handleToggleView(e)}>
							<option value="ranked" selected={selectedDropdown('ranked')}>Top Ranked</option>
							<option value="visited" selected={selectedDropdown('visited')}>Most Visited</option>
						</select>
				</div>}
				{view === 'tripLocation' && <div className="groupedHeaderButtons">
					<i className={'material-icons activeIcon iconMedium'} onClick={() => handleShowAddAttraction()}>add-location</i>
					{isEditing ? <button onClick={() => handleToggleIsEditing()}>
						Cancel
					</button> :
					<i className={'material-icons activeIcon iconMedium'} onClick={() => handleToggleIsEditing()}>edit</i>}
				</div>}
			</div>
				<div className="groupedAttractionsContent">
					<div className="tabButtons">
						<div className={`tabButton ${selectedType('ALL')}`} onClick={()=>handleToggleType('ALL')}>All</div>
						<div className={`tabButton ${selectedType('ACTIVITY')}`} onClick={()=>handleToggleType('ACTIVITY')}>Activities</div>
						<div className={`tabButton ${selectedType('LODGING')}`} onClick={()=>handleToggleType('LODGING')}>Lodging</div>
						<div className={`tabButton ${selectedType('RESTAURANT')}`} onClick={()=>handleToggleType('RESTAURANT')}>Food & Drinks</div>
					</div>
					<div className="attractionsTable mainBackground">
						{attractions.length ? attractions.map(attraction=> {
							const number = (attractions.indexOf(attraction) + 1).toString() + ". "
							return (<div key={attraction.name}>
								<div className="groupedAttraction">
									<div className="groupedAttractionLeft">
										{(view === 'tripLocation' && isEditing) && <div onClick={() => handleShowDelete(attraction, 'tripGeneralAttraction')} className="removeIcon">-</div>}
										
										{(collapsePlaceId === attraction.placeId || (view === 'tripLocation' && collapseId === attraction.id)) ? 
											<i className={'material-icons activeIcon iconMedium'} onClick={() =>handleToggleAttraction()}>expand_more</i>
											: <div>{view === 'tripLocation' ? 
											
												<i className={'material-icons activeIcon iconMedium'} onClick={() =>handleToggleAttraction(attraction.id, chosenType)}>chevron_right</i>
												: <i className={'material-icons activeIcon iconMedium'} onClick={() =>handleToggleAttraction(attraction.placeId, chosenType)}>chevron_right</i>}
	
											</div>
										}
										<div className="headerAndIcons">
											{(view === 'tripLocation' && tripLocView ==='tripLocItinerary') ?
												 <div>
												 	<ItineraryItem itineraryItem={attraction} type="new"/>
												 </div> :
											
												<Link className="textLink groupedAttrName fontLarge" to={`/attractionView/${attraction.placeId}`}>{number + attraction.name}</Link> 
											}
											
											<div className="groupedAttractionIcons">
													{attraction.attractionTypes.map(type => getIcon(type))}
											</div>
										</div>
									</div>
									<div className="groupedAttractionStars">{view === 'overallLocation' && (attraction.overallStars ? <div className="stars">
											{getStars(attraction.overallStars, 'star')}
									</div> : <div className="noRankings">No rankings available</div>)}</div>
									{view === 'tripLocation' && <TripAttractionVoteContainer 
										handleShowVoters={handleShowVoters}
										tripId={tripId}
										tripLocId={tripLocId} 
										tripGeneralAttrId={attraction.id}
										votes={attraction.votes}
										userVote={attraction.userVote}
										userVoteId={attraction.userVoteId}
									/>}
									
							   	</div>
							    <div>
								    <Collapse
							          isOpen={collapsePlaceId === attraction.placeId || (view === 'tripLocation' && collapseId === attraction.id)}
							        >
							         {attraction.reviews && !!attraction.reviews.length && attraction.reviews[0] !== null && 
							         	attraction.reviews.map(attr=> {
								         	return <div>
									            <div>
									              <Attraction 
													key={attr.id}
													attraction={attr}
													currentUser={currentUser}
													deleteTripAttraction={deleteTripAttraction}
													handleShowDelete={handleShowDelete}
													handleShowDeleteTripAttr={handleShowDeleteTripAttr}
													handleSelectAttraction={handleSelectAttraction}
													isGrouped={true}
													tripGeneralAttrId={attraction.id}
													view={view}
													/>
									            </div>
									          </div>
								         	})
							         } 
							        </Collapse>
								</div>
						   	</div>)	
							
							
						}) : <div>{view === 'tripLocation' ? <div>No places have been added to this location.</div> : <div>No places have been uploaded for this location.</div>}</div>}
					</div>

				</div>
			</div>
	);
};

export default GroupedAttractionsList;
