import React from "react";
import { Link } from "react-router-dom";
import ReadMoreAndLess from 'react-read-more-less';

import "../stylesheets/components/OverallAttractionReviews.css"

import AddToTripContainer from "../containers/AddToTripContainer"
import AttractionTags from "./AttractionTags"
import CommentsContainer from "../containers/CommentsContainer"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer" 
import SavedButtonContainer from "../containers/SavedButtonContainer"

const OverallAttractionReviews = props => {
	const { 
		attractionBasicInfo,
		currentUser,
		overallView,
		reviews
	} = props

	const getStars =  (count, className) => {
			const stars = []
			let filledStars, halfStar;
			if (count % 1 >= 0.75){
				filledStars = Math.round(count);
				halfStar = false;
			} else if (count % 1 < 0.75 && count % 1 >= 0.25){
				filledStars = (Math.floor(count));
				halfStar = true;
			} else {
				filledStars = (Math.floor(count));
				halfStar = false;
			}
			while(stars.length < filledStars){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}  
			if (halfStar){
				stars.push(<i className={`material-icon nonClickableIcon ${className}`}>star_half</i>)
			}
			while (stars.length < 5){
					stars.push(<i className={`material-icons nonClickableInactiveIcon ${className}`}>star</i>)
			}
			 
		
			return stars
	}


	const getCharLimit = (text) => {
		const idx = text.split('').findIndex(char => {
			return char === '\n'
		})
		if (idx > 1){
           return idx + 1
		} else if (idx !== -1) {
			return 2
		} else {
			return 60
		}
	}

	const getIcon = attractionType => {
			if (attractionType === "ACTIVITY"){
				return <i className={'material-icons nonClickableIcon iconSmall'}>local_activity</i>
			} else if (attractionType === 'RESTAURANT'){
				return <div className="icons">
					<i className={'material-icons nonClickableIcon iconSmall'}>local_bar</i>
					<i className={'material-icons nonClickableIcon iconSmall'}>restaurant</i>
				</div>
			} else if (attractionType === "LODGING") {
				return <i className={'material-icons nonClickableIcon iconSmall'}>hotel</i>
			}
	}



	return (
		<div className="overallAttractionReviews">
			{reviews.length ? reviews.map(review => {
				return <div className="mainBackground">
					<div className="attractionTop">
						<img className="picMedium" src={review.publicUrl} alt="profPic"/>
						<div className="attrUserInfo">
							<div className="nameAndType">
								<Link className="textLink" to={`/attraction/${review.id}`}>{(review.firstName + " " + review.lastName)}</Link>
								<div>{getIcon(review.attractionType)}</div>
							</div>
							{review.stars && <div>
								{getStars(review.stars, 'star')}
							</div>}
						</div>
					</div>
					 	<div className="attractionReviewsAndTips">
							 <div className="attractionInfo">
								<div className="textTitle">Review: </div>
								{review.review ? <div className="userInputText">
								{review.objectionable ? 
									<div>
										{review.objectionable === 'CONFIRMED' ? 
					             		<div className="mainBackground">This review has been flagged.</div> : 
					             		<div className="mainBackground">This review has been reported and is under review.</div>}
								             	
									</div>
									: <ReadMoreAndLess
						                className="textLink"
						                charLimit={getCharLimit(review.review)}
						                readMoreText="Read more"
						                readLessText="Read less"
						            >
						                {review.review}
					           		</ReadMoreAndLess>}
					            </div> : <div>N/A</div>}
				           	</div>
				           	<div className="attractionInfo">
				           		<div className="textTitle">Tips: </div>
				           		{review.tips ? <div className="userInputText">
				           		{review.objectionable ? 
									<div>
										{review.objectionable === 'CONFIRMED' ? 
					             		<div className="mainBackground">This tip has been flagged.</div> : 
					             		<div className="mainBackground">This tip has been reported and is under review.</div>}
								             	
									</div>
					             
					           		: <ReadMoreAndLess
						                className="textLink"
						                charLimit={getCharLimit(review.tips)}
						                readMoreText="Read more"
						                readLessText="Read less"
						            >
						                {review.tips}
						            </ReadMoreAndLess>}
					            </div> : <div>N/A</div>}
				           	</div>
				           	
						</div>
						<div className="attractionBottom marginBottom">
							{review.tags &&  <AttractionTags 
								 	tags ={review.tags}
								 	view="overallAttraction"
								 />}
							{currentUser && !!currentUser.id && <div className="icons">
									{/*<AddToTripContainer 
									attraction={review}
									locationId={review.locationId}
									type="attraction"
									/>*/}
									<SavedButtonContainer 
										attraction={review}
										locationId={review.locationId}
										attractionId={review.id}
										locationUserId={review.userId}
										view="overallAttraction"
									/> 
								</div>}
						</div>
						{currentUser.id !== review.userId && <div>
						<ReportObjectionableContainer
							reportItem={review}
							reportType="ATTRACTION"
							reportTypeRender="attraction"
						 />
						</div>}

					
				
					<CommentsContainer 
					 	commentType="attractionComment"
					 	comments={review.comments ? review.comments : []}
					 	parentId={review.id}
					 	pushToken={review.pushToken}
					 	parentType="attraction"
					 	placeholder="Ask a question"
					 	view="overallAttraction"
					 />
				</div>
			}) : 
				<div>{overallView === 'public' ? 
					<div className="mainBackground">There are no reviews for {attractionBasicInfo.name} yet.</div> 
					: <div className="mainBackground">No one that you follow has reviewed {attractionBasicInfo.name} yet. Try switching to the public view.</div>
				}</div>}

		</div>
	);
};

export default OverallAttractionReviews;
