import React from "react";
import { Query, Mutation, compose, graphql, withApollo } from "react-apollo";
import ReactPaginate from 'react-paginate';

import history from "../history"

import { 
	CREATE_LOCATION_AND_ATTRACTION_CUSTOM, 
	UPDATE_FOLLOW, 
	UPDATE_FOLLOWER_ALERTS, 
	UPDATE_NOTIFICATIONS 
} from "../apollo/db/mutations"
import {  
	GET_FOLLOWERS_ALPHABETIZED, 
	GET_FOLLOWERS_BY_DATE,
	GET_FOLLOWING_ALPHABETIZED,
	GET_NOTIFICATIONS_BY_DATE, 
	GET_UNSEEN_FOLLOWERS, 
	GET_UNSEEN_NOTIFICATIONS,
	GET_USER_AND_LOCATIONS
 } from "../apollo/db/queries";
import { getCurrentUser, getPopup, getNotificationsType } from "../apollo/state/queries";
import { setPopup, setNotificationsType } from "../apollo/state/mutations"

import Notifications from "../components/Notifications";
import NotificationsHeader from "../components/NotificationsHeader"
import PictureViewContainer from "./PictureViewContainer"

class NotificationsContainer extends React.Component {
	constructor(props) {
		super();
		this.state = {
		      page: 0, 
		      picView: null,
		      showPicture: false,
		      type: null, 
		      notifications: []
		    };

		this.handleCreateLocationAndAttraction = this.handleCreateLocationAndAttraction.bind(this)
		this.handleHidePicture = this.handleHidePicture.bind(this)
		this.handlePageChange = this.handlePageChange.bind(this)
		this.handleSetType = this.handleSetType.bind(this)
		this.handleShowPicture = this.handleShowPicture.bind(this)
		this.handleUpdateFollow = this.handleUpdateFollow.bind(this)
		this.handleViewAll = this.handleViewAll.bind(this)
		this.renderPagination = this.renderPagination.bind(this)
	}

	async componentDidMount(){
		const { client, notificationsType } = this.props
		const { type } = this.state
		
		const res = await client.query({
			query: GET_UNSEEN_NOTIFICATIONS
		})
		const unseenOther = res.data.unseenNotifications
		if (unseenOther && Number(unseenOther)){
			await client.mutate({
				mutation: UPDATE_NOTIFICATIONS,
				variables: {input: {}}, 
				refetchQueries: [{query: GET_UNSEEN_NOTIFICATIONS}]
			})
		}	

		
		this.setState({type: notificationsType.type})
	}


	async handleShowPicture(e, picNotification){
		const { handleRemoveListener, setPopup } = this.props
		e.stopPropagation()
		const href = window.location.href.split('/')
		const route = href[href.length -1]
		await setPopup({
			variables: {
				popup: true
			}
		})
		await this.setState({showPicture: true, picView: picNotification}, () => {
			if (route !== 'notifications'){
		   	 handleRemoveListener()
				
			}
		 })
	}

	


	async handlePageChange(pageInfo){
		await this.setState({page: pageInfo.selected})
		window.scrollTo(0, 0)
		
	}

	async handleHidePicture(e){
		const { handleAddListener, setPopup } = this.props

		e.stopPropagation()

		const href = window.location.href.split('/')
		const route = href[href.length -1]
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showPicture: false, picView: null}, () => {
		    if (route !== 'notifications') {
		    	handleAddListener()
		    }
		})

	}

	renderPicture(){
		const { picView } = this.state

		const picture = {
			ownerUserId: picView.userId,
			publicUrl: picView.pictureUrl,
			id: picView.pictureId,
			attractionName: picView.attractionName, 
			tags: picView.tags
		}

		return <PictureViewContainer 
			handleClose={this.handleHidePicture}
			pictures={[picture]}
			initialPicIndex={0}
		/>
	}

	async handleSetType(chosenType, event){
		const { client, setNotificationsType } = this.props
		if (event){
			//to prevent notifications from closing
			event.stopPropagation()
		}
		
		if (chosenType === 'followers'){
			const res = await client.query({
				query: GET_UNSEEN_FOLLOWERS
			})
			//this should not be an array - update
			const unseenFollowers = res.data.unseenFollowers
			if (unseenFollowers && Number(unseenFollowers)){
				console.log('doing the thign')
				await client.mutate({
					mutation: UPDATE_FOLLOWER_ALERTS, 
					variables: {input: {}}, 
					refetchQueries: [{query: GET_UNSEEN_FOLLOWERS}]
				})
			}
		}

		try {
			 await setNotificationsType({
                variables: { 
                  type: chosenType
                }
              })
		} catch {
			console.log('error')
		}

		this.setState({type: chosenType})
			
	}



	async handleUpdateFollow(e, id, status, updateFollow){
		e.stopPropagation()
		try {
			await updateFollow({
					variables: {
						input: {
							id: id,
							followerPatch:  {
								status: status, 
								updatedAt:new Date(Date.now()).toISOString()
							  }
							}	
						}
					})
		} catch {
			console.log('error')
		} 
	} 

	async handleCreateLocationAndAttraction(e, locationId, attractionId, currentLocId, createLocationAndAttractionCustom){
		e.stopPropagation()
		try {
			await createLocationAndAttractionCustom({
					variables: {
						input: {
							locId: locationId, 
							attrId: attractionId, 
							currentLocId: currentLocId
						}	
					}
				})
		} catch {
			console.log('error')
		} 
	}

	async handleViewAll(type){
		const { handleClose } = this.props
		await handleClose()
		await this.handleSetType(type)
		history.push(`/notifications`)
	}

	renderPagination(notifications){
		const { page } = this.state
		let paginationElement;
		   
		      paginationElement = (
		        <ReactPaginate
		          previousLabel={"← Previous"}
		          nextLabel={"Next →"}
		          breakLabel={<span className="gap">...</span>}
		          pageCount={notifications.length/10}
		          onPageChange={this.handlePageChange}
		          forcePage={page}
		          containerClassName={"pagination"}
		          previousLinkClassName={"textLink"}
		          nextLinkClassName={"textLink"}
		          disabledClassName={"disabledPagination"}
		          activeClassName={"paginationSelected"}
		          pageClassName={"paginationButton"}
		          pageLinkClassName={"paginationButtonLink"}
		        />
		      );
		    
			
		return <div className="paginationButtons">{paginationElement}</div>	
	}

	renderFollowerNotifications(){
		const { currentUser, view } = this.props
		return(<Query query={GET_FOLLOWERS_BY_DATE} >
				{({ loading, error, data }) => {
					if (loading) {return <div> Loading... </div>}
					if (error) {return <div> Error </div>}
					const followersByDate = data.followersByDate.nodes
					return <Mutation
							mutation={UPDATE_FOLLOW}
							refetchQueries={[
						      {query: GET_FOLLOWERS_BY_DATE}, 
						      {query: GET_FOLLOWERS_ALPHABETIZED, variables: {profUserId: currentUser.id}},
						      {query: GET_FOLLOWING_ALPHABETIZED, variables: {profUserId: currentUser.id}}
						      ]}
						>
						{(updateFollow, { loading, error, data }) => {
							return <Notifications
							    error={error} 
							    followersByDate={followersByDate}
								handleUpdateFollow={this.handleUpdateFollow}	
								notificationView="follow"
								renderPagination={this.renderPagination}
								updateFollow={updateFollow}
								view={view}
								/>	

							}}
						</Mutation>
					}}


				</Query>)
	}

	renderNotifications(){
		const { page } = this.state 
		const { currentUser, view } = this.props
		return <Query query={GET_NOTIFICATIONS_BY_DATE} >
				{({ loading, error, data }) => {
					if (loading) {return <div> Loading... </div>}
					if (error) {return <div> Error </div>}
					const notifications = data.notificationsByDate.nodes

				return <Mutation
					mutation={CREATE_LOCATION_AND_ATTRACTION_CUSTOM}
					refetchQueries={[{query: GET_NOTIFICATIONS_BY_DATE}, 
					{query: GET_USER_AND_LOCATIONS, variables: {id: currentUser.id}}
					]}
				>
					{(createLocationAndAttractionCustom, { loading, error, data }) => {
						return <Notifications 
							error={error} 
							createLocationAndAttractionCustom={createLocationAndAttractionCustom}
							handleCreateLocationAndAttraction={this.handleCreateLocationAndAttraction}
							handleShowPicture={this.handleShowPicture}
							notifications={notifications}
							page={page}
							renderPagination={this.renderPagination}
							view={view}
						/>
					}}
				</Mutation>	
			}}
		</Query>
	}

	renderHeader(){
		const { type } = this.state
		const { view } = this.props
		return <Query
			query={GET_UNSEEN_FOLLOWERS}
			//pollInterval={60000}
		>
			{({data, loading, error}) => {
				if (loading) return <div>Loading...</div> 
				if (error) return <div>Error</div>	
				const unseenFollowers = data.unseenFollowers
				return <NotificationsHeader
					handleSetType={this.handleSetType} 
					handleViewAll={this.handleViewAll}
					type={type}
					unseenFollowers={unseenFollowers}
					view={view}

				/>
			}}
		</Query>

	}

	render() {
		const { showPicture, type } = this.state
		const { currentUser, handleBlur, screenView, view } = this.props
		const popupClass = screenView.popup && "disabledBackground"
		return (
			<div className={view === 'navBar' ? `notificationsContainer ${popupClass}` : `notificationsContainer fullScreen ${popupClass}`}>
			{ currentUser.id > 0 ?
				<div className="temp">
					{this.renderHeader()}
					{type === 'followers' && this.renderFollowerNotifications()}
					{type === 'other' && this.renderNotifications()}
					{showPicture && this.renderPicture()}
				</div> : 
				<div> Please login or create an account to view this page</div> }
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(getNotificationsType, {
		props: ({ loading, data: { notificationsType } }) => ({ 
			notificationsType
		})
	}), 
	graphql(setNotificationsType, {name: 'setNotificationsType'}),
	graphql(setPopup, {name: 'setPopup'})
	)(NotificationsContainer);
