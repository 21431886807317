import React from "react";
import { Link } from "react-router-dom"

import "../stylesheets/components/LocationListView.css"

const LocationListView = props => {
	const { 
		handleChange,
		handleClose, 
		locations, 
		value
	} = props
	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}

	return (<div className="locationListView popup popupSmall">
			<div className="closeIconHolder">
								<i className="material-icons activeIcon iconMedium" onClick={() => handleClose()}>cancel</i>
			</div>

			<div className="popupContent popupScroll">
				<input className="filterInput" placeholder={'Filter by city or country'} value={value} onChange={(e) => handleChange(e)}/>
				{locations.map(location => {
					if (location.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1){
						return <Link className="mainBackground textLink" to={`/location/${location.id}`} key={location.id}>{getLocName(location)}</Link>
					}
				})}
				</div>
		</div>
	);
};

export default LocationListView;
