import React from "react";
import { compose, graphql, Mutation, withApollo, Query } from "react-apollo";
import * as Yup from "yup"
import { analytics } from '../index.js';

import history from "../history"


import { getCurrentUser } from "../apollo/state/queries";
import { GET_ALL_USER_MESSAGES, GET_FOLLOWING_ALPHABETIZED } from "../apollo/db/queries"
import { CREATE_NEW_MESSAGE } from "../apollo/db/mutations"

import CreateMessageForm from "../components/CreateMessageForm"

class CreateMessageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			receiver: null,
			sent: false,
			showUsers: false,
			stateError: null,
			userValue: ''
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSelectUser = this.handleSelectUser.bind(this)
		this.handleCreateMessage = this.handleCreateMessage.bind(this)
		this.handleHideUsers = this.handleHideUsers.bind(this)
		this.handleShowUsers = this.handleShowUsers.bind(this)
		this.handleRemoveUser = this.handleRemoveUser.bind(this)
		
	}

	componentDidMount(){
		const { currentUser } = this.props
		this.setState({userIds: [currentUser.id]})
	}

	async handleCreateMessage(values, createNewMessage, resetForm, setSubmitting) {
		const { currentUser, client, handleClose, location, recipient } = this.props
		const { receiver } = this.state

		setSubmitting(true)

		const messageContent = values.message

		const receiverUser = recipient ? recipient : receiver
		const recieverId = recipient ? recipient.id : receiver.followeeId

		if (!recieverId){
			this.setState({stateError: "Please select a recipient"})
			return null;
		}


		try {
			const res = await createNewMessage({
				variables: {
					input: {
				
						receiveId: recieverId, 
						locId: location ? location.id : null,
						message: messageContent
							
					}	
				}
			})
			await this.setState({sent: true})
				setTimeout(() => {
					handleClose()
				}, 1000)

			setSubmitting(false)

			if (receiverUser.pushToken && receiverUser.pushToken !== currentUser.pushToken){
				await this.sendPushNotification(receiverUser.pushToken)
				
			}
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('create_new_message', {
				  locationId: location ? location.id : null, 
				  receiverId: recieverId
				});
			}
	
	
			
		} catch {
			console.log('error')
			setSubmitting(false)
		}
	} 

	async sendPushNotification(token){
		const { currentUser, profUser } = this.props


	    const message = {
	      to: token,
	      sound: 'default',
	      body: `${currentUser.firstName} ${currentUser.lastName} sent you a message`,
	      data: { data: 'goes here' },
	      _displayInForeground: true,
	    };
	    const response = await fetch('https://exp.host/--/api/v2/push/send', {
	       'mode': 'no-cors',
	        'method': 'POST',
	        'headers': {
	            'Accept': 'application/json',
	            'Content-Type': 'application/json',
	        },
	      body: JSON.stringify(message),
	    });
  	};

	async handleSelectUser(user){
		const { receiver } = this.state
		await this.setState({
			receiver: user, 
			userValue: ''
		})
		this.handleHideUsers()


	}

	handleChange(event){
		this.setState({userValue: event.target.value})
	}


	handleRemoveUser(){
		this.setState({receiver: null})
	}

	handleShowUsers(){
		this.setState({showUsers: true})
	}

	handleHideUsers(){
		this.setState({showUsers: false})
	}

	

	renderMessageForm(){
		const { currentUser, handleClose, location, recipient, view } = this.props
		const { receiver, sent, stateError, showUsers, userValue } = this.state

		const initialValues = {
			message: ''
		}

		const messageSchema = Yup.object().shape({
		  	message: Yup.string()
		  	.max(2000, 'Message is too long. Maximum of 2000 characters.')

		})
		return <Query query={GET_FOLLOWING_ALPHABETIZED} variables={{profUserId: currentUser.id}}>
	 		{({ loading, error, data }) => {
				if (loading) {return <div> Loading... </div>}
				if (error) {return <div>Error</div>}
				const following = data.followingAlphabetized.nodes
			

				return <Mutation mutation={CREATE_NEW_MESSAGE}
					refetchQueries={[{query: GET_ALL_USER_MESSAGES}]}
					>
			      	{(createNewMessage, { data, loading }) => (
				        <CreateMessageForm
							createNewMessage={createNewMessage}
							currentUser={currentUser}
							stateError={stateError}
							handleClose={handleClose}
							handleSubmit={this.handleCreateMessage}
							initialValues={initialValues}
							following={following}
							handleChange={this.handleChange}
							handleFocus={this.handleShowUsers}
							handleBlur={this.handleHideUsers}
							handleRemoveUser={this.handleRemoveUser}
							handleSelect={this.handleSelectUser}
							location={location}
							sent={sent}
							showUsers={showUsers}
							receiver={receiver}
							recipient={recipient}
							userValue={userValue}
							validationSchema={messageSchema}
							view={view}
						/>
					)}
				</Mutation>	
			}}
		</Query>
	}

	render() {
		const { showUsers } = this.state
		return (
			<div className="CreateMessageContainer">
				
				{this.renderMessageForm()}
				
			</div>
				
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(CreateMessageContainer);
