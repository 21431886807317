import React from "react";
import { Link } from "react-router-dom";
import ReadMoreAndLess from 'react-read-more-less';

import "../stylesheets/components/Attraction.css"


import AddToTripContainer from "../containers/AddToTripContainer"
import AttractionTags from "./AttractionTags"
import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"
import Visitors from "./Visitors"
import SavedButtonContainer from "../containers/SavedButtonContainer"

const Attraction = props => {
	const {
		attraction,
		currentUser,
		handleSelectAttraction,
		handleShowDelete,
		handleShowDeleteTripAttr,
		handleShowEdit,
		handleShowOtherVisitors,
		id, 
		isGrouped,
		locationId, 
		locationUserId,
		number,
		otherVisitors, 
		tripGeneralAttrId,
		view
	} = props;


	const getStars =  (count, className) => {
			const stars = []
			let filledStars, halfStar;
			if (count % 1 >= 0.75){
				filledStars = Math.round(count);
				halfStar = false;
			} else if (count % 1 < 0.75 && count % 1 >= 0.25){
				filledStars = (Math.floor(count));
				halfStar = true;
			} else {
				filledStars = (Math.floor(count));
				halfStar = false;
			}
			while(stars.length < filledStars){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star</i>)
			}  
			if (halfStar){
				stars.push(<i className={`material-icons nonClickableIcon ${className}`}>star_half</i>)
			}
			while (stars.length < 5){
					stars.push(<i className={`material-icons nonClickableInactiveIcon  ${className}`}>star</i>)
			}
			 
		
			return stars
	}

	const getCharLimit = (text) => {
		const idx = text.split('').findIndex(char => {
			return char === '\n'
		})
		if (idx > 1){
           return idx + 1
		} else if (idx !== -1) {
			return 2
		} else {
			return 60
		}
	}

	return (
		<div className={isGrouped ? "attraction mainBackground secondaryBackgroundColor" : "attraction mainBackground"}>
			<div className="attractionMain">
				<div className="attractionContent"> 
					{!isGrouped ?
						<div>
							<div className="textLink secondarySubHeader" onClick={() => handleSelectAttraction(attraction.id)}>{attraction.name}</div>
							
						</div>
						: <div className="textLink secondarySubHeader attractionUserName" onClick={() => handleSelectAttraction(attraction.id)}>
							{(attraction.firstName + ' ' + attraction.lastName)}
					</div>}
					{attraction.stars && <div className="stars">
							{getStars(attraction.stars, 'star')}
						
					</div>}
					<div>
						<div className="attractionInfo fontSmall">
							<div className="textTitle fontSmall">Review: </div>
				             {attraction.review ? <div className="userInputText">
				             {attraction.objectionable ?
				             	<div>{attraction.objectionable === 'CONFIRMED' ? 
				             		<div>This review has been flagged.</div> : 
				             		<div>This review has been reported and is under review.</div>}
				             	</div> :
					             	<ReadMoreAndLess
					                className="textLink "
					                charLimit={getCharLimit(attraction.review)}
					                readMoreText="Read more"
					                readLessText="Read less"
					            >
				               	 {attraction.review}
				            	</ReadMoreAndLess>}
				            </div> : <div>N/A</div>}
				        

			           	</div>
			           	<div className="attractionInfo fontSmall">
			           		<div className="textTitle fontSmall">Tips: </div>
			           		{attraction.tips ? <div className="userInputText">
			           		{attraction.objectionable ?
				             	<div>{attraction.objectionable === 'CONFIRMED' ? 
				             		<div>This tip has been flagged.</div> : 
				             		<div>This tip has been reported and is under review.</div>}
				             	</div> : <ReadMoreAndLess
				                className="textLink"
				                charLimit={getCharLimit(attraction.tips)}
				                readMoreText="Read more"
				                readLessText="Read less"
				            >
				                {attraction.tips}
				            </ReadMoreAndLess>}
				            </div>: <div>N/A</div>}

			           	</div>
	           		</div>
	    		</div>
           
           	<div className="attractionRight">
			
				{otherVisitors && <Visitors 
					handleShowOtherVisitors={handleShowOtherVisitors}
					otherVisitors={otherVisitors}
					view="attractions"
				/>}

			</div>
		</div>
   		<div className="attractionBottom">
       		{attraction.tags &&  <AttractionTags 
			 	tags={attraction.tags}
			 	view={view}
			 />}
			 {currentUser.id > 0 && currentUser.id !== locationUserId && <div className="icons">
				{view === 'location' && <div className="pinAndNumber">
					<i className={`material-icons activeIcon iconSmall`}>place</i>
					<div>{number}</div>
				</div>}
				{/*view === 'tripLocation' ?
					<div className="fontXS" onClick={() => handleShowDeleteTripAttr(attraction.id, tripGeneralAttrId)}>Remove from trip</div>
					 : <AddToTripContainer 
					attraction={attraction}
					locationId={attraction.locationId}
					type="attraction"
					/>*/}
					{view !== 'overallLocation' && <SavedButtonContainer 
						attraction={attraction}
						locationId={locationId}
						attractionId={id}
						locationUserId={locationUserId}
						view={view}
					/>} 
			</div>}

			{currentUser.id === locationUserId && 
				<div className="icons">
					{view === 'location' && <div className="pinAndNumber">
						<i className={`material-icons activeIcon iconSmall`}>place</i>
					
						<div>{number}</div>
					</div>}
					{attraction.visibility === 'PRIVATE' &&
					<i className={`material-icons activeIcon iconSmall`}>visibility_off</i>}
					<i className={`material-icons activeIcon iconSmall`} onClick={() => handleShowEdit(attraction)}>edit</i>
					<i className={`material-icons activeIcon iconSmall`} onClick={() => handleShowDelete(attraction)}>delete</i>


			</div>}
		</div>
		{currentUser.id > 0 && currentUser.id !== locationUserId && (view === 'location' || view === 'overallLocation') && <div>
			<ReportObjectionableContainer 
				reportItem={attraction}
				reportType='ATTRACTION'
				reportTypeRender="attraction"
			/>
		</div>}
			
		

			
	</div>
	);
};

export default Attraction;
