import React from "react";
import { Query, Mutation, graphql, compose, withApollo } from "react-apollo";
import axios from "axios"
import * as Yup from 'yup'
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import { analytics } from '../index.js';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';


import "../stylesheets/containers/ProfileContainer.css"
import "../stylesheets/components/Cropper.css"

import history from "../history"

import AddLocationContainer from "./AddLocationContainer";
import BasicInfo from "../components/BasicInfo"
import CreateMessageContainer from "./CreateMessageContainer"
import Deletion from "../components/Deletion"
import FollowViewContainer from "./FollowViewContainer"
import InstaConnectionContainer from "./InstaConnectionContainer"
import InstaConnectionRequest from "../components/InstaConnectionRequest"
import LocationListViewContainer from "./LocationListViewContainer"
import MapView from "../components/MapView";
import NoTripLocations from '../components/NoTripLocations'
import OpenApp from "../components/OpenApp"
import ProfileHeader from "../components/ProfileHeader";
import SinglePicAdd from "../components/SinglePicAdd"

import { CREATE_NEW_HEADER_PICTURE, CREATE_NEW_PROFILE_PICTURE, DELETE_PROFILE_PICTURE, UPDATE_USER, VIEW_PROFILE } from "../apollo/db/mutations"
import { GET_TRIP_LOCATION_AND_DATES, GET_WEEKLY_EMAIL, GET_ALL_OVERALL_LOCATIONS,  GET_FOLLOWING_INFO, GET_USER_AND_LOCATIONS, GET_USER_VIEW_BY_ID, GET_SAVED_ATTRACTIONS, GET_UPCOMING_TRIPS } from "../apollo/db/queries";
import { getCurrentUser, getPopup } from "../apollo/state/queries";
import { setCurrentUser, setPopup } from "../apollo/state/mutations";

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}



class ProfileContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addLocation: false, 
			basicInitialValues: {},
			currentCityValue: '',
			currentUserId: null, 
			followView: null,
			headerBlob: null,
			headerSrc: null,
			loading: false,
			newCurrentCity: null,
			onMobile: false,
			pictureId: null,
			profUserId: null,
			src: null,
			profUserId: null,
			showCreateMessage: false,
			showDelete: false,
			showEdit: false,
			showFollow: false,
			showHeaderUpload: false, 
			showInstaAdd: false,
			showInstaPrompt: false,
			showListView: false, 
			showNoTripLocs: false,  
			showUpload: false, 
			stateError: null,
			run: false, 
			steps: [{
               target: '.profJoyride1',
               content: 'Upload a new profile picture here.', 
               disableBeacon: true
             },
             {
               target: '.profJoyride2',
               content: 'Edit your basic info and about here.', 
               disableBeacon: true
             },
             {
               target: '.profJoyride3',
               content: 'Add a new location page here.', 
               disableBeacon: true
             }, 
              {
               target: '.profJoyride4',
               content: 'Connect to your Instagram here.', 
               disableBeacon: true
             }]
		};

		this.cropImage = this.cropImage.bind(this)
		this.handleChangeCurrentCity = this.handleChangeCurrentCity.bind(this)
		this.handleConnectInsta = this.handleConnectInsta.bind(this)
		this.handleDeleteProfPic = this.handleDeleteProfPic.bind(this)
		this.handleHideAddLocation = this.handleHideAddLocation.bind(this)
		this.handleHideCreateMessage = this.handleHideCreateMessage.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleHideFollow = this.handleHideFollow.bind(this)
		this.handleHideHeaderUpload = this.handleHideHeaderUpload.bind(this)
		this.handleHideInstaAdd = this.handleHideInstaAdd.bind(this)
		this.handleHideInstaPrompt = this.handleHideInstaPrompt.bind(this)
		this.handleHideListView = this.handleHideListView.bind(this)
		this.handleHideNoTripLocs = this.handleHideNoTripLocs.bind(this)
		this.handleHideUpload = this.handleHideUpload.bind(this)
		this.handleJoyride = this.handleJoyride.bind(this)
		this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this)
		this.handleOpenLocation = this.handleOpenLocation.bind(this)
		this.handleSetHeader = this.handleSetHeader.bind(this)
		this.handleSelectCurrentCity = this.handleSelectCurrentCity.bind(this)
		this.handleSelectHeaderPicture = this.handleSelectHeaderPicture.bind(this)
		this.handleSelectPicture = this.handleSelectPicture.bind(this)
		this.handleSelectTripPin = this.handleSelectTripPin.bind(this)
		this.handleShowAddLocation = this.handleShowAddLocation.bind(this)
		this.handleShowCreateMessage = this.handleShowCreateMessage.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleShowFollow = this.handleShowFollow.bind(this)
		this.handleShowHeaderUpload = this.handleShowHeaderUpload.bind(this)
		this.handleShowInstaPrompt = this.handleShowInstaPrompt.bind(this)
		this.handleShowListView = this.handleShowListView.bind(this)
		this.handleShowUpload = this.handleShowUpload.bind(this)
		this.handleUpdateInstaConnection = this.handleUpdateInstaConnection.bind(this)
		this.handleUploadProfPic = this.handleUploadProfPic.bind(this)
		this.handleUpdateUser = this.handleUpdateUser.bind(this)
		this.handleUpdateUserExplanation = this.handleUpdateUserExplanation.bind(this)
		 this.headerPic = React.createRef();
		}

	async componentDidMount(){
		const { profUserId } = this.state
		const { client, currentUser, setPopup } = this.props
		const newProfUserId = parseInt(window.location.href.split('/').slice(-1)[0])


    	try {
			await client.mutate({
			   	 	mutation: VIEW_PROFILE, 
			   	 	variables: {input: {profUserId: newProfUserId, viewerId: currentUser.id}}
		 	})
		 	if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('view_profile', {
				  profUserId: newProfUserId
				});
			}
		} catch {
			console.log("Error")
		}


	
  


	    await setPopup({
			variables: {
				popup: false
			}
		})

		
		function isMobile() {
		  var check = false;
		  (function(a){
		    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
		      check = true;
		  })(navigator.userAgent||navigator.vendor||window.opera);
		  return check;
		};
 
		let args, accessToken, userId, instagramDenied; 
		console.log('window.location.search', window.location.search)
		if (window.location.search){
				 if (window.location.search[1] === 'a'){
					args = window.location.search.split('&')
					accessToken = args[0] &&  args[0].split('=') && args[0].split('=')[1]
					userId = args[1] && args[1].split('=') && args[1].split('=')[1]
				} else {
					console.log('in here')
					instagramDenied = true
				}
			 	
		}
		
		const onMobile = isMobile()

		if (onMobile && accessToken){
			this.setState({onMobile: true}, () => {
				window.location.href = `trvlpage://app/profile/${accessToken}/${userId}`;
			})
		} else if (onMobile && instagramDenied){
			console.log('sending to profile no accessToken')
			this.setState({onMobile: true}, () => {
				window.location.href = `trvlpage://app/profile/`;
			})
		} else if(accessToken){
			this.setState({
				currentUserId: currentUser.id ? currentUser.id : null,
				profUserId: currentUser ? currentUser.id : null, 
				showEdit: false, 
				showFollow: false, 
				addLocation: false, 
				showInstaAdd: true
			})
		} else if (instagramDenied) {
			this.setState({
				currentUserId: currentUser.id ? currentUser.id : null,
				profUserId: currentUser ? currentUser.id : null, 
				showEdit: false, 
				showFollow: false, 
				addLocation: false, 
				showInstaAdd: false
			})
		} else if (currentUser.id && currentUser.id === newProfUserId && !currentUser.seenProfExplanation){
	    	this.setState({
				currentUserId: currentUser.id ? currentUser.id : null,
				profUserId: newProfUserId, 
				showEdit: false, 
				showFollow: false, 
				addLocation: false
			}, () => {
				setTimeout(() => {
					this.handleJoyride(newProfUserId)
				}, 1000)
			})
	    } else {
	    	this.setState({
				currentUserId: currentUser.id ? currentUser.id : null,
				profUserId: newProfUserId, 
				showEdit: false, 
				showFollow: false, 
				addLocation: false 
			})
	    }
	 		

		

	}

	async componentDidUpdate(){
		const { addLocation, currentUserId, profUserId } = this.state
		const { client, currentUser, screenView, setPopup } = this.props
		const newProfUserId = parseInt(window.location.href.split('/').slice(-1)[0])
		let args, accessToken, userId; 
		console.log('window', window.location.search)
		if (window.location.search){
		 	args = window.location.search.split('&')
			accessToken = args[0] &&  args[0].split('=') && args[0].split('=')[1]
			userId = args[1] && args[1].split('=') && args[1].split('=')[1]
		}

			if (accessToken && !profUserId && currentUser.id){
				{
			 		this.setState({
						currentUser: currentUser.id ? currentUser.id : null,
						profUserId: currentUser.id, 
						showEdit: false, 
						showFollow: false, 
						addLocation: false
					})
		 		}
			} else if (newProfUserId && profUserId !== newProfUserId && !(isNaN(profUserId) && isNaN(newProfUserId))){

		 		try {
					await client.mutate({
					   	 	mutation: VIEW_PROFILE, 
					   	 	variables: {input: {profUserId: newProfUserId, viewerId: currentUser.id}}
				 	})
				 	if (process.env.NODE_ENV !== 'development'){
					 	await analytics.logEvent('view_profile', {
						  profUserId: newProfUserId
						});
					}

				} catch {
					console.log("Error")
				}

		 	

	 		
			if (screenView.popup){
				await setPopup({
					variables: {
						popup: false
					}
				})
			}
		 	
			
			console.log('currentUser', currentUser, profUserId)

		 	{
		 		this.setState({
					currentUser: currentUser.id ? currentUser.id : null,
					profUserId: newProfUserId, 
					showEdit: false, 
					showFollow: false, 
					addLocation: false
				})
		 	}
		
		}
 
	}

	async handleJoyride(newProfUserId){
		const { currentUser } = this.props
		if (currentUser.id && currentUser.id === newProfUserId && !currentUser.seenProfExplanation){
			try {
				this.setState({run: true}, () => {
					this.handleUpdateUserExplanation()
				})
				
			} catch {
				console.log('error with copilot')
				
			}
		}
	}


	handleJoyrideCallback(data){
	    const { action, index, status, type } = data;

	    console.log('is this runnig?', EVENTS, STATUS, type)

	    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
	      // Update state to advance the tour
	      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
	    }
	    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
	      // Need to set our running state to false, so we can restart if we click start again.
	      this.setState({ run: false });
	    }

 	};

	async handleUpdateUserExplanation(){
		const { currentUser, setCurrentUser, client } = this.props
		const userPatch = { seenProfExplanation: true } 

		const newCurrentUser = Object.assign({}, currentUser, userPatch)

			try {
				
				await client.mutate({
					mutation: UPDATE_USER,
					variables: {
						input: {
							id: currentUser.id,
							userPatch: userPatch
							}	
						}
				})


				await setCurrentUser({
              	  variables: newCurrentUser
	            })
				
			} catch {
				console.log('error')
			}
	}

	async handleUpdateInstaConnection(){
		const { currentUser, setCurrentUser, client } = this.props

		const userPatch = { seenInstaConnection: true } 

		const newCurrentUser = Object.assign({}, currentUser, userPatch)

			try {
				
				await client.mutate({
					mutation: UPDATE_USER,
					variables: {
						input: {
							id: currentUser.id,
							userPatch: userPatch
							}	
						}
				})


				await setCurrentUser({
              	  variables: newCurrentUser
	            })
				
			} catch (error) {
				console.log('error', error)
			}
	}

	


	async handleDeleteProfPic(deleteProfilePic){
		const { pictureId } = this.state
		const { setPopup } = this.props
		try {
				await deleteProfilePic({
					variables: {
						input: {
							deletionId: pictureId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ loading: false, showDelete: false })
			} catch {
				console.log('error')
			}
	}


	async handleHideInstaPrompt(){
			if (process.env.NODE_ENV !== 'development'){
			 	await analytics.logEvent('hide_insta_prompt');
			}
		this.setState({showInstaPrompt: false})
	}

	handleHideNoTripLocs(){
		this.setState({showNoTripLocs: false})
	}

	async handleHideInstaAdd(){
		if (process.env.NODE_ENV !== 'development'){
		 	await analytics.logEvent('hide_insta_add');
		}
		this.setState({showInstaAdd: false})
	}

	async handleConnectInsta(){
		if (process.env.NODE_ENV !== 'development'){
		 	await analytics.logEvent('connect_insta');
		}
		const res = window.location.href = 'https://api.instagram.com/oauth/authorize?client_id=368242907540964&redirect_uri=https://trvlpage.uc.r.appspot.com/instagram&scope=user_profile,user_media&response_type=code'
		
	}

	async handleShowInstaPrompt(){
		const { currentUser, setPopup } = this.props

		await setPopup({
			variables: {
				popup: false
			}
		})
		if (!currentUser.seenInstaConnection){
			await this.handleUpdateInstaConnection()
		}
		if (process.env.NODE_ENV !== 'development'){
		 	await analytics.logEvent('show_insta_prompt', {
		 		initiated: 'button'
		 	});
		}
		this.setState({showInstaPrompt: true})
	}

		
	async handleHideAddLocation(locationId){
		const { currentUser, setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		
		if (locationId){
			history.push(`/location/${locationId}`)
		
		} else {
			this.setState({addLocation: false})
		}
	}

	async handleShowHeaderUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showHeaderUpload: true})
	}

	async handleHideHeaderUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showHeaderUpload: false})
	}


	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false})
	}

	async handleHideEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})

		this.setState({
			showEdit: false, 
			newCurrentCity: null
		})
	}

	async handleHideFollow(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showFollow: false
		})
	}

	async handleHideListView(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showListView: false})
	}

	async handleHideUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showUpload: false, 
			src: null
		})
	}

	async handleHideCreateMessage(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showCreateMessage: false})
	}

	async handleShowCreateMessage(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showCreateMessage: true})
	}

	async handleShowAddLocation(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		await this.setState({addLocation: true})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('show_add_location', {
				view: 'profile'
			});
		}
	}

	async handleShowDelete(id){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, pictureId: id})
	}

	async handleShowListView(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showListView: true})
	}

	handleOpenLocation(location){
		const locationId = location.id
		history.push(`/location/${locationId}`)
	}

	async handleSelectTripPin(placeId){
		const { client } = this.props
		const res =  await client.query({
		   	 	query: GET_SAVED_ATTRACTIONS
		})
	 	
	 	const savedAttractions = res.data.savedAttractionViews.nodes
	 	if (savedAttractions.find(attr => attr.locationPlaceId === placeId)){
	 		history.push(`/savedLocation/${placeId}`)
	 	} else {
	 		const res =  await client.query({
		   	 	query: GET_ALL_OVERALL_LOCATIONS
	    	})
	 	
	 		const overallLocations = res.data.allOverallLocations.nodes
	 		if (overallLocations.find(loc => loc.placeId === placeId)){
	 			history.push(`/locationView/${placeId}`)
	 		} else {
	 			this.setState({showNoTripLocs: true})
	 		}
	 	}

	 	try {
	 		 if (process.env.NODE_ENV !== 'development'){
	 			await analytics.logEvent('click_trip_pin', {
				  placeId: placeId
				});
			 }
	 	} catch {
	 		console.log('analytics error')
	 	}

	}

	handleChangeCurrentCity(event){
		this.setState({currentCityValue: event.target.value})
	}

	handleSelectCurrentCity(selected){
		this.setState({newCurrentCity: selected, currentCityValue: selected.full_name})
	}

	async handleShowEdit(user){
		const { setPopup } = this.props 

		let about = user.about
		let birthday = user.birthday
		if (about === null){
			about = ""
		}
		if (birthday === null){
			birthday = ""
		}

		await setPopup({
			variables: {
				popup: true
			}
		})

		this.setState({
			basicInitialValues: {
				about: about,
				birthday: birthday, 
				currentCity: user.currentCity,
				currentCityLat: user.currentCityLat, 
				currentCityLng: user.currentCityLng,
				firstName: user.firstName,
				lastName: user.lastName
			}, 
			showEdit: true
		})

	}


	async handleShowFollow(view){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({
			showFollow: true, 
			followView: view
		})
	}


	async handleShowUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({
			showUpload: true
		})
	}

	async cropImage(createNewProfilePicture){

		const cropper = this.cropper

		await this.setState({loading: true})
		try {
			if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
	     		 return;
	    	}
			
	    	new Promise(function(resolve, reject){
				cropper.getCroppedCanvas().toBlob(blob => {
					resolve(blob)

			})}).then(blob => {
				this.handleUploadProfPic(blob, createNewProfilePicture)
			})
		} catch {
			this.setState({stateError: "There was an error."})
		}

		
	}

	async handleSelectPicture(event){
		event.preventDefault() 

		this.setState({src: URL.createObjectURL(event.target.files[0])})	
	}

	async handleSelectHeaderPicture(event){
		const { setPopup } = this.props
		event.preventDefault()
		await this.setState({headerSrc: URL.createObjectURL(event.target.files[0]), headerBlob: event.target.files[0] ,showHeaderUpload: false})
		await setPopup({
			variables: {
				popup: false
			}
		})
	}
 
 	async handleSetHeader(createNewHeaderPicture){
 		const { headerBlob } = this.state
 		const { isEditing, setPopups } = this.props

 		const fd = new FormData();
		fd.append('file', headerBlob)



	    const res = await axios.post(`${getUrl()}/image/prof`, fd, {
	        headers: {
	          'Content-Type': 'multipart/form-data'

	        }
	    })
	    const publicUrl = res.data

	  
	   try {
			await createNewHeaderPicture({
				variables: {
					input: {	
							newPublicUrl: publicUrl
						}	
					}
				})

			this.setState({ loading: false, headerSrc: null})


		} catch {
			console.log('error')
		}
 	}

	async handleUpdateUser(values,  setSubmitting, updateUser) {
		const { currentUser, setPopup } = this.props
		const { basicInitialValues, newCurrentCity } = this.state

		setSubmitting(true)

		const firstName = values.firstName;
		const lastName = values.lastName;
		const about = values.about;
		let birthday = values.birthday;
		const currentCity = newCurrentCity ? newCurrentCity.name : basicInitialValues.currentCity
		const currentCityLat = newCurrentCity ? newCurrentCity.geometry.location.lat() : basicInitialValues.currentCityLat
		const currentCityLng = newCurrentCity ? newCurrentCity.geometry.location.lng() : basicInitialValues.currentCityLng
		
		if (!birthday || birthday.length !== 10){
			birthday = null
		}

		if (about.length){
			const enterSplit = about.split('\n')
			if (enterSplit.length > 3){
		    	await this.setState({stateError: 'About section is too long. Maximum of 3 lines.'})
		    	setSubmitting(false)
		    	return;
		    } 
		}
	   

		this.setState({loading: true})
			try {
				await updateUser({
					variables: {
						input: {
							id: currentUser.id,
							userPatch:  {
								firstName: firstName, 
								lastName: lastName,
								birthday: birthday, 
								about: about, 
								currentCity: currentCity, 
								currentCityLat: currentCityLat, 
								currentCityLng: currentCityLng
							  }
							}	
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ 
					loading: false, 
					showEdit: false, 
					newCurrentCity: null, 
					currentCityValue: '', 
					stateError: null})
				setSubmitting(false)
			} catch {
				setSubmitting(false)
				console.log('error')
			}
	} 

	async handleUploadProfPic(blob, createNewProfilePicture){
		const { setPopup } = this.props
		const fd = new FormData();
		console.log('BLOB', blob)
		fd.append('file', blob)


	    const res = await axios.post(`${getUrl()}/image/prof`, fd, {
	        headers: {
	          'Content-Type': 'multipart/form-data'

	        }
	    })
	    const publicUrl = res.data
	  
	   try {
			await createNewProfilePicture({
				variables: {
					input: {	
							newPublicUrl: publicUrl
						}	
					}
				})
			await setPopup({
				variables: {
					popup: false
				}
			})
			this.setState({ loading: false, showUpload: false, src: null, stateError: null})


		} catch {
			console.log('error')
		}
	}


	renderDelete(){
		const { profUserId, pictureId } = this.state
		return (<Mutation 
			mutation={DELETE_PROFILE_PICTURE}
			refetchQueries={[{query: GET_USER_AND_LOCATIONS, variables: {id: profUserId}}]}
			>
			{( deleteProfilePic, { loading, error }) => {

				return (<Deletion 
					error={error}
					id={pictureId} 
			    	handleHideDelete={this.handleHideDelete}
			    	handleDelete={this.handleDeleteProfPic}
			    	mutationFunction={deleteProfilePic}
			    	type="picture"
					/>)
			}}
		</Mutation>)
	}

	renderCreateMessage(user){
		
		return <CreateMessageContainer 
			handleClose={this.handleHideCreateMessage}
			recipient={user}
		/>
	}

	renderEdit(){
		const { basicInitialValues, currentCityValue, profUserId, stateError } = this.state;

		const max = new Date(Date.now()).toISOString()

		const basicSchema = Yup.object().shape({
		  firstName: Yup.string()
		  	.matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'Invalid characters in first name')
		    .required('First Name is required')
		    .max(20, 'First Name cannot be longer than 20 characters.'),
		  lastName: Yup.string()
		  	.matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'Invalid characters in last name')
		    .required('Last Name is required')
		    .max(20, 'Last Name cannot be longer than 20 characters.'),
		  birthday: Yup.date()
		  	.max(max, "Date must be in the past"), 
		  about: Yup.string()
		  	.max(100, 'About section is too long. Maximum of 100 characters.')

		})

		return (<Mutation 
			mutation={UPDATE_USER}
			refetchQueries={[
				{query: GET_USER_AND_LOCATIONS, variables: {id: profUserId}}, 
				{query: GET_USER_VIEW_BY_ID, variables: {profUserId: profUserId}},
				{query: GET_USER_AND_LOCATIONS, variables: {id: profUserId}}
			]}
			>
			{( updateUser, { loading, error }) => {

				return (<BasicInfo 
							basicSchema={basicSchema}
							currentCityValue={currentCityValue}
							error={error}
							handleChange={this.handleChangeCurrentCity}
							handleSelect={this.handleSelectCurrentCity}
							initialValues={basicInitialValues}
							handleSubmit={this.handleUpdateUser}
							mutationFunction={updateUser}
							handleClose={this.handleHideEdit}
							stateError={stateError}
							title="Edit Basic Info"
							view='profile'
						/>)
			}}
		</Mutation>)
	}

	renderHeaderUpload(){
		const { profUserId } = this.state;

			return <SinglePicAdd 
				handleChange={this.handleSelectHeaderPicture}
				handleClose={this.handleHideHeaderUpload}
				title="Upload New Header Picture"
			/>
	}


	renderUpload(){
		const { profUserId, loading, src, stateError } = this.state;
		console.log('loading', loading)
		return(<Mutation 
			mutation={CREATE_NEW_PROFILE_PICTURE}
			refetchQueries={[{query: GET_USER_AND_LOCATIONS, variables: {id: profUserId}}, 
			{query: GET_USER_VIEW_BY_ID, variables: {profUserId: profUserId}}]}
			>
			{( createNewProfilePicture, { loading, error }) => {
				const uploadingClass = loading ? "picUploading" : "";
				return <div>
				{
					src ? 
					<div className="popup popupXL uploadProfPic">
						<div className="popupContent">
							<div className="secondaryHeader">Upload Profile Picture</div>
							{loading
								? <div> loading... this may take a bit </div>
								: <form className="form">
										<label className="primaryButton">Choose New File
											<input className="pictureInput" type="file" name="file" accept="image/*" onChange={e => this.handleSelectPicture(e)}/>
										</label>
									</form>}

							
							{error && <div>There was an error</div>}
							{stateError && <div>{stateError}</div>}
							<div className={`${uploadingClass}`}>
								<Cropper className="cropper"
						            viewMode={1}
						            aspectRatio={3 / 3}
						            guides={false}
						            src={src}
						            zoomOnWheel={false}
						            style={{'height': '20rem', 'width': '20rem'}}
						            ref={cropper => { this.cropper = cropper; }}
						            crossorigin
						          />
					          
				         	</div>
			         		<div className="formButtons">
					         	<button className="secondaryButton" disabled={loading} onClick={this.handleHideUpload}>Cancel</button>
					          	<button className="primaryButton" disabled={loading} onClick={() => this.cropImage(createNewProfilePicture)}>Submit</button>
				          	</div>
				        </div>
			      </div>
				: 
					<SinglePicAdd 
				       loading={loading}
				       error={error} 
				       handleChange={this.handleSelectPicture}
				       handleSubmit={this.handleUploadProfPic}
				       handleClose={this.handleHideUpload}
				       createNewProfilePicture={createNewProfilePicture}
				       title='Upload New Profile Picture'
				        /> }
		
			
			      </div>

			}}
		</Mutation>)
	}

	renderProfile(){
		const { currentUser } = this.props;
		const { addLocation, headerSrc, profUserId, showCreateMessage, showListView } = this.state;
		return <Query query={GET_USER_AND_LOCATIONS} variables={{id: profUserId}} >
			{({ loading, error, data }) => {
				if (loading) return <div>Loading...</div>;
				if (error) return <div>Error</div>;
				if (!data.userById) return <div> This user does not exist </div>;

				const locations = data.userById.locationsByUserId.nodes
				const followingUserId = currentUser.id > 0 ? currentUser.id : 0
				return <Query 
					query={GET_FOLLOWING_INFO}
			    	variables= {{ profileId: profUserId, currentUserId: followingUserId }}
					>
					{({data, loading, error}) => {
						if (loading) return <div>Loading...</div>;
						if (error) return <div>Error</div>;

						const followingInfo = data.followingInfo
						return <Query 
							query={GET_SAVED_ATTRACTIONS}
							>
							{({data, loading, error}) => {
								if (loading) return <div>Loading...</div>;
								if (error) return <div>Error</div>;

								const savesNumber = data.savedAttractionViews.nodes.length
								return <Query query={GET_USER_VIEW_BY_ID} variables={{profUserId: profUserId}} >
									{({ loading, error, data }) => {

										if (loading) return <div>Loading...</div>;
										if (error) return <div>Error</div>;

										const user = data.userViewById
										
										if (!user) return <div>This user does not exist</div>
										
										
										const publicity = user.publicity 
										return <Query query={GET_UPCOMING_TRIPS} variables={{profUserId: profUserId}}>
											{({ loading, error, data }) => {
												if (loading) return <div>Loading...</div>;
												if (error) return <div>Error</div>;

												const upcomingTrips = (currentUser.id === profUserId || (followingInfo && followingInfo.status === 'APPROVED')) ? data.upcomingTrips.nodes : undefined
												// const upcomingTrips = []
												return <Mutation 
													mutation={CREATE_NEW_HEADER_PICTURE}
													refetchQueries={[{query: GET_USER_VIEW_BY_ID, variables: {profUserId: profUserId}}]}
													>
													{( createNewHeaderPicture, { loading, error }) => {

												 	
														return(<div>
														{addLocation && <AddLocationContainer 
															currentUser={currentUser} 
															profUserId={profUserId} 
															handleClose={this.handleHideAddLocation}
															locations={locations}
															/>}
														{showListView && <LocationListViewContainer
															handleHideListView={this.handleHideListView} 
															locations={locations}
															/>}

														{showCreateMessage && this.renderCreateMessage(user)}

														<ProfileHeader 
															blocked={followingInfo && followingInfo.status === "BLOCKED"}
															createNewHeaderPicture={createNewHeaderPicture}
															currentUser={currentUser}
															error={error}
															handleSetHeader={this.handleSetHeader}
															handleShowEdit={this.handleShowEdit}
															handleShowAddLocation={this.handleShowAddLocation}
															handleShowCreateMessage={this.handleShowCreateMessage}
															handleShowDeleteProfPic={this.handleShowDelete}
															handleShowFollow={this.handleShowFollow}
															handleShowHeaderUpload={this.handleShowHeaderUpload}
															handleShowInstaPrompt={this.handleShowInstaPrompt}
															handleShowListView={this.handleShowListView}
															handleShowUpload={this.handleShowUpload}
															headerPic={this.headerPic}
															headerSrc={headerSrc}
															locationsNumber={locations.length}
															savesNumber={savesNumber}
															profUserId={profUserId}
															user={user}

														/>


														
														 <MapView
															pins={locations} 
															tripPins={upcomingTrips}
															currentCityLat={user.currentCityLat}
															currentCityLng={user.currentCityLng}
															currentUser={currentUser}
															followingInfo={followingInfo}
															handleSelectPin={this.handleOpenLocation}
															handleSelectTripPin={this.handleSelectTripPin}
															profUserId={profUserId}
															view='profile'
															zoom={2}
															/> 
														</div>)
													}}
												</Mutation>
											}}
										</Query>
									}}
								</Query>
							}}
						</Query>
					}}
				</Query>

		     			
			}}
		</Query>	
	}	

	render() {
		const { run, steps } = this.state
		const { currentUser, screenView } = this.props 
		const {  
			followView,
			onMobile,
			profUserId,
			showDelete,
			showEdit, 
			showFollow, 
			showHeaderUpload,
			showInstaAdd,
			showInstaPrompt,
			showNoTripLocs,
			showUpload
		} = this.state;

		const popupClass = screenView.popup && "disabledBackground"
		return (
				<div className={`profileContainer ${popupClass}`}>

				<Joyride 
					callback={this.handleJoyrideCallback}
					continuous={true}
					showProgress={true}
          			showSkipButton={true}
					steps={steps} 
					run={run}

				/>
		

				{showEdit && this.renderEdit()}

				{showFollow && <FollowViewContainer 
					handleHideFollow={this.handleHideFollow} 
					profUserId={profUserId}
					followView={followView}
					/>}

				{showHeaderUpload && this.renderHeaderUpload()}


				{showUpload && this.renderUpload()}

				{showDelete && this.renderDelete()}

				{showInstaAdd && <InstaConnectionContainer
					handleClose={this.handleHideInstaAdd}
				 />}


				{showInstaPrompt && <InstaConnectionRequest 
				 	handleConnect={this.handleConnectInsta}
				 	handleClose={this.handleHideInstaPrompt}
				  />}

				  {showNoTripLocs && <NoTripLocations 
				  	handleClose={this.handleHideNoTripLocs}
				  />}

				 {onMobile ? <OpenApp /> : <div>
				 	{profUserId !== null ? <div>
						{!isNaN(profUserId) ? this.renderProfile() : <div>This user does not exist</div>}
					</div> : <div>Loading...</div>}
				 </div>}
				


	
	
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'}),
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
)(ProfileContainer);
