import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from "react-router-dom";

import "../stylesheets/components/Form.css"
import { Button } from 'reactstrap';

import initialPic from '../assets/globe.jpg'



const PasswordReset = props => {
	const {
		handlePasswordReset, 
		initialValues,
		loginError, 
		loginMessage, 
		resetSchema, 
		stateError
	} = props

	return (
	<div className="passwordReset initialScreen">
		<div className="initialScreenLeft">
			<div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">password reset</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
			{loginError && loginError}
			{loginMessage && loginMessage}
			{stateError && <div className="error">{stateError}</div>}

		<Formik
		      initialValues={initialValues}
		      validationSchema={resetSchema}
	
		      onSubmit={(values, {setSubmitting}) => {
		        handlePasswordReset(values, setSubmitting)
		      }}
		    >
		      {({ isSubmitting }) => (
		        <Form className="form">
		        	<div className="formItem">
			          	<label className="textTitle textTitleForm" htmlFor="password">verification key</label>
			          	<Field className="formField" type="text" name="verificationKey" autoComplete="off" required/>
		          	</div>
			          	<ErrorMessage name="verificationKey" component="div" required/>
				        
		        	
		        	<div className="formItem">
			          	<label className="textTitle textTitleForm" htmlFor="password">new password</label>
			          	<Field className="formField" type="password" name="password" autoComplete="off" required/>
		          	</div>
			          	<ErrorMessage name="password" component="div" required/>
				        
			          <button className="primaryButton singleFormButton" type="submit" disabled={isSubmitting}>
			           	 Reset Password
			          </button>

		          <Link className="textLink" to="/signup">Sign Up</Link>
		        </Form>
		      )}
		    </Formik>
		 </div>
		 <div className="initialScreenRight">
			<img 
			className="initialPicture"
		 	src={require('../assets/globe.jpg')} 
		 	alt="loginPicture"/>
		</div>

	</div>);
}

export default PasswordReset;
