 import React from "react";


const AttractionReviewAndTips = props => {
	const { 
		attraction, 
		currentUser, 
		handleShowEdit
	} = props
	return (
		<div className="attractionReviewAndTips">
			<div className="headerAndIcons">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">review</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				{attraction.userId === currentUser.id && 
					<i className="material-icons activeIcon iconMedium" onClick={() => handleShowEdit(attraction)}>edit</i>}
			</div>
			{attraction.review ? 
				<div>{attraction.objectionable ? 
					<div>
						{attraction.objectionable === 'CONFIRMED' ? 
	             		<div className="mainBackground">This review has been flagged.</div> : 
	             		<div className="mainBackground">This review has been reported and is under review.</div>}
				             	
					</div>
					: <div className="mainBackground">{attraction.review}</div>}
				</div>
				: <div className="mainBackground">N/A</div>}
			<div className="headerAndIcons">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">tips</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				{attraction.userId === currentUser.id && 
					<i className="material-icons activeIcon iconMedium" onClick={() => handleShowEdit(attraction)}>edit</i>}
			</div>
			{attraction.tips ? 
				<div>{attraction.objectionable ? 
					<div>
						{attraction.objectionable === 'CONFIRMED' ? 
	             		<div className="mainBackground">This tip has been flagged.</div> : 
	             		<div className="mainBackground">This tip has been reported and is under review.</div>}
				             	
					</div>
					: <div className="mainBackground">{attraction.tips}</div>}
				</div> : 
				<div className="mainBackground">N/A</div>
			}
		</div>
	);
};

export default AttractionReviewAndTips;
