import React from "react";


const OpenApp = props => {
	const { 
		
	} = props
	return (
		<div className="OpenApp">
			
			<div>We notice you're on mobile. Please click to open the app. If you have already closed the popup, please refresh and click to open the app.</div>
		</div>
	);
};

export default OpenApp;
