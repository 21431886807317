import React from "react";
import { compose, graphql, Query, withApollo, Mutation } from "react-apollo";
import * as Yup from "yup"

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import { 
	GET_FOLLOWERS_ALPHABETIZED,  
	GET_MY_TRIPS,
	GET_TRIP_BY_ID,
	GET_TRIP_USERS 
} from "../apollo/db/queries"
import {   
	CREATE_TRIP_USERS, 
	DELETE_TRIP_USER
} from "../apollo/db/mutations"


import CreateTripUsers from "../components/CreateTripUsers"
import Deletion from "../components/Deletion"
import TripUsers from "../components/TripUsers"

class TripUsersContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionId: null,
			deletionName: null,
			deletionUserId: null,
			isEditingUsers: false,
			isViewable: false,
			showAddTripUsers: false,
			showDelete: false,
			showUsers: false,
			users: [], 
			userIds: [],
			userValue: '',
			value: null
		}

		this.handleAddUser = this.handleAddUser.bind(this)
		this.handleCreateTripUsers = this.handleCreateTripUsers.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleGoToProfile = this.handleGoToProfile.bind(this)
		this.handleHideAddTripUsers = this.handleHideAddTripUsers.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleRemoveUser = this.handleRemoveUser.bind(this)
		this.handleShowAddTripUsers = this.handleShowAddTripUsers.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowUsers = this.handleShowUsers.bind(this)
		this.handleToggleIsEditingUsers = this.handleToggleIsEditingUsers.bind(this)
		this.handleUserChange = this.handleUserChange.bind(this)
	}
	
	async handleShowDelete(user){
		const { setPopup } = this.props
		const userName = user.firstName + ' ' + user.lastName
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: user.id, deletionUserId: user.userId, deletionName: userName})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null, deletionName: null})
	}

	handleToggleIsEditingUsers(){
		const { isEditingUsers } = this.state
		this.setState({isEditingUsers: !isEditingUsers})
	}

	handleUserChange(event){
		this.setState({userValue: event.target.value})
	}

	handleShowUsers(){
		this.setState({showUsers: true})
	}

	async handleShowAddTripUsers(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAddTripUsers: true})
	}

	async handleHideAddTripUsers(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAddTripUsers: false})
	}

	handleGoToProfile(profId){
		history.push(`/profile/${profId}`)
	}

	async handleCreateTripUsers(createTripUsers){
		const { currentUser, client, setPopup, tripId } = this.props
		const { userIds } = this.state

		try {
			await createTripUsers({   	 	
				   	 	variables: {
				   	 		input: {
								parentTripId: tripId, 
								userIds: userIds, 
								adderUserId: currentUser.id
							}
						}
			})
			await setPopup({
				variables: {
					popup: false
				}
			})
			this.setState({showAddTripUsers: false})

			
		} catch {
			console.log('error')
		}
	}
	
	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		const { setPopup } = this.props
		try {
				await mutationFunction({
					variables: {
						input: {
							id: deletionId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showDelete: false, deletionId: null })
			} catch {
				console.log('error')
			}
	}

	handleAddUser(follower){
		const { userIds, users } = this.state
		const userId = follower.followerId
		const user = {
			name: follower.firstName + ' ' + follower.lastName, 
			id: userId, 
			publicUrl: follower.publicUrl
		}
		this.setState({
			userIds: [...userIds, userId],
			users: [...users, user], 
			userValue: ''
		})
	}

	handleRemoveUser(userId){
		const { userIds, users } = this.state
		const newUserIds = userIds.filter(id => id !== userId)
		const newUsers = users.filter(user => user.id !== userId)
		this.setState({userIds: newUserIds, users: newUsers})
	}


	renderTripUsers (){
		const { isEditingUsers, showAddTripUsers, showDelete  } = this.state
		const { currentUser, tripId } = this.props
		
		return <Query 
			query={GET_TRIP_BY_ID}
    		variables= {{ id: tripId }}
		>
			{({data, loading, error}) => { 
				if (loading) { return <div>Loading...</div> }
				if (error) { return <div>Error</div> }

				const trip = data.tripById
				const creatorId = trip.creatorId

				return <Query 
					query={GET_TRIP_USERS}
			    	variables= {{ viewedTripId: tripId}}
			    	fetchPolicy='network-only'
					>
					{({data, loading, error}) => {
						if(loading) {return <div>Loading..</div>}
						if(error) {return <div>Error</div>}
						const tripUsers = data.tripUsers.nodes
					
						
				      	return <div>
				  
					      	{showAddTripUsers && this.renderAddTripUsers(tripUsers)}
							<TripUsers 
							    error={error}
								creatorId={creatorId}
								currentUser={currentUser}
								handleGoToProfile={this.handleGoToProfile}
								handleShowDelete={this.handleShowDelete}
								handleShowAddTripUsers={this.handleShowAddTripUsers}
								handleToggleIsEditingUsers={this.handleToggleIsEditingUsers}
								isEditingUsers = {isEditingUsers}
								tripUsers={tripUsers}
							/>
						
						</div>	 
					
					}}
				</Query>
			}}
		</Query>
	}

	renderDelete(){
		const { currentUser, tripId } = this.props
		const { deletionUserId, deletionName } = this.state

		

		return <Mutation
     		mutation={DELETE_TRIP_USER}
     		refetchQueries={() => {
     			if (deletionUserId === currentUser.id){
     				return [{query: GET_TRIP_USERS, variables:{viewedTripId: tripId}}, 
     				{query: GET_MY_TRIPS}]
     			} else {
     				return [{query: GET_TRIP_USERS, variables:{viewedTripId: tripId}}]
     			}
     		}}
	    >
	      {(deleteTripUser, { loading, error }) => {
	      	return  <div className="deletionUser">
	      		<Deletion 
	      		    error={error}
	      	 		handleDelete={this.handleDelete}
	      	 		handleHideDelete={this.handleHideDelete}
	      	 		mutationFunction={deleteTripUser}
	      	 		name={deletionName}
	      	 		type='trip user'
	    		/>
	    	</div>

	      }}
		</Mutation>

	}

	renderAddTripUsers(tripUsers){
		const { currentUser, tripId } = this.props
		const {  showUsers, userIds, users, userValue } = this.state

		return <Query query={GET_FOLLOWERS_ALPHABETIZED} variables={{profUserId: currentUser.id}}>
			{({ loading, error, data }) => {
				if (loading) {return <div> Loading... </div>}
				if (error) {return <div>Error</div>}
				if (data.followersAlphabetized.nodes.length === 0) {return <div>You currently have no one following you</div>}
				const followers = data.followersAlphabetized.nodes

				return <Mutation
		     		 mutation={CREATE_TRIP_USERS}
		     		 refetchQueries={[{query: GET_TRIP_USERS, variables:{viewedTripId: tripId}}]}
			    >
			      	{(createTripUsers, { loading, error }) => (
			
						 <CreateTripUsers
						    error={error} 
					 		createTripUsers={createTripUsers}
							followers={followers}
							handleFocus={this.handleShowUsers}
							handleChange={this.handleUserChange}
							handleClose={this.handleHideAddTripUsers}
							handleRemoveUser={this.handleRemoveUser}
							handleSelect={this.handleAddUser}
							handleSubmit={this.handleCreateTripUsers}
							showUsers={showUsers}
							tripUsers={tripUsers}
							userIds={userIds}
							users={users}
							userValue={userValue}
							view='trip'
						/>
					)}
			    </Mutation>
			}}
		</Query>	
	}
	
	render() {
		const { deletionId, isViewable, mutationFunction, showDelete } = this.state
		return (<div className="tripUsersContainer">
				{this.renderTripUsers()}
				{showDelete && this.renderDelete()}
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
)(TripUsersContainer);
