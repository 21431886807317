import React from "react";
import { Query, compose } from "react-apollo";

import {GET_FOLLOWERS_ALPHABETIZED, GET_FOLLOWING_ALPHABETIZED} from "../apollo/db/queries"

import FollowView from "../components/FollowView"


class FollowViewContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: '',
			view: null
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleViewFollowers = this.handleViewFollowers.bind(this)
		this.handleViewFollowing = this.handleViewFollowing.bind(this)
	}

	componentDidMount(){
		const {view} = this.state
		const { followView } = this.props
		if (view !== followView){
			this.setState({
				view: followView
			})
		}
	}

	handleChange(event){
		this.setState({value: event.target.value})
	}

	handleViewFollowers(){
		this.setState({view: "followers", value: ''})
	}

	handleViewFollowing(){
		this.setState({view: "following", value: ''})
	}

	renderFollowersView(){
		const { handleHideFollow, profUserId } = this.props
		const { value, view } = this.state
		return (<Query query={GET_FOLLOWERS_ALPHABETIZED} variables={{profUserId: profUserId}}>
					{({ loading, error, data }) => {
						if (loading) {return <div> Loading... </div>}
						if (error) {return <div> Error </div>}
						const followers = data.followersAlphabetized.nodes
						return <FollowView 
							handleChange={this.handleChange}
							handleClose={handleHideFollow}
							handleViewFollowers={this.handleViewFollowers}
							handleViewFollowing={this.handleViewFollowing}
							users={followers}
							value={value}
							view={view}
						/>
					}}

				</Query>)
	}

	renderFollowingView(){
		const { handleHideFollow, profUserId } = this.props
		const { value, view } = this.state
		return (<Query query={GET_FOLLOWING_ALPHABETIZED} variables={{profUserId: profUserId}}>
					{({ loading, error, data }) => {
	
						if (loading) {return <div> Loading... </div>}
						if (error) {return <div> Error </div>}
						const following = data.followingAlphabetized.nodes
						return <FollowView
							handleChange={this.handleChange} 
							handleClose={handleHideFollow}
							handleViewFollowers={this.handleViewFollowers}
							handleViewFollowing={this.handleViewFollowing}
							users={following}
							value={value}
							view={view}
						/>
					}}

				</Query>)
	}
	render() {
		const { view } = this.state
		
		return (
			<div className="followViewContainer">
				{view === "followers" ? this.renderFollowersView() : this.renderFollowingView()}
			</div>
		);
	}
}

export default compose()(FollowViewContainer);
