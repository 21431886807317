import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import "../stylesheets/components/LocItinerary.css"

import ItineraryItem from "../components/ItineraryItem"
import ItineraryDropZone from "../components/ItineraryDropZone"

const LocItinerary = props => {
	const { 
		datesList,
		datesStartIndex,
		error,
		handleChangeDate,
		handleLeftDateArrow, 
		handleRightDateArrow,
		handleShowAddItem, 
		handleShowDelete,
		handleShowEdit,
		itineraryItems, 
		renderItineraryDropZone,
		renderItineraryItem,
		selectedDate, 
		tripId
	} = props
	
	const selectedDateClass = date => selectedDate && selectedDate.getTime() === date.getTime() && 'tabSelected';
	
	const lastDateClass = ind => ind === datesStartIndex + 3 && 'lastDate';

	return (
		<div className="locItinerary">
			<div className="locItineraryHeader">
				
				<Link className="secondaryButton" to={`/tripItinerary/${tripId}`}>Trip Itinerary</Link>
				<img 
					className="iconMedium" 
					onClick={() => handleShowAddItem()}
					src="https://storage.googleapis.com/trvlpage-bucket/plus.svg"
					/>
	
			</div>
			<div className="itineraryTable">
				<div className="tabButtons">
					{datesList.map((date, index) => {
						if (index === datesStartIndex && index !== 0){
							return <div className="tabAndArrow" key={date.id}>
								<div onClick={() => handleLeftDateArrow()}>\</div>
								<div className={`tabButton fontSmall ${selectedDateClass(date)} ${lastDateClass(index)} `} onClick={() => handleChangeDate(date)}>{moment.utc(date).format('MMMM Do YYYY, h:mm:ss a').slice(0, -17)}</div>
							</div>
						} else if (index === datesStartIndex + 2 && index + 1 < datesList.length){
							return <div className="tabAndArrow" key={date.id}>
								<div className={`tabButton fontSmall ${selectedDateClass(date)} ${lastDateClass(index)}`} onClick={() => handleChangeDate(date)}>{moment.utc(date).format('MMMM Do YYYY, h:mm:ss a').slice(0, -17)}</div>
								<div onClick={() => handleRightDateArrow()}>/</div>
							</div>

						} else if (index >= datesStartIndex && index <= datesStartIndex + 2){
							return <div className={`tabButton fontSmall ${selectedDateClass(date)} ${lastDateClass(index)}`} key={date.id} onClick={() => handleChangeDate(date)}>{moment.utc(date).format('MMMM Do YYYY, h:mm:ss a').slice(0, -17)}</div>
						}
					})}
				</div>
				<div className="mainBackground">
					{renderItineraryDropZone(0, itineraryItems.length === 0)}
					{itineraryItems.map(item => {
						return <div>
							<div className="itemAndButtons">
								{<div className="itineraryEditButtons"><img 
										className="iconSmall activeIcon" 
										onClick={() => handleShowEdit(item)}
										src="https://storage.googleapis.com/trvlpage-bucket/pencil.svg" 
										alt="editIcon"
									/>
									<div className="removeIcon" onClick={() => handleShowDelete(item)}>-</div>
								</div>}
								<ItineraryItem
					  				handleShowDelete={handleShowDelete}
									itineraryItem={item}
									type="existing"
								/>
								
							</div>
							{renderItineraryDropZone(item.orderNumber, item.orderNumber === itineraryItems.length)}
						</div>


					})}
					
				</div>

			</div>
		</div>
	);
};

export default LocItinerary;
