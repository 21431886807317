import React from "react";

import "../stylesheets/components/SavedHeader.css"

import Autocomplete from "../components/Autocomplete"

const SavedHeader = props => {
	const {
		error,
		filterTerm,
		handleChangeFilter,
		handleChangeZoom,
		handleEnter, 
		handleSelectZoom,
		handleViewChange,
		view, 
		zoomTerm
	} = props
		const selected = type => view === type && 'toggleSelected'
	return (
		<div className="savedHeader">
			<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">saved</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
			<div className="toggleButtons"> 
				<div className={`toggleButton toggleLeft ${selected('list')}`} onClick={() => handleViewChange("list")}>List View</div>
				<div className={`toggleButton toggleRight ${selected('map')}`} onClick={() => handleViewChange("map")}>Map View</div>
			</div>
			{view === 'list' ? <div className="savedFilter">
				<input className="formField" onChange={event => handleChangeFilter(event)} value={filterTerm} type="text" name="search" placeholder={"Filter by city or country"} autoComplete="off"/>
			</div> : <div className="savedFilter">
				<Autocomplete 
					error={error}
					handleChange={handleChangeZoom}
					handleSelect={handleSelectZoom}
					handleSubmit={handleEnter}
					placeholder="City or country to zoom in to"
					value={zoomTerm}
					type="saved"
					
				/>
			</div>}
		</div>
	);
};

export default SavedHeader;
