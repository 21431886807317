import React from "react";

//import "../stylesheets/components/ReportObjectionable.css"

const ReportObjectionable = props => {
	const { 
		error, 
		handleReport, 
		handleHideReport, 
		name,
		mutationFunction, 
		type,
		} = props;
	return (
		<div className="reportObjectionable popup popupSmall">
			<div className="popupContent">
				{name ? <div className="tertiaryHeader">{`Are you sure you want to report the ${type} ${name}?`}</div> : <div className="secondaryHeader">{`Are you sure you want to report this ${type}?`}</div>}
				{error && <div className="error">There was an error.</div>}
				<div className="formButtons">
					<button className="secondaryButton formButton" onClick={() => handleHideReport()}> Nevermind </button>
					<button className="primaryButton formButton" onClick={(e) => handleReport(mutationFunction, e)}>Yes</button>
				</div>
			</div>
		</div>
	);
};

export default ReportObjectionable;
