import React from "react";

import Autocomplete from "../components/Autocomplete";

import initialPic from '../assets/globe.jpg'


const LocationsSignup = props => {
	const {
		error, 
		handleChange,
		handleClose, 
		handleRemoveLocation,
		handleIncreaseLocAmount,
		handleSelect,
		handleSubmit, 
		locationAmount,
		loading, 
		mutationFunction
	} = props
	const locationExamples = ['Nashville', 'Vienna', 'Cabo San Lucas', 'Austin', 'Cape Town', 'Brisbane', 'Bangkok', 'New York City', 'Singapore', 'Madrid']
	const getAutocompletes =  (locationAmount) => {
			const autocompletes = []
			while(autocompletes.length < locationAmount){
				const index = autocompletes.length
				autocompletes.push(<div className="autocompleteHolder">
					<Autocomplete 
				        loading={loading} 
				        error={error} 
				        handleRemoveLocation={handleRemoveLocation}
				        handleSubmit={handleSubmit}
				        handleSelect={handleSelect}
				        handleClose={handleClose}
				        mutationFunction={mutationFunction}
				        locationAmount={locationAmount}
				        placeholder={`Ex. ${locationExamples[index]}`}
				        type="locationsSignup"
				        index={index}
				        cancelText="Later" />
				    </div>)
			}  
			 
			return autocompletes 
		}


	return (
		<div className="locationsSignup initialScreen">
			<div className="initialScreenLeft">
				<div className="headerHolder initialHeaderHolder" style={{'margin-top': '-2rem'}}>
					<div className="mainHeader initialHeader">reflect</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
				<div className="formAdditionalText">To get started, fill in a few locations you've traveled to.</div>
				<div className='form'>

					<div className="fullWidth">{getAutocompletes(locationAmount)}</div>
					{locationAmount < 5 && <div className="textLink" onClick={() => handleIncreaseLocAmount()}>Add Another Location</div>}
			    
			        <div className="progressDots">
			         	<div className="progressDot"></div>
			         	<div className="progressDot"></div>
			         	<div className="progressDot"></div>
			         	<div className="progressDot"></div>
			         	<div className="progressDot progressDotFilled"></div>
			         	<div className="progressDot"></div>
					  		
		      		</div>
		     	</div>
			</div>
			<div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="formPicture"/>
			</div>
			
		</div>

	);
};

export default LocationsSignup;