import React from "react";
import { Link } from 'react-router-dom'

//import "../stylesheets/components/Voters.css"

const Voters = props => {
	const { 
		handleHideVoters,
		voters
	} = props
	return (
		<div className="voters popup popupMedium">
			<div className="popupContent popupScroll">
				{voters.map(voter => {
					return <div className="userInfo mainBackground" key={voter.id}>
						<img className="picSmall" src={voter.publicUrl} alt="profPic"/>
						<Link className="userName textLink" to={`/profile/${voter.userId}`}>{(voter.firstName + ' ' + voter.lastName)}</Link>
					</div>
				})}
			</div>
			<button className="tertiaryButton iconMedium" onClick={() => handleHideVoters()}>X</button>
		</div>
	);
};

export default Voters;
