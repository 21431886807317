import React from "react";
import { compose, graphql, withApollo, Query, Mutation } from "react-apollo";
import * as Yup from "yup"


import history from "../history"

import Autocomplete from "../components/Autocomplete"
import CommentsContainer from "../containers/CommentsContainer"
import Deletion from "../components/Deletion"
import GroupedAttractionsList from "../components/GroupedAttractionsList"
import MapView from "../components/MapView"
import Voters from "../components/Voters"

import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import {  
	GET_TRIP_GENERAL_ATTRACTIONS,  
	GET_TRIP_USERS
	 } from "../apollo/db/queries"
import { 
	CREATE_NEW_TRIP_GENERAL_ATTRACTION, 
	DELETE_TRIP_ATTRACTION, 
	DELETE_TRIP_GENERAL_ATTRACTION
	 } from "../apollo/db/mutations"

class TripLocAttractionsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chosenType: 'ALL',
			collapseId: null,
			deletionId: null,
			deletionGeneralAttrId: null,
			deletionName: null,
			deletionTripAttrId: null, 
			deletionType: null,
			isEditing: false,
			newAttraction: null,
			newAttractionType: null,
			showAddAttraction: false,
			showDelete: false,
			showDeleteTripAttr: false,
			showVoters: false,
			stateError: null,
			value: '', 
			voters: []
		}

		this.getDisplayAttractions = this.getDisplayAttractions.bind(this)
		this.getSuggestedAttractionType = this.getSuggestedAttractionType.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleCreateTripAttraction = this.handleCreateTripAttraction.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleDeleteTripAttr = this.handleDeleteTripAttr.bind(this)
		this.handleGoToProfile = this.handleGoToProfile.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideAddAttraction = this.handleHideAddAttraction.bind(this)
		this.handleHideDeleteTripAttr = this.handleHideDeleteTripAttr.bind(this)
		this.handleHideVoters = this.handleHideVoters.bind(this)
		this.handleSelectAttraction = this.handleSelectAttraction.bind(this)
		this.handleSelectAttractionType = this.handleSelectAttractionType.bind(this)
		this.handleSelectNewAttraction = this.handleSelectNewAttraction.bind(this)
		this.handleShowAddAttraction = this.handleShowAddAttraction.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowDeleteTripAttr = this.handleShowDeleteTripAttr.bind(this)
		this.handleShowVoters = this.handleShowVoters.bind(this)
		this.handleToggleAttraction = this.handleToggleAttraction.bind(this)
		this.handleToggleIsEditing = this.handleToggleIsEditing.bind(this)
		this.handleToggleType = this.handleToggleType.bind(this)
	}


	getDisplayAttractions(attractions){
		const { chosenType } = this.state
		const displayAttractions = []
		attractions.forEach(attraction => {
			if (chosenType === 'ALL' || attraction.attractionTypes.includes(chosenType)){
				/*plpgsql wont allow custom nested array data types so reviews cant have savedId. 
				This is a janky way to pull the savedId out of a saved attractions array */
				const attrReviews = []
				attraction.reviews && attraction.reviews.forEach(review => {
					const saved = attraction.savedAttractions && attraction.savedAttractions.find(savedAttr => savedAttr.id === review.id)
					const attrReview = Object.assign({}, review, {savedId: saved ? saved.savedId : null})
					attrReviews.push(attrReview)
				})
				const finalAttr = Object.assign({}, attraction)
				finalAttr.reviews = attrReviews
				displayAttractions.push(finalAttr)
			}
		})

		return displayAttractions
	}



	async handleCreateTripAttraction(event, createNewTripGeneralAttraction, selected, attractions){
		const { newAttraction, newAttractionType } = this.state
		const { setPopup, tripLocId } = this.props

		event.preventDefault();

		if (!selected || !selected.id) {
			this.setState({stateError: 'Please select an attraction'})
			return null;
		}
		
		const placeIds = attractions.map(attraction => {
			return attraction.placeId
		})
		if (!placeIds.includes(selected.place_id)){
			try {
				await createNewTripGeneralAttraction({
					variables: {
						input: {
							tripLocId: tripLocId,
							attrName: selected.name,
							attrPlaceId: selected.place_id,
							attrLat: selected.geometry.location.lat(),
							attrLng: selected.geometry.location.lng(), 
							attrType: newAttractionType
							}	
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({showAddAttraction: false, newAttractionType: null, value: null})
				
			} catch {
				console.log('error')
			}
		} else {
			this.setState({error: "This attraction has already been added"})
		}
	}


	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		const { setPopup } = this.props 
		try {
				await mutationFunction({
					variables: {
						input: {
							id: deletionId
							}
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showDelete: false, deletionId: null })

			} catch {
				console.log('error')
			}
	}

	async handleDeleteTripAttr(deleteTripAttraction){
		const { deletionTripAttrId, deletionGeneralAttrId } = this.state
		const { setPopup } = this.props
		try {
				await deleteTripAttraction({
					variables: {
						input: {
							attrId: deletionTripAttrId, 
							tripGeneralAttrId: deletionGeneralAttrId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ showDeleteTripAttr: false, deletionTripAttrId: null, deletionGeneralAttrId: null })

			} catch {
				console.log('error')
			}
	}



	selectedToggle(view){
		const { mapView } = this.props
		return view === mapView && 'toggleSelected'
	}

	handleSelectAttraction(attractionId){
		history.push(`/attraction/${attractionId}`)
	}

	handleToggleIsEditing(){
		const { isEditing } = this.state
		this.setState({isEditing: !isEditing})
	}

	handleChange(event){
		this.setState({value: event.target.value, stateError: null})
	}

	handleSelectNewAttraction(selected){
		const suggestedType = this.getSuggestedAttractionType(selected)
		this.setState({ newAttractionType: suggestedType, value: selected.formatted_name})
	}

	handleSelectAttractionType(type){
		this.setState({newAttractionType: type})
	}

	async handleShowAddAttraction(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAddAttraction: true})
	}

	async handleHideAddAttraction(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAddAttraction: false, newAttraction: null, value: null, newAttractionType: null})
	}

	async handleShowDelete(attraction, deletionType){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: attraction.id, deletionName: attraction.name, deletionType: deletionType})
	}

	async handleShowDeleteTripAttr(attractionId, tripGeneralAttrId){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDeleteTripAttr: true, deletionTripAttrId: attractionId, deletionGeneralAttrId: tripGeneralAttrId})
	}

	async handleHideDeleteTripAttr(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDeleteTripAttr: false, deletionTripAttrId: null, deletionGeneralAttrId: null})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null, deletionType: null, deletionName: null})
	}

	handleToggleType(type){
		this.setState({chosenType: type})
	}

	handleShowVoters(voters){
		this.setState({showVoters: true, voters: voters})
	}

	handleHideVoters(){
		this.setState({showVoters: false})
	}

	handleGoToProfile(profUserId){
		history.push(`/profile/${profUserId}`)
	}

	handleToggleAttraction(collapseId){
		if (collapseId){
			this.setState({collapseId: collapseId})
		} else {
			this.setState({collapseId: null})
		}
	}

	 getSuggestedAttractionType(selected){
		let suggestedType;
		if (selected.types.includes('restaurant') || selected.types.includes('food') || selected.types.includes('bar')){
			return "RESTAURANT"
		} else if (selected.types.includes('lodging')){
			return "LODGING"
		} else {
			return"ACTIVITY"
		}

	}



	renderVoters(){
		const { voters } = this.state
		return <Voters 
			handleHideVoters={this.handleHideVoters}
			voters={voters}
		/>
	}	

	

	renderDeleteGeneralAttraction(){
		const { tripLocId } = this.props
		const { deletionName } = this.state
		return <Mutation
		    mutation={DELETE_TRIP_GENERAL_ATTRACTION}
		    refetchQueries={[{query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLocId}}]}
  		> 
  			{(deleteTripGeneralAttraction, { data, loading, error }) => {

				return <Deletion 
				    error={error}
					handleDelete={this.handleDelete}
					handleHideDelete={this.handleHideDelete}
					mutationFunction={deleteTripGeneralAttraction}
					name={deletionName}
					type="attraction"
				/>
			}}
		</Mutation>
	}

	renderDeleteTripAttr(){
		const { collapseId, chosenType } = this.state
		const { tripLocId } = this.props

		return <Mutation
		    mutation={DELETE_TRIP_ATTRACTION}
		    refetchQueries={[{query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLocId}}]}
  		> 
  			{(deleteTripAttraction, { data, loading, error }) => {
  				return <Deletion 
  				    error={error}
					handleDelete={this.handleDeleteTripAttr}
					handleHideDelete={this.handleHideDeleteTripAttr}
					mutationFunction={deleteTripAttraction}
					type="attraction review"
				/>
  			}}
  		</Mutation>

	}


	renderAddAttraction(attractions){
		const { newAttraction, newAttractionType, stateError, value } = this.state
		const { tripLocId, tripLocation } = this.props
		const addButtonDisabled = newAttraction && newAttractionType ? false : true
		return <Mutation
		    mutation={CREATE_NEW_TRIP_GENERAL_ATTRACTION}
		    refetchQueries={[{query: GET_TRIP_GENERAL_ATTRACTIONS, variables: {tripLocId: tripLocId}}]}
  		> 
  			{(createNewTripGeneralAttraction, { data, loading, error }) => {
  				return <div className="addTripAttraction popup popupMedium">
	  				<Autocomplete 
	  					cancelText='Cancel'
	  					error={error}
	  					handleChange={this.handleChange}
	  					handleClose={this.handleHideAddAttraction}
	  					handleSelect={this.handleSelectNewAttraction}
	  					handleSelectAttractionType={this.handleSelectAttractionType}
	  					handleSubmit={this.handleCreateTripAttraction}
	  					items={attractions}
	  					locationLat={tripLocation.lat}
	  					locationLng={tripLocation.lng}
	  					mutationFunction={createNewTripGeneralAttraction}
	  					name="Add New Attraction"
	  					newAttraction={newAttraction}
	  					newAttractionType={newAttractionType}
	  					placeholder="Enter a place"
	  					popup={true}
	  					stateError={stateError}
	  					type="tripAttraction"
	  					value={value}
	  				/> 
	  				
  				</div>

  			}}
  		</Mutation>
	}

	renderAttractions(){
		const { currentUser, handleToggleMapView, itineraryPins, mapView, tripId, tripLocId, tripLocation, view } = this.props
		const { collapseId, chosenType, deletionType, isEditing, showAddAttraction, showDelete } = this.state

		return  <Query query={GET_TRIP_GENERAL_ATTRACTIONS} variables={{tripLocId: tripLocId}}>
  			{({ loading, error, data }) => {
  			if (loading) {return <div> Loading... </div>}
  			if (error) {return <div>Error</div>}

			const attractions = data.tripGeneralAttractions.nodes

		
			const viewClass = view === 'overview' ? 'tripLocAttrOverview' : 'tripLocAttrItinerary'
	
			return <div className={`${viewClass}`}>
				{showAddAttraction && this.renderAddAttraction(attractions)}
					<div className="tripLocAttrLeft">
						<GroupedAttractionsList
							attractions={this.getDisplayAttractions(attractions)}
							collapseId={collapseId}
							chosenType={chosenType}
							currentUser={currentUser}
							isEditing={isEditing}
							handleSelectAttraction={this.handleSelectAttraction}
							handleShowAddAttraction={this.handleShowAddAttraction}
							handleShowDelete={this.handleShowDelete}
							handleShowDeleteTripAttr={this.handleShowDeleteTripAttr}
							handleShowVoters={this.handleShowVoters}
							handleToggleIsEditing={this.handleToggleIsEditing}
							handleToggleAttraction={this.handleToggleAttraction}
							handleToggleType={this.handleToggleType}
							tripId={tripId}
							tripLocId={tripLocId}
							tripLocView={view}
							view="tripLocation"
						/>
						{view === 'tripLocItinerary' && 
						<div>
							<div className="toggleButtons">
								<div className={`toggleButton toggleLeft ${this.selectedToggle('all')} `} onClick={() => handleToggleMapView('all')}>All</div>
								<div className={`toggleButton toggleRight ${this.selectedToggle('itinerary')} `} onClick={() => handleToggleMapView('itinerary')}>Itinerary (selected date)</div>
							</div>
							{mapView === 'all' ? <MapView 
								centerLat={tripLocation.lat}
								centerLng={tripLocation.lng}
								pins={this.getDisplayAttractions(attractions)}
								view='tripLocation'
								zoom={11}
								/> : 
							<MapView 
								centerLat={tripLocation.lat}
								centerLng={tripLocation.lng}
								pins={itineraryPins}
								view='tripLocation'
								zoom={11}
								/>}
						</div>}
					</div>
					
					{view === 'overview' && <div className="tripLocAttrRight">
							 <MapView 
								centerLat={tripLocation.lat}
								centerLng={tripLocation.lng}
								pins={this.getDisplayAttractions(attractions)}
								view='tripLocation'
								zoom={11}
								/>
					</div>}
					
			</div>
		
			
			
			}}
		</Query>
	}


	
	render() {
		const { deletionType, showDelete, showDeleteTripAttr, showVoters } = this.state

		return (
			<div className="tripLocAttractionsContainer">
				{showDelete && this.renderDeleteGeneralAttraction()}
				{showDeleteTripAttr &&  this.renderDeleteTripAttr()}
				{showVoters && this.renderVoters()}
				{this.renderAttractions()}
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
	)(TripLocAttractionsContainer);
