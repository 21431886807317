import React from "react";
import { compose, graphql, withApollo, Query, Mutation } from "react-apollo";
import axios from "axios";
import history from "../history";

import { getCurrentUser, getPopup } from "../apollo/state/queries";
import { setCurrentUser, setNotificationsType } from "../apollo/state/mutations";
import {  
	GET_FOLLOWERS_ALPHABETIZED, 
	GET_FOLLOWERS_BY_DATE,  
	GET_FOLLOWING_ALPHABETIZED,
	GET_NOTIFICATIONS_BY_DATE,
	GET_UNSEEN_FOLLOWERS, 
	GET_UNSEEN_MESSAGES,
	GET_UNSEEN_NOTIFICATIONS
} from "../apollo/db/queries";
import { UPDATE_FOLLOW, UPDATE_FOLLOWER_ALERTS, UPDATE_NOTIFICATIONS } from "../apollo/db/mutations"

import NavBar from "../components/NavBar";
import AllMessagesContainer from "./AllMessagesContainer"
import Notification from "../components/Notification"
import NotificationsContainer from "./NotificationsContainer"

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}

class NavBarContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			showMessages: false,
			showNotifications: false
		};

		this.logout = this.logout.bind(this);
		this.handleAddNotificationListener = this.handleAddNotificationListener.bind(this)
		this.handleAddMessageListener = this.handleAddMessageListener.bind(this)
		this.handleCloseNotifications = this.handleCloseNotifications.bind(this)
		this.handleCloseMessages = this.handleCloseMessages.bind(this)
		this.handleGoToLogin = this.handleGoToLogin.bind(this)
		this.handleGoToSignup = this.handleGoToSignup.bind(this)
		this.handleRemoveNotificationListener = this.handleRemoveNotificationListener.bind(this)
		this.handleRemoveMessageListener = this.handleRemoveMessageListener.bind(this)
		this.handleToggleNotifications = this.handleToggleNotifications.bind(this)
		this.handleToggleMessages = this.handleToggleMessages.bind(this)
	
	}


	componentWillUnmount(){
		console.log('unmount?')
		window.removeEventListener('click', this.handleCloseNotifications)
	}

	handleRemoveNotificationListener(){
		console.log('remove event listener')

		window.removeEventListener('click', this.handleCloseNotifications)
	}

	handleRemoveMessageListener(){
		window.removeEventListener('click', this.handleCloseMessages)
	}

	handleCloseNotifications() {
		console.log('close noti')
	  this.setState({showNotifications: false}, () => {
	    window.removeEventListener('click', this.handleCloseNotifications)
	  })
	}

	handleCloseMessages() {
	  this.setState({showMessages: false}, () => {
	    window.removeEventListener('click', this.handleCloseMessages)
	  })
	}

	handleAddNotificationListener() {
		console.log('add noti listener')
		window.addEventListener('click', this.handleCloseNotifications)		  
	}

	handleAddMessageListener() {
		window.addEventListener('click', this.handleCloseMessages)		  
	}

	handleGoToLogin(){
		const { client } = this.props
		client.resetStore()
		history.push('/login')

	}

	handleGoToSignup(){
		const { client } = this.props
		client.resetStore()
		history.push('/signup')
	}


	async handleToggleNotifications(event){
		console.log('toggle notifications')
		const { client } = this.props
		if (event){
			event.stopPropagation()
		}
		const { showNotifications } = this.state 
		this.setState(({showNotifications: !showNotifications, showMessages: false}), () => {
			console.log('this.state.noti', this.state.showNotifications)
		    if (this.state.showNotifications) {
		      window.addEventListener('click', this.handleCloseNotifications)
		    } else {
		      window.removeEventListener('click', this.handleCloseNotifications)
		    }
  		})
	}

	async handleToggleMessages(event){
		const { client } = this.props
		if (event){
			event.stopPropagation()
		}
		const { showMessages } = this.state 
		this.setState(({showMessages: !showMessages, showNotifications: false}), () => {
		    if (this.state.showMessages) {
		      window.addEventListener('click', this.handleCloseMessages)
		    }
  		})
	}

	
	async logout(event) {
		const {client} = this.props
		
		try{
			await axios.delete(`${getUrl()}/me`, {withCredentials: true});
	
			client.resetStore()

			history.push("/");
		} catch {
			console.log('error')
		}
	}

	renderCurrentUserNav(){
		const { currentUser, value } = this.props;
		const { showMessages, showNotifications } = this.state
		
	
		return <Query
			query={GET_UNSEEN_FOLLOWERS}
			pollInterval={60000}
		>
		{({data, loading, error}) => {
			if (loading) return <div>Loading...</div> 
			if (error) return <div>Error</div>	
			const unseenFollowers = data.unseenFollowers

			return <Query
				query={GET_UNSEEN_NOTIFICATIONS}
				//pollInterval={60000}
			>
				{({data, loading, error}) => {
					if (loading) return <div>Loading...</div> 
					if (error) return <div>Error</div>	
					const unseenNotifications = data.unseenNotifications

						return <Query
							query={GET_UNSEEN_MESSAGES}
							pollInterval={60000}
						>
							{({data, loading, error}) => {
								if (loading) return <div>Loading...</div> 
								if (error) return <div>Error</div>	
								const unseenMessages = data.unseenMessages
						
								return <div>
									<NavBar 
										currentUser={currentUser}  
										handleToggleMessages={this.handleToggleMessages}
										handleToggleNotifications={this.handleToggleNotifications}
										logout={this.logout}
										unseenFollowers={unseenFollowers}
										unseenMessages={unseenMessages}
										unseenNotifications={unseenNotifications}
									 />
									
									{showMessages && this.renderMessages()}
									{showNotifications && this.renderNotifications()}
							
				 				</div>
				 			}}
				 		</Query>
		 			}}
		 		</Query>
 			}}
 		</Query>

	}


	renderNotifications(){
			return <NotificationsContainer 
				handleAddListener={this.handleAddNotificationListener}
				handleBlur={this.handleCloseNotifications}
				handleClose={this.handleToggleNotifications}
				handleRemoveListener={this.handleRemoveNotificationListener}
				view="navBar"
			/>

			
	}

	renderMessages(){
		return <AllMessagesContainer 
			handleAddMessageListener={this.handleAddMessageListener}
			handleBlur={this.handleCloseMessages}
			handleClose={this.handleToggleMessages}
			handleRemoveListener={this.handleRemoveNotificationListener}
			view="navBar"
		/>
	}



	render() {
		const { currentUser, screenView } = this.props;
		const popupClass = screenView.popup && "disabledBackground"
		//add css pointer-events:none; opacity: 0.5 to make editing disabled 
		return (
				<div className={`navBarContainer ${popupClass}`}>
				<div className={popupClass}>
				{currentUser.id > 0 ? this.renderCurrentUserNav() : 
					<NavBar 
						currentUser={currentUser} 
						handleGoToLogin={this.handleGoToLogin}
						handleGoToSignup={this.handleGoToSignup}
					 />}
				</div>
			</div>
		);
	}
}


export default compose(
	withApollo,
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
	graphql(setNotificationsType, {name: 'setNotificationsType'}),
	graphql(getCurrentUser, {
		props: ({loading, data: { currentUser }}) => ({
			loading,
			currentUser
		})
	}), 
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	})
)(NavBarContainer);
