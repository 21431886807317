export const defaultState = {
	currentUser: {
		__typename: "currentUser",
		id: null,
		firstName: null,
		lastName: null,
		about: null,
		currentCity: null,
		birthday: null,
		publicity: "public",
		seenExplanation: false,
		seenSaveExplanation: false,
		seenLocExplanation: false, 
		seenProfExplanation: false,
		seenInstaConnection: false,
		locationViews: 0,
		pageViews: 0,
		pushToken: null,
		publicUrl: null, 
		locUploadNumber: 0,
    	attrUploadNumber: 0
	},  
	destinationSearch: {
		__typename: "destinationSearch",
		value: null, 
		type: 'locations', 
		place: null,
		attractionAndUp: false, 
		attractionTypes: [], 
		attractionContent: [], 
		attractionTags: [], 
		attractionSort: 'recommended',
		attractionSortDir: 'desc', 
		attractionStars: 0,
		locationSort: 'recommended', 
		locationSortDir: 'desc', 
		needsRefresh: false
	}, 
	userSearch: {
		__typename: 'userSearch', 
		value: null
	}, 
	searchView: {
		__typename: 'searchView', 
		view: 'destination'
	},
	screenView: {
		__typename: "screenView", 
		popup: false
	},
	explore: {
		__typename: "explore",
		locations: []
	},
	notificationsType: {
		__typename: "notificationsType", 
		type: "other"
	}, 
	messenger: {
		__typename: "messenger", 
		id: null
	}
};


