import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";


import initialPic from '../assets/globe.jpg'


const Connect = props => {
	const {
		acceptedContacts,
		connect,
		error, 
		following, 
		handleClose, 
		handleSubmit, 
		handleSelectFollow,
		mutationFunction, 
		suggestedUsers, 
	} = props

	console.log('suggestedUsers', suggestedUsers)

	return (
		<div className="connect initialScreen">
			<div className="initialScreenLeft">
					<div className="headerHolder initialHeaderHolder">
						<div className="mainHeader initialHeader">connect</div>
						<div className="headerUnderline initialHeaderUnderline"></div>
					</div>
					{acceptedContacts ? <div className="marginBottom formAdditionalText">Let's get this party started!</div> : 
					<div className="marginBottom formAdditionalText">You get the most out of Trvlpage by connecting with friends so consider adding your contacts but for now here's some top travlers!</div>}
									
					<Formik
			
				      onSubmit={( values, { setSubmitting }) => {
								handleSubmit(connect, setSubmitting)
							}}
							onKeyDown={(e) => {
				                if (e.key === 'Enter') {
				                  e.preventDefault()
				                }
				            }} 
				    >
				      {({ isSubmitting }) => (
				        <Form 
				        	className="form"
				        >
				        	<div>
				        		{suggestedUsers.map(user => {
				        			return <div>
				        				<div>{user.firstName} {user.lastName}</div>
					        			<input 
									 		type="checkbox" 
									 		value={user.id} 
									 		className="checkbox"
									 		checked={following.includes(user.id)}
									 		onChange={() => handleSelectFollow(user.id)}
									 	/>	
								 	</div>
				        		})}
				        	</div>
								
					       	<div className="formButtons">
										<button className="secondaryButton formButton" onClick={handleClose}>Later</button> 
										<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>Send</button>
									</div>
				           <div className="progressDots">
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot progressDotFilled"></div>
						         	<div className="progressDot"></div>
								  		
					      		</div>
				       	 
				        </Form>
				      )}
				    </Formik>
						
				</div>
			<div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="formPicture"/>
			</div>

		</div>

	);
};

export default Connect;