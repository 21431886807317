import React from "react";

import "../stylesheets/components/Pictures.css"

import Picture from "./Picture"

const Pictures = props => {
	const { 
		currentUser,
		handleShowPicture,
		handleShowAll,
		handleShowUpload,
		locationUserId, 
		pictures, 
		picturesViewable,
		type,
		view
	 } = props;
	 console.log('locationUserId', locationUserId)
	 const displayPics = (pictures.length && pictures[0] !== null) ? pictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []
	return (
		<div className="pictures">
			<div className="headerAndIcons">
				<div className="headerHolder fullHeaderHolder">
					<div className="subHeader">pictures</div>
					<div className="headerUnderline subHeaderUnderline"></div>
				</div>
				<div>
				{(view === 'attraction' || view === 'location') && currentUser.id === locationUserId && 
					<i className={'material-icons activeIcon iconMedium locJoyride3'} onClick={() => handleShowUpload()}>add_a_photo</i>}
				</div>
			</div>
			{picturesViewable ? <div>
				{displayPics.length ? 
					<div className="mainBackground picturesContent">
						
						<div className="picturesRow">{displayPics.map((picture, picIndex) => {
								return <Picture 
									key={picture.id}
									handleShowPicture={handleShowPicture}
									picture={picture}
									picIndex={picIndex}
									view={view}
								/>
							
						})}
						</div>
					 <div className="textLink" onClick={() => handleShowAll()}>View all</div>
					</div> 
					: <div>
						{view === 'overallAttraction'|| view === 'overallLocation' ? 
						<div className="mainBackground">{`No one has uploaded pictures for this ${type}`}</div>
						: <div className="mainBackground">{`This user has not uploaded any pictures for this ${type}.`}</div>}
					</div>}
				</div>
			: <div className="mainBackground">You need to be following this user to see their pictures.</div>}
			
		</div>
			
	);
};

export default Pictures;
