import React from "react";


import "../stylesheets/components/AttractionTags.css"
import { availableTags } from "../utilities"

const AttractionTags = props => {
	const { 
		searchTags,
		tags, 
		view
	} = props

	
	const getDisplayTags = (tags, searchTags) => {
		const displayTags = []
		if (searchTags){
			{searchTags.forEach(searchTag => {
				{tags.forEach(tag => {
					if (displayTags.length < 2 && tag.tag === searchTag){
						displayTags.push(tag)
					}
				})}
			})}
		} 
		tags.forEach(tag => {
			if (displayTags.length < 2 && !displayTags.includes(tag)){
				displayTags.push(tag)
			}
		})
		return displayTags
	}
	const displayTags = (tags && tags[0] !== null) ? 
		view === 'attraction' ? tags : getDisplayTags(tags, searchTags) 
		: []
	return (

		<div className={view === 'attraction' ? "allTags attractionTags" : "attractionTags"}>
			<div className="attractionTagsContent">
				{displayTags.map(tag => {
					return <div className="attractionTagSmall" key={tag.tag}>{availableTags[tag.tag]}</div>	
				})}
			</div>
			
			{(view !== 'attraction' && tags && tags.length > 2) && (tags.length - 2 === 1 ? <div className="tertiaryText fontXS">1 other tag</div> : <div className="tertiaryText fontXS">and {tags.length - 2} other tags</div>)}
		</div>
	);
};

export default AttractionTags;
