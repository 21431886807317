import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from "react-router-dom";


import { Button, Collapse, CardBody, Card } from 'reactstrap';

import initialPic from '../assets/globe.jpg'


const Signup = props => {
	const { 
		error,
		handleSubmit, 
		handleToggleGuidelines,
		initialValues, 
		signupSchema,
		stateError
	} = props;

	
	return (
		<div className="initialScreen signup">
			<div className="initialScreenLeft">
				<div className="headerHolder initialHeaderHolder">
					<div className="mainHeader initialHeader">email & password</div>
					<div className="headerUnderline initialHeaderUnderline"></div>
				</div>
				<Formik
			    	initialValues={initialValues}
					validationSchema={signupSchema}
			      	onSubmit={(values, { setSubmitting }) => {
			        	handleSubmit(values, setSubmitting)
			      	}}
			      	onKeyDown={(e) => {
		                if (e.key === 'Enter') {
		                  e.preventDefault()
		                }
		            }} 
			    >
			      {({ isSubmitting, values, setFieldValue }) => (
			        <Form className="form">
			       		
			          	<div className="formItem">
				          <label className="textTitle textTitleForm" htmlFor="email">email</label>
				          <Field className="formField" type="text" name="email" autoComplete="off"/>
				        </div>
				          <ErrorMessage className="formError" name="email" component="div" />
				        <div className="formItem">
				          <label className="textTitle textTitleForm" htmlFor="password">password</label>
				          <Field className="formField" type="password" name="password" autoComplete="off"/>
				        </div>
				          <ErrorMessage className="formError" name="password" component="div" />
				         <div className="formItem">
				          <label className="textTitle textTitleForm" htmlFor="retypePassword">retype password</label>
				          <Field className="formField" type="password" name="retypePassword" autoComplete="off"/>
				        </div>
				          <ErrorMessage className="formError" name="retypePassword" component="div" />
				          
							
					 	
				        <div className="guidelineSection">
				        {error && <div className="error">There was an error.</div>}
				        {stateError && <div className="error">{stateError}</div>}
						</div>
				     	<div className="formButtons">
				          <button className="primaryButton singleFormButton" type="submit" disabled={isSubmitting}>
				            Sign Up
				          </button>
				        </div>
				        <div className="progressDots">
				         	<div className="progressDot"></div>
				         	<div className="progressDot"></div>
				         	<div className="progressDot progressDotFilled"></div>
				         	<div className="progressDot"></div>
				         	<div className="progressDot"></div>
				         	<div className="progressDot"></div>
	   				  		
			      		  </div>

				        <Link className="textLink" to="/login">Login</Link>
			        </Form>
			      )}
			    </Formik>
			 </div>
			 <div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="signupPicture"/>
			</div>


		</div>
	);
};

export default Signup;
