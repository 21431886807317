import React from "react";

import Attraction from "../components/Attraction"

const SavedListView = props => {
	const {
		attractions,
		currentUser,
		filterTerm, 
		handleShowAddToTrip,
		handleSelectAttraction,
		handleSelectLocation,
		savedByLocation,
		view
	} = props
 

	const displayWording = {
				'ACTIVITY': "activities", 
				'LODGING': "lodging", 
				'RESTAURANT': 'food & drinks'
			}

	return (
		<div className="savedListView">
			{Object.keys(savedByLocation).length ? Object.keys(savedByLocation).map(locationName => {
				if (locationName.toLowerCase().includes(filterTerm.toLowerCase())) {
					return <div key={locationName}> 
						<div className="secondaryHeader textLink" onClick={() => handleSelectLocation(savedByLocation[locationName].placeId)}>{locationName}</div> 
						
					{Object.keys(savedByLocation[locationName]).map(type => {
						if (type !== 'placeId' && savedByLocation[locationName][type].length > 0){
							return (<div key={type}>
									<div className="headerHolder fullHeaderHolder  marginBottom">
										<div className="categoryHeader">{displayWording[type]}</div>
										<div className="headerUnderline categoryHeaderUnderline"></div>
									</div>
			 						{savedByLocation[locationName][type].map(attraction => {
			 							const number = view === "savedLocation" ? (attractions.indexOf(attraction) + 1).toString() + ". ": undefined;
				 						return <Attraction
											key={attraction.id}
											attraction={attraction}
											currentUser={currentUser}
											handleShowAddToTrip={handleShowAddToTrip}
											handleSelectAttraction={handleSelectAttraction}
											id={attraction.id}
											locationUserId={attraction.locationUserId}
											locationId={attraction.locationId}
											number={number}
											view='saved'
									 	/>	
									})}
								</div>)
							}
					})}
					</div>
				}
			}) : <div className="examplePicHolder">
			<div className="fontXL marginBottom">You have no saved reviews.</div>
			<div className="fontLarge">To save, click the bookmark icon on a review.</div>
				<img 
					className="examplePic"
				 	src={require('../assets/SaveExample.png')} 
				 	alt="saveExample"/>
			</div>}		
		</div>
	);
};

export default SavedListView;
