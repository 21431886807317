import React from "react";
import { compose, Query, graphql, withApollo } from "react-apollo";

import history from "../history"

import { getCurrentUser, getPopup } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import { GET_MY_TRIPS } from "../apollo/db/queries"

import CreateTripContainer from "./CreateTripContainer"
import MyTrips from "../components/MyTrips"

class TripsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state={ 
			showCreateTrip: false
	
		}

		this.handleGoToTrip = this.handleGoToTrip.bind(this)
		this.handleHideCreateTrip = this.handleHideCreateTrip.bind(this)
		this.handleShowCreateTrip = this.handleShowCreateTrip.bind(this)
	}



	async handleShowCreateTrip(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showCreateTrip: true})
	}

	async handleHideCreateTrip(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showCreateTrip: false})
	}


	handleGoToTrip(tripId){
		history.push(`/trip/${tripId}`)
	}

	
	renderCreateTrip(){
		return <CreateTripContainer 
			handleClose={this.handleHideCreateTrip}
		/>
	}

	renderMyTrips(){
		const { currentUser } = this.props
		return <Query query={GET_MY_TRIPS}>
			{({data, loading, error}) => {
				if (loading) return (<div>Loading...</div>)
				if (error) return (<div>Error</div>)
				//if (!data.myTrips.nodes.length) return (<div>You have no trips</div>)
				const myTrips = data.myTrips.nodes
				return <MyTrips 
					handleGoToTrip={this.handleGoToTrip}
					handleShowCreateTrip={this.handleShowCreateTrip}
					myTrips={myTrips}
				/>
			}}
		</Query>
	}

	

	render() {
		const { currentUser, screenView } = this.props
		const { showAddToTrip, showCreateTrip } = this.state
		 const popupClass = screenView.popup && "disabledBackground"

		return (
				<div className={`tripsContainer ${popupClass}`}>
			{currentUser.id > 0 ? <div className="TripsContainerContent">
					{showCreateTrip && this.renderCreateTrip()}	
				{this.renderMyTrips()}
				</div> : <div>Please login or create an account to view this section</div>}		
			</div>
		);
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}), 
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'})
)(TripsContainer);
