import React from "react";
import { compose, graphql, Query, Mutation } from "react-apollo";

import history from "../history"


import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import { GET_TRIP_LOCATION_TIP_EXISTS, GET_TRIP_LOCATION_TIPS } from "../apollo/db/queries"
import { DELETE_TRIP_LOCATION_TIP } from "../apollo/db/mutations"

import Deletion from "../components/Deletion"
import GeneralLocTips from "../components/GeneralLocTips";

class TripLocTipsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionId: null,
			isEditing: false, 
			showDelete: false, 
			showTooltip: false
		}

		this.handleDelete = this.handleDelete.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleToggleIsEditing = this.handleToggleIsEditing.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
	}

	handleToggleIsEditing(){
		const { isEditing } = this.state
		this.setState({isEditing: !isEditing})
	}

	handleToggleTooltip(){
		const { showTooltip } = this.state
		this.setState({showTooltip: !showTooltip})
	}

	async handleShowDelete(tipId){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: tipId})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null})
	}

	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		const { setPopup } = this.props
		try {
				await mutationFunction({
					variables: {
						input: {
							id: deletionId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showDelete: false, deletionId: null })
			} catch {
				console.log('error')
			}
	}

	renderDelete(){
		const { currentUser, tripLocId } = this.props
		const { deletionId } = this.state

		return <Mutation
     		mutation={DELETE_TRIP_LOCATION_TIP}
     		refetchQueries={[{query: GET_TRIP_LOCATION_TIPS, variables:{tripLocId: tripLocId}}, 
     		{query: GET_TRIP_LOCATION_TIP_EXISTS, variables: { tripLocId: tripLocId, tipId: deletionId }}]}
	    >
	      {(deleteTripLocationTip, { loading, error }) => {
	      	return  <div className="deletionTip">
	      		<Deletion 
	      		    error={error}
	      	 		mutationFunction={deleteTripLocationTip}
	      	 		handleDelete={this.handleDelete}
	      	 		handleHideDelete={this.handleHideDelete}
	      	 		type="tip"
	    		/>
	    	</div>

	      }}
		</Mutation>
	}

	renderTips(){
		const { currentUser, tripLocId, tripLocName } = this.props
		const { isEditing, showTooltip } = this.state

		return <Query 
			query={GET_TRIP_LOCATION_TIPS}
    		variables={{ tripLocId: tripLocId }}
		>
			{({data, loading, error}) => { 
				if (loading) { return <div>Loading...</div> }
				if (error) { return <div>Error</div> }

				const locationTips = data.tripLocationTips.nodes

				return <GeneralLocTips
					currentUser={currentUser}
					isEditing={isEditing}
					handleShowDelete={this.handleShowDelete}
					handleToggleIsEditing={this.handleToggleIsEditing}
					handleToggleTooltip={this.handleToggleTooltip}
					showTooltip={showTooltip}
					tips={locationTips}
					tripLocName={tripLocName}
					view="tripLocation"
				/>

			}}
		</Query>

	}

	render() {
		const { showDelete } = this.state
		return (
			<div className="tripLocTipsContainer">
				{this.renderTips()}
				{showDelete && this.renderDelete()}
			</div>
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
)(TripLocTipsContainer);
