import React from "react";

import "../stylesheets/components/SearchHeader.css"
import { availableTags } from "../utilities"

const SearchHeader = props => {
	const { 
		attrSort,
		attrTypes,
		destinationType,
		handleChangeSort,
		handleSelectAttrType,
		handleSetDestinationType,
		handleSetDestSearchValue,
		locSort, 
		placeValue
	} = props;

	const selectedToggle = type => type === destinationType && 'toggleSelected';


	return (
		<div className="searchHeader">
			<div className="toggleButtons"> 
				<div className={`toggleButton toggleLeft ${selectedToggle('locations')}`} onClick={() => handleSetDestinationType("locations")}>Location Pages</div>
				<div className={`toggleButton toggleRight ${selectedToggle('attractions')}`} onClick={() => handleSetDestinationType("attractions")}>Activities, Lodging, Food & Drinks</div>
			</div>
			<div className="searchHeaderMiddle">
				{destinationType === 'attractions' ? 
					<div className="keyWordAndSort">
						<div className="keyWordSection">
							<div className="textTitle textTitleForm">key word search</div>
								<input 
									 className="formField" 
									 onChange={e => handleSetDestSearchValue(e)}  
									 autoComplete="off"
									 value={placeValue}
								/>
						</div> 
						<div className="dropdownSection">
							<div className="textTitle">Sort By:</div>
							<select className="dropdownSelect" onChange={(e) => handleChangeSort('attrSort', e)}>
								<option value="recommended" selected={attrSort === 'recommended'}>Recommended</option>
								<option value="rating" selected={attrSort === 'rating'}>Rating</option>
							</select>
						</div>	
					</div> :
					<div>
						<div className="dropdownSection">
							<div className="textTitle">Sort By:</div>
							<select className="dropdownSelect" onChange={(e) => handleChangeSort('locSort', e)}>
								<option value="recommended" selected={locSort === 'recommended'}>Recommended</option>
								<option value="rating" selected={locSort === 'rating'}>Rating</option>
							</select>
						</div>	
						
					</div>}
			</div>
			{destinationType === 'attractions' && <div className="searchHeaderBottom">
				<div className="checkboxesAndFilters">
					<div className="checkboxesSection">
						<div className="checkboxSection">
							
							<i className="material-icons nonClickableIcon iconMedium">local_activity</i>

						 	<label for="ACTIVITY">Activities</label>
						 	<input 
						 		type="checkbox" 
						 		value="ACTIVITY" 
						 		className="checkboxLarge"
						 		checked={attrTypes.includes('ACTIVITY')}
						 		onChange={() => handleSelectAttrType("ACTIVITY")}
						 	/>
						</div>
						<div className="checkboxSection">
							<div className="icons">
								<i className="material-icons nonClickableIcon iconMedium">bar</i>

								<i className="material-icons nonClickableIcon iconMedium">restaurant</i>

							</div>
						 	<label for="RESTAURANT">Food & Drinks</label>
						 	<input 
						 		type="checkbox" 
						 		value="RESTAURANT" 
						 		className="checkboxLarge"
						 		checked={attrTypes.includes('RESTAURANT')}
						 		onChange={() => handleSelectAttrType("RESTAURANT")}
						 	/>	
						</div>
						<div className="checkboxSection">
							<i className="material-icons nonClickableIcon iconMedium">hotel</i>

						 	<label for="LODGING">Lodging</label>
						 	<input 
						 		type="checkbox" 
						 		value="LODGING" 
						 		className="checkboxLarge"
						 		checked={attrTypes.includes('LODGING')} 
						 		onChange={() => handleSelectAttrType("LODGING")}
						 	/>
						</div>
					</div>
				</div>
			</div>}
		</div>
	);
};

export default SearchHeader;
