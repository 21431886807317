import React from "react";
import { compose, Query, graphql, withApollo} from "react-apollo";
import { withLastLocation } from 'react-router-last-location';
import { analytics } from '../index.js';


import history from "../history"

import {
	GET_OVERALL_LOCATION_ATTRACTIONS,
	GET_OVERALL_LOCATION_ATTRACTIONS_ALL,
	GET_OVERALL_LOCATION_BY_PLACE_ID,
	GET_OVERALL_LOCATION_PICTURES,
	GET_OVERALL_LOCATION_PICTURES_ALL, 
	GET_OVERALL_LOCATION_TIPS,
	GET_OVERALL_LOCATION_TIPS_ALL,
	GET_OVERALL_LOCATION_VISITORS
} from "../apollo/db/queries"
import { getCurrentUser, getPopup } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";

import AllPicturesView from "../components/AllPicturesView"
import GroupedAttractionsList from "../components/GroupedAttractionsList"
import LocationTips from "../components/LocationTips"
import MapView from "../components/MapView"
import OverallLocationHeader from "../components/OverallLocationHeader"
import GeneralLocTips from "../components/GeneralLocTips"
import OverallMonthsVisited from "../components/OverallMonthsVisited"
import Pictures from "../components/Pictures"
import PictureViewContainer from "./PictureViewContainer"
import Visitors from "../components/Visitors"



class OverallLocationContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attractionPlaceId: null,
			attractionType: null,
			chosenType: 'ALL',
			collapse: false,
			currentUserId: null, 
			initialPicIndex: 0,
			locationPlaceId: null,
			overallView: 'following', 
			showAllPictures: false,
			showAllTips: false,
			showPicture: false,
			sortBy: 'ranked'

		}

		this.getDisplayAttractions = this.getDisplayAttractions.bind(this)
		this.handleBack = this.handleBack.bind(this)
		this.handleGoToLocation = this.handleGoToLocation.bind(this)
		this.handleHidePicture = this.handleHidePicture.bind(this)
		this.handleHideAllPictures = this.handleHideAllPictures.bind(this)
		this.handleSelectAttraction = this.handleSelectAttraction.bind(this)
		this.handleSelectPicture = this.handleSelectPicture.bind(this)
		this.handleShowAllPictures = this.handleShowAllPictures.bind(this)
		this.handleShowPicture = this.handleShowPicture.bind(this)
		this.handleToggleOverallView = this.handleToggleOverallView.bind(this)
		this.handleToggleType = this.handleToggleType.bind(this)
		this.handleToggleView = this.handleToggleView.bind(this)
		this.handleToggleAttraction = this.handleToggleAttraction.bind(this)
		this.handleToggleTips = this.handleToggleTips.bind(this)
	}


	async componentDidMount(){
		const { locationPlaceId } = this.state
		const { client, currentUser } = this.props
		const newLocationPlaceId = window.location.href.split('/').slice(-1)[0]
			if (currentUser.id){
						
				try {
					const res = await client.query({
						query: GET_OVERALL_LOCATION_VISITORS,
						variables: { locationPlaceId: newLocationPlaceId}
					})

					const visitors = res.data.overallLocationVisitors.nodes
					if (visitors.length){
						this.setState({
							currentUserId: currentUser.id,
							locationPlaceId: newLocationPlaceId
						})

					} else {
						this.setState({
							currentUserId: currentUser.id,
							locationPlaceId: newLocationPlaceId, 
							overallView: 'public'
						})
					}
					
				} catch {
					console.log('error')
				}
				
			} else {
				this.setState({
					locationPlaceId: newLocationPlaceId, 
					overallView: 'public'
				})
			}
		
	}							

	async componentDidUpdate(){
		const { client, currentUser } = this.props
		const { currentUserId, locationPlaceId, overallView } = this.state
		//if page is refreshed current user is not set on mount so need this to be able to display following view.
		//there is no way to change overall views without a mount
		if (overallView !== 'following' && currentUser.id && !currentUserId){		
			try {
				const res = await client.query({
					query: GET_OVERALL_LOCATION_VISITORS,
					variables: { locationPlaceId: locationPlaceId}
				})

				const visitors = res.data.overallLocationVisitors.nodes
				if (visitors.length){
					this.setState({
						currentUserId: currentUser.id,
						overallView: 'following'
					})
				}
				
			} catch {
				console.log('error')
			}
				
			
		} 	
	}
	
	handleSelectAttraction(attractionId){
		history.push(`/attraction/${attractionId}`)
	}

	handleGoToLocation(locationId){
		history.push(`/location/${locationId}`)
	}

	handleToggleTips(){
		const { showAllTips } = this.state
		console.log('toggling tips')
		this.setState({showAllTips: !showAllTips})
	}


	
	async handleShowPicture(initialPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: initialPicIndex})
	}

	async handleHidePicture(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showPicture: false, intialPicIndex: 0})
	}

	async handleShowAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAllPictures: true})
	}

	async handleHideAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAllPictures: false})
	}

	async handleSelectPicture(id, currentPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: currentPicIndex, showAllPictures: false})
	}


	handleToggleAttraction(collapsePlaceId, attractionType){
		if (collapsePlaceId){
			this.setState({collapsePlaceId: collapsePlaceId, attractionType: attractionType})
		} else {
			this.setState({collapsePlaceId: null, attractionType: null})
		}
	}

	handleBack(){
		history.goBack()
	}


	async handleToggleOverallView(view){
		await this.setState({overallView: view})
		if (process.env.NODE_ENV !== 'development'){
			await analytics.logEvent('change_overall_location_view', {
			 	view: view
			});
		}
	}

	handleToggleType(type){
		this.setState({chosenType: type})
	}

	handleToggleView(event){
		this.setState({sortBy: event.target.value})
	}

	handleSortAttractions(attractions){
		return attractions.slice().sort((a, b) => {
			return b.amount - a.amount;
		})
	}

	getDisplayAttractions(attractions){
		const { chosenType } = this.state
		const displayAttractions = []
		attractions.forEach(attraction => {
			if (chosenType === 'ALL' || attraction.attractionTypes.includes(chosenType)){
				/*plpgsql wont allow custom nested array data types so reviews cant have savedId. 
				This is a janky way to pull the savedId out of a saved attractions array */
				const attrReviews = []
				attraction.reviews && attraction.reviews.forEach(review => {
					const saved = attraction.savedAttractions && attraction.savedAttractions.find(savedAttr => savedAttr.id === review.id)
					const attrReview = Object.assign({}, review, {savedId: saved ? saved.savedId : null})
					attrReviews.push(attrReview)
				})
				const finalAttr = Object.assign({}, attraction)
				finalAttr.reviews = attrReviews
				displayAttractions.push(finalAttr)
			}
		})

		console.log('displayAttractions', displayAttractions)
		return displayAttractions
	}


	renderHeader() {
		const { locationPlaceId, overallView } = this.state
		const { currentUser, lastLocation } = this.props
		return <Query 
			query={GET_OVERALL_LOCATION_BY_PLACE_ID}
	    	variables= {{ locPlaceId: locationPlaceId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const name = data.overallLocationByPlaceId

				return <OverallLocationHeader 
					currentUser={currentUser}
					handleBack={this.handleBack}
					handleToggleOverallView={this.handleToggleOverallView}
					lastLocation={lastLocation}
					overallView={overallView}
					name={name}
				/>
				
			}}
		</Query>
	}

	renderPictures(){
		const { locationPlaceId, initialPicIndex, showAllPictures, showPicture } = this.state

		return <Query 
			query={GET_OVERALL_LOCATION_PICTURES}
	    	variables= {{ locPlaceId: locationPlaceId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const pictures = data.overallLocationPictures.nodes
				const displayPics = (pictures.length && pictures[0] !== null) ? pictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []

				return <div>
					{showPicture && <PictureViewContainer 
						 	handleClose={this.handleHidePicture}
						 	initialPicIndex={initialPicIndex}
							pictures={displayPics}
						/>}
					<Pictures 
						handleShowPicture={this.handleShowPicture}
						pictures={displayPics}
						picturesViewable={true}
						type="overall location"
						view='overallLocation'
						handleShowAll={this.handleShowAllPictures}
					/>
					{showAllPictures && <AllPicturesView
						pictures={displayPics}
						handleClose={this.handleHideAllPictures}
						handleSelectPicture={this.handleSelectPicture}
					/>}
				</div>
			}}
		</Query>
	}

	renderAllPictures(){
		const { locationPlaceId, initialPicIndex, showAllPictures, showPicture } = this.state
		const { currentUser } = this.props
		const currentUserId = currentUser.id ? currentUser.id : 0;
		return <Query 
			query={GET_OVERALL_LOCATION_PICTURES_ALL}
	    	variables= {{ locPlaceId: locationPlaceId, currentUserId: currentUserId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const pictures = data.overallLocationPicturesAll.nodes
				const displayPics = (pictures.length && pictures[0] !== null) ? pictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []

				return <div>
					{showPicture && <PictureViewContainer 
						 	handleClose={this.handleHidePicture}
						 	initialPicIndex={initialPicIndex}
							pictures={displayPics}
						/>}
					<Pictures 
						handleShowPicture={this.handleShowPicture}
						pictures={displayPics}
						picturesViewable={true}
						type="overall location"
						view={'overallLocation'}
						handleShowAll={this.handleShowAllPictures}
					/>

					{showAllPictures && <AllPicturesView
						pictures={displayPics}
						handleClose={this.handleHideAllPictures}
						handleSelectPicture={this.handleSelectPicture}
					/>}
				</div>
			}}
		</Query>
	}


	renderVisitors(){
		const { locationPlaceId } = this.state
		return <Query 
			query={GET_OVERALL_LOCATION_VISITORS}
	    	variables= {{ locationPlaceId: locationPlaceId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const visitors = data.overallLocationVisitors.nodes
			
				return (<div>
					<Visitors 
						otherVisitors={visitors}
						view="overallLocation"
					/>
					{/*this.renderMonths(visitors)*/}
				</div>)
			
			}}
		</Query>
	}

	renderMonths(visitors){
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		const unknownMonthVisitors = []
		const visitorsInMonths = {}
		months.forEach((month, monthIndex) => {
			let monthVisitors = []
			visitors.forEach(visitor => {
				if (visitor.dates && visitor.dates.length && visitor.dates[0] !== null){
					if (visitor.dates.some(date => {
						//this needs to be cleaned up to account for year changes
						return ((date.startDate && new Date(date.startDate).getMonth() === monthIndex) || 
							(date.endDate && new Date(date.endDate).getMonth() === monthIndex) ||
							(date.startDate && date.endDate && monthIndex > new Date(date.startDate).getMonth() && monthIndex < new Date(date.endDate).getMonth()) 
							//||
							//(date.startDate && date.endDate && new Date(date.startDate).getYear() < new Date(date.endDate).getYear())
							) 

					})){
			
						monthVisitors.push(visitor)
					}
				} else if (month === 'January'){
					unknownMonthVisitors.push(visitor)
				}
	
			})
			 visitorsInMonths[month] = monthVisitors
		})
		return <OverallMonthsVisited 
			unknownMonthVisitors={unknownMonthVisitors}
			visitorsInMonths={visitorsInMonths}
		/>

	}

	renderTips(){
		const { currentUser } = this.props
		const { locationPlaceId, showAllTips } = this.state
		return <Query 
			query={GET_OVERALL_LOCATION_TIPS}
	    	variables= {{ locationPlaceId: locationPlaceId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const locationTips = data.overallLocationTips.nodes


				return <GeneralLocTips 
					currentUser={currentUser}
					handleToggleTips={this.handleToggleTips}
					showAllTips={showAllTips}
					tips={locationTips}
					view="overallLocation"
				/>
			}}
		</Query>
	}

	renderAllTips(){
		const { currentUser } = this.props
		const { locationPlaceId, showAllTips } = this.state
		const currentUserId = currentUser.id ? currentUser.id : 0;
		return <Query 
			query={GET_OVERALL_LOCATION_TIPS_ALL}
	    	variables= {{ locationPlaceId: locationPlaceId, currentUserId: currentUserId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const locationTips = data.overallLocationTipsAll.nodes

				return <GeneralLocTips 
					currentUser={currentUser}
					handleToggleTips={this.handleToggleTips}
					showAllTips={showAllTips}
					tips={locationTips}
					view="overallLocation"

				/>
			}}
		</Query>
	}



	renderAttractions(){
		const { 
			sortBy, 
			attractionType,
			collapsePlaceId,
			chosenType, 
			locationPlaceId
		} = this.state
		const { currentUser } = this.props
		return <Query 
			query={GET_OVERALL_LOCATION_ATTRACTIONS}
	    	variables= {{ locPlaceId: locationPlaceId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				let attractions = data.overallLocationAttractions.nodes

				if (sortBy === 'visited'){
					attractions = this.handleSortAttractions(attractions)
				}
				
					
				return (
					<div className="overallLocContent">
						<div className="overallLocLeft">
							<GroupedAttractionsList 
								attractions={this.getDisplayAttractions(attractions)}
								chosenType={chosenType}
								collapsePlaceId={collapsePlaceId}
								currentUser={currentUser}
								handleSelectAttraction={this.handleSelectAttraction}
								handleToggleType={this.handleToggleType}
								handleToggleView={this.handleToggleView}
								handleToggleAttraction={this.handleToggleAttraction}
								sortBy={sortBy}
								view="overallLocation"
							/>
							{this.renderTips()}
						</div>
					<div className="overallLocRight">
						{!!attractions.length && 
							<MapView 
								centerLat={attractions[0].lat}
								centerLng={attractions[0].lng}
								handleSelectPin={() => console.log('this will do something')}
								pins={this.getDisplayAttractions(attractions)}
								view="overallLocation"
								zoom={11}
							/>
						}
						{this.renderVisitors()}
					</div>
					
				</div>)
					
			}}
		</Query>
	}

	renderAllAttractions(){
		const { 
			sortBy, 
			collapsePlaceId,
			attractionType,
			chosenType, 
			locationPlaceId
		} = this.state
		const { currentUser } = this.props
		const currentUserId = currentUser.id ? currentUser.id : 0;
		return <Query 
			query={GET_OVERALL_LOCATION_ATTRACTIONS_ALL}
	    	variables= {{ locPlaceId: locationPlaceId, currentUserId: currentUserId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				let attractions = data.overallLocationAttractionsAll.nodes

				if (sortBy === 'visited'){
					attractions = this.handleSortAttractions(attractions)
				}
				
					
					return (
						<div className="overallLocContent">
							<div className="overallLocLeft">
								<GroupedAttractionsList 
									attractions={this.getDisplayAttractions(attractions)}
									chosenType={chosenType}
									collapsePlaceId={collapsePlaceId}
									currentUser={currentUser}
									handleSelectAttraction={this.handleSelectAttraction}
									handleToggleType={this.handleToggleType}
									handleToggleView={this.handleToggleView}
									handleToggleAttraction={this.handleToggleAttraction}
									sortBy={sortBy}
									view="overallLocation"
								/>
								{this.renderAllTips()}
							</div>
						<div className="overallLocRight">
						{!!attractions.length && 
							<MapView 
								centerLat={attractions[0].lat}
								centerLng={attractions[0].lng}
								handleSelectPin={() => console.log('this will do something')}
								pins={this.getDisplayAttractions(attractions)}
								view="overallLocation"
								zoom={11}
							/>
						}
						
					</div>
								
				</div>)
						
					
			}}
		</Query>
	}


	render() {
		const { currentUser, screenView } = this.props
		const { overallView, locationPlaceId } = this.state
		const popupClass = screenView.popup && "disabledBackground"
		return (
				<div className={`overallLocationContainer ${popupClass}`}>
				{locationPlaceId ? <div>
					{this.renderHeader()}
					{overallView === 'following' ? this.renderPictures() : this.renderAllPictures()}
					{overallView === 'following' ? this.renderAttractions() : this.renderAllAttractions()}
					
				</div> : 
				<div>Loading...</div>}
				
				
			</div>
			
		);
	}
}

export default compose(
	withApollo,
	withLastLocation,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}), 
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'})
)(OverallLocationContainer);
