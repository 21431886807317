import React from "react";
import { compose, graphql, Query } from "react-apollo";

import { GET_OVERALL_LOCATION, GET_LOCATION_SAVED_ATTRACTIONS } from "../apollo/db/queries"
import { getCurrentUser } from "../apollo/state/queries"

import history from "../history"

import GroupedAttractionsList from "../components/GroupedAttractionsList"
import MapView from "../components/MapView"
import SavedLocationHeader from "../components/SavedLocationHeader"

class SavedLocationContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsePlaceId: null,
			attractionType: null,
			chosenType: 'ALL', 
			locationPlaceId: null
		}

		this.getDisplayAttractions = this.getDisplayAttractions.bind(this)
		this.handleToggleType = this.handleToggleType.bind(this)
		this.handleToggleAttraction = this.handleToggleAttraction.bind(this)
		this.handleSelectAttraction = this.handleSelectAttraction.bind(this)
		this.handleSelectPin = this.handleSelectPin.bind(this)
		this.renderAttractions = this.renderAttractions.bind(this)

	}

	async componentDidMount(){
		const { locationPlaceId } = this.state
		const newLocationPlaceId = window.location.href.split('/').slice(-1)[0]
		if (locationPlaceId !== newLocationPlaceId){
			this.setState({locationPlaceId: newLocationPlaceId})

		}
	}

	getDisplayAttractions(attractions){
		const { chosenType } = this.state
		return attractions.filter(attraction => {
			 return (chosenType === "ALL" || attraction.attractionTypes.includes(chosenType))			
		})
	}


	handleToggleType(type){
		this.setState({chosenType: type})
	}

	handleToggleAttraction(collapsePlaceId, attractionType){
		if (collapsePlaceId){
			this.setState({collapsePlaceId: collapsePlaceId, attractionType: attractionType})
		} else {
			this.setState({collapsePlaceId: null, attractionType: null})
		}
	}

	handleSelectPin(pin){
		history.push(`/attractionView/${pin.placeId}`)
	}

	handleSelectAttraction(attractionId){
		history.push(`/attraction/${attractionId}`)
	}

	renderHeader(location){
		return <SavedLocationHeader 
			location={location}
		/>
	}

	renderAttractions(){
		const { currentUser } = this.props

		const { 
			collapsePlaceId,
			attractionType,
			chosenType, 
			locationPlaceId 
		} = this.state

		return <Query query={GET_OVERALL_LOCATION} variables={{locationPlaceId: locationPlaceId}}>
					{({data, loading, error}) => {
						if (loading) return (<div>Loading...</div>)
						if (error) return (<div>Error</div>)
						const location = data.overallLocation 
						return <Query query={GET_LOCATION_SAVED_ATTRACTIONS} variables={{locPlaceId: locationPlaceId}}>
							{({data, loading, error}) => {
								if (loading) return (<div>Loading...</div>)
								if (error) return (<div>Error</div>)
								const attractions = data.locationSavedAttractions.nodes

								return <div>
									{this.renderHeader(location)}
									<div className="savedLocationContent">
										<div className="savedLocationLeft">
											<GroupedAttractionsList
												attractions={this.getDisplayAttractions(attractions)}
												collapsePlaceId={collapsePlaceId}
												chosenType={chosenType}
												currentUser={currentUser}
												handleSelectAttraction={this.handleSelectAttraction}
												handleToggleAttraction={this.handleToggleAttraction}
												handleToggleType={this.handleToggleType}
												location={location}
												view="savedLocation"
											/>
										</div>
										<div className="savedLocationRight">
											<MapView 
												centerLat={Number(location.lat)}
												centerLng={Number(location.lng)}
												handleSelectPin={this.handleSelectPin}
												pins={this.getDisplayAttractions(attractions)}
												view="savedLocation"
												zoom={11}

											/>
										</div>
									</div>
								</div>
							}}
						</Query>	
					}}
				</Query>
	}


	render() {
		const { currentUser } = this.props

		const { 
			locationPlaceId 
		} = this.state
		return (
			<div>
			{locationPlaceId && (currentUser.id > 0 ? 
				<div className="savedLocationContainer">
					{this.renderAttractions()}
				</div>

			: <div> Please login or create an account to see this page</div>)}
			
		</div>);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}), )(SavedLocationContainer);
