import React from "react";

import "../stylesheets/components/AllMessagesHeader.css"

const AllMessagesHeader = props => {
	const { 
		handleGoToMessages, 
		handleShowCreateMessage,
		view
	} = props
	return (
		<div className="allMessagesHeader">
			{view !== 'navBar' ? <div className="allMessagesHeaderMain">
				<div className="headerHolder fullHeaderHolder">
					<div className="mainHeader">messages</div>
					<div className="headerUnderline mainHeaderUnderline"></div>
				</div>
				<button className="primaryButton" onClick={() => handleShowCreateMessage()}>Create Message</button>
			</div> :
			<div className="textLink" onClick={() => handleGoToMessages()}>View Messages</div>}
			

		</div>
	);
};

export default AllMessagesHeader;
