import React from "react";
import { compose, graphql, withApollo, Query } from "react-apollo";
import { withLastLocation } from 'react-router-last-location';

import { getCurrentUser } from "../apollo/state/queries";

import history from "../history"


import ItineraryContainer from "./ItineraryContainer"
import TripItineraryHeader from "../components/TripItineraryHeader"

import { 
	GET_TRIP_BY_ID, 
	GET_TRIP_GENERAL_LOCATIONS,
	GET_TRIP_USERS
} from "../apollo/db/queries"

class TripItineraryContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			exportItinerary: false, 
			isViewable: false,
			tripId: null
		};

		this.handleExportItinerary = this.handleExportItinerary.bind(this)
		this.handleGoToTrip = this.handleGoToTrip.bind(this)
		this.handleHideExportItinerary = this.handleHideExportItinerary.bind(this)
		this.handleBack = this.handleBack.bind(this)
	}

	async componentDidMount(){
		const { client, currentUser } = this.props
		const { isViewable } = this.state
		const tripId = Number(window.location.href.split('/').slice(-1))

		const tripRes = await client.query({
			query: GET_TRIP_BY_ID, 
			variables: { id: tripId }
		})

		const trip = tripRes.data.tripById

		await this.setState({voteNameViewable: !trip.voterAnonymity})

		if (currentUser.id && !isViewable){
			const res =  await client.query({
	  	  		   	 	query: GET_TRIP_USERS,
	  	  	    		variables: { viewedTripId: tripId }
	  	  	 })

			const tripUsers = res.data.tripUsers.nodes

			const viewable = tripUsers.some(user => user.userId === currentUser.id)
			if (viewable){
				this.setState({isViewable: true, tripId: tripId})
			}
		} 
	}

	async componentDidUpdate(){
		//this is needed if currentUser has yet to be set on state from Main.js request
		const { client, currentUser } = this.props
		const { isViewable } = this.state
		const tripId = Number(window.location.href.split('/').slice(-1))

		if (currentUser.id && !isViewable){
			const res =  await client.query({
	  	  		   	 	query: GET_TRIP_USERS,
	  	  	    		variables: { viewedTripId: tripId }
	  	  	 })

			const tripUsers = res.data.tripUsers.nodes
			
			const viewable = tripUsers.some(user => user.userId === currentUser.id)
			if (viewable){
				this.setState({isViewable: true, tripId: tripId})
			}
		}
	}

	handleGoToTrip(){
		const { tripId } = this.state
		history.push(`/trip/${tripId}`)
	}

	handleBack(){
		history.goBack()
	}

	handleExportItinerary(){
		this.setState({exportItinerary: true})
	}

	handleHideExportItinerary(){
		this.setState({exportItinerary: false})
	}


	renderHeader(){
		const { lastLocation } = this.props
		return <TripItineraryHeader 
			handleBack={this.handleBack}
			handleExportItinerary={this.handleExportItinerary}
			handleGoToTrip={this.handleGoToTrip}
			lastLocation={lastLocation}
		/>
	}

	renderTripItinerary(){
		const { exportItinerary, tripId } = this.state
		return <Query 
			query={GET_TRIP_BY_ID}
	    	variables= {{ id: tripId}}
		>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const trip = data.tripById

				return <Query 
					query={GET_TRIP_GENERAL_LOCATIONS}
			    	variables= {{ viewedTripId: tripId}}
				>
					{({data, loading, error}) => {
						if(loading) {return <div>Loading..</div>}
						if(error) {return <div>Error</div>}

						const tripLocations = data.tripGeneralLocations.nodes
			

						return <ItineraryContainer 
							exportItinerary={exportItinerary}
							handleHideExportItinerary={this.handleHideExportItinerary}
							trip={trip}
							tripId={tripId}
							tripLocations={tripLocations}
							view='tripItinerary'
						/>
					}}
				</Query>

			}}
		</Query>

		
	}	

	render() {
		const { isViewable } = this.state
		return (<div>
			{isViewable ? 
				<div className="tripItineraryContainer">
					{this.renderHeader()}
					{this.renderTripItinerary()}
				</div> : 
			<div>You must be a part of this trip to view the itinerary</div>
		}

		</div>)
	}
}


export default compose(
	withApollo,
	withLastLocation,
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(TripItineraryContainer);
