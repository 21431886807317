import React from "react";
import { compose, graphql, Query, Mutation } from "react-apollo";

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import { 
	GET_TRIP_GENERAL_ATTRACTIONS,
	GET_TRIP_GENERAL_LOCATIONS, 
	GET_TRIP_ITINERARY_ITEMS, 
	GET_TRIP_LOCATION_AND_DATES,
	GET_TRIP_LOCATION_COMMENTS,
	GET_TRIP_LOCATION_TIPS 
} from "../apollo/db/queries"
import {  
	CREATE_TRIP_GENERAL_LOCATION, 
	DELETE_TRIP_GENERAL_LOCATION, 
	GROUP_TRIP_LOCATIONS
} from "../apollo/db/mutations"

import Autocomplete from "../components/Autocomplete"
import Deletion from "../components/Deletion"
import GroupTripLocations from "../components/GroupTripLocations"
import MapView from "../components/MapView"
import TripLocations from "../components/TripLocations"

class TripLocationsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionId: null,
			deletionName: null,
			isEditing: false,
			selectedLocations: [],
			mainLocationId: null,
			showAdd: false, 
			showDelete: false,
			showGroupLocations: false,
			showTooltip: false, 
			stateError: null, 
			tooltipType: null,
			value: '' 

		}

		this.handleChange = this.handleChange.bind(this)
		this.handleChangeMainLocation = this.handleChangeMainLocation.bind(this)
		this.handleCreateTripLocation = this.handleCreateTripLocation.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleGroupLocations = this.handleGroupLocations.bind(this)
		this.handleGoToLocation = this.handleGoToLocation.bind(this)
		this.handleHideAdd = this.handleHideAdd.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideGroupLocations = this.handleHideGroupLocations.bind(this)
		this.handleShowAdd = this.handleShowAdd.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowGroupLocations = this.handleShowGroupLocations.bind(this)
		this.handleToggleGroupLocation = this.handleToggleGroupLocation.bind(this)
		this.handleToggleIsEditing = this.handleToggleIsEditing.bind(this)
		this.handleToggleTooltip = this.handleToggleTooltip.bind(this)
		this.renderLocations = this.renderLocations.bind(this)
	}

	async handleShowDelete(tripLocation){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: tripLocation.id, deletionName: this.getLocName(tripLocation)})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null, deletionName: null})
	}

	async handleShowAdd(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAdd: true})
	}

	async handleHideAdd(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAdd: false, value: ''})
	}

	handleToggleIsEditing(){
		const { isEditing } = this.state
		this.setState({isEditing: !isEditing})
	}

	handleChange(event){
		this.setState({value: event.target.value, stateError: null})
	}

	handleGoToLocation(locationId){
		history.push(`/tripLocation/${locationId}`)
	}

	async handleShowGroupLocations(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showGroupLocations: true})
	}

	async handleHideGroupLocations(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showGroupLocations: false, selectedLocations: [], stateError: null})
	}

	handleChangeMainLocation(locationId){
		this.setState({mainLocationId: locationId})
	}

	handleToggleTooltip(type){
		const { showTooltip } = this.state
		if (showTooltip) {
			this.setState({showTooltip: false, tooltipType: null})
		} else {
			this.setState({showTooltip: true, tooltipType: type})
		}
	}


	async handleToggleGroupLocation(locationId){
		const { selectedLocations } = this.state
		let newLocations;
		if (selectedLocations.includes(locationId)){
			newLocations = selectedLocations.filter(locId => {
				return locId !== locationId
			})
			this.setState({selectedLocations: newLocations,})
		} else {
			newLocations = [...selectedLocations, locationId]
			if (newLocations.length === 1){
				await this.setState({mainLocationId: locationId})
			}
			this.setState({selectedLocations: newLocations, stateError: null})
		}
	}

	async handleGroupLocations(event, groupTripLocations){
		const { mainLocationId, selectedLocations } = this.state
		const { setPopup } = this.props
		event.preventDefault()
		if (selectedLocations.length < 2){
			this.setState({stateError: 'Please select at least two locations to group'})
		} else {
			try {
				await groupTripLocations({
					variables: {
						input: {
							groupedTripLocIds: selectedLocations, 
							mainTripLocId: mainLocationId
							}
						}
					})

				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showGroupLocations: false, selectedLocations: [] })
			} catch {
				console.log('error')
			}
		}
		
	}


	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		const { setPopup } = this.props
		try {
			await mutationFunction({
			variables: {
				input: {
					id: deletionId
					}
				}
			})

			await setPopup({
				variables: {
					popup: false
				}
			})
				this.setState({ showDelete: false, deletionId: null, deletionName: null })
			} catch {
				console.log('error')
			}
	}


	async handleCreateTripLocation(event, createTripGeneralLocation, selected, locations){
		const { setPopup, tripId } = this.props

		event.preventDefault();

		if (!selected || !selected.id) {
			this.setState({stateError: 'Please select a location'})
			return null;
		}


		

		const placeIds = locations.map(location => {
			return location.placeId
		})
		if (!placeIds.includes(selected.place_id)){
			const localityObj = selected.address_components.find(component => {
				return component.types.includes('locality')
			})
			const locality = localityObj ? localityObj.long_name : null
			const adminLevel1Obj = selected.address_components.find(component => {
				return component.types.includes('administrative_area_level_1')
			})
			const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
			const countryObj = selected.address_components.find(component => {
				return component.types.includes('country')
			})
			const country = countryObj.long_name

			try {
			 await createTripGeneralLocation({
				variables: {
					input: {
						tripGeneralLocation:  {
							tripId: tripId,
							fullName: selected.formatted_address,
							locality: locality, 
							adminLevel1: adminLevel1, 
							country: country,
							placeId: selected.place_id,
							lat: selected.geometry.location.lat(),
							lng: selected.geometry.location.lng()

						  }
						}	
					}
				})

			await setPopup({
				variables: {
					popup: false
				}
			})

			 this.setState({showAdd: false, value: ''})
	
			} catch {
				console.log('error')
			}
		} else {
			this.setState({stateError: "This location has already been added"})
		}
	}

	sortLocations(locations){

		const locWithDates = locations.filter(result => result.dates && result.dates[0] !== null)
		const locWithoutDates = locations.filter(result => !result.dates || result.dates[0] === null)

			const newArr = locWithDates.slice().sort((resultA, resultB) => {
				if (resultA.dates[0].startDate && resultB.dates[0].startDate){
					return new Date(resultA.dates[0].startDate) - new Date(resultB.dates[0].startDate)
				} else if (!resultA.dates[0].startDate && !resultB.dates[0].startDate) {
					return new Date(resultA.dates[0].endDate) - new Date(resultB.dates[0].endDate)
				} else if (!resultB.dates[0].startDate && resultA.dates[0].endDate) {
					return new Date(resultA.dates[0].endDate) - new Date(resultB.dates[0].endDate)
				} else if (resultB.dates[0].endDate && !resultA.dates[0].startDate) {
					return new Date(resultA.dates[0].endDate) - new Date(resultB.dates[0].endDate)
				} else if (!resultB.dates[0].endDate && !resultA.dates[0].startDate) {
					return new Date(resultA.dates[0].endDate) - new Date(resultB.dates[0].startDate)
				} else {
					return new Date(resultA.dates[0].startDate) - new Date(resultB.dates[0].endDate)
				}
			}).concat(locWithoutDates)
			return newArr

	}

	getLocName(location){
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName.toUpperCase()
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName.toUpperCase()
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName.toUpperCase()
		} else {
			return location.country.toUpperCase()
		}
	}


	renderDeleteLocation(){
		const { tripId } = this.props
		const { deletionName } = this.state
		return <Mutation
     		 mutation={DELETE_TRIP_GENERAL_LOCATION}
     		 refetchQueries={[{query: GET_TRIP_GENERAL_LOCATIONS, variables:{viewedTripId: tripId}}]}
	    >
	      	{(deleteTripGeneralLocation, { loading, error }) => {
	      		return <div className="deletionLocation">
	  				<Deletion 
	  				    error={error}
						handleHideDelete={this.handleHideDelete}
						handleDelete={this.handleDelete}
						mutationFunction={deleteTripGeneralLocation}
						name={deletionName}
						type="trip location"
					/>
				</div>
	      	}}
	    </Mutation>
	}

	renderAddTripLocation(locations){
		const { newLocation, stateError, value } = this.state
		const { tripId } = this.props
		const addButtonDisabled = newLocation ? false : true
		return <Mutation
     		 mutation={CREATE_TRIP_GENERAL_LOCATION}
     		 refetchQueries={[{query: GET_TRIP_GENERAL_LOCATIONS, variables:{viewedTripId: tripId}}]}
	    >
	      	{(createTripGeneralLocation, { loading, error }) => {
	      		return <div className="addTripLocation popup popupMedium">
	      		 <Autocomplete 
	      		 	cancelText='Cancel'
	      		 	error={error}
	      		 	mutationFunction={createTripGeneralLocation}
					handleChange={this.handleChange}
					handleSubmit={this.handleCreateTripLocation}
					handleClose={this.handleHideAdd}
					items={locations}
					name='Add Trip Location'
					popup={true}
					stateError={stateError}
					type="tripLocation"
					value={value}
				/>
				</div>
	      	}}  
	    </Mutation>
	}

	renderGroupLocations(locations){
		const { mainLocationId, selectedLocations, showTooltip, stateError, tooltipType } = this.state
		const { tripId } = this.props

		return <Mutation
     		 mutation={GROUP_TRIP_LOCATIONS}
     		 refetchQueries={[{query: GET_TRIP_GENERAL_LOCATIONS, variables:{viewedTripId: tripId}},
     		 {query: GET_TRIP_LOCATION_COMMENTS, variables:{tripLocId: mainLocationId}}, 
     		 {query: GET_TRIP_ITINERARY_ITEMS, variables:{tripLocId: mainLocationId}}, 
     		 {query: GET_TRIP_LOCATION_TIPS, variables:{tripLocId: mainLocationId}}, 
     		 {query: GET_TRIP_GENERAL_ATTRACTIONS, variables:{tripLocId: mainLocationId}},
     		 {query : GET_TRIP_LOCATION_AND_DATES, variables: {tripLocId: mainLocationId}}
     		 ]}
	    >
	      	{(groupTripLocations, { loading, error }) => {
	      		return <GroupTripLocations 
	      		    error={error}
	      			groupTripLocations={groupTripLocations}
					handleClose={this.handleHideGroupLocations}
					handleChangeMainLocation={this.handleChangeMainLocation}
					handleGroupLocations={this.handleGroupLocations}
					handleToggleGroupLocation={this.handleToggleGroupLocation}
					handleToggleTooltip={this.handleToggleTooltip}
					locations={locations}
					mainLocationId={mainLocationId}
					selectedLocations={selectedLocations}
					showTooltip={showTooltip}
					stateError={stateError}
					tooltipType={tooltipType}
				/>
	      	}}
	    </Mutation>
		
	}


	renderLocations (){
		const { deletionId, isEditing, showAdd, showGroupLocations } = this.state
		const { tripId } = this.props

		return <Query 
			query={GET_TRIP_GENERAL_LOCATIONS}
	    	variables={{ viewedTripId: tripId}}
			>
			{({data, loading, error}) => {
				if(loading) {return <div>Loading..</div>}
				if(error) {return <div>Error</div>}
				const locations = data.tripGeneralLocations.nodes
				let locLat, locLng;

				//to center map around single pin if only one exists 
				if (locations.length === 1){
					locLat = locations[0].lat
					locLng = locations[0].lng
				}
      	
	      		return <div className="tripLocationsContent">
	      		
				 	{showAdd && this.renderAddTripLocation(locations)}
				 	{showGroupLocations && this.renderGroupLocations(locations)}
					<div className="tripLocationsLeft">
						<TripLocations 
							isEditing={isEditing}
							handleGoToLocation={this.handleGoToLocation}
							handleShowAddLocation={this.handleShowAdd}
							handleShowDelete={this.handleShowDelete}
							handleShowGroupLocations={this.handleShowGroupLocations}
							handleToggleIsEditing={this.handleToggleIsEditing}
							locations={this.sortLocations(locations)}
							tripId={tripId}

						/>
					</div>
					<div className="tripLocationsRight">
						<MapView 
							centerLat={locLat}
							centerLng={locLng}
							pins={locations}
							view="trip"
							zoom={locLat ? 4 : 1} />
					</div>
				</div>
					
			
			}}
		</Query>
	}

	render() {
		const { showDelete } = this.state 
		return (
			<div className="tripLocationsContainer">
				{showDelete  && this.renderDeleteLocation()}
				
				{this.renderLocations()}
			</div>
		);
	}
}

export default compose(

	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
	)(TripLocationsContainer);
