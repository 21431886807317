import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "../stylesheets/components/CommentForm.css"

const CommentForm = props => {
	const {
		handleCancel,
		handleSubmit,
		isEditing,
		initialValues, 
		mutationFunction,
		validationSchema, 
		placeholder
	} = props

	const placeholderText = placeholder ? placeholder : "Add a comment..."
	return (
		<div className="CommentForm">
			<div>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						handleSubmit(
							values,
							mutationFunction, 
							resetForm, 
							setSubmitting	
						);
					}}
				>
					{({ isSubmitting }) =>
						<Form>
							<div>
									<Field className="formField" component="textarea" name="comment" placeholder={placeholderText} autoComplete="off" required />
									<ErrorMessage className="formError" name="comment" component="div" />
								</div>
							{isEditing ? 
								<div className="formCommentEditButtons">
									<button className="primaryButton" onClick={() => handleCancel()}>
										Cancel
									</button>
									<button className="primaryButton" type="submit" disabled={isSubmitting}>
										Save Changes
									</button>
								</div> :
								<div className="formCommentAddButtons">
								<button className="primaryButton" type="submit" disabled={isSubmitting}>
									Add Comment
								</button>
							</div>}

						</Form>}
				</Formik>
			</div>


		</div>
	);
};

export default CommentForm;
