import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { Mutation, Query, compose, graphql } from "react-apollo";

import history from "../history";

import Attractions from "../components/Attractions"
import AttractionDetailsContainer from "./AttractionDetailsContainer"
import Deletion from "../components/Deletion"
import Visitors from "../components/Visitors"

import { getCurrentUser } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import { DELETE_ATTRACTION, UPDATE_ATTRACTION } from "../apollo/db/mutations"
import { GET_LOCATION_ATTRACTIONS } from "../apollo/db/queries"


class AttractionsContainer extends React.Component {
	constructor() {
		super();
		this.state = {
		      attractionId: null, 
		      deletionName: null,
		      editAttraction: null,
		      error: null, 
		      loading: false,
		      otherVisitors: null, 
		      showDelete: false, 
		      showEdit: false, 
		    };
		      

    		this.handleDeleteAttraction = this.handleDeleteAttraction.bind(this)
    		this.handleHideDelete = this.handleHideDelete.bind(this)
    		this.handleHideEdit = this.handleHideEdit.bind(this)
    		this.handleHideOtherVisitors = this.handleHideOtherVisitors.bind(this)
    		this.handleSelectPin = this.handleSelectPin.bind(this)
    		this.handleShowDelete = this.handleShowDelete.bind(this)
    		this.handleShowEdit = this.handleShowEdit.bind(this)
    		this.handleShowOtherVisitors = this.handleShowOtherVisitors.bind(this)
	}

	
	async handleShowDelete(attraction){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, attractionId: attraction.id, deletionName: attraction.name})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, attractionId: null, deletionName: null})
	}

	


	async handleShowOtherVisitors(otherVisitors){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showOtherVisitors: true, otherVisitors: otherVisitors})
	}

	async handleHideOtherVisitors(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showOtherVisitors: false, otherVisitors: null})
	}

	async handleShowEdit(attraction){
		const { setPopup } = this.props

		await setPopup({
					variables: {
						popup: true
					}
				})


		this.setState({showEdit: true, editAttraction: attraction})
	}

	async handleHideEdit(){
		const { setPopup } = this.props

		await setPopup({
					variables: {
						popup: false
					}
				})

		this.setState({showEdit: false, editAttraction: null})
	}

	

	async handleDeleteAttraction(deleteAttraction){
		const { setPopup } = this.props
		const { attractionId } = this.state
		try {
				await deleteAttraction({
					variables: {
						input: {
							id: attractionId
							}
						}
					})
				
				await setPopup({
					variables: {
						popup: false
					}
				})

				this.setState({ loading: false, showDelete: false })
			} catch {
				console.log('error')
			}
	}

	

	handleSelectPin(pin){
		history.push(`/attraction/${pin.id}`)
	}

	renderAttractions(){
		const { 
			attractions, 
			currentUser, 
			handleSelectAttraction,
			handleShowAddAttraction,
			locationLat, 
			locationLng,
			locationUserId 
	} = this.props
		
	
	 		const sortedAttractions = attractions.reduce((accum, currentValue)=> {
				accum[currentValue.attractionType].push(currentValue)
				return accum
			}, {'ACTIVITY': [], "LODGING": [], "RESTAURANT": []})

		
			return <Attractions
				attractions={attractions}
				currentUser={currentUser}
				handleSelectAttraction={handleSelectAttraction}
				handleSelectPin={this.handleSelectPin}
				handleShowAddAttraction={handleShowAddAttraction}
				handleShowDelete={this.handleShowDelete}
				handleShowEdit={this.handleShowEdit}
				handleShowOtherVisitors={this.handleShowOtherVisitors}
				locationUserId={locationUserId}
				locationLat={locationLat}
				locationLng={locationLng}
				sortedAttractions={sortedAttractions}
	

			 />
			
		
			
			

	}

	renderOtherVisitors(){
		const { otherVisitors } = this.state
		return <Visitors 
			handleClose={this.handleHideOtherVisitors}
			otherVisitors={otherVisitors}
			popup={true}
			view="attractions"
		/>

	}


	renderDelete(){
		const { currentUser, locationId } = this.props
		const { attractionId, deletionName } = this.state

		const currentUserId = currentUser.id > 0 ? currentUser.id : 0;
		return(<Mutation
		      mutation={DELETE_ATTRACTION}
		      refetchQueries={[{query: GET_LOCATION_ATTRACTIONS, variables: {locId: locationId, currentUserId: currentUserId}}]}
		    >
		      {(deleteAttraction, { data, loading, error }) => {
				  	return (<Deletion 
				    	id={attractionId}
				    	error={error} 
				    	handleHideDelete={this.handleHideDelete}
				    	handleDelete={this.handleDeleteAttraction}
				    	mutationFunction={deleteAttraction}
				    	name={deletionName}
				    	type="attraction"
			    	/>
    			)}}       
		</Mutation>)
	}



	renderEdit(){
		const { editAttraction } = this.state

		return <AttractionDetailsContainer 
			attraction={editAttraction}
			closeText="Cancel"
			handleFinish={this.handleHideEdit}
			submitText="Submit"
		/>
	}

		

	render() {
		const { showDelete, showEdit, showOtherVisitors } = this.state

		return (
			<div className="attractionsContainer">
			    {showDelete && this.renderDelete()}
			    {showEdit && this.renderEdit()}
			    {showOtherVisitors && this.renderOtherVisitors()}
			    {this.renderAttractions()}

			    		
			</div>
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	graphql(setPopup, {name: 'setPopup'}),
	GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
	})
)(AttractionsContainer);
