import React from "react";

import "../stylesheets/components/MyTrips.css"

const MyTrips = props => {
	const { 
		handleGoToTrip,
		handleShowCreateTrip,
		myTrips
	} = props
	return (
		<div className="myTrips">
			<div className="myTripsHeader">
				<div className="headerHolder fullHeaderHolder">
						<div className="mainHeader">my trips</div>
						<div className="headerUnderline mainHeaderUnderline"></div>
					</div>
				
				<button className="primaryButton" onClick={handleShowCreateTrip}>Create Trip</button>
			</div>
			<div>
				{myTrips.length ? myTrips.map(trip => {
					return <div className="mainBackground textLink fontLarge" key={trip.id} onClick={() => handleGoToTrip(trip.id)}>{trip.name}</div>
				}): <div className="mainBackground">You have no trips</div> }
			</div>
		</div>
	);
};

export default MyTrips;
