import React from "react";

import "../stylesheets/components/AddSelection.css"



const AddSelection = props => {
	const {
		handleSelectAdd, 
		locations
	} = props;


	return (<div className="addSelection">
			<div className="addSelectionHolder">
				<button className="primaryButton addButton" onClick={() => handleSelectAdd('location')}>Location</button>

				<button className="primaryButton addButton" disabled={!locations.length} onClick={() => handleSelectAdd('attraction')}>Activity, Lodging, Food & Drinks</button>

				<button className="primaryButton addButton" disabled={!locations.length} onClick={() => handleSelectAdd('pictures')}>Pictures</button>

				<button className="primaryButton addButton"  onClick={() => handleSelectAdd('upcomingTrip')}>Upcoming Trip</button>


	
			</div>
		</div>
	
		
	);
};

export default AddSelection;