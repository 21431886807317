import React from "react";
import { compose, graphql, Mutation, Query, withApollo } from "react-apollo";
import * as Yup from "yup";
import moment from "moment"

import history from "../history"

import { getCurrentUser } from "../apollo/state/queries";
import { setPopup } from "../apollo/state/mutations";
import { 
	GET_LOCATION_VIEW_AND_DATES,
	GET_TRIP_BY_ID,
	GET_TRIP_LOCATION_AND_DATES, 
	GET_TRIP_GENERAL_LOCATIONS } from "../apollo/db/queries"
import { 
	CREATE_LOCATION_DATE, 
	CREATE_TRIP_LOCATION_DATE, 
	DELETE_LOCATION_DATE, 
	DELETE_TRIP_LOCATION_DATE, 
	UPDATE_TRIP_LOCATION_DATE, 
	UPDATE_LOCATION_DATE
 } from "../apollo/db/mutations"

import Deletion from "../components/Deletion"
import DatesForm from "../components/DatesForm"
import Dates from "../components/Dates"


class DatesContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deletionId: null,
			editDateId: null, 
			editStartDate: null, 
			editEndDate: null,
			isEditing: false,
			showAdd: false, 
			showAllDates: false,
			showDelete: false, 
			showEdit: false, 
			stateError: null
		}

		this.handleCreate = this.handleCreate.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleHideAdd = this.handleHideAdd.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleShowAdd = this.handleShowAdd.bind(this)
		this.handleToggleAllDates = this.handleToggleAllDates.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleToggleIsEditing = this.handleToggleIsEditing.bind(this)
		this.handleUpdate = this.handleUpdate.bind(this)
	}

	async handleShowAdd(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAdd: true})
	}

	async handleHideAdd(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAdd: false, stateError: null})
	}

	handleToggleAllDates(){
		const { showAllDates } = this.state
		this.setState({showAllDates: !showAllDates})
	}

	handleToggleIsEditing(){
		const { isEditing } = this.state
		this.setState({isEditing: !isEditing})
	}


	async handleShowEdit(editDateId, editStartDate, editEndDate){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showEdit: true, editDateId: editDateId, editStartDate: editStartDate, editEndDate: editEndDate})
	}

	async handleHideEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({
			showEdit: false, 
			editDateId: null, 
			editStartDate: null, 
			editEndDate: null, 
			stateError: null
		})
	}

	async handleShowDelete(deletionId){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionId: deletionId})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionId: null, deletionType: null})
	}


	async handleDelete(mutationFunction){
		const { deletionId } = this.state
		const { setPopup } = this.props
		try {
				await mutationFunction({
					variables: {
						input: {
							id: deletionId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.setState({ showDelete: false, deletionId: null, deletionType: null })
			} catch {
				console.log('error')
			}
	}



	async handleCreate(values, mutationFunction, setSubmitting){
		const { client, dateType, parentType, parentId, setPopup, tripId } = this.props

		setSubmitting(true)

		const startDate = values.startDate ? values.startDate : null
		const endDate = values.endDate ? values.endDate : null

		console.log('startDate', startDate)

		if (parentType === 'tripLocation'){
			const tripRes = await client.query({
     			query: GET_TRIP_BY_ID, 
    			variables: { id: tripId }
    		})
    		const trip = tripRes.data.tripById 
    		const tripStartDate = trip.startDate
    		const tripEndDate = trip.endDate

    		if (!tripStartDate || !tripEndDate){
    			this.setState({stateError: "Please ensure your overall trip has a start date and end date before adding dates to specific locations"})
    			setSubmitting(false)
    			return;
    		} else if ((new Date(startDate).getTime() < new Date(tripStartDate).getTime() || new Date(endDate).getTime() > new Date(tripEndDate).getTime())){
    			this.setState({stateError: "Dates must be within the date range of the trip"})
    			setSubmitting(false)
    			return;
    		}
		} 

		try {
				await mutationFunction({
					variables: {
						input: {
							[dateType]: {
								[parentType + 'Id']: parentId, 
								startDate: startDate, 
								endDate: endDate

							}
						}
					}
				})
				await setPopup({
					variables: {
						popup: false
					}
				})
				await this.setState({ showAdd: false, stateError: null })
				setSubmitting(false)
				

			} catch {
				setSubmitting(false)
				console.log('error')
			}
	}



	async handleUpdate(values, mutationFunction, setSubmitting){
		const { editDateId } = this.state
		const { client, dateType, parentType, setPopup, tripId } = this.props

		setSubmitting(true)

		const startDate = values.startDate ? values.startDate : null
		const endDate = values.endDate ? values.endDate : null

		if (parentType === 'tripLocation'){
			const tripRes = await client.query({
     			query: GET_TRIP_BY_ID, 
    			variables: { id: tripId }
    		})
    		const trip = tripRes.data.tripById 
    		const tripStartDate = trip.startDate
    		const tripEndDate = trip.endDate

    		if (!tripStartDate || !tripEndDate){
    			this.setState({stateError: "Please ensure your overall trip has a start date and end date before adding dates to specific locations"})
    			setSubmitting(false)
    			return;
    		} else if ((new Date(startDate).getTime() <= new Date(tripStartDate).getTime() || new Date(endDate).getTime() >= new Date(tripEndDate).getTime())){
    			this.setState({stateError: "Dates must be within the date range of the trip"})
    			setSubmitting(false)
    			return;
    		}
		} 

		try {
				await mutationFunction({
					variables: {
						input: {
							id: editDateId,
							[dateType + "Patch"]: {
								startDate: startDate, 
								endDate: endDate
							}	
						}
					}
				})

				await setPopup({
					variables: {
						popup: false
					}
				})
				await this.setState({ showEdit: false, stateError: null })
				setSubmitting(false)


			} catch {
				setSubmitting(false)
				console.log('error')
			}
	}

	renderAddTripLocDates(tripLocation){
		const { dates, parentId, tripId } = this.props
		const { stateError } = this.state

		const tripDatesSchema = Yup.object().shape({
		  startDate: Yup.date(),
		  endDate: Yup.date().when('startDate', (st, schema) => {
		    	return Yup.date().min(st, "End Date must be after Start Date");
			})	
		})


		const initialValues = {
			startDate: '', 
			endDate: ''
		}

		const formClass = !dates || dates[0] === null || !dates.length ? "firstDateAdd" : "additionalDateAdd"
		
		return <Mutation
		    mutation={CREATE_TRIP_LOCATION_DATE}
		    refetchQueries={[{query: GET_TRIP_LOCATION_AND_DATES, variables: {tripLocId: parentId}}, 
		    {query: GET_TRIP_GENERAL_LOCATIONS, variables: {viewedTripId: tripId}}]}
  		> 
  			{(createTripLocationDate, { data, loading, error }) => {
				return <div className={`addDatesForm ${formClass}`}>
					<DatesForm 
					    error={error}
						initialValues={initialValues}
						handleClose={this.handleHideAdd}
						handleSubmit={this.handleCreate}
						mutationFunction={createTripLocationDate}
						stateError={stateError}
						validationSchema={tripDatesSchema}
					/>
				</div>
  			}}
  		</Mutation>
	} 

	renderAddLocDates(location){
		const { dates, parentId, tripId } = this.props



		const datesSchema = Yup.object().shape({
		  startDate: Yup.date(),
		  endDate: Yup.date().when('startDate', (st, schema) => {
		    	return Yup.date().min(st, "End Date must be after Start Date");
			})	
		})


		const initialValues = {
			startDate: '', 
			endDate: ''
		}

		const formClass = !dates || dates[0] === null || !dates.length ? "firstDateAdd" : "additionalDateAdd"
		
		return <Mutation
		    mutation={CREATE_LOCATION_DATE}
		    refetchQueries={[{query: GET_LOCATION_VIEW_AND_DATES, variables: {locId: parentId}}]}
  		> 
  			{(createLocationDate, { data, loading, error }) => {
				return <div className={`addDatesForm ${formClass}`}>
				<DatesForm 
				    error={error}
					initialValues={initialValues}
					handleClose={this.handleHideAdd}
					handleSubmit={this.handleCreate}
					mutationFunction={createLocationDate}
					validationSchema={datesSchema}
				/>
				</div>
  			}}
  		</Mutation>
	} 

	renderEditTripLocDates(){
	 	const { editStartDate, editEndDate, editDateId, stateError } = this.state
		const { parentId, tripId } = this.props

		const initialValues = {
			startDate: editStartDate, 
			endDate: editEndDate
		}

		const tripDatesSchema = Yup.object().shape({
		  startDate: Yup.date(),
		  endDate: Yup.date().when('startDate', (st, schema) => {
		    	return Yup.date().min(st, "End Date must be after Start Date");
			})	
		})


		return <Mutation
		    mutation={UPDATE_TRIP_LOCATION_DATE}
		    refetchQueries={[{query: GET_TRIP_LOCATION_AND_DATES, variables: {tripLocId: parentId}}, 
		    {query: GET_TRIP_GENERAL_LOCATIONS, variables: {viewedTripId: tripId}}]}
  		> 
  			{(updateTripLocationDate, { data, loading, error }) => {
  				return <div className="editDatesForm">
  				    <DatesForm 
  				        error={error}
  						initialValues={initialValues}
  						handleClose={this.handleHideEdit}
  						handleSubmit={this.handleUpdate}
  						mutationFunction={updateTripLocationDate}
  						stateError={stateError}
  						validationSchema={tripDatesSchema}
  					/>
  					</div>

		  	}}
  		</Mutation>
	}

	renderEditLocDates(){
	 	const { editStartDate, editEndDate, editDateId } = this.state
		const { parentId } = this.props

		const initialValues = {
			startDate: editStartDate, 
			endDate: editEndDate
		}

		const datesSchema = Yup.object().shape({
		  startDate: Yup.date(),
		  endDate: Yup.date().when('startDate', (st, schema) => {
		    	return Yup.date().min(st, "End Date must be after Start Date");
			})	
		})


		return <Mutation
		    mutation={UPDATE_LOCATION_DATE}
		    refetchQueries={[{query: GET_LOCATION_VIEW_AND_DATES, variables: {locId: parentId}}]}
  		> 
  			{(updateLocationDate, { data, loading, error }) => {
  				return <div className="editDatesForm">
  				    <DatesForm 
  				        error={error}
  						initialValues={initialValues}
  						handleClose={this.handleHideEdit}
  						handleSubmit={this.handleUpdate}
  						mutationFunction={updateLocationDate}
  						validationSchema={datesSchema}
  					/>
  					</div>

		  	}}
  		</Mutation>
	}


	renderDeleteTripLocDate() {
		const { parentId, tripId } = this.props 
		return <Mutation
 		 mutation={DELETE_TRIP_LOCATION_DATE}
 		 refetchQueries={[
 	      	{query: GET_TRIP_GENERAL_LOCATIONS, variables:{viewedTripId: tripId}}, 
 		 	{query: GET_TRIP_LOCATION_AND_DATES, variables:{tripLocId: parentId}}]}
	    >
	      	{(deleteTripLocationDate, { loading, error }) => {
	      		return  <div className="deletionDate">
					 <Deletion 
					    error={error}
						handleHideDelete={this.handleHideDelete}
						handleDelete={this.handleDelete}
						mutationFunction={deleteTripLocationDate}
						type="trip location date"
					/>
				</div>

	      	}}
	    </Mutation>

	}

	renderDeleteLocDate() {
		const { parentId, tripId } = this.props 
		return <Mutation
 		 mutation={DELETE_LOCATION_DATE}
 		 refetchQueries={[{query: GET_LOCATION_VIEW_AND_DATES, variables: {locId: parentId}}]}
	    >
	      	{(deleteLocationDate, { loading, error }) => {
	      		return  <div className="deletionDate">
					 <Deletion 
					    error={error}
						handleHideDelete={this.handleHideDelete}
						handleDelete={this.handleDelete}
						mutationFunction={deleteLocationDate}
						type="location date"
					/>
				</div>

	      	}}
	    </Mutation>

	}


	renderDates(){
		const { 
			currentUser,
			dates, 
			dateType, 
			isEditingTripLoc,
			locationUserId,
			title,
			type, 
			view } = this.props
		const { isEditing, showAllDates } = this.state
		return <Dates 
					currentUser={currentUser}
					dates={dates}
					dateType={dateType}
					handleShowAdd={this.handleShowAdd}
					handleShowEdit={this.handleShowEdit}
					handleShowDelete={this.handleShowDelete}
					handleToggleAllDates={this.handleToggleAllDates}
					handleToggleIsEditing={this.handleToggleIsEditing}
					locationUserId={locationUserId}
					isEditing={isEditing}
					isEditingTripLoc={isEditingTripLoc}
					showAllDates={showAllDates}
					title={title}
					view={view}
				/>
	}



	render() {
		const { showAdd, showDelete, showEdit } = this.state
		const { currentUser, dates, isEditing, dateType, handleToggleIsEditing, locationUserId } = this.props

		return (
			<div className="datesContainer">
				{showDelete && ( dateType === 'tripLocationDate' ? this.renderDeleteTripLocDate() : this.renderDeleteLocDate())}
				{showAdd && (dateType === 'tripLocationDate'  ? this.renderAddTripLocDates() : this.renderAddLocDates())}
				{showEdit && (dateType === 'tripLocationDate' ? this.renderEditTripLocDates() : this.renderEditLocDates())}
				<div className="datesContent">
					{this.renderDates()}
				</div>
		
			</div>
		);
	}
}

export default compose(
	withApollo, 
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	}), 
	graphql(setPopup, {name: 'setPopup'})
	)(DatesContainer);
