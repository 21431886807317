import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-number-input';

import "../stylesheets/components/WaitingList.css"

import Autocomplete from "./Autocomplete"

const WaitingList = props => {
	const {
		createReferral,
		country,
		error, 
		handleClose, 
		handleChangePhoneNumber,
		handleChangeReferNumber,
		handleSelect,
		handleSignup, 
		handleSendReferrals,
		inviteFailure,
		mutationFunction, 
		phoneNumber, 
		onMobile,
		referNumbers,
		stateError, 
		step, 
		updateWaitingList,
		waitListSignup
	} = props

	return (
	<div className="waitListBackground waitingList">
		{/*This is for better search results*/}
		<h1 style={{display: 'none' }}>Trvlpage</h1>
		{onMobile ? <div className="waitListMainMobile">
				<div className="waitListTitleText waitListTitleTextMobile waitListTopText">
					Reflect.
				</div>
				<div className="waitListTitleText waitListTitleTextMobile">
					Connect.
				</div>
				<div className="waitListTitleText waitListTitleTextMobile">
					Explore.
				</div>
			</div> :
			 <div className="waitListMain">
				<div className="waitListTitleSection">
					<div className="waitListTitleWord">
						Reflect.
					</div>
					<div className="waitListTitleText">
						Pin your travels & add recommendations
					</div>
					
				</div>
				<div className="waitListTitleSection">
					<div className="waitListTitleWord">
						Connect.
					</div>
					<div className="waitListTitleText">
						Connect to your network
					</div>
					
				</div>
				<div className="waitListTitleSection">
					<div className="waitListTitleWord">
						Explore.
					</div>
					<div className="waitListTitleText">
						Plan trips with your network's recs
					</div>
					
				</div>
			</div>}



		{step === 'refer' && <div className="waitListSubTextSection">
			<div className="waitListSubText">
				You're on the list!
			</div>
			<div className="waitListSmallSubText">
				Refer friends and you'll move up in the line!
			</div>
		</div>}
		{step === 'thanks' && <div className="waitListSubTextSection">
			<div className="waitListSubText">
				We can't wait to see where you've been!
			</div>
			
		</div>}



		{step === 'initial' && <Formik
				onSubmit={( values, { setSubmitting }) => {
					handleSignup(setSubmitting, waitListSignup)
				}}
		 
		    >
		      {({ isSubmitting }) => (
		        <Form className={onMobile ? "waitListFormMobile" : "waitListForm"}>
		        	
		        	<div className="waitListItem">
			          <PhoneInput
				        placeholder="Enter phone number"
				        name="phoneNumber"
				        value={phoneNumber}
				        onChange={(value) => handleChangePhoneNumber(value)}
				       defaultCountry="US"
				      />
			          	<ErrorMessage className="waitListFormError" name="email" component="div" />

			        </div>
		        	
		        	<Autocomplete  
				        handleSelect={handleSelect}
				        type="waitingList"
				        placeholder="Where do you live?"
					/>

					<button className="waitingListButton" type="submit" disabled={isSubmitting}>Join the Waiting List</button>

		        </Form>
		      )}
		    </Formik>}
		   {step === 'refer' && <Formik
		      onSubmit={(values, {setSubmitting}) => {
		        handleSendReferrals(values, setSubmitting, createReferral, updateWaitingList)
		      }}
		    >
		      {({ isSubmitting }) => (
		        <Form className={onMobile ? "waitListFormReferMobile" : "waitListFormRefer"}>
		        	<div className="waitListNames">
			        	<div className="waitListNamesMain">
				        	<div className="waitListNames waitListNameLeft">
					          <Field 
					          	className="waitListFormField" 
					          	type="text"
					          	placeholder="Your First Name"
	 
					          	name="firstName" 
					          	onKeyDown={(e) => {
					                if (e.key === 'Enter') {
					                  e.preventDefault()
					                }
					              }}
					          	/>
					          	<ErrorMessage className="waitListFormError" name="firstName" component="div" />

					        </div>
					        <div className="waitListNames waitListNameRight">
					          <Field 
					          	className="waitListFormField" 
					          	type="text" 
					          	placeholder="Your Last Name"

					          	name="lastName" 
					          	onKeyDown={(e) => {
					                if (e.key === 'Enter') {
					                  e.preventDefault()
					                }
					              }}
					          	/>
					          	<ErrorMessage className="waitListFormError" name="lastName" component="div" />

					        </div>
					      </div>
					        <div className="waitListExtraText">So your friends know who referred them.</div>
					</div>

		        	<div className="waitListItem">
			         <PhoneInput
				        placeholder="Enter phone number"
				        name="phoneNumber"
				        value={referNumbers[0]}
				        onChange={(value) => handleChangeReferNumber(value, 0)}
				       defaultCountry="US"
				      />

			        </div>
		        	<div className="waitListItem">
			           <PhoneInput
				        placeholder="Enter phone number"
				        name="phoneNumber"
				        value={referNumbers[1]}
				        onChange={(value) => handleChangeReferNumber(value, 1)}
				       defaultCountry="US"
				      />
			        </div>
			        <div className="waitListItem">
			           <PhoneInput
				        placeholder="Enter phone number"
				        name="phoneNumber"
				        value={referNumbers[2]}
				        onChange={(value) => handleChangeReferNumber(value, 2)}
				       defaultCountry="US"
				      />
			        </div>
		        	

					<button className="waitingListButton" type="submit" disabled={isSubmitting}>Send them those invites!</button>
		        </Form>
		      )}
		    </Formik>}
		
		
		{step !== 'refer' && <div className="waitListLogoHolder">
			<img 
				className={onMobile ? "waitListLogo waitListLogoMobile": "waitListLogo"}
			 	src={require('../assets/logo.svg')}
			 	alt="logo"/>
		</div>}

	</div>);
}

export default WaitingList;
