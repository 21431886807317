import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";


import initialPic from '../assets/globe.jpg'


const EntryCode = props => {
	const {
		codeFailure,
		error, 
		handleClose, 
		handleSubmit, 
		mutationFunction
	} = props

	

	return (
		<div className="entryCode initialScreen">
			<div className="initialScreenLeft">
					<div className="headerHolder initialHeaderHolder">
						<div className="mainHeader initialHeader">entry code</div>
						<div className="headerUnderline initialHeaderUnderline"></div>
					</div>
					<div className="marginBottom formAdditionalText">If you do not have a code or invite, join our waitlist.</div>
					{codeFailure && <div>The code you provided has either expired or does not exist.</div>}		
					<Formik
			
					    onSubmit={(values, { setSubmitting }) => {
				        	handleSubmit(values, setSubmitting)
				      	}}
						onKeyDown={(e) => {
			                if (e.key === 'Enter') {
			                  e.preventDefault()
			                }
			            }} 
				    >
				      {({ isSubmitting }) => (
				        <Form 
				        	className="form"
				        >

								<Field 
									className="formField" 
									type="text" 
									name="entryCode"
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault()
						                }
						              }}
								/>
					       	<div className="formButtons">
										<button className="secondaryButton formButton" onClick={handleClose}>Join Waitlist</button> 
										<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>Access Trvlpage Now</button>
									</div>
				           <div className="progressDots">
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot"></div>
						         	<div className="progressDot progressDotFilled"></div>
								  		
					      		</div>
				       	 
				        </Form>
				      )}
				    </Formik>
						
				</div>
			<div className="initialScreenRight">
				<img 
				className="initialPicture"
			 	src={require('../assets/globe.jpg')}
			 	alt="formPicture"/>
			</div>

		</div>

	);
};

export default EntryCode;