import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ScrollToBottom from 'react-scroll-to-bottom';

import "../stylesheets/components/UserMessages.css"
import { Element } from 'react-scroll';

import ReportObjectionableContainer from "../containers/ReportObjectionableContainer"

const UserMessages = props => {
	const { 
		currentUser,
		messages
	} = props
	const senderInfo = messages[0].senderId === currentUser.id ? 
		{firstName: messages[0].receiverFirstName, lastName: messages[0].receiverLastName, publicUrl: messages[0].receiverPublicUrl, messengerId: messages[0].receiverId} : 
		{firstName: messages[0].senderFirstName, lastName: messages[0].senderLastName, publicUrl: messages[0].senderPublicUrl, messengerId: messages[0].senderId}


	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		} else {
			return location.country		}
	}


	return (
		<div  className="userMessages">
			
			<Link className="textLink categoryHeader marginBottom" to={`/profile/${senderInfo.messengerId}`}>{(senderInfo.firstName + ' ' + senderInfo.lastName)}</Link>
			<ScrollToBottom className="userMessagesContent">
				{messages.map(message => {
					return <div className={message.senderId === currentUser.id ? "messageHolder myMessageHolder" : "messageHolder"} key={message.id}>
						<div className={message.senderId === currentUser.id ? "secondaryBackground" : "secondaryBackground mainBackgroundColor"} >
							<div className="message fontSmall">
								<img className="picMedium" src={message.senderPublicUrl} alt="profPic" />
								<div className="messageContent">
									<div className="messageHeader">
										<div className="messageCreatedAt fontXS">{moment.utc(message.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}</div>
										{message.locationId && <Link className="locationTag textLink" to={`/location/${message.locationId}`}>{getLocName(message)}</Link>}
									</div>
									{message.objectionable ?
						             	<div>{message.objectionable === 'CONFIRMED' ? 
						             		<div>This message has been flagged.</div> : 
						             		<div>This message has been reported and is under review.</div>}
						             	</div> : 
						             <div>{message.messageContent}</div>}
						             {currentUser.id !== message.senderId && <div>
										<ReportObjectionableContainer 
											reportItem={message}
											reportType='MESSAGE'
											reportTypeRender="message"
										/>
									</div>}
								</div>
								
							</div>
						</div>
					</div>
				})}
				
			</ScrollToBottom>
		</div>
	);
};

export default UserMessages;
