import React from "react";
import { compose, graphql, Mutation, Query } from "react-apollo";
import axios from "axios"
import { analytics } from '../index.js';


import { getCurrentUser } from "../apollo/state/queries";
import { 
	GET_ATTRACTION_PICTURES,
	GET_LOCATION_ATTRACTIONS, 
	GET_LOCATION_PICTURES, 
	GET_OVERALL_ATTRACTION_PICTURES
} from "../apollo/db/queries"
import { CREATE_LOCATION_PICTURES } from "../apollo/db/mutations"

import PictureAdd from "../components/PictureAdd";
import PictureAttractionSelection from "../components/PictureAttractionSelection"

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}



class UploadPicturesContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displaySelectIndex: null, 
			loading: false,
			selectedAttractions: {},
			selectedPictures: {}, 
			stateError: null
		}

		this.handleRemovePicture = this.handleRemovePicture.bind(this)
		this.handleSelectAttraction = this.handleSelectAttraction.bind(this)
		this.handleSelectPictures = this.handleSelectPictures.bind(this)
		this.handleUploadPictures = this.handleUploadPictures.bind(this)
		this.renderSelectedPicture = this.renderSelectedPicture.bind(this)
	}

	handleSelectPictures(event){
		if (event.target.files.length > 100){
			this.setState({stateError: "Please upload a maximum of 100 photos"})
		} else {
		this.setState({selectedPictures: event.target.files})
		}
	}

	handleShowAddAttraction(fileIndex){
		this.setState({displaySelectIndex: fileIndex})
	}

	handleRemoveSelectedAttraction(fileIndex){
		const { selectedAttractions } = this.state
		const newSelectedAttractions = Object.assign({}, selectedAttractions)
		delete newSelectedAttractions[fileIndex]
		this.setState({selectedAttractions: newSelectedAttractions})

	}	

	handleRemovePicture(fileIndex){
		const{ selectedPictures } = this.state
		const newSelectedPics = Object.assign({}, selectedPictures)
		delete newSelectedPics[fileIndex]
		this.setState({selectedPictures: newSelectedPics, stateError: null})
	}

	handleSelectAttraction(attraction){
		const { displaySelectIndex, selectedAttractions } = this.state
		const newSelectedAttractions = Object.assign({}, selectedAttractions)
		newSelectedAttractions[displaySelectIndex] = { id: attraction.id, name: attraction.name }
		this.setState({ selectedAttractions: newSelectedAttractions, displaySelectIndex: null})
	}

	async handleUploadPictures(createLocationPictures, setSubmitting){
		const { loading, selectedAttractions, selectedPictures } = this.state
		const { attractionId, handleClose, locationId, view } = this.props

		setSubmitting(true)

		if (!Object.keys(selectedPictures).length){
			setSubmitting(false)
			return null
		}

		this.setState({loading: true})
	
		const fd = new FormData();

		const attractions = {}
	
		Object.keys(selectedPictures).forEach((key, index) => {
			console.log('adding this to fd', selectedPictures[key])
			fd.append('files', selectedPictures[key])
			attractions[index] = selectedAttractions[key]
		})
		
	    const res = await axios.post(`${getUrl()}/image/location`, fd, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
	    })

	    const publicUrls = res.data

	    const pictures = Object.keys(publicUrls).map(fileIndex => {
	    	if (view === 'attractionView'){
	    		return {url: publicUrls[fileIndex], attrId: attractionId}
	    	} else {
	    		const attrId = attractions[fileIndex] ? attractions[fileIndex].id : null
	    		return {url: publicUrls[fileIndex], attrId: attrId}
	    	}
	    })
	    
	    try {
			await createLocationPictures({
				variables: {
					input: {	
							locId: locationId, 
							pictures: pictures	
						}	
					}
				})
			
			await this.setState({ loading: false, error: null, selectedPictures: {}})
			//setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('add_location_pictures', {
				 	amount: pictures.length
				});
			}
			

		} catch {
			setSubmitting(false)
			console.log('error')
		}
		handleClose()
	}

	renderSelectedPicture(selectedPictures, fileIndex){
		console.log('selectedPictures', selectedPictures)
		const { displaySelectIndex, selectedAttractions } = this.state
		const { view } = this.props
	
		const reader = new FileReader();
		reader.onload = function(){
      		const output = document.getElementById(`upload${fileIndex}`);
      		if (output){
     	 		output.src = reader.result;
      		}
  		};

		reader.readAsDataURL(selectedPictures[fileIndex]);
	
		return <div className="selectedPictureContent" key={fileIndex}>
			<div className="removePicHolder">
				<div className="iconMedium tertiaryButton" onClick={() => this.handleRemovePicture(fileIndex)}>x</div>
				<div className="fontXS">Remove Picture</div>
			</div>
			<img id={`upload${fileIndex}`} className="selectedPicture"/>
			{(view !== 'attractionView' && displaySelectIndex !== fileIndex) && (selectedAttractions[fileIndex] ? 
				<div className="selectedAttraction">
					<div className="fontXS">{selectedAttractions[fileIndex].name.toUpperCase()}</div>
					<div className="removeIcon iconSmall" onClick={() => this.handleRemoveSelectedAttraction(fileIndex)}>-</div>
				</div> 
				: <div className='secondaryButton fontXS' onClick={() => this.handleShowAddAttraction(fileIndex)}>Connect to Specific Place</div>)}
			{(displaySelectIndex === fileIndex && view !== 'attractionView') && this.renderSelectAttraction()}

		</div>

	}

	renderSelectAttraction(){
		const { currentUser, locationId } = this.props 
		return <Query query={GET_LOCATION_ATTRACTIONS} variables={{locId: locationId, currentUserId: currentUser.id}}>
  			{({ loading, error, data }) => {
	  			if (loading) {return <div> Loading... </div>}
	  			if (error) {return <div>Error</div>}

	  			const locAttractions = data.locationAttractions.nodes

	  			return <PictureAttractionSelection
	  				locAttractions={locAttractions} 
	  				handleSelectAttraction={this.handleSelectAttraction}
	  			/>

	  		}}
	  	</Query>
	}


	renderPictureUpload(){
		const { loading, selectedPictures, stateError} = this.state
		const { handleClose, locationId } = this.props
		return <Mutation
	      mutation={CREATE_LOCATION_PICTURES}
	      refetchQueries={(result) => {
	      	const queries = [{query: GET_LOCATION_PICTURES, variables: {locId: locationId}}]
	      	const attrIds =[]
	      	const attrPlaceIds = []
	      	const pics = result.data.createLocationPictures.attrPictureData
	      	pics.forEach(picture => {
	      		if (picture.attractionId && !attrIds.includes(picture.attractionId)){
	      			attrIds.push(picture.attractionId)
	      			queries.push({query: GET_ATTRACTION_PICTURES, variables: {attrId: picture.attractionId}}) 
	      		}
	      		if (picture.attractionPlaceId && !attrPlaceIds.includes(picture.attractionPlaceId)){
	      			attrPlaceIds.push(picture.attractionPlaceId)
	      			queries.push({query: GET_OVERALL_ATTRACTION_PICTURES, variables: {attrPlaceId: picture.attractionPlaceId}})
	      		}
	      	})
	      	return queries
	      }}
	    >
	      {(createLocationPictures, { error }) => {
		      	return (<div>
		      		<PictureAdd 
			      		createLocationPictures={createLocationPictures}
			      		error={error}
			      		handleChange={this.handleSelectPictures}
			      		handleClose={handleClose}
			      		handleSubmit={this.handleUploadPictures}
			      		loading={loading}
			      		selectedPictures={selectedPictures}
			      		stateError={stateError} 
			      		renderSelectedPicture={this.renderSelectedPicture}
		      		/>
		      	</div>)
	      	}}
		</Mutation>
	}

	render() {
		const { selectedPictures } = this.state
		return (
			<div className="uploadPicturesContainer">
				{this.renderPictureUpload()}
			</div>
		);
	}
}

export default compose(
	graphql(getCurrentUser, {
		props: ({ loading, data: { currentUser } }) => ({ 
			loading,
			currentUser
		})
	})
)(UploadPicturesContainer);
