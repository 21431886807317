import React from "react";
import { compose, Mutation, graphql } from "react-apollo";
import axios from "axios"


import { getCurrentUser, getDestinationSearch } from "../apollo/state/queries"
import { setDestinationSearchNeedsRefresh, setPopup } from "../apollo/state/mutations"
import { REPORT_OBJECTIONABLE } from "../apollo/db/mutations"

import {
	GET_ALL_USER_MESSAGES,
	GET_ATTRACTION_COMMENTS, 
	GET_ATTRACTION_VIEW_BY_ID, 
	GET_LOCATION_TIPS,
	GET_LOCATION_VIEW_AND_DATES,
	GET_SAVED_ATTRACTIONS, 
	GET_LOCATION_ATTRACTIONS, 
	GET_LOCATION_PICTURE_COMMENTS,
	GET_LOCATION_SAVED_ATTRACTIONS, 
	GET_OVERALL_ATTRACTION_REVIEWS,
	GET_OVERALL_ATTRACTION_REVIEWS_ALL,
	GET_OVERALL_LOCATION_TIPS,
	GET_OVERALL_LOCATION_TIPS_ALL, 
	GET_OVERALL_LOCATION_ATTRACTIONS, 
	GET_USER_MESSAGES,
	SEARCH_ATTRACTIONS
} from "../apollo/db/queries"



import ReportObjectionable from "../components/ReportObjectionable"
import ReportObjectionableButton from "../components/ReportObjectionableButton"

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}


class ReportObjectionableContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			showReport: false
		}

		this.handleHideReport = this.handleHideReport.bind(this)
		this.handleShowReport = this.handleShowReport.bind(this)
		this.handleReport = this.handleReport.bind(this)
		
	}

	async handleShowReport(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		console.log('running show report')
		this.setState({showReport: true})
	}

	async handleHideReport(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showReport: false})
	}

	async handleReport(reportObjectionable){
		const { setPopup, reportItem, reportType, reportTypeRender } = this.props
		let contentId, contentTypeRender; 
		if (reportType === 'ATTRACTION_COMMENT'){
			contentId  = reportItem.attrId
			contentTypeRender = 'attraction comment'
		} else if (reportType === 'LOCATION_PICTURE_COMMENT') {
			contentId = reportItem.picId
			contentTypeRender = 'location picture comment'
		} else {
			contentId = reportItem.id
			contentTypeRender = reportTypeRender
		}
		try {
				await reportObjectionable({
					variables: {
						input: {
							reportedContentId: contentId, 
							reportedContentType: reportType,
							contentTypeRender: contentTypeRender
							}
						}
					})

				
				await setPopup({
					variables: {
						popup: false
					}
				})

				await axios(`${getUrl()}/report`, {
				  method: "put",
				  withCredentials: true, 
				})

				
				this.setState({ loading: false, showReport: false })
			} catch {
				console.log('error')
			}
	}

	renderReportButton(){
		return <ReportObjectionableButton 
			handleShowReport={this.handleShowReport}
		/>

	}

	renderReport(){
		const { currentUser, reportItem, reportType, reportTypeRender } = this.props
		console.log('report type', reportType)

		const currentUserId = currentUser.id > 0 ? currentUser.id : 0;
		return(<Mutation
		      mutation={REPORT_OBJECTIONABLE}
		      refetchQueries={() => {
		      		if (reportType === 'ATTRACTION'){
			      			return [{query: GET_LOCATION_ATTRACTIONS, variables: {locId: reportItem.locationId, currentUserId: currentUser.id}}, 
				      {query: GET_ATTRACTION_VIEW_BY_ID, variables: { attrId: reportItem.id, currentUserId: currentUser.id}}, 
				      {query: GET_LOCATION_SAVED_ATTRACTIONS, variables: { locPlaceId: reportItem.locationPlaceId }}, 
				      {query: GET_SAVED_ATTRACTIONS}, 
				      {query: GET_OVERALL_ATTRACTION_REVIEWS, variables: {attrPlaceId: reportItem.placeId}}, 
				      {query: GET_OVERALL_ATTRACTION_REVIEWS_ALL, variables: {attrPlaceId: reportItem.placeId}}, 
				      {query: GET_OVERALL_LOCATION_ATTRACTIONS, variables: {locationPlaceId: reportItem.locationPlaceId}}
				      ]
		      		} else if (reportType === 'ATTRACTION_COMMENT') {
		      			return [{query: GET_ATTRACTION_COMMENTS, variables:{attrId: reportItem.attrId}}]
		      		} else if (reportType === 'LOCATION_PICTURE_COMMENT'){
		      			return [{query: GET_LOCATION_PICTURE_COMMENTS, variables:{locPicId: reportItem.picId}}]
		      		} else if (reportType === 'LOCATION_TIP'){
		      			return [{query: GET_OVERALL_LOCATION_TIPS, variables: {locationPlaceId: reportItem.placeId}},
		      			{query: GET_OVERALL_LOCATION_TIPS, variables:{locationPlaceId: reportItem.placeId, currentUserId: currentUser.id}},
		      			{query: GET_LOCATION_TIPS, variables:{locId: reportItem.locationId}}]
		      		} else if (reportType === 'LOCATION') {
		      			return [{query: GET_LOCATION_VIEW_AND_DATES, variables: {locId: reportItem.id}}]
		      		} else if (reportType === 'MESSAGE'){
		      			return [{query: GET_ALL_USER_MESSAGES},
		      			{query: GET_USER_MESSAGES, variables:{messengerId: reportItem.senderId}}]
		      		}
		      	}
			  }
		    >
		      {(reportObjectionable, { data, loading, error }) => {
				  	return (<ReportObjectionable
				    	id={reportItem.id}
				    	error={error} 
				    	handleHideReport={this.handleHideReport}
				    	handleReport={this.handleReport}
				    	mutationFunction={reportObjectionable}
				    	type={reportTypeRender}
			    	/>
    			)}}       
		</Mutation>)
	}


	render() {
		const { showReport } = this.state

		return (
			<div className="attractionsContainer">
			   
			    {showReport && this.renderReport()}
			    {this.renderReportButton()}
			   

			    		
			</div>
		);
	}
} 

export default compose(
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}), 
	graphql(getDestinationSearch, {
		props: ({loading, data: { destinationSearch }}) => ({
			loading,
			destinationSearch
		})
	}), 
    graphql(setDestinationSearchNeedsRefresh, {name: 'setDestinationSearchNeedsRefresh'}),
    graphql(setPopup, {name: 'setPopup'}),



	)(ReportObjectionableContainer);