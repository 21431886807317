import React from "react";
import { compose, graphql, Mutation, withApollo } from "react-apollo";
import axios from "axios";
import * as Yup from "yup"

import history from "../history"

import { analytics } from '../index.js';

import LandingPage from "../components/LandingPage";
import ResumeLandingPage from "../components/ResumeLandingPage"
import { CREATE_NEW_PROFILE_PICTURE,  WAIT_LIST_SIGNUP, CREATE_REFERRAL, UPDATE_WAITING_LIST } from "../apollo/db/mutations";


//const analytics = analytics();
const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}


class LandingPageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: null,  
			lastName: null, 
			phoneNumber: null,
			referNumbers: [],
			stateError: null, 
			step: 'initial', 
			selected: null, 
			waitListId: null, 
			view: 'landing'
		}

		this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this)
		this.handleChangeReferNumber = this.handleChangeReferNumber.bind(this)
		this.handleSelect = this.handleSelect.bind(this)
		this.handleSendReferrals = this.handleSendReferrals.bind(this)
		this.handleSignup = this.handleSignup.bind(this)

	}
		async componentDidMount(){


		function isMobile() {
		  var check = false;
		  (function(a){
		    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
		      check = true;
		  })(navigator.userAgent||navigator.vendor||window.opera);
		  return check;
		};
 
		let args, accessToken, userId, instagramDenied;
		if (window.location.search === '?resume'){
			this.setState({view: 'resume'})
		} 
		// if (window.location.search){
		// 		 if (window.location.search[1] === 'a'){
		// 			args = window.location.search.split('&')
		// 			accessToken = args[0] &&  args[0].split('=') && args[0].split('=')[1]
		// 			userId = args[1] && args[1].split('=') && args[1].split('=')[1]
		// 		} else {
		// 			instagramDenied = true
		// 		}
			 	
		// }
		
		// const onMobile = isMobile()

		// if (onMobile && accessToken){
		// 		window.location.href = `trvlpage://app/profile/${accessToken}/${userId}`;
			
		// } else if (onMobile && instagramDenied){
		// 		window.location.href = `trvlpage://app/profile/`;
		
		// }

	
	}
	

	handleSelect(selected){
		this.setState({selected: selected})
	}

		handleChangeReferNumber(value, index){
		const { referNumbers } = this.state  
		const newReferNumbers = referNumbers.slice(0)
		newReferNumbers[index] = value
		this.setState({referNumbers: newReferNumbers})
	}


	async handleSignup(values, setSubmitting, waitListSignup){
		const { selected, phoneNumber } = this.state
		setSubmitting(true)



		const localityObj = selected.address_components.find(component => {
				return component.types.includes('locality')
			})
			const locality = localityObj ? localityObj.long_name : null
			const adminLevel1Obj = selected.address_components.find(component => {
				return component.types.includes('administrative_area_level_1')
			})
			const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
			const countryObj = selected.address_components.find(component => {
				return component.types.includes('country')
			})
			const country = countryObj.long_name 
	

		try { 
			const res = await waitListSignup({
				variables: {
					input: {
							userPhoneNumber: phoneNumber,
						 	userPlaceId: selected.place_id,
							userLocality: locality, 
							userAdminLevel1: adminLevel1, 
							userCountry: country, 
						    userLat: selected.geometry.location.lat(), 
						    userLng: selected.geometry.location.lng()
						
						
  						
						
					}	
				}
			})
			const waitListId = res.data.waitListSignup.integer


			this.setState({waitListId: waitListId, step: 'refer', firstName: values.firstName, lastName: values.lastName})

			//  const res2 = await axios(`${getUrl()}/waitingList/signup`, {
			// 	  method: "post",
			// 	  data: {
			// 	  	 "phone": email
				 	
			// 		}
			// })


			setSubmitting(false)


		} catch {
			setSubmitting(false)
			this.setState({stateError: "There was an error"})
		}

	}


	async handleSendReferrals(values, setSubmitting, createReferral, updateWaitingList){
		const { referNumbers, waitListId, firstName, lastName,  } = this.state
		setSubmitting(true)

		const email1 = values.email1
		const email2 = values.email2
		const email3 = values.email3

		
		const finalEmails = []
		if (email1){
			finalEmails.push(email1)
		}
		if (email2){
			finalEmails.push(email2)
		}
		if (email3){
			finalEmails.push(email3)
		}

		try {
			finalEmails.forEach(async(email) => {
				const res = await createReferral({
					variables: {
						input: {
							referral: {
								email: email,
							 	referrerId: waitListId
							
							}
	  						
							
						}	
					}
				})
			})
			
			const res2 = await updateWaitingList({
					variables: {
						input: {
							id: waitListId, 
							waitingListPatch: {
								referralsSent: finalEmails.length
							}
							
						}	
					}
				})

			 const res3 = await axios(`${getUrl()}/refer`, {
				  method: "post",
				  data: {
				  	 "emails": finalEmails, 
				 	 "firstName": firstName,
				 	 "lastName": lastName
					}
			})

			 this.setState({step: 'thanks'})
			 
			setSubmitting(false)
		} catch {
			this.setState({stateError: 'There was an error.'})
			setSubmitting(false)
		}
		
	}

		

	
	handleChangePhoneNumber(value){
		this.setState({phoneNumber: value})
	}

	handleGoToPrivacyPolicy = () => {
		history.push('/privacy-policy')
	}

	handleGoToTerms = () => {
		history.push('/terms-of-service')
	}

	
	render() {
		const { referNumbers, stateError, step, view } = this.state
		

		const referralSchema = Yup.object().shape({
		 firstName: Yup.string()
		  	.matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid first name')
		    .required('First Name is required')
		    .max(20, 'First Name cannot be longer than 20 characters.'),
		  lastName: Yup.string()
		  	.matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid last name')
		    .required('Last Name is required')
		    .max(20, 'Last Name cannot be longer than 20 characters.'),
		})

		function isMobile() {
		  var check = false;
		  (function(a){
		    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
		      check = true;
		  })(navigator.userAgent||navigator.vendor||window.opera);
		  return check;
		};

		const onMobile = isMobile()
		
		return (<div>
			{view === 'landing' ? 
				<div className="landingPageContainer">

					<Mutation
				      mutation={CREATE_REFERRAL}
				    >
				      {(createReferral, { loading, error }) => (
					      	<Mutation
						      mutation={WAIT_LIST_SIGNUP}
						    	>
						      	{(waitListSignup, { loading, error }) => (
						   			<Mutation
								      mutation={UPDATE_WAITING_LIST}
								    >
								      {(updateWaitingList, { loading, error }) => (
										 <LandingPage
											createReferral={createReferral}
											handleChangePhoneNumber={this.handleChangePhoneNumber}
											handleChangeReferNumber={this.handleChangeReferNumber}
											handleGoToPrivacyPolicy={this.handleGoToPrivacyPolicy}
											handleGoToTerms={this.handleGoToTerms}
											handleSelect={this.handleSelect}
											handleSignup={this.handleSignup} 
											handleSendReferrals={this.handleSendReferrals} 
											referralSchema={referralSchema}
											step={step}
											onMobile={onMobile}
											referNumbers={referNumbers}
											stateError={stateError}
											updateWaitingList={updateWaitingList}
											waitListSignup={waitListSignup}/>
										 )}
						      	</Mutation>
						      	)}
							</Mutation>
						)}
				     </Mutation>

				</div>
			 : <ResumeLandingPage/>}
			 </div>
		);
	}
}

export default compose(
	
 withApollo
)(LandingPageContainer);
