import React from "react";

import "../stylesheets/components/Explore.css"

const Explore = props => {
	const { 
		handleGoToLocation,
		handleRefreshLocations, 
		handleShowMore, 
		locations
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country		
		}
	}


	return (
		<div className="explore">
		{locations.length ? 
			<div>
				{/*<div className="textLink exploreLink" onClick={() => handleRefreshLocations()}>Refresh Locations</div>*/}
				<div className="exploreLocations">
					{locations.map(location => {
						return <div onClick={() => handleGoToLocation(location.id)} className="exploreLocation">
							<img className="explorePicture" src={location.publicUrl} alt="explorePicture"/>
							<div className="exploreContent">
								<div className="exploreLocationName">{getLocName(location)}</div>
								<div className="exploreUserName">{`${location.firstName} ${location.lastName}`}</div>
							</div>
						</div>
					})}
				</div>
				{/*<div className="textLink exploreLink" onClick={() => handleShowMore()}>Show more</div>*/}
			</div> : <div className="loadingHolder"><div className="fontLarge">Hold tight. We're loading location pages for you to explore.</div></div>}
		
		</div>
		
	);
};

export default Explore;
