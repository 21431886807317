import React from "react";
import { Mutation, graphql, compose, withApollo } from "react-apollo";
import axios from "axios"
import { Link } from "react-router-dom";
import * as Yup from "yup"
import { Button } from 'reactstrap';
import { analytics } from '../index.js';


import Cropper from 'react-cropper';


import history from "../history"
 
import BasicInfo from "../components/BasicInfo";
import CheckKey from "../components/CheckKey";
import Connect from "../components/Connect"
import ContactAccess from "../components/ContactAccess"
import EmailPassword from "../components/EmailPassword";
import EntryCode from "../components/EntryCode";
import InviteFriends from "../components/InviteFriends"
import LocationsSignup from "../components/LocationsSignup"
import LoginContainer from "./LoginContainer"
import PhoneNumber from "../components/PhoneNumber";
import SinglePicAdd from "../components/SinglePicAdd";


import { setCurrentUser } from "../apollo/state/mutations"
import { getCurrentUser } from "../apollo/state/queries"
import { CONNECT, CREATE_FOLLOWER, CREATE_LOCATIONS, CREATE_NEW_PROFILE_PICTURE, CREATE_PROFILE_PICTURE, CREATE_NEW_USER, UPDATE_USER } from "../apollo/db/mutations";
import { CHECK_ENTRY_CODE, GET_SUGGESTED_FOLLOW_USERS, GET_SUGGESTED_FROM_CONTACTS } from "../apollo/db/queries"

const getUrl = () => {
	if (process.env.REACT_APP_SERVER_ENV === 'qa'){
			return ''
	} else if (process.env.NODE_ENV === 'development'){
		if (process.env.REACT_APP_SERVER_ENV === 'local'){
			return 'http://localhost:5000'
			//return  'https://trvlpage.uc.r.appspot.com'
		} else if (process.env.REACT_APP_SERVER_ENV === 'demo'){
			return  'https://trvlpage.uc.r.appspot.com'
		} else {
			return 'https://20220112t173811-dot-trvlpage.appspot.com'
		} 
	} else {
		return  'https://trvlpage.uc.r.appspot.com'
	}
}


class SignupContainer extends React.Component {
		constructor(props) {
		super(props);
		this.state = { 
			acceptedContacts: null,
			ask: 1,
			codeFailure: false,
			currentCity: null,
			entryCodeSuccess: false, 
			fromLogin: false,
			following: [],
			loading: false, 
			locations: [],
			locationAmount: 3,
			newAuthId: null,
			phoneNumber: null, 
			step: "phoneNumber",
			suggestedUsers: [],
			src: null,
			stateError: null,
			value: "", 
			verificationSent: false
		};

		this.cropImage = this.cropImage.bind(this)
		this.handleAcceptContact = this.handleAcceptContact.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleChangeEmail = this.handleChangeEmail.bind(this)
		this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this)
		this.handleCheckEntryCode = this.handleCheckEntryCode.bind(this)
		this.handleCheckKey = this.handleCheckKey.bind(this)
		this.handleConnect = this.handleConnect.bind(this)
		this.handleContactsLater = this.handleContactsLater.bind(this)
		this.handleCreateLocations = this.handleCreateLocations.bind(this)
		this.handleCreateUser = this.handleCreateUser.bind(this)
		this.handleEmailPassword = this.handleEmailPassword.bind(this)
		this.handleGetSuggestedFollowers = this.handleGetSuggestedFollowers.bind(this)
		this.handleIncreaseEmailAmount = this.handleIncreaseEmailAmount.bind(this)
		this.handleIncreaseLocAmount = this.handleIncreaseLocAmount.bind(this)
		this.handleInitiateUser = this.handleInitiateUser.bind(this)
		this.handleInvitesLater = this.handleInvitesLater.bind(this)
		this.handleLocationsLater = this.handleLocationsLater.bind(this)
		this.handleProfPicLater = this.handleProfPicLater.bind(this)
		this.handleRemoveLocation = this.handleRemoveLocation.bind(this)
		this.handleResendVerification = this.handleResendVerification.bind(this)
		this.handleSelectCity = this.handleSelectCity.bind(this)
		this.handleSelectFollow = this.handleSelectFollow.bind(this)
		this.handleSelectLocation = this.handleSelectLocation.bind(this)
		this.handleSelectPicture = this.handleSelectPicture.bind(this)
		//this.handleSendInvites = this.handleSendInvites.bind(this)
		this.handleUploadProfPic = this.handleUploadProfPic.bind(this)
		this.renderBasic = this.renderBasic.bind(this)
		
	}


	async componentDidMount(){
		//this is to account for people who only complete initiate user and dont finish making account
		const { currentUser, setCurrentUser } = this.props
		const { step } = this.state

		// console.log('location', window.location.search)
		// if (window.location.search === '?notVerified'){
		// 	console.log('running this')
		// 	this.setState({step: 'verify', stateError: null})
		// } else if (window.location.search === '?finishSignup'){
		// 	this.setState({step: 'basic', stateError: null, fromLogin: true})
		// }

		// if (currentUser.id && !currentUser.firstName && step !== "basic"){
		// 	this.setState({step: "basic", stateError: null})
		// }

	}

	async componentDidUpdate(){
		//this is to account for people who only complete initiate user and dont finish making account
		const { currentUser, setCurrentUser } = this.props
		const { step } = this.state
		

		// if (currentUser.id && !currentUser.firstName && step !=="basic"){
		// 	this.setState({step: "basic", stateError: null})
		// }

	}

	handleChangePhoneNumber(value){
		this.setState({phoneNumber: value})
	}

	handleAcceptContact(){
		console.log('accepting contacts')
		//fill in 
		this.setState({step: 'connect', acceptedContacts: true}, () => {
			this.handleGetSuggestedFollowers()
		})
		
	}

	handleContactsLater(setSubmitting){
		const { ask } = this.state
		console.log('ask ', ask)
		setSubmitting(true)
		if (ask === 1){
			this.setState({ask: 2})
			console.log('about to set setSubmitting')
			setSubmitting(false)
		} else {
			console.log('going to step')
			this.setState({step: 'connect', acceptedContacts: false}, () => {
				this.handleGetSuggestedFollowers()
			})
		}

	}



	async handleLocationsLater(){
		if (process.env.NODE_ENV !== 'development') {
			try {
				await analytics.logEvent('sign_up_process', {
				  page: 'locations', 
				  action: 'later'
				});	
			} catch {
				console.log('error with analytics')
			}
		}
		history.push('/explore')
	}

	async handleInvitesLater(){
		if (process.env.NODE_ENV !== 'development') {
			try {
				await analytics.logEvent('sign_up_process', {
				  page: 'connect', 
				  action: 'later'
				});	
			} catch {
				console.log('error with analytics')
			}
		}
		this.setState({step: 'locations'})
	}

	async handleProfPicLater(){
		if (process.env.NODE_ENV !== 'development') {
			try {
				await analytics.logEvent('sign_up_process', {
				  page: 'profPic', 
				  action: 'later'
				});	
			} catch {
				console.log('error with analytics')
			}
		}
		this.setState({step: 'contactAccess'})
	}

	handleIncreaseLocAmount(){
		const { locationAmount } = this.state
		if (locationAmount < 5){
			const newLocationAmount = locationAmount + 1
			this.setState({locationAmount: newLocationAmount})
		}
	}
	
	handleIncreaseEmailAmount(){
		const { emailAmount } = this.state
		if (emailAmount < 5){
			const newEmailAmount = emailAmount + 1
			this.setState({emailAmount: newEmailAmount})
		}
	}

	handleChangeEmail(event, index){
		const { emails } = this.state
		const newEmail = event.target.value
		console.log('newEmail', newEmail)
		const newEmails = emails.slice(0)
		newEmails[index] = newEmail
		this.setState({emails: newEmails})

	}

	handleGoToWaitlist(){
		history.push('/waitingList')
	}



	async handleCheckEntryCode(values, setSubmitting){
		const { client } = this.props
		setSubmitting(true)
		
		try {
			const code = values.entryCode

			const res = await client.query({
		   	 	query: CHECK_ENTRY_CODE, 
		   	 	variables: { enteredCode: code}
	 		})
	 		const codeWorked = res.data.checkEntryCode

	 		if (codeWorked){
	 			this.setState({step: 'phoneNumber', entryCodeSuccess: true})
	 		} else {
	 			console.log('setting Code failure')
	 			this.setState({codeFailure: true})
	 		}
	 		setSubmitting(false)
	 	} catch {
	 		setSubmitting(false)
	 		this.setState({stateError: "There was an error checking the code"})
	 	}
	}


	async handleInitiateUser(setSubmitting) {
		const { phoneNumber } = this.state
		const { setCurrentUser } = this.props

		setSubmitting(true)

	
		try {
	
			const res = await axios(`${getUrl()}/me`, {
			  method: "post",
			  data: {"phoneNumber": phoneNumber},
			  withCredentials: true
			})

			if (res.data.message){
				this.setState({stateError: res.data.message})
				setSubmitting(false)
				return null;
			} else if (res.data.id) {
					if (process.env.NODE_ENV !== 'development') {
						try {
							await analytics.logEvent('sign_up_process', {
							  page: 'initiate', 
							  action: 'complete'
							});	
						} catch {
							console.log('error with analytics')
						}
					}

				this.setState({ loading: false, step:'verify', stateError: null, newAuthId: res.data.id})
				setSubmitting(false)
			}

			

		} catch {
			this.setState({stateError: "There was an error."})
			setSubmitting(false)
		}
	}

	async handleEmailPassword(values, setSubmitting) {
		const { phoneNumber } = this.state
		const { setCurrentUser } = this.props

		setSubmitting(true)

		const email = values.email.toLowerCase()
		const password = values.password
		const retypePassword = values.retypePassword
		const agreeTerms = values.agreeTerms

		if (password !== retypePassword){
			this.setState({stateError: 'Please make sure your passwords match'})
			setSubmitting(false)
			return undefined;
		}

		/*if (!agreeTerms){
			this.setState({stateError: 'You must agree to the terms to create an account'})
			setSubmitting(false)
			return undefined;
		}*/
		
		try {
	
			const res = await axios(`${getUrl()}/me/emailPassword`, {
			  method: "put",
			  data: {"email": email, "password": password, "phoneNumber": phoneNumber},
			  withCredentials: true
			})

			if (res.data.message){
				this.setState({stateError: res.data.message})
				setSubmitting(false)
				return null;
			} else {
					if (process.env.NODE_ENV !== 'development') {
						try {
							await analytics.logEvent('sign_up_process', {
							  page: 'initiate', 
							  action: 'complete'
							});	
						} catch {
							console.log('error with analytics')
						}
					}

				this.setState({ loading: false, step:'basic', stateError: null})
				setSubmitting(false)
			}

			

		} catch {
			this.setState({stateError: "There was an error."})
			setSubmitting(false)
		}
	}

	async handleResendVerification() {
		const { email } = this.state
		try {
			const res = await axios(`${getUrl()}/me/resendVerification`, {
			  method: "put",
			  data: {"email": email},
			  withCredentials: true
			})

			if (res.data.message){
				this.setState({verificationSent: true})
			}
		} catch {
			this.setState({stateError: "There was an error resending the verification."})
		}
	}

	

	async handleCreateUser(values, setSubmitting, createNewUser, createProfilePicture, createFollower) {
		const { currentUser, setCurrentUser } = this.props
		const { newAuthId, currentCity, phoneNumber } = this.state

		setSubmitting(true)

		const firstName = values.firstName;
		const lastName = values.lastName;
		let birthday = values.birthday;
		const currentCityName = currentCity ? currentCity.formatted_address : null;
		const currentCityLat = currentCity ? currentCity.geometry.location.lat() : null; 
		const currentCityLng = currentCity ? currentCity.geometry.location.lng() : null; 

	
		this.setState({loading: true})

		if (!birthday || birthday.length !== 10){
			birthday = null
		}

		try { 
			const res = await createNewUser({
				variables: {
					input: {
						newAuthId: newAuthId,
						newPhoneNumber: phoneNumber,
						newFirstName: firstName,
						newLastName: lastName, 
						newBirthday: birthday, 
						newCurrentCity: currentCityName, 
						newCurrentCityLat: currentCityLat,
						newCurrentCityLng: currentCityLng
						
						}	
					}
				})

			const user = res.data.createNewUser.user
	
			await createProfilePicture({
				variables: {
					input: {
						profilePicture: {
							userId: user.id,
							publicUrl: "https://storage.googleapis.com/trvlpage-bucket/user.jpg"
						}
					}	
				}
			})

			

			 await setCurrentUser({
               variables: {
                    id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					birthday: user.birthday, 
					seenExplanation: false,
					seenSaveExplanation: false,
					seenLocExplanation: false, 
					seenProfExplanation: false,
					seenInstaConnection: false,
					seenAddPrompt: false, 
					locationViews: 0,
					pageViews: 0, 
					pushToken: false,
					publicUrl: "https://storage.googleapis.com/trvlpage-bucket/user.jpg", 
					locUploadNumber: 0,
    				attrUploadNumber: 0
                }
              })
 

			await this.setState({ loading: false, step: 'profPic', stateError: null })
			setSubmitting(false)
			if (process.env.NODE_ENV !== 'development'){
				

					await analytics.setUserId(user.id);
				

			
					await analytics.setUserProperties({
					  birthday: user.birthday,
					  currentCity: user.currentCity, 
					  device: 'desktop', 
					  createdAt: user.createdAt, 
					  version: '1.4.6'
					});

					await analytics.logEvent('sign_up_process', {
					  page: 'create', 
					  action: 'complete'

					});	
						await analytics.logEvent('sign_up', {
					  method: 'trvlpage'
					});
				}
				
		} catch(err) {
			console.log('error', err)
			setSubmitting(false)
		}
	}

	async handleCheckKey(values, setSubmitting){
		const { setCurrentUser } = this.props
		setSubmitting(true)
		const token = values.verificationKey
		try {
			const res = await axios(`${getUrl()}/me/verify`, {
			  method: "put",
			  data: {"token": token},
			  withCredentials: true
			})

		if (res.data[0].id){
			const user = res.data[0]
			await setCurrentUser({
                variables: {
                  id: user.id
                }
              })
			if (process.env.NODE_ENV !== 'development') {
						try {
							await analytics.logEvent('sign_up_process', {
							  page: 'verify', 
							  action: 'complete'
							});	
						} catch {
							console.log('error with analytics')
						}
					}
			this.setState({ loading: false, step:'emailPassword', stateError: null})
			setSubmitting(false)
		}

		} catch {
			this.setState({stateError: 'There was an error.'})
			setSubmitting(false)
		}
		
	}

	async handleCreateLocations(createLocations, selected, setSubmitting){
		console.log('creating locations')
		const { locations } = this.state
		setSubmitting(true)
		
		const locationsInfo = []


		locations.forEach((location, index) => {
			if (location) {
				const localityObj = location.address_components.find(component => {
					return component.types.includes('locality')
				})
				//locality and country should always exist (this is just an extra protective layer in case it doesn't)
				const locality = localityObj ? localityObj.long_name : null
				const adminLevel1Obj = location.address_components.find(component => {
					return component.types.includes('administrative_area_level_1')
				})
				const adminLevel1 = adminLevel1Obj ? adminLevel1Obj.long_name : null
				const countryObj = location.address_components.find(component => {
					return component.types.includes('country')
				})
				const country = countryObj ?countryObj.long_name : null

				const locationInfo =
					{locPlaceId: location.place_id,
					locLocality: locality, 
	 			 	locAdminLevel1: adminLevel1,
	 			 	locCountry: country,
	 			 	locFullName: location.formatted_address, 
					locLat: location.geometry.location.lat(), 
					locLng: location.geometry.location.lng() 
				 }

				locationsInfo.push(locationInfo)
			}
			
		})

		console.log('locationsInfo', locationsInfo)

		try {
			 await createLocations({
				variables: {
					input: {
						locationAdds: locationsInfo 
						
						}	
					}
				})
			 if (process.env.NODE_ENV !== 'development') {
						try {
							await analytics.logEvent('sign_up_process', {
							  page: 'locations', 
							  action: 'complete', 
							  amount: locationsInfo.length
							});	
						} catch {
							console.log('error with analytics')
						}
					}
			history.push('/explore')
		} catch {
			this.setState({stateError: 'There was an error.'})
			setSubmitting(false)
		}
		
	}

	async handleConnect(connect, setSubmitting){
		const { following } = this.state
		console.log('connecting!', following)
		setSubmitting(true)
		try {
			await connect({
				variables: {
					input: {
						following: following
						
						}	
					}
				})
		} catch {
			console.log('error')
			this.setState({stateError: 'There was an error.'})
			setSubmitting(false)
		}
		
		this.setState({step: 'locations'})
	}

	// async handleSendInvites(setSubmitting){
	// 	const { currentUser } = this.props
	// 	const { emails } = this.state
	// 	setSubmitting(true)
		
	// 	const finalEmails = emails.filter(email => {
	// 		console.log('filter', email, email.length)
	// 		return email.length
	// 	})

	// 	try {
	// 		 const res = await axios(`${getUrl()}/refer`, {
	// 			  method: "post",
	// 			  data: {
	// 			  	 "emails": finalEmails, 
	// 			 	 "firstName": currentUser.firstName,
	// 			 	 "lastName": currentUser.lastName
	// 				},
	// 			  withCredentials: true
	// 		})
	// 		 if (process.env.NODE_ENV !== 'development') {
	// 					try {
	// 						await analytics.logEvent('sign_up_process', {
	// 						  page: 'invite', 
	// 						  action: 'complete', 
	// 						  amount: finalEmails.length
	// 						});	
	// 					} catch {
	// 						console.log('error with analytics')
	// 					}
	// 				}
	// 		 setSubmitting(false)
	// 	} catch {
	// 		this.setState({stateError: 'There was an error.'})
	// 		setSubmitting(false)
	// 	}
		
	// }

	

	async cropImage(createNewProfilePicture){

		const cropper = this.cropper

		await this.setState({loading: true})
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
     		 return;
    	}
		
    	try {
    		new Promise(function(resolve, reject){
				cropper.getCroppedCanvas().toBlob(blob => {
					resolve(blob)

			})}).then(blob => {
				this.handleUploadProfPic(blob, createNewProfilePicture)
			})
		} catch {
			this.setState({stateError: "There was an error."})
		}
	}

	async handleSelectPicture(event){
		event.preventDefault() 

		this.setState({src:  URL.createObjectURL(event.target.files[0])})	
	}

	handleSelectCity(selected){
		this.setState({currentCity: selected})
	}


	async handleUploadProfPic(blob, createNewProfilePicture){
		const { currentUser, setCurrentUser } = this.props


		const fd = new FormData();
		fd.append('file', blob)


		console.log('blob', blob)
	    const res = await axios.post(`${getUrl()}/image/prof`, fd, {
	        headers: {
	          'Content-Type': 'multipart/form-data'

	        }
	    })
	    const publicUrl = res.data

	    const newCurrentUser = Object.assign({}, currentUser, {publicUrl: publicUrl})
	  
	   try {
				await createNewProfilePicture({
						variables: {
						input: {	
								newPublicUrl: publicUrl
							}	
						}
					})

				await setCurrentUser({
	                variables: newCurrentUser
	              })
				if (process.env.NODE_ENV !== 'development') {
						try {
							await analytics.logEvent('sign_up_process', {
							  page: 'profPic', 
							  action: 'complete'
							});	
						} catch {
							console.log('error with analytics')
						}
					}
				this.setState({ loading: false, stateError: null, step: 'contactAccess'})
				//history.push('/explore?explanation')

			} catch {
				this.setState({ error: "There was an error." })
			}
	}


	handleSelectLocation(selected, index){
		const { locations } = this.state
		if (!locations.includes(selected)){
			const newLocations = locations.slice(0)
			newLocations[index] = selected
			this.setState({locations: newLocations, stateError: null})
		} else {
			this.setState({stateError: "You've already selected this location."})
		}
		
		
	}

	handleRemoveLocation(index){
		const { locations } = this.state
		const newLocations = locations.slice(0)
		newLocations[index] = null
		this.setState({locations: newLocations})
	}

	handleChange(event){
		this.setState({value: event.target.value})
	}

	handleSelectFollow(id){
		const { following } = this.state
		console.log('following', following)
		if (following.includes(id)){
			const newFollowing = following.filter(followingId => followingId !== id)
			this.setState({following: newFollowing})
		} else {
			const newFollowing = following.slice(0)
			newFollowing.push(id)
			this.setState({following: newFollowing})
		}
	}


	async handleGetSuggestedFollowers(){
		const { client } = this.props
		const { acceptedContacts } = this.state

		if (acceptedContacts){
			const res1 =  await client.query({
	  		   	 	query: GET_SUGGESTED_FOLLOW_USERS
	  		 })
			const res2 =  await client.query({
	  		   	 	query: GET_SUGGESTED_FROM_CONTACTS,
	  	    		variables: { contacts: ['+10830848234'] },
	  	    })

	  	    const suggestedFollows = res1.data.suggestedFollowUsers.nodes
	  	    const suggestedContacts = res2.data.suggestedFromContacts.nodes
	  	    console.log('suggested!!', suggestedFollows, suggestedContacts)
	  	    const suggestedUsers = [...suggestedContacts]
	  	    suggestedFollows.forEach(follow => {
	  	    	if (!suggestedUsers.find(user => user.id === follow.id)){
	  	    		suggestedUsers.push(follow)
	  	    	}
	  	    })
	  	    console.log('suggestedUsers', suggestedUsers)
	  	    const initialFollowing = suggestedUsers.slice(0,15).map(user => {
	  	    	return user.id
	  	    })
	  	    this.setState({following: initialFollowing, suggestedUsers: suggestedUsers})

		} else {
			const suggestedFollows =  await client.query({
	  		   	 	query: GET_SUGGESTED_FOLLOW_USERS
	  		 })
			
	  	   
	  	    this.setState({following: suggestedFollows.slice(0,15), suggestedUsers: suggestedFollows})
		}
		
	}

	renderPhoneNumber(){
		const { phoneNumber, stateError } = this.state

		return (
			<div>
		        <PhoneNumber
		        	handleChangePhoneNumber={this.handleChangePhoneNumber}
			        handleSubmit={this.handleInitiateUser}
			        phoneNumber={phoneNumber}
			        stateError={stateError} 
		      />
		 	</div>)
	}

	renderEntryCode(){
		const { codeFailure, stateError } = this.state

		return (
			<div>
		        <EntryCode
		        	codeFailure={codeFailure}
			        handleSubmit={this.handleCheckEntryCode}
			        stateError={stateError} 
		      />
		 	</div>)
	}

	renderContactAccess(){
		const { ask, stateError } = this.state

		return (
			<div>
		        <ContactAccess
		        	ask={ask}
		        	handleClose={this.handleContactsLater}
			        handleSubmit={this.handleAcceptContact}
			        stateError={stateError} 
		      />
		 	</div>)
	}

	

	renderConnect(){
		const { acceptedContacts, following, suggestedUsers, stateError } = this.state
		
		return (
			<Mutation
		      mutation={CONNECT}
		    >
		      	{(connect, { loading, error }) => (
				<div>
			        <Connect
			        	acceptedContacts={acceptedContacts}
			        	connect={connect}
			        	following={following}
			        	handleConnectLater={this.handleConnectLater}
			        	handleSelectFollow={this.handleSelectFollow}
				        handleSubmit={this.handleConnect}
				        stateError={stateError} 
				        suggestedUsers={suggestedUsers}
			      />
			 	</div>)
		      	}
			</Mutation>)

	}




	renderEmailPassword(){
		const { loading, stateError } = this.state
		const initialValues = {
			email: '', 
			password: '', 
			agreeTerms: false
		}


		const signupSchema = Yup.object().shape({
		  email: Yup.string()
		    .email('E-mail is not valid!')
		    .required('E-mail is required!'),
		  password: Yup.string()
		  	.strict(true)
		    .min(8, 'Password has to be longer than 8 characters!') 
		    .required('Password is required!') 
		    .trim("No leading or trailing spaces")
		})

		
		return (
			<div>
		        <EmailPassword 
		        loading={loading} 
		        handleToggleGuidelines={this.handleToggleGuidelines}
		        handleSubmit={this.handleEmailPassword}
		        initialValues={initialValues}
		       	signupSchema={signupSchema}
		        stateError={stateError} 
		      />
		 	</div>)
	}



	renderBasic(){
		const { currentUser } = this.props
		const { fromLogin } = this.state
		const initialValues = {
			firstName: '',
			lastName: '', 
			birthday: ''
		}

		const max = new Date(Date.now()).toISOString()

		const basicSchema = Yup.object().shape({
		  firstName: Yup.string()
		  	.matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid first name')
		    .required('First Name is required')
		    .max(20, 'First Name cannot be longer than 20 characters.'),
		  lastName: Yup.string()
		  	.matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid last name')
		    .required('Last Name is required')
		    .max(20, 'Last Name cannot be longer than 20 characters.'),
		  birthday: Yup.date()
		  	.max(max, "Date must be in the past")

		})

		return (
			<div>
				<Mutation
			      mutation={CREATE_NEW_USER}
			    >
			      {(createNewUser, { loading, error }) => (
				      	<Mutation
					      mutation={CREATE_PROFILE_PICTURE}
					    	>
					      {(createProfilePicture, { loading, error }) => (
					      	<Mutation
						      mutation={CREATE_FOLLOWER}
						    >
						      	{(createFollower, { loading, error }) => (

							      	<div className="signupBasic">
							        <BasicInfo 
								        basicSchema={basicSchema}
								        error={error} 
								        fromLogin={fromLogin}
								         handleSelect={this.handleSelectCity}
								        handleSubmit={this.handleCreateUser} 
								        initialValues={initialValues}
								        loading={loading} 
								        mutationFunction={createNewUser}
								        optionalMutationFunction={createProfilePicture}
								        thirdMutationFunction={createFollower}
								        title="Add Details About Yourself"
								        view='signup'
							        />
							       </div>
							    )}
							</Mutation>

							)}


					    </Mutation>
			    	)}
			    </Mutation>
		    </div>
			)
	}

	renderVerifyPhone(){
		const { verificationSent} = this.state
		const initialValues = {
			verificationKey: ''
		}

		return <CheckKey 
			handleCheckKey={this.handleCheckKey}
			handleResendVerification={this.handleResendVerification}
			initialValues={initialValues}
			verificationSent={verificationSent}
		/>
	}

	renderLocations(){
		const { locationAmount } = this.state
		return (
			<div>
				<Mutation mutation={CREATE_LOCATIONS}>
			      	{(createLocations, { data, loading, error }) => (
			        	<LocationsSignup 
					        loading={loading} 
					        error={error} 
					        handleIncreaseLocAmount={this.handleIncreaseLocAmount}
					        handleRemoveLocation={this.handleRemoveLocation}
					        handleSubmit={this.handleCreateLocations}
					        handleSelect={this.handleSelectLocation}
					        handleClose={this.handleLocationsLater}
					        locationAmount={locationAmount}
					        mutationFunction={createLocations}
					        type="location"
					        cancelText="Later" 
					        />
			      	)}
			    </Mutation>
		    </div>)
	}

	
	renderProfPic(){
		const {  loading, src, stateError } = this.state
		const uploadingClass = loading ? "picUploading" : "";
		return (
			<div>
			<Mutation
		      mutation={CREATE_NEW_PROFILE_PICTURE}
		    >
		      {(createNewProfilePicture, { data, error }) => {
		      	return (<div>
			       {
					src ? 
					<div className="singlePicAdd initialScreen">
			        <div className="initialScreenLeft">
						<div className="secondaryHeader">Upload Profile Picture</div>
						{loading
							? <div> loading... this may take a bit </div>
							: <form className="form">
									<label className="primaryButton topButton">Choose New File
										<input className="pictureInput" type="file" name="file" accept="image/*" onChange={e => this.handleSelectPicture(e)}/>
									</label>
								</form>}

						
						{error && <div>There was an error</div>}
						{stateError && <div>{stateError}</div>}
						<div className={`${uploadingClass}`}>
							<Cropper className="cropper"
					            viewMode={1}
					            aspectRatio={3 / 3}
					            guides={false}
					            src={src}
					            zoomOnWheel={false}
					            ref={cropper => { this.cropper = cropper; }}
					            style={{'height': '20rem', 'width': '20rem'}}
					            crossorigin
					          />
					        <div className="formButtons">
					         	<button className="secondaryButton formButton" onClick={this.handleProfPicLater}>Cancel</button>
					          	<button className="primaryButton formButton" onClick={() => this.cropImage(createNewProfilePicture)}>Submit</button>
					        </div>
				         </div>
				    </div>

					
					<div className="initialScreenRight">
						<img 
						className="initialPicture"
					 	src={require('../assets/globe.jpg')}
					 	alt="formPicture"/>
					</div>
		      
			  
			      </div>
				: 	<SinglePicAdd 
				       loading={loading}
				       error={error} 
				       handleChange={this.handleSelectPicture}
				       handleSubmit={this.handleUploadProfPic}
				       handleClose={this.handleProfPicLater}
				       closeText="Later"
				       createNewProfilePicture={createNewProfilePicture}
				       title="Upload A Profile Picture"
				       view="signup"

				        />}
			    </div>)
		      }}
		    </Mutation>
		    
		        
		    </div>)
	}

	
	

	render() {
		const { step } = this.state
	
		return (
			<div className="signupContainer">
				{step === 'entryCode' && this.renderEntryCode()}
				{step === 'phoneNumber' && this.renderPhoneNumber()}
				{step === 'verify' && this.renderVerifyPhone()}
				{step === 'emailPassword' && this.renderEmailPassword()}
				{step === "basic" && this.renderBasic()}
				{step === "profPic" && this.renderProfPic()}
				{step === "contactAccess" && this.renderContactAccess()}
				{step === "connect" && this.renderConnect()}
				{step === "locations" && this.renderLocations()}
			</div>
		)
	}
			
			
}

export default compose( 
		withApollo,
	graphql(setCurrentUser, {name: 'setCurrentUser'}),
  	graphql(getCurrentUser, {
    props: ({ data: { currentUser } }) => ({
     	currentUser
    })
  }))(SignupContainer);
