import React from "react";
import { Query, compose, graphql, withApollo, Mutation } from "react-apollo";
import { GoogleApiWrapper } from "google-maps-react";
import { analytics } from '../index.js';

import history from "../history"

import AllPicturesView from "../components/AllPicturesView"
import AttractionBasicInfo from "../components/AttractionBasicInfo"
import AttractionDetailsContainer from "./AttractionDetailsContainer"
import AttractionHeader from "../components/AttractionHeader"
import AttractionReviewAndTips from "../components/AttractionReviewAndTips"
import CommentsContainer from "./CommentsContainer"
import Deletion from "../components/Deletion"
import MapView from "../components/MapView"
import Pictures from "../components/Pictures"
import PictureViewContainer from "./PictureViewContainer"
import UploadPicturesContainer from "./UploadPicturesContainer"
import Visitors from "../components/Visitors"

import { getCurrentUser, getPopup } from "../apollo/state/queries"
import { setPopup } from "../apollo/state/mutations";
import {  
	GET_ATTRACTION_BY_ID, 
	GET_ATTRACTION_PICTURES, 
	GET_ATTRACTION_VIEW_BY_ID,
	GET_FOLLOWING_INFO,
	GET_LOCATION_ATTRACTIONS, 
	GET_USER_BY_ID,
	PICTURES_VIEWABLE
} from "../apollo/db/queries"
import { DELETE_ATTRACTION, REPLACE_ATTRACTION_PLACE_IDS, VIEW_ATTRACTION } from "../apollo/db/mutations"

class AttractionContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attractionId: null,
			attractionPlaceId: null,
			attractionUserId: null,
			collapse: false, 
			deletionName: null,
			initialPicIndex: 0,
			locationId: null,
			showAllPictures: false,
			showDelete: false,
			showEdit: false,
			showGooglePictures: false,
			showPicture: false, 
			showUpload: false
		}

		this.handleDelete = this.handleDelete.bind(this)
		this.handleGoToLocation = this.handleGoToLocation.bind(this)
		this.handleHideAllPictures = this.handleHideAllPictures.bind(this)
		this.handleHideDelete = this.handleHideDelete.bind(this)
		this.handleHideEdit = this.handleHideEdit.bind(this)
		this.handleHideGooglePictures = this.handleHideGooglePictures.bind(this)
		this.handleHidePicture = this.handleHidePicture.bind(this)
		this.handleHideUpload = this.handleHideUpload.bind(this)
		this.handleSelectPin = this.handleSelectPin.bind(this)
		this.handleShowAllPictures = this.handleShowAllPictures.bind(this)
		this.handleShowDelete = this.handleShowDelete.bind(this)
		this.handleShowEdit = this.handleShowEdit.bind(this)
		this.handleShowGooglePictures = this.handleShowGooglePictures.bind(this)
		this.handleShowUpload = this.handleShowUpload.bind(this)
		this.handleShowPicture = this.handleShowPicture.bind(this)
		this.handleSelectPicture = this.handleSelectPicture.bind(this)
		this.handleToggleHours = this.handleToggleHours.bind(this)

	}

	async componentDidMount(){
		const { attractionId } = this.state
		const { client, currentUser, setPopup } = this.props
		const google = this.props.google;
		const self = this;
		const newAttractionId = parseInt(window.location.href.split('/').slice(-1)[0])
		let attraction;
		
	 	try {
	 		const res = await client.query({
	 			query: GET_ATTRACTION_VIEW_BY_ID, 
	 			variables: {attrId: newAttractionId, currentUserId: currentUser.id}
	 		})

	 		attraction = res.data.attractionViewById


          
			
		} catch {
			console.log('error')
		}
		await setPopup({
			variables: {
				popup: false
			}
		})

	   try {
	   		await client.mutate({
		   	 	mutation: VIEW_ATTRACTION, 
		   	 	variables: {
		   	 		input: {
		   	 			attrId: newAttractionId, 
		   	 			viewerId: currentUser.id
		   	 		}
		   	 	}
		 	})

	   } catch {
	   	 console.log('error')
	   }
	

		await this.setState({
			attractionId: newAttractionId, 
			attractionPlaceId: attraction ? attraction.placeId : null, 
			locationId: attraction ? attraction.locationId : null,  
			attractionUserId: attraction ? attraction.userId : null, 
			currentUserId: currentUser.id ? currentUser.id : null
		})


		if (process.env.NODE_ENV !== 'development'){	
			await analytics.logEvent('view_attraction', {
				attrId: newAttractionId, 
				attrPlaceId: attraction ? attraction.placeId : null,
				attrFullName: attraction ? attraction.fullName : null, 
				attrLocality: attraction ? attraction.locality : null, 
				attrAdminLevel1: attraction ? attraction.adminLevel1 : null, 
				attrCountry: attraction ? attraction.country : null, 
				locationId: attraction ? attraction.locationId : null,  
				attrUserId: attraction ? attraction.userId : null,

			});
		}

		

	}

	async componentDidUpdate(){
		const { attractionId,  attractionPlaceId, currentUserId } = this.state
		const { client, currentUser, setPopup } = this.props
		const google = this.props.google;
		const self = this;

		const newAttractionId = parseInt(window.location.href.split('/').slice(-1)[0])
		let attraction;


		if (attractionId !== newAttractionId && !(isNaN(attractionId) && isNaN(newAttractionId))){
				

		 	try {
		 		const res = await client.query({
		 			query: GET_ATTRACTION_VIEW_BY_ID, 
		 			variables: {attrId: newAttractionId, currentUserId: currentUser.id}
		 		})

		 		attraction = res.data.attractionViewById

		 		 await client.mutate({
			   	 	mutation: VIEW_ATTRACTION, 
			   	 	variables: {
			   	 		input: {
			   	 			attrId: newAttractionId, 
			   	 			viewerId: currentUser.id
			   	 		}
			   	 	}
			 	})

		 	
		 	} catch {
		 		console.log('error')	
			 }

		 	await setPopup({
				variables: {
					popup: false
				}
			})


			
			
			await this.setState({
				attractionId: newAttractionId, 
				attractionPlaceId: attraction ? attraction.placeId : null, 
				attractionUserId: attraction ? attraction.userId : null, 
				locationId: attraction ? attraction.locationId : null,
				currentUserId: currentUser.id,
				showEdit: false, 
				showDelete: false, 
				showUpload: false
			})
			if (process.env.NODE_ENV !== 'development'){
				await analytics.logEvent('view_attraction', {
					attrId: newAttractionId, 
					attrPlaceId: attraction ? attraction.placeId : null,
					attrFullName: attraction ? attraction.fullName : null, 
					attrLocality: attraction ? attraction.locality : null, 
					attrAdminLevel1: attraction ? attraction.adminLevel1 : null, 
					attrCountry: attraction ? attraction.country : null, 
					locationId: attraction ? attraction.locationId : null,  
					attrUserId: attraction ? attraction.userId : null,

				});
			}
		
		//I think this needs to be a seperate setstate call but now im not sure why I cant just set everything for state in the handleDetails call
		} 
		//else if (attractionBasicInfo === null) {
			// try {
			// 	const request = {
			// 	  placeId: attractionPlaceId,
			// 	  fields: ['opening_hours', 'website', 'formatted_phone_number', 'international_phone_number',  'photos']
			// 	};

			// 	const placeIdRequest = { 
			// 		placeId: attractionPlaceId, 
			// 		fields: ['place_id'] 
			// 	}

			// 	const service = new google.maps.places.PlacesService(document.getElementById('attractionViewMount'));

				
			// 	service.getDetails(request, handleDetails);
			// 	//problem with apply when this is an async function therefore must set state inside
			// 	function handleDetails(place, status){
			// 		if (status === 'NOT_FOUND'){
			// 			self.setState({attractionBasicInfo: 'NOT_FOUND'})
			// 			//service.getDetails(placeIdRequest, setPlaceIds)
						
			// 		} else if (status === google.maps.places.PlacesServiceStatus.OK) {
			// 		  self.setState({attractionBasicInfo: place})
			// 		}
			// 	}



				

			// } catch {
			// 	console.log('error')
			// }

		//}
	}

	

	async handleDelete(mutationFunction){
		const { attractionId } = this.state
		const { setPopup } = this.props

		try {
				await mutationFunction({
					variables: {
						input: {
							id: attractionId
							}
						}
					})
				await setPopup({
					variables: {
						popup: false
					}
				})
				this.handleGoToLocation()
			} catch {
				console.log('error')
			}
	}


	async handleShowAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showAllPictures: true})
	}

	async handleHideAllPictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showAllPictures: false})
	}


	async handleSelectPicture(id, currentPicIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showPicture: true, initialPicIndex: currentPicIndex, showAllPictures: false})
	}
	
	async handleShowDelete(attraction){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showDelete: true, deletionName: attraction.name})
	}

	async handleHideDelete(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showDelete: false, deletionName: null})
	}

	async handleShowPicture(picIndex){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({initialPicIndex: picIndex, showPicture: true})
	}

	async handleShowUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showUpload: true})
	}

	async handleHideUpload(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showUpload: false})
	}

	async handleHidePicture(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({initialPicIndex: 0, showPicture: false})
	}

	async handleShowGooglePictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showGooglePictures: true})
	}

	async handleHideGooglePictures(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showGooglePictures: false})
	}


	handleGoToLocation(){
		const { locationId } = this.state
		history.push(`/location/${locationId}`)
	}

	handleSelectPin(pin){
		history.push(`/attractionView/${pin.placeId}`)
	}

	async handleShowEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: true
			}
		})
		this.setState({showEdit: true})
	}


	async handleHideEdit(){
		const { setPopup } = this.props
		await setPopup({
			variables: {
				popup: false
			}
		})
		this.setState({showEdit: false})
	}


	handleToggleHours(){
		this.setState({collapse: !this.state.collapse})
	}


	renderUpload(){
		const { attractionId, locationId } = this.state
		return <UploadPicturesContainer
				handleClose={this.handleHideUpload} 
				attractionId={attractionId}
				locationId={locationId}
				view="attractionView"
			/>
	}

	renderPictures(attraction, picturesViewable){
		const {  currentUser } = this.props
		const { 
			attractionId, 
			attractionUserId, 
			initialPicIndex, 
			locationId,
			showAllPictures, 
			showPicture, 
			showUpload 
		} = this.state

		
		return <Query 
			query={GET_ATTRACTION_PICTURES}
			variables={{ attrId: attractionId}}
		>
			{({data, loading, error}) => {
				if(error){return <div>Error</div>}
				if(loading){return <div>Loading...</div>}

				const attractionPictures = data.attractionPictures.nodes
					 const displayPics = (attractionPictures.length && attractionPictures[0] !== null) ? attractionPictures.filter(picture => picture.publicUrl && picture.publicUrl.slice(0,16) !== 'https://scontent' || picture.instaAuthorName) : []


				return <div>
					{showPicture && <PictureViewContainer 
							currentUser={currentUser}
							handleClose={this.handleHidePicture}
							initialPicIndex={initialPicIndex}
							locationId={locationId}
							pictures={displayPics}
							view="attraction"
						/>}
	
				
						{<Pictures
							currentUser={currentUser}
							handleShowPicture={this.handleShowPicture}
							handleShowAll={this.handleShowAllPictures}
							handleShowUpload={this.handleShowUpload}
							locationUserId={attractionUserId}
							pictures={displayPics}
							picturesViewable={picturesViewable}
							maxIndex={50}
							type='attraction'
							view="attraction"
						/>}

						{showAllPictures && <AllPicturesView
							pictures={displayPics}
							handleClose={this.handleHideAllPictures}
							handleSelectPicture={this.handleSelectPicture}
						/>}
						
				</div>
			}}
		</Query>
				
	}

	renderHeader(attraction){
		const { currentUser } = this.props
		const { showEdit } = this.state
		return <div>
			{showEdit && <AttractionDetailsContainer 
					attraction={attraction}
					handleFinish={this.handleHideEdit}
					closeText="Cancel"
					submitText="Submit"
				/>}
			<AttractionHeader 
				attraction={attraction}
				currentUser={currentUser}
				handleShowDelete={this.handleShowDelete}
				handleShowEdit={this.handleShowEdit}

			/>
		</div>
	}

	renderReviewAndTips(attraction){
		const { currentUser } = this.props
		return <AttractionReviewAndTips 
			attraction={attraction}
			currentUser={currentUser}
			handleShowEdit={this.handleShowEdit}
		/>
	}



	renderOtherVisitors(otherVisitors){
		const { currentUser } = this.props
		if (currentUser.id > 0){
			return <Visitors 
				otherVisitors={otherVisitors}
				view="attraction"
			/>
		
		} else {
			return <div>You must create an account or login to see this section</div>
		}
		
	}

	renderBasicInfo(attraction){
		const {  collapse } = this.state
		return <AttractionBasicInfo 
		    attraction={attraction}
			collapse={collapse}
			handleShowGooglePictures={this.handleShowGooglePictures}
			handleToggleHours={this.handleToggleHours}

		/>
	}

	renderComments(pushToken){
		const { attractionId } = this.state
		return <CommentsContainer 
			commentType='attractionComment'
			parentId={attractionId}
			parentType='attraction'
			placeholder="Ask a question"
			pushToken={pushToken}

		/>
	}

	renderMap(attraction){
		return <MapView
			centerLat={attraction.lat}
			centerLng={attraction.lng}
			handleSelectPin={this.handleSelectPin}
			pins={[attraction]}
			zoom={11}

		/>
	}

	renderGooglePictures(){
		const { attractionBasicInfo } = this.state
		const pictures = attractionBasicInfo.photos && attractionBasicInfo.photos.map(photo => {
			return photo.getUrl()
		})
		return <AllPicturesView 
			handleClose={this.handleHideGooglePictures}
			handleSelectPicture={()=> console.log('this will show pic')}
			pictures={pictures}
			type="googlePictures"
		/>
	} 

	renderDelete(){
		const { currentUser } = this.props
		const { deletionName, locationId } = this.state
		const currentUserId = currentUser.id > 0 ? currentUser.id : 0;
		return (<Mutation
		      mutation={DELETE_ATTRACTION}
		      refetchQueries={[{query: GET_LOCATION_ATTRACTIONS, variables: {locId: locationId, currentUserId: currentUserId}}]}
		    >
		      {(deleteAttraction, { loading, error }) => {
		      	return (<Deletion 
		      		error={error}
			    	id={locationId} 
			    	handleHideDelete={this.handleHideDelete}
			    	handleDelete={this.handleDelete}
			    	mutationFunction={deleteAttraction}
			    	name={deletionName}
			    	type='attraction'
			    />)
		      	}}
		</Mutation>)
	}

	renderMain(){
		const { attractionId, attractionBasicInfo, collapse } = this.state
		const { currentUser } = this.props
		console.log('currentUser', currentUser)
			const currentUserId = currentUser.id > 0 ? currentUser.id : 0
			return <Query query={GET_ATTRACTION_VIEW_BY_ID} variables={{attrId: attractionId, currentUserId: currentUserId}} >
					{({data, loading, error}) => {
						if(error){return <div>Error</div>}
						if(loading){return <div>Loading...</div>}

						const attraction = data.attractionViewById
					    if(!attraction){
					    	return <div>This attraction does not exist.</div>
					    }
						const followingUserId = currentUser.id > 0 ? currentUser.id : 0
						return <Query 
							query={GET_FOLLOWING_INFO}
				    		variables= {{ profileId: attraction.userId, currentUserId: followingUserId }}
						>
							{({data, loading, error}) => {
								if(loading) {return <div>Loading..</div>}
								if(error) {return <div>Error</div>}
								const followingInfo = data.followingInfo

								return <Query 
									query={GET_USER_BY_ID}
							    	variables= {{ id: attraction.userId }}
									>
									{({data, loading, error}) => {
										if(loading) {return <div>Loading..</div>}
										if(error) {return <div>Error</div>}


										const publicity = data.userById.publicity
										return <div>
											
											<div className="attractionContainerMain">
												{this.renderHeader(attraction)}
												{((followingInfo && followingInfo.status === 'BLOCKED') || 
												(publicity === "PRIVATE" && (!followingInfo || followingInfo.status !== "APPROVED"))) 
												&& currentUser.id !== attraction.userId  ?
												<div>{this.renderPictures(attraction, false)}</div>
												: <div>{this.renderPictures(attraction, true)}</div>}
												<div className="attractionContainerContent">
													<div className="attractionLeft">
														{this.renderReviewAndTips(attraction)}
														{this.renderComments(attraction.pushToken)}
													</div>
													<div className="attractionRight">
														{this.renderMap(attraction)}
														{this.renderBasicInfo(attraction)}
														{this.renderOtherVisitors(attraction.visitors)}
													</div>
												</div>
											</div>
									 	</div>
									}}
								</Query>
							}}
						</Query>	
					}}
				</Query>

	}

	render() {
		console.log('rendering!')
		const { attractionId, showDelete, showGooglePictures, showUpload } = this.state
		const { screenView } = this.props
		const popupClass = screenView.popup && "disabledBackground"
		return (
			<div className={`attractionContainer ${popupClass}`}>
	
			{showDelete && this.renderDelete()}
			{showGooglePictures && this.renderGooglePictures()}
			{showUpload && this.renderUpload()}

			{attractionId !== null ? <div>
				{!isNaN(attractionId) ? this.renderMain() : <div>This page does not exist</div>}
			</div> : <div>Loading...</div>}

			<div id="attractionViewMount"></div>
			
			</div>
		)
		
		
	}
}

export default compose(
	withApollo,
	graphql(getCurrentUser, {
		props: ({ data: { currentUser } }) => ({
			currentUser
		})
	}),
	graphql(getPopup, {
		props: ({loading, data: {screenView}}) => ({
			screenView
		})
	}),
	graphql(setPopup, {name: 'setPopup'}),
	GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
	})
)(AttractionContainer);


