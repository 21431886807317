import React from "react";
import { Router, Route, Switch} from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';
import { compose } from "react-apollo";
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import AttractionContainer from "./containers/AttractionContainer";
import AddContainer from "./containers/AddContainer";
import ExploreContainer from "./containers/ExploreContainer";
import InstagramMobileContainer from "./containers/InstagramMobileContainer";
import InstaConnectionContainer from "./containers/InstaConnectionContainer";
import LandingPageContainer from "./containers/LandingPageContainer"
import LocationContainer from "./containers/LocationContainer";
import LoginContainer from "./containers/LoginContainer";
import Main from "./containers/Main"
import AllMessagesContainer from "./containers/AllMessagesContainer"
import NoMatch from "./components/NoMatch"
import NotificationsContainer from "./containers/NotificationsContainer"
import OverallAttractionContainer from "./containers/OverallAttractionContainer"
import OverallLocationContainer from "./containers/OverallLocationContainer"
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProfileContainer from "./containers/ProfileContainer";
import SavedContainer from "./containers/SavedContainer"
import SavedLocationContainer from "./containers/SavedLocationContainer"
import SearchContainer from "./containers/SearchContainer";
import SettingsContainer from "./containers/SettingsContainer";
import SignupContainer from "./containers/SignupContainer";
import TermsOfService from "./components/TermsOfService";
import TripContainer from "./containers/TripContainer"
import TripItineraryContainer from "./containers/TripItineraryContainer"
import TripsContainer from "./containers/TripsContainer"
import TripLocationContainer from "./containers/TripLocationContainer"
import WaitingListContainer from "./containers/WaitingListContainer"
import ResumeLandingPage from "./components/ResumeLandingPage"

import history from "./history";

const Routes = props => {
	console.log('props', props)
	

	return (
		<div>
			<DndProvider backend={HTML5Backend}>
				<Router history={history}>
					<LastLocationProvider>
						<div className="router">
							{<Switch>
								<Route path='/privacy-policy' component={PrivacyPolicy}/>
								<Route path='/terms-of-service' component={TermsOfService}/>
								<Route path='/resume' component={ResumeLandingPage}/>
								<Route path='/' component={LandingPageContainer} />
								</Switch>}

						    {/*<Route path="/instagram" component={InstagramMobileContainer} />*/}
							{/*<Switch>
								<Route exact path="/login" component={LoginContainer} />
								<Route exact path='/waitlist' component={WaitingListContainer} />
								<Route exact path="/signup" component={SignupContainer} />
								<Route path='/' component={Main}/>	
							</Switch>}
							<Switch>
								<Route path="/add" component={AddContainer} />
								<Route
									path="/attraction/:attractionId"
									component={AttractionContainer}
								/>

								<Route path="/attractionView/:placeId" component={OverallAttractionContainer} />

								<Route
									path="/location/:locationId"
									component={LocationContainer}
								/>
								<Route path="/explore" component={ExploreContainer} />
								<Route path="/locationView/:placeId" component={OverallLocationContainer} />
								<Route path="/messages" component={AllMessagesContainer} />
								
								

								<Route path='/profile' component={ProfileContainer}/>


								<Route path="/saved" component={SavedContainer} />
								<Route path="/savedLocation/:placeId" component={SavedLocationContainer} />
								
								<Route path="/search" component={SearchContainer} />
								<Route path="/settings" component={SettingsContainer} />

								<Route path='/trips' component={TripsContainer} />
								
								<Route path='/trip/:id' component={TripContainer} />
								<Route path='/tripItinerary/:id' component={TripItineraryContainer} />
								<Route path='/tripLocation/:id' component={TripLocationContainer} />

								<Route path="/notifications" component={NotificationsContainer} />
								<Route component={NoMatch} />
							</Switch>*/}
						</div>
					</LastLocationProvider>
				</Router>
			</DndProvider>
		</div>
	);
};

export default compose()(Routes);
