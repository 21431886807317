import React from "react";

import "../stylesheets/components/Search.css"

const Search = props => {
	return (
		<div className="search">
			<div>Enter a search term for a location, attraction, or user</div>
		</div>
	);
};

export default Search;
