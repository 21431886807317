import React from "react";


const UngroupLocations = props => {
	const { 
		error,
		groupedTripLocations,
		handleHideUngroup, 
		handleUngroupLocations, 
		ungroupTripLocations
	} = props

	const getLocName = (location) => {
		//country is the only field gaurenteed
		if (location.locality && location.adminLevel1 && location.country === 'United States'){
			let locName = `${location.locality}, ${location.adminLevel1}`
			return locName		
		} else if (location.locality){
			let locName = `${location.locality}, ${location.country}`
			return locName		
		} else if (location.adminLevel1){
			let locName = `${location.adminLevel1}, ${location.country}`
			return locName		
		} else {
			return location.country	
		}
	}

	return (
		<div  className="ungroupLocations popup popupMedium">
			<div className="popupContent">
				<div className="textTitle">Are you sure you want to ungroup these locations:</div>
				{groupedTripLocations.map(tripLoc => {
					return <div className="mainBackground" key={tripLoc.id}>{getLocName(tripLoc)}</div>
				})}
				{error && <div className="error">There was an error.</div>}
				<div className="formButtons">
					<button className="secondaryButton" onClick={() => handleHideUngroup()}>Nevermind</button>
					<button className="primaryButton" onClick={() => handleUngroupLocations(ungroupTripLocations)}>Ungroup</button>
				 </div>
			</div>
			
		</div>
	);
};

export default UngroupLocations;
