import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Switch from 'react-toggle-switch'

import { Button } from 'reactstrap';
import "../../node_modules/react-toggle-switch/dist/css/switch.min.css"; 

import CreateAttractionTags from "./CreateAttractionTags"

import "../stylesheets/components/Form.css"
import "../stylesheets/components/AttractionDetails.css"

const AttractionDetails = props => {
	const {
		attraction,
		attractionPublic,
		attractionType,
		attrDetailsSchema,
		closeText,
		createAttractionTags, 
		deleteAttractionTags,
		error, 
		handleChange,
		handleChangeAttrType,
		handleFinish,
		handleHover,
		handleRemoveTag,
		handleSelectStar,
		handleSelectTag,
		handleShowTags,
		handleSubmit,
		handleToggle,
		handleToggleTooltip,
		hoverStars,
		initialValues,
		showTags,
		showTooltip,
		selectedStars,
		selectedTags,
		submitText,
		tagValue,
		tooltipType,
		updateAttraction
	} = props;

	const selectedType = type => attractionType === type
	return (
		<div className="attractionDetails popup popupXL popupScroll">
			<div className="popupContent">
				<div className="secondaryHeader">Details</div>

				<Formik
					initialValues={initialValues}
					validationSchema={attrDetailsSchema}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(values, updateAttraction, createAttractionTags, deleteAttractionTags, setSubmitting);
					}}
				>
					{({ values, setFieldValue, isSubmitting }) =>
						<Form className="form">
							

							<div className="formItem">
								<label className="textTitle textTitleForm" htmlFor="stars">stars</label>
								<div className="formStars">
									<div
									onMouseEnter={() => handleHover(1)}
									onMouseLeave={() => handleHover(0)}
									onClick={() => handleSelectStar(1)}
									>
									{(hoverStars > 0 || selectedStars > 0) ?
									<i className="material-icons activeIcon iconLarge">star</i>

									 : 
									<i className="material-icons inactiveIcon iconLarge">star</i>
									} 
									</div>
									<div
									onMouseEnter={() => handleHover(2)}
									onMouseLeave={() => handleHover(0)}
									onClick={() => handleSelectStar(2)}
									>
									{(hoverStars > 1 || selectedStars > 1) ?
									<i className="material-icons activeIcon iconLarge">star</i> : 
									<i className="material-icons inactiveIcon iconLarge">star</i>

									} 
									</div>
									<div
									onMouseEnter={() => handleHover(3)}
									onMouseLeave={() => handleHover(0)}
									onClick={() => handleSelectStar(3)}
									>
									{(hoverStars > 2 || selectedStars > 2) ?
									<i className="material-icons activeIcon iconLarge">star</i> : 
									<i className="material-icons inactiveIcon iconLarge">star</i>

									}
									</div>
									<div
									onMouseEnter={() => handleHover(4)}
									onMouseLeave={() => handleHover(0)}
									onClick={() => handleSelectStar(4)}
									> 
									{(hoverStars > 3 || selectedStars > 3) ?
									<i className="material-icons activeIcon iconLarge">star</i> : 
									<i className="material-icons inactiveIcon iconLarge">star</i>

									} 
									</div>
									<div
									onMouseEnter={() => handleHover(5)}
									onMouseLeave={() => handleHover(0)}
									onClick={() => handleSelectStar(5)}
									>
									{(hoverStars > 4 || selectedStars > 4) ?
									<i className="material-icons activeIcon iconLarge">star</i> : 
									<i className="material-icons inactiveIcon iconLarge">star</i>

									}
									</div>
								</div>
							</div>
						
							<div className="formItem">
								<div className="textTitle textTitleForm">type</div>
								<select 
									className="dropdownSelect" 
									onChange={(e) => handleChangeAttrType(e.target.value)} 
									onKeyDown={(e) => {
						                if (e.key === 'Enter') {
						                  e.preventDefault();
						                }
						              }}>
									<option value="RESTAURANT" selected={selectedType('RESTAURANT')}>Food & Drinks</option>
									<option value="LODGING" selected={selectedType('LODGING')}>Lodging</option>
									<option value="ACTIVITY" selected={selectedType('ACTIVITY')}>Activity</option>
								</select>
							</div>
							<div className="formItem">
								<div className="textTitle textTitleForm">publicity</div>
								<div className="formToggle">
									<div className="fontSmall">Private (Only Me)</div>
									<Switch className="formToggleSwitch" onClick={() => handleToggle()} on={attractionPublic}/>
									<div className="fontSmall">Public (Anyone)</div>
								</div>
							</div>

							<div className="formItem">
								<div>
				       				<div className="labelAndTooltip">
							          	<label className="textTitle textTitleForm" htmlFor="review">review</label>
										<div className="tooltipIcon" onClick={() => handleToggleTooltip('review')}>?</div>
									</div>
									{showTooltip && tooltipType === 'review' && attractionType === 'RESTAURANT' && <div className='tooltip'>Your experience at this place. Ex. They had a great patio! I got the burger and it did not dissapoint.</div>}
									{showTooltip && tooltipType === 'review' && attractionType === 'LODGING' && <div className='tooltip'>Your experience at this place. Ex. The hotel was very clean and the staff was super friendly but the location was a bit far from the city center.</div>}
									{showTooltip && tooltipType === 'review' && attractionType === 'ACTIVITY' && <div className='tooltip'>Your experience at this place. Ex. This is a "must see" because the city is so famous for it but it was crowded and a bit overhyped in my opinion.</div>}

								</div>
								<Field className="formField formFieldLarge" component="textarea" name="review" autoComplete="off"/>
							</div>
							<ErrorMessage name="review" component="div" />

							<div className="formItem">
								<div>
				       				<div className="labelAndTooltip">
							          	<label className="textTitle textTitleForm" htmlFor="tips">tips</label>
										<div className="tooltipIcon" onClick={() => handleToggleTooltip('tips')}>?</div>
									</div>
									{showTooltip && tooltipType === 'tips' && attractionType === 'RESTAURANT' && <div className='tooltip'>Tips about this place that will help others. Ex. If you get seated before 6pm, your first round of drinks are half off. </div>}
									{showTooltip && tooltipType === 'tips' && attractionType === 'LODGING' && <div className='tooltip'>Tips about this place that will help others. Ex. Don't book taxis through the hotel - if you walk into the street, you can find one right away for much cheaper.</div>}
									{showTooltip && tooltipType === 'tips' && attractionType === 'ACTIVITY' && <div className='tooltip'>Tips about this place that will help others. Ex. Book your tickets ahead of time!</div>}

								</div>
								<Field className="formField formFieldLarge" component="textarea" name="tips" autoComplete="off"/>
							</div>
							<ErrorMessage name="tips" component="div" />

					

							<CreateAttractionTags 
								handleChange={handleChange}
								handleFocus={handleShowTags}
								handleRemoveTag={handleRemoveTag}
								handleSelectTag={handleSelectTag}
								handleToggleTooltip={handleToggleTooltip}
								showTags={showTags}
								showTooltip={showTooltip}
								selectedTags={selectedTags} 
								tagValue={tagValue}
								tooltipType={tooltipType}
							
							/>
					
						

							<ErrorMessage name="stars" component="div" />
                            {error && <div className="error">There was an error.</div>}
							<div className="formButtons">
								<button className="secondaryButton formButton" onClick={() => handleFinish(attraction.id)}>{closeText}</button>
								<button className="primaryButton formButton" type="submit" disabled={isSubmitting}>
									{submitText}
								</button>
							</div>
						</Form>}
				</Formik>
				
			</div>

		</div>
	);
};

export default AttractionDetails;
