import gql from "graphql-tag";



const setCurrentUser = gql`
  mutation setCurrentUser($id: Integer $firstName: String $lastName: String $about: String $currentCity: String $birthday: Date $publicity: String $seenExplanation: Boolean $seenSaveExplanation: Boolean $seenLocExplanation: Boolean $seenProfExplanation: Boolean $seenInstaConnection: Boolean $locationViews: Integer $pageViews: Integer $pushToken: String $publicUrl: String $locUploadNumber: Integer $attrUploadNumber: Integer) {
    setCurrentUser(id: $id, firstName: $firstName, lastName: $lastName about: $about currentCity: $currentCity birthday: $birthday publicity: $publicity seenExplanation: $seenExplanation seenSaveExplanation: $seenSaveExplanation seenLocExplanation: $seenLocExplanation seenProfExplanation: $seenProfExplanation seenInstaConnection: $seenInstaConnection locationViews: $locationViews pageViews: $pageViews pushToken: $pushToken publicUrl: $publicUrl locUploadNumber: $locUploadNumber attrUploadNumber: $attrUploadNumber) @client {
      id
      firstName
      lastName
      about
      currentCity
      birthday
      publicity
      seenExplanation
      seenSaveExplanation
      seenLocExplanation
      seenProfExplanation
      seenInstaConnection
      locationViews
      pageViews
      pushToken
      publicUrl
      locUploadNumber
      attrUploadNumber
    }
  }
`;

const setDestinationSearchPlace = gql`
  mutation setDestinationSearchPlace($place: String) {
    setDestinationSearchPlace(place: $place) @client {
     place
    }
  }
`;

const setDestinationSearchNeedsRefresh = gql`
  mutation setDestinationSearchNeedsRefresh($needsRefresh: String) {
    setDestinationSearchNeedsRefresh(needsRefresh: $needsRefresh) @client {
     needsRefresh
    }
  }
`;

const setDestinationSearchType = gql`
  mutation setDestinationSearchType($type: String) {
    setDestinationSearchType(type: $type) @client {
     type
    }
  }
`;


const setDestinationSearchValue = gql`
  mutation setDestinationSearchValue($value: String) {
    setDestinationSearchValue(value: $value) @client {
     value
    }
  }
`;

const setDestinationSearchAttrTypes = gql`
  mutation setDestinationSearchAttrTypes($attractionTypes: [String]) {
    setDestinationSearchAttrTypes(attractionTypes: $attractionTypes) @client {
     attractionTypes
    }
  }
`;

const setDestinationSearchAttrContent = gql`
  mutation setDestinationSearchAttrContent($attractionContent: [String]) {
    setDestinationSearchAttrContent(attractionContent: $attractionContent) @client {
     attractionContent
    }
  }
`;

const setDestinationSearchAttrTags = gql`
  mutation setDestinationSearchAttrTags($attractionTags: String) {
    setDestinationSearchAttrTags(attractionTags: $attractionTags) @client {
     attractionTags
    }
  }
`;

const setDestinationSearchAttrStars = gql`
  mutation setDestinationSearchAttrStars($attractionStars: Number) {
    setDestinationSearchAttrStars(attractionStars: $attractionStars) @client {
     attractionStars
    }
  }
`;


const setDestinationSearchAttrAndUp = gql`
  mutation setDestinationSearchAttrAndUp($attractionAndUp: Boolean) {
    setDestinationSearchAttrAndUp(attractionAndUp: $attractionAndUp) @client {
     attractionAndUp
    }
  }
`;

const setDestinationSearchAttrSort = gql`
  mutation setDestinationSearchAttrSort($attractionSort: String) {
    setDestinationSearchAttrSort(attractionSort: $attractionSort) @client {
     attractionSort
    }
  }
`;

const setDestinationSearchAttrSortDir = gql`
  mutation setDestinationSearchAttrSortDir($attractionSortDir: String) {
    setDestinationSearchAttrSortDir(attractionSortDir: $attractionSortDir) @client {
     attractionSortDir
    }
  }
`;

const setDestinationSearchLocSort = gql`
  mutation setDestinationSearchLocSort($locationSort: String) {
    setDestinationSearchLocSort(locationSort: $locationSort) @client {
     locationSort
    }
  }
`;

const setDestinationSearchLocSortDir = gql`
  mutation setDestinationSearchLocSortDir($locationSortDir: String) {
    setDestinationSearchLocSortDir(locationSortDir: $locationSortDir) @client {
     locationSortDir
    }
  }
`;



const setPopup = gql`
  mutation setPopup($isEditing: Boolean) {
    setPopup(popup: $popup) @client {
     popup
    }
  }
`;



const setExploreLocations = gql`
  mutation setExploreLocations($locations: [Location]) {
    setExploreLocations(locations: $locations) @client { 
     locations
    }
  }
`;



const setNotificationsType = gql`
  mutation setNotificationsType($type: String) {
    setNotificationsType(type: $type) @client {
     type
    }
  }
`;



const setUserSearchValue = gql`
  mutation setUserSearchValue($value: String) {
    setUserSearchValue(value: $value) @client {
     value
    }
  }
`;

const setSearchView = gql`
  mutation setSearchView($view: String) {
    setSearchView(view: $view) @client {
     view
    }
  }
`;

const setMessengerId = gql`
  mutation setMessengerId($id: Int) {
    setMessengerId(id: $id) @client {
     id
    }
  }
`;


export { 
  setCurrentUser, 
  setDestinationSearchPlace,
  setDestinationSearchType,
  setDestinationSearchValue,
  setDestinationSearchAttrContent,
  setDestinationSearchAttrStars,
  setDestinationSearchAttrTypes,
  setDestinationSearchAttrTags,
  setDestinationSearchAttrAndUp,
  setDestinationSearchAttrSort, 
  setDestinationSearchAttrSortDir, 
  setDestinationSearchLocSort, 
  setDestinationSearchLocSortDir,
  setDestinationSearchNeedsRefresh,
  setPopup, 
  setExploreLocations, 
  setNotificationsType,
  setMessengerId, 
  setUserSearchValue,
  setSearchView
};
