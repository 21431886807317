import gql from "graphql-tag";

const getCurrentUser = gql`
query {
	currentUser @client {
		id
		firstName
		lastName
		about
		birthday
		publicity
		seenExplanation
		seenSaveExplanation
		seenLocExplanation
		seenProfExplanation
		seenInstaConnection
		locationViews
		pageViews
		pushToken
		publicUrl
		locUploadNumber
    	attrUploadNumber
	}
}
`;


const getPopup = gql`
query {
	screenView @client {
		popup
	}
}
`

const getExplore = gql`
query {
	explore @client { 
		locations @client {
			id
			firstName
			lastName
			locality, 
			adminLevel1, 
			country, 
			fullName
			userId
			publicUrl
			score
		}
	}
}
`

const getNotificationsType = gql`
query {
	notificationsType @client {
		type
	}
}
`

const getDestinationSearch = gql`
query {
	destinationSearch @client {
		value, 
		type, 
		place, 
		attractionAndUp, 
		attractionStars,
		attractionTypes, 
		attractionContent, 
		attractionTags, 
		attractionSortDir, 
	    attractionSort, 
	    locationSortDir, 
	    locationSort, 
	    needsRefresh
	}
}
`
const getUserSearch = gql`
query {
	userSearch @client {
		value
	}
}
`
const getSearchView = gql`
query {
	searchView @client {
		view
	}
}
`
const getMessenger = gql`
query {
	messenger @client {
		id
	}
}
`

export { getCurrentUser, 
	getDestinationSearch,
	getPopup, 
	getExplore, 
	getMessenger,
	getNotificationsType,
	getSearchView, 
	getUserSearch };
