import React from "react";

import "../stylesheets/components/TripVoteButtons.css"

const TripVoteButtons = props => {
	const {
		createNewTripAttractionVote,
		deleteTripAttractionVote,
		downVotes, 
		handleCreateTripAttractionVote, 
		handleDeleteTripAttractionVote, 
		handleShowVoters, 
		upVotes,
		userVote, 
		userVoteId, 
		voteNameViewable
	} = props
	return (
		<div  className="tripVoteButtons">
			<div className="tripVoteButton">
				{userVote === "UP" ? 
					<div className="tertiaryButton iconLarge voteSelected" onClick={() => handleDeleteTripAttractionVote(userVoteId, deleteTripAttractionVote)}>
						<img 
							className="iconSmall" 
							src="https://storage.googleapis.com/trvlpage-bucket/arrow_up_white.svg" 
							alt="upVoteIcon"
						/>
					</div>
	      		: 
		      		<div  className="tertiaryButton iconLarge voteNotSelected" onClick={() => handleCreateTripAttractionVote("UP", createNewTripAttractionVote)}>
		      			<img 
							className="iconSmall" 
							src="https://storage.googleapis.com/trvlpage-bucket/arrow_up.svg" 
							alt="upVoteIcon"
						/>
					</div>
				}

	    
	      		{voteNameViewable ? <div className="textLink" onClick={() => handleShowVoters(upVotes)}>{upVotes.length}</div> : 
	      			<div>{upVotes.length}</div>
	      		}
      		</div>
      		<div className="tripVoteButton">
				{userVote === "DOWN" ? 
					<div className="tertiaryButton iconLarge voteSelected" onClick={() => handleDeleteTripAttractionVote(userVoteId, deleteTripAttractionVote)}>
						<img 
							className="iconSmall" 
							src="https://storage.googleapis.com/trvlpage-bucket/arrow_down_white.svg" 
							alt="downVoteIcon"
						/>
					</div>
				: 
					<div className="tertiaryButton iconLarge voteNotSelected" onClick={() => handleCreateTripAttractionVote("DOWN", createNewTripAttractionVote)}>
						<img 
							className="iconSmall" 
							src="https://storage.googleapis.com/trvlpage-bucket/arrow_down.svg" 
							alt="downVoteIcon"
						/>
					</div>
				}
				{voteNameViewable ? <div className="textLink" onClick={() => handleShowVoters(downVotes)}>{downVotes.length}</div> : 
				<div>{downVotes.length}</div>}
			</div>

		</div>
	);
};

export default TripVoteButtons;
